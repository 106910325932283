import * as React from "react";
import { MOBILE_WIDTH_THRESHOLD } from "./Constants";

export const useWindowSize = () => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [heigth, setHeight] = React.useState(window.innerHeight);

    React.useEffect(() => {
        const listener = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight)
        }

        window.addEventListener('resize', listener);
        return () => {
            window.removeEventListener('resize', listener);
        }
    }, []);

    return {
        windowHeight: heigth,
        windowWidth: width,
        isMobile: width < MOBILE_WIDTH_THRESHOLD
    };
}