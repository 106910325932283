import { Formik, FormikHelpers } from "formik";
import Enumerable from "linq";
import { AddressDto, AdminService, ErrorCode, GetParkingSubscriptionsResultEntryDto } from "parkcash-api";
import * as React from "react";
import { Redirect, useParams } from "react-router-dom";
import Resources from "../../../Resources";
import { YesNoModal } from "../../components/ConfirmModal";
import ErrorView from "../../components/ErrorView";
import GlobalProgress from "../../components/GlobalProgress";
import Spinner from "../../components/Spinner";
import { DESKTOP_HEADER_DEFAULT_HEIGHT } from "../../layouts/Common/DesktopHeaderBase";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { MOBILE_WIDTH_THRESHOLD, PHONE_REGEX } from "../../utils/Constants";
import { getErrorMessage } from "../../utils/ErrorUtils";
import Notify from "../../utils/Notify";
import { useWindowSize } from "../../utils/WindowSizeHook";
import CreateSubscriptionFormState from "./CreateSuscriptionFormState";
import { buySubscription, getActiveSubscriptionsCount, getCreateSubscriptionValidationSchema, getInitialCreateSubscriptionFormState, getSubscriptions, startSMSVerification } from "../SubscriptionsUtils";
import BigForm from "../formParts/BigForm";
import FormWrapper from "../formParts/FormWrapper";
import PaymentPart, { PAYMENT_PART_HEADER_HEIGHT } from "../formParts/PaymentPart";
import Data from "./Data";
import Parameters from "./Parameters";
import AcceptRegulations from "../formParts/AcceptRegulations";
import InvoiceFormPart from "../formParts/InvoiceFormPart";
import TargetFormPart from "../formParts/TargetFormPart";
import {getWebPaymentConfiguration, validateBlik} from "../../utils/PaymentConfigurationUtils";
import {ISelectedPaymentInfo, PaymentMethodsTypes} from "../formParts/PaymentMethod";

export default () => {
    const {windowHeight, windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const {parkingId, initialSubscriptionId} = useParams<{parkingId: string, initialSubscriptionId: string}>();
    const [initialProgress, setInitialProgress] = React.useState(true);
    const [initialError, setInitialError] = React.useState<ErrorCode | string>(null);
    const [availableSubscriptions, setAvailableSubscriptions] = React.useState<GetParkingSubscriptionsResultEntryDto[]>([]);
    const [globalProgress, setGlobalProgress] = React.useState(false);
    const [codeId, setCodeId] = React.useState<string>(null);
    const [parkingAddress, setParkingAddress] = React.useState<AddressDto>();
    const [generalParkingRegulationUrl, setGeneralParkingRegulationUrl] = React.useState<string>(null);
    const [privacyPolicyRegulationUrl, setPrivacyPolicyRegulationUrl] = React.useState<string>(null); 
    const [salesRegulationUrl, setSalesRegulationUrl] = React.useState<string>(null);
    const [selectedPaymentMethodInfo, setSelectedPaymentMethodInfo] = React.useState<ISelectedPaymentInfo>();
    const [allowedPaymentMethods, setAllowedPaymentMethods] = React.useState<PaymentMethodsTypes[]>();
    const [blikError, setBlikError] = React.useState<string>();

    const [initialValues, setInitialValues] = React.useState(getInitialCreateSubscriptionFormState(false, null, null, false)); 

    const onSubmit = async (state: CreateSubscriptionFormState, helpers: FormikHelpers<CreateSubscriptionFormState>) => {

        if (!validateBlik(selectedPaymentMethodInfo, e => setBlikError(e))) {
            return;
        }

        const {
            setSubmitting,
            setStatus
        } = helpers;
        setSubmitting(true);
        const {
            isBlikError,
            errorMessage,
            paymentUrl
        } = await buySubscription(state, selectedPaymentMethodInfo.blikCode);
        if (errorMessage) {
            if (isBlikError) {
                setBlikError(errorMessage)
            } else {
                setStatus(errorMessage);
            }

        } else {
            window.location.href = paymentUrl;
        }
        setSubmitting(false);
    }

    React.useEffect(() => {
        const init = async () => {
            setInitialProgress(true);
            const {requireLicensePlateNumber,enableParticularSpotPurchase, errorCode, spotSelectorType, subscriptions, generalParkingRegulationUrl, privacyPolicyRegulationUrl, salesRegulationUrl, parkingAddress} = await getSubscriptions(parkingId);
            if(typeof errorCode === "number"){
                setInitialError(errorCode);
            }
            else if(!subscriptions.length){
                setInitialError(Resources.Dla_tego_parkingu_nie_zostaly_zdefiniowane_zadne_abonamenty)
            }
            else{
                setParkingAddress(parkingAddress);
                setGeneralParkingRegulationUrl(generalParkingRegulationUrl);
                setPrivacyPolicyRegulationUrl(privacyPolicyRegulationUrl);
                setSalesRegulationUrl(salesRegulationUrl);
                setAvailableSubscriptions(subscriptions);
                setInitialValues(getInitialCreateSubscriptionFormState(enableParticularSpotPurchase, spotSelectorType, initialSubscriptionId || subscriptions[0].id, requireLicensePlateNumber));
            }

            const allowedPaymentMethods = await getWebPaymentConfiguration(parkingId);
            setAllowedPaymentMethods(allowedPaymentMethods);

            setInitialProgress(false);
        }
        init();
    }, []);

    const checkPhonePurchases = async (phone: string) => {
        if(!PHONE_REGEX.test(phone)){
            return;
        }

        const {count} = await getActiveSubscriptionsCount(phone, parkingId);
        if(count > 0){
            const yes = await YesNoModal.show({
                text: Resources.Z_podanym_numerem_telefonu_masz_juz_powiazane_abonamenty_Czy_zamiast_kupowania_nowego_chciałbys_którys_z_nich_przedluzyc,
                width: isMobile ? 300 : 450
            });
            if(yes){
                setGlobalProgress(true);
                const {codeId, message} = await startSMSVerification(phone, parkingId);
                if(message){
                    Notify.Error(message);
                    
                }
                else{
                    setCodeId(codeId);
                }
                setGlobalProgress(false);
            }
        }
    }

    if(codeId){
        return <Redirect push to={`/op/entersubscriptionsmscode/${codeId}/${parkingId}`} />;
    }

    return (
        <>        
        <GlobalProgress visible={globalProgress} />
        <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            enableReinitialize
            validationSchema={getCreateSubscriptionValidationSchema()}
        >
            {args => {
                const {handleSubmit, isSubmitting, values, setFieldValue, status} = args;
                const currentSubscription = Enumerable.from(availableSubscriptions).firstOrDefault(s => s.id === values.subscriptionId)
                const onPromotionCode = async (code: string) => {
                    if(values.enableParticularSpotPurchase && !values.spotId){
                        Notify.Error(Resources.Nie_wybrales_miejsca);
                    }
                    setFieldValue("discountCode", code);
                    return null;
                }

                return (
                    <FormWrapper isMobile={isMobile}>
                        <div>
                            <Parameters 
                                initialError={initialError}
                                isMobile={isMobile}
                                initialProgress={initialProgress}
                                args={args}
                                availableSubscriptions={availableSubscriptions}
                                parkingId={parkingId}
                                parkingAddress={parkingAddress}
                            />
                            {!initialProgress && !initialError && (
                                <>
                                    <BaseSpaceSeparator size={30} />
                                    <Data 
                                        isMobile={isMobile}
                                        availableSubscriptions={availableSubscriptions}
                                        args={args}
                                        parkingId={parkingId}
                                        onPhoneBlur={() => checkPhonePurchases(values.phoneNumber)}
                                        generalParkingRegulationUrl={generalParkingRegulationUrl}
                                        privacyPolicyRegulationUrl={privacyPolicyRegulationUrl}
                                        salesRegulationUrl={salesRegulationUrl}
                                    />
                                </>
                            )}
                        </div>
                        {!initialProgress && !initialError && (
                            <>
                                <BaseSpaceSeparator size={isMobile ? 7 : 30} />
                                <PaymentPart 
                                    isMobile={isMobile}
                                    maxHeight={windowHeight - PAYMENT_PART_HEADER_HEIGHT - DESKTOP_HEADER_DEFAULT_HEIGHT }
                                    status={status}                          
                                    onPay={selectedPaymentMethodInfo =>{
                                        setSelectedPaymentMethodInfo(selectedPaymentMethodInfo)
                                        handleSubmit()
                                    }                                    }
                                    onPromotionCode={onPromotionCode}                               
                                    showPromotionCode={!currentSubscription?.discountedPrice}
                                    price={values.currentPrice}
                                    lowerPrice={values.discountCodePrice}
                                    priceProgress={values.promotionCodeProgress}
                                    submitProgress={isSubmitting}
                                    isValid={!!values.subscriptionId}    
                                    showTimeRange
                                    periodType={currentSubscription.periodType}
                                    validFrom={values.targetFrom}
                                    validTo={values.targetTo}
                                    allowedPaymentMethods={allowedPaymentMethods}
                                    onBlikErrorClear={() => setBlikError('')}
                                    blikError={blikError}
                                    
                                />
                            </>
                        )}
                    </FormWrapper>
                )
            }}
        </Formik>
        </>
    );
}

