import {FormState} from "./FormState";
import {
    AddressDto,
    ChangeOwnerDto,
    ErrorCode,
    GetParkingMembersResultEntryDto,
    IParkingSpotInfoDto,
    ManagerGetParkingSpotInfoResult,
    nameof,
    ParkingManagerService,
    ParkingSpotAvailibilityType,
    ParkingSpotInfoDto,
    ParkingSpotModyficationDto,
    ParkingSpotSizeType,
    ParkingSpotsService,
    ParkingSpotType,
    ParkingsService,
    ParkingType,
    ServiceConfig
} from "parkcash-api";
import Resources from "../../../Resources";
import {CURRENCY_REGEX} from "../../utils/Constants";
import {searchAddressItem} from "../../components/forms/SearchAddressInput";
import * as uuid from "uuid";
import {parseFloatString} from "../../utils/NumberUtils";

export const getInitialFormState = (address: searchAddressItem): FormState => ({
    availibilityType: ParkingSpotAvailibilityType.Public,
    directionHints: "",
    level: "",
    sector: "",
    longTermPrice: "",
    shareForFreeInLocalCommunity: true,
    availableLongTermSharing: false,
    availableShortTermSharing: true,
    shortTermPrice: "5",
    spotNumber: "",
    type: ParkingSpotType.Garage,
    sizeType: ParkingSpotSizeType.Car,
    ownerId: null,
    address,
    max24HPrice: "",
    parkingAccessPointId: null,
    isForHandicapped: false,
    electricCharger: false,
    isMonitored: false,
    isGuarded: false,
    isForMaintanceStaff: false,
    isForGuest: false,
  /*  onlyAdminCanMakeReservations: false,*/
});

export const getParkingSpotInfo = async (id: string, jwt: string, mode: "admin" | "user"): Promise<{
    result?: IParkingSpotInfoDto,
    success: boolean,
    error?: ErrorCode
}> => {
    try {
        const {
            isSuccess,
            result,
            error
        } = mode === "user" ? await new ParkingSpotsService(new ServiceConfig({jwt})).getParkingSpotInfo(id) : await new ParkingManagerService(
            new ServiceConfig({jwt})).getParkingSpotInfo(id);
        if (isSuccess && mode === "user") {
            return {
                success: true,
                result: result as ParkingSpotInfoDto
            }
        } else if (isSuccess && mode === "admin") {
            return {
                success: true,
                result: (result as ManagerGetParkingSpotInfoResult).spotInfo
            }
        } else {
            return {
                success: false,
                error: error.code
            };
        }
    } catch (e) {
        const error = e;
        return {
            success: false,
            error: ErrorCode.NetworkError
        };
    }
}

export const getFormStateFromInfo = (info: IParkingSpotInfoDto): FormState => {
    if (!info) {
        return null;
    }

    const {
        parkingId,
        availibilityType,
        directionHints,
        isLongTermRentingEnabled,
        enableFreeBookingWithinGroup,
        isShortTermRentingEnabled,
        level,
        sector,
        longTermRentingPrice,
        shortTermRentingPrice,
        sizeType,
        spotNumber,
        type,
        ownerId,
        addressDto,
        shortTermRentingPriceMaxPer24H,
        parkingType,
        parkingAccessPointId,
        isForHandicapped,
        electricCharger,
        isMonitored,
        isGuarded,
        isForMaintanceStaff,
        isForGuest,
      /*onlyAdminCanMakeReservations*/
    } = info;

    const address: searchAddressItem = {
        id: parkingId || uuid.v1(),
        type: parkingType === ParkingType.Public ? "location" : "parking",
        city: addressDto.city,
        streetName: addressDto.streetName,
        streetNumber: addressDto.streetNumber,
        lat: addressDto.latitude,
        lon: addressDto.longitude
        //...addressDto.toJSON()
    }

    return {
        address,
        directionHints,
        level,
        sector,
        spotNumber,
        sizeType,
        type: type,
        availibilityType: availibilityType,
        longTermPrice: longTermRentingPrice > 0 ? longTermRentingPrice.toString() : "",
        shortTermPrice: shortTermRentingPrice > 0 ? shortTermRentingPrice.toString() : "",
        ownerId,
        availableLongTermSharing: isLongTermRentingEnabled,
        availableShortTermSharing: isShortTermRentingEnabled,
        shareForFreeInLocalCommunity: enableFreeBookingWithinGroup,
        max24HPrice: shortTermRentingPriceMaxPer24H > 0 ? shortTermRentingPriceMaxPer24H.toString() : "",
        parkingAccessPointId,
        isForHandicapped,
        electricCharger,
        isMonitored,
        isGuarded,
        isForMaintanceStaff,
        isForGuest,
       /* onlyAdminCanMakeReservations,*/
      

    }
}

export const saveParkingSpot = async (state: FormState, spotId: string, jwt: string, assignUser: boolean,  shareSpot24_7:boolean|undefined): Promise<{
    result: boolean,
    spotId?: string,
    error?: ErrorCode
}> => {
    try {
        if (spotId) {
            const modificationResponse = await new ParkingSpotsService(new ServiceConfig({jwt})).modifyParkingSpot(spotId,
                getParkingSpotCreationDTO(state, shareSpot24_7)
        );
            if (modificationResponse.isSuccess) {
                if (assignUser) {
                    const assignResponse = await new ParkingManagerService(new ServiceConfig({jwt})).changeOwner(spotId,
                        new ChangeOwnerDto({
                            newOwnerId: state.ownerId
                        })
                    );
                    if (assignResponse.isSuccess) {
                        return {
                            result: true,
                            spotId
                        };
                    } else {
                        return {
                            result: false,
                            error: assignResponse.error.code
                        };
                    }
                } else {
                    return {
                        result: true,
                        spotId
                    };
                }
            } else {
                return {
                    result: false,
                    error: modificationResponse.error.code
                };
            }

        } else {
            const creationResponse = await new ParkingSpotsService(new ServiceConfig({jwt})).createParkingSpot(
                getParkingSpotCreationDTO(state, shareSpot24_7));
            if (creationResponse.isSuccess) {
                const {id: newSpotId} = creationResponse.result;
                await new ParkingSpotsService(new ServiceConfig({jwt})).activate(newSpotId);
                if (assignUser) {
                    await new ParkingManagerService(new ServiceConfig({jwt})).changeOwner(newSpotId,
                        new ChangeOwnerDto({
                            newOwnerId: state.ownerId
                        })
                    );
                }
                return {
                    result: true,
                    spotId: newSpotId
                };
            } else {
                return {
                    result: false,
                    error: creationResponse.error.code
                };
            }
        }
    } catch (e) {
        const error = e;
        return {
            result: false,
            error: ErrorCode.NetworkError
        };
    }
}

const getParkingSpotCreationDTO = (state: FormState, authoShare:boolean): ParkingSpotModyficationDto => {
    const {
        directionHints,
        level,
        sector,
        longTermPrice,
        address,
        shareForFreeInLocalCommunity,
        shortTermPrice,
        spotNumber,
        availableLongTermSharing,
        availableShortTermSharing,
        availibilityType,
        ownerId,
        sizeType,
        type,
        parkingAccessPointId,
        isForHandicapped,
        electricCharger,
        isMonitored,
        isGuarded,
        isForMaintanceStaff,
        isForGuest,
      /*  onlyAdminCanMakeReservations,*/
    
        
    } = state;

    const privateParkingId = address.type === "parking" ? address.id : null;
    const addressDto = address.type === "location" ? new AddressDto({
        city: address.city,
        streetName: address.streetName,
        country: "Poland",
        latitude: address.lat,
        longitude: address.lon,
        streetNumber: address.streetNumber
    }) : null;

    return new ParkingSpotModyficationDto({
        additionalInfo: "",
        directionHints,
        privateParkingId,
        spotNumber,
        level,
        sector,
        isLongTermRentingEnabled: availableLongTermSharing,
        addressDto,
        enableFreeBookingWithinGroup: shareForFreeInLocalCommunity,
        availibilityType: availibilityType,
        sizeType: sizeType,
        type: type,
        isForHandicapped,
        electricCharger,
        isMonitored,
        isGuarded,
        isForMaintanceStaff,
        isForGuest,
     /*   onlyAdminCanMakeReservations,*/
        isShortTermRentingEnabled: availableShortTermSharing,
        communityRentingPrice: parseFloatString(shortTermPrice, -1),
        shortTermRentingPrice: parseFloatString(shortTermPrice, -1),
        longTermRentingPrice: parseFloatString(longTermPrice, -1),
        communityRentingMaxPricePer24H: parseFloatString(state.max24HPrice, null),
        shortTermRentingPriceMaxPer24H: parseFloatString(state.max24HPrice, null),
        parkingAccessPointId: parkingAccessPointId || undefined,
        autoShare:authoShare
    });
}

export const getParkingUsers = async (parkingId: string, jwt: string): Promise<{
    result?: Array<{ id: string, text: string }>,
    success: boolean,
    error?: ErrorCode
}> => {
    try {
        const {
            result,
            isSuccess,
            error
        } = await new ParkingsService(new ServiceConfig({jwt}))
            .getParkingMembers(parkingId,
                "",
                `${nameof<GetParkingMembersResultEntryDto>("firstName")},${nameof<GetParkingMembersResultEntryDto>(
                    "lastName")}`,
                1,
                10000
            );

        if (isSuccess) {
            return {
                success: true,
                result: result.entries.map<{ id: string, text: string }>(e => ({
                    id: e.userId,
                    text: `${e.firstName} ${e.lastName} (${e.email})`
                }))
            }
        } else {
            return {
                success: false,
                error: error.code
            };
        }

    } catch {
        return {
            error: ErrorCode.NetworkError,
            success: false
        };
    }
}

export const getParkings = async (jwt: string): Promise<{
    result?: Array<searchAddressItem>,
    success: boolean,
    error?: ErrorCode
}> => {
    try {
        const response = await new ParkingsService(new ServiceConfig({jwt})).getUserParkings();
        if (response.isSuccess) {
            return {
                success: true,
                result: response.result.map<searchAddressItem>(p => {
                    const {
                        city,
                        streetName,
                        streetNumber
                    } = p.address;

                    return {
                        id: p.id,
                        spotCreationMode: p.spotCreationMode,
                        type: "parking",
                        city,
                        streetName,
                        streetNumber
                    }
                })
            }
        } else {
            return {
                success: false,
                error: response.error.code
            };
        }
    } catch {
        return {
            success: false,
            error: ErrorCode.NetworkError
        };
    }

}

export const validate = (state: FormState) => {
    const errors: { [key in keyof FormState]?: string } = {};

    if (!state.address) {
        errors.address = Resources.Wymagane;
    }

    if (state.address && !state.address.streetNumber) {
        errors.address = Resources.Wymagane_jest_podanie_numeru_domu;
    }

    if (!state.spotNumber) {
        errors.spotNumber = Resources.Wymagane;
    }

    if (state.availableLongTermSharing) {
        if (!state.longTermPrice) {
            errors['longTermPrice'] = Resources.Wymagane
        } else if (!CURRENCY_REGEX.test(state.longTermPrice)) {
            errors['longTermPrice'] = Resources.Niepoprawny_format;
        }
    }

    if (state.availableShortTermSharing || !state.shareForFreeInLocalCommunity) {
        if (!state.shortTermPrice) {
            errors.shortTermPrice = Resources.Wymagane;
        } else if (!CURRENCY_REGEX.test(state.shortTermPrice)) {
            errors.shortTermPrice = Resources.Niepoprawny_format;
        }

        if (state.max24HPrice && !CURRENCY_REGEX.test(state.max24HPrice)) {
            errors['max24HPrice'] = Resources.Niepoprawny_format;
        }
    }

    return errors;
}

export const validateWithUser = (state: FormState) => {
    const errors = validate(state);

    if (!state.ownerId) {
        errors.ownerId = Resources.Wymagane;
    }

    return errors;
}