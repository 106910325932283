import { GetParkingMobilePilotsListResultEntryDto, IVirtualPilotDto, ParkingManagerService, ServiceConfig } from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import Resources from "../../Resources";
import RegularDropdownList from "../components/forms/RegularDropdownList";
import { applicationState } from "../redux/ApplicationState";
import Colors from "../styles/Colors";
import { getVirtualPilots } from "./Redux";

interface OwnProps {
    mode: "admin" | "user";
    value: string,
    error: string,
    touched: boolean,
    setFieldValue: (key: string, value) => void;
    setFieldTouched: (key: string, touched) => void;
    name: string;
}

interface Props extends OwnProps {
    userPilots: IVirtualPilotDto[];
    onInit: () => void;
    parkingId: string;
    jwt: string;
}

const Container = (props: Props) => {
    const {userPilots,jwt,mode,onInit,parkingId,value,error,name,setFieldTouched,setFieldValue,touched} = props;
    const [adminPilots, setAdminPilots] = React.useState<GetParkingMobilePilotsListResultEntryDto[]>([]);

    const init = async () => {
        if(mode === "user"){
            onInit();
        }
        else{
            try{
                setAdminPilots([]);
                const {isSuccess, result} = await new ParkingManagerService(new ServiceConfig({jwt})).getParkingMobilePilotsList(parkingId);            
                if(isSuccess){
                    setAdminPilots(result.entries)
                }
            }
            catch{

            }
        }
    }

    React.useEffect(() => {
        init();
    }, [parkingId, mode]);

    return (
        <RegularDropdownList 
            name={name}
            value={value}
            touched={touched}
            error={error}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            borderColor={Colors.brownish_grey}
            variant="standard"
            actions={mode === "user" ? userPilots.map(p => ({id: p.virtualPilotId, text: p.friendlyName})) : adminPilots.map(p => ({id: p.id, text: p.friendlyName}))}
            label={Resources.Wybierz_pilota}
        />
    );
}

const mapStateToProps = (state: applicationState, ownProps: OwnProps): Partial<Props> => ({
    ...ownProps,
    userPilots: state.virtualPilots.pilots || [],
    parkingId: state.user.applicationMode.getCurrentParking()?.parkingId,
    jwt: state.user.token
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
    onInit: () => dispatch(getVirtualPilots())
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);