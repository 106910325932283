import * as React from "react";
import "../components/ParkingMap/ParkingMap.scss";
import Body from "../layouts/Main/Body";
import {AdminService, NotificationsService, ServiceConfig} from "parkcash-api";
import Notify from "../utils/Notify";
import {getErrorMessage} from "../utils/ErrorUtils";
import {ISelectedPaymentInfo, PaymentMethod, PaymentMethodsTypes} from "./formParts/PaymentMethod";
import ReactFlagsSelect, {Nu} from "react-flags-select";
import {useEffect, useState} from "react";
import {getInterfaceLanguage} from "../culture-helper";

export default () => {
    const [selectedLang, setSelectedLang] = useState("PL");

    useEffect(() => {

        let l = getInterfaceLanguage();
        console.log(l);

        if(l.toLowerCase().startsWith("pl")){
            setSelectedLang("PL");
        }else {
            setSelectedLang("US");
        }

    }, []);

    return (<Body title="">
        <div>
            <ReactFlagsSelect

                countries={[
                    "PL",
                    "US"
                ]}
                placeholder=""
                selected={selectedLang}
                showSelectedLabel={true}
                showOptionLabel={true}
                customLabels={{"US": "EN",  "PL": "PL"}}
                onSelect={(countryCode) => {
                    console.log(countryCode);
                    setSelectedLang(countryCode)
                }}
            /></div>
    </Body>)

    // const [spotToUnreserve, setSpotToUnreserve] = React.useState("");
    // const [unreserveProgress, setUnreserveProgress] = React.useState(false);
    // const [spotInfoId, setSpotInfoId] = React.useState("");
    // const [spotInfoProgress, setSpotInfoProgress] = React.useState(false);
    // const [spotInfo, setSpotInfo] = React.useState<any>(null);
    // const [gridKey, setGridKey] = React.useState(uuid.v1());

    // const getSpotInfo = async () => {
    //     try{
    //         setSpotInfoProgress(true);
    //         setSpotInfo(null);
    //         const {data} = await axios.post(URL + "/spotreservation", {spotId: spotInfoId});
    //         setSpotInfo(data);
    //     }
    //     catch{

    //     }
    //     finally{
    //         setSpotInfoProgress(false);
    //     }
    // }

    // const unreserveSpot = async () => {
    //     try{
    //         setUnreserveProgress(true);
    //         const {data} = await axios.post(URL + "/unreserve", {spotId: spotToUnreserve});
    //         if(data.unreserved){
    //             setGridKey(uuid.v1());
    //         }
    //     }
    //     catch{

    //     }
    //     finally{
    //         setUnreserveProgress(false);
    //     }
    // }

    // const dataSource = React.useRef(new CustomDataSource<ParkingSpot>({
    //     load: async () => {
    //         try{
    //             // const response = await fetch("http://dev.parkcash.io:50040/spotslist", {
    //             //     method: "POST",
    //             // } as any)
    //             // const data = await response.json();

    //             const {data} = await axios.post(URL + "/spotslist") 
    //             if(data?.result?.error){
    //                 return ParkCashApiErrorCode.NetworkError;
    //             }
    //             else{
    //                 const spots: ParkingSpot[] = data.result.parkingPlaces;
    //                 return {
    //                     items: spots,
    //                     totalItems: spots.length
    //                 }
    //             }
    //         }
    //         catch{
    //             return ParkCashApiErrorCode.NetworkError;
    //         }
    //     }
    // })).current;

    // return (
    //     <Body
    //         title=""

    //     >
    //         <div style={{display: 'flex', alignItems: 'center'}}>
    //             <div style={{width: 300}}>
    //                 <Input label="Miejsce" value={spotInfoId} onChangeText={setSpotInfoId} />

    //             </div>
    //             <BaseSpaceSeparator size={10} />
    //             <div style={{width: 300}}>
    //                 <StandardButton progress={spotInfoProgress} onClick={getSpotInfo}>Info o
    // rezerwacji</StandardButton> </div> <BaseSpaceSeparator size={10} /> <PCText color={Colors.brownish_grey}
    // fontSize={12}>{spotInfo ? JSON.stringify(spotInfo) : ""}</PCText> </div> <BaseSpaceSeparator size={20} /> <div
    // style={{display: 'flex', alignItems: 'center'}}> <div style={{width: 300}}> <Input label="Miejsce"
    // value={spotToUnreserve} onChangeText={setSpotToUnreserve} />

    //             </div>
    //             <BaseSpaceSeparator size={10} />
    //             <div style={{width: 300}}>
    //                 <StandardButton progress={unreserveProgress} onClick={unreserveSpot}>Usun
    // rezerwacje</StandardButton> </div> </div> <BaseSpaceSeparator size={50} /> <PCDataGrid<ParkingSpot>
    // key={gridKey} dataSource={dataSource} defaultPageSize={100} columns={[ { dataField: "idParkingPlace", label:
    // "IdParkingPlace" }, { dataField: "name", label: "Nazwa" }, { label: "Adres", renderCell: (item) =>
    // formatAddress({city: item.city, streetName: item.street, streetNumber: item.number}) }, { label: "Status",
    // renderCell: (item) => <PlaceState id={item.idParkingPlace} /> } ]} >

    //         </PCDataGrid>
    //     </Body>
    // )
}