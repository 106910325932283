import {UpdateparkingFormState} from "./parkingConfigFormTypes";
import {IGetManagerParkingsViewResultEntryDto} from "parkcash-api";
import {AddressDto, ParkingType} from "parkcash-api";
import {searchAddressItem} from "../../../components/forms/SearchAddressInput";

export const mapGetParkginDtoToUpdateFormValues=(parkingDto?: IGetManagerParkingsViewResultEntryDto): UpdateparkingFormState=>{ 
    return {
       address:getAdresInputFromAddressDto(parkingDto?.address),
        friendlyName: parkingDto?.friendlyName ?? "",
        parkingType: parkingDto?.parkingType,
        contactEmail: parkingDto?.contactEmail?? "",
        contactPhone: parkingDto?.contactPhone?? "",
        description: parkingDto?.description?? "",
        generalParkingRegulationUrl: parkingDto?.generalParkingRegulationUrl?? "",
        salesRegulationUrl: parkingDto?.salesRegulationUrl?? "",
        privacyPolicyRegulationUrl: parkingDto?.privacyPolicyRegulationUrl?? "",
        parkingMapUrl: parkingDto?.parkingMapUrl?? "",
        appManualUrl: parkingDto?.appManualUrl?? "",
        customLogoUrl: parkingDto?.customLogoUrl?? "",
    }
    
}
export const mapUpdateFormValuesToFormRelatedDtoProperties=(updateFormValues: UpdateparkingFormState): IGetManagerParkingsViewResultEntryDto=>{
    return {
        address: getAddressDtoFromAddressInput(updateFormValues?.address),
        friendlyName: updateFormValues?.friendlyName ?? undefined,
        parkingType: updateFormValues?.parkingType??ParkingType.Office,
        contactEmail: updateFormValues?.contactEmail?? undefined,
        contactPhone: updateFormValues?.contactPhone??undefined,
        description: updateFormValues?.description??undefined,
        generalParkingRegulationUrl: updateFormValues?.generalParkingRegulationUrl?? undefined,
        salesRegulationUrl: updateFormValues?.salesRegulationUrl?? undefined,
        privacyPolicyRegulationUrl: updateFormValues?.privacyPolicyRegulationUrl?? undefined,
        parkingMapUrl: updateFormValues?.parkingMapUrl?? undefined,
        appManualUrl: updateFormValues?.appManualUrl?? undefined,
        customLogoUrl: updateFormValues?.customLogoUrl?? undefined,
    }
    
}

export const getAdresInputFromAddressDto=(addressDto: AddressDto): searchAddressItem=>{
    return addressDto? {
        id:"mock", // not provided by the API
        type:"parking",
        city: addressDto?.city?? undefined,
        country: addressDto?.country?? undefined,
        streetName: addressDto?.streetName?? "",
        streetNumber: addressDto?.streetNumber?? undefined,
        lat: addressDto?.latitude?? undefined,
        lon: addressDto?.longitude?? undefined
    }: null;
}
export const addressIsValid=(address: searchAddressItem): boolean=>{
    if(!address || !address.city || !address.country || !address.streetName || !address.streetNumber || !address.lat || !address.lon){
        return false
    }
    return true;
}

export const getAddressDtoFromAddressInput=(address: searchAddressItem): AddressDto=>{
    let  streetNumber= ""
    if(address.streetNumber){
        streetNumber= address.streetNumber
    }
    else if(address.address) {
        const streetNumberMatch= address.address.match(/\d+/)
        if(streetNumberMatch){
            streetNumber= streetNumberMatch[0]
        }
    }
    
    return new AddressDto({
        city: address?.city,
        country: address?.country,
        streetName: address?.streetName?? " ",
        streetNumber: streetNumber,
        latitude: address?.lat,
        longitude: address?.lon
    })
}