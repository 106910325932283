import { FormikHelpers } from "formik";
import { CreateSubscriptionDto, GetParkingSubscriptionsViewResultEntryDto, ModifySubscriptionDto, ServiceConfig, SubscriptionPeriodType, SubscriptionsService } from "parkcash-api";
import * as React from "react";
import {SchemaOf, string } from "yup";
import * as yup from "yup";
import Resources from "../../../Resources";
import ModalForm from "../../components/ModalForm";
import RegularInput from "../../components/forms/RegularInput";
import { SwitchWithLabel } from "../../components/Switch";
import RegularTextArea from "../../components/forms/RegularTextArea";
import { parseFloatString } from "../../utils/NumberUtils";
import { CURRENCY_REGEX, INTEGER_REGEX } from "../../utils/Constants";
import { getErrorMessage } from "../../utils/ErrorUtils";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";

const subscription_type = require("../../../assets/images/subscription_type.svg");

interface FormState {
    name: string,
    description: string,
    type: SubscriptionPeriodType,
    days?: string,
    price: string,
    hasDiscount: boolean,
    discountPrice: string,
    orderIndex: string
}

const getFormState = (item: GetParkingSubscriptionsViewResultEntryDto): FormState => {
    if(!item){
        return {
            type: SubscriptionPeriodType.NumberOfDays,
            name: "",
            description: "",
            days: "",
            discountPrice: "",
            hasDiscount: false,
            price: "",
            orderIndex: ""
        }
    }

    const {price, duration, discountedPrice, description,name, periodType, orderIndex} = item;
    return {
        name,
        description,
        days: periodType === SubscriptionPeriodType.NumberOfDays ? duration.toString() : "",
        discountPrice: !discountedPrice ? "" : discountedPrice.toString(),
        hasDiscount: !!discountedPrice,
        price: price.toString(),
        type: periodType,
        orderIndex: typeof orderIndex === "number" ? orderIndex.toString() : ""
    }   
}

export default (props: {
    item: GetParkingSubscriptionsViewResultEntryDto,
    visible: boolean,
    onSubmitted: () => void,
    jwt: string,
    onClose: () => void,
    parkingId: string
}) => {
    const {item, onSubmitted, visible, jwt, onClose, parkingId} = props;
    const [initialValues, setInitialValues] = React.useState<FormState>(getFormState(item))

    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {
        try{
            helpers.setSubmitting(true);

            const {isSuccess, error} = !!item ? (await new SubscriptionsService(new ServiceConfig({jwt})).modifySubscription(new ModifySubscriptionDto({
                name: state.name, 
                subscriptionId: item.id,
                discountedGrossPrice: state.hasDiscount ? parseFloatString(state.discountPrice) : undefined,
                grossPrice: parseFloatString(state.price),
                duration: state.type === SubscriptionPeriodType.NumberOfDays ? parseInt(state.days) : undefined,
                description: state.description || "",
                periodType: state.type,
                orderIndex: parseInt(state.orderIndex) || undefined
            }))) : (await new SubscriptionsService(new ServiceConfig({jwt})).createSubscription(new CreateSubscriptionDto({
                periodType: state.type,
                name: state.name,
                discountedGrossPrice: state.hasDiscount ? parseFloatString(state.discountPrice) : undefined,
                isActive: true,
                description: state.description,
                duration: state.type === SubscriptionPeriodType.NumberOfDays ? parseInt(state.days) : undefined,
                parkingId,
                grossPrice: parseFloatString(state.price),
                maxAllowedForwardPurchase: "3.00:00:00",
                orderIndex: parseInt(state.orderIndex) || undefined
            })))

            if(isSuccess){
                onSubmitted();
            }
            else{
                helpers.setStatus(getErrorMessage(error.code));
            }
        }
        catch{
            helpers.setStatus(getErrorMessage());
        }
        finally{
            helpers.setSubmitting(false);
        }
    }

    const validationSchema: SchemaOf<FormState> = yup.object({
        name: yup.string().required(Resources.Wymagane),
        days: yup.string().nullable().notRequired().when("type", {
            is: SubscriptionPeriodType.NumberOfDays,
            then: yup.string().required(Resources.Wymagane).matches(INTEGER_REGEX, Resources.Niepoprawny_format)
        }),
        discountPrice: yup.string().notRequired().nullable().when("hasDiscount", {
            is: true,
            then: yup.string().required(Resources.Wymagane).matches(CURRENCY_REGEX, Resources.Niepoprawny_format)
        }),
        price: yup.string().required(Resources.Wymagane).matches(CURRENCY_REGEX, Resources.Niepoprawny_format),
        type:yup.mixed<SubscriptionPeriodType>().nullable().notRequired(),
        description:yup.string().nullable().notRequired(),
        hasDiscount:yup.boolean().nullable().notRequired(),
        orderIndex: yup.string().matches(/^\d*$/, Resources.Niepoprawny_format)
    })

    React.useEffect(() => {
        setInitialValues(getFormState(item));
    }, [item]);

    return (
        <ModalForm
            icon={subscription_type}
            iconHeight={105}
            iconWidth={120}
            visible={visible}
            onClose={onClose}
            title={!!item ? Resources.Abonament : Resources.Nowy_abonament}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {args => {
                const {values, touched, errors, setFieldValue, setFieldTouched} = args;

                return (
                    <>
                        <RegularDropdownList 
                            borderColor={Colors.brownish_grey}
                            label={Resources.Typ}
                            value={values.type}
                            error={errors.type}
                            touched={touched.type}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            name="type"
                            actions={[
                                {
                                    id: SubscriptionPeriodType.NumberOfDays,
                                    text: Resources.Ilosc_dni
                                },
                                {
                                    id: SubscriptionPeriodType.CalenendarMonth,
                                    text: Resources.Pelny_miesiac
                                },
                                {
                                    id: SubscriptionPeriodType.GivenFullCalendarMonth,
                                    text: Resources.Wybrany_miesiac
                                }
                            ]}
                        />
                        <RegularInput 
                            value={values.name}
                            touched={touched.name}
                            error={errors.name}
                            name="name"
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            label={Resources.Nazwa}
                            showClearButton
                            showValidatedButton
                        />
                        <RegularTextArea 
                            value={values.description}
                            touched={touched.description}
                            error={errors.description}
                            name="description"
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            label={Resources.Opis}
                        />
                        <div style={{width: 210}}>
                            {values.type === SubscriptionPeriodType.NumberOfDays && (
                                <RegularInput 
                                    value={values.days}
                                    touched={touched.days}
                                    error={errors.days}
                                    name="days"
                                    setFieldTouched={setFieldTouched}
                                    setFieldValue={setFieldValue}
                                    label={Resources.Liczba_dni_obowiazywania}
                                    showClearButton
                                    showValidatedButton
                                />
                            )}
                            <RegularInput 
                                value={values.price}
                                touched={touched.price}
                                error={errors.price}
                                name="price"
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                label={Resources.Cena_brutto}
                                showClearButton
                                showValidatedButton
                            />
                        </div>
                        <SwitchWithLabel 
                            label={Resources.Cena_promocyjna}
                            value={values.hasDiscount}
                            onChange={v => setFieldValue("hasDiscount", v)}
                        />
                        {values.hasDiscount && (
                            <div style={{width: 210}}>
                                <RegularInput 
                                    value={values.discountPrice}
                                    touched={touched.discountPrice}
                                    error={errors.discountPrice}
                                    name="discountPrice"
                                    setFieldTouched={setFieldTouched}
                                    setFieldValue={setFieldValue}
                                    showClearButton
                                    showValidatedButton
                                    label={Resources.Cena_promocyjna}
                                />
                            </div>
                        )}
                        <BaseSpaceSeparator size={50} />
                        <RegularInput 
                            value={values.orderIndex}
                            touched={touched.orderIndex}
                            error={errors.orderIndex}
                            name="orderIndex"
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            label={Resources.Kolejnosc_sortowania}
                            showClearButton
                            showValidatedButton
                        />
                    </>
                )
            }}
        </ModalForm>
    );
}