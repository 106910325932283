import { FormikHelpers } from "formik";
import { ManagerModifyMobilePilotLinkDto, ParkingManagerService, ServiceConfig, TimeRange } from "parkcash-api";
import * as React from "react";
import * as yup from "yup";
import Resources from "../../../Resources";
import RegularDatetimePicker from "../../components/forms/RegularDateTimePicker";
import ModalForm from "../../components/ModalForm";
import { addDays } from "../../utils/DateTimeUtils";
import { getErrorMessage } from "../../utils/ErrorUtils";
import { useJWT } from "../../utils/JWTUtils";
import {Asserts} from "yup";

const pilot = require("../../../assets/images/pilot.svg");

const validationSchema = yup.object({
    validTo: yup.date().required(Resources.Wymagane)
});

interface FormState extends Asserts<typeof validationSchema> {}

export default (props: {
    visible: boolean,
    onClose: () => void,
    validTo: Date,
    linkId: string,
    onSubmitted: (validTo: Date) => void;
}) => {
    const jwt = useJWT();
    const {onClose,visible,validTo,linkId,onSubmitted} = props;
    const initialValues: FormState = {
        validTo
    }
   
    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {
        const {validTo} = state;
        const targetAccessTo = new Date(validTo.getFullYear(), validTo.getMonth(), validTo.getDate());
        const {setSubmitting, setStatus} = helpers;

        try{
            setSubmitting(true);
            const {isSuccess, error} = await new ParkingManagerService(new ServiceConfig({jwt})).modifyMobilePilotLink(linkId, new ManagerModifyMobilePilotLinkDto({
                timeRange: new TimeRange({
                    validFrom: addDays(new Date(), -1),
                    validTo: targetAccessTo
                })
                
            }));
            if(isSuccess){
                onSubmitted(validTo);
            }
            else{
                setStatus(getErrorMessage(error.code));
            }
        }
        catch{
            setStatus(getErrorMessage());
        }
        finally{
            setSubmitting(false);
        }
    }

    return (
        <ModalForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            icon={pilot}
            iconWidth={100}
            iconHeight={114}
            visible={visible}
            onClose={onClose}
            title={Resources.Edytuj_dostep_do_mobilnego_pilota}
        >
        {args => {
            const {values, errors, touched, setFieldTouched, setFieldValue} = args;

            return (
                <RegularDatetimePicker 
                    label={Resources.Data_waznosci}
                    name="validTo"
                    value={values.validTo}
                    error={errors.validTo}
                    touched={touched.validTo}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    mode="date"
                />
            )
        }}
        </ModalForm>
    );
}