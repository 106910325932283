import { el } from "date-fns/locale";
import {
  AvailibilityStatus,
  GetAllSpotsAvailibilityDataForParkingEntry,
  ParkingManagerService,
  ServiceConfig,
  SortOrder,
} from "parkcash-api";
import React, { useEffect, useState } from "react";
import Resources from "../../Resources";
import ErrorView from "../components/ErrorView";
import Spinner from "../components/Spinner";
import Colors from "../styles/Colors";
import { PCText } from "../styles/Texts";
import { FilterOperator } from "../utils/DataSource";
import { getFilters, getSorts } from "../utils/SieveUtils";

const SpotsPerSectorReport = (props: {
  spots: GetAllSpotsAvailibilityDataForParkingEntry[];
}) => {
  const { spots } = props;

  return (
    <>
      {spots.map((spot, index, arr) => {
        if (spot.sector === arr[index - 1]?.sector) {
          return <React.Fragment key={index}></React.Fragment>;
        }

        const allInSector = arr.filter((i) => i.sector === spot.sector);
        return (
          <div key={index} style={{ marginBottom: 20 }}>
            <PCText color={Colors.brownish_grey} fontSize={17}>
              {Resources.Sektor} {spot.sector} -{" "}
              {allInSector.map((s) => s.number).join(", ")}
            </PCText>
          </div>
        );
      })}
    </>
  );
};

const Report = (props: {
  entries: GetAllSpotsAvailibilityDataForParkingEntry[];
}) => {
  const { entries } = props;
  const spotsWithNoLevelsAndSectors = entries.filter(
    (e) => !e.level && !e.sector
  );
  const spotsWithLevels = entries.filter((e) => !!e.level && !e.sector);
  const spotsWithSectors = entries.filter((e) => !e.level && !!e.sector);
  const spotsWithLevelsAndSectors = entries.filter(
    (e) => !!e.level && !!e.sector
  );

  return (
    <>
      {!!spotsWithNoLevelsAndSectors && !!spotsWithNoLevelsAndSectors.length && (
        <PCText color={Colors.brownish_grey} fontSize={17}>
          {spotsWithNoLevelsAndSectors.map((s) => s.number).join(", ")}
        </PCText>
      )}
      <SpotsPerSectorReport spots={spotsWithSectors} />
      {spotsWithLevels.map((spot, index, arr) => {
        if (spot.level === arr[index - 1]?.level) {
          return <React.Fragment key={index}></React.Fragment>;
        }

        const allInLevel = arr.filter((i) => i.level === spot.level);
        return (
          <div key={index} style={{ marginBottom: 20 }}>
            <PCText color={Colors.brownish_grey} fontSize={17}>
              {Resources.Poziom} {spot.level} -{" "}
              {allInLevel.map((s) => s.number).join(", ")}
            </PCText>
          </div>
        );
      })}

      {spotsWithLevelsAndSectors
        .reduce<string[]>((curr, item) => {
          if (curr.indexOf(item.level) < 0) {
            curr.push(item.level);
          }
          return curr;
        }, [])
        .map((level) => {
          const allSpotsInLevel = spotsWithLevelsAndSectors.filter(
            (s) => s.level === level
          );

          return (
            <div key={level} style={{ marginBottom: 50 }}>
              <PCText fontSize={20} semibold color={Colors.black}>
                {Resources.Poziom} {level}
              </PCText>
              <div style={{ marginTop: 10, marginLeft: 10 }}>
                <SpotsPerSectorReport spots={allSpotsInLevel} />
              </div>
            </div>
          );
        })}
    </>
  );
};

const ListOfVacantSpots = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [entries, setEntries] = useState<
    GetAllSpotsAvailibilityDataForParkingEntry[]
  >([]);
  const jwt = localStorage.token;
  const date = new Date();
  const parkingId = localStorage.parkingId;
  useEffect(() => {
    const getData = async () => {
      try {
        const filter: Array<{
          key: keyof GetAllSpotsAvailibilityDataForParkingEntry;
          operator: FilterOperator;
          value: any;
        }> = [];
        const sort: Array<{
          key: keyof GetAllSpotsAvailibilityDataForParkingEntry;
          order: SortOrder;
        }> = [];

        // filter.push({
        //   key: "status",
        //   operator: FilterOperator.EQUALS,
        //   value: AvailibilityStatus.Free,
        // });
        sort.push({ key: "level", order: SortOrder.Ascending });
        sort.push({ key: "sector", order: SortOrder.Ascending });
        sort.push({ key: "number", order: SortOrder.Ascending });

        const response = await new ParkingManagerService(
          new ServiceConfig({ jwt })
        ).getAllSpotsAvailibilityDataForParking(
          parkingId,
          date,
          getFilters(filter),
          getSorts(sort),
          1,
          10000
        );
        if (response.isSuccess) {
          setEntries(
            response.result.entries.filter(
              (s) => s.status === AvailibilityStatus.Free
            )
          );
        } else {
          setIsError(true);
        }
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && isError && <ErrorView title={Resources.Nastapil_blad} />}
      {!isLoading && !isError && <Report entries={entries} />}
    </>
  );
};

export { ListOfVacantSpots };
