import * as React from "react";
import { connect } from "react-redux";
import { applicationState } from "../../redux/ApplicationState";
import { IParkCashApplicationMode } from "../../utils/ParkCashApplicationMode";
import ManagerGrid from "./ManagerGrid";
import StaffGrid from "./StaffGrid";

interface Props {
  jwt: string;
  applicationMode: IParkCashApplicationMode;
}

const Container = (props: Props) => {
  const { applicationMode, jwt } = props;

  if (applicationMode.isParkingStaff()) {
    return <StaffGrid jwt={jwt} applicationMode={applicationMode} />;
  } else {
    return <ManagerGrid jwt={jwt} applicationMode={applicationMode} />;
  }
};

const mapStateToProps = (state: applicationState): Props => ({
  jwt: state.user.token,
  applicationMode: state.user.applicationMode,
});

export default connect(mapStateToProps)(Container);
