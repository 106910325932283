import React, {useCallback} from 'react';
import {ApexOptions} from 'apexcharts';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Card, {CardProps} from '@mui/material/Card';
import Chart, {useChart} from '../../../../MUI/components/chart';
import {useUser} from "../../../../user/UseUserHook";
import ButtonBase from "@mui/material/ButtonBase";
import Iconify from "../../iconify";
import CustomPopover, {usePopover} from "../../custom-popover";
import MenuItem from "@mui/material/MenuItem";
import {SelectOption} from "../../../../utils/EnumUtils/MapEnumToSelectOptions";

interface Props extends CardProps {
    title?: string;
    subheader?: string;
    dataScopeOptions?: SelectOption[];
    setDataScope?: (value: string) => void;
    selectedDataScope?: string;
    useDataScopeSelectOnChart?: boolean;

    chart: {
        labels: string[]; colors?: string[]; series: {
            name: string;
            type: string;
            yAxisunit?: string;
            separateYAxis?: boolean;
            xAxisunit?: string;
            fill?: string;
            data: number[] | any[],
        }[]; options?: ApexOptions;
    };
}

export default function MultiSeriesChartWithBarAndLines({
                                                            title,
                                                            subheader,
                                                            chart,
                                                            dataScopeOptions,
                                                            setDataScope,
                                                            selectedDataScope,
                                                            useDataScopeSelectOnChart = false,
                                                            ...other
                                                        }: Props) {
    const {
        labels,
        colors,
        series,
        options
    } = chart;
    const userLanguage = useUser().language;

    const chartOptions = useChart({
        colors,
        plotOptions: {
            bar: {
                columnWidth: '50%',
            },
        },
        fill: {
            type: series.map((i) => i.fill) as string[],
        },
        labels,
        xaxis: {
            type: 'datetime',
            labels: {
                rotate: -45,
                formatter: (value) => {
                    const date = new Date(value);
                    const result = userLanguage === 'pl' ? date.toLocaleDateString('pl-PL') : date.toLocaleDateString('en-US',
                        {
                            year: 'numeric',
                            month: 'short',   // This will show month in short form, like 'Jan', 'Feb', etc.
                            day: 'numeric'
                        }
                    );
                    return result;
                }
            }
        }, /*  yaxis: allYaxis,*/
        tooltip: {
            shared: true,
            intersect: false,
            y: series.map((i) => ({
                formatter: (value: number) => {
                    let formattedValue: string = String(value);
                    if (typeof value !== 'undefined') {
                        formattedValue = `${value.toFixed(0)}`;
                    }
                    if (i.yAxisunit) {
                        formattedValue = `${formattedValue} ${i.yAxisunit}`;
                    }
                    return formattedValue;
                },
            })), /*
            x: {
                show: true,
                formatter(val: number, opts?: any): string {
                    const date = new Date(val);
                    // check if date is valid
                    if (date instanceof Date && !isNaN(date.valueOf())) {
                        const date = new Date(val);
                        const dayOfWeek= getDayOfWeek(date.getDay());
                        const resultedDate = userLanguage === 'pl' ? date.toLocaleDateString('pl-PL') : val;
                        // reformat next line to move  dayOfWeek to new line
                        const result=`${resultedDate} \n ${dayOfWeek}`;
                        return  result;
                    }
                    return String(val);
                }
            }
*/
        }, ...options,
    });
    const popover = usePopover();
    const selectedDataScopeLabel = dataScopeOptions?.find((option) => option?.value === selectedDataScope)?.label;
    const handleChangeSeries = useCallback((newValue: string) => {
        popover.onClose();
        setDataScope(newValue);
    }, [popover]);

    return (<Card {...other} sx={{height: '100%'}} id={"CardContainer"}>
        <CardHeader title={title} subheader={subheader}
                    action={useDataScopeSelectOnChart ? <ButtonBase
                        onClick={popover.onOpen}
                        sx={{
                            pl: 1,
                            py: 0.5,
                            pr: 0.5,
                            borderRadius: 1,
                            typography: 'subtitle2',
                            bgcolor: 'background.neutral',
                        }}
                    >
                        {selectedDataScopeLabel}

                        <Iconify
                            width={16}
                            icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                            sx={{ml: 0.5}}
                        />
                    </ButtonBase> : null}
        />

        <Box sx={{
            p: 3,
            pb: 1
        }}>
            <Chart dir="ltr" type="line" series={series} options={chartOptions} height={364}/>
        </Box>
        <CustomPopover open={popover.open} onClose={popover.onClose} sx={{width: 140}}>
            {dataScopeOptions?.map((option) => (<MenuItem
                key={option.label}
                selected={option.label === selectedDataScopeLabel}
                onClick={() => handleChangeSeries(option.value)}
            >
                {option.label}
            </MenuItem>))}
        </CustomPopover>
    </Card>);
}
