import React from "react";
import Colors from "../../styles/Colors";
import { PCText } from "../../styles/Texts";
import Input, { InputProps } from "./Input";

export interface RegularInputProps extends InputProps {
  error: string;
  touched: boolean;
  value: string;
  name: string;

  setFieldValue: (key: string, value) => void;
  setFieldTouched: (key: string, touched: boolean) => void;
  autoTrim?: boolean;
  transformText?: (t: string) => string;
}

export default class RegularInput extends React.Component<RegularInputProps> {
  private input: Input;

  constructor(props) {
    super(props);
  }

  focus() {
    this.input && this.input.focus();
  }

  blur() {
    this.input && this.input.blur();
  }

  render() {
    const {
      showValidatedButton,
      error,
      touched,
      value,
      name,
      setFieldValue,
      setFieldTouched,
      onChangeText,
      onBlur,
      autoTrim = false,
      onFocus,
      transformText,
      ...restProps
    } = this.props;
    const isError = error && touched;
    //console.log('isError', isError)

    return (
      <>
        <Input
          {...restProps}
          ref={(i) => (this.input = i)}
          value={value}
          onChangeText={(text) => {
            let targetText = text;
            if (transformText) {
              targetText = transformText(targetText);
            }
            if (autoTrim) {
              targetText = targetText.trim();
            }
            setFieldValue(name, targetText);
            onChangeText && onChangeText(targetText);
          }}
          onBlur={(e) => {
            setFieldTouched(name, true);
            onBlur && onBlur(e);
          }}
          onFocus={(e) => {
            setFieldTouched(name, false);
            onFocus && onFocus(e);
          }}
          isInvalid={isError}
          showValidatedButton={showValidatedButton && touched && !error}
        />
        <div
          style={{
            paddingTop: 4,
            paddingBottom: 4,
            height: 20,
            boxSizing: "border-box",
          }}
        >
          <PCText
            fontSize={12}
            lineHeight={1}
            letterSpacing={0}
            color={Colors.red}
          >
            {isError ? error : ""}
          </PCText>
        </div>
      </>
    );
  }
}
