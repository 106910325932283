import * as React from "react";
import {useSelector} from "react-redux";
import {applicationState} from "../redux/ApplicationState";
import {supportedLanguages} from "../../app.json";
import DropdownList from "../components/DropdownList";
import Resources from "../../Resources";
import LocalStorageManager from "../utils/LocalStorageManager";

export default () => {
    const {language} = useSelector<applicationState, { language: string }>(s => ({language: s.user.language}));
    return (
        <DropdownList
            label={Resources.Wybierz_jezyk}
            value={language}
            actions={supportedLanguages.map(l => ({id: l, text: l}))}
            onChange={l => {
                LocalStorageManager.SetCurrentLang(l);
                window.location.reload()
            }}
        />
    )
}