import { FormikHelpers } from "formik";
import { GetParkingUsersViewResultEntryDto, ModifyParkingMemberDto, ParkingManagerService, ParkingMemberType, ServiceConfig } from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import RegularDateTimePicker from "../../components/forms/RegularDateTimePicker";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import ModalForm from "../../components/ModalForm";
import Colors from "../../styles/Colors";
import {getErrorMessage, getErrorMessageNew} from "../../utils/ErrorUtils";
import { useJWT } from "../../utils/JWTUtils";
import { getText } from "./Utils";
import {SwitchWithLabel} from "../../components/Switch";
import {nameof} from "../../utils/NameOf";

interface FormState {
    memberType: ParkingMemberType,
    validFrom: Date
    validTo: Date,
    allowFreeBooking: boolean
    isUnrestrictedAccess: boolean
}   

const driver = require("../../../assets/images/driver.svg");
const now = new Date();

export default (props: {
    user: GetParkingUsersViewResultEntryDto;
    visible: boolean,
    onClose: () => void;
    onSubmitted: () => void;
    parkingId: string;
}) => {
    const {onClose, parkingId, onSubmitted, user, visible} = props;
    const jwt = useJWT();
    const initialValues = React.useMemo<FormState>(() => {
        if(!user){
            return {validFrom:null, validTo: null, memberType: ParkingMemberType.User, allowFreeBooking:false, isUnrestrictedAccess:true}
        }
        else{
            let isUnrestrictedAccess = user.membershipValidFrom == null && user.membershipValidTo == null;
            return {validFrom:user.membershipValidFrom, validTo: user.membershipValidTo, memberType: user.memberType, allowFreeBooking: user.allowFreeBooking, isUnrestrictedAccess: isUnrestrictedAccess};
        }
    }, [user]);

    const onSubmit = async ({memberType, validFrom, validTo, allowFreeBooking}: FormState, {setStatus, setSubmitting}: FormikHelpers<FormState>) => {
        try{
            setSubmitting(true);
            const {isSuccess, error} = await new ParkingManagerService(new ServiceConfig({jwt})).modifyParkingMember(new ModifyParkingMemberDto({
                parkingId,
                userId: user.userId,
                parkingMemberType: memberType,
                membershipValidFrom: validFrom || undefined,
                membershipValidTo: validTo || undefined,
                allowFreeBooking: allowFreeBooking
            }))
            if(isSuccess){
                onSubmitted();
            }
            else{
                setStatus(getErrorMessageNew(error));
            }
        }
        catch{
            setStatus(getErrorMessage());
        }
        finally{
            setSubmitting(false);
        }
    }

    return (
        <ModalForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            icon={driver}
            iconHeight={110}
            iconWidth={110}
            visible={visible}
            onClose={onClose}
            title={Resources.Edytuj_uzytkownika}
            showCancelButton
            enableReinitialize
        >
            {({values, errors, touched, setFieldTouched, setFieldValue}) => {
                return (
                    <>
                        
                        <RegularDropdownList 
                            label={Resources.Rola}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            value={values.memberType}
                            touched={touched.memberType}
                            error={errors.memberType}
                            name={"memberType"}
                            actions={[
                                {
                                    id: ParkingMemberType.User,
                                    text: getText(ParkingMemberType.User)
                                },
                                {
                                    id: ParkingMemberType.Manager,
                                    text: getText(ParkingMemberType.Manager)
                                },
                                {
                                    id: ParkingMemberType.Staff,
                                    text: getText(ParkingMemberType.Staff)
                                },
                                {
                                    id: ParkingMemberType.Moderator,
                                    text: getText(ParkingMemberType.Moderator)
                                },
                            ]} 
                            borderColor={Colors.brownish_grey}
                        />
                        <SwitchWithLabel
                            value={values.isUnrestrictedAccess}
                            onChange={v => {
                                setFieldValue(nameof<FormState>("isUnrestrictedAccess"), v);
                                if (v) {
                                    setFieldValue(nameof<FormState>("validFrom"), undefined);
                                    setFieldValue(nameof<FormState>("validTo"), undefined);
                                }
                            }}
                            label={Resources.Nieograniczony_dostep}
                        />
                        {!values.isUnrestrictedAccess && (<>
                        <RegularDateTimePicker
                            error={errors.validFrom}
                            touched={touched.validFrom}
                            value={values.validFrom}
                            name="validFrom"
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            label={Resources.Waznosc_od}
                            min={now}
                        />
                        <RegularDateTimePicker 
                            error={errors.validTo}
                            touched={touched.validTo}
                            value={values.validTo}
                            name="validTo"
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            label={Resources.Waznosc_do}
                            min={now}
                        /> </>)}
                        <SwitchWithLabel
                            value={values.allowFreeBooking}
                            onChange={v => setFieldValue(nameof<FormState>("allowFreeBooking"), v)}
                            label={Resources.Darmowa_rezerwacja}
                        />
                    </>
                )
            }}
        </ModalForm>
    )
}