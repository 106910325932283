import { FormikProps } from "formik";
import Enumerable from "linq";
import moment from "moment";
import {
  AvailibilityStatus,
  GetParkingSubscriptionsResultEntryDto,
  ParkingSpotsAvailibilityEntry,
  SpotSelectorType,
  SubscriptionPeriodType,
} from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import ChoosePlaceFromMap from "../../components/ChoosePlaceFromMap";
import { BigFormLabel } from "../../components/FormLabel";
import RegularDateTimePicker from "../../components/forms/RegularDateTimePicker";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { getTime } from "../../utils/DateTimeUtils";
import { getErrorMessage } from "../../utils/ErrorUtils";
import Notify from "../../utils/Notify";
import AcceptRegulations from "../formParts/AcceptRegulations";
import BigForm from "../formParts/BigForm";
import InvoiceFormPart from "../formParts/InvoiceFormPart";
import TargetFormPart from "../formParts/TargetFormPart";
import {
  getAvailableNumberOfPeriods,
  getAvailableSpotsForSubscription,
  getMonths,
  getSubscriptionEffectivePrice,
  getTimeRangeForSubscription,
} from "../SubscriptionsUtils";
import CreateSubscriptionFormState from "./CreateSuscriptionFormState";

export default (props: {
  isMobile: boolean;
  parkingId: string;
  args: FormikProps<CreateSubscriptionFormState>;
  availableSubscriptions: GetParkingSubscriptionsResultEntryDto[];
  salesRegulationUrl: string;
  privacyPolicyRegulationUrl: string;
  generalParkingRegulationUrl: string;
  onPhoneBlur: () => void;
}) => {
  const {
    isMobile,
    args,
    parkingId,
    availableSubscriptions,
    generalParkingRegulationUrl,
    onPhoneBlur,
    privacyPolicyRegulationUrl,
    salesRegulationUrl,
  } = props;
  const { values, setFieldValue, setFieldTouched, errors, touched } = args;

  const subscription = Enumerable.from(availableSubscriptions).firstOrDefault(
    (s) => s.id === values.subscriptionId
  );
  const currentDate = getTime(new Date(), 0, 0);
  const fromMax = !!subscription?.maxAllowedForwardPurchase
    ? moment(currentDate)
        .add(moment.duration(subscription.maxAllowedForwardPurchase))
        .toDate()
    : undefined;

  React.useEffect(() => {
    const checkPrice = async (
      subscriptionId: string,
      discountCode: string,
      spotId: string,
      numberOfPeriods: number
    ) => {
      const hasPrice =
        !!values.subscriptionId &&
        (!values.enableParticularSpotPurchase ||
          subscription?.periodType === SubscriptionPeriodType.NumberOfDays ||
          !!values.spotId);
      if (hasPrice) {
        setFieldValue("promotionCodeProgress", true);

        const { discountedPrice, price, message } =
          await getSubscriptionEffectivePrice(
            subscriptionId,
            discountCode,
            spotId,
            numberOfPeriods
          );
        if (message) {
          Notify.Error(message);
          setFieldValue("discountCode", "");
        } else {
          if (discountCode) {
            setFieldValue("currentPrice", price);
            setFieldValue("discountCodePrice", discountedPrice);
          } else {
            setFieldValue("currentPrice", price);
            setFieldValue("discountCodePrice", null);
          }
        }
        setFieldValue("promotionCodeProgress", false);
      } else {
        setFieldValue("currentPrice", null);
        setFieldValue("discountCodePrice", null);
      }
    };

    checkPrice(
      values.subscriptionId,
      values.discountCode,
      values.spotId,
      values.numberOfPeriods
    );
  }, [
    values.subscriptionId,
    values.spotId,
    values.discountCode,
    values.enableParticularSpotPurchase,
    subscription?.periodType,
    values.numberOfPeriods,
  ]);

  React.useEffect(() => {
    const getTimeRange = async (pId: string, nOP: number, fr: Date) => {
      setFieldValue("targetFrom", null);
      setFieldValue("targetTo", null);
      const { error, from, to } = await getTimeRangeForSubscription(
        pId,
        nOP,
        fr
      );
      if (error) {
        Notify.Error(getErrorMessage(error));
      } else {
        setFieldValue("targetFrom", from);
        setFieldValue("targetTo", to);
      }
    };

    getTimeRange(values.subscriptionId, values.numberOfPeriods, values.from);
  }, [values.numberOfPeriods, values.subscriptionId, values.from]);

  return (
    <BigForm isMobile={isMobile} title={Resources.Dane_abonamentu}>
      <div
        style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}
      >
        <div style={{ flex: 1, width: isMobile ? "100%" : undefined }}>
          <BigFormLabel>
            {subscription.periodType ===
            SubscriptionPeriodType.GivenFullCalendarMonth
              ? Resources.Miesiac_rozpoczecia_abonamentu
              : Resources.Dzien_rozpoczecia_abonamentu}
          </BigFormLabel>
          <BaseSpaceSeparator size={15} />
          {subscription.periodType ===
            SubscriptionPeriodType.GivenFullCalendarMonth && (
            <RegularDropdownList<Date>
              zIndex={200}
              borderColor={Colors.brownish_grey}
              value={values.from}
              error={errors.from as any}
              touched={touched.from as any}
              //label={Resources.Miesiac}
              name="from"
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              actions={getMonths()}
              maxContentHeight={300}
              comparator={(d1, d2) =>
                d1.getFullYear() === d2.getFullYear() &&
                d1.getMonth() === d2.getMonth()
              }
            />
          )}
          {subscription.periodType !==
            SubscriptionPeriodType.GivenFullCalendarMonth && (
            <RegularDateTimePicker
              value={values.from}
              error={errors.from}
              touched={touched.from}
              name="from"
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              mode="date"
              label={Resources.Dzien}
              min={currentDate}
              max={fromMax}
            />
          )}
        </div>
        <BaseSpaceSeparator size={20} />
        <div style={{ flex: 1, width: isMobile ? "100%" : undefined }}>
          <BigFormLabel>
            {Resources.Okres_obowiazywania_abonamentu}
          </BigFormLabel>
          <BaseSpaceSeparator size={15} />
          <RegularDropdownList
            zIndex={100}
            actions={getAvailableNumberOfPeriods(
              subscription.periodType,
              subscription.duration
            )}
            error={errors.numberOfPeriods}
            name="numberOfPeriods"
            touched={touched.numberOfPeriods}
            value={values.numberOfPeriods}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            borderColor={Colors.brownish_grey}
            maxContentHeight={250}
            label={Resources.Okres}
          />
        </div>
      </div>
      <TargetFormPart
        args={args}
        isMobile={isMobile}
        onPhoneBlur={onPhoneBlur}
      />
      <BaseSpaceSeparator size={40} />
      <AcceptRegulations
        args={args}
        parkingRegulationsUrl={generalParkingRegulationUrl}
        privacyPolicyUrl={privacyPolicyRegulationUrl}
        saleRegulationsUrl={salesRegulationUrl}
      />
      <BaseSpaceSeparator size={40} />
      <InvoiceFormPart isMobile={isMobile} args={args} />
    </BigForm>
  );
};
