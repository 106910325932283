import React from "react"
import Resources from "../../../Resources";
import {WidgetStatisticsSection} from "./sections/FinancialReportSections/WidgetStatisticsWithCharts/view";
import {DetailedFinancialChartsSection} from "./sections/FinancialReportSections/DetailedCharts/view";

import {DashboardService, ServiceConfig} from "parkcash-api";
import {useUser} from "../../user/UseUserHook";
import {GetFinancialDashboradDataResult} from "parkcash-api";
import MinimalPageWraperWithDataFetcher
    from "../../MUI/components/AddedOrModyfiedMuiComponents/PageWrapers/MinimalPageWraperWithDataFetcher";

export const FinancialReport = () => {
    const {token: jwt} = useUser();
    const dashBoardService = new DashboardService(new ServiceConfig({jwt}));

    return (<MinimalPageWraperWithDataFetcher
            pageTitle={Resources.Dane_finansowe}
            fetchFunction={(parkingId, start, end, cancelToken) => dashBoardService.getFinancialDashboradData(
                parkingId,
                start,
                end,
                cancelToken
            )}>
            {(data: GetFinancialDashboradDataResult) => (<>
                    <WidgetStatisticsSection data={data}/>
                    <DetailedFinancialChartsSection incomePerDayChartRawData={data.dayByDaySummarySeries}
                                                    typeOfParkingChartRawData={data.typeSummarySeries}/>
                </>)}

        </MinimalPageWraperWithDataFetcher>

    )
}

