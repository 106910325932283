import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";

export default (props: {usersCount: number}) => {
    const {usersCount} = props;
    return (
        <div
            style={{
                width: 57,
                height: 33,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 8,
                borderWidth: usersCount === null ? 0 : 1,
                borderStyle: "solid",
                borderColor: Colors.light_royal_blue,
                backgroundColor: usersCount === null ? "transparent" : Colors.pale_grey_two
            }}
        >
            {usersCount !== null && (
                <>
                    <FontAwesomeIcon icon={faUser} style={{letterSpacing: 0.27, fontSize: 15, color: Colors.brownish_grey}} />
                    <BaseSpaceSeparator size={5} />
                    <PCText color={Colors.light_royal_blue} letterSpacing={0} fontSize={18}>
                        {usersCount}    
                    </PCText> 
                </>
            )}
        </div>
    )
}