import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { ChangeSubscriptionActivationStatusDto, GetParkingSubscriptionsViewResultEntryDto, ModifySubscriptionDiscountCodeDto, ModifySubscriptionDto, ServiceConfig, SubscriptionPeriodType, SubscriptionsService } from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import Resources from "../../../Resources";
import DropdownList from "../../components/DropdownList";
import PCDataGrid from "../../components/PCDataGrid";
import ActionColumn, { ActionIcon } from "../../components/PCDataGrid/ActionColumn";
import StatusPresenter from "../../components/StatusPresenter";
import PCSwitch from "../../components/Switch";
import Body from "../../layouts/Main/Body";
import { applicationState } from "../../redux/ApplicationState";
import { StandardButton } from "../../styles/Buttons";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { MOBILE_WIDTH_THRESHOLD } from "../../utils/Constants";
import { CustomDataSource, FilterOperator, IDataSource } from "../../utils/DataSource";
import { getErrorMessage } from "../../utils/ErrorUtils";
import Notify from "../../utils/Notify";
import { IParkCashApplicationMode } from "../../utils/ParkCashApplicationMode";
import { getFilters, getSorts } from "../../utils/SieveUtils";
import { useWindowSize } from "../../utils/WindowSizeHook";
import EmptyTable from "../EmptyTable";
import CreateSubscriptionForm from "./CreateSubscriptionForm";
import {routePrefix} from "../../../app.json";

const subscription_type = require("../../../assets/images/subscription_type.svg");

interface Props {
    jwt: string,
    applicationMode: IParkCashApplicationMode;
}

const getDataSource = (parkingId: string, jwt: string) => new CustomDataSource<GetParkingSubscriptionsViewResultEntryDto, {jwt: string, parkingId: string}>({
    load: async (loadOptions, additional) => {
        const {filter, page, pageSize, sort} = loadOptions;
        const {jwt, parkingId} = additional;

        const {isSuccess, result, error} = await new SubscriptionsService(new ServiceConfig({jwt})).getParkingSubscriptionsView
        (
            parkingId,
            getFilters(filter),
            getSorts(sort),
            page,
            pageSize
        );

        return isSuccess ? {
            items: result.entries,
            totalItems: result.paginationDto.totalElementsCount
        } : error.code;
    },
    additionalInfo: {jwt, parkingId}
})

const Container = (props: Props) => {
    const {jwt, applicationMode} = props;
    const {isMobile} = useWindowSize();
    const [dataSource, setDataSource] = React.useState<IDataSource<GetParkingSubscriptionsViewResultEntryDto>>(null);
    const grid = React.useRef<PCDataGrid<GetParkingSubscriptionsViewResultEntryDto>>();
    const [status, setStatus] = React.useState<"active" | "notactive" | "all">(null);
    
    const [createSubscriptionFormVisible, setCreateSubscriptionFormVisible] = React.useState(false);
    const [editedItem, setEditedItem] = React.useState<GetParkingSubscriptionsViewResultEntryDto>(null);

    const onCopyPurchaseLink = () => {
        const parkingId = applicationMode.getCurrentParking().parkingId;
        const {host} = window.location;
        navigator.clipboard.writeText(`${host}${routePrefix}/op/choosesubscriptionaction/${parkingId}`);
        Notify.Info(Resources.Skopiowano_do_schowka);
    }

    const onAdd = () => {
        setEditedItem(null);
        setCreateSubscriptionFormVisible(true);
    }

    const onEdit = (i: GetParkingSubscriptionsViewResultEntryDto) => {
        setEditedItem(i);
        setCreateSubscriptionFormVisible(true);
    }

    const onStatusChanged = (s: "active" | "notactive" | "all") => {
        setStatus(s);
        if(s === "all"){
            dataSource.removeFilter("isActive");
        }
        else if(s === "active"){
            dataSource.filter("isActive", FilterOperator.EQUALS, true);
        }
        else{
            dataSource.filter("isActive", FilterOperator.EQUALS, false);
        }
    }

    const changeActivation = async (item: GetParkingSubscriptionsViewResultEntryDto) => {
        try{
            const response = await new SubscriptionsService(new ServiceConfig({jwt})).changeSubscriptionActivationStatus(new ChangeSubscriptionActivationStatusDto({
                isActive: item.isActive,
                subscriptionId: item.id
            }));
            if(response.isSuccess){
                return true;
            }
            else{
                Notify.Error(getErrorMessage(response.error.code));
            }
        }
        catch{
            Notify.Error(getErrorMessage());
            return false;
        }
    }

    React.useEffect(() => {
        setStatus(null);
        const parkingId = applicationMode.getCurrentParking()?.parkingId;
        if(parkingId){
            setDataSource(getDataSource(parkingId, jwt));
        }
        else{
            setDataSource(null);
        }
    }, [applicationMode.getCurrentParking()?.parkingId]);

    return (
        <Body 
            title={Resources.Abonamenty}
            rigth={(
                <div style={{width: isMobile ? '100%' : 300}}>
                    <StandardButton onClick={onCopyPurchaseLink}>{Resources.Skopiuj_adres_zakupu_abonamentu}</StandardButton>
                </div>
            )}
        >
            <CreateSubscriptionForm 
                item={editedItem}
                jwt={jwt}
                onClose={() => setCreateSubscriptionFormVisible(false)}
                onSubmitted={() => {
                    dataSource.reload();
                    setStatus(null);
                    setCreateSubscriptionFormVisible(false);
                }}
                parkingId={applicationMode.getCurrentParking()?.parkingId}
                visible={createSubscriptionFormVisible}
            />
            <div
                style={{
                    marginBottom: 20,
                    display: 'flex',
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "space-between",
                    alignItems: 'center'
                }}
            >
                <div style={{width: isMobile ? '100%' : 180}}>
                    <DropdownList 
                        value={status}
                        onChange={onStatusChanged}
                        actions={[
                            {
                                text: Resources.Aktywne,
                                id: "active"
                            },
                            {
                                text: Resources.Dezaktywowane,
                                id: "notactive"
                            },
                            {
                                text: Resources.Wszystkie,
                                id: "all"
                            }
                        ]}
                        placeholder={Resources.Status}
                    />
                </div>

                <BaseSpaceSeparator size={20} />
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: isMobile ? "column" : "row"
                }}>                    
                    <div style={{width: isMobile ? '100%' : 200}}>
                        <StandardButton onClick={onAdd}>{Resources.Stworz_abonament}</StandardButton>
                    </div>
                </div>
                
            </div>
            <PCDataGrid<GetParkingSubscriptionsViewResultEntryDto> 
                ref={grid}
                dataSource={dataSource}
                columns={[
                    {
                        dataField: "name",
                        label: Resources.Nazwa,
                        width: 250,
                        renderCell: item => <PCText color={Colors.black} fontSize={16} semibold letterSpacing={0}>{item.name}</PCText>
                    },
                    {
                        dataField: "description",
                        label: Resources.Opis,
                        width: 320
                    },
                    {   
                        dataField: "duration",
                        label: Resources.Liczba_dni_obowiazywania,
                        width: 150,
                        renderCell: item => <PCText color={Colors.greyishBrown} fontSize={20} semibold letterSpacing={0}>{item.duration || "-"}</PCText>
                    },
                    {   
                        dataField: "periodType",
                        label: Resources.Typ,
                        width: 150,
                        renderCell: item => <StatusPresenter textColor={Colors.white} text={item.periodType === SubscriptionPeriodType.NumberOfDays ? Resources.Ilosc_dni : item.periodType === SubscriptionPeriodType.CalenendarMonth ? Resources.Pelny_miesiac : Resources.Wybrany_miesiac} color={Colors.light_royal_blue}/>
                    },
                    {
                        dataField: "price",
                        label: Resources.Cena_brutto,
                        width: 150,
                        renderCell: item => <PCText bold fontSize={20} color={Colors.light_royal_blue} letterSpacing={0} textDecoration={!!item.discountedPrice ? "line-through" : undefined}>{item.price} PLN</PCText>
                    },
                    {
                        dataField: "discountedPrice",
                        label: Resources.Cena_promocyjna,
                        width: 150,
                        renderCell: item => item.discountedPrice ? <PCText bold fontSize={20} color={Colors.light_royal_blue} letterSpacing={0}>{item.discountedPrice} PLN</PCText> : null
                    },
                    {
                        label: Resources.Status,
                        dataField: "isActive",
                        width: 110,
                        renderCell: item => <StatusPresenter textColor={Colors.white} text={item.isActive ? Resources.Aktywny : Resources.Nieaktywny} color={item.isActive ? Colors.light_royal_blue : Colors.brownish_grey} />
                    },
                    {
                        label: Resources.Akcje,
                        width: "150",
                        renderCell: item => {
                            return (
                                <ActionColumn>
                                    <ActionIcon iconColor={Colors.light_royal_blue} icon={faEdit} onClick={() => onEdit(item)} title={Resources.Edytuj} />
                                </ActionColumn>
                            )
                        }
                    },
                    {
                        label: Resources.Aktywacja,
                        width: "150",
                        renderCell: item => {
                            return (
                                <PCSwitch 
                                    value={item.isActive} 
                                    onChange={async () => {
                                        item.isActive = !item.isActive;
                                        grid.current.refresh();
                                        const success = await changeActivation(item);
                                        if(!success){
                                            item.isActive = !item.isActive;
                                            grid.current.refresh();
                                        }
                                    }}
                                />
                            )
                        }
                    }
                ]}
                EmptyComponent={() => (
                    <EmptyTable 
                        image={subscription_type}
                        imageHeight={193}
                        imageWidth={220}
                        text={Resources.Nie_stworzyles_jeszcze_abonamentu}
                        onButtonClick={onAdd}
                        buttonText={(
                            <>
                                {Resources.Stworz_abonament}
                            </>
                        )}
                    />
                )}    
            />
        </Body>
    );
}

const mapStateToProps = (state: applicationState): Props => ({
    applicationMode: state.user.applicationMode,
    jwt: state.user.token
});

export default connect(mapStateToProps)(Container);