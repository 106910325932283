import {
  IDataSource,
  DataSourceEvents,
  DataSourceLoadedArgs,
  FilterOperator,
} from "./IDataSource";
import { ErrorCode, SortOrder } from "parkcash-api";

export default abstract class DataSource<T> implements IDataSource<T> {
  protected currentPage: number = null;
  protected currentPageSize: number = null;

  constructor(
    protected currentSort: Array<{ key: keyof T; order: SortOrder }> = [],
    protected currentFilter: Array<{
      key: keyof T;
      value: any;
      operator: FilterOperator;
    }> = []
  ) {}
  
  getPage() {
    return this.currentPage;
  }
  getPageSize() {
    return this.currentPageSize;
  }

  private callbacks: { [key in DataSourceEvents]: Array<(args: any) => void> } =
    {
      loaded: [],
      loadingError: [],
      loadingStarted: [],
    };

  on(event: DataSourceEvents, callback: (args: any) => void) {
    const eventCallbacks = this.callbacks[event];
    const index = eventCallbacks.indexOf(callback);
    if (index < 0) {
      eventCallbacks.push(callback);
    }
  }

  off(event: DataSourceEvents, callback: (args: any) => void) {
    const eventCallbacks = this.callbacks[event];
    const newEventCallbacks = eventCallbacks.filter((c) => c !== callback);
    this.callbacks[event] = newEventCallbacks;
  }

  clearAllEvents() {
    this.callbacks = {
      loaded: [],
      loadingError: [],
      loadingStarted: [],
    };
  }

  reload() {
    this.currentPage = 1;
    this.loadDataCore();
  }

  load(page: number, pageSize: number) {
    this.currentPage = page;
    this.currentPageSize = pageSize;
    this.loadDataCore();
  }

  filter(key: keyof T, operator: FilterOperator, value: any) {
    const newFilter = this.currentFilter.filter((f) => f.key !== key);
    newFilter.push({ key, value, operator });
    this.currentFilter = newFilter;
    this.currentPage = 1;
    this.loadDataCore();
  }

  removeFilter(key: keyof T) {
    const newFilter = this.currentFilter.filter((f) => f.key !== key);
    this.currentFilter = newFilter;
    this.currentPage = 1;
    this.loadDataCore();
  }

  clearFilters() {
    this.currentFilter = [];
    this.currentPage = 1;
    this.loadDataCore();
  }

  addSort(key: keyof T, order: SortOrder) {
    const newSort = this.currentSort.map<{ key: keyof T; order: SortOrder }>(
      (c) => ({ key: c.key, order: c.key === key ? order : c.order })
    );
    const sortIndex = newSort.findIndex((c) => c.key === key);
    if (sortIndex < 0) {
      newSort.push({ key, order });
    }

    this.currentSort = newSort;
    this.currentPage = 1;
    this.loadDataCore();
  }

  removeSort(key: keyof T) {
    const newSort = this.currentSort.filter((s) => s.key !== key);
    this.currentSort = newSort;
    this.currentPage = 1;
    this.loadDataCore();
  }

  clearSorts() {
    this.currentSort = [];
    this.currentPage = 1;
    this.loadDataCore();
  }

  setPage(page: number) {
    this.currentPage = page;
    this.loadDataCore();
  }

  setPageSize(pageSize: number) {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.loadDataCore();
  }

  protected abstract loadDataCore(): void;

  protected onDataSourceLoaded(items: T[], totalItems: number) {
    const totalPages = Math.ceil(totalItems / this.currentPageSize);
    const args: DataSourceLoadedArgs<T> = {
      items,
      totalPages,
      page: this.currentPage,
      pageSize: this.currentPageSize,
      filter: this.currentFilter,
      sort: this.currentSort,
      totalItems,
    };
    this.dispatchEvent("loaded", args);
  }

  protected onDataSourceLoadingStarted() {
    this.dispatchEvent("loadingStarted");
  }

  protected onDataSourceLoadingError(error: ErrorCode) {
    this.dispatchEvent("loadingError", error);
  }

  private dispatchEvent(event: DataSourceEvents, args?: any) {
    this.callbacks[event].forEach((callback) => {
      callback(args);
    });
  }
}
