import {MinimalContextWraper} from "./MUI/components/AddedOrModyfiedMuiComponents/PageWrapers/MinimalContextWrapper";

require("setimmediate"); //webpack 5 do not have polyfill for node setimmediate, have to add this manually
import * as React from "react";
import {Provider} from "react-redux";
import store from "./redux";
import SplashScreen from "./components/SplashScreen";
import Routes from "./Routes";
import ResourceProvider from "./ResourceProvider";
import "./index.scss";
import LocalStorageManager from "./utils/LocalStorageManager";
import {prolongJWT} from "./user/Utils";
import {initUser} from "./user/Redux";


export default () => {
    const [initialized, setInitialized] = React.useState(window.location.href.indexOf("/op/") >= 0);

    const init = async () => {
        if (!initialized) {
            const token = LocalStorageManager.GetUserToken();

            if (token) {
                const jwtProlongation = await prolongJWT(token);
                if (jwtProlongation) {
                    // await new Promise(resolve => {
                    //     store.dispatch(initUser(jwtProlongation.jwt, jwtProlongation.userId, () => resolve()) as any)
                    // });
                    const afterLogin = await new Promise<boolean>(resolve => {
                        store.dispatch(initUser(
                            jwtProlongation.jwt,
                            jwtProlongation.userId,
                            () => resolve(true),
                            () => resolve(false)
                        ) as any);
                    });
                    if (!afterLogin) {
                        LocalStorageManager.ClearAll();
                    }
                } else {
                    LocalStorageManager.ClearAll();
                }
            }

            setInitialized(true);
        }
    }

    React.useEffect(() => {
        init();
    }, []);

    return (<Provider store={store}>
        {initialized ? <App/> : <SplashScreen/>}
    </Provider>);
}

const App = () => {

    return (<MinimalContextWraper>
            <ResourceProvider>
                <Routes/>
            </ResourceProvider>
        </MinimalContextWraper>)

}


