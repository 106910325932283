import { IParkingInfo } from "parkcash-api";
import memoizee from "memoizee";
import { RADIUS } from "./Constants";
import { formatAddress } from "../utils/AddressUtils";
import { searchAddressItem } from "../components/forms/SearchAddressInput";

type searchType = "searchingInParking" | "searchingInSearchedLocation" | "searchingInMapPosition";


const getAddress = memoizee((lat: number, lon: number) => {
    return {streetName: "", city: "", streetNumber: ""}; 
    //GoogleMapsApiManager.GetAddressFromCoordinates(lat, lon);
});

export default class SearchTarget {

    private searchedLocation: searchAddressItem;

    private parking: IParkingInfo;

    private searchType: searchType;

    private lat: number;

    private lon: number;

    private constructor(args: {
        searchedLocation: searchAddressItem;
        parking: IParkingInfo;
        searchType: searchType;
        lat: number;
        lon: number;
    }){
        const { searchedLocation, parking, searchType, lat, lon} = args;

        this.searchedLocation = searchedLocation;
        this.parking = parking;
        this.searchType = searchType;  
        this.lat = lat;
        this.lon = lon;
    }

    static CreateForSearchingInParking(parking: IParkingInfo){
        return new SearchTarget({
            lat: null,
            lon: null,
            searchedLocation: null,
            parking,
            searchType: "searchingInParking"
        });
    }

    static CreateForSearchingInSearchedLocation(searchedLocation: searchAddressItem){
        return new SearchTarget({
            lat: null,
            lon: null,
            searchedLocation,
            parking: null,
            searchType: "searchingInSearchedLocation"
        });
    }

    static CreateForSearchingInMapPosition(lat: number, lon: number){
        return new SearchTarget({
            lat,
            lon,
            parking: null,
            searchedLocation: null,
            searchType: "searchingInMapPosition"
        });
    }

    getParkingId(): string {
        return this.parking && this.parking.id;
    }

    async getEffectiveAddress(): Promise<string> {
        if(this.searchType === "searchingInMapPosition"){
            const address = await getAddress(this.lat, this.lon);
            return formatAddress(address, true);
        }
        else if(this.searchType === "searchingInSearchedLocation"){
            const {city,streetName,streetNumber} = this.searchedLocation;
            return formatAddress({city,streetName,streetNumber}, true);
        }
        else if(this.searchType === "searchingInParking"){
            const {address: {city:city,streetName:streetName,streetNumber:streetNumber}} = this.parking;
            return formatAddress({city,streetName,streetNumber}, true);
        }
    }

    async getSearchAddress(): Promise<string> {
        if(this.searchType === "searchingInMapPosition"){
            return "";
        }
        else{
            return await this.getEffectiveAddress();
        }
    }

    getSearchRequest(arriving: Date, leaving: Date) {
        return {
            start: arriving,
            end: leaving,
            radiusInMeters: RADIUS,
            latitude: this.getLatitude(),
            longitude: this.getLongitude()  
        }
    }

    getLatitude(){
        switch(this.searchType){
            case "searchingInMapPosition":
                return this.lat;
            case "searchingInParking":
                return this.parking.address.latitude;
            case "searchingInSearchedLocation":
                return this.searchedLocation.lat;    
        }
    }

    getLongitude(){
        switch(this.searchType){
            case "searchingInMapPosition":
                return this.lon;
            case "searchingInParking":
                return this.parking.address.longitude;
            case "searchingInSearchedLocation":
                return this.searchedLocation.lon;    
        }
    }
}