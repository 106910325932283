import { FormikProps } from "formik";
import * as React from "react";
import Resources from "../../../Resources";
import RegularInput from "../../components/forms/RegularInput";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PHONE_MASK } from "../../utils/Constants";
import { transformLicencePlateNumber } from "../../utils/LicencePlateNumberUtils";
import TargetFormState from "../formInterfaces/TargetFormState";

export default (props: {
  isMobile: boolean;
  args: FormikProps<TargetFormState>;
  onPhoneBlur?: () => void;
}) => {
  const { args, isMobile, onPhoneBlur } = props;
  const { values, errors, touched, setFieldTouched, setFieldValue } = args;

  return (
    <>
      <div style={{ width: isMobile ? "100%" : 200 }}>
        <RegularInput
          label={Resources.Imie_i_nazwisko}
          value={values.nameAndSurname}
          error={errors.nameAndSurname}
          touched={touched.nameAndSurname}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          name="nameAndSurname"
          showValidatedButton
          showClearButton
        />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        {values.licencePlateNumberRequired && (
          <div style={{ width: isMobile ? "100%" : 200 }}>
            <RegularInput
              label={Resources.Nr_rejestracyjny}
              transformText={transformLicencePlateNumber}
              value={values.licencePlateNumber}
              error={errors.licencePlateNumber}
              touched={touched.licencePlateNumber}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              name="licencePlateNumber"
              showValidatedButton
              showClearButton
            />
          </div>
        )}
        {!isMobile && values.licencePlateNumberRequired && (
          <BaseSpaceSeparator size={15} />
        )}
        <div style={{ width: isMobile ? "100%" : 200 }}>
          <RegularInput
            label={Resources.Nr_telefonu}
            value={values.phoneNumber}
            error={errors.phoneNumber}
            touched={touched.phoneNumber}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            name="phoneNumber"
            showValidatedButton
            showClearButton
            mask={PHONE_MASK}
            onBlur={onPhoneBlur}
          />
        </div>
        {!isMobile && <BaseSpaceSeparator size={15} />}
        <div style={{ width: isMobile ? "100%" : 200 }}>
          <RegularInput
            label={Resources.Email}
            value={values.email}
            error={errors.email}
            touched={touched.email}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            name="email"
            showValidatedButton
            showClearButton
          />
        </div>
      </div>
    </>
  );
};
