import * as React from "react";
import {applicationState} from "../../redux/ApplicationState";
import {PCClasses} from "../../utils/CSSUtils";
import {useSelector} from "react-redux";
import Colors from "../../styles/Colors";
import {
    ErrorCode, ResendEmailConfirmationCodeDto, ServiceConfig, UsersService, UserWalletService, UserWalletType
} from "parkcash-api";
import Notify from "../../utils/Notify";
import Resources from "../../../Resources";
import {Link, Redirect} from "react-router-dom";
import {PCText} from "../../styles/Texts";
import {BaseSpaceSeparator} from "../../styles/Separators";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {StandardButton, StandardButton2} from "../../styles/Buttons";
import ChangeBankAccountForm from "../ChangeBankAccountForm";
import ChangePasswordForm from "../ChangePasswordForm";
import ChangeUserInfoForm from "../ChangeUserInfoForm";
import {getClientPhoneNumber} from "../../utils/PhoneNumberUtils";
import {useWindowSize} from "../../utils/WindowSizeHook";
import {getErrorMessage} from "../../utils/ErrorUtils";
import ConfirmModal from "../../components/ConfirmModal";
import GlobalProgress from "../../components/GlobalProgress";
import ChangeProfileIdForm from "../ChangeProfileIdForm";
import {useUser} from "../UseUserHook";

const driver = require("../../../assets/images/driver.svg");
const wallet_profile = require("../../../assets/images/wallet_profile.svg");
const trophy_profile = require("../../../assets/images/trophy_profile.svg");



const BigButton = (props: {
    to: string,
    title: string,
    icon: any,
    iconWidth: number,
    ammount: number,
    unit: string,
    borderTopLeftRadius?: boolean,
    borderTopRightRadius?: boolean,
    borderBottomLeftRadius?: boolean,
    borderBottomRightRadius?: boolean
    
}) => {
    const {icon, title, to, ammount,unit,iconWidth, borderBottomLeftRadius,borderBottomRightRadius,borderTopLeftRadius,borderTopRightRadius} = props;

    return (
        <Link
            to={to}
            className={PCClasses("pc-button")}
            style={{
                display: "flex", 
                flexDirection: "column", 
                height: 212, 
                flex: 1,
                alignItems: 'center',
                outline: "none",
                textDecoration: "none",
                backgroundImage: "linear-gradient(to bottom, rgba(31, 50, 169, 0), rgba(31, 50, 169, 1))",
                borderBottomLeftRadius: borderBottomLeftRadius ? 25 : 0,
                borderBottomRightRadius: borderBottomRightRadius ? 25 : 0,
                borderTopLeftRadius: borderTopLeftRadius ? 25 : 0,
                borderTopRightRadius: borderTopRightRadius ? 25 : 0
            }}
        >
            <BaseSpaceSeparator size={20} />
            <img src={icon} width={iconWidth} height={60} />
            <BaseSpaceSeparator size={20} />
            <PCText color={Colors.white} textAlign="center" letterSpacing={0} semibold fontSize={16}>{title}:</PCText>
            <BaseSpaceSeparator size={2} />
            <PCText color={Colors.white} textAlign="center" semibold fontSize={27}>{ammount || "-"} {unit}</PCText>
        </Link>
    )
}

const UserInfoStatus = (props: {status?: "verified" | "unverified" | "verificationpending", onVerify?: () => void}) => {
    return (
        <div 
            onClick={props.status === "unverified" ? props.onVerify : undefined} 
            className={PCClasses(null, {"pc-button": props.status === "unverified"})} 
            style={{
                height: 18, 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                paddingLeft: 10, 
                paddingRight: 10,
                borderRadius: 9,
                border: props.status === "verificationpending" ? `1px dashed ${Colors.brownish_grey}` : undefined,
                backgroundColor: props.status === "unverified" ? Colors.brownish_grey : props.status === "verified" ? Colors.light_royal_blue : Colors.white,
                boxShadow: "0 3px 26px 4px rgba(0, 0, 0, 0.08);"
            }}>
            <PCText semibold fontSize={12} color={props.status === "verificationpending" ? Colors.brownish_grey : Colors.white}>
                {props.status === "unverified" && Resources.Zweryfikuj}
                {props.status === "verificationpending" && Resources.W_trakcie}
                {props.status === "verified" && Resources.Zweryfikowane}
            </PCText>
        </div>
    )
}

const ProfileItem = (props: {
    label: string,
    value: string,
    onClick?: () => void,
    status?: "verified" | "unverified" | "verificationpending",
    onVerify?: () => void
}) => {
    const {label, onClick, value, onVerify, status} = props;

    return (
        <div
            onClick={onClick}
            className={PCClasses([], {"pc-button": !!onClick})}
            style={{
                paddingTop: 5,
                paddingBottom: 5,
                borderBottomStyle: "solid",
                borderBottomColor: Colors.very_light_pink,
                borderBottomWidth: 1,
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <div>
                <PCText color={Colors.brownish_grey} fontSize={12} lineHeight={20/12}>{label}</PCText>
                <PCText color={Colors.black} fontSize={16} lineHeight={24/16}>{value}</PCText>
            </div>
            <div style={{flex: 1}} />
            {!!status && (
                <>
                <UserInfoStatus status={status} onVerify={onVerify} />
                <BaseSpaceSeparator size={10} />
                </>
            )}
            {!!onClick && <FontAwesomeIcon icon={faChevronRight} color={Colors.heliotrope} fontSize={24}/>}
        </div>
    );
}

interface Props {
    name: string,
    surname: string,
    email: string,
    phoneNumber: string,
    bankAccount: string,
    jwt: string,
    profileId: string,
    emailConfirmed: boolean,
    userId: string
}

export default () => {
    const {windowWidth} = useWindowSize();
    const {bankAccount, surname,name,jwt,email,phoneNumber, profileId, emailConfirmed, userId} = useSelector<applicationState, Props>(s => ({
        profileId: s.user.profileId, 
        bankAccount: s.user.bankAccountNumber, 
        email: s.user.email, 
        jwt: s.user.token, 
        phoneNumber: s.user.phoneNumber, 
        name: s.user.firstName, 
        surname: s.user.lastName,
        emailConfirmed: s.user.emailConfirmed,
        userId: s.user.userId
    }))
    const [pointsSummary, setPointsSummary] = React.useState<number>(null);
    const [moneySummary, setMoneySummary] = React.useState<number>(null);
    const [bankAccountFormVisible, setBankAccountFormVisible] = React.useState(false);
    const [profileIdFormVisible, setProfileIdFormVisible] = React.useState(false);
    const [changePasswordFormVisible, setChangePasswordFormVisible] = React.useState(false);
    const [userInfoFormVisible, setUserInfoFormVisible] = React.useState(false);
    const [globalProgress, setGlobalProgress] = React.useState(false);
    const [accountRemoved, setAccountRemoved] = React.useState(false);
    const isSmallDevice = windowWidth < 1000;
    const {hideWallet, hideProfileId} = useUser();

    React.useEffect(() => {
        const init = async () => {
            try{
                const [pointsResponse, moneyResponse] = await Promise.all([ new UserWalletService(new ServiceConfig({jwt})).getSummary(UserWalletType.Points), new UserWalletService(new ServiceConfig({jwt})).getSummary(UserWalletType.Money) ]);
                if(pointsResponse.isSuccess && moneyResponse.isSuccess){
                    setPointsSummary(pointsResponse.result.totalBalance);
                    setMoneySummary(moneyResponse.result.totalBalance);
                }
                else if(!pointsResponse.isSuccess){
                    Notify.Error(getErrorMessage(pointsResponse.error.code));
                }
                else if(!moneyResponse.isSuccess){
                    Notify.Error(getErrorMessage(moneyResponse.error.code));
                }
            }
            catch{
                Notify.Error(getErrorMessage(ErrorCode.NetworkError));
            }           
        }
        init();
    }, []);

    const onBankAccountEdit = () => {
        setBankAccountFormVisible(true);
    }

    const onProfileEdit = () => {
        setProfileIdFormVisible(true);
    }

    const onChangePassword = () => {
        setChangePasswordFormVisible(true);
    }

    const onEditProfile = () => {
        setUserInfoFormVisible(true);
    }

    const onRemoveAccount = async () => {
        const confirm = await ConfirmModal.show({text: Resources.Czy_na_pewno_chcesz_usunac_konto, confirmText: Resources.Usun});
        if(confirm){
            try{
                setGlobalProgress(true);
                const {isSuccess, error} = await new UsersService(new ServiceConfig({jwt})).closeAccount();
                if(isSuccess){
                    Notify.Success(Resources.Konto_usuniete)
                    setAccountRemoved(true);
                }
                else{
                    Notify.Error(getErrorMessage(error.code));
                }
            }
            catch{
                Notify.Error(getErrorMessage());
            }
            finally{
                setGlobalProgress(false);
            }
        }
    }

    const onEmailVerify = async () => {
        const response = await new UsersService(new ServiceConfig({jwt})).resendEmailConfirmationCode(new ResendEmailConfirmationCodeDto({
            userId
        }));
        console.log('response: ', response);
        ConfirmModal.show({
            confirmText: "Ok",
            text: Resources.Na_twoja_skrzynke_zostal_wyslany_link_potwierdzajacy_W_celu_potwierdzenia_adresu_email_kliknij_go
        })
    }

    if(accountRemoved){
        return <Redirect to="/logout" />
    }

    return (
        <div 
            className={PCClasses("pc-profileuserinfo-container")}
            style={{
                display: 'flex',
                borderRadius: 25,
                backgroundColor: Colors.white,
                boxShadow: "0 3px 26px 4px rgba(0, 0, 0, 0.08)",
                flexDirection: isSmallDevice ? "column" : "row"            
            }}
        >
            <GlobalProgress visible={globalProgress} />
            <ChangeBankAccountForm 
                onClose={() => setBankAccountFormVisible(false)}
                visible={bankAccountFormVisible}
            />
            <ChangeProfileIdForm 
                onClose={() => setProfileIdFormVisible(false)}
                visible={profileIdFormVisible}
            />
            <ChangePasswordForm 
                onClose={() => setChangePasswordFormVisible(false)}
                visible={changePasswordFormVisible}
            />
            <ChangeUserInfoForm 
                onClose={() => setUserInfoFormVisible(false)}
                visible={userInfoFormVisible}
            />
            <div 
                style={{
                    flex: isSmallDevice ? undefined : 570, 
                    backgroundColor: Colors.light_royal_blue, 
                    borderTopLeftRadius: 25, 
                    borderTopRightRadius: isSmallDevice ? 25 : undefined,
                    borderBottomLeftRadius: isSmallDevice ? undefined : 25, 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                {!isSmallDevice && (
                    <>
                        <BaseSpaceSeparator size={50} />
                        <div 
                            style={{
                                height: 194, 
                                width: 194, 
                                backgroundColor: Colors.white, 
                                borderRadius: 97,
                                flexDirection: "column",
                                justifyContent: 'flex-end',
                                display: 'flex',
                                alignItems: 'center',
                                overflow: 'hidden'
                            }}
                        >
                            <img src={driver} width={160} height={160} />
                        </div>
                        <BaseSpaceSeparator size={20} />
                        <PCText textAlign="center" fontSize={34} semibold color={Colors.white}>ParkCash</PCText>
                        <div style={{flex: 1}} />
                    </>
                )}
                <div 
                    style={{
                        display: 'flex', 
                        alignItems: 'center', 
                        width: '100%'
                    }}
                >
                    {!hideWallet && <BigButton 
                        ammount={moneySummary}
                        unit={Resources.zl}
                        title={Resources.Zarobiles_juz}
                        to={`/wallet/${UserWalletType.Money}`}
                        icon={wallet_profile}
                        iconWidth={57}
                        borderBottomLeftRadius={!isSmallDevice}
                    />}
                    {!hideWallet && <div style={{height: 212, width: 2, backgroundColor: Colors.light_royal_blue}}/>}
                    {!hideWallet && <BigButton 
                        ammount={pointsSummary}
                        unit={Resources.pkt}
                        title={Resources.Twoje_punkty}
                        to={`/wallet/${UserWalletType.Points}`}
                        icon={trophy_profile}
                        iconWidth={43}
                        borderTopRightRadius={isSmallDevice}
                    />}
                </div>
            </div>
            <div style={{flex: 470, display: 'flex', flexDirection: 'column', paddingRight: 35, paddingLeft: 35, paddingTop: 65, paddingBottom: 25}}>
                <ProfileItem 
                    label={Resources.Imie_i_nazwisko}
                    value={`${name} ${surname}`}
                />
                <ProfileItem 
                    label={Resources.Email}
                    value={email}
                    status={emailConfirmed ? "verified" : "unverified"}
                    onVerify={onEmailVerify}
                />
                <ProfileItem 
                    label={Resources.Nr_telefonu}
                    value={getClientPhoneNumber(phoneNumber) || "-"}
                />
                {!hideWallet && <ProfileItem 
                    label={Resources.Konto_do_wyplat}
                    value={bankAccount ? `xx xxxx xxxx xxxx xxxx xxxx ${bankAccount.substr(bankAccount.length - 4)}` : "-"}
                    onClick={onBankAccountEdit}
                />}
                {!hideProfileId && <ProfileItem 
                    label={Resources.Identyfikator_profilu}
                    value={profileId ||  "-"}
                    onClick={onProfileEdit}
                />}
                <BaseSpaceSeparator size={20} />
                <div style={{width: 140}}>
                    <StandardButton2 onClick={onRemoveAccount}>{Resources.Usun_konto}</StandardButton2>
                </div>
                <div style={{flex: 1}}/>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{width: 140}}>
                        <StandardButton2 onClick={onChangePassword}>{Resources.Zmien_haslo}</StandardButton2>
                    </div>
                    <div style={{flex: 1}} />
                    <div style={{width: 140}}>
                        <StandardButton onClick={onEditProfile}>{Resources.Edytuj_profil}</StandardButton>
                    </div>
                </div>
            </div>         
        </div>
    );
}