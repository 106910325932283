import {faPencilAlt, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {
    ChangeVirtualPilotSharingPossibilityDto,
    GetParkingMobilePilotsViewResultEntryDto,
    ManagerUnassignPilotFromUserDto,
    ParkingManagerService,
    ServiceConfig,
    VirtualPilotsService,
    VirtualPilotStatus
} from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import BooleanPresenter from "../../components/BooleanPresenter";
import ConfirmModal from "../../components/ConfirmModal";
import PCDataGrid from "../../components/PCDataGrid";
import ActionColumn, {ActionIcon, MoreDropdown} from "../../components/PCDataGrid/ActionColumn";
import StatusPresenter from "../../components/StatusPresenter";
import {SubBody} from "../../layouts/Main/Body";
import {StandardButton} from "../../styles/Buttons";
import Colors from "../../styles/Colors";
import {BaseSpaceSeparator} from "../../styles/Separators";
import {PCText} from "../../styles/Texts";
import {CustomDataSource, FilterOperator, IDataSource} from "../../utils/DataSource";
import {DateFormat, DayOfWeekFormatType, formatDateTime} from "../../utils/DateTimeUtils";
import Notify from "../../utils/Notify";
import {IParkCashApplicationMode} from "../../utils/ParkCashApplicationMode";
import {getFilters, getSorts} from "../../utils/SieveUtils";
import ChangePilotAccessForm from "./ChangePilotAccessForm";
import GiveAccessToPilotForm from "./GiveAccessToPilotForm";
import {CustomizedSelect} from "../../MUI/components/AddedOrModyfiedMuiComponents/CustomizedInputs/CustomizedSelect";
import {useWindowSize} from "../../utils/WindowSizeHook";
import {MOBILE_WIDTH_THRESHOLD} from "../../utils/Constants";

export default (props: {
    jwt: string; applicationMode: IParkCashApplicationMode; pilots: { text: string, id: string }[]
}) => {
    const {
        applicationMode,
        jwt,
        pilots
    } = props;
    const [status, setStatus] = React.useState<string>("all");
    const [pilot, setPilot] = React.useState<string>("all");
    const [giveAccessToPilotFormVisible, setGiveAccessToPilotFormVisible] = React.useState(false);
    const [changePilotAccessFormVisible, setChangePilotAccessFormVisible] = React.useState(false);
    const [pilotsGridDataSource, setPilotsGridDataSource] = React.useState<IDataSource<GetParkingMobilePilotsViewResultEntryDto>>(
        null);
    const [editedEntry, setEditedEntry] = React.useState<GetParkingMobilePilotsViewResultEntryDto>(null);
    const [dataGridKey, setDataGridKey] = React.useState(0);
    const parkingId = applicationMode?.getCurrentParking()?.parkingId;
    let accessGrid: PCDataGrid<GetParkingMobilePilotsViewResultEntryDto> = null
    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;

    const onGiveAccess = () => {
        setGiveAccessToPilotFormVisible(true);
    }

    React.useEffect(() => {
        if (parkingId) {
            setPilotsGridDataSource(getPilotsGridDataSource());
            setDataGridKey(prevState => prevState + 1)
        }
    }, [parkingId]);

    const resetFilters = () => {
        setStatus("all");
        setPilot("all");
    }
    const getPilotsGridDataSource = React.useCallback(
        () => {
            resetFilters(); // reset filters when new data source is created eg. after parking change
            return new CustomDataSource<GetParkingMobilePilotsViewResultEntryDto, { jwt: string, parkingId: string }>({
                load: async (loadOptions, additional) => {
                    const {
                        filter,
                        page,
                        pageSize,
                        sort
                    } = loadOptions;
                    const {
                        jwt,
                        parkingId
                    } = additional;

                    const {
                        isSuccess,
                        error,
                        result
                    } = await new ParkingManagerService(new ServiceConfig({jwt})).getParkingMobilePilotsView(parkingId,
                        getFilters(filter),
                        getSorts(sort),
                        page,
                        pageSize
                    );

                    return isSuccess ? {
                        items: result.entries,
                        totalItems: result.paginationDto.totalElementsCount
                    } : error.code;
                },
                additionalInfo: {
                    jwt,
                    parkingId
                }
            });
        },
        [
            jwt,
            parkingId
        ]
    );

    const onPilotFilter = (v: string) => {
        setPilot(v);
        v !== "all" ? pilotsGridDataSource.filter("pilotId",
            FilterOperator.EQUALS,
            v
        ) : pilotsGridDataSource.removeFilter("pilotId");
    }

    const onStatusFilter = (v: string) => {
        setStatus(v);
        v !== "all" ? pilotsGridDataSource.filter("status",
            FilterOperator.EQUALS,
            v
        ) : pilotsGridDataSource.removeFilter("status");
    }

    const onEdit = (item: GetParkingMobilePilotsViewResultEntryDto) => {
        setEditedEntry(item);
        setChangePilotAccessFormVisible(true);
    }

    const onRemovePilotAccess = async (item: GetParkingMobilePilotsViewResultEntryDto) => {
        const confirm = await ConfirmModal.show({
            text: `${Resources.Czy_na_pewno_chcesz_usunac_dostep_do_pilota} ${item.name} ${Resources.dla_uzytkownika} ${item.ownerFirstName} ${item.ownerLastName}?`,
            confirmText: Resources.Usun
        });

        if (confirm) {
            try {
                const {isSuccess} = await new ParkingManagerService(new ServiceConfig({jwt})).unassignPilotFromUser(item.pilotId,
                    new ManagerUnassignPilotFromUserDto({
                        userId: item.ownerId,
                        virtualPilotId: item.pilotId
                    })
                );
                if (isSuccess) {
                    pilotsGridDataSource.reload();
                } else {
                    Notify.Error(Resources.Nastapil_blad);
                }
            } catch {
                Notify.Error(Resources.Nastapil_blad);
            }
        }
    }

    const changeCanShare = async (item: GetParkingMobilePilotsViewResultEntryDto) => {
        try {
            const {
                ownerId,
                pilotId,
                canShare
            } = item;
            const {isSuccess} = await new VirtualPilotsService(new ServiceConfig({jwt})).changeVirtualPilotSharingPossibility(pilotId,
                new ChangeVirtualPilotSharingPossibilityDto({
                    canShare: !canShare,
                    targetUserId: ownerId
                })
            );
            if (isSuccess) {
                item.canShare = !canShare;
                accessGrid?.refresh();
            } else {
                Notify.Error(Resources.Nastapil_blad);
            }
        } catch {
            Notify.Error(Resources.Nastapil_blad);
        }
    }

    return (<SubBody
        title={Resources.Zarzadzanie_mobilnymi_pilotami}
        subtitle={Resources.Uzytkownicy_ktorzy_posiadaja_staly_dostep_do_mobilnych_pilotow}
        rigth={<>

            <div style={{width: isMobile ? "100%" : 250}}>
                <CustomizedSelect label={Resources.Status} options={[
                    {
                        value: "all",
                        label: Resources.Wszystkie
                    },
                    {
                        value: VirtualPilotStatus.Active.toString(),
                        label: Resources.Aktywny
                    },
                    {
                        value: VirtualPilotStatus.InActive.toString(),
                        label: Resources.Nieaktywny
                    }

                ]}
                                  onChangeCustom={onStatusFilter}
                                  value={status}/>
            </div>
            <BaseSpaceSeparator size={20}/>
            <div style={{width: isMobile ? "100%" : 250}}>

                <CustomizedSelect label={Resources.Pilot} options={[
                    {
                        value: "all",
                        label: Resources.Wszystkie
                    },
                    ...pilots.map(p => ({
                        value: p.id,
                        label: p.text
                    }))
                ]}
                                  onChangeCustom={onPilotFilter}
                                  value={pilot}/>
            </div>
            <BaseSpaceSeparator size={20}/>
            <div style={{width: isMobile ? "100%" : 250}}>
                <StandardButton onClick={onGiveAccess} variant={"big"}>{Resources.Przydziel_pilota}</StandardButton>
            </div>
        </>}
    >
        <GiveAccessToPilotForm
            visible={giveAccessToPilotFormVisible}
            onClose={() => setGiveAccessToPilotFormVisible(false)}
            onSubmitted={() => {
                setPilot(null);
                setStatus(null);
                setGiveAccessToPilotFormVisible(false);
                pilotsGridDataSource.reload();
            }}
            parkingId={parkingId}
        />
        <ChangePilotAccessForm
            visible={changePilotAccessFormVisible}
            onClose={() => setChangePilotAccessFormVisible(false)}
            linkId={editedEntry?.linkId}
            validTo={editedEntry?.validTo}
            onSubmitted={validTo => {
                setChangePilotAccessFormVisible(false);
                editedEntry.validTo = validTo;
                accessGrid?.refresh();
            }}
        />
        <PCDataGrid<GetParkingMobilePilotsViewResultEntryDto>
            ref={i => {
                if (i) {
                    accessGrid = i
                }

            }}
            key={dataGridKey}
            dataSource={pilotsGridDataSource}
            columns={[
                {
                    label: Resources.Pilot,
                    width: 329,
                    dataField: "name",
                    renderCell: item => (
                        <PCText fontSize={16} lineHeight={20 / 16} semibold color={Colors.brownish_grey}
                                letterSpacing={0}>
                            {item.name}
                            <br/>
                            <PCText inline fontSize={16} lineHeight={20 / 16} color={Colors.black} letterSpacing={0}
                                    semibold>
                                {`ul. ${item.addressDto.streetName} ${item.addressDto.streetNumber}`}
                            </PCText>
                        </PCText>)
                },
                {
                    label: Resources.Imie_i_Nazwisko,
                    width: 329,
                    dataField: "ownerLastName",
                    renderCell: item => (
                        <PCText fontSize={16} lineHeight={20 / 16} semibold color={Colors.brownish_grey}
                                letterSpacing={0}>
                            {item.ownerFirstName} {item.ownerLastName}
                            <br/>
                            <PCText inline fontSize={16} lineHeight={20 / 16} color={Colors.black} letterSpacing={0}
                                    semibold>
                                {item.ownerEmail}
                            </PCText>
                        </PCText>)
                },
                {
                    label: Resources.Data_waznosci,
                    dataField: "validTo",
                    width: 194,
                    renderCell: item => {
                        const {
                            validTo,
                            validFrom
                        } = item;
                        const now = new Date();
                        const isNotValidYet = now < validFrom;
                        const isExpired = !isNotValidYet && now > validTo;
                        const isActive = !isNotValidYet && !isExpired;
                        const isLimitless = moment.duration(moment(validTo).diff(moment(new Date())))
                                                  .asMonths() > 6;

                        const getTopText = () => {
                            if (isActive) {
                                if (isLimitless) {
                                    return Resources.Wazny_bezterminowo
                                } else {
                                    return `${Resources.Aktywny_do} ${formatDateTime(validTo,
                                        DateFormat.WthoutYear,
                                        DayOfWeekFormatType.None
                                    )}`
                                }
                            } else if (isNotValidYet) {
                                return `${Resources.Aktywny_od} ${formatDateTime(validFrom,
                                    DateFormat.WthoutYear,
                                    DayOfWeekFormatType.None
                                )}`
                            } else {
                                return `${Resources.Waznosc_uplynela} ${formatDateTime(validTo,
                                    DateFormat.WthoutYear,
                                    DayOfWeekFormatType.None
                                )}`
                            }
                        }

                        return (<PCText fontSize={16} lineHeight={20 / 16} semibold color={Colors.brownish_grey}
                                        letterSpacing={0}>
                            {getTopText()}
                            <br/>
                            <StatusPresenter
                                text={isActive ? Resources.Aktywny : Resources.Nieaktywny}
                                color={isActive ? Colors.green : Colors.red}
                            />
                        </PCText>)
                    }
                },
                {
                    label: Resources.Udostepnianie,
                    dataField: "canShare",
                    width: 174,
                    renderCell: item => <BooleanPresenter value={item.canShare}/>
                },
                {
                    label: Resources.Akcje,
                    width: '166',
                    renderCell: (item) => (<ActionColumn>
                        <MoreDropdown
                            actions={[
                                {
                                    text: item.canShare ? Resources.Zablokuj_mozliwosc_udostepniania : Resources.Odblokuj_mozliwosc_udostepniania,
                                    onClick: () => changeCanShare(item)
                                }
                            ]}
                        />
                        <ActionIcon icon={faPencilAlt} onClick={() => onEdit(item)} title={Resources.Edytuj}/>
                        <ActionIcon icon={faTrashAlt} onClick={() => onRemovePilotAccess(item)}
                                    title={Resources.Usun}/>
                    </ActionColumn>)
                }
            ]}
        />
    </SubBody>)
}