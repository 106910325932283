import {
    IGetManagerParkingsViewResult,
    IGetManagerParkingsViewResultEntryDto,
    IModifyCommercialParkingDto,
    ModifyCommercialParkingDto
} from "parkcash-api";
import ModalForm from "../../../components/ModalForm";
import Resources from "../../../../Resources";
import * as React from "react";
import {useCallback, useEffect} from "react";
import Stack from "@mui/material/Stack";
import Colors from "../../../styles/Colors";
import {FormikHelpers} from "formik";
import RegularInput from "../../../components/forms/RegularInput";
import {ParkingManagerService, ParkingType, ServiceConfig} from "parkcash-api";
import RegularDropdownList from "../../../components/forms/RegularDropdownList";
import {mapEnumToSelectOptions} from "../../../utils/EnumUtils/MapEnumToSelectOptions";
import {useUser} from "../../../user/UseUserHook";
import {useSnackbar} from "../../../MUI/components/snackbar";
import {MapParkingTypeEnumToLocalizedText} from "../../../utils/EnumUtils/ParkingConfigRelatedEnums/ParkingTypeHelpers";
import {
    useCustomizedNotification
} from "../../../MUI/components/AddedOrModyfiedMuiComponents/CustomizedNotification/useCustomizedNotification";
import {UpdateparkingFormState} from "./parkingConfigFormTypes";
import {
    mapGetParkginDtoToUpdateFormValues, mapUpdateFormValuesToFormRelatedDtoProperties
} from "./parkingConfigFormUtils";
import {useFetch} from "../../../MUI/hooks/useFetch";
import {getFilters} from "../../../utils/SieveUtils";
import {FilterOperator} from "../../../utils/DataSource";
import {BaseSpaceSeparator} from "../../../styles/Separators";
import {LoadingErrorAndContentWrapper} from "../../../components/Common/LoadingErrorAndContentWrapper";
import * as yup from "yup";
import RegularSearchAddressInput from "../../../components/forms/RegularSearchAddressInput";
import SearchAddressInput from "../../../components/forms/SearchAddressInput";

interface Props {
    /*  parkingFriendlyName: string,*/
    visible: boolean;
    onClose: () => void;
    editedParkingId: string;
    onSubmitted?: () => void;

}

export const UpdateParkingForm = ({
                                      visible,
                                      onClose,
                                      editedParkingId,
                                      onSubmitted

                                  }: Props) => {
    const applicationMode = useUser().applicationMode;
    const contextParkingId = applicationMode.getCurrentParking()?.parkingId || null;
    const {enqueueSnackbar} = useSnackbar();
    const [initialFormValues, setInitialFormValues] = React.useState<UpdateparkingFormState>(
        mapGetParkginDtoToUpdateFormValues());
    const parkingTypeSelectOptions = mapEnumToSelectOptions(ParkingType, MapParkingTypeEnumToLocalizedText, false);
    const jwt = useUser().token;

    const {
        enqueError,
        enqueSuccess
    } = useCustomizedNotification();
    const fetchFunction = useCallback(async () => {
        const filters: Array<{
            key: keyof IGetManagerParkingsViewResultEntryDto, operator: FilterOperator, value: any
        }> = [
            {
                key: "id",
                operator: FilterOperator.EQUALS,
                value: editedParkingId

            }
        ]
        return await new ParkingManagerService(new ServiceConfig({jwt})).getManagerParkingsView(getFilters(filters),
            null,
            null,
            null
        )
    }, [
        jwt,
        editedParkingId
    ])

    const {
        data: initialFormDataSource,
        loading,
        error,
        fetch
    } = useFetch<IGetManagerParkingsViewResult>(
        fetchFunction,
        (data) => {
            setInitialFormValues(mapGetParkginDtoToUpdateFormValues(data?.entries[0]))
        }
    );

    const yupSchema = yup.object().nullable().shape({
        friendlyName: yup.string().required(Resources.To_pole_jest_wymagane),
      /*  address: yup.object().nullable().required(Resources.To_pole_jest_wymagane).shape({
            streetNumber: yup.string().required(Resources.Podanie_nr_ulicy_jest_wymagane),
        }),*/
        parkingType: yup.string().required(Resources.To_pole_jest_wymagane),

    });
    useEffect(() => {
        if (visible) {
            fetch();
        }
    }, [visible]);
    return (<ModalForm
        initialValues={initialFormValues}
        validationSchema={yupSchema}
        iconWidth={90}
        width={"500px"}
        iconHeight={110}
        visible={visible}
        onClose={onClose}
        headerTitle={initialFormDataSource?.entries[0]?.friendlyName || ""}
        title={Resources.Edytuj_parking}
        enableReinitialize={true}
        onSubmit={async (values: UpdateparkingFormState, actions: FormikHelpers<UpdateparkingFormState>) => {
            console.log(values);
            const iDto: IModifyCommercialParkingDto = {
                ...mapUpdateFormValuesToFormRelatedDtoProperties(values),
                contextParkingId: contextParkingId,
                parkingId: editedParkingId,
            }
            const dto: ModifyCommercialParkingDto = new ModifyCommercialParkingDto(iDto);
            try {
               const result= await new ParkingManagerService(new ServiceConfig({jwt})).modifyCommercialParking(dto);
                if (!result.isSuccess) {
                    throw new Error("An Errorr during saving occured")
                }
                onClose();
                enqueSuccess(Resources.Dane_zostaly_pomyslnie_zaktualizowane);
                if (onSubmitted) {
                    onSubmitted();
                }

            } catch (e) {
                onClose();
                enqueError(Resources.Wystapil_blad_podczas_aktualizacji_danych);
            }
        }}
    >
        {({
              setFieldTouched,
              setFieldValue,
              values,
              errors,
              touched
          }) => <Stack>
            <LoadingErrorAndContentWrapper loading={loading} errors={[error]} data={!!initialFormDataSource}>
                <RegularInput
                    label={Resources.Nazwa}
                    value={values.friendlyName}
                    error={errors.friendlyName}
                    touched={touched.friendlyName}
                    name={"friendlyName"}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    showClearButton
                    showValidatedButton
                />
                <RegularSearchAddressInput
                    error={errors.address}
                    current={values.address}
                    zIndex={1000}
                    name={"address"}
                    label={Resources.Podaj_adres_miejsca}
                    placeholder={Resources.Wpisz_adres}
                    borderColor={Colors.brownish_grey}
                    disabled={false}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    touched={touched.address}/>
                <RegularDropdownList
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    error={errors.parkingType}
                    touched={touched.parkingType}
                    value={values.parkingType?.toString()}
                    name="parkingType"
                    actions={parkingTypeSelectOptions.map((option) => ({
                        id: option.value,
                        text: option.label
                    }))}
                    borderColor={Colors.brownish_grey}
                    label={Resources.Rodzaj_parkingu}
                />
                <RegularInput
                    label={Resources.Email}
                    value={values.contactEmail}
                    error={errors.contactEmail}
                    touched={touched.contactEmail}
                    name={"contactEmail"}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    showClearButton
                    showValidatedButton
                />

                <RegularInput
                    label={Resources.Numer_telefonu}
                    value={values.contactPhone}
                    error={errors.contactPhone}
                    touched={touched.contactPhone}
                    name={"contactPhone"}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    showClearButton
                    showValidatedButton
                />
                <RegularInput
                    label={Resources.Opis}
                    value={values.description}
                    error={errors.description}
                    touched={touched.description}
                    name={"description"}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    showClearButton
                    showValidatedButton
                />
                <RegularInput
                    label={Resources.Regulamin_ogolny}
                    value={values.generalParkingRegulationUrl}
                    error={errors.generalParkingRegulationUrl}
                    touched={touched.generalParkingRegulationUrl}
                    name={"generalParkingRegulationUrl"}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    showClearButton
                    showValidatedButton
                />
                <RegularInput
                    label={Resources.Url_regulaminu_sprzedarzy}
                    value={values.salesRegulationUrl}
                    error={errors.salesRegulationUrl}
                    touched={touched.salesRegulationUrl}
                    name={"salesRegulationUrl"}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    showClearButton
                    showValidatedButton
                />
                <RegularInput
                    label={Resources.Regulamin_prywatnosci}
                    value={values.privacyPolicyRegulationUrl}
                    error={errors.privacyPolicyRegulationUrl}
                    touched={touched.privacyPolicyRegulationUrl}
                    name={"privacyPolicyRegulationUrl"}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    showClearButton
                    showValidatedButton
                />
                <RegularInput
                    label={Resources.Url_mapy_parkingu}
                    value={values.parkingMapUrl}
                    error={errors.parkingMapUrl}
                    touched={touched.parkingMapUrl}
                    name={"parkingMapUrl"}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    showClearButton
                    showValidatedButton
                />
                <RegularInput
                    label={Resources.Instrukcja_aplikacji}
                    value={values.appManualUrl}
                    error={errors.appManualUrl}
                    touched={touched.appManualUrl}
                    name={"appManualUrl"}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    showClearButton
                    showValidatedButton
                />
                <RegularInput
                    label={Resources.Url_loga}
                    value={values.customLogoUrl}
                    error={errors.customLogoUrl}
                    touched={touched.customLogoUrl}
                    name={"customLogoUrl"}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    showClearButton
                    showValidatedButton
                />
            </LoadingErrorAndContentWrapper>
        </Stack>}
    </ModalForm>);

}