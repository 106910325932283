import { ExternalReservationStatus } from "parkcash-api";
import * as React from "react";
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";
import Resources from "../../Resources";
import Spinner from "../components/Spinner";
import { StandardButton } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { MOBILE_WIDTH_THRESHOLD } from "../utils/Constants";
import { getErrorMessage } from "../utils/ErrorUtils";
import { useWindowSize } from "../utils/WindowSizeHook";
import { getExternalReservationPurchaseStatus } from "./ExternalReservationsUtils";

const subscription_type = require("../../assets/images/subscription_type.svg");
const errorImg = require("../../assets/images/error.svg");

const MAX_TRIES = 200;

const INTERVAL = 5000;

function setIntervalImmediately(func: () => void, interval: number) {
    func();
    return setInterval(func, interval);
}

export default () => {
    const {externalReservationId} = useParams<{externalReservationId: string}>();
    const {windowHeight, windowWidth} = useWindowSize();
    const tries = React.useRef(0);
    const interval = React.useRef<any>();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const [progress, setProgress] = React.useState(true);
    const [errorStatus, setErrorStatus] = React.useState<string>(null);
    const [parkingUrl, setParkingUrl] = React.useState<string>(null);

    const onError = (status: string) => {
        clearInterval(interval.current);
        setProgress(false);
        setErrorStatus(status);
    }

    React.useEffect(() => {
        interval.current = setIntervalImmediately(async () => {
            const {error, partnerWebsiteUrl, status} = await getExternalReservationPurchaseStatus(externalReservationId);
            if(error){
                onError(getErrorMessage(error));
            }
            else if(status === ExternalReservationStatus.Processing){
                if(tries.current >= MAX_TRIES){
                    onError(Resources.Przekroczono_czas_oczekiwania);
                }
                else{
                    tries.current = tries.current + 1;
                }
            }
            else if(status === ExternalReservationStatus.Confirmed){
                setProgress(false);
                clearInterval(interval.current);
                setParkingUrl(partnerWebsiteUrl);
            }
            else if(status === ExternalReservationStatus.PaymentRejected){
                onError(Resources.Twoja_płatnosc_została_odrzucona_przez_operatora);
            }
            else if(status === ExternalReservationStatus.SelectedSpotAlreadyTaken){
                onError(Resources.Wybrane_miejsce_zostalo_zarezerwowane_w_miedzyczasie_Skontatktuj_sie_z_nami_w_celu_uzyskania_zwrotu_platnosci);
            }
            else{
                onError(`${Resources.Cos_poszlo_nie_tak}. ${Resources.Status_zakupu}: ${status}`)
            }            
        }, INTERVAL);

        return () => {
            clearInterval(interval.current);
        }
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: isMobile ? 20 : 120,
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: isMobile ? 20 : 50
            }}
        >
            {!errorStatus && <img src={subscription_type} width={220} height={193} />}
            {!!errorStatus && <img src={errorImg} width={220} height={207} />}

            <BaseSpaceSeparator size={isMobile ? 30 : 50} />

            <div style={{width: isMobile ? 300 : 600}}>
                <PCText fontSize={36} color={Colors.black} textAlign="center">
                    {progress && Resources.Sprawdzanie_statusu_zakupu}
                    {!progress && !!errorStatus && errorStatus}
                    {!progress && !errorStatus && Resources.Dokonano_zakupu}
                </PCText>
                
            </div>

            <BaseSpaceSeparator size={isMobile ? 30 : 100} />

            {progress && <Spinner size="medium" />}
            {!progress && (
                <div style={{width: isMobile ? 250 : 300}}>
                    <StandardButton
                        href={parkingUrl}
                        to={"/"}
                    >
                        {parkingUrl ? Resources.Wroc_na_strone_parkingu : Resources.Powrot_do_aplikacji}
                    </StandardButton>
                </div>
            )}
        </div>
    )
}