import * as React from "react";
import { useParams } from "react-router";
import Resources from "../../../Resources";
import Body from "../../layouts/Main/Body";
import ParkingSpotTimeline from "../../parkingSpots/ParkingSpotTimeline";

export default () => {
    const {spotId, spotDescription} = useParams<{spotId: string,  spotDescription:string}>();

    return (
        <Body title={`${Resources.Linia_czasowa} ${spotDescription}`}>
            <ParkingSpotTimeline 
                spotId={spotId}
            />
        </Body>
    )
}