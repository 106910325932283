import { PaymentOrderStatus, PaymentsService, ServiceConfig, SubscriptionPurchaseStatus, SubscriptionsService } from "parkcash-api";
import * as React from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import Resources from "../../Resources";
import Spinner from "../components/Spinner";
import { StandardButton } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { MOBILE_WIDTH_THRESHOLD } from "../utils/Constants";
import { getErrorMessage } from "../utils/ErrorUtils";
import { useWindowSize } from "../utils/WindowSizeHook";

const subscription_type = require("../../assets/images/subscription_type.svg");
const errorImg = require("../../assets/images/error.svg");

const MAX_TRIES = 200;

const INTERVAL = 5000;

function setIntervalImmediately(func: () => void, interval: number) {
    func();
    return setInterval(func, interval);
}

export default () => {
    const {windowHeight, windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const [progress, setProgress] = React.useState(true);
    const [errorStatus, setErrorStatus] = React.useState<string>(null);
    const tries = React.useRef(0);
    const interval = React.useRef<any>();
    const {purchaseId} = useParams<{purchaseId: string}>();
    const [parkingUrl, setParkingUrl] = React.useState<string>(null);
    const [redirectToApp, setRedirectToApp] = React.useState(false);

    const onError = (status: string) => {
        clearInterval(interval.current);
        setProgress(false);
        setErrorStatus(status);
    }

    React.useEffect(() => {
        interval.current = setIntervalImmediately(async () => {
            try{
                const purchaseStatusResponse = await new SubscriptionsService(new ServiceConfig()).getSubscriptionPurchaseStatus(purchaseId)
                if(purchaseStatusResponse.isSuccess){
                    const {status, partnerWebsiteUrl} = purchaseStatusResponse.result;
                                
                    if(status === SubscriptionPurchaseStatus.Confirmed){
                        setProgress(false);
                        setParkingUrl(partnerWebsiteUrl);
                        clearInterval(interval.current);
                    }
                    else if(status === SubscriptionPurchaseStatus.PaymentRejected){
                        onError(Resources.Twoja_płatnosc_została_odrzucona_przez_operatora);
                    }
                    else if(status === SubscriptionPurchaseStatus.SelectedSpotAlreadyTaken){
                        onError(Resources.Wybrane_miejsce_zostalo_zarezerwowane_w_miedzyczasie_Skontatktuj_sie_z_nami_w_celu_uzyskania_zwrotu_platnosci);
                    }
                    else if(status === SubscriptionPurchaseStatus.Processing){
                        if(tries.current >= MAX_TRIES){
                            onError(Resources.Przekroczono_czas_oczekiwania);
                        }
                        else{
                            tries.current = tries.current + 1;
                        }
                    }
                    else{
                        onError(`${Resources.Cos_poszlo_nie_tak}. ${Resources.Status_zakupu}: ${status}`)
                    }
                }
                else{
                    onError(getErrorMessage(purchaseStatusResponse.error.code));
                }
            }
            catch{
                onError(getErrorMessage());
            }
            
        }, INTERVAL);

        return () => {
            clearInterval(interval.current);
        }
    }, []);

    if(redirectToApp){
        return <Redirect to="/" />;
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: isMobile ? 20 : 120,
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: isMobile ? 20 : 50
            }}
        >
            {!errorStatus && <img src={subscription_type} width={220} height={193} />}
            {!!errorStatus && <img src={errorImg} width={220} height={207} />}

            <BaseSpaceSeparator size={isMobile ? 30 : 50} />

            <div style={{width: isMobile ? 300 : 600}}>
                <PCText fontSize={36} color={Colors.black} textAlign="center">
                    {progress && Resources.Sprawdzanie_statusu_zakupu}
                    {!progress && !!errorStatus && errorStatus}
                    {!progress && !errorStatus && Resources.Dokonano_zakupu}
                </PCText>
                
            </div>

            <BaseSpaceSeparator size={isMobile ? 30 : 100} />

            {progress && <Spinner size="medium" />}
            {!progress && (
                <div style={{width: isMobile ? 250 : 300}}>
                    <StandardButton
                        onClick={() => {
                            if(parkingUrl){
                                window.location.href = parkingUrl;
                            }
                            else{
                                setRedirectToApp(true);
                            }
                            
                        }}
                    >
                        {parkingUrl ? Resources.Wroc_na_strone_parkingu : Resources.Powrot_do_aplikacji}
                    </StandardButton>
                </div>
            )}
        </div>
    )
}