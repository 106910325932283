import * as React from "react";
import Resources from "../../Resources";
import { StandardButton, StandardButton2 } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";

const homeWithDrive = require("../../assets/images/homeWithDrive.svg");

export default (props: {
    showButton?: boolean;
    buttonText?: string;
    onButtonPress?: () => void;

    showButton2?: boolean;
    buttonText2?: string;
    onButton2Press?: () => void;

    title: string;
    subtitle?: string;
    image?: any,
    imageHeight?: number,
    imageWidth?: number,
    noCenter?: boolean
}) => {
    const {
        showButton2 = false,
        onButton2Press,
        buttonText2,

        showButton = false,
        buttonText = Resources.Dodaj,
        onButtonPress,

        title,     
        subtitle,
        image = homeWithDrive,
        imageHeight = 105,
        imageWidth = 113,
        noCenter = false
    } = props;

    const hasButtons = showButton || showButton2;
    if(hasButtons || noCenter){
        return (
            <div style={{flex: 1, display: 'flex', flexDirection:'column'}}>
                <img src={image} width={imageWidth} height={imageHeight} style={{alignSelf: 'center'}}/>
                <BaseSpaceSeparator size={20} />
                <div style={{width: '100%', padding: "0px 10px"}}>
                    <PCText bold fontSize={18} textAlign="center" color={Colors.black}>
                        {title}
                    </PCText> 
                </div>
                {!!subtitle && (
                    <>
                        <BaseSpaceSeparator size={16} />
                        <div style={{width: '100%', padding: "0px 20px"}}>
                            <PCText textAlign="center" fontSize={14} color={Colors.brownish_grey}>
                                {subtitle}
                            </PCText>
                        </div>
                    </>
                )}
                <div style={{flex: 1, minHeight: 50}} />
                <div style={{width: '100%', flexDirection: 'row', alignItems: 'center'}}>
                    {showButton2 && (
                        <div style={{flex: 1}}>
                            <StandardButton2 onClick={onButton2Press}>{buttonText2}</StandardButton2>
                        </div>
                    )}
                    {showButton && showButton2 && <BaseSpaceSeparator size={20} />}
                    {showButton && (
                        <div style={{flex: 1}}>
                            <StandardButton onClick={onButtonPress}>{buttonText}</StandardButton>
                        </div>
                    )}
                </div>
            </div>
        );
    }
    else{
        return (
            <div style={{flex: 1, justifyContent: 'center', display: "flex", flexDirection: 'column'}}>
                <img src={image} width={imageWidth} height={imageHeight} style={{alignSelf: 'center'}}/>
                <BaseSpaceSeparator size={20} />
                <PCText bold fontSize={18} textAlign="center" color={Colors.black}>
                    {title}
                </PCText> 
                {!!subtitle && (
                    <>
                        <BaseSpaceSeparator size={20} />
                        <PCText textAlign="center" fontSize={14} color={Colors.brownish_grey}>
                            {subtitle}
                        </PCText>
                    </>
                )}
            </div>
        )
    }
}