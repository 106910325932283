import * as React from "react";
import { useParams } from "react-router";
import { getParkingLogoUrl, getParkingLogoUrlByPurchaseId } from "../otherPages/SubscriptionsUtils";
import Colors from "../styles/Colors";
import { PCText } from "../styles/Texts";
import { MOBILE_WIDTH_THRESHOLD } from "../utils/Constants";
import { PCClasses } from "../utils/CSSUtils";
import { useWindowSize } from "../utils/WindowSizeHook";
import DesktopHeaderBase, { DESKTOP_HEADER_DEFAULT_HEIGHT } from "./Common/DesktopHeaderBase";
const logo = require("../../assets/images/logo.svg");

const getParkingId = (def: string) => {
    try{
        return useParams<{parkingId: string, purchaseId: string}>().parkingId;
    }
    catch{
        return def;
    }
}

const getPurchaseId = (def: string) => {
    try{
        return useParams<{parkingId: string, purchaseId: string}>().purchaseId;
    }
    catch{
        return def;
    }
}

const DesktopHeader = (props: {title: string, purchaseId?: string, parkingId?: string, adjustHeaderToScroll?: boolean, height: number}) => {
    const {parkingId: defParkingId = null, purchaseId: defPurchaseId = null, adjustHeaderToScroll, height, title} = props;
    const parkingId = getParkingId(defParkingId);
    const purchaseId = getPurchaseId(defPurchaseId);
    const [targetLogo, setTargetLogo] = React.useState<string>(logo);

    React.useEffect(() => {
        const init = async () => {
            if(parkingId){
                const l = await getParkingLogoUrl(parkingId);
                if(l){
                    setTargetLogo(l);
                }
            }
            else if(purchaseId){
                const l = await getParkingLogoUrlByPurchaseId(purchaseId);
                if(l){
                    setTargetLogo(l);
                }
            }
        }
        
        init();
    }, [parkingId, purchaseId]);

    return (
        <DesktopHeaderBase height={height} adjustHeaderToScroll={adjustHeaderToScroll}>
            <img key={targetLogo} width={260} height={45} src={targetLogo} />
            <div style={{margin: "0 20px"}}>
                <PCText semibold fontSize={28} textAlign="center" color={Colors.greyishBrown}>
                    {title}
                </PCText>
            </div>
            <div style={{width: 260}}/>
        </DesktopHeaderBase>
    );
}


const MobileHeader = (props: {title: string, height: number}) => {
    const {height, title} = props;
    return (
        <div style={{height, backgroundColor: Colors.light_royal_blue, display: 'flex', alignItems: 'center', paddingLeft: 50}}>
            <PCText semibold fontSize={20} textAlign="center" color={Colors.white}>
                {title}
            </PCText>
        </div>
    )
}

export default (props: {
    title: string,
    children: React.ReactNode,
    purchaseId?: string,
    parkingId?: string,
    adjustHeaderToScroll?: boolean,
    headerHeight?: number
}) => {
    const {children, title, purchaseId, parkingId, adjustHeaderToScroll, headerHeight = DESKTOP_HEADER_DEFAULT_HEIGHT} = props;
    const {windowWidth, windowHeight} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const paddingTop = isMobile ? 0 : headerHeight;

    return (
        <div
            className={PCClasses("pc-main-wrapper")}
            style={{overflow: props.adjustHeaderToScroll ? "auto" : undefined}}
        >   
            <div 
                className={PCClasses("pc-main-container")} 
                style={{paddingTop, minHeight: '100%'}}
            >
                {isMobile && <MobileHeader height={headerHeight || 80} title={title} />}
                {!isMobile && <DesktopHeader purchaseId={purchaseId} parkingId={parkingId} height={headerHeight} title={title} adjustHeaderToScroll={adjustHeaderToScroll} />}
                
                <div style={{minHeight: windowHeight - headerHeight, backgroundColor: isMobile ? Colors.white : Colors.pale_grey_two, overflow: "auto"}}>
                    {props.children}
                </div>      
            </div>
        </div>
    )
}