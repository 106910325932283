import * as React from "react";

export default (props: {
    isMobile: boolean,
    children: React.ReactNode
}) => {
    const {children, isMobile} = props;

    if(isMobile){
        return (
            <>
                {children}
            </>
        );
    }
    else{
        return (
            <div style={{padding: "50px 20px", display: 'flex', justifyContent: 'center'}}>
                {children}
            </div>
        );
    }
}