import Enumerable from "linq";
import { BeginReservationPaymentDto, IParkingInfo, ParkingInfo, ParkingSpotsService, ParkingsService, PaymentsService, PaymentTargetType, ServiceConfig } from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Resources from "../../Resources";
import PCDateTimePicker from "../components/PCDatetimePicker";
import SearchAddressInput, { searchAddressItem } from "../components/forms/SearchAddressInput";
import { TransactionType } from "../payments/Enums";
import PaymentForm from "../payments/PaymentForm";
import SavedCreditCardModal from "../payments/SavedCreditCardModal";
import { applicationState } from "../redux/ApplicationState";
import { StandardButton } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { addHours, addMinutes, roundToHours } from "../utils/DateTimeUtils";
import GeolocationHelper from "../utils/GeolocationHelper";
import Notify from "../utils/Notify";
import Map from "./Map";
import PlacesResult from "./PlacesResult";
import ReservationConfirmationForm from "./ReservationConfirmationForm";
import ReservationParameters from "./ReservationParameters";
import { ExtendedParkingInfo, ParkingTargetInfo, SearchResult } from "./SearchResult";
import SearchTarget from "./SearchTarget";
import {routePrefix} from "../../app.json";
import GlobalProgress from "../components/GlobalProgress";
import GoogleMapsApiManager from "../utils/GoogleMapsApiManager";
import * as uuid from "uuid";
import { getGroups } from "../groups/Redux";
import BookingPanelFilterRow from "./BookingPanelFilterRow";
import { useWindowSize } from "../utils/WindowSizeHook";
import { getErrorMessage } from "../utils/ErrorUtils";

const THRESHHOLD = 641;

interface OwnProps {
    extraWidth?: number
}

interface Props extends OwnProps {
    groups: ParkingInfo[],
    jwt: string,
    onInit: () => void;
    
}

const Container = (props: Props) => {
    const {onInit, groups, jwt, extraWidth = 0} = props;
    const [showReservationConfirmation, setShowConfirmationReservation] = React.useState(false);
    const [arriving, setArriving] = React.useState(roundToHours(new Date(), true));
    const [leaving, setLeaving] = React.useState(addHours(roundToHours(new Date(), true), 1));
    const [searchProgress, setSearchProgress] = React.useState(false);
    const [searchWasDone, setSearchWasDone] = React.useState(false);
    const [searchResult, setSearchResult] = React.useState<SearchResult>(null);
    const [searchItem, setSearchItem] = React.useState<searchAddressItem>(null);
    const [initialParkings, setInitialParkings] = React.useState<ExtendedParkingInfo[]>([]);
    const [predefinedSearchItems, setPredefinedSearchItems] = React.useState<searchAddressItem[]>([]);
    const [selectedParkingTarget, setSelectedParkingTarget] = React.useState<ParkingTargetInfo>(null);
    const [reservationParameters, setResevationParameters] = React.useState<ReservationParameters>(null);
    const [redirectToBookings, setRedirectToBookings] = React.useState(false);
    const [globalProgress, setGlobalProgress] = React.useState(false);
    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth - extraWidth < THRESHHOLD;


    React.useEffect(() => {
        setPredefinedSearchItems(groups.map<searchAddressItem>(p => ({
            id: p.id,
            type: "parking",
            ...p.address
        })));
    }, [groups]);

    React.useEffect(() => {
        onInit();
        getInitialParkings();
    }, []);

    const onArrivingChanged = (a: Date) => {
        setArriving(a);
        if(a >= leaving){
            setLeaving(addMinutes(a, 15));
        }
    }

    const onLeavingChanged = (l: Date) => {
        setLeaving(l);
        if(l <= arriving){
            setArriving(addMinutes(l, -15));
        }
    }

    const onSearchHere = async (lat: number, lon: number) => {
        setSearchProgress(true);
        const address = await GoogleMapsApiManager.GetAddressFromCoordinates(lat, lon);
        const newSearchItem: searchAddressItem = {
            id: uuid.v1(),
            type: "location",
            ...address
        }
        setSearchItem(newSearchItem);
        onSearch(newSearchItem);
    }

    const onSearch = async (searchItem: searchAddressItem) => {
        try{
            setSearchProgress(true);
            if(!searchItem){
                const {latitude, longitude} = await GeolocationHelper.getLocation();
                const address = await GoogleMapsApiManager.GetAddressFromCoordinates(latitude, longitude);
                const newSearchItem: searchAddressItem = {
                    id: uuid.v1(),
                    type: "location",
                    ...address
                }
                setSearchItem(newSearchItem);
                onSearch(newSearchItem);
                return;
            }
            
            const {type, id} = searchItem;
            const searchTarget = type === "location" ? SearchTarget.CreateForSearchingInSearchedLocation(searchItem) : SearchTarget.CreateForSearchingInParking(Enumerable.from(groups).firstOrDefault(p => p.id === id));
            const searchRequest = searchTarget.getSearchRequest(arriving, leaving);
            const response = await new ParkingSpotsService(new ServiceConfig({jwt})).searchForParkingPlaces(
                searchRequest.latitude,
                searchRequest.longitude,
                searchRequest.radiusInMeters,
                searchRequest.start,
                searchRequest.end
            );
            if (response.isSuccess) {
                setSearchResult(new SearchResult(response.result, searchTarget));
                setSearchWasDone(true);         
            } 
            else {
                Notify.Error(getErrorMessage(response.error.code));
            }
        }
        catch{
            Notify.Error(getErrorMessage());
        }
        finally{
            setSearchProgress(false);
        }
    }

    const onPlaceChosen = (place: ParkingTargetInfo) => {
        setResevationParameters(new ReservationParameters({arriving, leaving, parkingSpot: place.spotInfo}));
        setShowConfirmationReservation(true);
    }

    const getInitialParkings = async () => {
        const {latitude, longitude} = await GeolocationHelper.getLocation();

        const response = await new ParkingSpotsService(new ServiceConfig({jwt})).searchForParkingPlaces(
            latitude,
            longitude,
            500,
            new Date(),
            addMinutes(new Date(), 5)
        );

        if(response.isSuccess){
            setInitialParkings([
                ...response.result.userParkings.map<ExtendedParkingInfo>(e => ({
                    distance: null,
                    parkingId: e.parkingId,
                    lat: e.latitude,
                    lon: e.longitude,
                    hasFreePlaces: false,
                    hasMyPlaces: false,
                    type: "normal"
                })),
                ...response.result.nearbyParkings.map<ExtendedParkingInfo>(e => ({
                    distance: null,
                    parkingId: e.parkingId,
                    lat: e.latitude,
                    lon: e.longitude,
                    hasFreePlaces: false,
                    hasMyPlaces: false,
                    type: "normal"
                })),
                ...response.result.unnumberedSpotsParkings.entries.map<ExtendedParkingInfo>(e => ({
                    distance: null,
                    parkingId: e.parkingId,
                    lat: e.address.latitude,
                    lon: e.address.longitude,
                    hasMyPlaces: false,
                    hasFreePlaces: false,
                    type: "startStop"
                }))
            ])
        }
    }

    const onParkingOnMapPressed = (parking: ExtendedParkingInfo) => {
        // const {searchWasAlreadyDone} = this.state;
        // if(!searchWasAlreadyDone && parking.type === "startStop"){
        //     this.onStartStopParkingChosen(parking.parkingId);
        //     return;
        // }

        // if(parking.type === "startStop" || parking.hasFreePlaces){
        //     const target = this.state.searchResult.getFreeTargetByParkingId(parking.parkingId);
        //     this.setState({
        //         selectedParkingTarget: target,
        //         searchWasAlreadyDone: true
        //     }, () => {
        //         this.bottomSheet && this.bottomSheet.scroll(target);
        //     });
        // }       
    }

    const onReservationMade = async (reservationId: string, amount: number, payment: string) => {
        setShowConfirmationReservation(false);
        if(!amount){
            Notify.Success(Resources.Rezerwacja_dokonana);
            setRedirectToBookings(true);
        }
        else if(payment === TransactionType.BlumediaGate.toString()){
            //PaymentsService -> BeginReservationPayment
            try{
                setGlobalProgress(true);
                const beginBluemediaPaymentResponse = await new PaymentsService(new ServiceConfig({jwt})).beginReservationPayment(new BeginReservationPaymentDto({
                    reservationId,
                    redirectUrl: `${window.location.origin}${!routePrefix || routePrefix === "/" ? "" : routePrefix}/bookings`
                }));
                if(beginBluemediaPaymentResponse.isSuccess){
                    window.location.href = beginBluemediaPaymentResponse.result.paymentUrl;
                }
                else{
                    Notify.Error(getErrorMessage(beginBluemediaPaymentResponse.error.code));
                }
            }
            catch{
                Notify.Error(getErrorMessage());
            }
            finally{
                setGlobalProgress(false);
            }
            
        }
        else{
            const transaction = await SavedCreditCardModal.show({amount, targetObjectId: reservationId, paymentTargetType: PaymentTargetType.Reservation, cardId: payment});
            if(transaction){
                Notify.Success(Resources.Rezerwacja_dokonana);
                setRedirectToBookings(true);
            }
        }
    }

    React.useEffect(() => {
        GeolocationHelper.getLocation()
        .then(loc => {
            const {latitude, longitude} = loc;
            GoogleMapsApiManager.GetAddressFromCoordinates(latitude, longitude)
            .then(address => {
                console.log('my address: ', address);
            })
        })
    }, []);

    if(redirectToBookings){
        return <Redirect to="/bookings" push/>;
    }

    return (
        <div>
            <GlobalProgress visible={globalProgress} />
            <ReservationConfirmationForm 
                parameters={reservationParameters}
                onClose={() => setShowConfirmationReservation(false)}
                visible={showReservationConfirmation}
                onReservationMade={onReservationMade}
            />
            <BookingPanelFilterRow 
                arriving={arriving}
                leaving={leaving}
                predefinedSearchItems={predefinedSearchItems}
                searchItem={searchItem}
                onSearhItemChanged={setSearchItem}
                searchProgress={searchProgress}
                onArrivingChanged={onArrivingChanged}
                onLeavingChanged={onLeavingChanged}
                onSearch={() =>onSearch(searchItem)}
                extraWidth={extraWidth}
            />
            <BaseSpaceSeparator size={30} />
            <div style={{display: 'flex', flexDirection: isMobile ? "column" : 'row'}}>
                <div style={{flex: isMobile ? undefined : 1, width: isMobile ? '100%' : undefined}}>
                    <Map 
                        searchResult={searchResult}
                        onParkingPressed={onParkingOnMapPressed}
                        currentParkingId={selectedParkingTarget?.getParkingId()}
                        searchWasAlreadyDone={searchWasDone}
                        initialParkings={initialParkings}
                        onSearchHere={onSearchHere}
                    />
                </div>
                {searchWasDone && (
                    <>
                        <BaseSpaceSeparator size={30} />
                        <PlacesResult 
                            onChosen={onPlaceChosen}
                            targets={(searchResult?.getParkingTargets() || []).filter(t => t.type !== "startStopParking")}
                        />
                    </>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state: applicationState, ownProps: OwnProps): Partial<Props> => ({
    groups: state.groups.groups || [],
    jwt: state.user.token,
    ...ownProps
});

const mapDispatchToProps = (dispatch):Partial<Props> => ({
    
    onInit: () => dispatch(getGroups())
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);