import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { applicationState } from "../../../../redux/ApplicationState";
import { PCClasses } from "../../../../utils/CSSUtils";
import { IParkCashApplicationMode } from "../../../../utils/ParkCashApplicationMode";

interface OwnProps {
    scrolled: boolean;
    onClick?: () => void;
}

interface Props extends OwnProps {
    applicationMode: IParkCashApplicationMode;
}

const HomeButton = (props: Props) => {
    const logo = props.applicationMode.getLogo();
    return (
        <Link 
            onClick={props.onClick}
            to={props.applicationMode.isSuperAdmin() ? "/admin" : props.applicationMode.isInParkingManagementMode() ? "/parkingmanagement": "/"} 
            className={PCClasses("pc-button")} 
            style={{
                pointerEvents: props.scrolled ? "none" : "initial"
            }}
        >
            <img key={logo} width={260} height={45} src={logo} />
        </Link>
    );
}

const mapStateToProps = (state: applicationState, ownProps: OwnProps): Props => ({
    ...ownProps,
    applicationMode: state.user.applicationMode
});

export default connect(mapStateToProps)(HomeButton);