
import { ErrorCode } from "parkcash-api";
import * as React from "react";
import { StandardButton } from "../../../styles/Buttons";
import Colors from "../../../styles/Colors";
import { BaseSpaceSeparator } from "../../../styles/Separators";
import { PCText } from "../../../styles/Texts";
import {ErrorWrapperOfObject, IErrorWrapperOfObject} from "parkcash-api";
import {getErrorMessageNew} from "../../../utils/ErrorUtils";

const error = require("../../../../assets/images/error.svg");

export default function  ErrorsView (props: {
    errors: Array<IErrorWrapperOfObject>
    showButton?: boolean,
    onButtonClick?: () => void,
    buttonText?: string
    buttonTo?: string
}) {
    const {errors,onButtonClick,showButton = false,buttonText, buttonTo} = props;

    return (
        <div 
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <img src={error} width={117} height={110} />
            <BaseSpaceSeparator size={50} />
            <div >
                {errors.map((error, index) => (
                    <React.Fragment key={index}>
                        {!!error && (
                        <div style={{width: 300}}>
                            <PCText textAlign="center" fontSize={18} semibold color={Colors.black}>
                                {getErrorMessageNew(new ErrorWrapperOfObject(error))}
                            </PCText>
                        </div>
                        )}
                    </React.Fragment>
                    ))
                }
            </div>
          
            {showButton && (
                <>
                    <BaseSpaceSeparator size={70} />
                    <div style={{width: 300}}>
                        <StandardButton to={buttonTo} onClick={onButtonClick}>{buttonText}</StandardButton>
                    </div>
                </>
            )}
        </div>
    )
}