import {
    ErrorCode, ExternalParkingTicketService, PaymentsService, ServiceConfig, StartExternalParkingTicketPaymentDto,
} from "parkcash-api";
import * as React from "react";
import {useParams} from "react-router-dom";
import Resources from "../../../Resources";
import ConfirmModal from "../../components/ConfirmModal";
import RegularInput from "../../components/forms/RegularInput";
import {StandardButton} from "../../styles/Buttons";
import Colors from "../../styles/Colors";
import {BaseSpaceSeparator} from "../../styles/Separators";
import {PCText} from "../../styles/Texts";
import {getErrorMessage} from "../../utils/ErrorUtils";
import {transformLicencePlateNumber} from "../../utils/LicencePlateNumberUtils";
import Notify from "../../utils/Notify";
import {useWindowSize} from "../../utils/WindowSizeHook";
import BigForm from "../formParts/BigForm";
import FormWrapper from "../formParts/FormWrapper";
import PaymentPart from "../formParts/PaymentPart";
import {ISelectedPaymentInfo, PaymentMethodsTypes} from "../formParts/PaymentMethod";
import {checkBlikError, getWebPaymentConfiguration, validateBlik} from "../../utils/PaymentConfigurationUtils";
import {useEffect} from "react";
import {Aw} from "react-flags-select";

const payment = require("../../../assets/images/payment.svg");
const formatLicencePlateNumber = (l: string) => (l || "").toUpperCase().replace(/\s/g, "");

function useQueryParam(param) {
    return new URLSearchParams(window.location.search).get(param);
}

export default () => {
    const {parkingId} = useParams<{ parkingId: string }>();
    const {isMobile} = useWindowSize();
    const [ticketIdentifier, setTicketIdentifier] = React.useState("");
    const [ticketIdentifierTouched, setTicketIdentifierTouched] = React.useState(false);
    const [price, setPrice] = React.useState<number>(null);
    const [startProgress, setStartProgress] = React.useState(false);
    const [checkProgress, setCheckProgress] = React.useState(false);
    const [showErrorStatus, setShowErrorStatus] = React.useState(false);
    const [allowedPaymentMethods, setAllowedPaymentMethods] = React.useState<PaymentMethodsTypes[]>();
    const [blikError, setBlikError] = React.useState<string>();

    const backgroundUrl = useQueryParam('background-url');

    useEffect(() => {
        if (backgroundUrl) {
            // Here you should add any necessary validation for the backgroundUrl
            document.body.style.backgroundImage = `url('${backgroundUrl}')`;
            document.body.style.backgroundSize = 'cover';
        }
        // Cleanup function to reset background when component unmounts
        return () => {
            document.body.style.backgroundImage = '';
            document.body.style.backgroundSize = '';
        };
    }, [backgroundUrl]);

    const onPay = async (selectedPaymentMethodInfo: ISelectedPaymentInfo) => {
        try {
            setStartProgress(true);

            if(!validateBlik(selectedPaymentMethodInfo,e =>setBlikError(e))){
                return;
            }
            
            if (selectedPaymentMethodInfo?.selectedMethod === "BlikInPlace" && !selectedPaymentMethodInfo?.isBlikCodeValid) {
                setBlikError("Niepoprawny kod blik")
                return;
            }

            const {
                isSuccess,
                error,
                result
            } = await new ExternalParkingTicketService(new ServiceConfig()).startExternalParkingTicketPayment(new StartExternalParkingTicketPaymentDto(
                {
                    parkingId,
                    ticketIdentifier: formatLicencePlateNumber(ticketIdentifier),
                    blikCode: selectedPaymentMethodInfo.blikCode
                }));
            if (isSuccess) {
                const {
                    paymentUrl,
                    price: returnedPrice
                } = result;
                //let returnedPrice = 0;
                if (!returnedPrice) {
                    ConfirmModal.show({
                        confirmText: "OK",
                        text: Resources.Darmowe_parkowanie_zakonczone_Szlaban_podniesie_sie_automatycznie_przy_probie_wyjazdu,
                    });
                } else {
                    window.location.href = paymentUrl;
                }
            } else {

                if(checkBlikError(error,(e)=>setBlikError(e))){}
                else if (error.code === ErrorCode.ExternalTicketNotFound) {
                    setShowErrorStatus(true)
                    setPrice(null);
                }
                else if (error.code === ErrorCode.BlikError) {
                        setBlikError(error.message)
                } else {
                    setPrice(null);
                    Notify.Error(getErrorMessage(error.code));
                }
               
            }
        } catch {
            Notify.Error(getErrorMessage());
            setPrice(null);
        } finally {
            setStartProgress(false);
        }
    };

    const onCheck = async (ti?:string) => {
        try {
            setCheckProgress(true);
            // await new Promise(resolve => setTimeout(resolve, 500));
            // setShowErrorStatus(true);
            const {
                isSuccess,
                error,
                result
            } = await new ExternalParkingTicketService(new ServiceConfig()).getExternalParkingTicketInfo(parkingId,
                formatLicencePlateNumber(ti??ticketIdentifier)
            );
            if (isSuccess) {
                //setPrice(0);
                setPrice(result.price);

                if (result.price > 0) {
                    const allowedPaymentMethods = await getWebPaymentConfiguration(parkingId);
                    setAllowedPaymentMethods(allowedPaymentMethods); 
                }

            } else {
                if (error.code === ErrorCode.ExternalTicketNotFound) {
                    setShowErrorStatus(true);
                } else {
                    Notify.Error(getErrorMessage(error.code));
                }

                setPrice(null);
            }
        } catch {
            Notify.Error(getErrorMessage());
            setPrice(null);
        } finally {
            setCheckProgress(false);
        }
    };

    useEffect( () => {

        if(!ticketIdentifier){
            const check = async (ti:string)=> await onCheck(ti);

            let ticketIdentifierValue = new URLSearchParams(new URL(window.location.href).search).get('ticketIdentifier');
            if(ticketIdentifierValue){
                setTicketIdentifier(ticketIdentifierValue);
                check(ticketIdentifierValue);
            }
        }
       
    }, []);
    
    return (<FormWrapper isMobile={isMobile}>
            <BigForm
                isMobile={isMobile}
                title={Resources.Oplac_parkowanie}
                noOverflow
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <img
                        src={payment}
                        width={isMobile ? 103 : 60}
                        height={isMobile ? 65 : 110}
                    />
                </div>
                <BaseSpaceSeparator size={15}/>
                <PCText textAlign="center" fontSize={16} color={Colors.brownish_grey}>
                    {Resources.Wpisz_numer_rejestracyjny_swojego_pojazdu_badź_18_cyfrowy_numer_biletu_parkingowego_i_w_prosty_ureguluj_oplate_za_parking_przy_pomocy_platnosci_elektronicznej}
                </PCText>
                <BaseSpaceSeparator size={20}/>
                <RegularInput
                    label={Resources.Numer_rejestracyjny_18_cyfrowy_numer_biletu}
                    name="licencePlateNumber"
                    transformText={transformLicencePlateNumber}
                    setFieldTouched={(_, v) => setTicketIdentifierTouched(v)}
                    setFieldValue={(_, v) => setTicketIdentifier(v)}
                    error={ticketIdentifier ? null : Resources.Wymagane}
                    value={ticketIdentifier}
                    touched={ticketIdentifierTouched}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            onCheck();
                        } else {
                            setShowErrorStatus(false);
                            setPrice(null);
                        }
                    }}
                />
                <div
                    style={{
                        paddingTop: 4,
                        paddingBottom: 20,
                        boxSizing: "border-box"
                    }}
                >
                    <PCText
                        fontSize={24}
                        lineHeight={1}
                        letterSpacing={0}
                        color={Colors.red}
                    >
                        {showErrorStatus ? Resources.Numer_rejestracyjny_nie_zostal_znaleziony_w_systemie_Upewnij_sie_ze_wpisales_go_poprawnie_oraz_ze_tablica_rejestracyjna_jest_czytelna_nie_jest_zaslonieta_badz_zabrudzona : ""}
                        <br/>
                        {showErrorStatus ? Resources.Jesli_problem_bedzie_sie_powtarzal_zamiast_numeru_rejestracyjnego_podaj_numer_biletu_parkingowego_i_sprobuj_ponownie : ""}
                    </PCText>
                </div>
                <StandardButton onClick={()=>onCheck()} progress={checkProgress}>
                    {Resources.Sprawdz_cene}
                </StandardButton>
            </BigForm>
            {typeof price === "number" && (<>
                    <BaseSpaceSeparator size={isMobile ? 7 : 30}/>
                    <PaymentPart
                        isMobile={isMobile}
                        isValid
                        showPromotionCode={false}
                        showTimeRange={false}
                        submitProgress={startProgress}
                        lowerPrice={null}
                        price={price}
                        priceProgress={checkProgress}
                        onPay={onPay}
                        noPaymentText={Resources.bezplatnie}
                        submitText={price > 0 ? Resources.Oplac : Resources.Otworz}
                        title={price > 0 ? Resources.Platnosc : Resources.Potwierdzenie}
                        allowedPaymentMethods={allowedPaymentMethods}
                        onBlikErrorClear={() => setBlikError('')}
                        blikError={blikError}
                    />
                </>)}
        </FormWrapper>);
};
