import React from 'react';
// @mui
import Box from '@mui/material/Box';
//
import {fileData} from "../file-thumbnail";
import {CustomFile} from "./types";
import ListItemText from "@mui/material/ListItemText";
import {fData} from "../../utils/format-number";

// ----------------------------------------------------------------------

type Props = {
    imgUrl?: string; file: File | string
    onRemove?: (file: CustomFile | string) => void;
};

export default function SingleFilePreview({
                                              imgUrl = '',
                                              file,
                                              onRemove
                                          }: Props) {
    const isNotFormatFile = typeof file === 'string';
    const img=imgUrl;
    const { key, name = '', size = 0 } = fileData(file);
    return (  <Box>
        {/*<Box
      sx={{
        p: 1,
        top: 0,
        left: 0,
        width: 1,
        height: 1,
        position: 'absolute',
      }}
    >
      <Image
        alt="file preview"
        src={imgUrl}
        sx={{
          width: 1,
          height: 1,
          borderRadius: 1,
        }}
      />
      
    </Box>*/}
        <ListItemText
            primary={isNotFormatFile ? file : name}
            secondary={isNotFormatFile ? '' : fData(size)}
            secondaryTypographyProps={{
                component: 'span',
                typography: 'caption',
            }}
        />
    </Box>);
      /*  <Box>
            <FileThumbnail
                tooltip
                imageView
                file={file}
                sx={{position: 'absolute'}}
                imgSx={{position: 'absolute'}}
            />

            {onRemove && (<IconButton
                    size="small"
                    onClick={() => onRemove(file)}
                    sx={{
                        p: 0.5,
                        top: 4,
                        right: 4,
                        position: 'absolute',
                        color: 'common.white',
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                        '&:hover': {
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                        },
                    }}
                >
                    <Iconify icon="mingcute:close-line" width={14}/>
                </IconButton>)}
        </Box>);*/
}
