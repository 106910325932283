import {UseFormReturn} from "react-hook-form";
import {
    getFirstDateOfCurentMonth,
    getFirstDateOfCurrentYear,
    getFirstDateOfPreviousMonth,
    getFirstDateOfPreviousYear,
    getLastDateOfPreviousMonth,
    getLastDateOfPreviousYear
} from "../../../utils/DateTimeUtils";
import React from "react";
import Chip from "@mui/material/Chip";

export type ChangeValue = "CM" | "PM" | "CY" | "PY" | "OwnConfig";

interface SetDateButtonProps {
    startFieldName?: string
    endFieldName?: string
    changeValue: ChangeValue
    buttonLabel: string;
    ownConfig?: { start: Date, end: Date }
    useFormMethods: UseFormReturn<any>
    setSelectedInParent?: (value: ChangeValue) => void
    selected?: boolean
}

// make typeFromSwitchValue

export const QuickSetDateRangeValueButton = ({
                                                 changeValue,
                                                 buttonLabel,
                                                 startFieldName = 'start',
                                                 endFieldName = 'end',
                                                 ownConfig,
                                                 useFormMethods,
                                                 setSelectedInParent,
                                                 selected=false

                                             }: SetDateButtonProps) => {
    const {
        register,
        setValue,
        watch
    } = useFormMethods;
    const ownStartValue = ownConfig?.start;
    const ownEndValue = ownConfig?.end;
    const startOldValue = watch(startFieldName);
    const endOldValue = watch(endFieldName);
    let buttonTitle = '';

    function handleClick() {
        let startNewValue: Date | null = null;
        let endNewValue: Date | null = null;

        switch (changeValue) {
            case ("CM"):
                startNewValue = getFirstDateOfCurentMonth()
                endNewValue = new Date();
                break;
            case ("PM"):
                startNewValue = getFirstDateOfPreviousMonth()
                endNewValue = getLastDateOfPreviousMonth();
                break;
            case ("CY"):
                startNewValue = getFirstDateOfCurrentYear()
                endNewValue = new Date()
                break;
            case ("PY"):
                startNewValue = getFirstDateOfPreviousYear()
                endNewValue = getLastDateOfPreviousYear()
                break;
            case ("OwnConfig"):
                startNewValue = ownStartValue?ownStartValue:startOldValue
                endNewValue = ownEndValue?ownEndValue:endOldValue
        }
        setValue(startFieldName, startNewValue);
        setValue(endFieldName, endNewValue);
        if (setSelectedInParent) {
            setSelectedInParent(changeValue)
        }

    }

    return (<Chip
            size="small" label={buttonLabel} color={selected?'success':'primary'}
            onClick={handleClick}
            /* sx={{ m: 0, p: 0, minWidth: '0px', width: 'fitContent' }}*/
        />

    )
}
