import { FormikProps } from "formik";
import { GetSubscriptionProlongationInfoResult } from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import { BigFormLabel } from "../../components/FormLabel";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { getErrorMessage } from "../../utils/ErrorUtils";
import Notify from "../../utils/Notify";
import BigForm from "../formParts/BigForm";
import InvoiceFormPart from "../formParts/InvoiceFormPart";
import TargetFormPart from "../formParts/TargetFormPart";
import { getAvailableNumberOfPeriods, getSubscriptionEffectivePrice, getTimeRangeForSubcriptionProlongation } from "../SubscriptionsUtils";
import ProlongSubscriptionFormState from "./ProlongSubscriptionFormState";

export default (props: {
    isMobile: boolean,
    args: FormikProps<ProlongSubscriptionFormState>,
    info: GetSubscriptionProlongationInfoResult
}) => {
    const {isMobile, args, info} = props;
    const {values, errors, touched, setFieldTouched, setFieldValue} = args;

    React.useEffect(() => {
        const checkPrice = async (subscriptionId: string, discountCode: string, spotId: string, numberOfPeriods: number) => {
            setFieldValue("promotionCodeProgress", true);
            const {discountedPrice, price, message} = await getSubscriptionEffectivePrice(subscriptionId, discountCode, spotId, numberOfPeriods);
            if(message){
                Notify.Error(message);
                setFieldValue("discountCode", "");
            }
            else{
                if(discountCode){
                    setFieldValue("currentPrice", price);
                    setFieldValue("discountCodePrice", discountedPrice);
                }
                else{
                    setFieldValue("currentPrice", price);
                    setFieldValue("discountCodePrice", null);
                }              
            }
            setFieldValue("promotionCodeProgress", false);
        }
        checkPrice(values.subscriptionId, values.discountCode, info.spotId, values.numberOfPeriods);
    }, [values.subscriptionId, values.discountCode, info.spotId, values.numberOfPeriods])

    React.useEffect(() => {
        const getTimeRange = async (pId: string, nOP: number) => {
            setFieldValue("targetFrom", null);
            setFieldValue("targetTo", null);
            const {error, from, to} = await getTimeRangeForSubcriptionProlongation(pId, nOP);
            if(error){
                Notify.Error(getErrorMessage(error));
            }
            else{
                setFieldValue("targetFrom", from);
                setFieldValue("targetTo", to);
            }
        }

        getTimeRange(info.id, values.numberOfPeriods);
    }, [values.numberOfPeriods, info.id]);

    return (
        <BigForm 
            isMobile={isMobile}
            title={Resources.Dane_abonamentu}
            noOverflow
        >
            <div style={{width: isMobile ? "100%": 315}}>
                    <BigFormLabel>{Resources.Okres_obowiazywania_abonamentu}</BigFormLabel>
                    <BaseSpaceSeparator size={15} />
                    <RegularDropdownList 
                        zIndex={40}
                        actions={getAvailableNumberOfPeriods(info.periodType, info.durationInDays)}
                        error={errors.numberOfPeriods}
                        name="numberOfPeriods"
                        touched={touched.numberOfPeriods}
                        value={values.numberOfPeriods}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        borderColor={Colors.brownish_grey}
                        maxContentHeight={250}
                        label={Resources.Okres}
                    />
                </div>
            <TargetFormPart 
                args={args}
                isMobile={isMobile}    
            />
            <BaseSpaceSeparator size={40} />
            <InvoiceFormPart 
                isMobile={isMobile}
                args={args}
            />
        </BigForm>
    )
}