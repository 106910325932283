import {FormikHelpers} from "formik";
import * as React from "react";
import ModalForm from "../../components/ModalForm";
import * as yup from "yup";
import Resources from "../../../Resources";
import RegularInput from "../../components/forms/RegularInput";
import {getErrorMessage, getErrorMessageNew} from "../../utils/ErrorUtils";
import {useJWT} from "../../utils/JWTUtils";
import {ServiceConfig} from "parkcash-api";
import RegularDateTimePicker from "../../components/forms/RegularDateTimePicker";
import {
    CreateLprWhiteListEntryDto, GetExternalParkingAreasAreaInfo, LprWhiteListService
} from "parkcash-api";
import {nameof} from "../../utils/NameOf";
import {SwitchWithLabel} from "../../components/Switch";
import DropdownList from "../../components/DropdownList";
import Enumerable from "linq";

const driver = require("../../../assets/images/driver.svg");
const now = new Date();

interface FormState {
    licensePlateNumber: string,
    description: string,
    area: string,
    isUnrestrictedAccess: boolean;
    validFrom?: Date | undefined,
    validTo?: Date | undefined,
}

export default (props: {
    visible: boolean, onClose: () => void; onSubmitted: () => void; parkingId: string; areas: GetExternalParkingAreasAreaInfo[];
}) => {
    const {
        onClose,
        visible,
        onSubmitted,
        parkingId,
        areas
    } = props;
    const jwt = useJWT();
    const initialValues: FormState = {
        licensePlateNumber: "",
        description: "",
        area: Enumerable.from(areas).firstOrDefault()?.id ?? "",
        isUnrestrictedAccess: true,
        validFrom: undefined,
        validTo: undefined,
    }

    const onSubmit = async ({
                                licensePlateNumber,
                                description,
                                area,
                                validFrom,
                                validTo,
                            }: FormState, {
                                setStatus,
                                setSubmitting
                            }: FormikHelpers<FormState>) => {
        try {
            setSubmitting(true);
            const {
                isSuccess,
                error
            } = await new LprWhiteListService(new ServiceConfig({jwt})).createLprWhiteListEntry(new CreateLprWhiteListEntryDto(
                {
                    parkingId: parkingId,
                    licensePlateNumber: licensePlateNumber,
                    description: description,
                    area: area === "default" ? null : area,
                    validFrom: validFrom,
                    validTo: validTo,
                }));
            if (isSuccess) {
                onSubmitted();
            } else {
                setStatus(getErrorMessageNew(error));
            }
        } catch {
            setStatus(getErrorMessage());
        } finally {
            setSubmitting(false);
        }
    }

    const validationSchema = yup.object().shape({
        licensePlateNumber: yup.string().required(Resources.Wymagane),
    })

    return (<ModalForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        icon={driver}
        iconHeight={110}
        iconWidth={110}
        visible={visible}
        onClose={onClose}
        title={Resources.Dodaj_Dostep}
        showCancelButton
    >
        {({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue
          }) => {
            return (<>
                <RegularInput
                    label={Resources.Numer_rejestracyjny}
                    value={values.licensePlateNumber}
                    error={errors.licensePlateNumber}
                    touched={touched.licensePlateNumber}
                    name={nameof<FormState>('licensePlateNumber')}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                />
                <RegularInput
                    label={Resources.Opis}
                    value={values.description}
                    error={errors.description}
                    touched={touched.description}
                    name={nameof<FormState>('description')}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                />
                <DropdownList
                    zIndex={20}
                    placeholder={Resources.Obszar}
                    //value={Enumerable.from(areas.map(a => a.id)).firstOrDefault()}
                    value={values.area}
                    onChange={id => setFieldValue(nameof<FormState>("area"), id)}
                    actions={areas.map(a => ({
                        id: a.id,
                        text: a.name
                    }))}
                />
                <SwitchWithLabel
                    value={values.isUnrestrictedAccess}
                    onChange={v => {
                        setFieldValue(nameof<FormState>("isUnrestrictedAccess"), v);
                        if (v) {
                            setFieldValue(nameof<FormState>("validFrom"), undefined);
                            setFieldValue(nameof<FormState>("validTo"), undefined);
                        }
                    }}
                    label={Resources.Nieograniczony_dostep}
                />
                {!values.isUnrestrictedAccess && (<>
                    <RegularDateTimePicker
                        error={errors.validFrom}
                        touched={touched.validFrom}
                        value={values.validFrom}
                        name={nameof<FormState>('validFrom')}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        label={Resources.Waznosc_od}
                        min={now}
                    />
                    <RegularDateTimePicker
                        error={errors.validTo}
                        touched={touched.validTo}
                        value={values.validTo}
                        name={nameof<FormState>('validTo')}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        label={Resources.Waznosc_do}
                        min={now}
                    />
                </>)}
            </>)
        }}
    </ModalForm>)
}