import * as React from "react";
import Colors from "../../styles/Colors";
import { PCText } from "../../styles/Texts";
import DropdownList, {DropdownListProps} from "../DropdownList";

interface Props<T> extends DropdownListProps<T>{
    error: string,
    name: string;
    touched: boolean;
    
    setFieldValue: (key: string, value) => void;
    setFieldTouched: (key: string, touched: boolean) => void;
}


export default function<T>(props: Props<T>) {
    const {value, onChange, setFieldTouched, touched,onBlur,onFocus,name,setFieldValue,error, ...rest}  = props;
    const isError = error && touched;

    return (
        <>
            <DropdownList<T>
                value={value} 
                onChange={v => {
                    onChange && onChange(v);
                    setFieldValue(name, v);
                }}
                onBlur={() => {
                    setFieldTouched(name, true);
                    onBlur && onBlur();
                }}
                onFocus={() => {
                    setFieldTouched(name, false);
                    onFocus && onFocus();
                }}
                isInvalid={isError}
                {...rest}
            />
            <div style={{paddingTop: 4, paddingBottom: 4, height: 20, boxSizing: "border-box"}}>
                <PCText fontSize={12} lineHeight={1} letterSpacing={0} color={Colors.red}>{isError ? error : ""}</PCText>
            </div>
        </>
    );
}