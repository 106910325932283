import * as React from "react";
import Colors from "../styles/Colors";
import {MapContainer, TileLayer, Marker, Popup} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L, { LatLng } from "leaflet";
import Spinner from "../components/Spinner";
import GeolocationHelper from "../utils/GeolocationHelper";
import { ExtendedParkingInfo, ParkingSpotInfo, SearchResult } from "./SearchResult";
import MarkerClusterGroup from 'react-leaflet-markercluster';
import "./Map.scss";
import { StandardButton } from "../styles/Buttons";
import Resources from "../../Resources";

const markerGreen = require("../../assets/images/markerGreen.svg");
const markerLightRoyalBlue = require("../../assets/images/markerLightRoyalBlue.svg");
const markerHeliotrope = require("../../assets/images/markerHeliotrope.svg");
const markerMango = require("../../assets/images/markerMango.svg");

const ICON_SIZE = 20;

const iconMango  = new L.Icon({
    iconUrl: markerMango,
    iconRetinaUrl: markerMango,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(ICON_SIZE, ICON_SIZE),
    className: 'leaflet-div-icon'
});

const iconLightRoyalBlue  = new L.Icon({
    iconUrl: markerLightRoyalBlue,
    iconRetinaUrl: markerLightRoyalBlue,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(ICON_SIZE, ICON_SIZE),
    className: 'leaflet-div-icon'
});

const iconHeliotrope  = new L.Icon({
    iconUrl: markerHeliotrope,
    iconRetinaUrl: markerHeliotrope,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(ICON_SIZE, ICON_SIZE),
    className: 'leaflet-div-icon'
});

const iconGreen  = new L.Icon({
    iconUrl: markerGreen,
    iconRetinaUrl: markerGreen,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(ICON_SIZE, ICON_SIZE),
    className: 'leaflet-div-icon'
});

interface Props {   
    searchResult: SearchResult;
    initialParkings: ExtendedParkingInfo[];
    currentParkingId: string;
    searchWasAlreadyDone: boolean;

    onParkingPressed: (parking: ExtendedParkingInfo) => void;
    onReady?: () => void;
    onSearchHere: (lat: number, lon: number) => void;
}

const getMarker = (type: "startStop" | "normal", hasFreePlaces: boolean, hasMyPlaces: boolean) => {
    if(type === "startStop"){
        return iconLightRoyalBlue;
    }

    if(hasMyPlaces){
        return iconHeliotrope;
    }

    if(hasFreePlaces){
        return iconGreen;
    }

    return iconMango;
}

const createClusterCustomIcon = function (cluster) {
    return L.divIcon({
      html: `<div>${cluster.getChildCount()}</div>`,
      className: 'marker-cluster-custom',
      iconSize: L.point(40, 40, true),
    });
  }

const DEFAULT_ZOOM = 17;
export default (props: Props) => {
    const {currentParkingId, initialParkings, onParkingPressed, onReady, searchResult, searchWasAlreadyDone, onSearchHere} = props;
    const [progress, setProgress] = React.useState(true);
    const [position, setPosition] = React.useState<LatLng>(null);
    const [map, setMap] = React.useState<L.Map>(null);

    const onSearchHereClicked = () => {
        const m = map?.getCenter();
        if(m){
            onSearchHere(m.lat, m.lng);
        }
    }

    React.useEffect(() => {
        GeolocationHelper.getLocation()
        .then(pos => {
            setPosition(new LatLng(pos.latitude, pos.longitude));
            setProgress(false);
            onReady && onReady();
        })
    }, []);

    React.useEffect(() => {
        if(!searchResult){
            return;
        }

        const searchTarget = searchResult.getSearchTarget();
        const latitude = searchTarget.getLatitude();
        const longitude = searchTarget.getLongitude();
        map.setView(new LatLng(latitude, longitude), DEFAULT_ZOOM);
    }, [searchResult]);

    const parkings = searchResult ? searchResult.getParkings() : [];
    const targetParkings = searchWasAlreadyDone ? parkings : initialParkings;

    const handleClick = () => {
        console.log('handle click');
    }

    return (
        <div
            style={{
                width: '100%',
                boxSizing: "border-box",
                boxShadow: "0 3px 26px 4px rgba(0, 0, 0, 0.08)",
                backgroundColor: Colors.white,
                height: 750,
                borderRadius: 25,
                position: 'relative'
            }}
        >
            {progress && (
                <div 
                    style={{
                        display: "flex", 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        borderRadius: 25,
                        backgroundColor: Colors.white, 
                        boxShadow: "0 3px 26px 4px rgba(0, 0, 0, 0.08)",
                        height: 750
                    }}
                >
                    <Spinner size="medium" />
                </div>
            )}
            <div
                style={{position: 'absolute', bottom: 20, zIndex: 10000000, display: 'flex', justifyContent: 'center', width: '100%'}}
            >
                <div style={{width: 300}}>
                    <StandardButton onClick={onSearchHereClicked}>{Resources.Znajdz_miejsce_parkingowe}</StandardButton>
                </div>
            </div>
            {!progress && (
                <MapContainer 
                    center={position} 
                    zoom={DEFAULT_ZOOM} 
                    whenCreated={setMap}
                    scrollWheelZoom={true} 
                    style={{height: 750, width: '100%', borderRadius: 25, boxShadow: "0 3px 26px 4px rgba(0, 0, 0, 0.08)"}} 
                >
                    <TileLayer 
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
                        //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        url="http://parkcash.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                    />
                    {/* <Marker position={position} icon={iconPerson}>
                    
                    </Marker> */}
                    <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
                        {(targetParkings || []).filter(p => p.type !== "startStop").map(parking => {
                            const {lat, lon} = parking;
                            const parkingPosition = new L.LatLng(lat, lon)
                            if(parking.parkingId === currentParkingId){
                                return (
                                    <Marker
                                        //onClick={handleClick}
                                        key={parking.parkingId} 
                                        position={parkingPosition}                                   
                                    />
                                );
                            }
                            else{
                                return (
                                    <Marker
                                        //onClick={handleClick}
                                        key={parking.parkingId} 
                                        position={parkingPosition} 
                                        
                                        icon={getMarker(parking.type, parking.hasFreePlaces, parking.hasMyPlaces)}                                  
                                    />
                                );
                            }
                        })}
                    </MarkerClusterGroup>
                </MapContainer>
            )}
        </div>
    )
}