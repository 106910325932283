/*
//Get Dto
export interface IGetParkingAuthorizedEmailDomainsResult { 
    domains?: GetParkingAuthorizedEmailDomainEntry[];
}*/

import {IAssignAuthorizedDomainsToParkingDto, IGetParkingAuthorizedEmailDomainsResult } from "parkcash-api"
import { ParkingDomainFormValues } from "./ParkingDomenFormTypes"
import {AssignAuthorizedDomainsToParkingDomeinEntry} from "parkcash-api";

export const mapGetDomainsDtoToFormValues = (dto: IGetParkingAuthorizedEmailDomainsResult): ParkingDomainFormValues => {
    return {
        domains: dto?.domains?.map((d) => ({
            domain: d.domain
        })) ?? [],
        newDomain: ""
    }
}
export const mapDomainFormValuesToCreateDto = (values: ParkingDomainFormValues, parkingId: string): IAssignAuthorizedDomainsToParkingDto => {
    const domains:AssignAuthorizedDomainsToParkingDomeinEntry[] = values?.domains?.map((d):AssignAuthorizedDomainsToParkingDomeinEntry => (
       new AssignAuthorizedDomainsToParkingDomeinEntry({domain:d.domain})
    )) ?? [];
    return {
        parkingId: parkingId,
        domains:domains
    }
}