import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { BOTTOM_COLOR, BOTTOM_FONT_SIZE } from "./Constants";

export default (props: {
    onPageChanged: (p: number) => void;
    page: number;
    pageSize: number;
    totalItems: number;
}) => {
    const {onPageChanged,page,pageSize, totalItems} = props;
    const first = (page - 1)*pageSize + 1;
    const second = page*pageSize;
    const third = totalItems;

    const hasPreviousPage = first > 1;
    const hasNextPage = second < third;

    return (
        <div style={{display: 'inline-flex', alignItems: 'center'}}>
            <div 
                style={{
                    cursor: 'pointer',
                    pointerEvents: hasPreviousPage ? undefined : "none",
                    opacity: hasPreviousPage ? 1 : 0.5
                }}
            >
                <FontAwesomeIcon icon={faCaretLeft} color={BOTTOM_COLOR} style={{fontSize: BOTTOM_FONT_SIZE, letterSpacing: 0}} onClick={() => onPageChanged(page-1)}/>
            </div>
            <BaseSpaceSeparator size={10} />
            <PCText color={BOTTOM_COLOR} fontSize={BOTTOM_FONT_SIZE}>{first}-{second} of {third}</PCText>
            <BaseSpaceSeparator size={10} />
            <div 
                style={{
                    cursor: 'pointer',
                    pointerEvents: hasNextPage ? undefined : "none",
                    opacity: hasNextPage ? 1 : 0.5
                }}
            >
                <FontAwesomeIcon icon={faCaretRight} color={BOTTOM_COLOR} style={{fontSize: BOTTOM_FONT_SIZE, letterSpacing: 0}} onClick={() => onPageChanged(page+1)}/>
            </div>
        </div>
    )
}