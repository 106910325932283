import { GetAccessHistoryViewReservationType } from "parkcash-api";
import Resources from "../../../../Resources";
import {GetAccessHistoryViewReaderType, LinkSource} from "parkcash-api";

export const mapGetAccessHistoryViewReservationTypeToLocalizedString = (
  reservationType: GetAccessHistoryViewReservationType
): string => {
  switch (reservationType) {
    case GetAccessHistoryViewReservationType.Reservation:
      return Resources.Rezerwacja
    case GetAccessHistoryViewReservationType.Whitelist:
      return Resources.Staly_dostep
    case GetAccessHistoryViewReservationType.RotaryTicket:
      return Resources.bilet
    case GetAccessHistoryViewReservationType.Subscription:
        return Resources.abonament
    default:
      return "";
  }
};

export const mapGetAccessHistoryViewReaderTypeToLocalizedString = (readerType: GetAccessHistoryViewReaderType): string => {
  switch (readerType) {
    case GetAccessHistoryViewReaderType.Lpr:
      return Resources.LPR
    case GetAccessHistoryViewReaderType.MobileRemote:
      return Resources.Mobilny_pilot
    case GetAccessHistoryViewReaderType.QrCode:
        return Resources.Kod_QR
    default:
      return "";
  }
}
export const mapLinkSourceToLocalizedString = (linkSource: LinkSource): string => {
  switch (linkSource) {
    case LinkSource.OrdinarySharing:
      return Resources.Udostepnianie
    case LinkSource.ExternalReservationSharing:
      return Resources.Rezerwacja_zewnetrzna
    case LinkSource.ReservationSharing:
        return Resources.Rezerwacja
    default:
      return Resources.Nieznany;
  }
}