import React from "react";
import {IGetParkingParkingSpotsViewResultEntryDto} from "parkcash-api";
import Stack from "@mui/material/Stack";
import {faUser, faUsers} from "@fortawesome/free-solid-svg-icons";
import {ActionIcon} from "../../../components/PCDataGrid/ActionColumn";
import Resources from "../../../../Resources";
import Colors from "../../../styles/Colors";
import {PCText} from "../../../styles/Texts";

interface Props {
    spot: IGetParkingParkingSpotsViewResultEntryDto;
}

export const SpotAsigmentDescriptionWithIcon = ({spot}: Props) => {
    const {
        isAssignedPermanently,
        whiteListEntriesIds,
        ownerFirstName,
        ownerLastName,
        ownerEmail
    } = spot ?? {};
    return (<Stack direction={'row'} spacing={2} width={"auto"} height={"100%"} alignItems={"center"}>
        {isAssignedPermanently || whiteListEntriesIds?.length ?
            <ActionIcon icon={faUser} title={Resources.Przypisane_na_stale}/> :
            <ActionIcon icon={faUsers} title={Resources.Miejsce_rotacyjne}/>}
        <PCText
            fontSize={16}
            lineHeight={20 / 16}
            color={Colors.brownish_grey}
            letterSpacing={0}
            semibold
        >
            {isAssignedPermanently ?
                (<>
                    {ownerFirstName} {ownerLastName}
                    <br/>
                    {ownerEmail}
                </>) : whiteListEntriesIds?.length ? Resources.Przypisane_na_stale :
                    Resources.Miejsce_rotacyjne}
        </PCText>

    </Stack>)
}