import * as React from "react";
import ModalForm from "../components/ModalForm";
import * as yup from "yup";
import Resources from "../../Resources";
import { FormikHelpers } from "formik";
import RegularInput from "../components/forms/RegularInput";
import { AddVirtualPilotDto, ErrorCode, IVirtualPilotDto, ServiceConfig, VirtualPilotsService } from "parkcash-api";
import { virtualPilotAdded } from "./Redux";
import { connect } from "react-redux";
import { applicationState } from "../redux/ApplicationState";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import Colors from "../styles/Colors";
import { getErrorMessage } from "../utils/ErrorUtils";
import {Asserts} from "yup";

const pilot = require("../../assets/images/pilot.svg");

const validationSchema = yup.object({
    pilotId: yup.string().required(Resources.Wymagane),
    pin: yup.string().required(Resources.Wymagane)
});

interface FormState extends Asserts<typeof validationSchema> {}

interface OwnProps {
    onClose: () => void;
    visible: boolean;
}

interface Props extends OwnProps {
    jwt: string;
    onPilotAdded: () => void;
}

const Container = (props: Props) => {
    const {onClose, visible, jwt, onPilotAdded} = props;
    const initialValues: FormState = {
        pilotId: "",
        pin: ""
    }
    

    const onSubmit = async (state: FormState, formikHelpers: FormikHelpers<FormState>) => {
        const {setStatus, setSubmitting} = formikHelpers;
        try{
            const response = await new VirtualPilotsService(new ServiceConfig({jwt})).addVirtualPilot(new AddVirtualPilotDto({
                friendlyName: state.pilotId,
                pin: state.pin
            }));
            if(response.isSuccess){
                onPilotAdded();
                onClose();
            }
            else{
                switch(response.error.code){
                    case ErrorCode.AccessToVirtualPilotExpired:
                        setStatus(Resources.Dostep_do_wirtualnego_pilota_wygasl);
                        break;
                    case ErrorCode.IncorrectVirtualPilotPIN: 
                    case ErrorCode.AggregateDoesNotExist:
                        setStatus(Resources.Niepoprawny_identyfikator_lub_pin);
                        break;
                    case ErrorCode.VirtualPilotAlreadyAssignedToUser:
                        setStatus(Resources.Posiadasz_juz_dostep_do_tego_pilota);
                        break;
                        
                    default:
                        setStatus(getErrorMessage(response.error.code));
                        break;   
                    
                }
            }
        }
        catch{
            setStatus(Resources.Blad_zapisu)
        }
        finally{
            setSubmitting(false)
        }
    }

    return (
        <ModalForm 
            onClose={onClose} 
            visible={visible}
            initialValues={initialValues}
            onSubmit={onSubmit}
            icon={pilot}
            iconWidth={100}
            iconHeight={114}
            title={Resources.Pilot_dostepu}
            validationSchema={validationSchema}
            showCancelButton
            bottom={(
                <>
                    <BaseSpaceSeparator size={20} />
                    <PCText textAlign="center" fontSize={14} color={Colors.brownish_grey}>
                        {Resources.Jesli_jeszcze_nie_masz_swojego_pilota_zdobądź_go_pod_adresem} <a target="_blank" href={'https://www.mobilnypilot.pl'} style={{textDecoration: 'none'}}>mobilnypilot.pl</a>
                    </PCText>
                </>
            )}
        >
        {args => {
            const {values, touched, errors, setFieldValue, setFieldTouched} = args;
            return (
                <>
                    <RegularInput 
                        value={values.pilotId}
                        touched={touched.pilotId}
                        error={errors.pilotId}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        name="pilotId"
                        label={Resources.Identyfikator_pilota}
                        showClearButton
                        showValidatedButton
                    />
                    <RegularInput 
                        password
                        value={values.pin}
                        touched={touched.pin}
                        error={errors.pin}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        name="pin"
                        label={Resources.PIN}
                    />
                </>
            )
        }}
        </ModalForm>
    );
}

const mapStateToProps = (state: applicationState, ownProps: OwnProps): Partial<Props> => ({
    jwt: state.user.token,
    ...ownProps
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
    onPilotAdded: () => dispatch(virtualPilotAdded())
})

export default connect(mapStateToProps, mapDispatchToProps)(Container);