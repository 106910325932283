import * as React from "react";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import Dropdown from "../../../../components/Dropdown";
import {applicationState} from "../../../../redux/ApplicationState";
import Colors from "../../../../styles/Colors";
import {PCText} from "../../../../styles/Texts";
import {useUser} from "../../../../user/UseUserHook";
import {PCClasses} from "../../../../utils/CSSUtils";
import {ExternalRedirectConfig, IParkCashApplicationMode} from "../../../../utils/ParkCashApplicationMode";
import Link, {LinkContent} from "../Common/Link";

interface OwnProps {
    scrolled: boolean
}

interface Props extends OwnProps {
    applicationMode: IParkCashApplicationMode;
}

const Links = (props: Props) => {
    const {applicationMode, scrolled} = props;
    const {hidePilotsManagement, hideGroups} = useUser();
    const links = applicationMode.getMenuConfiguration(hidePilotsManagement, hideGroups);
    const showSeparator = links.length > 1;
    const {pathname} = useLocation();

    function handleExternalNewWindowRedirect(externalAnchorRedirectConfig:ExternalRedirectConfig): void {
        const newWindow = window.open(externalAnchorRedirectConfig.href, externalAnchorRedirectConfig.target || '_blank');
        if (newWindow) {
            newWindow.opener = null; // For security reasons
        }
       
    }

    return (
        <div className={PCClasses("pc-desktopheader-linkscontainer")}>
            {links.map((link, index, arr) => (
                <React.Fragment key={link.to || index}>
                    {showSeparator && index !== 0 && (
                        <PCText color={Colors.very_light_pink} fontSize={24} lineHeight={1.66}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </PCText>
                    )}
                    {!!link.externalRedirect && <Link text={link.text} isActive={false} onClick={()=>handleExternalNewWindowRedirect(link.externalRedirect)} /> }
                    {!!link.to && <Link isActive={pathname === link.to} text={link.text} to={link.to} />}
                    {!!link.submenus && (
                        <Dropdown
                            
                            actions={link.submenus.map(s => ({
                                text: s.text,
                                to: s.to,
                                selected: pathname === s.to,
                            }))}
                        >
                            <LinkContent 
                                text={link.text} 
                                isActive={link.submenus.some(l => l.to === pathname)}
                            />
                        </Dropdown>
                    )}                 
                </React.Fragment>
            ))}
        </div>
    );
}

const mapStateToProps = (state: applicationState, ownProps: OwnProps): Props => ({
    ...ownProps,
    applicationMode: state.user.applicationMode
});

export default connect(mapStateToProps)(Links);