import clsx from "clsx";

type PCClasses =
  | "pc-textbutton-container"
  | "pc-button-container"
  | "pc-button-container-disabled"
  | "pc-main-wrapper"
  | "pc-main-container"
  | "pc-desktopheader-container"
  | "pc-desktopheader-linkscontainer"
  | "pc-header-link"
  | "pc-desktopheader-userdropdown"
  | "pc-desktopheader-userdropdown-initials"
  | "pc-dropdown-content"
  | "pc-dropdown-link"
  | "pc-dropdown-linkselected"
  | "pc-dropdown-container"
  | "pc-facebook-login"
  | "pc-list-container"
  | "pc-body"
  | "pc-body-mobile"
  | "pc-button-container-big"
  | "pc-button-container-tiny"
  | "pc-virtualpilotslist-item"
  | "pc-virtualpilotslist-item-inactive"
  | "pc-virtualpilotslist-item-remove"
  | "pc-confirmmodal-wrapper"
  | "pc-confirmmodal-container"
  | "pc-confirmodal-contentcontainer"
  | "pc-confirmmodal-closebutton"
  | "pc-modalform"
  | "pc-modalform-content"
  | "pc-modalform-closebutton"
  | "pc-parkinspotslist-item"
  | "pc-parkinspotslist-item-remove"
  | "pc-parkinspotslist-item-inactive"
  | "pc-dropdownlist-link"
  | "pc-sharinggrid-filter"
  | "pc-dropdown-content-reversed"
  | "pc-button"
  | "pc-datetimepicker-wrapper"
  | "pc-datetimepicker-content"
  | "pc-modalformhown"
  | "pc-profile-container"
  | "pc-profileaddresses-container"
  | "pc-profileuserinfo-container"
  | "pc-profilevehicles-container"
  | "pc-profilepayments-container"
  | "pc-modalform-topicon"
  | "pc-reservationdetailsgrid"
  | "pc-custom-big-calendar"
  | "pc-dropdownlist-link-current"
  | "pc-modalform-header"
  | "pc-modalform-contentcontainer";

type ClassValue = ClassDictionary | PCClasses;

type ClassDictionary = {
  [id in PCClasses]?: boolean;
};

export const PCClasses = (
  classes: Array<PCClasses> | PCClasses,
  cond?: ClassDictionary
) => {
  return clsx(classes, cond);
};
