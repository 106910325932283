import * as React from "react";
import Colors from "../../styles/Colors";
import { PCText } from "../../styles/Texts";
import TextArea, { TextAreaProps } from "./TextArea";

export interface RegularTextAreaProps extends TextAreaProps {
    error: string,
    touched: boolean,
    value: string;
    name: string;
    
    setFieldValue: (key: string, value) => void;
    setFieldTouched: (key: string, touched: boolean) => void;
    autoTrim?: boolean;
}

export default class RegularTextArea extends React.Component<RegularTextAreaProps> {
    
    private textArea: TextArea;

    constructor(props){
        super(props);
    }

    focus(){
        this.textArea && this.textArea.focus();
    }

    blur(){
        this.textArea && this.textArea.blur();
    }
    
    render(){
        const {
            error, 
            touched, 
            value, 
            name, 
            setFieldValue, 
            setFieldTouched, 
            onChangeText, 
            onBlur, 
            autoTrim = false,
            onFocus, 
            ...restProps
        } = this.props;
        const isError = error && touched;
          
        return (
            <>
                <TextArea
                    {...restProps}
                    ref={i => this.textArea = i}
                    value={value}
                    onChangeText={text => {
                        const targetText = autoTrim ? text.trim() : text;
                        setFieldValue(name, targetText);
                        onChangeText && onChangeText(targetText);
                    }}
                    onBlur={e => {
                        setFieldTouched(name, true);
                        onBlur && onBlur(e);
                    }}
                    onFocus={e => {
                        setFieldTouched(name, false);
                        onFocus && onFocus(e);
                    }}
                    isInvalid={isError}
                />
                <div style={{paddingTop: 4, paddingBottom: 4, height: 20, boxSizing: "border-box"}}>
                    <PCText fontSize={12} lineHeight={1} letterSpacing={0} color={Colors.red}>{isError ? error : ""}</PCText>
                </div>
            </>
        )
    }
}