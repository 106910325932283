import * as React from "react";
import Colors from "../../styles/Colors";
import { SOURCESANSPRO_BOLD } from "../../styles/Texts";
import Triangle from "../Triangle";

export default (props: {
    onLeft: () => void,
    onRight: () => void,
    onUp: () => void,
    onDown: () => void,
    onZoomIn: () => void,
    onZoomOut: () => void,
    height: number,
    width: number,
    triangleSize: number,
    buttonSize: number
}) => {
    const {onDown, onLeft, onRight, onUp, onZoomIn, onZoomOut, height, width, triangleSize, buttonSize} = props;

    return (
        <div style={{height: height, width: width, position: 'relative'}}>
            <div style={{position: 'absolute', top: 0, left: width/2 - triangleSize/2}}>
                <Triangle className="pc-button" onClick={onUp} color={Colors.light_royal_blue} width={triangleSize} height={triangleSize} />
            </div>

            <div style={{position: 'absolute', left: 0, top: height/2 - triangleSize/2}}>
                <Triangle className="pc-button" onClick={onLeft} rotate={270} color={Colors.light_royal_blue} width={triangleSize} height={triangleSize} />
            </div>

            <div style={{position: 'absolute', bottom: 0, left: width/2 - triangleSize/2}}>
                <Triangle className="pc-button" onClick={onDown} rotate={180} color={Colors.light_royal_blue} width={triangleSize} height={triangleSize} />
            </div>

            <div style={{position: 'absolute', right: 0, top: height/2 - triangleSize/2}}>
                <Triangle className="pc-button" onClick={onRight} rotate={90} color={Colors.light_royal_blue} width={triangleSize} height={triangleSize} />
            </div>

            <div onClick={onZoomIn} className="pc-button" style={{userSelect: "none", position: 'absolute', top: height/2 - buttonSize/2, left: width/2 - buttonSize/2 -10, width: buttonSize, height: buttonSize, color: Colors.light_royal_blue, fontSize: buttonSize, fontWeight: "bolder", display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: SOURCESANSPRO_BOLD}}>
                +
            </div>

            <div onClick={onZoomOut} className="pc-button" style={{userSelect: "none", position: 'absolute', top: height/2 - buttonSize/2 - 2, right: width/2 - buttonSize/2 -10, width: buttonSize, height: buttonSize, color: Colors.light_royal_blue, fontSize: buttonSize, fontWeight: "bolder", display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: SOURCESANSPRO_BOLD}}>
                -
            </div>
        </div>
    )
}