import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { ParkingInfo, ParkingMemberType, ParkingsService, ServiceConfig } from "parkcash-api";
import * as React from "react";
import Resources from "../../Resources";
import IconButton from "../components/IconButton";
import { StandardButton, StandardButton2, StandardButton3 } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { formatAddress } from "../utils/AddressUtils";
import { useJWT } from "../utils/JWTUtils";
import GroupUsersCount from "./GroupUsersCount";

const groups = require("../../assets/images/groups.svg");

export default (props: {
    item: ParkingInfo,
    onManage: (p: ParkingInfo) => void,
    onEdit: (p: ParkingInfo) => void,
    onRemove: (p: ParkingInfo) => void
}) => {
    const {item: {id, friendlyName, address, membersCount, memberType}, onEdit, onManage, onRemove} = props;

    const onEditClicked = () => {
        onEdit(props.item);
    }

    const onManageClicked = () => {
        onManage(props.item);
    }

    const onRemoveClicked = () => {
        onRemove(props.item);
    }

    return (
        <div
            style={{
                borderRadius: 25,
                backgroundColor: Colors.white,
                boxShadow: '0 3px 26px 4px rgba(0, 0, 0, 0.08)',
                width: 360,
                height: 330,
                boxSizing: "border-box",
                padding: 30,
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <GroupUsersCount usersCount={membersCount} />
                <img src={groups} width={86} height={80} />
                <IconButton onClick={onRemoveClicked} icon={faTrashAlt}/>
            </div>
            <BaseSpaceSeparator size={20} />
            <div style={{width: '100%', boxSizing: "border-box", padding: "0px 20px"}}>
                <PCText letterSpacing={0} color={Colors.brownish_grey} fontSize={18} textAlign="center">
                    {formatAddress(address, true)}
                </PCText>
                <BaseSpaceSeparator size={5} />
                <PCText lineBreak="anywhere" letterSpacing={0} color={Colors.black} fontSize={24} textAlign="center">
                    {friendlyName}
                </PCText>
            </div>
            <div style={{flex: 1}} />
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                {memberType !== ParkingMemberType.User && (
                    <div style={{width: 130}}>
                        <StandardButton3 onClick={onEditClicked} variant="tiny">
                            {Resources.Edytuj}
                        </StandardButton3>
                    </div>
                )}
                <div style={{width: 130}}>
                    <StandardButton onClick={onManageClicked} variant="tiny">
                        {Resources.Zarzadzaj}
                    </StandardButton>
                </div>
            </div>
        </div>
    )
}