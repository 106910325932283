import { faCalendarAlt, faClock } from "@fortawesome/free-regular-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DayOfWeek } from "parkcash-api";
import React from "react";
import Resources from "../../../Resources";
import PCDateTimePicker from "../../components/PCDatetimePicker";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { PCClasses } from "../../utils/CSSUtils";

export const DayOfWeekActivePresenter = (props: {
    day: DayOfWeek;
    isActive: boolean;
    onClick: () => void;
}) => {
    const {day, isActive, onClick} = props;

    const translationsMap = {
        [DayOfWeek.Monday]: Resources.poniedzialekOneLetter,
        [DayOfWeek.Tuesday]: Resources.wtorekOneLetter,
        [DayOfWeek.Wednesday]: Resources.srodaOneLetter,
        [DayOfWeek.Thursday]: Resources.czwartekOneLetter,
        [DayOfWeek.Friday]: Resources.piatekOneLetter,
        [DayOfWeek.Saturday]: Resources.sobotaOneLetter,
        [DayOfWeek.Sunday]: Resources.niedzielaOneLetter
    };

    return (
        <div
            className={PCClasses("pc-button")}
            onClick={onClick}
            style={{
                width: 28,
                height: 28,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: isActive ? Colors.heliotrope : Colors.white,
                borderWidth: isActive ? 0 : 1,
                borderColor: Colors.very_light_pink,
                borderStyle: 'solid',
                borderRadius: 28
            }}
        >
            <PCText
                textAlign="center"
                color={isActive ? Colors.white : Colors.brownish_grey}
                fontSize={14}   
            >
                {translationsMap[day]}
            </PCText>
        </div>
    )
}

export const SingleTimePresenter = (props: {
    time: Date,
    onChange: (v: Date) => void;
}) => {
    return (
        <PCDateTimePicker
            mode="time"
            value={props.time}
            onChange={props.onChange}
        >
            {text => (
                <div
                    style={{
                        borderRadius: 28,
                        padding: "6px 12px",
                        borderColor: Colors.very_light_pink,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row'
                    }}
                >
                    <FontAwesomeIcon icon={faClock} color={Colors.light_royal_blue} style={{fontSize: 16}} />
                    <BaseSpaceSeparator size={8}/>
                    <PCText
                        fontSize={14}
                        color={Colors.light_royal_blue}
                    >
                        {text}
                    </PCText>
                </div>
            )}
        </PCDateTimePicker>

        
    )
}

export const SingleDayTimeRangePresenter = (props: {
    day: DayOfWeek,
    start: Date,
    end: Date,
    onStartChange: (v: Date) => void;
    onEndChange: (v: Date) => void;    
}) => {
    const {day,end,onEndChange,onStartChange,start} = props;

    const translationsMap = {
        [DayOfWeek.Monday]: Resources.Poniedzialek,
        [DayOfWeek.Tuesday]: Resources.Wtorek,
        [DayOfWeek.Wednesday]: Resources.Sroda,
        [DayOfWeek.Thursday]: Resources.Czwartek,
        [DayOfWeek.Friday]: Resources.Piatek,
        [DayOfWeek.Saturday]: Resources.Sobota,
        [DayOfWeek.Sunday]: Resources.Niedziela
    }

    return (
        <div
            style={{
                borderRadius: 28,
                borderWidth: 1,
                borderColor: Colors.very_light_pink,
                borderStyle: 'solid',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingLeft: 20,
                paddingRight: 20
            }}
        >
            <FontAwesomeIcon icon={faCalendarAlt} color={Colors.light_royal_blue} style={{fontSize: 16}}/>
            <BaseSpaceSeparator size={10} />
            <PCText
                fontSize={14}
                color={Colors.brownish_grey}
            >
                {translationsMap[day]}
            </PCText>
            <div style={{flex: 1}} />
            <PCDateTimePicker
                mode="time"
                value={start}
                onChange={onStartChange}
            >
                {text => (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: "6px 13px"                       }}
                    >
                        <FontAwesomeIcon icon={faClock} name="clock" color={Colors.light_royal_blue} style={{fontSize: 16, marginRight: 7}}/>
                        <PCText
                            fontSize={14}
                            color={Colors.light_royal_blue}
                        >
                            {text}
                        </PCText>
                    </div>
                )}
            </PCDateTimePicker>
            
            <FontAwesomeIcon icon={faChevronRight} color={Colors.brownish_grey} style={{fontSize: 14}} />

            <PCDateTimePicker
                mode="time"
                value={end}
                onChange={onEndChange}
            >
                {text => (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: "6px 13px"                       
                        }}
                    >
                        <FontAwesomeIcon icon={faClock} name="clock" color={Colors.light_royal_blue} style={{fontSize: 16, marginRight: 7}}/>
                        <PCText
                            fontSize={14}
                            color={Colors.light_royal_blue}
                        >
                            {text}
                        </PCText>
                    </div>
                )}
            </PCDateTimePicker>
        </div>
    )
}