import {IRegisterCommercialParkingDto, PolicyCreationMode} from "parkcash-api";
import ModalForm from "../../../components/ModalForm";
import Resources from "../../../../Resources";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Colors from "../../../styles/Colors";
import {FormikHelpers} from "formik";
import RegularInput from "../../../components/forms/RegularInput";
import {
    AddressDto, ParkingManagerService, ParkingType, RegisterCommercialParkingDto, ServiceConfig
} from "parkcash-api";
import RegularDropdownList from "../../../components/forms/RegularDropdownList";
import {mapEnumToSelectOptions} from "../../../utils/EnumUtils/MapEnumToSelectOptions";
import {useUser} from "../../../user/UseUserHook";
import {useSnackbar} from "../../../MUI/components/snackbar";
import {BaseSpaceSeparator} from "../../../styles/Separators";
import {
    mapPolicyCreationModeToLocalizedDisplayText
} from "../../../utils/EnumUtils/ParkingConfigRelatedEnums/PolicyCreationModeHelpers";
import {MapParkingTypeEnumToLocalizedText} from "../../../utils/EnumUtils/ParkingConfigRelatedEnums/ParkingTypeHelpers";
import {
    useCustomizedNotification
} from "../../../MUI/components/AddedOrModyfiedMuiComponents/CustomizedNotification/useCustomizedNotification";
import {CreateParkingFormValues} from "./parkingConfigFormTypes";
import * as yup from "yup";
import RegularSearchAddressInput from "../../../components/forms/RegularSearchAddressInput";
import {getAddressDtoFromAddressInput} from "./parkingConfigFormUtils";



interface Props {
    /*  parkingFriendlyName: string,*/
    visible: boolean;
    onClose: () => void;
    onSubmitted?: () => void;

}

export const ParkingConfigForm = ({
                                      visible,
                                      onClose,
                                      onSubmitted

                                  }: Props) => {
    const applicationMode = useUser().applicationMode;
    const parkingId = applicationMode.getCurrentParking()?.parkingId || null;
    const {enqueueSnackbar} = useSnackbar();

    const yupSchema = yup.object().nullable().shape({
        friendlyName: yup.string().required(Resources.To_pole_jest_wymagane),
      /*  address: yup.object().nullable().required(Resources.To_pole_jest_wymagane).shape({
            streetNumber: yup.string().required(Resources.Podanie_nr_ulicy_jest_wymagane),
        }),*/
        parkingType: yup.string().required(Resources.To_pole_jest_wymagane),
        policyCreationMode: yup.string().required(Resources.To_pole_jest_wymagane)
    });

    const initialFormValues: CreateParkingFormValues = {
        address: null,
        parkingType: ParkingType.Office,
        friendlyName: "",
        policyCreationMode: PolicyCreationMode.Inherit,
        numberTest: 0
    };

    const parkingTypeSelectOptions = mapEnumToSelectOptions(ParkingType, MapParkingTypeEnumToLocalizedText, false);
    const policyCreationTypeSelectOptions = mapEnumToSelectOptions(PolicyCreationMode,
        mapPolicyCreationModeToLocalizedDisplayText,
        false
    );
    const jwt = useUser().token;
    const {
        enqueError,
        enqueSuccess
    } = useCustomizedNotification();
    return (<ModalForm
        initialValues={initialFormValues}
        iconWidth={90}
        iconHeight={110}
        visible={visible}
        onClose={onClose}
        title={Resources.Dodaj_parking}
        validationSchema={yupSchema}
        onSubmit={async (values: CreateParkingFormValues, actions: FormikHelpers<CreateParkingFormValues>) => {
            console.log(values);
            const iDto: IRegisterCommercialParkingDto = {
                address: getAddressDtoFromAddressInput(values.address ),
                friendlyName: values.friendlyName,
                policyCreationMode: values.policyCreationMode,
                parkingType: values.parkingType,
                contextParkingId: parkingId // id of parking from which action is performed
            }
            const dto: RegisterCommercialParkingDto = new RegisterCommercialParkingDto(iDto);
            try {
              const result=  await new ParkingManagerService(new ServiceConfig({jwt})).registerCommercialParking(dto);
                if (!result.isSuccess) {
                    throw new Error("An Errorr during saving occured")
                }
                onClose();
                enqueSuccess(Resources.Dane_zostaly_pomyslnie_zapisane);
                if (onSubmitted) {
                    onSubmitted();
                }
            } catch (e) {
                onClose();
                enqueError(Resources.Wystapil_blad_podczas_zapisu_danych);
            }
        }}
    >
        {({
              setFieldTouched,
              setFieldValue,
              values,
              errors,
              touched
          }) => <Stack>
         {/*   {<p>{JSON.stringify(values?.address)}</p>}*/}
          
            <RegularInput
                label={Resources.Nazwa}
                value={values.friendlyName}
                error={errors.friendlyName}
                touched={touched.friendlyName}
                name={"friendlyName"}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                showClearButton
                showValidatedButton
            />
            <RegularSearchAddressInput
                error={errors.address}
                current={values.address}
                zIndex={1000}
                name={"address"}
                label={Resources.Podaj_adres_miejsca}
                placeholder={Resources.Wpisz_adres}
                borderColor={Colors.brownish_grey}
                disabled={false}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched.address}/>
         {/*   <BaseSpaceSeparator size={20}/>*/}
            <RegularDropdownList
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                error={errors.parkingType}
                touched={touched.parkingType}
                value={values.parkingType?.toString()}
                name="parkingType"
                actions={parkingTypeSelectOptions.map((option) => ({
                    id: option.value,
                    text: option.label
                }))}
                borderColor={Colors.brownish_grey}
                label={Resources.Rodzaj_parkingu}
            />
            <RegularDropdownList
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                error={errors.policyCreationMode}
                touched={touched.policyCreationMode}
                value={values.policyCreationMode?.toString()}
                name="policyCreationMode"
                actions={policyCreationTypeSelectOptions.map((option) => ({
                    id: option.value,
                    text: option.label
                }))}
                borderColor={Colors.brownish_grey}
                label={Resources.Tryb_tworzenia_polityki}
            />
        </Stack>}
    </ModalForm>);

}