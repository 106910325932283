import React, {useEffect} from 'react';
import Stack from "@mui/material/Stack";
import NumberInput from "./NumberInput";
import Resources from "../../../Resources";
import Typography from "@mui/material/Typography";
import {Block} from "../../MUI/components/hook-form/formBlock";
import {NotNullOrUndefined} from "../../utils/ImportResponseAsFile/generalUtils";

interface TimeSpanPickerProps {
    label: string;
    value: string;
    onChange: (value: string) => void;
    initialValue?: string;
    displaySeconds?: boolean;
    dayInputConfig?: InputConfig;
    hourInputConfig?: InputConfig;
    minuteInputConfig?: InputConfig;
    secondInputConfig?: InputConfig;
    /* setTouched?: (isTouched?: boolean, shouldValidate?: boolean) => void;
  touched?: boolean;
  setErrors?: (errors: string[]) => void;*/
}

interface ErrorState {
    days: string[];
    hours: string[];
    minutes: string[];
    seconds: string[];
}

export interface InputConfig {
    min?: number;
    silentValidateMin?: boolean;
    max?: number;
    silentValidateMax?: boolean;

}

interface TimeInputValues {
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
}

type SetValueFunction = React.Dispatch<React.SetStateAction<string>>

// The type for the setErrors function which updates the error state
type SetErrorsFunction = React.Dispatch<React.SetStateAction<ErrorState>>;

export default function TimeSpanPicker({
                                           value,
                                           initialValue,
                                           displaySeconds = false,
                                           onChange,
                                           label,
                                           hourInputConfig = {
                                               min: 0,
                                               silentValidateMin: true,
                                               max: 23,
                                               silentValidateMax: true,
                                           },
                                           minuteInputConfig = {
                                               min: 0,
                                               silentValidateMin: true,
                                               max: 59,
                                               silentValidateMax: true

                                           },
                                           dayInputConfig = {
                                               min: 0,
                                               silentValidateMin: true,
                                               silentValidateMax: false

                                           },
                                           secondInputConfig = {
                                               min: 0,
                                               silentValidateMin: true,
                                               max: 59,
                                               silentValidateMax: true

                                           }
                                       }: TimeSpanPickerProps) {

    const initialControlValues = initializeControlsFromTimeSpanString(value);

    const [days, setDays] = React.useState<string>("");
    const [hours, setHours] = React.useState<string>("");
    const [minutes, setMinutes] = React.useState<string>("");
    const [seconds, setSeconds] = React.useState<string>("");
    const [errors, setErrors] = React.useState<ErrorState>({
        days: [],
        hours: [],
        minutes: [],
        seconds: []
    });
    const {
        min: dayMin,
        max: dayMax
    } = dayInputConfig;
    const {
        min: hourMin,
        max: hourMax
    } = hourInputConfig;
    const {
        min: minuteMin,
        max: minuteMax
    } = minuteInputConfig;
    const {
        min: secondMin,
        max: secondMax
    } = secondInputConfig;

    const allErrors = [
        ...errors.days,
        ...errors.hours,
        ...errors.minutes,
        ...errors.seconds,
        ...[""]
    ];
    const errorDisplay = allErrors.reduce((acc, error, index) => {
        if (index !== allErrors.length - 1) {
            return acc + error + ", ";
        }
        return acc + error;
    });

    function handleDaysChange(value: string) {
        setDays(value);
        timeSpanOnChangeWrapper(onChange, value, hours, minutes, seconds);

    }

    function handleHoursChange(value: string) {
        setHours(value);
        timeSpanOnChangeWrapper(onChange, days, value, minutes, seconds);

    }

    function handleMinutesChange(value: string) {
        setMinutes(value);
        timeSpanOnChangeWrapper(onChange, days, hours, value, seconds);
    }

    function handleSecondsChange(value: string) {
        setSeconds(value);
        timeSpanOnChangeWrapper(onChange, days, hours, minutes, value);
    }

    useEffect(() => {
        const initializeControlsValues = () => {
            const initialControlValues = initializeControlsFromTimeSpanString(initialValue);
            setDays(initialControlValues.days);
            setHours(initialControlValues.hours);
            setMinutes(initialControlValues.minutes);
            if(!displaySeconds){
                setSeconds("");
            }
            else {
                setSeconds(initialControlValues.seconds);
            }
           
        }
        initializeControlsValues();

    }, [initialValue, displaySeconds]);
    const silentDaysMin = dayInputConfig.silentValidateMin ? dayMin : 0;
    const silentHoursMin = hourInputConfig.silentValidateMin ? hourMin : 0;
    const silentMinutesMin = minuteInputConfig.silentValidateMin ? minuteMin : 0;
    const silentSecondsMin = secondInputConfig.silentValidateMin ? secondMin : 0;
    const silentDaysMax = dayInputConfig.silentValidateMax ? dayMax : undefined;
    const silentHoursMax = hourInputConfig.silentValidateMax ? hourMax : 23;
    const silentMinutesMax = minuteInputConfig.silentValidateMax ? minuteMax : 59;
    const silentSecondsMax = secondInputConfig.silentValidateMax ? secondMax : 59;

    return (<Block label={label} labelTextAlign={"left"}>
        <Stack direction="row" spacing={1}>
            <NumberInput value={days} name={"days"} label={Resources.Dni} onValueChanged={handleDaysChange}
                         error={errors?.days?.length > 0} min={silentDaysMin} max={silentDaysMax}
            />
            <NumberInput value={hours} name={"hours"} label={Resources.Godziny} onValueChanged={handleHoursChange}
                         error={errors?.hours?.length > 0}
                         min={silentHoursMin} max={silentHoursMax}/>
            <NumberInput value={minutes} name={"minutes"} onValueChanged={handleMinutesChange}
                         error={errors?.minutes?.length > 0} min={silentMinutesMin} max={silentMinutesMax}
                         label={Resources.Minuty}/>
            {displaySeconds ? <NumberInput value={seconds} name={"seconds"} label={Resources.Sekundy}
                                           onValueChanged={handleSecondsChange}
                                           error={errors?.seconds?.length > 0} min={silentSecondsMin}
                                           max={silentSecondsMax}/> : null}

        </Stack>
        <Typography variant={"caption"} color={"error"}
                    minHeight={10}
        >{errorDisplay ?? " "}</Typography>
    </Block>)

}

const standardNotSilentValidationOfNumberInput = (value: string, name: string, displayName: string, min?: number, max?: number,): string[] => {
    const errors: string[] = [];
    if (NotNullOrUndefined(min) && Number(value) < min) {
        errors.push(`${displayName}:Wartośc musi być większa niż ${min}`);
    } else if (NotNullOrUndefined(max) && Number(value) > max) {
        errors.push(`${displayName}:Wartośc musi być mniejsza niż ${max}`);
    }
    return errors;
}

const timeSpanOnChangeWrapper = (onChange: (value: any) => void, days: string, hours: string, minutes: string, seconds: string) => {
    let timeSpanStringResult = "";
    let daysValue=days;
    let hoursValue=hours;
    let minutesValue=minutes;
    let secondsValue=seconds;
        if (days?.length < 3) {
            daysValue = days.padStart(3, "0");
        }
        if (hours?.length < 2) {
            hoursValue = hours.padStart(2, "0");
        }

        if (minutes?.length < 2) {
            minutesValue = minutes.padStart(2, "0");
        }

        if (seconds?.length < 2) {
            secondsValue = seconds.padStart(2, "0");
        }
        if(!days&&!hours&&!minutes&&!seconds) {
            console.log("in empty string case");
            timeSpanStringResult = "";
        }
        else {
            console.log("in non empty string case");
            timeSpanStringResult = `${daysValue}.${hoursValue}:${minutesValue}:${secondsValue}`;
        }
        
        
    
    onChange(timeSpanStringResult);

}

const initializeControlsFromTimeSpanString = (value: string): TimeInputValues => {

    if (!NotNullOrUndefined(value)) {
        return {
            days: "",
            hours: "",
            minutes: "",
            seconds: ""
        };
    }
    let daysValue: number
    let hoursValue: number
    let minutesValue: number
    let secondsValue: number
    let timeValue = "";
    const dayAndTimeSplit = value?.split(".");

    if (dayAndTimeSplit.length == 2) {
        daysValue = parseInt(dayAndTimeSplit[0])
        timeValue = dayAndTimeSplit[1]
        hoursValue = parseInt(timeValue?.split(":")[0])
        minutesValue = parseInt(timeValue?.split(":")[1])
        secondsValue = parseInt(timeValue?.split(":")[2])
    } else {
        timeValue = value ?? ""
        hoursValue = parseInt(timeValue?.split(":")[0])
        minutesValue = parseInt(timeValue?.split(":")[1])
        secondsValue = parseInt(timeValue?.split(":")[2])

    }
    const dayStringValue = isNaN(daysValue) ? "" : String(daysValue)
    const hoursStringValue = isNaN(hoursValue) ? "" : String(hoursValue)
    const minutesStringValue = isNaN(minutesValue) ? "" : String(minutesValue)
    const secondsStringValue = isNaN(secondsValue) ? "" : String(secondsValue)
    return {
        days: dayStringValue,
        hours: hoursStringValue,
        minutes: minutesStringValue,
        seconds: secondsStringValue
    };

}


