import LocalizedStrings from 'react-localization'; 

let strings = new LocalizedStrings({
  "en": {
    "Dashboard": "Dashboard",
    "Parkingi": "Parkings",
    "Piloty": "Remotes",
    "Moj_profil": "Profile",
    "Email": "Email",
    "Haslo": "Password",
    "Regulamin": "Regulations",
    "Zaloguj_sie": "Log in",
    "Otworz": "Open",
    "Udostepnij": "Share",
    "ul": "st.",
    "Udostepnij_Pilota": "Share Remote",
    "Imie_i_Nazwisko": "Name and surname",
    "Wybierz_pilota": "Choose remote",
    "Usun": "Delete",
    "Dodaj": "Add",
    "Niepoprawny_format": "Invalid format",
    "Wymagane": "Required",
    "Identyfikator": "ID",
    "PIN": "PIN",
    "Blad_zapisu": "Save error",
    "Posiadasz_juz_dostep_do_tego_pilota": "Access to the remote was already granted",
    "Niepoprawny_identyfikator_lub_pin": "Invalid ID or PIN",
    "Dostep_do_wirtualnego_pilota_wygasl": "Access to the remote expired",
    "Blad_usuniecia_pilota": "Remote delete error",
    "Otwarto": "Opened",
    "Blad_otwarcia": "Opening error",
    "Adres": "Adress",
    "Ulica": "Street",
    "Typ": "Type",
    "Haslo_musi_zawierac_co_najmniej_7_znakow": "Password must contain 7 letters",
    "Zmien_haslo": "Change password",
    "Zmien": "Change",
    "Stare_haslo": "Old password",
    "Nowe_haslo": "New password",
    "Haslo_zostalo_zmienione": "Password was changed",
    "Profil": "Profile",
    "Konto_do_wyplat": "Paycheck bank account",
    "Imie": "Name",
    "Nazwisko": "Surname",
    "Numer_telefonu": "Phone number",
    "Dane": "Data",
    "Data_rozpoczecia_musi_byc_mniejsza_od_daty_zakonczenia": "Start date must be less then end date",
    "Wymagane_jest_podanie_emaila_lub_telefonu": "You must provide a phone number or an email address",
    "Adres_email": "Email address",
    "Udostepnij_jednorazowo": "Share one time",
    "Link_zostal_wyslany": "Link was sent",
    "czas": "time",
    "Wyslij": "Send",
    "Kod": "Code",
    "Ustaw_nowe_haslo": "Set new password",
    "Wyslany_kod_nie_jest_juz_aktualny": "Provided code is expired",
    "Podany_kod_resetujacy_jest_niepoprawny": "Provided code is invalid",
    "Wszystko": "Everything",
    "Status": "Status",
    "Aktywny": "Active",
    "Zablokowany": "Blocked",
    "Nieaktywny": "Not active",
    "Wszystkie": "Everything",
    "Pilot": "Remote",
    "Twoje_udostepnienia": "Your sharing",
    "Nazwa": "Name",
    "Poczatek": "Start",
    "Koniec": "End",
    "Opis": "Description",
    "Numer_rejestracyjny_samochodu": "Vehicle licence plate number",
    "Wroc": "Back",
    "Niewazny": "Expired",
    "Zablokuj": "Block",
    "Brak_danych": "No data",
    "Szczegoly": "Details",
    "Wyslij_ponownie": "Send again",
    "Blad": "Error",
    "Aktywuj": "Activate",
    "Dezaktywuj": "Deactivate",
    "Data_udostepnienia": "Share date",
    "Jednorazowy": "One time",
    "Zrob_rezerwacje": "Make a reservation",
    "na": "on",
    "Rezerwacja": "Reservation",
    "Koszt": "Cost",
    "Odrzucone": "Rejected",
    "Zapytanie": "Request",
    "oczekuje_na_płatnosc": "waiting for payment",
    "zakonczona": "finished",
    "potwierdzona": "confirmed",
    "trwajaca": "active",
    "anulowana": "cancelled",
    "zapytanie": "request",
    "Odwolaj": "Cancel",
    "Czy_na_pewno_chcesz_odwolac_ta_rezerwacje": "Are you sure you want to cancel the reservation?",
    "Rezerwacja_dokonana": "Reservation completed",
    "Nastapil_blad": "Error occured",
    "Zapisz": "Save",
    "Nieprawidlowy_format": "Invalid format",
    "Poziom": "Level",
    "Informacje_o_miejscu": "Parking spot info",
    "Cena": "Price",
    "Parking": "Parking",
    "Garaz": "Garage",
    "Na_zewnatrz": "Outside",
    "Samochod": "Car",
    "Motocykl": "Motorcyclce",
    "Publiczna": "Public",
    "Ograniczona": "Limited",
    "Edytuj": "Edit",
    "Wskazowki_dojazdu": "Direction hints",
    "Typ_miejsca": "Place type",
    "Rodzaj_pojazdu": "Vehicle type",
    "Dostepnosc_miejsca": "Place availability",
    "Wynajmij_krotkotermninowo": "Rent short term",
    "Wynajmij_dlugoterminowo": "Rent long term",
    "Cena_za_godzine": "Price for hour (PLN/1h)",
    "Cena_za_miesiac": "Price for month (PLN/month)",
    "Numer_rejestracyjny": "Licence plate number",
    "Parkujacy": "Parking",
    "Mobilny_pilot": "Mobile remote",
    "Tak": "Yes",
    "Nie": "No",
    "Data_dolaczenia": "Join date",
    "Uzytkownicy": "Users",
    "Rezerwacje": "Reservations",
    "Aktywne": "Active",
    "Dezaktywowane": "Deactivated",
    "Miejsca": "Parking spots",
    "Czy_na_pewno_chcesz_usunac_pilota": "Are you sure you want to remove the remote?",
    "Czy_na_pewno_chcesz_usunac_miejsce": "Are you sure you want to remove the parking spot?",
    "Czy_na_pewno_chcesz_dezaktywowac_miejsce": "Are you sure you want to deactivate the parking spot?",
    "Zapytania": "Requests",
    "Abonamenty": "Subscriptions",
    "Faktury": "Invoices",
    "Start": "Start",
    "Parkowanie": "Parking",
    "Moje_parkowanie": "My parking",
    "Akcje": "Actions",
    "Miejsce_parkingowe": "Parking spot",
    "Zarzadzanie_mobilnymi_pilotami": "Mobile remotes management",
    "Uzytkownicy_ktorzy_posiadaja_staly_dostep_do_mobilnych_pilotow": "Users who have permanent access to mobile remotes",
    "Lista_udostenien_czasowych": "List of sharings for specyfic time",
    "Czasowe_dostepy_pozwalajace_na_wykorzystanie_mobilnego_pilota": "Time limited access to mobile remotes",
    "Data_waznosci": "Expiration date",
    "Udostepnianie": "Sharing",
    "Wlasciciel": "Owner",
    "Numer": "Number",
    "Nieaktywne": "Deactivated",
    "Miejsce": "Parking spot",
    "Zakonczone": "Finished",
    "Anulowane": "Cancelled",
    "Anulowana": "Cancelled",
    "Zaplanowane": "Confirmed",
    "Wlasciciel_miejsca": "Place owner",
    "Wybierz": "Choose",
    "Waznosc_od": "Valid from",
    "Waznosc_do": "Valid to",
    "Przydziel_pilota": "Grant access to mobile remote",
    "Przydziel_mobilnego_pilota": "Grant access to mobile remote",
    "Dostep_przydzielony": "Access granted",
    "Dodaj_uzytkownika": "Add user",
    "Czy_na_pewno_chcesz_usunac_uzytkownika_z_tego_parkingu": "Are you sure you want to remove user from parking?",
    "Uzyty": "Used",
    "Czy_na_pewno_chcesz_usunac_dostep_do_pilota": "Are you sure you want to remove access to the remote?",
    "dla_uzytkownika": "for user",
    "Uzytkownik_posiada_juz_dostep_do_tego_pilota": "User already has access to this remote",
    "Odblokuj": "Unblock",
    "Skopiowano_do_schowka": "Copied to clipboard",
    "Mail_na_ktory_wyslalismy_rezerwacje": "Email to which we sent the reservation details",
    "Nr_telefonu_na_ktory_wyslalismy_rezerwacje": "Phone number on which we sent the reservation details.",
    "Informacje_o_rezerwacji": "Reservation info",
    "Udostepnienie_zostalo_wyslane": "Sharing was sent",
    "Stworzone": "Created",
    "Bezplatne_udostepnianie_w_lokalnej_wspolnocie": "Free sharing in the local community",
    "Wynajem_krotkoterminowy": "Short term sharing",
    "Wynajem_dlugoterminowy": "Long term sharing",
    "brak": "none",
    "bezplatny": "free",
    "Przydziel": "Allocate",
    "Edytuj_dostep_do_mobilnego_pilota": "Edit access to mobile remote",
    "Edytuj_udostepnienie_czasowe_pilota": "Edit sharing",
    "Udostepnienie_pilota": "Remote sharing",
    "Zablokuj_mozliwosc_udostepniania": "Block sharing possibility",
    "Odblokuj_mozliwosc_udostepniania": "Unblock sharing",
    "Data_wystawienia": "Date of issue",
    "Pobierz_fakture": "Download invoice",
    "NIP": "NIP",
    "Nazwa_firmy": "Company name",
    "Cena_netto": "Net price",
    "Cena_brutto": "Gross price",
    "VAT": "VAT",
    "Nr_faktury": "Invoice number",
    "SMS_zostal_wyslany": "SMS was sent",
    "Na_twoj_numer_telefony_wyslalismy_adres_do_otwarcia_bramy": "We sent a link to open the gate to your phone number",
    "Wjazd": "Entrance",
    "Wyjazd": "Departure",
    "Wjedz_na_parking_otwierajac_brame": "Enter the parking lot by opening the gate",
    "Zaparkuj_i_oplac_parkowanie": "Park and pay",
    "Wyjedz_z_parkingu": "Leave parking",
    "Zakonczyles_parkowanie": "Parking finished",
    "Dziekujemy_za_skorzystanie_i_zapraszamy_ponownie": "Thank you for using our services and see you again",
    "Oczekiwanie_na_potwierdzenie_transakcji": "Waiting for transaction confirmation",
    "Wpisz_swoj_numer_rejestracyjny_i_telefon": "Enter your licence plate number and phone",
    "Na_podany_numer_telefonu_wyslemy_Ci_link_do_otwarcia_bramy_dzieki_czemu_bedziesz_mogl_rozpoczac_parkowanie": "We will send a link which allows to open the gate to your phone. Use it to start parking.",
    "Wyslij_SMS": "Send SMS",
    "Masz_nieoplacone_parkowanie": "You have unpaid parking",
    "Parkowales_na_tym_parkingu_i_nieusciles_oplaty_W_celu_kontynuowania_oplac_stare_parkowanie": "You have unpaid parking here. In order to park, pay for unfinished parking.",
    "Wjedz_na_parking": "Enter the parking",
    "Otworz_brame_wciskajac_ponizszy_przycisk": "Open the gate using below button",
    "Link_zostal_juz_uzyty_do_wjechania_na_parking": "Link was already used to enter the parking",
    "Przejdz_do_starego_parkowania": "Go to previous parking",
    "zl": "zł",
    "gr": "gr",
    "Zakoncz_parkowanie_i_zaplac": "Finish parking and pay",
    "Zakoncz_parkowanie": "Finish parking",
    "Aktualnie_parkujesz": "You are currently parking",
    "Koszt_parkowania": "Parking cost",
    "Pozostaly_czas_do_wyjazdu_z_parkingu": "Time left to leave the parking",
    "Platforma_wspoldzielenia_miejsc_parkingowych": "Parking space sharing platform",
    "dla": "for",
    "biur": "offices",
    "i": "and",
    "osiedli": "housing estates",
    "Zaloz": "Create",
    "darmowe_konto": "a free account",
    "i_zobacz_jak_działa_aplikacja": "and check how our app works",
    "Do_pobrania_z": "Download from",
    "lub": "or",
    "Zapomniales_hasla": "Forgot password?",
    "Nie_masz_jeszcze_konta": "Don't have an account yet?",
    "Zaloz_je": "Create account",
    "Blad_logowania": "Login error",
    "Profil_indywidualny": "Profile",
    "Zarzadzenie_parkingiem": "Parking management",
    "Administrator": "Administrator",
    "Wyloguj": "Log out",
    "Dodaj_nowego_pilota": "Add new remote",
    "Potwierdz": "Confirm",
    "Pilot_dostepu": "Access remote",
    "Anuluj": "Cancel",
    "Jesli_jeszcze_nie_masz_swojego_pilota_zdobądź_go_pod_adresem": "If you don't have a remote get it at address",
    "Dodaj_nowe_miejsce": "Add a new parking spot",
    "Oceny": "Ratings",
    "Udostepnij_miejsce_parkingowe": "Share parking spot",
    "poniedzialek_abbr": "mon",
    "wtorek_abbr": "tue",
    "sroda_abbr": "wed",
    "czwartek_abbr": "thu",
    "piatek_abbr": "fri",
    "sobota_abbr": "sat",
    "niedz_abbr": "sun",
    "poniedzialekOneLetter": "M",
    "wtorekOneLetter": "Tu",
    "srodaOneLetter": "W",
    "czwartekOneLetter": "Th",
    "piatekOneLetter": "F",
    "sobotaOneLetter": "Sa",
    "niedzielaOneLetter": "Su",
    "stycznia": "january",
    "lutego": "february",
    "marca": "march",
    "kwietnia": "april",
    "maja": "may",
    "czerwca": "june",
    "lipca": "july",
    "sierpnia": "august",
    "wrzesnia": "september",
    "pazdziernika": "october",
    "listopada": "november",
    "grudnia": "december",
    "poniedzialek": "monday",
    "wtorek": "tuesday",
    "sroda": "wednesday",
    "czwartek": "thursday",
    "piatek": "friday",
    "sobota": "saturday",
    "niedziela": "sunday",
    "Dzisiaj": "Today",
    "Wczoraj": "Yesterday",
    "Jutro": "Tomorrow",
    "godz": "h",
    "min": "min.",
    "sek": "sec.",
    "Udostepnij_mobilnego_pilota": "Share the mobile remote",
    "Wyszukaj": "Search",
    "Ilosc_wierszy": "Number of rows",
    "Data_rozpoczecia": "Start date",
    "Data_zakonczenia": "End date",
    "Nr_telefonu": "Phone number",
    "Udostepnione_dla": "Shared for",
    "Dodaj_rezerwacje": "Add a reservation",
    "styczen": "january",
    "luty": "february",
    "marzec": "march",
    "kwiecien": "april",
    "maj": "may",
    "czerwiec": "june",
    "lipiec": "july",
    "sierpien": "august",
    "wrzesien": "september",
    "pazdziernik": "october",
    "listopad": "november",
    "grudzien": "december",
    "Profil_uzytkownika": "User profile",
    "Wybierz_uzytkownika": "Choose user",
    "Miejsce_zostanie_podane_15_min_przed_parkowaniem": "You will get spot number 15 minutes before reservation start",
    "tymczasowa": "temporary",
    "W_jakich_godzinach_chcesz_udostepnic_pilota": "When do you want to share a remote?",
    "Komu_chcesz_udostepnic_pilota": "Who do you want to share the mobile remote with?",
    "Pojazdy": "Vehicles",
    "Metody_platnosci": "Payment methods",
    "Dodaj_nowy_pojazd": "Add new vehicle",
    "Twoje_punkty": "Points",
    "Zarobiles_juz": "Earnings",
    "pkt": "points",
    "Imie_i_nazwisko": "Name and surname",
    "Edytuj_profil": "Edit profile",
    "Konto_bankowe": "Bank account",
    "Aktualne_haslo_nie_jest_poprawne": "Current password is incorrect",
    "Zmiana_hasla": "Password change",
    "Lista_wplywow": "Income list",
    "Lista_uzyskanych_parkcoinow": "Obtained ParkCoins list",
    "Kwota": "Amount",
    "Data": "Date",
    "Tytul": "Title",
    "Dane_profilowe": "Profile data",
    "Czy_na_pewno_chcesz_usunac_metode_platnosci": "Are you sure you want to delete payment method?",
    "Czy_na_pewno_chcesz_usunac_pojazd": "Are you sure you want to delete vehicle?",
    "Dane_pojazdu": "Vehicle data",
    "Kod_wpoldzielenia": "Sharing code",
    "Marka": "Make",
    "Model": "Model",
    "Jednorazowo": "One time",
    "Cyklicznie": "Cyclic",
    "Wybierz_miejsce_parkingowe_do_udostepnienia": "Choose parking spot to share",
    "miejsce": "spot",
    "poziom": "level",
    "jutro": "tomorrow",
    "pojutrze": "day after tomorrow",
    "weekend": "weekend",
    "Ustaw_dostepnosc_miejsca_parkingowego": "Set parking spot availability:",
    "Ustaw_na_caly_dzien": "Set whole day",
    "Godzina_rozpoczecia": "Start time of day",
    "Godzina_zakonczenia": "End time of day",
    "Komu_chcesz_udostepnic": "Who do you want to share with?",
    "Zakladajac_konto_zgadzasz_sie_z": "Creating account you are agreeing to",
    "Regulaminem": "Regulations",
    "oraz": "and",
    "Polityka_Prywatnosci": "Privacy policy",
    "Zaloz_konto": "Create account",
    "Udostepnij_do_odwolania": "Share until further notice",
    "Uzyj_wszedzie_tych_samych_godzin": "Use same hours everywhere",
    "Wybierz_godziny_dostepnosci_miejsca": "Choose parking spot availability hours",
    "Poniedzialek": "Monday",
    "Wtorek": "Tuesday",
    "Sroda": "Wednesday",
    "Czwartek": "Thursday",
    "Piatek": "Friday",
    "Sobota": "Saturday",
    "Niedziela": "Sunday",
    "Czy_chcesz_udostepnic_swoje_miejsce_parkingowe": "Do you want to share your parking spot",
    "Dzien": "Day",
    "Tydzien": "Week",
    "Wolne": "Free",
    "Zajete": "Occupied",
    "Odwolaj_rezerwacje": "Cancel reservation",
    "Zarobek": "Earning",
    "Pojazd": "Vehicle",
    "bezplatnie": "free",
    "Udostepnione": "Shared",
    "Czy_na_pewno_chcesz_usunac_udostepnienie": "Are you sure you want to remove sharing?",
    "Rezerwuj_pod_adresem": "Book under address:",
    "Zapytanie_zostalo_juz_zaakceptowane": "Request was already accepted",
    "Zapytanie_zostalo_juz_usuniete": "Request was already deleted",
    "Zapytanie_o_miejsce_jest_juz_nieaktualne": "Request is outdated",
    "Zaakceptuj": "Accept",
    "Odrzuc": "Reject",
    "Zapytania_o_miejsce": "Parking spots sharing requests",
    "Nie_znaleziono_miejsca_parkingowego": "Parking spot not found",
    "Nie_przejmuj_sie_wynikiem_wyszukiwania_i_zapytaj_okolicznych_wlascicieli_o_wolne_miejsce": "Don't worry about search result - ask local parking spot owners",
    "Zapytaj_o_miejsce": "Request a free parking spot",
    "zl_h": "pln/h",
    "Dla_goscia": "For guest",
    "Dla_siebie": "For me",
    "Wiadomosc": "Message",
    "Nr_rejestracyjny": "Licence plate number",
    "Forma_zaplaty": "Payment form",
    "Podaj_adres_parkowania": "Enter parking address",
    "Wyniki_wyszukiwania": "Search result",
    "Wpisz_adres": "Type address",
    "Nie_znaleziono_adresu": "Address not found",
    "Wymagane_jest_podanie_numeru_domu": "Street number is required",
    "Podaj_adres": "Enter address",
    "Wyslij_kod_do_zresetowania_hasla": "Send password reset code",
    "Haslo_zostalo_zresetowane": "Password has been reset",
    "Wpisz_nazwe": "Enter name",
    "Haslo_superadmina": "Superadmin password",
    "Id_uzytkownika": "User Id",
    "Zaloguj": "Log in",
    "Wroc_do_strony_glownej": "Back to home page",
    "Zakaz_wjazdu": "No entry!",
    "Przepraszamy_ale_strona_ktorej_szukasz_nie_istnieje": "Sorry, page you are looking for was not found",
    "Moze_byc_to_spowodowane_nieprawidlowym_odnosnikiem_na_stronie_z_ktorej_tu_trafiles": "It could be due to invalid link",
    "Podaj_adres_miejsca": "Enter spot address",
    "Aby_moc_dokonac_platnej_rezerwacji_musisz_miec_dodana_forme_platnosci": "To make paid reservation, you have to choose payment form",
    "Rezerwuje_i_place": "Make reservation and pay",
    "Rezerwuje": "Make reservation",
    "darmo": "free",
    "Szybki_przelew": "Fast online payment",
    "Uzytkownik_z_podanym_emailem_juz_istnieje": "User with given email already exists",
    "Wszystkie_wymagane_zgody_musza_byc_zaznaczone": "All mandatory agreements must be checked",
    "Znajdz_miejsce_parkingowe": "Find your parking spot",
    "Grupy": "Groups",
    "Nie_masz_jeszcze_zadnych_grup_adresowych": "You don't have any address groups yet",
    "Wystarcza": "It takes",
    "2_minuty": "2 minutes",
    "by_stworzyc_Grupe_adresowa": "to create Address Group",
    "Dolacz_do_nowej_grupy": "Join new group",
    "Dodaj_nowa_grupe": "Add new group",
    "Zapros_do_grupy": "Invite to group",
    "Czy_na_pewno_chcesz_odrzucic_zaproszenie_do_grupy": "Are you sure you want to reject group invitation?",
    "Czy_na_pewno_chcesz_zaakceptowac_zaproszenie_do_grupy": "Are you sure you want to accept group invitation?",
    "Zostales_zaproszony_do_parkingu": "You have been invited to a parking",
    "pod_adresem": "at address",
    "Czy_akceptujesz_zaproszenie": "Do you accept the invitation",
    "Zarzadzaj": "Manage",
    "i_ciesz_sie_blyskawiczna_rezerwacja": "and make a reservations",
    "Kopiuj_link": "Copy the link",
    "Czy_na_pewno_chcesz_usunac_uzytkownika_z_grupy": "Are you sure you want to remove the user from group",
    "Utworz_grupe": "Create a gruop",
    "Dolacz_do_grupy": "Join the group",
    "Dolacz_do_grupy_adresowej_podajac_kod_wspoldzielenia": "Join the group by entering sharing code",
    "Utworz_grupe_adresowa_podajac_jej_adres": "Create an address group using it address",
    "Nazwa_grupy": "Group name",
    "Opusc": "Leave",
    "Czy_na_pewno_chcesz_opuscic_grupe": "Are you sure want to remove the group?",
    "Administrator_lub_moderator_nie_moze_opuscic_grupy": "Admin or moderator cannot leave group",
    "W_celu_dolaczenia_do_grupy_zaloguj_sie_lub_zaloz_konto": "Log in or create account in order to join the group",
    "Nie_mozna_odwolac_rezerwacji_ktora_sie_juz_rozpoczela_lub_zaraz_rozpocznie": "Reservation cannot be dismissed if it started or will start soon",
    "Status_faktury": "Invoice status",
    "W_przygotowaniu": "Preparing",
    "Gotowa": "Ready",
    "Maksymalna_cena_za_dobe": "Max price per day",
    "Udostepnij_bezplatnie_w_grupie": "Share for free in a group",
    "Abonament_parkingowy": "Parking subscription",
    "Zakup_abonamentu_parkingowego": "Parking subscription purchase",
    "Przedluzenie_abonamentu_parkingowego": "Parking subscription prolongation",
    "Rezerwacja_miejsca_parkingowego": "Parking spot reservation",
    "Wybierz_czy_chcesz_przedluzyc_abonament_czy_wykupic_nowy": "Choose if you want prolong a subscription or buy a new one",
    "Przedluz": "Prolong",
    "abonament": "subscription",
    "Nowy": "New",
    "Platnosc": "Payment",
    "Potwierdz_platnosc": "Confirm payment",
    "Wpisz_kod_znizkowy": "Enter the discount code",
    "Kod_rabatowy": "Discount code",
    "Do_zaplaty": "To pay",
    "Uzyj_kodu": "Use code",
    "Cena_po_obnizce": "Discount price",
    "Potwierdz_kodem_z_wiadomości_SMS_ktora_do_Ciebie_wyslalismy": "Confirm using the code which was sent to you via SMS",
    "Wybierz_abonament_ktory_chcesz_przedluzyc": "Choose a subscription you want to prolong",
    "Podaj_numer_dla_ktorego_chcesz_przedlużyc_abonament": "Enter a phone number for which you want to prolong subscription",
    "Kod_sms": "SMS code",
    "Potwierdzam": "Confirm",
    "Wazny_do": "Valid to",
    "Transakcja_w_trakcie_realizownia": "Transaction in progress",
    "Oczekujemy_na_odpowiedz_z_Twojego_banku": "We are waiting for confirmation from your bank",
    "Transakcja_nie_zostala_zrealizowana": "Transaction failed",
    "Sprobuj_jeszcze_raz": "Try again",
    "Wroc_na_strone_parkingu": "Back to parking lot page",
    "Ponow_platnosc": "Re-pay",
    "Dane_abonamentu": "Subscription data",
    "Chce_otrzymac_fakture": "I want to get an invoice",
    "Typ_faktury": "Invoice type ",
    "Osoba_prywatna": "Natural person",
    "Firma": "Company",
    "Obowiazkowe_tylko_dla_faktur_na_firme": "Required only for company invoice",
    "Dla_firmy": "For company",
    "nazwa_firmy": "company name",
    "Dla_osoby_fizycznej": "For natural person",
    "imie_i_nazwisko": "name and surname",
    "Kontrahent": "Contractor",
    "Nr_domu": "Street number",
    "Nr_lokalu": "Flat number",
    "Miejscowosc": "Town",
    "Kod_pocztowy": "Postal code",
    "Zapoznalem_sie_i_akceptuje": "I have read and accept",
    "Regulamin_sprzedazy": "Purchase regulations",
    "Polityke_prywatnosci": "Privacy policy",
    "Regulamin_parkingu": "Parking lot regulations",
    "Miejsce_dla_ktorego_chcesz_wykupic_abonament": "Parking spot",
    "Poczatek_parkowania": "Parking start",
    "Wybierz_abonament": "Choose a subscription type",
    "Nieprawidlowy_kod_promocyjny": "Invalid discount code",
    "Nie_mozna_skorzystac_z_wielu_promocji_na_raz": "You cannot use many discount codes at once",
    "Podany_kod_promocyjny_wygasl": "Promotion code expired",
    "Wyrazenie_zgody_wymagane": "Giving consent required",
    "Zbyt_duzo_prob_weryfikacji_numeru_Sprobuj_za_kilka_minut": "Too many phone number verification attempts. Try again in a few minutes.",
    "Do_podanego_numeru_telefonu_nie_ma_przypisanego_zadnego_abonamentu": "You haven't any subscriptions associated with this phone number",
    "Nieprawidlowy_kod_weryfikacyjny": "Invalid verification code",
    "Podany_kod_weryfikacyjny_zostal_juz_uzyty": "Given verification code was already used",
    "Link_do_przedluzenia_zostal_juz_wykorzystany": "Prolongation link was already used",
    "Podany_kod_promocyjny_zostal_juz_uzyty": "Given discount code was already used",
    "Potwierdzenie_transakcji": "Transaction confirmation",
    "Twoja_płatnosc_została_odrzucona_przez_operatora": "Your payment was rejected by the payment operator",
    "Wybrane_miejsce_zostalo_zarezerwowane_w_miedzyczasie_Skontatktuj_sie_z_nami_w_celu_uzyskania_zwrotu_platnosci": "Chosen place is already reserved. Contact us to get a refund",
    "Cos_poszlo_nie_tak": "Something went wrong",
    "Status_zakupu": "Purchase status",
    "Przekroczono_czas_oczekiwania": "Waiting time expired",
    "Sprawdzanie_statusu_zakupu": "Check purchase status",
    "Dokonano_zakupu": "Purchase done",
    "Kody_rabatowe": "Discount codes",
    "Liczba_dni_obowiazywania": "Duration in days",
    "Cena_promocyjna": "Discount price",
    "Aktywacja": "Activation",
    "Abonament": "Subscription",
    "Nie_stworzyles_jeszcze_abonamentu": "You haven't created any subscriptions yet",
    "Stworz_abonament": "Create a subscription",
    "Nie_stworzyles_jeszcze_kodow_rabatowych": "You haven't created any discount code",
    "Stworz_kod_rabatowy": "Create discount code",
    "rabatowy": "disocunt",
    "Bez_limitu": "No limit",
    "Stworz_kod": "Create code",
    "Nowy_kod_rabatowy": "New discount code",
    "Nowy_abonament": "New subscription",
    "Zakup_abonamentu": "Subscription purchase",
    "Kup_abonament": "Buy subscription",
    "Przedluzenie_abonamentu": "Subscription prolongation",
    "Konfiguracja": "Configuration",
    "Rodzaje_abonamentow": "Subscription types",
    "Administrator_parkingu_wylaczyl_abonament": "Parking lot manager has deactivated the subscription",
    "Kod_promocyjny_zostal_wylaczony_przez_administratora_parkingu": "Discount code was deactivated by parking lot manager",
    "Nie_mozesz_przedluzyc_tego_abonamentu_Administrator_parkingu_wylaczyl_abonament": "You cannot prolong this subscription. Parking lot manager deactivated this subscription.",
    "Z_podanym_numerem_telefonu_masz_juz_powiazane_abonamenty_Czy_zamiast_kupowania_nowego_chciałbys_którys_z_nich_przedluzyc": "You have subscriptions purchases associated with this phone number. Do you want prolong an existing purchase instead of buying a new subscription?",
    "Uzytkownik_zostal_zbanowany": "User was banned",
    "Wszystkie_miejsca_zostaly_juz_udostepnione": "All parking spots was already shared",
    "Blad_zakupu_abonamentu": "Subscription purchase error",
    "Blad_walidacji": "Validation erorr",
    "Blad_wewnetrzny_serwera": "Internal server error",
    "Konto_uzytkownika_nie_jest_aktywne": "User account is not active",
    "Parking_jest_juz_zamkniety": "Parking is already closed",
    "Brak_dostepu": "No access",
    "Mobilny_pilot_nie_jest_jeszcze_aktywny": "Mobile remote is not active yet",
    "Blad_sterownika_mobilnego_pilota": "Mobile remote driver error",
    "Nie_ma_takiego_uzytkownika": "There is not such user",
    "Niepoprawne_email_lub_haslo": "Invalid email or password",
    "Konto_nie_istnieje": "Account doesn't exist",
    "Uzytkownik_nie_istnieje": "User not found",
    "Rezerwacja_nie_istnieje": "Reservation not found",
    "Nastapil_konflikt_w_robieniu_rezerwacji": "Conflict occured during making a reservation",
    "Link_do_przedluzenia_abonamentu_wygasl": "Subscription prolongation link expired",
    "Przekazanie_miejsca_jest_wylaczone": "Parking spot transfer is turned off",
    "Blad_polaczenia_sieciowego_lub_wewnetrzny_blad_serwera": "Internet connection error or internal server error",
    "Nieznany_blad": "Unknown error",
    "To_nie_jest_blad": "This is not en error",
    "Uzytkownik_nie_jest_czlonkiem_parkingu": "User is not member of a parking",
    "Kod_QR_nie_zostal_znaleziony": "QR code not found",
    "Uzytkownik_jest_zbyt_daleko_od_parkingu": "User is too far from parking",
    "Blad_inicjalizacji_platnosci_webowej": "Web payment initialization not found",
    "Nie_mozna_kupic_abonamentu_od_podanego_dnia": "Cannot buy subscription from given day",
    "Nie_znaleziono_zadnych_elementow": "No elements found",
    "Nieprawidlowy_kod_zaproszenia_do_parkingu": "Invalid parking lot invitation code",
    "Zduplikowane_miejsce_parkingowe": "Parking spot duplicated",
    "Zduplikowany_uzytkownik": "Duplicated user",
    "Blad_logowania_zewnetrznego": "External log in error",
    "Rezerwacja_zostala_juz_oceniona": "Reservation is already rated",
    "Nie_mozna_pobrac_szczegolow_rezerwacji": "Cannot get reservation details",
    "Kod_dostepu_zostal_juz_uzyty": "Access code was already used",
    "Blad_transakcji_karty_kredytowej": "Credit card transaction error",
    "Zapytanie_o_miejsce_zostalo_odrzucone": "Request was rejected",
    "Uzytkownik_posiada_juz_mobilnego_pilota": "User already has this mobile remote",
    "Obowiazkowa_zgoda_nie_zostala_zaakceptowana": "Required agreement was not accepted",
    "Obiekt_nie_zostal_znaleziony": "Object not found",
    "Identyfikator_prywatnego_parkingu_nie_jest_unikalny": "ID of private parking lot is not unique",
    "Miejsce_parkingowe_nie_zostalo_znalezione": "Parking spot not found",
    "Nieaktualne_dane": "Stale data",
    "Kod_dostepu_do_parkowania_zostal_juz_uzyty": "Access code was already used",
    "Masz_niezakonczone_parkowanie": "You have unfinished parking",
    "Uzytkownik_jest_juz_czlonkiem_grupy": "User is no longer a group member",
    "Miejsce_parkingowe_nie_jest_dostepne": "Parking spot not available",
    "Zduplikowany_identyfikator_publiczny_parkingu": "Parking public ID duplicated",
    "Blad_integracji_XControl": "XControl integration error",
    "Konflikt_przy_tworzeniu_abonamentu": "Subscription create conflict",
    "Link_do_pilota_zostal_zablokowany_przez_wystawce": "Link was blocked by issuer",
    "Uzytkownik_nie_ma_dostepu_do_pilota": "User does not have access to this mobile remote",
    "Uzytkownik_nie_moze_udostepniac_pilota": "User cannot share this remote",
    "Udostepnienie_pilota_zostalo_juz_wykorzystane": "Sharing was already used",
    "Udostepnienie_pilota_nie_zostalo_znalezione": "Sharing was not found",
    "Transakcja_odrzucona": "Transaction rejected",
    "Wyszukaj_zakup_po_imieniu_i_nazwsku_nipie_emailu_telefonie_lub_numerze_rejestracyjnym": "Find purchase by name, surname, email, phone number, nip, spot number or licence plate number",
    "Informacje_o_zakupie": "Purchase data",
    "Dane_kupujacego": "Buyer data",
    "Dane_faktury": "Invoice data",
    "Przedzial_czasowy": "Time range",
    "Obnizona_o": "Discounted by",
    "Powrot_do_aplikacji": "Back to app",
    "Identyfikator_pilota": "Remote ID",
    "Dolaczyles_do_grupy": "You have joined the group",
    "Utworzono_grupe": "Group created",
    "Komentarz": "Comment",
    "Ocena": "Rating",
    "Dla_tego_parkingu_nie_zostaly_zdefiniowane_zadne_abonamenty": "No subscriptions were defined for this parking lot",
    "Abonament_zostal_juz_przedluzony": "Subscription was prolonged",
    "Podany_link_jest_juz_niewazny": "Given link is no longer valid",
    "Kup_nowy_abonament": "Buy a new subscription",
    "Wybierz_miejsce_parkingowe": "Choose a parking spot",
    "Powrot_do_formularza": "Back to form",
    "Wybierz_miejsce": "Choose parking spot",
    "Sektor": "Sector",
    "Ktos_zarezerwowal_juz_wybrane_miejsce": "Somebody has already reserved selected place",
    "sektor": "sektor",
    "Nie_mozna_przedluzyc_abonamentu_juz_przedluzonego_przez_uzytkownika": "Cannot prolong already prolonged subscription",
    "Podana_data_musi_byc_pozniejsza_niz_aktualna_data_zakonczenia_abonamentu": "Entered date must be later than subscription end date",
    "Parametry_rezerwacji": "Reservation parameters",
    "Dane_rezerwacji": "Reservation data",
    "Koniec_parkowania": "Parking end",
    "Nie_wybrales_miejsca": "You have not choose the place",
    "Miejsce_rezerwacji": "Reservation location",
    "Wybrane_miejsce_nie_jest_dostepne": "Chosen place is not available",
    "Edytuj_zakup_abonamentu": "Edit subscription purchase",
    "Usluga_rezerwacji_zewnetrznej_jest_obecnie_wylaczona": "External reservations service is turned off",
    "Blokada_miejsca": "Place blockade",
    "platnosc_odrzucona": "payment rejected",
    "Linia_czasowa": "Timeline",
    "Oznacz_jako_rozliczone": "Mark as settled",
    "rez_zewn": "ext. res.",
    "rezerwacja": "reservation",
    "start_stop": "start/stop",
    "Nie_zdefiniowano_zadnego_rodzaju_abonamentu": "No subscription type was defined",
    "Ilosc_dni": "Number of days",
    "Wybrany_miesiac": "Given month",
    "Pelny_miesiac": "Full month",
    "Miesiac_od_ktorego_abonament_ma_obowiazywac": "Month from which subscription is active",
    "Lista": "List",
    "Stan_na": "As of",
    "Funkcjonalnosc_niedostepna": "Feature unavailable",
    "Jezeli_jestes_zainteresowany_planem_na_swoim_parkingu_skontaktuj_sie_z_naszym_dzialem_sprzedazy": "If you are interested in parking lot plan feature, contact our sales department",
    "Plan": "Plan",
    "Na_tym_miejscu_nigdy_nie_parkowano": "The weren't any parkings on this place yet",
    "Nr_dokumentu": "Document number",
    "Skopiuj_adres_zakupu_abonamentu": "Copy subscription purchase address",
    "Okres_trwania": "The duration of",
    "Zwolnij_miejsce": "Free place",
    "Czy_na_pewno_chcesz_zwolnic_miejsce_i_umozliwic_wykupienie_na_nim_nowych_abonamentow": "Are you sure you want to make this place available?",
    "Waznosc": "Validity",
    "Kod_promocyjny_musi_byc_unikalny": "Promotion code must be unique",
    "Skopiuj_adres_zakupu_rezerwacji": "Copy the reservation purchase address",
    "Ostatnie_uzycie": "Last use",
    "Klient": "Client",
    "Liczba_miesiecy": "Number of months",
    "Liczba_okresow": "Number of periods",
    "Sposob_platnosci": "Payment method",
    "przelew": "transfer",
    "gotowka": "cash",
    "Wybierz_jezyk": "Choose language",
    "bilet": "ticket",
    "Nieznany": "Unknown",
    "Niestety_ale_nie_ma_juz_zadnych_dostepnych_miejsc": "Unfortunately, there are no more seats available",
    "Usun_konto": "Delete account",
    "Czy_na_pewno_chcesz_usunac_konto": "Are you sure you want to delete account?",
    "Konto_usuniete": "Account deleted",
    "Kalendarz": "Calendar",
    "Stan": "State",
    "Parametry_abonamentu": "Subcription parameters",
    "Miesiac_rozpoczecia_abonamentu": "Subscription start month",
    "Dzien_rozpoczecia_abonamentu": "Subscription start day",
    "Okres": "Period",
    "Okres_obowiazywania_abonamentu": "Subscription duration",
    "Miesiac": "Month",
    "miesiace": "months",
    "dzien": "day",
    "dni": "days",
    "miesiac": "month",
    "Powiadomienia": "Notifications",
    "Podsumowanierezerwacji": "Booking summary",
    "Nagroda": "Award",
    "Odwolanie": "Cancellation",
    "Przypomnienie": "Reminder",
    "Oferta": "Offer",
    "Data_utworzenia": "Creation date",
    "Tresc": "Content",
    "Sprawdz_cene": "Check price",
    "usluga_platnosci_zostala_wylaczona_dla_parkingu": "payment service has been disabled for parking",
    "blad_komunikacji_z_systemem_kontroli_dostepu": "miscommunication with access control system",
    "bilet_nie_moze_zostac_oplacony": "ticket cannot be paid for",
    "nie_znaleziono_biletu_dla_tej_rejestracji": "no ticket found for this registration",
    "Aktywny_do": "Active until",
    "Waznosc_uplynela": "Validity has expired",
    "Aktywny_od": "Active from",
    "Wazny_bezterminowo": "Valid indefinitely",
    "karta_platnicza": "debit card",
    "wolne_miejsce": "place free",
    "zajete_miejsce": "place taken",
    "nieodblokowane_miejsce": "unblocked place",
    "zajete_w_przyszlosci_miejsce": "place taken in the future",
    "wybrane_miejsce": "chosen place",
    "Numer_rejestracyjny_nie_zostal_znaleziony_w_systemie_Upewnij_sie_ze_wpisales_go_poprawnie_oraz_ze_tablica_rejestracyjna_jest_czytelna_nie_jest_zaslonieta_badz_zabrudzona": "The registration number was not found in the system. Please make sure you have entered it correctly and that the number plate is legible, not obscured or dirty",
    "Jesli_problem_bedzie_sie_powtarzal_zamiast_numeru_rejestracyjnego_podaj_numer_biletu_parkingowego_i_sprobuj_ponownie": "If the problem persists, enter your parking ticket number instead of your license plate number and try again",
    "Potwierdzenie": "Confirmation",
    "Bilet_oplacony_Szlaban_podniesie_sie_automatycznie_przy_probie_wyjazdu": "Ticket paid. The barrier will raise automatically when you attempt to leave.",
    "Darmowe_parkowanie_zakonczone_Szlaban_podniesie_sie_automatycznie_przy_probie_wyjazdu": "Free Parking Ended. The barrier will raise automatically when you attempt to exit.",
    "Adres_email_zostal_juz_potwierdzony": "Email has been already confirmed",
    "Nieprawidlowy_kod_potwierdzajacy_email": "Invalid email confirmation code",
    "Naruszenie_polityki_rezerwacji": "Booking policy violation",
    "Wymagane_potwierdzenie_adresu_email": "Email confirmation required",
    "Wymagane_potwierdzenie_numeru_telefonu": "Phone number confirmation required",
    "Identyfikator_profilu": "Profile identifier",
    "klient_zalega_z_oplata_miesiac": "the customer is 1 month in arrears",
    "klient_zalega_z_oplata_co_najmniej_2_miesiace": "the customer is at least 2 months in arrears",
    "Zweryfikowane": "Verified",
    "W_trakcie": "Pending",
    "Zweryfikuj": "Verify",
    "Na_twoja_skrzynke_zostal_wyslany_link_potwierdzajacy_W_celu_potwierdzenia_adresu_email_kliknij_go": "No parking spaces available  To confirm your email address, click it.",
    "Usuniete_konto": "Account deleted",
    "Wymagane_ustawienie_identyfikatora_profilu": "Setting profile id required",
    "Historia_uzycia": "Usage history",
    "Historia_uzycia_mobilnych_pilotow_przez_uzytkownikow": "Mobile remotes usage history",
    "Uzywajacy": "User",
    "Wystawiajacy": "Issuer",
    "Data_uzycia": "Date of use",
    "Staly_dostep": "Permanent access",
    "Udostepnienie": "Sharing",
    "Czy_chcesz_dolaczyc_do_grupy": "Do you want to join grop?",
    "Dolacz": "Join",
    "Twoja_platnosc_zostala_przyjeta_Szerokiej_drogi": "Your payment has been accepted",
    "Oplac": "Pay",
    "Oplac_parkowanie": "Pay for parking",
    "Numer_rejestracyjny_18_cyfrowy_numer_biletu": "Registration number/18 digit ticket number",
    "Wpisz_numer_rejestracyjny_swojego_pojazdu_badź_18_cyfrowy_numer_biletu_parkingowego_i_w_prosty_ureguluj_oplate_za_parking_przy_pomocy_platnosci_elektronicznej": "Enter your vehicle registration number or 18-digit parking ticket number and easily pay for your parking via e-payment",
    "Brak_wolnych_miejsc_na_parkingu": "No parking spaces available",
    "Dla_tego_parkingu_nie_zdefiniowano_zadnych_cennikow": "No price lists have been defined for this parking lot",
    "Wybierz_cennik": "Pick a price list",
    "Kolejnosc_sortowania": "Sort order",
    "Typ_silnika": "Engine type",
    "Spalinowy": "Combustion engine",
    "Hybrydowy": "Hybrid",
    "LPG": "LPG",
    "Elektryczny": "Electric",
    "Punkt_dostepowy": "Access point",
    "Autem_o_tym_typie_silnika_nie_mozna_parkowac_na_tym_miejscu": "A car with this type of engine cannot be parked on this spot",
    "ZwyklyUzytkownik": "Regular user",
    "Zarzadca": "Manager",
    "Moderator": "Moderator",
    "Obsluga": "Staff",
    "Rola": "Role",
    "Wyszukaj_uzytkownika_po_imieniu_nazwisku_emailu_lub_identyfikatorze": "Search for a user by name, surname, email or identifier",
    "Haslo_do_konta_nie_zostalo_ustawione_skorzystaj_z_instrukcji_jaka_wyslalismy_na_Twoj_email_po_utworzeniu_konta_lub_skontaktuj_sie_z_administratorem_parkingu_firmowego": "The account password has not been set, please use the instructions we sent to your email after creating the account or contact your company's parking administrator.",
    "Kod_ustawiajacy_haslo_nie_istnieje": "The code to set the password does not exist",
    "Możliwosc_rezerwacji_zostala_zablokowana_przez_administratora_parkingu": "The possibility of reservation has been blocked by the parking administrator.",
    "Ustawienie_hasla_zabronione": "Password set forbidden",
    "Kod_ustawiajacy_haslo_zostal_juz_wykorzystany": "The password setting code has already been used",
    "Nieprawidlowy_kod_ustawiajacy_haslo": "Invalid password setting code",
    "Edytuj_uzytkownika": "Edit user",
    "Haslo_ustawione": "Password set",
    "Ustaw_haslo": "Set password",
    "Ustawianie_hasla": "Setting a password",
    "Wyszukaj_zakup_po_numerze_rejestracyjnym": "Search for a purchase by license plate number or spot number",
    "Obsluga_parkingu": "Parking lot maintenance",
    "Anulowany_abonament_nie_moze_zostac_przedluzony": "Canceled subscription can not be prolonged",
    "Legenda": "Legend",
    "Raporty": "Reports",
    "Wykaz_wolnych_miejsc": "List of free spots",
    "Wyszukaj_miejsce_po_numerze": "Search spot by number",
    "Czy_na_pewno_chcesz_usunac_abonament": "Are you sure you want to remove subscription?",
    "Data_wplaty": "Date of payment",
    "Zarzadzaj_dostepnoscia": "Manage availability",
    "Czy_napewno_chcesz_zmienic_linie_czasowa_miejsca": "Changing the cyclic sharing will automatically cancel all future user reservations. Review user reservations and make sure you are sure you want to do this before performing this operation. Do you want to continue?",
    "Drukuj_historie": "Print history",
    "Niepoprawny_kod_blik": "Invalid BLIK Code",
    "SZYBKA_PLATNOSC": "FAST PAYMENT",
    "Kod_blik": "BLIK Code",
    "Wklej_kod": "Paste Code",
    "Blad_platnosci_blik": "BLIK payment error",
    "Darmowa_rezerwacja": "Free booking",
    "Dostep": "Access",
    "LPR": "LPR",
    "Dostep_LPR": "LPR Access",
    "Dodaj_Dostep": "Grant Access",
    "Wyszykaj_dostep_po_numerze_rejestracyjnym": "Search access by license plate number",
    "Edytuj_dostep": "Edit access",
    "Nieograniczony_dostep": "Unrestricted access",
    "Czy_na_pewno_chcesz_usunac_dostep": "Are you sure you want to remove access?",
    "Obszar": "Area",
    "Naruszenie_unikalnosci": "Violation of uniqueness",
    "Jezeli_jestes_zainteresowany_dostepem_z_uzuciem_automatycznego_rozpoznawania_tablic_rejestracyjnych_skontaktuj_sie_z_naszym_dzialem_sprzedazy": "If you are interested in access using automatic license plate recognition, please contact our sales department",
    "Pomoc": "Help",
    "Kontakt": "Contact",
    "Przypisz_na_stale_do_uzytkownika": "Assign permanently to user",
    "Zwolnij_do_puli_miejsc": "Release to the pool of spots",
    "Przypisz_miejsce": "Assign spot",
    "Email_lub_Identyfikator": "Email or Identifier",
    "Czy_na_pewno_chesz_usunac_stale_przypisanie_i_zwrocic_miejsce_do_puli_miejsc_dostepnych_dla_wszystkich_uzytkownikow": "Are you sure you want to remove the permanent assignment and return the spot to the pool of spots available to all users?",
    "Czy_napewno_chesz_przypisac_miejsce_na_stale": "This will remove all reservation made for this place. Are you sure you want to permanently assign the spot to this user?",
    "Przypisz": "Assign",
    "Przypisanie": "Assignment",
    "Miejsce_rotacyjne": "Rotational spot",
    "Czy_na_pewno_chcesz_usunac_uzytkownika": "Are you sure you want to remove user?",
    "Jezyk": "Language",
    "Wpowadz_dane": "Enter data",
    "Dalej": "Next",
    "Odswiez": "Refresh",
    "Raport_Finansowy": "Financial Report",
    "Wyniki_Finansowe": "Financial results",
    "Przychod": "Revenue",
    "Liczba_Transakcji": "Number of transactions",
    "Zwroty": "Returns",
    "Przychod_w_poszczegolne_dni": "Revenue in individual days",
    "Poprzedni_okres": "Previous period",
    "Rodzaj_parkowania": "Type of parking",
    "Suma_transakcji": "Sum of transactions",
    "Maksymalny_zakres_wynosi__366_dni": "The maximum range is 366 days",
    "Rezerwacja_zewnetrzna": "External reservation",
    "Wybierz_zakres_dat": "Select date range",
    "Zarejestrowani": "Registered",
    "Aktywni": "Active",
    "Nowi": "New",
    "Top_10_najczesciej_rezerwujacych": "Top 10 most frequently booking",
    "Top_10_pustych_rezerwacji": "Top 10 empty reservations",
    "Top_10_anulujacych_rezewacje": "Top 10 canceling places",
    "Nie_udostepnione": "Not shared",
    "p_zajetosci": "% occupancy",
    "Zarezerwowane": "Reserved",
    "Przypisane_na_stale": "Permanently assigned",
    "Wszystkie_rezerwacje": "All reservations",
    "Wykorzystane": "Used",
    "Nie_wykorzystane": "Unused",
    "Typ_rezerwacji": "Type of reservation",
    "Liczba_rezerwacji_w_podziale_na_dni_tygodnia": "Number of reservations by day of the week",
    "Platne": "Paid",
    "Bezplatne": "Free",
    "Gosc": "Guest",
    "Stale": "Permanent",
    "Wykorzystanie_parkingu": "Parking utilization",
    "Statusy_rezerwacji": "Reservation statuses",
    "Generuj_raport": "Generate report",
    "Terminal_platniczy": "Payment terminal",
    "Parkowanie_rotacyjne": "Rotational parking",
    "Miejsc": "Spots",
    "MinimalBookingsDashboard": "MinimalBookingsDashboard",
    "Aktywni_w_danym_okresie": "Active in a given period",
    "Wszyscy_zarejestrowani": "All registered",
    "Zarejestrowani_w_danym_okresie": "Registered in a given period",
    "Top_10_niewykorzystanych_rezerwacji": "Top 10 unused reservations",
    "Top_10_anulujacych_rezerwacje": "Top 10 canceling reservations",
    "Ilosc_zwrotow": "Number of returns",
    "Ilosc_transakcji": "Number of transactions",
    "Raporty_Generowane": "Reports generated",
    "Raport_pomyslnie_wygenerowany_i_wyslany_na_adres_email": "The report has been successfully generated and sent to the email address associated with your account",
    "To_pole_jest_wymagane": "This field is required",
    "Wybierz_przynajmniej_jedna_opcje": "Select at least one option",
    "Wybierz_parkingi": "Select parking lots",
    "Wystapil_nieznany_blad_podczas_generacji_raportu": "An unknown error occurred while generating the report",
    "Dane_finansowe": "Financial data",
    "Aktywnosc_uzytkownikow": "User activity",
    "Wygeneruj_i_wyslij": "Generate and send",
    "Wlasny_zakres_dat": "Custom date range",
    "Obecny_miesiac": "Current month",
    "Poprzedni_miesiac": "Previous month",
    "Obecny_rok": "Current year",
    "Poprzedni_rok": "Previous year",
    "Data_anulowania": "Cancellation date",
    "Liczba_wolnych_miejsc": "Free spots",
    "Liczba_zarezerwowanych_miejsc": "Reserved spots",
    "Zajetosc_miejsc": "Occupancy of spots",
    "Wyszukaj_Po_Numerze_miejsca_nazwisku_emailu": "Search by spot number, surname, email of spot owner",
    "Rower": "Bicycle",
    "Hulajnoga": "Scooter",
    "Ladowarka_dla_pojazdow_elektrycznych": "Electric charger",
    "Miejsce_dla_niepelnosprawnych": "Disabled parking spot",
    "Miejsce_dla_obslugi": "Staff parking spot",
    "Miejsce_dla_gosci": "Guest parking spot",
    "Rezerwacja_tylko_przed_administratora": "Reservation only by administrator",
    "Cechy_miejsca": "Spot features",
    "Miejsce_monitorowane": "Monitored spot",
    "Miejsce_strzezone": "Guarded spot",
    "Miejsce_podziemne": "Underground spot",
    "Garaz_w_budynku": "Building garage",
    "Preferuj_cene_Taryfowa": "Prefer tariff price",
    "Cena_wyliczana_na_podstawie_cennika_parkingu": "Price calculated based on parking price list",
    "Liczba_nieaktywnych_miejsc": "Inactive spots",
    "Laczna_liczba_zarzadzanych_miejsc": "All managed spots",
    "Od": "From",
    "Do": "To",
    "Rezerwacje_bezplatne": "Free reservations",
    "Rezerwacje_platne": "Paid reservations",
    "Cechy_miejsca_parkingowego": "Parking spot features",
    "Miejsce_tylko_dla_malych_pojazdow_elektrycznych": "Spot only for small electric vehicles",
    "Zabronione_rodzaje_silnikow": "Forbidden engine types",
    "Pozostale": "Other",
    "Publiczny": "Public",
    "Prywatny": "Private",
    "Komercyjny": "Commercial",
    "Biuro": "Office",
    "BiuroEV": "OfficeEV",
    "Rodzaj_parkingu": "Parking type",
    "Wystapil_blad_podczas_zapisu_danych": "An error occurred while saving the data",
    "Importuj_z_csv": "Import from CSV",
    "Usun_Wszystkie": "Remove all",
    "Nazwa_pliku": "File name",
    "Udostepnij_plik": "Upload file",
    "Upusc_lub_wybierz_plik": "Drop or Select file",
    "Upusc_pliki_tutaj_lub_kliknij": "Drop files here or click",
    "Przegladaj": "Browse",
    "Aby_wybrac_plik_z_dysku": "through your machine",
    "Dziedzicz": "Inherit",
    "Domyslny_caly_dzien": "Default all day",
    "Domyslny_zakres": "Default range",
    "Tryb_tworzenia_polityki": "Policy creation mode",
    "Reczny": "Manual",
    "Automatyczny": "Automatic",
    "Polityka_parkingu": "Parking policy",
    "Wymagane_jest_podanie_id_profilu_zewnetrznego": "External profile id is required",
    "Domyslny": "Default",
    "Pozwol_na_rownoczesne_rezerwacje": "Allow concurrent reservations",
    "Zastosuj_dla_menadzerow": "Apply to managers",
    "Pozwol_na_pojazdy_elektryczne_w_parkingu_podziemnym": "Allow electric vehicles in underground parking",
    "Pozwol_na_pojazdy_LPG_w_parkingu_podziemnym": "Allow LPG vehicles in underground parking",
    "Calodobowa": "Whole day",
    "Wybrany_zakres": "Custom range",
    "Polityka_rezerwacji": "Reservation policy",
    "Sposob_wyboru_miejsca": "Spot selection type",
    "Minimalna_dlugosc_rezerwacji": "Minimum reservation duration",
    "Maksymalna_dlugosc_rezerwacji": "Maximum reservation duration",
    "Maksymalna_dlugosc_rezerwacji_od_teraz": "Maximum reservation duration from now",
    "Maksymalny_Okres_wyprzedzenia_pozwalajacy_na_rezerwacje": "Maximum advance period of reservations",
    "Minuty": "Minutes",
    "Dni": "Days",
    "Godziny": "Hours",
    "Brak_polityki": "No policy",
    "Akcja_zostala_pomyslnie_ukonczona": "Action has been successfully completed",
    "Wystapil_blad": "An error occurred",
    "Import_danych_z_pliku_zostal_ukonczony_pomyslnie": "Data import from file has been successfully completed",
    "Wystapil_blad_podczas_importu_danych_z_pliku": "An error occurred during data import from file",
    "Importuj_dane_z_pliku_CSV": "Import data from .csv file",
    "Importuj": "Import",
    "Dane_zostaly_pomyslnie_zapisane": "Data has been successfully saved!",
    "Wyszukaj_po_nazwie_parkingu": "Search by parking name",
    "Dane_zostaly_pomyslnie_zaktualizowane": "Data has been successfully updated!",
    "Wystapil_blad_podczas_aktualizacji_danych": "An error occurred while updating data",
    "Najpozniejszy_czas_anulowania": "Latest cancellation time of reservation",
    "Regulamin_ogolny": "General regulations URL",
    "Regulamin_prywatnosci": "Privacy policy URL",
    "Url_mapy_parkingu": "Parking map URL",
    "Instrukcja_aplikacji": "Application manual URL",
    "Url_loga": "Logo URL",
    "Limit_aktywnych_rezerwacji": "Active reservations limit",
    "Predefiniowane_przedzialy_czasowe": "Predefined time ranges",
    "Wartosc": "Value",
    "Rodzaj": "Type",
    "Dodaj_parking": "Add parking",
    "Edytuj_parking": "Edit parking",
    "Url_regulaminu_sprzedarzy": "Sales regulations URL",
    "Dodaj_polityke_rezerwacji": "Add reservation policy",
    "Edytuj_polityke_rezerwacji": "Edit reservation policy",
    "Parking_nie_posiada_polityki_rezerwacji": "Parking does not have a reservation policy, edit and save the policy to add it or close the window to cancel",
    "Ulica_jest_wymagana": "Street is required",
    "Miasto_jest_wymagane": "City is required",
    "Podanie_nr_ulicy_jest_wymagane": "Street number is required",
    "Sekundy": "Seconds",
    "Autoryzowane_domeny": "Authorized domains",
    "Edytuj_Autoryzowane_domeny": "Edit Authorized domains",
    "Nazwa_domeny": "Domain name",
    "Przypisane_nr_rejestracyjne": "Assigned plates",
    "Przypisz_numery_rejestracyjne": "Assign license plates",
    "Przypisane_tablice": "Assigned plates",
    "Numery_rejestracyjne": "License plates",
    "Pokaz_kod_qr": "Show QR code",
    "Dashboardy": "Dashboards",
    "Dashboard_glowny": "Main dashboard",
    "Statystyki_dzienne": "Daily statistics",
    "Siedem_dni": "7 days",
    "Trzydziesci_dni": "30 days",
    "Dwanascie_miesiecy": "12 months",
    "Statystyki_rezerwacji": "Reservation statistics",
    "Parkuje": "Parked",
    "Nie_parkuje": "Not parked",
    "Status_nieznany": "Unknown status",
    "Szlaban_Brama": "Barrier/Gate",
    "Kod_QR": "QR code",
    "Dane_uzytkownika": "User data",
    "Serwer": "Server",
    "Uzytkownik": "User",
    "Historia_uzycia_LPR_QR_przez_uzytkownikow": "History of LPR/QR usage by users",
    "Zarzadzanie_dostepem_LPR": "LPR access management",
    "Uzytkownicy_ktorzy_posiadaja_dostep_LPR": "Users with LPR access",
    "Statystyki_parkingu": "Parking statistics",
    "Typ_czytnika": "Reader type",
    "Data_zdarzenia": "Event date",
    "Czytnik": "Reader",
    "Wyszukaj_po_numerze_rejestracyjnym": "Search by license plate number",
    "Parking_OS": "Parking OS",
    "Wyszukaj_po_imieniu_nazwisku_uzytkonika": "Search by user name",
    "Niedostepne": "Unavailable",
    "Caly_parking": "Whole parking"
  },
  "pl": {
    "Dashboard": "Dashboard",
    "Parkingi": "Parkingi",
    "Piloty": "Piloty",
    "Moj_profil": "Mój profil",
    "Email": "Email",
    "Haslo": "Hasło",
    "Regulamin": "Regulamin",
    "Zaloguj_sie": "Zaloguj się",
    "Otworz": "Otwórz",
    "Udostepnij": "Udostępnij",
    "ul": "ul.",
    "Udostepnij_Pilota": "Udostępnij Pilota",
    "Imie_i_Nazwisko": "Imię i Nazwisko",
    "Wybierz_pilota": "Wybierz pilota",
    "Usun": "Usuń",
    "Dodaj": "Dodaj",
    "Niepoprawny_format": "Niepoprawny format",
    "Wymagane": "Wymagane",
    "Identyfikator": "Identyfikator",
    "PIN": "PIN",
    "Blad_zapisu": "Błąd zapisu",
    "Posiadasz_juz_dostep_do_tego_pilota": "Posiadasz już dostęp do tego pilota",
    "Niepoprawny_identyfikator_lub_pin": "Niepoprawny identyfikator lub pin",
    "Dostep_do_wirtualnego_pilota_wygasl": "Dostęp do wirtualnego pilota wygasł",
    "Blad_usuniecia_pilota": "Błąd usunięcia pilota",
    "Otwarto": "Otwarto",
    "Blad_otwarcia": "Błąd otwarcia",
    "Adres": "Adres",
    "Ulica": "Ulica",
    "Typ": "Typ",
    "Haslo_musi_zawierac_co_najmniej_7_znakow": "Hasło musi zawierać co najmniej 7 znaków",
    "Zmien_haslo": "Zmień hasło",
    "Zmien": "Zmień",
    "Stare_haslo": "Stare hasło",
    "Nowe_haslo": "Nowe hasło",
    "Haslo_zostalo_zmienione": "Hasło zostało zmienione",
    "Profil": "Profil",
    "Konto_do_wyplat": "Konto do wypłat",
    "Imie": "Imię",
    "Nazwisko": "Nazwisko",
    "Numer_telefonu": "Numer telefonu",
    "Dane": "Dane",
    "Data_rozpoczecia_musi_byc_mniejsza_od_daty_zakonczenia": "Data rozpoczęcia musi być mniejsza od daty zakończenia",
    "Wymagane_jest_podanie_emaila_lub_telefonu": "Wymagane jest podanie emaila lub telefonu",
    "Adres_email": "Adres email",
    "Udostepnij_jednorazowo": "Udostępnij jednorazowo",
    "Link_zostal_wyslany": "Link został wysłany",
    "czas": "czas",
    "Wyslij": "Wyślij",
    "Kod": "Kod",
    "Ustaw_nowe_haslo": "Ustaw nowe hasło",
    "Wyslany_kod_nie_jest_juz_aktualny": "Wysłany kod nie jest już aktualny",
    "Podany_kod_resetujacy_jest_niepoprawny": "Podany kod resetujący jest niepoprawny",
    "Wszystko": "Wszystko",
    "Status": "Status",
    "Aktywny": "Aktywny",
    "Zablokowany": "Zablokowany",
    "Nieaktywny": "Nieaktywny",
    "Wszystkie": "Wszystkie",
    "Pilot": "Pilot",
    "Twoje_udostepnienia": "Twoje udostępnienia",
    "Nazwa": "Nazwa",
    "Poczatek": "Początek",
    "Koniec": "Koniec",
    "Opis": "Opis",
    "Numer_rejestracyjny_samochodu": "Numer rejestracyjny samochodu",
    "Wroc": "Wróć",
    "Niewazny": "Nieważny",
    "Zablokuj": "Zablokuj",
    "Brak_danych": "Brak danych",
    "Szczegoly": "Szczegóły",
    "Wyslij_ponownie": "Wyślij ponownie",
    "Blad": "Błąd",
    "Aktywuj": "Aktywuj",
    "Dezaktywuj": "Dezaktywuj",
    "Data_udostepnienia": "Data udostępnienia",
    "Jednorazowy": "Jednorazowy",
    "Zrob_rezerwacje": "Zrób rezerwację",
    "na": "na",
    "Rezerwacja": "Rezerwacja",
    "Koszt": "Koszt",
    "Odrzucone": "Odrzucone",
    "Zapytanie": "Zapytanie",
    "oczekuje_na_płatnosc": "oczekuje na płatność",
    "zakonczona": "zakończona",
    "potwierdzona": "potwierdzona",
    "trwajaca": "trwająca",
    "anulowana": "anulowana",
    "zapytanie": "zapytanie",
    "Odwolaj": "Odwołaj",
    "Czy_na_pewno_chcesz_odwolac_ta_rezerwacje": "Czy na pewno chcesz odwołać tą rezerwację?",
    "Rezerwacja_dokonana": "Rezerwacja_dokonana",
    "Nastapil_blad": "Nastąpił błąd",
    "Zapisz": "Zapisz",
    "Nieprawidlowy_format": "Nieprawidłowy format",
    "Poziom": "Poziom",
    "Informacje_o_miejscu": "Informacje o miejscu",
    "Cena": "Cena",
    "Parking": "Parking",
    "Garaz": "Garaż",
    "Na_zewnatrz": "Na zewnątrz",
    "Samochod": "Samochód",
    "Motocykl": "Motocykl",
    "Publiczna": "Publiczna",
    "Ograniczona": "Ograniczona",
    "Edytuj": "Edytuj",
    "Wskazowki_dojazdu": "Wskazówki dojazdu",
    "Typ_miejsca": "Typ miejsca",
    "Rodzaj_pojazdu": "Rodzaj pojazdu",
    "Dostepnosc_miejsca": "Dostępność miejsca",
    "Wynajmij_krotkotermninowo": "Wynajmij krótkoterminowo",
    "Wynajmij_dlugoterminowo": "Wynajmij długoterminowo",
    "Cena_za_godzine": "Cena za godzinę (zł/1h)",
    "Cena_za_miesiac": "Cena za miesiąc (zł/mies.)",
    "Numer_rejestracyjny": "Numer rejestracyjny",
    "Parkujacy": "Parkujący",
    "Mobilny_pilot": "Mobilny pilot",
    "Tak": "Tak",
    "Nie": "Nie",
    "Data_dolaczenia": "Data dołączenia",
    "Uzytkownicy": "Użytkownicy",
    "Rezerwacje": "Rezerwacje",
    "Aktywne": "Aktywne",
    "Dezaktywowane": "Dezaktywowane",
    "Miejsca": "Miejsca",
    "Czy_na_pewno_chcesz_usunac_pilota": "Czy na pewno chcesz usunąć pilota?",
    "Czy_na_pewno_chcesz_usunac_miejsce": "Czy na pewno chcesz usunąć miejsce?",
    "Czy_na_pewno_chcesz_dezaktywowac_miejsce": "Czy na pewno chcesz dezaktywować miejsce?",
    "Zapytania": "Zapytania",
    "Abonamenty": "Abonamenty",
    "Faktury": "Faktury",
    "Start": "Start",
    "Parkowanie": "Parkowanie",
    "Moje_parkowanie": "Moje parkowanie",
    "Akcje": "Akcje",
    "Miejsce_parkingowe": "Miejsce parkingowe",
    "Zarzadzanie_mobilnymi_pilotami": "Zarządzanie mobilnymi pilotami",
    "Uzytkownicy_ktorzy_posiadaja_staly_dostep_do_mobilnych_pilotow": "Użytkownicy, którzy posiadają stały dostęp do mobilnych pilotów",
    "Lista_udostenien_czasowych": "Lista udostęnień czasowych",
    "Czasowe_dostepy_pozwalajace_na_wykorzystanie_mobilnego_pilota": "Czasowe dostępy pozwalające na wykorzystanie mobilnego pilota",
    "Data_waznosci": "Data ważności",
    "Udostepnianie": "Udostepnianie",
    "Wlasciciel": "Wlasciciel",
    "Numer": "Numer",
    "Nieaktywne": "Nieaktywne",
    "Miejsce": "Miejsce",
    "Zakonczone": "Zakończone",
    "Anulowane": "Anulowane",
    "Anulowana": "Anulowana",
    "Zaplanowane": "Zaplanowane",
    "Nieznany": "Nieznany",
    "Wlasciciel_miejsca": "Właściciel miejsca",
    "Wybierz": "Wybierz",
    "Waznosc_od": "Ważność od",
    "Waznosc_do": "Ważność do",
    "Przydziel_pilota": "Przydziel pilota",
    "Przydziel_mobilnego_pilota": "Przydziel mobilnego pilota",
    "Dostep_przydzielony": "Dostep przydzielony",
    "Dodaj_uzytkownika": "Dodaj użytkownika",
    "Czy_na_pewno_chcesz_usunac_uzytkownika_z_tego_parkingu": "Czy na pewno chcesz usunąć użytkownika z tego parkingu?",
    "Uzyty": "Użyty",
    "Czy_na_pewno_chcesz_usunac_dostep_do_pilota": "Czy na pewno chcesz usunąć dostęp do pilota",
    "dla_uzytkownika": "dla użytkownika",
    "Uzytkownik_posiada_juz_dostep_do_tego_pilota": "Użytkownik posiada już dostęp do tego pilota",
    "Odblokuj": "Odblokuj",
    "Skopiowano_do_schowka": "Skopiowano do schowka",
    "Mail_na_ktory_wyslalismy_rezerwacje": "Mail, na który wysłaliśmy rezerwację",
    "Nr_telefonu_na_ktory_wyslalismy_rezerwacje": "Nr telefonu, na który wysłaliśmy rezerwację",
    "Informacje_o_rezerwacji": "Informacje o rezerwacji",
    "Udostepnienie_zostalo_wyslane": "Udostępnienie zostało wysłane",
    "Stworzone": "Stworzone",
    "Bezplatne_udostepnianie_w_lokalnej_wspolnocie": "Bezpłatne udostępnianie w lokalnej wspólnocie",
    "Wynajem_krotkoterminowy": "Wynajem krótkoterminowy",
    "Wynajem_dlugoterminowy": "Wynajem długoterminowy",
    "brak": "brak",
    "bezplatny": "bezplatny",
    "Przydziel": "Przydziel",
    "Edytuj_dostep_do_mobilnego_pilota": "Edytuj dostęp do mobilnego pilota",
    "Edytuj_udostepnienie_czasowe_pilota": "Edytuj udostępnienie czasowe pilota",
    "Udostepnienie_pilota": "Udostępnienie pilota",
    "Zablokuj_mozliwosc_udostepniania": "Zablokuj możliwość udostępniania",
    "Odblokuj_mozliwosc_udostepniania": "Odblokuj możliwość udostępniania",
    "Data_wystawienia": "Data wystawienia",
    "Pobierz_fakture": "Pobierz fakturę",
    "NIP": "NIP",
    "Nazwa_firmy": "Nazwa firmy",
    "Cena_netto": "Cena netto",
    "Cena_brutto": "Cena brutto",
    "VAT": "VAT",
    "Nr_faktury": "Nr faktury",
    "SMS_zostal_wyslany": "SMS został wysłany",
    "Na_twoj_numer_telefony_wyslalismy_adres_do_otwarcia_bramy": "Na twój numer telefonu wysłaliśmy adres do otwarcia bramy",
    "Wjazd": "Wjazd",
    "Wyjazd": "Wyjazd",
    "Wjedz_na_parking_otwierajac_brame": "Wjedź na parking otwierając bramę",
    "Zaparkuj_i_oplac_parkowanie": "Zaparkuj i opłać parkowanie",
    "Wyjedz_z_parkingu": "Wyjedź z parkingu",
    "Zakonczyles_parkowanie": "Zakończyłeś parkowanie",
    "Dziekujemy_za_skorzystanie_i_zapraszamy_ponownie": "Dziękujemy za skorzystanie i zapraszamy ponownie",
    "Oczekiwanie_na_potwierdzenie_transakcji": "Oczekiwanie na potwierdzenie transakcji",
    "Wpisz_swoj_numer_rejestracyjny_i_telefon": "Wpisz swój numer rejestracyjny i telefon",
    "Na_podany_numer_telefonu_wyslemy_Ci_link_do_otwarcia_bramy_dzieki_czemu_bedziesz_mogl_rozpoczac_parkowanie": "Na podany numer telefonu wyślemy Ci link do otwarcia bramy, skorzystaj z niego aby rozpocząć parkowanie",
    "Wyslij_SMS": "Wyślij SMS",
    "Masz_nieoplacone_parkowanie": "Masz nieopłacone parkowanie",
    "Parkowales_na_tym_parkingu_i_nieusciles_oplaty_W_celu_kontynuowania_oplac_stare_parkowanie": "Parkowałeś na tym parkingu i nie uiściłeś opłaty. Aby kontynuować ureguluj płatność",
    "Wjedz_na_parking": "Wjedź na parking",
    "Otworz_brame_wciskajac_ponizszy_przycisk": "Otwórz bramę korzystając z poniższego przycisku",
    "Link_zostal_juz_uzyty_do_wjechania_na_parking": "Ten link został już użyty do wjechania na parking",
    "Przejdz_do_starego_parkowania": "Przejdź do nieukończonego parkowania",
    "zl": "zł",
    "gr": "gr",
    "Zakoncz_parkowanie_i_zaplac": "Zakończ parkowanie i zapłać",
    "Zakoncz_parkowanie": "Zakończ parkowanie",
    "Aktualnie_parkujesz": "Aktualnie parkujesz",
    "Koszt_parkowania": "Koszt parkowania",
    "Pozostaly_czas_do_wyjazdu_z_parkingu": "Pozostały czas do wyjazdu z parkingu",
    "Platforma_wspoldzielenia_miejsc_parkingowych": "Platforma współdzielenia miejsc parkingowych",
    "dla": "dla",
    "biur": "biur",
    "i": "i",
    "osiedli": "osiedli",
    "Zaloz": "Załóż",
    "darmowe_konto": "darmowe konto",
    "i_zobacz_jak_działa_aplikacja": "i zobacz jak działa aplikacja!",
    "Do_pobrania_z": "Do pobrania z",
    "lub": "lub",
    "Zapomniales_hasla": "Zapomniałeś hasła?",
    "Nie_masz_jeszcze_konta": "Nie masz jeszcze konta?",
    "Zaloz_je": "Załóż je",
    "Blad_logowania": "Błąd logowania",
    "Profil_indywidualny": "Profil indywidualny",
    "Zarzadzenie_parkingiem": "Zarządzanie parkingiem",
    "Administrator": "Administrator",
    "Wyloguj": "Wyloguj",
    "Dodaj_nowego_pilota": "Dodaj nowego pilota",
    "Potwierdz": "Potwierdz",
    "Pilot_dostepu": "Pilot dostępu",
    "Anuluj": "Anuluj",
    "Jesli_jeszcze_nie_masz_swojego_pilota_zdobądź_go_pod_adresem": "Jeśli jeszcze nie masz swojego pilota zdobądź go pod adresem",
    "Dodaj_nowe_miejsce": "Dodaj nowe miejsce",
    "Oceny": "Oceny",
    "Udostepnij_miejsce_parkingowe": "Udostępnij miejsce parkingowe",
    "poniedzialek_abbr": "pon",
    "wtorek_abbr": "wt",
    "sroda_abbr": "śr",
    "czwartek_abbr": "czw",
    "piatek_abbr": "pią",
    "sobota_abbr": "sob",
    "niedz_abbr": "ndz",
    "poniedzialekOneLetter": "P",
    "wtorekOneLetter": "W",
    "srodaOneLetter": "Ś",
    "czwartekOneLetter": "C",
    "piatekOneLetter": "Pi",
    "sobotaOneLetter": "S",
    "niedzielaOneLetter": "N",
    "stycznia": "stycznia",
    "lutego": "lutego",
    "marca": "marca",
    "kwietnia": "kwietnia",
    "maja": "maja",
    "czerwca": "czerwca",
    "lipca": "lipca",
    "sierpnia": "sierpnia",
    "wrzesnia": "września",
    "pazdziernika": "października",
    "listopada": "listopada",
    "grudnia": "grudnia",
    "poniedzialek": "poniedziałek",
    "wtorek": "wtorek",
    "sroda": "środa",
    "czwartek": "czwartek",
    "piatek": "piątek",
    "sobota": "sobota",
    "niedziela": "niedziela",
    "Dzisiaj": "Dzisiaj",
    "Wczoraj": "Wczoraj",
    "Jutro": "Jutro",
    "godz": "godz.",
    "min": "min.",
    "sek": "sek.",
    "Udostepnij_mobilnego_pilota": "Udostępnij mobilnego pilota",
    "Wyszukaj": "Wyszukaj",
    "Ilosc_wierszy": "Ilość wierszy",
    "Data_rozpoczecia": "Data rozpoczęcia",
    "Data_zakonczenia": "Data zakończenia",
    "Nr_telefonu": "Nr telefonu",
    "Udostepnione_dla": "Udostępnione dla",
    "Dodaj_rezerwacje": "Dodaj rezerwację",
    "styczen": "styczeń",
    "luty": "luty",
    "marzec": "marzec",
    "kwiecien": "kwiecień",
    "maj": "maj",
    "czerwiec": "czerwiec",
    "lipiec": "lipiec",
    "sierpien": "sierpień",
    "wrzesien": "wrzesien",
    "pazdziernik": "październik",
    "listopad": "listopad",
    "grudzien": "grudzień",
    "Profil_uzytkownika": "Profil użytkownika",
    "Wybierz_uzytkownika": "Wybierz użytkownika",
    "Miejsce_zostanie_podane_15_min_przed_parkowaniem": "Miejsce zostanie podane 15 min. przed parkowaniem",
    "tymczasowa": "tymczasowa",
    "W_jakich_godzinach_chcesz_udostepnic_pilota": "W jakich godzinach chcesz udostępnić pilota?",
    "Komu_chcesz_udostepnic_pilota": "Komu chcesz udostępnić pilota?",
    "Pojazdy": "Pojazdy",
    "Metody_platnosci": "Metody płatności",
    "Dodaj_nowy_pojazd": "Dodaj nowy pojazd",
    "Twoje_punkty": "Twoje punkty",
    "Zarobiles_juz": "Zarobiłeś już",
    "pkt": "pkt.",
    "Imie_i_nazwisko": "Imię i nazwisko",
    "Edytuj_profil": "Edytuj profil",
    "Konto_bankowe": "Konto bankowe",
    "Aktualne_haslo_nie_jest_poprawne": "Aktualne hasło nie jest poprawne",
    "Zmiana_hasla": "Zmiana hasła",
    "Lista_wplywow": "Lista wpływów",
    "Lista_uzyskanych_parkcoinow": "Lista uzyskanych ParkCoinów",
    "Kwota": "Kwota",
    "Data": "Data",
    "Tytul": "Tytul",
    "Dane_profilowe": "Dane profilowe",
    "Czy_na_pewno_chcesz_usunac_metode_platnosci": "Czy na pewno chcesz usunąć metodę płatności?",
    "Czy_na_pewno_chcesz_usunac_pojazd": "Czy na pewno chcesz usunąć pojazd?",
    "Dane_pojazdu": "Dane pojazdu",
    "Kod_wpoldzielenia": "Kod współdzielenia",
    "Marka": "Marka",
    "Model": "Model",
    "Jednorazowo": "Jednorazowo",
    "Cyklicznie": "Cyklicznie",
    "Wybierz_miejsce_parkingowe_do_udostepnienia": "Wybierz miejsce parkingowe do udostępnienia",
    "miejsce": "miejsce",
    "poziom": "poziom",
    "jutro": "jutro",
    "pojutrze": "pojutrze",
    "weekend": "weekend",
    "Ustaw_dostepnosc_miejsca_parkingowego": "Ustaw dostępność miejsca parkingowego:",
    "Ustaw_na_caly_dzien": "Ustaw na cały dzień",
    "Godzina_rozpoczecia": "Godzina rozpoczęcia",
    "Godzina_zakonczenia": "Godzina zakończenia",
    "Komu_chcesz_udostepnic": "Komu chcesz udostępnić?",
    "Zakladajac_konto_zgadzasz_sie_z": "Zakładając konto zgadzasz się z",
    "Regulaminem": "Regulaminem",
    "oraz": "oraz",
    "Polityka_Prywatnosci": "Polityką Prywatności",
    "Zaloz_konto": "Załóż konto",
    "Udostepnij_do_odwolania": "Udostępnij do odwołania",
    "Uzyj_wszedzie_tych_samych_godzin": "Użyj wszędzie tych samych godzin",
    "Wybierz_godziny_dostepnosci_miejsca": "Wybierz godziny dostępności miejsca",
    "Poniedzialek": "Poniedziałek",
    "Wtorek": "Wtorek",
    "Sroda": "Środa",
    "Czwartek": "Czwartek",
    "Piatek": "Piątek",
    "Sobota": "Sobota",
    "Niedziela": "Niedziela",
    "Czy_chcesz_udostepnic_swoje_miejsce_parkingowe": "Czy chcesz udostępnić swoje miejsce parkingowe?",
    "Dzien": "Dzień",
    "Tydzien": "Tydzien",
    "Wolne": "Wolne",
    "Zajete": "Zajęte",
    "Odwolaj_rezerwacje": "Odwołaj rezerwację",
    "Zarobek": "Zarobek",
    "Pojazd": "Pojazd",
    "bezplatnie": "bezpłatnie",
    "Udostepnione": "Udostępnione",
    "Czy_na_pewno_chcesz_usunac_udostepnienie": "Czy na pewno chcesz usunąć udostępnienie?",
    "Rezerwuj_pod_adresem": "Rezerwuj pod adresem:",
    "Zapytanie_zostalo_juz_zaakceptowane": "Zapytanie zostało już zaakceptowane",
    "Zapytanie_zostalo_juz_usuniete": "Zapytanie zostało już usunięte",
    "Zapytanie_o_miejsce_jest_juz_nieaktualne": "Zapytanie o miejsce jest już nieaktualne",
    "Zaakceptuj": "Zaakceptuj",
    "Odrzuc": "Odrzuć",
    "Zapytania_o_miejsce": "Zapytania o miejsce",
    "Nie_znaleziono_miejsca_parkingowego": "Nie znaleziono miejsca parkingowego",
    "Nie_przejmuj_sie_wynikiem_wyszukiwania_i_zapytaj_okolicznych_wlascicieli_o_wolne_miejsce": "Nie przejmuj się wynikiem wyszukiwania i zapytaj okolicznych właścicieli o wolne miejsce.",
    "Zapytaj_o_miejsce": "Zapytaj o miejsce",
    "zl_h": "zł/h",
    "Dla_goscia": "Dla gościa",
    "Dla_siebie": "Dla siebie",
    "Wiadomosc": "Wiadomość",
    "Nr_rejestracyjny": "Nr rejestracyjny",
    "Forma_zaplaty": "Forma zapłaty",
    "Podaj_adres_parkowania": "Podaj adres parkowania",
    "Wyniki_wyszukiwania": "Wyniki wyszukiwania",
    "Wpisz_adres": "Wpisz adres",
    "Nie_znaleziono_adresu": "Nie znaleziono adresu",
    "Wymagane_jest_podanie_numeru_domu": "Wymagane jest podanie numeru domu",
    "Podaj_adres": "Podaj adres",
    "Wyslij_kod_do_zresetowania_hasla": "Wyślij kod do zresetowania hasła",
    "Haslo_zostalo_zresetowane": "Hasło zostało zresetowane",
    "Wpisz_nazwe": "Wpisz nazwę",
    "Haslo_superadmina": "Hasło superadmina",
    "Id_uzytkownika": "Id użytkownika",
    "Zaloguj": "Zaloguj",
    "Wroc_do_strony_glownej": "Wróć do strony głównej",
    "Zakaz_wjazdu": "Zakaz wjazdu!",
    "Przepraszamy_ale_strona_ktorej_szukasz_nie_istnieje": "Przepraszamy, ale strona której szukasz nie istnieje.",
    "Moze_byc_to_spowodowane_nieprawidlowym_odnosnikiem_na_stronie_z_ktorej_tu_trafiles": "Może być to spowodowane nieprawidłowym odnośnikiem na stronie, z której tu trafiłeś.",
    "Podaj_adres_miejsca": "Podaj adres miejsca",
    "Aby_moc_dokonac_platnej_rezerwacji_musisz_miec_dodana_forme_platnosci": "Aby móc dokonać płatnej rezerwacji musisz mieć dodaną formę płatności",
    "Rezerwuje_i_place": "Rezerwuję i płacę",
    "Rezerwuje": "Rezerwuję",
    "darmo": "darmo",
    "Szybki_przelew": "Szybka płatność online",
    "Uzytkownik_z_podanym_emailem_juz_istnieje": "Użytkownik z podanym emailem już istnieje",
    "Wszystkie_wymagane_zgody_musza_byc_zaznaczone": "Wszystkie wymagane zgody muszą być zaznaczone",
    "Znajdz_miejsce_parkingowe": "Znajdź miejsce parkingowe",
    "Grupy": "Grupy",
    "Nie_masz_jeszcze_zadnych_grup_adresowych": "Nie masz jeszcze żadnych grup adresowych",
    "Wystarcza": "Wystarczą",
    "2_minuty": "2 minuty",
    "by_stworzyc_Grupe_adresowa": "by stworzyć Grupę adresową",
    "Dolacz_do_nowej_grupy": "Dołącz do nowej grupy",
    "Dodaj_nowa_grupe": "Dodaj nową grupę",
    "Zapros_do_grupy": "Zaproś do grupy",
    "Czy_na_pewno_chcesz_odrzucic_zaproszenie_do_grupy": "Czy na pewno chcesz odrzucić zaproszenie do grupy?",
    "Czy_na_pewno_chcesz_zaakceptowac_zaproszenie_do_grupy": "Czy na pewno chcesz zaakceptować zaproszenie do grupy?",
    "Zostales_zaproszony_do_parkingu": "Zostałeś zaproszony do parkingu",
    "pod_adresem": "pod adresem",
    "Czy_akceptujesz_zaproszenie": "Czy akceptujesz zaproszenie?",
    "Zarzadzaj": "Zarządzaj",
    "i_ciesz_sie_blyskawiczna_rezerwacja": "i ciesz się błyskawiczną rezerwacją.",
    "Kopiuj_link": "Kopiuj link",
    "Czy_na_pewno_chcesz_usunac_uzytkownika_z_grupy": "Czy na pewno chcesz usunąć użytkownika z grupy?",
    "Utworz_grupe": "Utwórz grupę",
    "Dolacz_do_grupy": "Dołącz do grupy",
    "Dolacz_do_grupy_adresowej_podajac_kod_wspoldzielenia": "Dołącz do grupy adresowej podając kod współdzielenia",
    "Utworz_grupe_adresowa_podajac_jej_adres": "Utwórz grupę adresową podając jej adres",
    "Nazwa_grupy": "Nazwa grupy",
    "Opusc": "Opuść",
    "Czy_na_pewno_chcesz_opuscic_grupe": "Czy na pewno chcesz opuścic grupę?",
    "Administrator_lub_moderator_nie_moze_opuscic_grupy": "Administrator lub moderator nie może opuścić grupy",
    "W_celu_dolaczenia_do_grupy_zaloguj_sie_lub_zaloz_konto": "W celu dołączenia do grupy zaloguj się lub załóż konto.",
    "Nie_mozna_odwolac_rezerwacji_ktora_sie_juz_rozpoczela_lub_zaraz_rozpocznie": "Nie można odwołać rezerwacji, która się już rozpoczęła lub zaraz rozpocznie",
    "Status_faktury": "Status faktury",
    "W_przygotowaniu": "W przygotowaniu",
    "Gotowa": "Gotowa",
    "Maksymalna_cena_za_dobe": "Maksymalna cena za dobę",
    "Udostepnij_bezplatnie_w_grupie": "Udostępnij bezpłatnie w grupie",
    "Abonament_parkingowy": "Abonament parkingowy",
    "Zakup_abonamentu_parkingowego": "Zakup abonamentu parkingowego",
    "Przedluzenie_abonamentu_parkingowego": "Przedłużenie abonamentu parkingowego",
    "Rezerwacja_miejsca_parkingowego": "Rezerwacja miejsca parkingowego",
    "Wybierz_czy_chcesz_przedluzyc_abonament_czy_wykupic_nowy": "Wybierz czy chcesz przedłużyć abonament czy wykupić nowy.",
    "Przedluz": "Przedłuż",
    "abonament": "abonament",
    "Nowy": "Nowy",
    "Platnosc": "Platność",
    "Potwierdz_platnosc": "Potwierdź płatność",
    "Wpisz_kod_znizkowy": "Wpisz kod zniżkowy",
    "Kod_rabatowy": "Kod rabatowy",
    "Do_zaplaty": "Do zapłaty",
    "Uzyj_kodu": "Użyj kodu",
    "Cena_po_obnizce": "Cena po obniżce",
    "Potwierdz_kodem_z_wiadomości_SMS_ktora_do_Ciebie_wyslalismy": "Potwierdź kodem z wiadomości SMS, którą do Ciebie wysłaliśmy.",
    "Wybierz_abonament_ktory_chcesz_przedluzyc": "Wybierz abonament, który chcesz przedłużyć.",
    "Podaj_numer_dla_ktorego_chcesz_przedlużyc_abonament": "Podaj numer, dla którego chcesz przedłużyć abonament.",
    "Kod_sms": "Kod sms",
    "Potwierdzam": "Potwierdzam",
    "Wazny_do": "Ważny do",
    "Transakcja_w_trakcie_realizownia": "Transakcja w trakcie realizownia",
    "Oczekujemy_na_odpowiedz_z_Twojego_banku": "Oczekujemy na odpowiedź z Twojego banku",
    "Transakcja_nie_zostala_zrealizowana": "Transakcja nie została zrealizowana",
    "Sprobuj_jeszcze_raz": "Spróbuj jeszcze raz",
    "Wroc_na_strone_parkingu": "Wróć na stronę parkingu",
    "Ponow_platnosc": "Ponów płatność",
    "Dane_abonamentu": "Dane abonamentu",
    "Chce_otrzymac_fakture": "Chcę otrzymać fakturę",
    "Typ_faktury": "Typ faktury",
    "Osoba_prywatna": "Osoba prywatna",
    "Firma": "Firma",
    "Obowiazkowe_tylko_dla_faktur_na_firme": "Obowiązkowe tylko dla faktur na firmę",
    "Dla_firmy": "Dla firmy",
    "nazwa_firmy": "nazwa firmy",
    "Dla_osoby_fizycznej": "Dla osoby fizycznej",
    "imie_i_nazwisko": "imię i nazwisko",
    "Kontrahent": "Kontrahent",
    "Nr_domu": "Nr domu",
    "Nr_lokalu": "Nr lokalu",
    "Miejscowosc": "Miejscowość",
    "Kod_pocztowy": "Kod pocztowy",
    "Zapoznalem_sie_i_akceptuje": "Zapoznałem się i akceptuję",
    "Regulamin_sprzedazy": "Regulamin sprzedaży",
    "Polityke_prywatnosci": "Politykę prywatności",
    "Regulamin_parkingu": "Regulamin parkingu",
    "Miejsce_dla_ktorego_chcesz_wykupic_abonament": "Miejsce dla którego chcesz wykupić abonament",
    "Poczatek_parkowania": "Początek parkowania",
    "Wybierz_abonament": "Wybierz abonament",
    "Nieprawidlowy_kod_promocyjny": "Nieprawidłowy kod promocyjny",
    "Nie_mozna_skorzystac_z_wielu_promocji_na_raz": "Nie można skorzystać z wielu promocji na raz",
    "Podany_kod_promocyjny_wygasl": "Podany kod promocyjny wygasł",
    "Wyrazenie_zgody_wymagane": "Wyrażenie zgody wymagane",
    "Zbyt_duzo_prob_weryfikacji_numeru_Sprobuj_za_kilka_minut": "Zbyt dużo prób weryfikacji numeru. Spróbuj za kilka minut.",
    "Do_podanego_numeru_telefonu_nie_ma_przypisanego_zadnego_abonamentu": "Do podanego numeru telefonu nie ma przypisanego żadnego abonamentu",
    "Nieprawidlowy_kod_weryfikacyjny": "Nieprawidłowy kod weryfikacyjny",
    "Podany_kod_weryfikacyjny_zostal_juz_uzyty": "Podany kod weryfikacyjny został już użyty",
    "Link_do_przedluzenia_zostal_juz_wykorzystany": "Link do przedłużenia został już wykorzystany",
    "Podany_kod_promocyjny_zostal_juz_uzyty": "Podany kod promocyjny został już użyty",
    "Potwierdzenie_transakcji": "Potwierdzenie transakcji",
    "Twoja_płatnosc_została_odrzucona_przez_operatora": "Twoja płatność została odrzucona przez operatora.",
    "Wybrane_miejsce_zostalo_zarezerwowane_w_miedzyczasie_Skontatktuj_sie_z_nami_w_celu_uzyskania_zwrotu_platnosci": "Wybrane miejsce zostało zarezerwowane w międzyczasie. Skontatktuj się z nami w celu uzyskania zwrotu płatności.",
    "Cos_poszlo_nie_tak": "Coś poszło nie tak",
    "Status_zakupu": "Status zakupu",
    "Przekroczono_czas_oczekiwania": "Przekroczono czas oczekiwania",
    "Sprawdzanie_statusu_zakupu": "Sprawdzanie statusu zakupu",
    "Dokonano_zakupu": "Dokonano zakupu",
    "Kody_rabatowe": "Kody rabatowe",
    "Liczba_dni_obowiazywania": "Liczba dni obowiązywania",
    "Cena_promocyjna": "Cena promocyjna",
    "Aktywacja": "Aktywacja",
    "Abonament": "Abonament",
    "Nie_stworzyles_jeszcze_abonamentu": "Nie stworzyłeś jeszcze abonamentu",
    "Stworz_abonament": "Stwórz abonament",
    "Nie_stworzyles_jeszcze_kodow_rabatowych": "Nie stworzyłeś jeszcze kodów rabatowych",
    "Stworz_kod_rabatowy": "Stwórz kod rabatowy",
    "rabatowy": "rabatowy",
    "Bez_limitu": "Bez limitu",
    "Stworz_kod": "Stwórz kod",
    "Nowy_kod_rabatowy": "Nowy kod rabatowy",
    "Nowy_abonament": "Nowy abonament",
    "Zakup_abonamentu": "Zakup abonamentu",
    "Kup_abonament": "Kup abonament",
    "Przedluzenie_abonamentu": "Przedłużenie abonamentu",
    "Konfiguracja": "Konfiguracja",
    "Rodzaje_abonamentow": "Rodzaje abonamentów",
    "Administrator_parkingu_wylaczyl_abonament": "Administrator parkingu wylaczyl abonament",
    "Kod_promocyjny_zostal_wylaczony_przez_administratora_parkingu": "Kod promocyjny zostal wylaczony przez administratora parkingu",
    "Nie_mozesz_przedluzyc_tego_abonamentu_Administrator_parkingu_wylaczyl_abonament": "Nie możesz przedłużyć tego abonamentu. Administrator parkingu wyłączył abonament.",
    "Z_podanym_numerem_telefonu_masz_juz_powiazane_abonamenty_Czy_zamiast_kupowania_nowego_chciałbys_którys_z_nich_przedluzyc": "Z podanym numerem telefonu masz już powiązane abonamenty. Czy zamiast kupowania nowego, chciałbyś któryś z nich przedłużyć?",
    "Uzytkownik_zostal_zbanowany": "Użytkownik został zbanowany",
    "Wszystkie_miejsca_zostaly_juz_udostepnione": "Wszystkie_miejsca_zostaly_juz_udostepnione",
    "Blad_zakupu_abonamentu": "Błąd zakupu abonamentu",
    "Blad_walidacji": "Błąd walidacji",
    "Blad_wewnetrzny_serwera": "Błąd wewnętrzny serwera",
    "Konto_uzytkownika_nie_jest_aktywne": "Konto użytkownika nie jest aktywne",
    "Parking_jest_juz_zamkniety": "Parking jest już zamknięty",
    "Brak_dostepu": "Brak dostępu",
    "Mobilny_pilot_nie_jest_jeszcze_aktywny": "Mobilny pilot nie jest jeszcze aktywny",
    "Blad_sterownika_mobilnego_pilota": "Błąd sterownika mobilnego pilota",
    "Nie_ma_takiego_uzytkownika": "Nie ma takiego użytkownika",
    "Niepoprawne_email_lub_haslo": "Niepoprawny email lub hasło",
    "Konto_nie_istnieje": "Konto nie istnieje",
    "Uzytkownik_nie_istnieje": "Użytkownik nie istnieje",
    "Rezerwacja_nie_istnieje": "Rezerwacja nie istnieje",
    "Nastapil_konflikt_w_robieniu_rezerwacji": "Nastąpił konflikt w robieniu rezerwacji",
    "Link_do_przedluzenia_abonamentu_wygasl": "Link do przedłużenia abonamentu wygasł",
    "Przekazanie_miejsca_jest_wylaczone": "Przekazanie miejsca jest wyłączone",
    "Blad_polaczenia_sieciowego_lub_wewnetrzny_blad_serwera": "Błąd połączenia sieciowego lub wewnętrzny błąd serwera",
    "Nieznany_blad": "Nieznany błąd",
    "To_nie_jest_blad": "To nie jest błąd",
    "Uzytkownik_nie_jest_czlonkiem_parkingu": "Użytkownik nie jest członkiem parkingu",
    "Kod_QR_nie_zostal_znaleziony": "Kod QR nie został znaleziony",
    "Uzytkownik_jest_zbyt_daleko_od_parkingu": "Użytkownik jest zbyt daleko od parkingu",
    "Blad_inicjalizacji_platnosci_webowej": "Błąd inicjalizacji płatnosci webowej",
    "Nie_mozna_kupic_abonamentu_od_podanego_dnia": "Nie_mozna_kupic_abonamentu_od_podanego_dnia",
    "Nie_znaleziono_zadnych_elementow": "Nie_znaleziono_zadnych_elementow",
    "Nieprawidlowy_kod_zaproszenia_do_parkingu": "Nieprawidlowy kod zaproszenia do parkingu",
    "Zduplikowane_miejsce_parkingowe": "Zdupplikowane miejsce parkingowe",
    "Zduplikowany_uzytkownik": "Zduplikowany_uzytkownik",
    "Blad_logowania_zewnetrznego": "Błąd logowania zewnętrznego",
    "Rezerwacja_zostala_juz_oceniona": "Rezerwacja została już oceniona",
    "Nie_mozna_pobrac_szczegolow_rezerwacji": "Nie można pobrać szczegółów rezerwacji",
    "Kod_dostepu_zostal_juz_uzyty": "Kod dostępu został już użyty",
    "Blad_transakcji_karty_kredytowej": "Blad_transakcji_karty_kredytowej",
    "Zapytanie_o_miejsce_zostalo_odrzucone": "Zapytanie o miejsce zostało odrzucone",
    "Uzytkownik_posiada_juz_mobilnego_pilota": "Użytkownik posiada już mobilnego pilota",
    "Obowiazkowa_zgoda_nie_zostala_zaakceptowana": "Obowiązkowa zgoda nie została zaakceptowana",
    "Obiekt_nie_zostal_znaleziony": "Obiekt nie został znaleziony",
    "Identyfikator_prywatnego_parkingu_nie_jest_unikalny": "Identyfikator prywatnego parkingu nie jest unikalny",
    "Miejsce_parkingowe_nie_zostalo_znalezione": "Miejsce parkingowe nie zostało znalezione",
    "Nieaktualne_dane": "Nieaktualne dane",
    "Kod_dostepu_do_parkowania_zostal_juz_uzyty": "Kod dostępu do parkowania został już użyty",
    "Masz_niezakonczone_parkowanie": "Masz niezakończone parkowanie",
    "Uzytkownik_jest_juz_czlonkiem_grupy": "Użytkownik jest już członkiem grupy",
    "Miejsce_parkingowe_nie_jest_dostepne": "Miejsce parkingowe nie jest dostępne",
    "Zduplikowany_identyfikator_publiczny_parkingu": "Zduplikowany identyfikator publiczny parkingu",
    "Blad_integracji_XControl": "Błąd integracji XControl",
    "Konflikt_przy_tworzeniu_abonamentu": "Konflikt przy tworzeniu abonamentu",
    "Link_do_pilota_zostal_zablokowany_przez_wystawce": "Link do pilota został zablokowany przez wystawcę",
    "Uzytkownik_nie_ma_dostepu_do_pilota": "Uzytkownik nie ma dostepu do pilota",
    "Uzytkownik_nie_moze_udostepniac_pilota": "Użytkownik nie może udostępniać pilota",
    "Udostepnienie_pilota_zostalo_juz_wykorzystane": "Udostępnienie pilota zostało już wykorzystane",
    "Udostepnienie_pilota_nie_zostalo_znalezione": "Udostępnienie pilota nie zostało znalezione",
    "Transakcja_odrzucona": "Transakcja odrzucona",
    "Wyszukaj_zakup_po_imieniu_i_nazwsku_nipie_emailu_telefonie_lub_numerze_rejestracyjnym": "Wyszukaj zakup po imieniu i nazwisku, nipie, emailu, telefonie, numerze miejsca lub numerze rejestracyjnym",
    "Informacje_o_zakupie": "Informacje o zakupie",
    "Dane_kupujacego": "Dane kupującego",
    "Dane_faktury": "Dane faktury",
    "Przedzial_czasowy": "Przedział czasowy",
    "Obnizona_o": "Obniżona o",
    "Powrot_do_aplikacji": "Powrót do aplikacji",
    "Identyfikator_pilota": "Identyfikator pilota",
    "Dolaczyles_do_grupy": "Dołączyleś do grupy",
    "Utworzono_grupe": "Utworzono grupę",
    "Komentarz": "Komentarz",
    "Ocena": "Ocena",
    "Dla_tego_parkingu_nie_zostaly_zdefiniowane_zadne_abonamenty": "Dla tego parkingu nie zostały zdefiniowane żadne abonamenty",
    "Abonament_zostal_juz_przedluzony": "Abonament został już przedłużony",
    "Podany_link_jest_juz_niewazny": "Podany link jest już nieważny",
    "Kup_nowy_abonament": "Kup nowy abonament",
    "Wybierz_miejsce_parkingowe": "Wybierz miejsce parkingowe",
    "Powrot_do_formularza": "Powrót do formularza",
    "Wybierz_miejsce": "Wybierz miejsce",
    "Sektor": "Sektor",
    "Ktos_zarezerwowal_juz_wybrane_miejsce": "Ktoś zarezerwował wybrane miejsce",
    "sektor": "sektor",
    "Nie_mozna_przedluzyc_abonamentu_juz_przedluzonego_przez_uzytkownika": "Nie można przedłużyć abonamentu już przedłużonego przez użytkownika",
    "Podana_data_musi_byc_pozniejsza_niz_aktualna_data_zakonczenia_abonamentu": "Podana data musi być poźniejsza niż aktualna data zakończenia abonamentu",
    "Parametry_rezerwacji": "Parametry rezerwacji",
    "Dane_rezerwacji": "Dane rezerwacji",
    "Koniec_parkowania": "Koniec parkowania",
    "Nie_wybrales_miejsca": "Nie wybrałeś miejsca",
    "Miejsce_rezerwacji": "Miejsce rezerwacji",
    "Wybrane_miejsce_nie_jest_dostepne": "Wybrane miejsce nie jest dostępne",
    "Edytuj_zakup_abonamentu": "Edytuj zakup abonamentu",
    "Usluga_rezerwacji_zewnetrznej_jest_obecnie_wylaczona": "Usługa rezerwacji zewnętrznej jest obecnie wyłaczona",
    "Blokada_miejsca": "Blokada miejsca",
    "platnosc_odrzucona": "płatność odrzucona",
    "Linia_czasowa": "Linia czasowa",
    "Oznacz_jako_rozliczone": "Oznacz jako rozliczone",
    "rez_zewn": "rez. zewn.",
    "rezerwacja": "rezerwacja",
    "start_stop": "start/stop",
    "Nie_zdefiniowano_zadnego_rodzaju_abonamentu": "Nie zdefiniowano żadnego rodzaju abonamentu",
    "Ilosc_dni": "Ilość dni",
    "Wybrany_miesiac": "Wybrany miesiąc",
    "Pelny_miesiac": "Pełny miesiąc",
    "Miesiac_od_ktorego_abonament_ma_obowiazywac": "Miesiąc od którego abonament ma obowiązywać",
    "Lista": "Lista",
    "Stan_na": "Stan na",
    "Funkcjonalnosc_niedostepna": "Funkcjonalność niedostępna",
    "Jezeli_jestes_zainteresowany_planem_na_swoim_parkingu_skontaktuj_sie_z_naszym_dzialem_sprzedazy": "Jeżeli jesteś zainteresowany planem na swoim parkingu, skontaktuj się z naszym działem sprzedaży",
    "Plan": "Plan",
    "Na_tym_miejscu_nigdy_nie_parkowano": "Na tym miejscu nigdy nie parkowano",
    "Nr_dokumentu": "Nr dokumentu",
    "Skopiuj_adres_zakupu_abonamentu": "Skopiuj adres zakupu abonamentu",
    "Okres_trwania": "Okres trwania",
    "Zwolnij_miejsce": "Zwolnij miejsce",
    "Czy_na_pewno_chcesz_zwolnic_miejsce_i_umozliwic_wykupienie_na_nim_nowych_abonamentow": "Czy na pewno chcesz zwolnić miejsce i umożliwić wykupienie na nim nowych abonamentów?",
    "Waznosc": "Ważność",
    "Kod_promocyjny_musi_byc_unikalny": "Kod promocyjny musi być unikalny",
    "Skopiuj_adres_zakupu_rezerwacji": "Skopiuj adres zakupu rezerwacji",
    "Ostatnie_uzycie": "Ostatnie użycie",
    "Klient": "Klient",
    "Liczba_miesiecy": "Liczba miesięcy",
    "Liczba_okresow": "Liczba okresów",
    "Sposob_platnosci": "Sposób płatności",
    "przelew": "przelew",
    "gotowka": "gotówka",
    "Wybierz_jezyk": "Wybierz język",
    "Niestety_ale_nie_ma_juz_zadnych_dostepnych_miejsc": "Niestety, ale nie ma już żadnych dostępnych miejsc",
    "Usun_konto": "Usuń konto",
    "Czy_na_pewno_chcesz_usunac_konto": "Czy na pewno chcesz usunąć konto?",
    "Konto_usuniete": "Konto usunięte",
    "Kalendarz": "Kalendarz",
    "Stan": "Stan",
    "Parametry_abonamentu": "Parametry abonamentu",
    "Miesiac_rozpoczecia_abonamentu": "Miesiąc rozpoczęcia abonamentu",
    "Dzien_rozpoczecia_abonamentu": "Dzień rozpoczęcia abonamentu",
    "Okres": "Okres",
    "Okres_obowiazywania_abonamentu": "Okres obowiązywania abonamentu",
    "Miesiac": "Miesiąc",
    "miesiace": "miesiące",
    "dzien": "dzień",
    "dni": "dni",
    "miesiac": "miesiąc",
    "Powiadomienia": "Notificatins",
    "Podsumowanierezerwacji": "Reservation summary",
    "Nagroda": "Reward",
    "Odwolanie": "Cancellation",
    "Przypomnienie": "Reminder",
    "Oferta": "Oferta",
    "Data_utworzenia": "Data utworzenia",
    "Tresc": "Treść",
    "Sprawdz_cene": "Sprawdź cenę",
    "usluga_platnosci_zostala_wylaczona_dla_parkingu": "usługa płatności została wyłączona dla parkingu",
    "blad_komunikacji_z_systemem_kontroli_dostepu": "błąd komunikacji z systemem kontroli dostepu",
    "bilet_nie_moze_zostac_oplacony": "bilet nie może zostać opłacony",
    "nie_znaleziono_biletu_dla_tej_rejestracji": "nie znaleziono biletu dla tej rejestracji",
    "Aktywny_do": "Aktywny do",
    "Waznosc_uplynela": "Ważność upłynęła",
    "Aktywny_od": "Aktywny od",
    "Wazny_bezterminowo": "Ważny bezterminowo",
    "karta_platnicza": "karta płatnicza",
    "wolne_miejsce": "wolne miejsce",
    "zajete_miejsce": "zajęte miejsce",
    "nieodblokowane_miejsce": "nieodblokowane miejsce",
    "zajete_w_przyszlosci_miejsce": "zajęte w przyszłości miejsce",
    "wybrane_miejsce": "wybrane miejsce",
    "Numer_rejestracyjny_nie_zostal_znaleziony_w_systemie_Upewnij_sie_ze_wpisales_go_poprawnie_oraz_ze_tablica_rejestracyjna_jest_czytelna_nie_jest_zaslonieta_badz_zabrudzona": "Numer rejestracyjny nie został znaleziony w systemie. Upewnij się, że wpisałeś go poprawnie oraz, że tablica rejestracyjna jest czytelna (nie jest zasłonięta bądź zabrudzona).",
    "Jesli_problem_bedzie_sie_powtarzal_zamiast_numeru_rejestracyjnego_podaj_numer_biletu_parkingowego_i_sprobuj_ponownie": "Jeśli problem będzie się powtarzał, zamiast numeru rejestracyjnego podaj numer biletu parkingowego i spróbuj ponownie.",
    "Potwierdzenie": "Potwierdzenie",
    "Bilet_oplacony_Szlaban_podniesie_sie_automatycznie_przy_probie_wyjazdu": "Bilet opłacony. Szlaban podniesie się automatycznie przy próbie wyjazdu.",
    "Darmowe_parkowanie_zakonczone_Szlaban_podniesie_sie_automatycznie_przy_probie_wyjazdu": "Darmowe parkowanie zakończone. Szlaban podniesie się automatycznie przy próbie wyjazdu.",
    "Adres_email_zostal_juz_potwierdzony": "Adres email został już potwierdzony",
    "Nieprawidlowy_kod_potwierdzajacy_email": "Nieprawidłowy kod potwierdzający email",
    "Naruszenie_polityki_rezerwacji": "Naruszenie polityki rezerwacji",
    "Wymagane_potwierdzenie_adresu_email": "Wymagane potwierdzenie adresu email",
    "Wymagane_potwierdzenie_numeru_telefonu": "Wymagane potwierdzenie numeru telefonu",
    "Identyfikator_profilu": "Identyfikator profilu",
    "klient_zalega_z_oplata_miesiac": "klient zalega z opłatą miesiąc",
    "klient_zalega_z_oplata_co_najmniej_2_miesiace": "klient zalega z opłatą co najmniej 2 miesiące",
    "Zweryfikowane": "Zweryfikowane",
    "W_trakcie": "W trakcie...",
    "Zweryfikuj": "Zweryfikuj",
    "Na_twoja_skrzynke_zostal_wyslany_link_potwierdzajacy_W_celu_potwierdzenia_adresu_email_kliknij_go": "Na twoją skrzynkę został wysłany link potwierdzający. W celu potwierdzenia adresu email, kliknij go.",
    "Usuniete_konto": "Usunięte konto",
    "Wymagane_ustawienie_identyfikatora_profilu": "Wyamagane ustawienie identyfikatora profilu",
    "Historia_uzycia": "Historia użycia",
    "Historia_uzycia_mobilnych_pilotow_przez_uzytkownikow": "Historia użycia mobilnych pilotów przez użytkowników",
    "Uzywajacy": "Używający",
    "Wystawiajacy": "Wystawiający",
    "Data_uzycia": "Data użycia",
    "Staly_dostep": "Stały dostęp",
    "Udostepnienie": "Udostępnienie",
    "Czy_chcesz_dolaczyc_do_grupy": "Czy chcesz dołączyć do grupy",
    "Dolacz": "Dołącz",
    "bilet": "bilet",
    "Twoja_platnosc_zostala_przyjeta_Szerokiej_drogi": "Twoja płatność została przyjęta. Szerokiej drogi!",
    "Oplac": "Opłać",
    "Oplac_parkowanie": "Opłać parkowanie",
    "Numer_rejestracyjny_18_cyfrowy_numer_biletu": "Numer rejestracyjny/18-cyfrowy numer biletu",
    "Wpisz_numer_rejestracyjny_swojego_pojazdu_badź_18_cyfrowy_numer_biletu_parkingowego_i_w_prosty_ureguluj_oplate_za_parking_przy_pomocy_platnosci_elektronicznej": "Wpisz numer rejestracyjny swojego pojazdu, bądź 18-cyfrowy numer biletu parkingowego i w prosty sposób ureguluj opłatę za parking przy pomocy płatności elektronicznej",
    "Brak_wolnych_miejsc_na_parkingu": "Brak wolnych miejsc na parkingu",
    "Dla_tego_parkingu_nie_zdefiniowano_zadnych_cennikow": "Dla tego parkingu nie zdefiniowano żadnych cenników",
    "Wybierz_cennik": "Wybierz cennik",
    "Kolejnosc_sortowania": "Kolejność sortowania",
    "Typ_silnika": "Typ silnika",
    "Spalinowy": "Spalinowy",
    "Hybrydowy": "Hybrydowy",
    "LPG": "LPG",
    "Elektryczny": "Elektryczny",
    "Punkt_dostepowy": "Punkt dostępowy",
    "Autem_o_tym_typie_silnika_nie_mozna_parkowac_na_tym_miejscu": "Autem o tym typie silnika nie można parkować na tym miejscu",
    "ZwyklyUzytkownik": "Zwykły użytkownik",
    "Zarzadca": "Zarządca",
    "Moderator": "Moderator",
    "Obsluga": "Obsługa",
    "Rola": "Rola",
    "Wyszukaj_uzytkownika_po_imieniu_nazwisku_emailu_lub_identyfikatorze": "Wyszukaj użytkownika po imieniu, nazwisku, emailu lub identyfikatorze",
    "Haslo_do_konta_nie_zostalo_ustawione_skorzystaj_z_instrukcji_jaka_wyslalismy_na_Twoj_email_po_utworzeniu_konta_lub_skontaktuj_sie_z_administratorem_parkingu_firmowego": "Hasło do konta nie zostało ustawione, skorzystaj z instrukcji jaką wysłaliśmy na Twój email po utworzeniu konta lub skontaktuj się z administratorem parkingu firmowego.",
    "Kod_ustawiajacy_haslo_nie_istnieje": "Kod ustawiający hasło nie istnieje",
    "Możliwosc_rezerwacji_zostala_zablokowana_przez_administratora_parkingu": "Możliwość rezerwacji została zablokowana przez administratora parkingu.",
    "Ustawienie_hasla_zabronione": "Ustawienie hasła zabronione",
    "Kod_ustawiajacy_haslo_zostal_juz_wykorzystany": "Kod ustawiający hasło został już wykorzystany",
    "Nieprawidlowy_kod_ustawiajacy_haslo": "Nieprawidłowy kod ustawiający hasło",
    "Edytuj_uzytkownika": "Edytuj użytkownika",
    "Haslo_ustawione": "Hasło ustawione",
    "Ustaw_haslo": "Ustaw hasło",
    "Ustawianie_hasla": "Ustawianie hasła",
    "Wyszukaj_zakup_po_numerze_rejestracyjnym": "Wyszukaj zakup po numerze rejestracyjnym lub numerze miejsca",
    "Obsluga_parkingu": "Obsługa parkingu",
    "Anulowany_abonament_nie_moze_zostac_przedluzony": "Anulowany abonament nie może zostać przedłużony",
    "Legenda": "Legenda",
    "Raporty": "Raporty",
    "Wykaz_wolnych_miejsc": "Wykaz wolnych miejsc",
    "Wyszukaj_miejsce_po_numerze": "Wyszukaj miejsce po numerze",
    "Czy_na_pewno_chcesz_usunac_abonament": "Czy na pewno chcesz usunąć abonament?",
    "Data_wplaty": "Data wpłaty",
    "Zarzadzaj_dostepnoscia": "Zarządzaj dostępnością",
    "Czy_napewno_chcesz_zmienic_linie_czasowa_miejsca": "Zmiana udostępnienia cyklicznego automatycznie odwoła wszystkie przyszłe rezerwacje użytkowników. Przed wykonaniem tej operacji dokonaj przeglądu rezerwacji i upewnij się, że na pewno chcesz to zrobić. Czy chesz kontynuować?",
    "Drukuj_historie": "Drukuj historię",
    "Niepoprawny_kod_blik": "Niepoprawny kod BLIK",
    "SZYBKA_PLATNOSC": "SZYBKA PŁATNOŚĆ",
    "Kod_blik": "Kod BLIK",
    "Wklej_kod": "Wklej kod",
    "Blad_platnosci_blik": "Błąd płatności BLIK",
    "Darmowa_rezerwacja": "Darmowa rezerwacja",
    "Dostep": "Dostęp",
    "LPR": "LPR",
    "Dostep_LPR": "Dostęp LPR",
    "Dodaj_Dostep": "Dodaj dostęp",
    "Wyszykaj_dostep_po_numerze_rejestracyjnym": "Wyszukaj dostęp po numerze rejestracyjnym",
    "Edytuj_dostep": "Edytuj dostęp",
    "Nieograniczony_dostep": "Nieograniczony dostęp",
    "Czy_na_pewno_chcesz_usunac_dostep": "Czy na pewno chcesz usunąć dostęp?",
    "Obszar": "Obszar",
    "Naruszenie_unikalnosci": "Naruszenie unikalności",
    "Jezeli_jestes_zainteresowany_dostepem_z_uzuciem_automatycznego_rozpoznawania_tablic_rejestracyjnych_skontaktuj_sie_z_naszym_dzialem_sprzedazy": "Jeśli jesteś zainteresowany dostępem z użyciem automatycznego rozpoznawania tablic rejestracyjnych skontaktuj się z naszym działem sprzedaży",
    "Pomoc": "Pomoc",
    "Kontakt": "Kontakt",
    "Przypisz_na_stale_do_uzytkownika": "Przypisz na stałe do użytkownika",
    "Zwolnij_do_puli_miejsc": "Zwolnij do puli miejsc",
    "Przypisz_miejsce": "Przypisz miejsce",
    "Email_lub_Identyfikator": "Email lub identyfikator",
    "Czy_na_pewno_chesz_usunac_stale_przypisanie_i_zwrocic_miejsce_do_puli_miejsc_dostepnych_dla_wszystkich_uzytkownikow": "Czy na pewno chcesz usunąć stałą przypisanie i zwolnić miejsce do puli miejsc dostępnych dla wszystkich użytkowników?",
    "Czy_napewno_chesz_przypisac_miejsce_na_stale": "Wykonanie tej akcji usunie wszystkie rezerwacje użytkowników zrobione na tym miejscu. Czy na pewno chcesz przypisać miejsce na stałe?",
    "Przypisz": "Przypisz",
    "Przypisanie": "Przypisanie",
    "Miejsce_rotacyjne": "Miejsce rotacyjne",
    "Czy_na_pewno_chcesz_usunac_uzytkownika": "Czy na pewno chcesz usunąć użytkownika?",
    "Jezyk": "Język",
    "Wpowadz_dane": "Wprowadź dane",
    "Dalej": "Dalej",
    "Odswiez": "Odśwież",
    "Wyniki_Finansowe": "Wyniki Finansowe",
    "Raport_Finansowy": "Raport Finansowy",
    "Przychod": "Przychód",
    "Liczba_Transakcji": "Liczba Transakcji",
    "Zwroty": "Zwroty",
    "Przychod_w_poszczegolne_dni": "Przychód w poszczególne dni",
    "Poprzedni_okres": "Poprzedni okres",
    "Rodzaj_parkowania": "Rodzaj parkowania",
    "Suma_transakcji": "Suma Transakcji",
    "Maksymalny_zakres_wynosi__366_dni": "Maksymalny zakres wynosi 366 dni",
    "Rezerwacja_zewnetrzna": "Rezerwacja zewnętrzna",
    "Wybierz_zakres_dat": "Wybierz zakres dat",
    "Zarejestrowani": "Zarejestrowani",
    "Aktywni": "Aktywni",
    "Nowi": "Nowi",
    "Top_10_najczesciej_rezerwujacych": "Top 10 najczęściej rezerwujących",
    "Top_10_pustych_rezerwacji": "Top 10 pustych rezerwacji",
    "Top_10_anulujacych_rezewacje": "Top 10 anulujących miejsca",
    "Nie_udostepnione": "Nie udostępnione",
    "p_zajetosci": "% zajętości",
    "Zarezerwowane": "Zarezerwowane",
    "Przypisane_na_stale": "Przypisane na stałe",
    "Wszystkie_rezerwacje": "Wszystkie rezerwacje",
    "Wykorzystane": "Wykorzystane",
    "Nie_wykorzystane": "Nie wykorzystane",
    "Typ_rezerwacji": "Typ rezerwacji",
    "Liczba_rezerwacji_w_podziale_na_dni_tygodnia": "Liczba rezerwacji w podziale na dni tygodnia",
    "Platne": "Płatne",
    "Bezplatne": "Bezpłatne",
    "Gosc": "Gość",
    "Stale": "Stałe",
    "Wykorzystanie_parkingu": "Wykorzystanie parkingu",
    "Statusy_rezerwacji": "Statusy rezerwacji",
    "Generuj_raport": "Generuj raport",
    "Terminal_platniczy": "Terminal płatniczy",
    "Parkowanie_rotacyjne": "Parkowanie rotacyjne",
    "Miejsc": "Miejsc",
    "MinimalBookingsDashboard": "MinimalBookingsDashboard",
    "Aktywni_w_danym_okresie": "Aktywni w danym okresie",
    "Wszyscy_zarejestrowani": "Wszyscy zarejestrowani",
    "Zarejestrowani_w_danym_okresie": "Zarejestrowani w danym okresie",
    "Top_10_niewykorzystanych_rezerwacji": "Top 10 niewykorzystanych rezerwacji",
    "Top_10_anulujacych_rezerwacje": "Top 10 anulujących rezerwacje",
    "Ilosc_zwrotow": "Ilość zwrotów",
    "Ilosc_transakcji": "Ilość transakcji",
    "Raporty_Generowane": "Raporty generowane",
    "Raport_pomyslnie_wygenerowany_i_wyslany_na_adres_email": "Raport zotał pomyślnie wygenerowany i wysłany na adres email powiązany z Twoim kontem",
    "To_pole_jest_wymagane": "To pole jest wymagane",
    "Wybierz_przynajmniej_jedna_opcje": "Wybierz przynajmniej jedną opcję",
    "Wybierz_parkingi": "Wybierz parkingi",
    "Wystapil_nieznany_blad_podczas_generacji_raportu": "Wystąpił nieznany błąd podczas generacji raportu",
    "Dane_finansowe": "Dane finansowe",
    "Aktywnosc_uzytkownikow": "Aktywność użytkowników",
    "Wygeneruj_i_wyslij": "Wygeneruj i wyślij",
    "Wlasny_zakres_dat": "Własny zakres dat",
    "Obecny_miesiac": "Obecny miesiąc",
    "Poprzedni_miesiac": "Poprzedni miesiąc",
    "Obecny_rok": "Obecny rok",
    "Poprzedni_rok": "Poprzedni rok",
    "Data_anulowania": "Data anulowania",
    "Liczba_wolnych_miejsc": "Wolne miejsca",
    "Liczba_zarezerwowanych_miejsc": "Zarezerwowane miejsca",
    "Zajetosc_miejsc": "Zajętość miejsc",
    "Wyszukaj_Po_Numerze_miejsca_nazwisku_emailu": "Wyszukaj po numerze miejsca, nazwisku lub emailu wlaściciela miejsca",
    "Rower": "Rower",
    "Hulajnoga": "Hulajnoga",
    "Ladowarka_dla_pojazdow_elektrycznych": "Ładowarka elektryczna",
    "Miejsce_dla_niepelnosprawnych": "Miejsce dla niepełnosprawnych",
    "Miejsce_dla_obslugi": "Miejsce obsługi techniczne",
    "Miejsce_dla_gosci": "Miejsce dla gościa",
    "Rezerwacja_tylko_przed_administratora": "Rezerwacja tylko przez administratora",
    "Cechy_miejsca": "Cechy miejsca",
    "Miejsce_monitorowane": "Miejsce monitorowane",
    "Miejsce_strzezone": "Miejsce strzeżone",
    "Garaz_w_budynku": "Garaż w budynku",
    "Cena_wyliczana_na_podstawie_cennika_parkingu": "Cena wyliczana na podstawie cennika parkingu",
    "Liczba_nieaktywnych_miejsc": "Nieaktywne miejsca",
    "Laczna_liczba_zarzadzanych_miejsc": "Wszystkie zarządzane miejsca",
    "Od": "Od",
    "Do": "Do",
    "Rezerwacje_bezplatne": "Rezerwacje bezpłatne",
    "Rezerwacje_platne": "Rezerwacje płatne",
    "Cechy_miejsca_parkingowego": "Cechy miejsca parkingowego",
    "Miejsce_tylko_dla_malych_pojazdow_elektrycznych": "Miejsce tylko dla małych pojazdów elektrycznych",
    "Zabronione_rodzaje_silnikow": "Zabronione typy silników",
    "Pozostale": "Pozostałe",
    "Publiczny": "Publiczny",
    "Prywatny": "Prywatny",
    "Komercyjny": "Komercyjny",
    "Biuro": "Biurowy",
    "BiuroEV": "BiurowyEV",
    "Rodzaj_parkingu": "Rodzaj parkingu",
    "Wystapil_blad_podczas_zapisu_danych": "Wystąpił błąd podczas zapisu danych",
    "Importuj_z_csv": "Importuj z CSV",
    "Usun_Wszystkie": "Usuń wszystkie",
    "Nazwa_pliku": "Nazwa pliku",
    "Udostepnij_plik": "Udostępnij plik",
    "Upusc_lub_wybierz_plik": "Upuść lub wybierz plik",
    "Upusc_pliki_tutaj_lub_kliknij": "Upuść pliki tutaj lub kliknij",
    "Przegladaj": "Przeglądaj",
    "Aby_wybrac_plik_z_dysku": "Aby wybrac plik z dysku",
    "Dziedzicz": "Dziedzicz",
    "Domyslny_caly_dzien": "Domyślnie cały dzień",
    "Domyslny_zakres": "Domyślny zakres",
    "Tryb_tworzenia_polityki": "Tryb tworzenia polityki",
    "Reczny": "Ręczny",
    "Automatyczny": "Automatyczny",
    "Polityka_parkingu": "Polityka parkingu",
    "Wymagane_jest_podanie_id_profilu_zewnetrznego": "Wymagane jest podanie id profilu zewnętrznego",
    "Domyslny": "Domyślny",
    "Pozwol_na_rownoczesne_rezerwacje": "Pozwól na równoczesne rezerwacje",
    "Zastosuj_dla_menadzerow": "Zastosuj dla menadzerow",
    "Pozwol_na_pojazdy_elektryczne_w_parkingu_podziemnym": "Pozwól na pojazdy elektryczne w parkingu podziemnym",
    "Pozwol_na_pojazdy_LPG_w_parkingu_podziemnym": "Pozwól na pojazdy LPG w parkingu podziemnym",
    "Calodobowa": "Całodobowa",
    "Wybrany_zakres": "Wybrany zakres",
    "Polityka_rezerwacji": "Polityka rezerwacji",
    "Sposob_wyboru_miejsca": "Sposób wyboru miejsca",
    "Minimalna_dlugosc_rezerwacji": "Minimalna długość rezerwacji",
    "Maksymalna_dlugosc_rezerwacji": "Maksymalna długość rezerwacji",
    "Maksymalna_dlugosc_rezerwacji_od_teraz": "Maksymalna długość rezerwacji od teraz",
    "Maksymalny_Okres_wyprzedzenia_pozwalajacy_na_rezerwacje": "Maksymalny okres wyprzedzenia rezerwacji",
    "Minuty": "Minuty",
    "Dni": "Dni",
    "Godziny": "Godziny",
    "Brak_polityki": "Brak polityki",
    "Akcja_zostala_pomyslnie_ukonczona": "Akcja została pomyślnie ukończona",
    "Wystapil_blad": "Wystąpił błąd",
    "Import_danych_z_pliku_zostal_ukonczony_pomyslnie": "Import danych z pliku został ukończony pomyślnie",
    "Wystapil_blad_podczas_importu_danych_z_pliku": "Wystąpił błąd podczas importu danych z pliku",
    "Importuj_dane_z_pliku_CSV": "Importuj dane z pliku .csv",
    "Importuj": "Importuj",
    "Dane_zostaly_pomyslnie_zapisane": "Dane zostały pomyślnie zapisane!",
    "Wyszukaj_po_nazwie_parkingu": "Wyszukaj po nazwie parkingu",
    "Dane_zostaly_pomyslnie_zaktualizowane": "Dane zostały pomyślnie zaktualizowane!",
    "Wystapil_blad_podczas_aktualizacji_danych": "Wystąpił błąd podczas aktualizacji danych!",
    "Najpozniejszy_czas_anulowania": "Najpóźniejszy czas anulowania rezerwacji",
    "Regulamin_ogolny": "URL regulamin ogólnego parkingu",
    "Regulamin_prywatnosci": "Url Regulaminu prywatności",
    "Url_mapy_parkingu": "Url mapy parkingu",
    "Instrukcja_aplikacji": "Url instrukcji aplikacji",
    "Url_loga": "Url loga",
    "Limit_aktywnych_rezerwacji": "Limit aktywnych rezerwacji",
    "Predefiniowane_przedzialy_czasowe": "Predefiniowane przedziały czasowe",
    "Wartosc": "Wartość",
    "Rodzaj": "Rodzaj",
    "Dodaj_parking": "Dodaj parking",
    "Edytuj_parking": "Edytuj parking",
    "Url_regulaminu_sprzedarzy": "Url regulaminu sprzedaży",
    "Dodaj_polityke_rezerwacji": "Dodaj politykę rezerwacji",
    "Edytuj_polityke_rezerwacji": "Edytuj politykę rezerwacji",
    "Parking_nie_posiada_polityki_rezerwacji": "Parking nie posiada polityki rezerwacji, edytuj i zapisz formularz aby dodać politykę, lub zamknij formularz aby anulować",
    "Ulica_jest_wymagana": "Ulica_jest_wymagana",
    "Miasto_jest_wymagane": "Podanie miasta jest wymagane",
    "Podanie_nr_budynku_jest_wymagane": "Podanie nr budynku jest wymagane",
    "Sekundy": "Sekundy",
    "Autoryzowane_domeny": "Autoryzowane domeny",
    "Edytuj_Autoryzowane_domeny": "Edytuj Autoryzowane domeny",
    "Nazwa_domeny": "Nazwa domeny",
    "Przypisane_nr_rejestracyjne": "Przypisane nr rej.",
    "Przypisz_numery_rejestracyjne": "Przypisz numery rejestracyjne",
    "Przypisane_tablice": "Przypisane tablice",
    "Numery_rejestracyjne": "Numery rejestracyjne",
    "Pokaz_kod_qr": "Pokaż kod QR",
    "Dashboardy": "Dashboardy",
    "Dashboard_glowny": "Dashboard główny",
    "Statystyki_dzienne": "Statystyki dzienne",
    "Siedem_dni": "7 dni",
    "Trzydziesci_dni": "30 dni",
    "Dwanascie_miesiecy": "12 miesięcy",
    "Statystyki_rezerwacji": "Statystyki rezerwacji",
    "Parkuje": "Parkuje",
    "Nie_parkuje": "Nie parkuje",
    "Status_nieznany": "Status nieznany",
    "Szlaban_Brama": "Szlaban/Brama",
    "Dane_uzytkownika": "Dane użytkownika",
    "Serwer": "Serwer",
    "Uzytkownik": "Użytkownik",
    "Historia_uzycia_LPR_QR_przez_uzytkownikow": "Historia użycia LPR/QR przez użytkowników",
    "Zarzadzanie_dostepem_LPR": "Zarządzanie dostępem LPR",
    "Uzytkownicy_ktorzy_posiadaja_dostep_LPR": "Użytkownicy, którzy posiadają dostęp LPR",
    "Statystyki_parkingu": "Statystyki parkingu",
    "Typ_czytnika": "Typ czytnika",
    "Data_zdarzenia": "Data zdarzenia",
    "Czytnik": "Czytnik",
    "Wyszukaj_po_numerze_rejestracyjnym": "Wyszukaj po numerze rejestracyjnym",
    "Parking_OS": "Parking OS",
    "Wyszukaj_po_imieniu_nazwisku_uzytkonika": "Wyszukaj po imieniu, nazwisku użytkownika",
    "Niedostepne": "Niedostępne",
    "Caly_parking": "Cały parking"
  }
});
export default strings;