import {rootUrl} from "./config-wrapper";
import ReactDOM from 'react-dom/client';
import {currentServerCulture} from "./culture-helper";
import {RootUrlProvider, ServiceBase} from "parkcash-api";
import L from "leaflet";
RootUrlProvider.urlProviderCallback = () =>  rootUrl();
ServiceBase.cultureProviderCallback = () => currentServerCulture();


import * as React from "react";
import {render} from "react-dom";
import 'whatwg-fetch';
import App from "./App";
import {HelmetProvider} from "react-helmet-async";
import {BrowserRouter} from "react-router-dom";
import {StrictMode, Suspense} from "react";

const markerIcon2x = require("leaflet/dist/images/marker-icon-2x.png");
const markerIcon = require("leaflet/dist/images/marker-icon.png");
const markerShadow = require("leaflet/dist/images/marker-shadow.png");

delete L.Icon.Default.prototype['_getIconUrl'];
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <HelmetProvider>
      <BrowserRouter>
       {/* <Suspense>*/}
         {/* <StrictMode>*/}
              <App/>
          {/*</StrictMode>*/}
   
        {/*</Suspense>*/}
    </BrowserRouter>
      </HelmetProvider>,
    
);