import * as React from "react";
import {createPortal} from "react-dom";
import Colors from "../styles/Colors";
import Spinner from "./Spinner";

const globalProgress = document.getElementById("globalprogress");

export default (props: {visible: boolean}) => {
    if(!props.visible){
        return null;
    }

    return createPortal((
        <div style={{
            position: 'fixed',
            top: 0,
            bottom: 0, 
            left: 0, 
            right: 0, 
            zIndex: 1000000,
            backgroundColor: Colors.darkOverlay, 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center'
        }}>
            <div>
                <Spinner size="extra-large" />
            </div>
        </div>
    ), globalProgress)
}