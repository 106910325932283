import * as React from "react";
import Triangle from "../../components/Triangle";
import Colors from "../../styles/Colors";
import { MOBILE_WIDTH_THRESHOLD } from "../../utils/Constants";
import { PCClasses } from "../../utils/CSSUtils";
import { useWindowSize } from "../../utils/WindowSizeHook";
import {DesktopHeader,DESKTOPHEADER_SCROLLED_HEIGHT,DESKTOPHEADER_TOP_HEIGHT,MobileHeader, MOBILEHEADER_HEIGHT} from "./Header";
import "../Common/Styles.scss";

export default (props: {
    children: React.ReactNode;
}) => {
    const {windowWidth, windowHeight} = useWindowSize();
    const [scrolled, setScrolled] = React.useState(false);
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;

    React.useEffect(() => {
        setScrolled(window.scrollY > 0);
        

        const windowListener = () => {
            setScrolled(window.scrollY > 0);
        }

        window.addEventListener("scroll", windowListener);

        return () => {
            window.removeEventListener("scroll", windowListener);
        }
    }, []);

    const headerHeight = isMobile ? MOBILEHEADER_HEIGHT : scrolled ? DESKTOPHEADER_SCROLLED_HEIGHT : DESKTOPHEADER_TOP_HEIGHT;
    const paddingTop = isMobile ? 0 : headerHeight;
    return (
        <div
            className={PCClasses("pc-main-wrapper")}
        >   
            <div 
                className={PCClasses("pc-main-container")} 
                style={{paddingTop}}
            >
                {isMobile && <MobileHeader />}
                {!isMobile && <DesktopHeader scrolled={scrolled} />}
                
                <div style={{minHeight: windowHeight - headerHeight, backgroundColor: Colors.pale_grey_two, overflow: "auto"}}>
                    {props.children}
                </div>      
            </div>
        </div>

        
    )
}