import * as React from "react";
import Resources from "../../Resources";
import { PASSWORD_REGEX } from "../utils/Constants";
import * as yup from "yup";
import ModalForm from "../components/ModalForm";
import { FormikHelpers } from "formik";
import { ChangePasswordDto, ErrorCode, ServiceConfig, UsersService } from "parkcash-api";
import { useJWT } from "../utils/JWTUtils";
import Notify from "../utils/Notify";
import RegularInput from "../components/forms/RegularInput";
import { getErrorMessage } from "../utils/ErrorUtils";
import {Asserts} from "yup";

const password = require("../../assets/images/password.svg");

const validationSchema = yup.object({
    oldPassword: yup.string().required(Resources.Wymagane),
    newPassword: yup.string().required(Resources.Wymagane).matches(PASSWORD_REGEX, Resources.Haslo_musi_zawierac_co_najmniej_7_znakow)
});

interface FormState extends Asserts<typeof validationSchema> {}

export default (props: {
    visible: boolean,
    onClose: () => void;
}) => {
    const {onClose, visible} = props;

   
    const initialValues: FormState = {
        newPassword: "",
        oldPassword: ""
    }
    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {
        const {oldPassword, newPassword} = state;
        const {setSubmitting, setStatus} = helpers;
        const jwt = useJWT();
        try{
            setSubmitting(true);
            const response = await new UsersService(new ServiceConfig({jwt})).changePassword(new ChangePasswordDto({
                newPassword,
                oldPassword
            }));
            if (response.isSuccess) {
                Notify.Success(Resources.Haslo_zostalo_zmienione);
                onClose();
            } 
            else {
                if(response.error.code === ErrorCode.InvalidCredentials){
                    setStatus(Resources.Aktualne_haslo_nie_jest_poprawne);
                }
                else{
                    setStatus(getErrorMessage(response.error.code));
                }         
            }
        }
        catch{
            setStatus(getErrorMessage(ErrorCode.NetworkError));
        }
    }

    return (
        <ModalForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            visible={visible}
            onClose={onClose}
            iconHeight={110}
            iconWidth={110}
            icon={password}
            title={Resources.Zmiana_hasla}
            showCancelButton
        >
            {args => {
                const {values, errors, touched, setFieldTouched, setFieldValue} = args;
                return (
                    <>
                        <RegularInput
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            value={values.oldPassword}
                            error={errors.oldPassword}
                            touched={touched.oldPassword}
                            name="oldPassword"
                            label={Resources.Stare_haslo} 
                            password
                            showValidatedButton
                        />
                        <RegularInput 
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue} 
                            value={values.newPassword}
                            error={errors.newPassword}
                            touched={touched.newPassword}
                            name="newPassword"
                            label={Resources.Nowe_haslo} 
                            password
                            showValidatedButton
                        />
                    </>
                );
            }}
        </ModalForm>
    );
}