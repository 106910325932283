export default class LocalStorageManager {
    public static GetUserToken() {
        return localStorage.getItem("token");
    }

    public static SetUserToken(token: string) {
        localStorage.setItem("token", token);
    }

    public static ClearUserToken() {
        localStorage.removeItem("token");
    }

    public static GetCurrentParkingId() {
        return localStorage.getItem("parkingId");
    }

    public static SetCurrentParkingId(id: string) {
        localStorage.setItem("parkingId", id);
    }

    public static ClearCurrentParking() {
        localStorage.removeItem("parkingId");
    }

    public static ClearAll() {
        this.ClearUserToken();
        this.ClearCurrentParking();
    }

    static SetCurrentLang(newLang: string) {
        localStorage.setItem("language", newLang);
    }

    static GetCurrentLang() {
        return localStorage.getItem("language");
    }
}