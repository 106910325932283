import * as React from "react";
import Resources from "../../Resources";
import Body from "../layouts/Main/Body";
import { BaseSpaceSeparator } from "../styles/Separators";
import VirtualPilotsList from "./VirtualPilotsList";
import "./Styles.scss";
import SharingGrid from "./SharingGrid";
import * as uuid from "uuid";

export default () => {
    const [sharingGridKey, setSharingGridKey] = React.useState(uuid.v1());


    return (
        <Body title={Resources.Piloty}>
            <VirtualPilotsList 
                onPilotShared={() => setSharingGridKey(uuid.v1())}
            />
            <BaseSpaceSeparator size={30} />
            <SharingGrid key={sharingGridKey} />
        </Body>
    );
}