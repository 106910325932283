import {PolicyCreationMode} from "parkcash-api";
import Resources from "../../../../Resources";

export const mapPolicyCreationModeToLocalizedDisplayText=(policyCreationMode: PolicyCreationMode): string=> {
    switch (policyCreationMode) {
        case PolicyCreationMode.Inherit:
            return Resources.Dziedzicz
        case PolicyCreationMode.DefaultWholeDay:
            return Resources.Domyslny_caly_dzien
        case PolicyCreationMode.DafaultRange:
            return Resources.Domyslny_zakres
        case PolicyCreationMode.None:
            return Resources.Brak_polityki
            
        default:
            return Resources.Nieznany
    }
  }
    