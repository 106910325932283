import * as React from "react";
import {applicationState} from "../../redux/ApplicationState";
import {PCClasses} from "../../utils/CSSUtils";
import {connect} from "react-redux";
import {PCText} from "../../styles/Texts";
import {BaseSpaceSeparator} from "../../styles/Separators";
import Colors from "../../styles/Colors";
import Resources from "../../../Resources";
import ListContainer from "./ListContainer";
import {DeactivateCreditCardDto, ErrorCode, PaymentsService, ServiceConfig,} from "parkcash-api";
import PaymentForm from "../../payments/PaymentForm";
import {getPaymentForms, removePaymentForm} from "../../payments/Redux";
import Spinner from "../../components/Spinner";
import PCScrollbars from "../../components/PCScrollbars";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCcMastercard, faCcVisa} from "@fortawesome/free-brands-svg-icons";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import ConfirmModal from "../../components/ConfirmModal";
import Notify from "../../utils/Notify";
import IconButton from "../../components/IconButton";
import ListGradient from "../../components/ListGradient";
import {TransactionType} from "../../payments/Enums";
import {getErrorMessage} from "../../utils/ErrorUtils";

const bluemedia_logo = require("../../../assets/images/bm.svg");

interface Props {
  progress: boolean;
  error: ErrorCode;
  items: PaymentForm[];
  name: string;
  surname: string;
  jwt: string;
  onInit: (refresh: boolean) => void;
  onRemoved: (form: PaymentForm) => void;
}

const PaymentListItem = (props: {
  item: PaymentForm;
  onRemove: (i: PaymentForm) => void;
  name: string;
  surname: string;
}) => {
  const {
    item: { Type, CreditCardInfo },
    name,
    surname,
    onRemove,
  } = props;
  const isBluemedia = Type === TransactionType.BlumediaGate;
  const isVisa = !isBluemedia && CreditCardInfo.issuer.toLowerCase() === "visa";

  const onRemoveClicked = () => {
    props.onRemove(props.item);
  };

  return (
    <div
      style={{
        position: "relative",
        borderRadius: 12,
        width: "100%",
        height: 160,
        boxShadow: "0 3px 26px 4px rgba(0, 0, 0, 0.08)",
        backgroundColor: isBluemedia
          ? Colors.very_light_pink
          : isVisa
          ? Colors.light_royal_blue
          : Colors.heliotrope,
      }}
    >
      {!isBluemedia && (
        <IconButton
          style={{ position: "absolute", top: 25, left: 20, zIndex: 2 }}
          icon={faTrashAlt}
          onClick={onRemoveClicked}
        />
      )}
      <div style={{ position: "absolute", top: 25, right: 20, zIndex: 2 }}>
        {isBluemedia && <img src={bluemedia_logo} width={45} height={35} />}
        {!isBluemedia && (
          <FontAwesomeIcon
            icon={isVisa ? faCcVisa : faCcMastercard}
            color={Colors.white}
            style={{ fontSize: 40 }}
          />
        )}
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 17,
          paddingLeft: 20,
          paddingRight: 20,
          left: 0,
          right: 0,
        }}
      >
        {isBluemedia && (
          <PCText letterSpacing={0} fontSize={16} color={Colors.white}>
            {Resources.Szybki_przelew}
          </PCText>
        )}
        {!isBluemedia && (
          <>
            <PCText letterSpacing={0} fontSize={17} color={Colors.white}>
              &middot;&middot;&middot;&middot; &middot;&middot;&middot;&middot;
              &middot;&middot;&middot;&middot; {CreditCardInfo.lastFourDigits}
            </PCText>
            <BaseSpaceSeparator size={12} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PCText letterSpacing={0} fontSize={16} color={Colors.white}>
                {(name || "").toUpperCase()} {(surname || "").toUpperCase()}
              </PCText>
              <PCText letterSpacing={0} fontSize={16} color={Colors.white}>
                {CreditCardInfo.validityDateMonth}/
                {CreditCardInfo.validityDateYear}
              </PCText>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const Container = (props: Props) => {
  const { error, items, progress, onInit, name, surname, jwt, onRemoved } =
    props;

  const onAdd = () => {};

  const onRemove = async (i: PaymentForm) => {
    const confirm = await ConfirmModal.show({
      confirmText: Resources.Usun,
      text: Resources.Czy_na_pewno_chcesz_usunac_metode_platnosci,
    });
    if (confirm) {
      const response = await new PaymentsService(
        new ServiceConfig({ jwt })
      ).deactivateCreditCard(
        new DeactivateCreditCardDto({
          creditCardId: i.CreditCardInfo.id,
        })
      );
      if (response.isSuccess) {
        onRemoved(i);
      } else {
        Notify.Error(Resources.Nastapil_blad);
      }
    }
  };

  React.useEffect(() => {
    onInit(false);
  }, []);

  return (
    <div
      className={PCClasses("pc-profilepayments-container")}
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        zIndex: 1,
      }}
    >
      <ListContainer title={Resources.Metody_platnosci}>
        {progress && (
          <div
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Spinner size="extra-small" />
          </div>
        )}
        {!progress && typeof error === "number" && (
          <div
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <PCText
              fontSize={14}
              textAlign="center"
              color={Colors.red}
              semibold
            >
              {getErrorMessage(error)}
            </PCText>
          </div>
        )}
        {!progress && !error && !items.length && (
          <div
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <PCText
              fontSize={14}
              textAlign="center"
              color={Colors.black}
              semibold
            >
              {Resources.Brak_danych}
            </PCText>
          </div>
        )}
        {!progress && !error && !!items.length && (
          <PCScrollbars
            height={520}
            contentContainerStyle={{
              paddingRight: 50,
              paddingLeft: 50,
              paddingTop: 35,
              paddingBottom: 35,
            }}
          >
            {items.map((item, index, arr) => {
              return (
                <React.Fragment key={index}>
                  <PaymentListItem
                    item={item}
                    onRemove={() => onRemove(item)}
                    name={name}
                    surname={surname}
                  />
                  {arr.length > 1 && index < arr.length - 1 && (
                    <BaseSpaceSeparator size={30} />
                  )}
                </React.Fragment>
              );
            })}
          </PCScrollbars>
        )}
      </ListContainer>
      <ListGradient />
    </div>
  );
};

const mapStateToProps = (state: applicationState): Partial<Props> => ({
  progress: state.payments.gettingPaymnentFormsProgress,
  error: state.payments.gettingPaymentFormsError,
  items: state.payments.paymentForms || [],
  name: state.user.firstName,
  surname: state.user.lastName,
  jwt: state.user.token,
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
  onInit: (refresh) => dispatch(getPaymentForms(refresh)),
  onRemoved: (form) => dispatch(removePaymentForm(form)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
