import * as React from "react";
import Colors from "../styles/Colors";
import Spinner from "./Spinner";

export default () => {
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: Colors.white
            }}
        >
            <Spinner size="extra-large" />
        </div>
    )
}