import {
    ErrorCode,
    GetParkingMembersResultEntryDto,
    IManagerParkingSpotInfoDto,
    IParkingSpotInfoDto,
    ManagerGetParkingSpotInfoResult,
    ParkingManagerService,
    ParkingSpotAvailibilityType,
    ParkingSpotInfoDto,
    ParkingSpotSizeType,
    ParkingSpotsService,
    ParkingSpotStatus,
    ParkingSpotType,
    ServiceConfig
} from "parkcash-api";
import * as React from "react";
import {ReactElement} from "react";
import Resources from "../../Resources";
import BooleanPresenter from "../components/BooleanPresenter";
import ModalForm from "../components/ModalForm";
import ParkingSpotNumberPresenter from "../components/ParkingSpotNumberPresenter";
import Spinner from "../components/Spinner";
import StatusPresenter from "../components/StatusPresenter";
import Colors from "../styles/Colors";
import {BaseSpaceSeparator} from "../styles/Separators";
import {PCText} from "../styles/Texts";
import {getErrorMessage} from "../utils/ErrorUtils";
import {useJWT} from "../utils/JWTUtils";
import {IGetParkingParkingSpotsViewResultEntryDto} from "parkcash-api";

const homeWithDrive = require("../../assets/images/homeWithDrive.svg");

type ForbiddenEngineType = 'Other' | 'Electric' | 'Hybrid' | 'LPG';
export const getForbiddenEngineLocalizedName = (forbiddenEngineType: ForbiddenEngineType): string => {
    switch (forbiddenEngineType) {
        case 'Electric':
            return Resources.Elektryczny;
        case 'Hybrid':
            return Resources.Hybrydowy;
        case 'LPG':
            return Resources.LPG;
        default:
            return Resources.Pozostale;
    }
}

const InfoPresenter = (props: {
    children: React.ReactNode, title: string
}) => {
    const {
        children,
        title
    } = props;

    return (<div style={{marginBottom: 30}}>
        <PCText fontSize={14} semibold color={Colors.black}>{title}</PCText>
        <BaseSpaceSeparator size={10}/>
        {typeof children !== "string" ? children : (<PCText fontSize={16} color={Colors.brownish_grey}>
            {children}
        </PCText>)}
    </div>)
}

const ParkingSpotOwner = (props: {
    jwt: string; ownerId: string; parkingId: string;
}) => {
    const {
        jwt,
        ownerId,
        parkingId
    } = props;
    const [owner, setOwner] = React.useState<GetParkingMembersResultEntryDto>(null);

    React.useEffect(() => {
        const init = async () => {
            //TODO JS
            /*  try{
                  const {isSuccess, result} = await new ParkingManagerService(new ServiceConfig({jwt})).getParkingMembers(parkingId, "", "", 1, 10000);
                  if(isSuccess){
                      setOwner(Enumerable.from(result.entries).firstOrDefault(e => e.userId === ownerId));
                  }
              }
              catch{
  
              }*/
        }

        init();
    })

    if (!owner) {
        return null;
    }

    return (<InfoPresenter title={Resources.Wlasciciel_miejsca}>
        <PCText fontSize={16} color={Colors.brownish_grey}>
            {owner.firstName} {owner.lastName}
            <br/>
            {owner.email}
        </PCText>
    </InfoPresenter>);
}

const ParkingSpotInfo = (props: {
    jwt: string, mode: "admin" | "user", info: IManagerParkingSpotInfoDto
    basicInfo?: IGetParkingParkingSpotsViewResultEntryDto;
}) => {
    const {
        jwt,
        mode,
        info: {
            additionalInfo,
            addressDto: {
                city,
                streetName,
                streetNumber
            },
            level,
            shortTermRentingPrice,
            spotNumber,
            availibilityType,
            directionHints,
            isLongTermRentingEnabled,
            enableFreeBookingWithinGroup,
            ownerId,
            isShortTermRentingEnabled,
            longTermRentingPrice,
            parkingId,
            sizeType,
            status,
            type,
            sector,
            forbiddenEngineTypesList,
            isMiniEvOnly,
            onlyAdminCanMakeReservations,
            electricCharger,
            isForHandicapped,
            isMonitored,
            isGuarded,
            isForMaintanceStaff,
            isForGuest

        }

    } = props;

    const preferTariffPrice = props?.basicInfo?.preferTariffPrice;
    const getForbidEngineTypesDisplayList = (forbidenEngineList: string | undefined | null): ReactElement => {
        let displayValue = "";
        if (forbidenEngineList) {
            displayValue = forbidenEngineList?.split("~")
                                             .map(name => getForbiddenEngineLocalizedName(name as ForbiddenEngineType))
                                             .join(", ");

        }
        return <PCText bold color={displayValue ? Colors.red : Colors.light_royal_blue}
                       fontSize={16}>{displayValue || Resources.brak.toUpperCase()}</PCText>

    }
    return (<div>
        {mode === "admin" && <ParkingSpotOwner jwt={jwt} ownerId={ownerId} parkingId={parkingId}/>}
        <InfoPresenter title={Resources.Adres}>
            <PCText color={Colors.brownish_grey} fontSize={16}>
                {city}
                <br/>
                {streetName} {streetNumber}
            </PCText>
        </InfoPresenter>
        <InfoPresenter title={Resources.Numer}>
            <ParkingSpotNumberPresenter level={level} spotNumber={spotNumber} sector={sector}/>
        </InfoPresenter>
        <InfoPresenter title={Resources.Status}>
            <StatusPresenter
                color={({
                    [ParkingSpotStatus.Active]: Colors.green,
                    [ParkingSpotStatus.Deactivated]: Colors.red,
                    [ParkingSpotStatus.None]: Colors.very_light_pink,
                    [ParkingSpotStatus.Created]: Colors.very_light_pink
                }[status])}
                text={{
                    [ParkingSpotStatus.Active]: Resources.Aktywne,
                    [ParkingSpotStatus.Deactivated]: Resources.Dezaktywowane,
                    [ParkingSpotStatus.None]: Resources.Nieznany,
                    [ParkingSpotStatus.Created]: Resources.Stworzone
                }[status]}
            />
        </InfoPresenter>
        <InfoPresenter title={Resources.Typ_miejsca}>
            <StatusPresenter
                color={Colors.light_royal_blue}
                text={{
                    [ParkingSpotType.Garage]: Resources.Garaz,
                    [ParkingSpotType.Outside]: Resources.Na_zewnatrz
                }[type]}
            />
        </InfoPresenter>
        <InfoPresenter title={Resources.Rodzaj_pojazdu}>
            <StatusPresenter
                color={Colors.light_royal_blue}
                text={{
                    [ParkingSpotSizeType.Car]: Resources.Samochod,
                    [ParkingSpotSizeType.Motorcycle]: Resources.Motocykl
                }[sizeType]}
            />
        </InfoPresenter>
        <InfoPresenter title={Resources.Zabronione_rodzaje_silnikow}>
            {getForbidEngineTypesDisplayList(forbiddenEngineTypesList)}
        </InfoPresenter>

        <InfoPresenter title={Resources.Dostepnosc_miejsca}>
            <StatusPresenter
                color={Colors.light_royal_blue}
                text={{
                    [ParkingSpotAvailibilityType.Limited]: Resources.Ograniczona,
                    [ParkingSpotAvailibilityType.Public]: Resources.Publiczna
                }[availibilityType]}
            />
        </InfoPresenter>
        <InfoPresenter title={Resources.Rezerwacje_bezplatne}>
            <BooleanPresenter value={enableFreeBookingWithinGroup}/>
        </InfoPresenter>
        <InfoPresenter title={Resources.Cena_wyliczana_na_podstawie_cennika_parkingu}>
            <BooleanPresenter value={preferTariffPrice}/>
        </InfoPresenter>
        <InfoPresenter title={Resources.Rezerwacje_platne}>
            <StatusPresenter
                color={Colors.light_royal_blue}
                text={!isShortTermRentingEnabled ? Resources.brak : !shortTermRentingPrice ? Resources.bezplatny : `${shortTermRentingPrice} PLN/godz.`}
            />

        </InfoPresenter>
        <InfoPresenter title={Resources.Wynajem_dlugoterminowy}>
            <StatusPresenter
                color={Colors.light_royal_blue}
                text={!isLongTermRentingEnabled ? Resources.brak : !longTermRentingPrice ? Resources.bezplatny : `${longTermRentingPrice} PLN/mies.`}
            />
        </InfoPresenter>
        <InfoPresenter title={Resources.Miejsce_tylko_dla_malych_pojazdow_elektrycznych}>
            <BooleanPresenter value={isMiniEvOnly}/>
        </InfoPresenter>

        <InfoPresenter title={Resources.Rezerwacja_tylko_przed_administratora}>
            <BooleanPresenter value={onlyAdminCanMakeReservations}/>
        </InfoPresenter>
        <InfoPresenter title={Resources.Ladowarka_dla_pojazdow_elektrycznych}>
            <BooleanPresenter value={electricCharger}/>
        </InfoPresenter>
        <InfoPresenter title={Resources.Miejsce_dla_niepelnosprawnych}>
            <BooleanPresenter value={isForHandicapped}/>
        </InfoPresenter>
        <InfoPresenter title={Resources.Miejsce_monitorowane}>
            <BooleanPresenter value={isMonitored}/>
        </InfoPresenter>
        <InfoPresenter title={Resources.Miejsce_strzezone}>
            <BooleanPresenter value={isGuarded}/>
        </InfoPresenter>
        <InfoPresenter title={Resources.Miejsce_dla_obslugi}>
            <BooleanPresenter value={isForMaintanceStaff}/>
        </InfoPresenter>
        <InfoPresenter title={Resources.Miejsce_dla_gosci}>
            <BooleanPresenter value={isForGuest}/>
        </InfoPresenter>
    </div>);
}

export const ParkingSpotDetailsModalContent = (props: {
    spotId: string, mode: "admin" | "user", spotBasicInfo?: IGetParkingParkingSpotsViewResultEntryDto
}) => {
    const {
        spotId,
        mode
    } = props;
    const jwt = useJWT();
    const [error, setError] = React.useState<ErrorCode>(null);
    const [progress, setProgress] = React.useState(true);
    const [info, setInfo] = React.useState<IParkingSpotInfoDto>(null);

    React.useEffect(() => {
        const init = async () => {
            if (spotId) {
                try {
                    setProgress(true);
                    setError(null);
                    setInfo(null);
                    const {
                        isSuccess,
                        result,
                        error
                    } = mode === "admin" ? await new ParkingManagerService(new ServiceConfig({jwt})).getParkingSpotInfo(
                        spotId) : await new ParkingSpotsService(new ServiceConfig({jwt})).getParkingSpotInfo(spotId);
                    if (isSuccess) {
                        console.log("mode==>", mode);
                        setInfo(mode === "admin" ? (result as ManagerGetParkingSpotInfoResult).spotInfo : (result as ParkingSpotInfoDto));
                    } else {
                        setError(error.code);
                    }
                } catch {
                    setError(ErrorCode.NetworkError);
                } finally {
                    setProgress(false);
                }
            }
        }

        init();
    }, [spotId]);

    return (<React.Fragment>
        {progress && (<div style={{
            display: 'flex',
            justifyContent: 'center'
        }}>
            <Spinner size="medium"/>
        </div>)}
        {error && (<PCText color={Colors.red} fontSize={16} textAlign="center">{getErrorMessage(error)}</PCText>)}
        {!progress && !error && <ParkingSpotInfo jwt={jwt} info={info} mode={mode} basicInfo={props?.spotBasicInfo}/>}
    </React.Fragment>);
}

export default (props: {
    mode: "admin" | "user",
    spotId: string,
    spotBasicInfo?: IGetParkingParkingSpotsViewResultEntryDto,
    visible: boolean,
    onClose: () => void
}) => {
    const {
        mode,
        spotId,
        onClose,
        visible
    } = props;

    return (<ModalForm
        initialValues={{}}
        onSubmit={() => {
        }}
        icon={homeWithDrive}
        iconWidth={90}
        iconHeight={110}
        visible={visible}
        onClose={onClose}
        title={Resources.Miejsce_parkingowe}
        showSubmitButton={false}
        noForm
    >
        {() => <ParkingSpotDetailsModalContent mode={mode} spotId={spotId} spotBasicInfo={props.spotBasicInfo}/>}
    </ModalForm>)
}