import React from "react";
import {DatePicker, DatePickerProps} from "@mui/x-date-pickers/DatePicker";

import Colors from "../../../../styles/Colors";

export interface CustomizedDatePickerProps extends DatePickerProps<Date | null> {
    backgroundColor?: string;
    fullWidth?: boolean;
}

export const CustomizedDatePicker = React.forwardRef(function CustomizedDatePicker(props: CustomizedDatePickerProps, ref: React.Ref<HTMLDivElement>) {
    const {
        label,
        value,
        onChange,
        backgroundColor = Colors.white,
        sx,
        fullWidth=false,
        ...other
    } = props;
    const [cleared, setCleared] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [cleared]);
    return (

        <DatePicker
            ref={ref}
            label={label}
            value={value}
            onChange={onChange}
            slotProps={{
             /*   field: { clearable: true, onClear: () => setCleared(true) },*/
                textField: {
                    sx: {
                        backgroundColor: backgroundColor,
                        borderRadius: '8px',
                        width:"100%",

                    }
                }
            }}
            sx={{
             
                ...sx
            }}
            {...other}
        />)
})
       
