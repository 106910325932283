import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import Colors from "../../../../styles/Colors";
import { PCText } from "../../../../styles/Texts";
import { PCClasses } from "../../../../utils/CSSUtils";

export const LinkContent = (props: {
    isActive: boolean,
    text: string
}) => {
    const {isActive, text} = props;

    return (
        <PCText color={isActive ? Colors.heliotrope : Colors.light_royal_blue} fontSize={24} lineHeight={1.66} inline>
            {text}
        </PCText>
    );
}

export default (props: {
    to?: string,
    text: string,
    isActive: boolean,
    onClick?: () => void;
}) => {
    const {text, to, onClick, isActive} = props;
    
    if(!to){
        return (
            <div onClick={onClick} className={PCClasses("pc-header-link")} style={{
                    cursor: "pointer"
            }} >
                <LinkContent text={text} isActive={isActive} />
            </div>
        )
    }

    return (
        <Link onClick={onClick} to={to} className={PCClasses("pc-header-link")}>
            <LinkContent text={text} isActive={isActive} />
        </Link>
    )
}