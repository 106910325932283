import React from 'react';
import FileManagerNewFolderDialog from "./file-manager-new-folder-dialog";
import {useBoolean} from "../../hooks/use-boolean";
import {Box} from '@mui/material';
import Iconify from "../../components/iconify";
import CustomPopover, {usePopover} from "../../components/custom-popover";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Resources from "../../../../Resources";
import Button from "@mui/material/Button/Button";
import {StandardResponseWrapperOfObjectAndErrorWrapperOfObject} from "parkcash-api";
import Colors from "../../../styles/Colors";

interface Props {
    onAdd: () => void;
    onUploadImportedFiles: (file: (File | string), fileName: string) => Promise<StandardResponseWrapperOfObjectAndErrorWrapperOfObject>;
    buttonTitle?: string
    addTitle?: string;
    importTitle?: string;
}

export const AddOrImportActionsMenu = ({
                                           onAdd,
                                           onUploadImportedFiles,
                                           addTitle = Resources.Dodaj,
                                           importTitle = Resources.Importuj_dane_z_pliku_CSV,
                                           buttonTitle
                                       }: Props) => {
    const upload = useBoolean();
    const popover = usePopover();

    return (<Box>

        <Button color={"primary"} onClick={popover.onOpen} fullWidth={true}
                sx={{
                    backgroundColor: Colors.light_royal_blue,
                    borderColor: "transparent",
                    borderWidth: 0,
                    color: Colors.white,
                    height: "48px",
                    borderRadius: "34px",
                    "&:hover":{ 
                        backgroundColor: Colors.light_blue
                    }
                }}
        >
            {buttonTitle}
        </Button>

        <CustomPopover
            open={popover.open}
            onClose={popover.onClose}
            arrow="top-right"
            /* sx={{width: 160}}*/
        >

            <MenuItem
                onClick={() => {
                    onAdd();
                    popover.onClose();
                }}
                sx={{width: "fitContent"}}
            >
                <Iconify
                    sx={{':hover': {color: "secondary.main"}}}
                    color={"primary.main"}
                    icon={"raphael:plus"}
                    title={addTitle}
                    width={20}
                    height={20}
                />
                {addTitle}
            </MenuItem>
            <MenuItem
                onClick={() => {
                    upload.onTrue();
                    popover.onClose();
                }}
                sx={{width: "fitContent"}}>
                <Iconify
                    sx={{':hover': {color: "secondary.main"}}}
                    color={"primary.main"}
                    icon={"pajamas:import"}
                    title={importTitle}
                    width={20}
                    height={20}
                />
                {importTitle}
            </MenuItem>

        </CustomPopover>

        <FileManagerNewFolderDialog open={upload.value} onClose={upload.onFalse}
                                    title={Resources.Importuj_dane_z_pliku_CSV}
                                    uploadButtonTitle={Resources.Importuj}
                                    onUploadImportedFiles={onUploadImportedFiles}
        />
    </Box>)
}