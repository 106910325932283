import { ExternalParkingTicketService, ExternalParkingTicketStatus, ServiceConfig } from "parkcash-api";
import * as React from "react";
import { useParams } from "react-router-dom";
import Resources from "../../../Resources";
import ConfirmModal from "../../components/ConfirmModal";
import Spinner from "../../components/Spinner";
import { StandardButton } from "../../styles/Buttons";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { MOBILE_WIDTH_THRESHOLD } from "../../utils/Constants";
import { getErrorMessage } from "../../utils/ErrorUtils";
import { useWindowSize } from "../../utils/WindowSizeHook";

const INTERVAL = 5000;

const MAX_TRIES = 200;

const subscription_type = require("../../../assets/images/subscription_type.svg");
const errorImg = require("../../../assets/images/error.svg");

function setIntervalImmediately(func: () => void, interval: number) {
    func();
    return setInterval(func, interval);
}

export default () => {
    const {windowHeight, windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const [progress, setProgress] = React.useState(true);
    const [errorStatus, setErrorStatus] = React.useState<string>(null);
    const {ticketId} = useParams<{ticketId: string}>();
    const interval = React.useRef<any>();
    const tries = React.useRef(0);

    const onError = (status: string) => {
        clearInterval(interval.current);
        setProgress(false);
        setErrorStatus(status);
    }

    React.useEffect(() => {
        interval.current = setIntervalImmediately(async () => {
            try{
                const {isSuccess, error, result} = await new ExternalParkingTicketService(new ServiceConfig()).getExternalParkingTicketStatus(ticketId)
                if(isSuccess){
                    const {status} = result;
                    if(status === ExternalParkingTicketStatus.Confirmed){
                        setProgress(false);
                        clearInterval(interval.current);
                    }
                    else if(status === ExternalParkingTicketStatus.Processing){
                        if(tries.current >= MAX_TRIES){
                            onError(Resources.Przekroczono_czas_oczekiwania);
                        }
                        else{
                            tries.current = tries.current + 1;
                        }
                    }
                    else{
                        onError(`${Resources.Cos_poszlo_nie_tak}. ${Resources.Status_zakupu}: ${status}`)
                    }
                }
                else{
                    onError(getErrorMessage(error.code))
                }
            }
            catch{
                onError(getErrorMessage());
            }
        }, INTERVAL);
    }, []);

    React.useEffect(() => {
        if(!progress){
            ConfirmModal.show({
                text: Resources.Bilet_oplacony_Szlaban_podniesie_sie_automatycznie_przy_probie_wyjazdu,
                confirmText: "OK"
            })
        }
    }, [progress]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: isMobile ? 20 : 120,
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: isMobile ? 20 : 50
            }}
        >
            {!errorStatus && <img src={subscription_type} width={220} height={193} />}
            {!!errorStatus && <img src={errorImg} width={220} height={207} />}

            <BaseSpaceSeparator size={isMobile ? 30 : 50} />

            <div style={{width: isMobile ? 300 : 600}}>
                <PCText fontSize={36} color={Colors.black} textAlign="center">
                    {progress && Resources.Sprawdzanie_statusu_zakupu}
                    {!progress && !!errorStatus && errorStatus}
                    {!progress && !errorStatus && Resources.Twoja_platnosc_zostala_przyjeta_Szerokiej_drogi}
                </PCText>
                
            </div>

            <BaseSpaceSeparator size={isMobile ? 30 : 100} />

            {progress && <Spinner size="medium" />}
        </div>
    )
}