import {ReservationPolicyType} from "parkcash-api";
import Resources from "../../../../Resources";

export const MapPolicyTypeEnumToLocalizedText = (policyType: ReservationPolicyType): string => {
    switch (policyType) {
        case ReservationPolicyType.WholeDay:
            return Resources.Calodobowa
        case ReservationPolicyType.CustomRange:
            return Resources.Wybrany_zakres
        default:
            return Resources.Nieznany
    }
}