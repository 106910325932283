import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCar, faUser, faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ParkingSpotTimelineDaySegmentInfo, TimelineMemberCategory } from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import StatusPresenter from "../../components/StatusPresenter";
import { StandardButton2 } from "../../styles/Buttons";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { formatDate, formatDateTime, formatTime } from "../../utils/DateTimeUtils";

export const InfoItem = (props: {
    icon: IconProp,
    title: string,
    children: string
}) => {
    const {children, icon, title} = props;

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <FontAwesomeIcon icon={icon} color={Colors.light_royal_blue} style={{letterSpacing: 0, fontSize: 12, }} />
            <BaseSpaceSeparator size={7} />
            <PCText color={Colors.black} letterSpacing={0} fontSize={12}>
                {title}: <PCText inline color={Colors.black} letterSpacing={0} fontSize={12} semibold>{children}</PCText>
            </PCText>
        </div>
    );
}

export default (props: {
    item: ParkingSpotTimelineDaySegmentInfo;
    showAdditionalInfo?: boolean,
    onDismiss?: (id: string) => void;  
    mode?: "time" | "date" | "datetime",
    showLicencePlateNumber?: boolean 
}) => {
    const {
        mode = "time",
        item: {
            reservationId, 
            startTimestampDisplay, 
            endTimestampDisplay, 
            bookingUserName,
            effectivePrice, 
            licensePlateNumber, 
            carMake, 
            carModel, 
            category
        },
        onDismiss,
        showAdditionalInfo = true,
        showLicencePlateNumber = true
    } = props;

    return (
        <div
            style={{
                paddingTop: 14,
                paddingBottom: 14
            }}
        >
            <div style={{display: 'flex', alignItems: 'center'}}>
                <StatusPresenter 
                    text={getTitle(category)}
                    color={Colors.light_royal_blue}
                    textColor={Colors.white}
                    width={90}
                />
                <BaseSpaceSeparator size={15} />
                <PCText semibold color={Colors.black} fontSize={16}>
                    {mode === "time" && `${formatTime(startTimestampDisplay)}-${formatTime(endTimestampDisplay)}`}
                    {mode === "date" && `${formatDate(startTimestampDisplay)}-${formatDate(endTimestampDisplay)}`}
                    {mode === "datetime" && `${formatDateTime(startTimestampDisplay)}-${formatDateTime(endTimestampDisplay)}`}
                </PCText>
            </div>
            {showAdditionalInfo && (
                <>
                    <BaseSpaceSeparator size={20} />
                    <InfoItem title={Resources.Klient} icon={faUser}>{bookingUserName}</InfoItem>
                    {!!licensePlateNumber && (
                        <>
                            <BaseSpaceSeparator size={7} />
                            <InfoItem title={Resources.Pojazd} icon={faCar}>{carMake && carModel ? `${carMake} ${carModel}, ${licensePlateNumber}` : licensePlateNumber}</InfoItem>
                        </>
                    )}
                    <BaseSpaceSeparator size={7} />
                    <InfoItem title={Resources.Zarobek} icon={faWallet}>{!!effectivePrice ? `${effectivePrice} ${Resources.zl}` : Resources.bezplatnie}</InfoItem>
                </>
            )}
            
            {!!onDismiss && (
                <>
                    <BaseSpaceSeparator size={20} />
                    <div style={{width: 180}}>
                        <StandardButton2 variant="tiny" onClick={() => onDismiss && onDismiss(reservationId)} >{Resources.Odwolaj_rezerwacje}</StandardButton2>
                    </div>
                </>
            )}       
        </div>
    )
}

const getTitle = (category: TimelineMemberCategory) => {
    if(category === TimelineMemberCategory.Reservation){
        return Resources.Zajete;
    }
    else if(category === TimelineMemberCategory.TechnicalBuffer){
        return Resources.Blokada_miejsca;
    }
    else if(category === TimelineMemberCategory.ExternalReservation){
        return Resources.Zajete;
    }
    else if(category === TimelineMemberCategory.Subscription){
        return Resources.Abonament;
    }
}