// @mui
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

// _mock
// components
//
import IncomePerDay from '../../../../../../MUI/components/AddedOrModyfiedMuiComponents/Charts/MultiSeriesChart';
import TypeOfParkingChart from '../../../../../../MUI/components/AddedOrModyfiedMuiComponents/Charts/CircularPieChart';
import Resources from '../../../../../../../Resources';
import {
    GetFinancialDashboradDataResultDayByDaySummaryRecord, GetFinancialDashboradDataResultTypeSummaryRecord
} from "parkcash-api";
import {DayOfWeek, getDayOfWeek} from "../../../../../../MUI/utils/format-time";

// ----------------------------------------------------------------------
interface DetailedFinancialChartsSectionProps {
    incomePerDayChartRawData: GetFinancialDashboradDataResultDayByDaySummaryRecord[];
    typeOfParkingChartRawData: GetFinancialDashboradDataResultTypeSummaryRecord[];

}

enum ParkingTypeEnum {
    BILETY_PARKINGOW = 'Bilety parkingowe',
    ABONAMENTY = 'Abonamenty',
    REZERWACJE_ZEWNETRZNE = 'Rezerwacje zewnętrzne',
    REZEWACJE = 'Rezerwacje',
    PARKOWANIE_ROTACYJNE = 'Parkowanie rotacyjne',
    TERMINALE_PLATNICZE = 'Terminale płatnicze'

}

interface DayOfWeekStatistic {
    dayOfWeek: string;
    totalValuePerDayOfWeek: number;
    totalCountPerDayOfWeek: number;
    refundCountPerDayOfWeek: number;
}

export const getDayOfWeeksChartData = (data: GetFinancialDashboradDataResultDayByDaySummaryRecord[]): DayOfWeekStatistic[] => {
    const daysOfWeekData = new Map<DayOfWeek, DayOfWeekStatistic>();
    for (let day in DayOfWeek) {
        if (DayOfWeek.hasOwnProperty(day)) {
            daysOfWeekData.set(DayOfWeek[day], {
                dayOfWeek: DayOfWeek[day],
                totalValuePerDayOfWeek: 0,
                totalCountPerDayOfWeek: 0,
                refundCountPerDayOfWeek: 0
            });
        }
    }
    data.forEach((item) => {
        const currentDate = item.timestamp;
        const dayOfWeek = getDayOfWeek(currentDate);

        if (daysOfWeekData.has(dayOfWeek)) {
            const currentDateMapValue = daysOfWeekData.get(dayOfWeek);
            if (currentDateMapValue) {
                currentDateMapValue.totalValuePerDayOfWeek += item.totalValue;
                currentDateMapValue.totalCountPerDayOfWeek += item.totalCount;
                currentDateMapValue.refundCountPerDayOfWeek += item.refundsTotalValue;
            }
        }

    });

    return Array.from(daysOfWeekData.values());

}

export default function DetailedFinancialChartsSection({
                                                           incomePerDayChartRawData,
                                                           typeOfParkingChartRawData
                                                       }: DetailedFinancialChartsSectionProps) {
    const typeOfParkingChartData = typeOfParkingChartRawData.map((item): { label: string, value: number } => ({
        label: item.serviceTypeName,
        value: item.totalValue
    }));

    /*sortObjectsByDateAscending(incomePerDayChartRawData);*/
    const incomePerDayOfWeekChartData = incomePerDayChartRawData.map((item) => item.totalValue);
    const countPerDayOfWeekChartData = incomePerDayChartRawData.map((item) => item.totalCount);
    //const refundCountPerDayOfWeekChartData = incomePerDayChartRawData.map((item) =>Math.floor(Math.random() * 300));
    const refundCountPerDayOfWeekChartData = incomePerDayChartRawData.map((item) => item.refundsTotalValue);
    //const labelsPerDayOfWeekChartData= incomePerDayChartRawData.map((item) =>fDate (item.timestamp, 'dd.MM.yyyy'));
    const labelsPerDayOfWeekChartData = incomePerDayChartRawData.map((item) => item.timestamp.toString());

    return (<Grid container spacing={3} xs={12}>
        <Grid xs={12} md={6} lg={8}>
            <IncomePerDay
                title={Resources.Przychod_w_poszczegolne_dni}
                chart={{
                    labels: labelsPerDayOfWeekChartData,
                    series: [

                        {
                            name: Resources.Przychod,
                            type: 'column',
                            fill: 'solid',
                            yAxisunit: 'PLN', /* ,
                                separateYAxis: true,*/
                            data: incomePerDayOfWeekChartData,
                        },
                        {
                            name: Resources.Ilosc_transakcji,
                            type: 'area',
                            fill: 'gradient',
                            data: countPerDayOfWeekChartData,

                        },
                        {
                            name: Resources.Ilosc_zwrotow,
                            type: 'line',
                            fill: 'solid',
                            data: refundCountPerDayOfWeekChartData,

                        },
                    ], //  options here owerrides default options created in useChart hook inside chart component

                    options: {
                        // crete separate y for income and common serie for count and refund count
                        yaxis: [
                            {
                                seriesName: Resources.Przychod,
                                opposite: true,
                                min: 0,

                                show: true,
                                labels: {
                                    formatter: (value: number) => {
                                        let formattedValue: string = `${value?.toFixed(0)}`;

                                        formattedValue = `${formattedValue} ${'PLN'}`;

                                        return formattedValue;
                                    }
                                }
                            },
                            {
                                seriesName: Resources.Ilosc_transakcji,
                                show: true
                            },
                            {
                                // same axis for refund but hidden (strange configuration)
                                seriesName: Resources.Ilosc_transakcji,
                                show: false
                            },
                        ]
                    }
                }}

            />
        </Grid>
        <Grid xs={12} md={6} lg={4}>
            <TypeOfParkingChart
                title={Resources.Rodzaj_parkowania}
                chart={{
                    series: typeOfParkingChartData
                }}
            />
        </Grid>
    </Grid>);
}
