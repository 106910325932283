import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Link } from "react-router-dom";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { PCClasses } from "../utils/CSSUtils";

export default (props: {
    to?: string,
    onClick?: () => void,
    width?: number,
    height?: number,
    icon: IconProp,
    title?: React.ReactNode,
    titleFontSize?: number,
    titleLetterSpacing?: number,
    subtitle?: string,
    backgroundColor?: string,
    paddingHorizontal?: number,
    paddingVertical?: number,
    separatorSize?: number
}) => {
    const {
        onClick,
        title,
        subtitle,
        backgroundColor = Colors.light_royal_blue,
        icon,
        width = 150,
        height = 150,
        paddingVertical = 0,
        paddingHorizontal = 0,
        titleFontSize = 22,
        titleLetterSpacing,
        to,
        separatorSize = 10
    } = props;

    const styles: React.CSSProperties = {
        width,
        height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor,
        borderRadius: 19,
        boxSizing: "border-box",
        paddingTop: paddingVertical,
        paddingBottom: paddingVertical,
        paddingLeft: paddingHorizontal,
        paddingRight: paddingHorizontal
    }
    const content = (
        <>
            <FontAwesomeIcon icon={icon} style={{color: Colors.white, fontSize: 39}} />
            {(!!title || !!subtitle) && <BaseSpaceSeparator size={separatorSize} />}
            {!!title && (
                <PCText textAlign="center" semibold fontSize={titleFontSize} color={Colors.white} letterSpacing={titleLetterSpacing}>
                    {title}
                </PCText>
            )}
            {(!!title && !!subtitle && <BaseSpaceSeparator size={separatorSize} />)}
            {!!subtitle && (
                <PCText textAlign="center" fontSize={18} color={Colors.white}>
                    {subtitle}
                </PCText>
            )}
        </>
    );
    const className = PCClasses("pc-button")

    if(to){
        return (
            <Link
                to={to}
                onClick={onClick}
                className={className}
                style={styles}
            >
                {content}
            </Link>
        );
    }
    else{
        return (
            <div
                onClick={onClick}
                className={className}
                style={styles}
            >
                {content}
            </div>
        );
    }
    
}