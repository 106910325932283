import { IVirtualPilotDto, ParkingManagerService, ServiceConfig } from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import Resources from "../../../Resources";
import Body, { SubBody } from "../../layouts/Main/Body";
import { applicationState } from "../../redux/ApplicationState";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { IParkCashApplicationMode } from "../../utils/ParkCashApplicationMode";
import EmptyList from "../../components/EmptyList";
import {LprAndQRUsageHistoryGrid} from "./LprAndQRUsageHistoryGrid";
import {LprManagementGrid} from "./LprManagementGrid";

interface Props {
    jwt: string;
    applicationMode: IParkCashApplicationMode;
}

export const Container = (props: Props) => {
    const {applicationMode, jwt} = props;
    const isFeatureEnabled = applicationMode.getCurrentParking()?.lprWhiteListManagementEnabled || null;
    
    return (
        isFeatureEnabled ?
        <Body title={"LPR/QR"}>
           <LprManagementGrid jwt={jwt} applicationMode={applicationMode} />
            <BaseSpaceSeparator size={50}/>
            <LprAndQRUsageHistoryGrid jwt={jwt} applicationMode={applicationMode} />
        </Body>:(<EmptyList
        title={Resources.Funkcjonalnosc_niedostepna}
        subtitle={Resources.Jezeli_jestes_zainteresowany_dostepem_z_uzuciem_automatycznego_rozpoznawania_tablic_rejestracyjnych_skontaktuj_sie_z_naszym_dzialem_sprzedazy}
    />)
    );
}

const mapStateToProps = (state: applicationState): Props => ({
    jwt: state.user.token,
    applicationMode: state.user.applicationMode,
});

export default connect(mapStateToProps)(Container);