import * as React from "react";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { BIGSCREEN_WIDTH_THRESHOLD, MOBILE_WIDTH_THRESHOLD } from "../../utils/Constants";
import { useWindowSize } from "../../utils/WindowSizeHook";

const logo = require("../../../assets/images/authentication/logo.svg"); 

export default (props: {children: React.ReactNode}) => {
    const {windowWidth} = useWindowSize();

    const isBigScreen = windowWidth >= BIGSCREEN_WIDTH_THRESHOLD;
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;

    return (
        <div
            style={{
                flex: 1,
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: isBigScreen ? 200 : 0,
                alignItems: isBigScreen ? "flex-start" : "center",
                paddingTop: isMobile ? 50 : 100,
                paddingBottom: 50
            }}
        >
            <div style={{width: 312, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <img src={logo} height={111} width={119} /> 
                <BaseSpaceSeparator size={90} />
                {props.children}
            </div>
            
        </div>
    );
}