import { ExternalReservationTargetDto, MakeReservationDto, ParkingSpotsService, PaymentsService, ServiceConfig } from "parkcash-api";
import { getErrorMessage } from "../../utils/ErrorUtils";
import { useJWT } from "../../utils/JWTUtils";
import ReservationParameters from "../ReservationParameters";

export default async (parameters: ReservationParameters, externalReservationTarget: ExternalReservationTargetDto, vehicleId: string): Promise<{result: boolean, reservationId?: string, amount?: number, message?: string}> => {
    try{
        const jwt = useJWT();
        const makeReservationResponse = await new ParkingSpotsService(new ServiceConfig({jwt})).makeReservation(parameters.ParkingSpot.spotId, new MakeReservationDto({
            end: parameters.Leaving,
            start: parameters.Arriving,
            externalReservationTarget,
            vehicleId: vehicleId || undefined
        }));
        if(makeReservationResponse.isSuccess){
            const reservationId = makeReservationResponse.result.id; 

            const ammountResponse = await new PaymentsService(new ServiceConfig({jwt: jwt})).getReservationPaymentAmountInfo(reservationId);
            if(ammountResponse.isSuccess){
                return {result: true, reservationId, amount: ammountResponse.result.data.totalPrice};
            }
            else{
                return {result: false, message: getErrorMessage(ammountResponse.error.code)};
            }
        }
        else{
            return {result: false, message: getErrorMessage(makeReservationResponse.error.code)};
        }
    }
    catch(e){
        return {result: false, message: getErrorMessage()};
    }
}