import * as React from "react";
import {Link} from "react-router-dom";
import Colors from "../styles/Colors";
import {PCClasses} from "../utils/CSSUtils";
import Triangle from "./Triangle";
import "./Dropdown.scss";

export interface DropdownAction {
    text: string;
    to?: string;
    href?: string;
    target?: string;
    onClick?: () => void;
    selected?: boolean;
    visible?: boolean;
}

interface Props {
    actions: DropdownAction[];
    children: React.ReactNode;
    contentWidth?: number;
    contentTranslateX?: number;
    reversed?: boolean;
    maxHeight?: number;
}

interface State {
    width: number;
    height: number;
    opacity: 0 | 1;
    display: "block" | "none";
}

const ANIMATION_DURATION = 200;

const TRIANGLE_WIDTH = 40;

const TRIANGLE_HEIGHT = 20;

const DEFAULT_CONTENT_WIDTH = 210;

const CONTENT_OFFSET = 26;

export default class Dropdown extends React.Component<Props, State> {
    private container: HTMLDivElement;

    private disableReshow = false;

    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            display: "none",
            opacity: 0,
        };
    }

    componentDidUpdate() {
        this.updateDimensions();
    }

    componentDidMount() {
        this.updateDimensions();
    }

    render() {
        return (/* orginal version  <div
        onClick={this.onClick}
        className={PCClasses("pc-dropdown-container")}
        ref={(i) => (this.container = i)}
      >
        {this.renderContent()}
        {this.props.children}
      </div>*/
            // after updating to react 18
            <div
                onClick={(event) => this.onClick(event)}
                className={PCClasses("pc-dropdown-container")}
                ref={(i) => (this.container = i)}
            >
                {this.renderContent()}
                {this.props.children}
            </div>);
    }

    private onClick = (event: React.MouseEvent) => {
        /* Orginall if (!this.disableReshow) {
           this.showContent();
         }*/
        // after updating to react 18
        document.body.click();
        event.stopPropagation();
        /* stopPropagation is added to not hide menu immediately (React 18 different event behaviour)
        * but if propagation is stopped, the menu will not hide at all if clicked or other menu instance (event does not reach document.body)
        * therefore document.body.click() is added to hide menu when clicked on other menu instance as temporary solution
        * */
        
        if (!this.disableReshow) {
            this.showContent();
        }
    };

    private updateDimensions = () => {
        const {
            width,
            height
        } = this.state;
        const newHeight = this.container?.clientHeight || 0;
        const newWidth = this.container?.clientWidth || 0;
        if (newWidth !== width || newHeight !== height) {
            this.setState({
                height: newHeight,
                width: newWidth
            });
        }
    };

    private showContent = () => {

        this.disableReshow = true;
        document.body.addEventListener("click", this.hideContent);
        this.setState({display: "block"});
        setImmediate(() => {
            this.setState({opacity: 1});
        });
      
    };

    private hideContent = () => {
        document.body.removeEventListener("click", this.hideContent);
        this.setState({opacity: 0});
        setTimeout(() => {
            this.setState({display: "none"});
            setImmediate(() => {
                this.disableReshow = false;
            });
        }, ANIMATION_DURATION);
    };

    private renderContent() {
        const {
            width,
            height,
            opacity,
            display
        } = this.state;
        const {
            contentWidth = DEFAULT_CONTENT_WIDTH,
            reversed = false,
            maxHeight,
            contentTranslateX = 0,
        } = this.props;
        if (!height || !width) {
            return null;
        }
        const menuItems = this.props.actions;
        return (<div
                className={PCClasses([], {
                    "pc-dropdown-content": !reversed,
                    "pc-dropdown-content-reversed": reversed,
                })}
                style={{
                    transition: `opacity ${ANIMATION_DURATION}ms`,
                    display,
                    opacity,
                    overflow: "auto",
                    maxHeight,
                    boxSizing: "border-box",
                    width: contentWidth,
                    transform: reversed ? `translate(${(width - contentWidth) / 2 + contentTranslateX}px, ${-height - CONTENT_OFFSET}px)` : `translate(${(width - contentWidth) / 2 + contentTranslateX}px, ${height + CONTENT_OFFSET}px)`,
                }}
            >
                {!reversed && (<div
                        style={{
                            position: "absolute",
                            top: -TRIANGLE_HEIGHT,
                            left: (contentWidth - TRIANGLE_WIDTH) / 2,
                        }}
                    >
                        <Triangle
                            height={TRIANGLE_HEIGHT}
                            width={TRIANGLE_WIDTH}
                            color={Colors.light_royal_blue}
                        />
                    </div>)}
                {menuItems.map((item, index, arr) => {
                    const {
                        text,
                        onClick,
                        selected,
                        to,
                        visible = true,
                        href,
                        target,
                    } = item;
                    const className = PCClasses("pc-dropdown-link", {
                        "pc-dropdown-linkselected": selected,
                    });
                    const separator = arr.filter((a) => a.visible).length > 1 && index > 0;
                    if (!visible) {
                        return null;
                    }
                    return (<React.Fragment key={index}>
                            {separator && (<div
                                    style={{
                                        height: 1,
                                        backgroundColor: Colors.very_light_pink
                                    }}
                                />)}
                            {!!href && (<a
                                    href={href}
                                    target={target}
                                    className={className}
                                    /* 
                                     orginal in react 16
                                     onClick={onClick}*/
                                    // after updating to react 18 to solve the problem of the dropdown menu not closing
                                    // when clicking on a link
                                    onClick={() => {
                                        onClick && onClick();
                                        this.hideContent();
                                    }}

                                >
                                    {text}
                                </a>)}
                            {!!to && (<Link to={to} className={className}
                                    /* 
                                     orginal in react 16
                                    onClick={onClick}*/
                                    // after updating to react 18 to solve the problem of the dropdown menu not closing
                                    // when clicking on a link
                                            onClick={() => {
                                                onClick && onClick();
                                                this.hideContent();
                                            }}

                                >
                                    {text}
                                </Link>)}
                            {!to && !href && (<div className={className}
                                    /* 
                                    orginal in react 16
                                    onClick={onClick}*/
                                    // after updating to react 18 to solve the problem of the dropdown menu not closing
                                    // when clicking on a link
                                                   onClick={() => {
                                                       onClick && onClick();
                                                       this.hideContent();
                                                   }}>
                                    {text}
                                </div>)}
                        </React.Fragment>);
                })}
                {reversed && (<div
                        style={{
                            position: "absolute",
                            bottom: -TRIANGLE_HEIGHT,
                            left: (contentWidth - TRIANGLE_WIDTH) / 2,
                        }}
                    >
                        <Triangle
                            rotate={180}
                            height={TRIANGLE_HEIGHT}
                            width={TRIANGLE_WIDTH}
                            color={Colors.blue_grey}
                        />
                    </div>)}
            </div>);
    }
}
