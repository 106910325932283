import { ErrorCode, PaymentOrderStatus, PaymentsService, PaymentTargetType, ServiceConfig, StartSavedCreditCardPaymentDto } from "parkcash-api";
import * as React from "react";
import {unmountComponentAtNode, render} from "react-dom";
import Resources from "../../Resources";
import Spinner from "../components/Spinner";
import { StandardButton2 } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { getErrorMessage } from "../utils/ErrorUtils";
import { useJWT } from "../utils/JWTUtils";
import BluemediaTransactionParameters, { BluemediaTransactionParametersTarget } from "./BluemediaTransactionParameters";
import { TransactionType } from "./Enums";
import PaymentForm from "./PaymentForm";

const credit_card = require("../../assets/images/credit_card.svg");
const errorImg = require("../../assets/images/error.png");

const savedCreditCardModal = document.getElementById("savedCreditCardModal");

export default class SavedCreditCardModal {
    static show(args: {
        cardId: string,
        amount: number,
        paymentTargetType: PaymentTargetType,
        targetObjectId: string
    }){
        return new Promise<boolean>(resolve => {
            const onSuccess = () => {
                resolve(true);
                unmountComponentAtNode(savedCreditCardModal);
            }

            const onFailed = () => {
                resolve(false);
                unmountComponentAtNode(savedCreditCardModal);
            }

            render((
                <Modal 
                    onFailed={onFailed}
                    onSuccess={onSuccess}
                    {...args}
                />
            ), savedCreditCardModal)
        });
    }
}

const Modal = (props: {
    cardId: string,
    amount: number,
    paymentTargetType: PaymentTargetType,
    targetObjectId: string
    onSuccess: () => void;
    onFailed: () => void;
}) => {
    const [progress, setProgress] = React.useState(true);
    const [error, setError] = React.useState<string>(null);
    const {amount, cardId, paymentTargetType, targetObjectId, onFailed, onSuccess} = props;
    const jwt = useJWT();

    const pay = async () => {
        const params = new BluemediaTransactionParameters
        (
            amount,
            new PaymentForm({
                type: TransactionType.PayWithSavedCreditCard,
                creditCardInfo: {
                    id: cardId
                }
            }),
            null,
            null,
            new BluemediaTransactionParametersTarget(paymentTargetType, targetObjectId)
        );
        const payResult = await payWithSavedCreditCard(params, jwt);
        if(typeof payResult !== "string"){
            onSuccess();
        }
        else{
            setError(payResult);
        }
        setProgress(false);
    };

    React.useEffect(() => {
        pay();
    }, []);

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1000000,
                backgroundColor: Colors.darkOverlay 
            }}
        >
            <div
                style={{
                    backgroundColor: Colors.white,
                    padding: "50px 30px 30px 30px",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 300
                }}
            >
                {progress && (
                    <>
                        <img src={credit_card} width={105} height={100} />
                        <BaseSpaceSeparator size={30} />
                        <PCText semibold fontSize={18} color={Colors.black} textAlign="center">{Resources.Oczekiwanie_na_potwierdzenie_transakcji}</PCText>
                        <BaseSpaceSeparator size={50} />
                        <Spinner size="large" />
                    </>
                )}
                {error && (
                    <>
                        <img src={errorImg} height={110} width={110} />
                        <BaseSpaceSeparator size={30}/>
                        <PCText bold textAlign="center" fontSize={18} color={Colors.black}>
                            {error}
                        </PCText>
                        <BaseSpaceSeparator size={50} />

                        {<StandardButton2 onClick={onFailed}>{Resources.Wroc}</StandardButton2>}
                    </>
                )}
            </div>
        </div>
    )
}

const MAX_RETRIES = 30;

const CHECK_INTERVAL = 2000;

export async function payWithSavedCreditCard(parameters: BluemediaTransactionParameters, jwt: string): Promise<true | string> {
    try{
        const {CardId, Amount, TargetObjectId, PaymentTargetType} = parameters;
        const ipAddress = "127.0.0.1";
        const response = await new PaymentsService(new ServiceConfig({jwt})).startSavedCreditCardPayment(new StartSavedCreditCardPaymentDto({
            amount: Amount,
            ipAddress,
            cardId: CardId,
            paymentTargetType: PaymentTargetType,
            targetObjectId: TargetObjectId
        }));
        if(response.isSuccess){
            console.log('pay with credit card success. start checking itn');
            return await checkForITN(response.result.id, jwt);
        }
        else{
            console.log('pay with credit card error: ', response. error);
            return getErrorMessage(response.error.code);
        }
    }
    catch(e){
        const error = e;
        console.log('unknown error while paying with saved credit card: ', error);
        return getErrorMessage(ErrorCode.NetworkError);
    }   
}

function checkForITN(paymentId: string, jwt: string): Promise<true | string> {   
    return new Promise<true | string>(resolve => {
        let retries = 0;
        let interval = null;

        const check = async (): Promise<PaymentOrderStatus | string> => {
            try{
                const response = await new PaymentsService(new ServiceConfig({jwt})).getPaymentStatus(paymentId);
                if(response.isSuccess){
                    return response.result.status;
                }
                else{
                    return getErrorMessage(response.error.code);
                }
            }
            catch{
                return getErrorMessage(ErrorCode.NetworkError);
            }
        }

        interval = setInterval(() => {
            check().then(result => {

                if(result === PaymentOrderStatus.Completed){
                    clearInterval(interval);
                    resolve(true);
                }
                else if(result === PaymentOrderStatus.Started){
                    if(++retries >= MAX_RETRIES){
                        clearInterval(interval);
                        resolve(Resources.Transakcja_nie_zostala_zrealizowana);
                    }
                }
                else if(typeof result === "string"){
                    clearInterval(interval);
                    resolve(result);
                }
                else{
                    clearInterval(interval);
                    resolve(Resources.Transakcja_nie_zostala_zrealizowana);
                }
            })
        }, CHECK_INTERVAL);
    });
}