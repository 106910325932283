import * as React from "react";
import { MOBILE_WIDTH_THRESHOLD } from "../../utils/Constants";
import { useWindowSize } from "../../utils/WindowSizeHook";
import Content from "./Content";
import LeftImage from "./LeftImage";

export default (props: {
    children: React.ReactNode;
}) => {
    const {windowWidth} = useWindowSize();

    return (
        <div id="authenticationLayoutWrapper"
            style={{
                height: '100%',
                display: 'flex',
            }}
        >
            {windowWidth >= MOBILE_WIDTH_THRESHOLD && <LeftImage />}
            <Content>{props.children}</Content>
        </div>
    )
}