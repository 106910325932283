import {
    faCalendarAlt, faCar, faEdit, faEye, faPowerOff, faQrcode, faTrashAlt, faUser, faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
    GetParkingParkingSpotsViewResultEntryDto,
    ParkingManagerService,
    ParkingSpotsService,
    ParkingSpotStatus,
    ReturnParkingSpotToSpotsPoolDto,
    ServiceConfig,
    SortOrder,
    StandardResponseWrapperOfObjectAndErrorWrapperOfObject,
} from "parkcash-api";
import * as React from "react";
import {useCallback} from "react";
import {connect} from "react-redux";
import Resources from "../../../Resources";
import ConfirmModal from "../../components/ConfirmModal";
import DropdownList from "../../components/DropdownList";
import Input from "../../components/forms/Input";
import ParkingSpotNumberPresenter, {ParkingSpotNumberPresenterUtils} from "../../components/ParkingSpotNumberPresenter";
import PCDataGrid from "../../components/PCDataGrid";
import ActionColumn, {ActionIcon,} from "../../components/PCDataGrid/ActionColumn";
import StatusPresenter from "../../components/StatusPresenter";
import ParkingSpotDetailsModal from "../../parkingSpots/ParkingSpotDetailsModal";
import ParkingSpotForm from "../../parkingSpots/ParkingSpotForm";
import {applicationState} from "../../redux/ApplicationState";
import {StandardButton} from "../../styles/Buttons";
import Colors from "../../styles/Colors";
import {BaseSpaceSeparator} from "../../styles/Separators";
import {MOBILE_WIDTH_THRESHOLD} from "../../utils/Constants";
import {CustomDataSource, FilterOperator, IDataSource,} from "../../utils/DataSource";
import {getErrorMessage, getErrorMessageNew} from "../../utils/ErrorUtils";
import Notify from "../../utils/Notify";
import {IParkCashApplicationMode} from "../../utils/ParkCashApplicationMode";
import {getFilters, getMultiKeysValue, getSorts} from "../../utils/SieveUtils";
import {useWindowSize} from "../../utils/WindowSizeHook";
import {useParkingLayout} from "../useParkingLayout";
import AssignSpotPermanentlyForm from "./AssignSpotPermanentlyForm";
import {
    AutomationService, FileParameter, GetParkingParkingSpotsViewStatus, IGetParkingParkingSpotsViewResultEntryDto,
} from "parkcash-api";
import {
    SpotNotAsigmentRelatedFeaturesComponent
} from "./ParkingSpotDisplayComponents/SpotNotAsigmentRelatedFeaturesComponent";
import {SpotAsigmentDescriptionWithIcon} from "./ParkingSpotDisplayComponents/SpotAsigmentDescriptionWithIcons";
import {useBoolean} from "../../MUI/hooks/use-boolean";
import {AddOrImportActionsMenu} from "../../MUI/sections/file-manager/AddOrImportActionsMenu";
import {AssignLicensePlatesToSpotForm} from "./AssignLicensePlatesToSpotForm/AssignLicensePlatesToSpotForm";
import {showQrCodesInSeperateWindows} from "../../utils/QrCodeShowUtils";
import Stack from "@mui/material/Stack";
import {ParkingSpotRealTimeOccupancyPresenter} from "../../components/ParkingSpotRealTimeOccupancyPresenter";

interface Props {
    jwt: string;
    applicationMode: IParkCashApplicationMode;
}

type filterType = "all" | "deactivated" | "unavailable" | "free" | "occupied";

const inactiveSpotStatuses = [
    ParkingSpotStatus.Created,
    ParkingSpotStatus.Deactivated
];

function getActivationDeactivationTitle(item: GetParkingParkingSpotsViewResultEntryDto) {
    if (inactiveSpotStatuses.includes(item.status)) {
        return Resources.Aktywuj
    }
    return Resources.Dezaktywuj;
}

const Container = (props: Props) => {
    const {
        applicationMode,
        jwt
    } = props;
    const parkingId = applicationMode.getCurrentParking()?.parkingId || null;
    const [dataSource, setDataSource] = React.useState<IDataSource<GetParkingParkingSpotsViewResultEntryDto>>(null);
    const [parkingSpotFormVisible, setParkingSpotFormVisible] = React.useState(false);
    const [detailsParkingSpot, setDetailsParkingSpot] = React.useState<string>(null);
    const [detailsBasicInfo, setDetailsBasicInfo] = React.useState<IGetParkingParkingSpotsViewResultEntryDto>(null);
    const [availabilityStatus, setAvailabilityStatus] = React.useState<filterType>("all");
    const [level, setLevel] = React.useState<string>(null);
    const [sector, setSector] = React.useState<string>(null);
    const {
        levels,
        sectors
    } = useParkingLayout();
    const [currentSpotId, setCurrentSpotId] = React.useState<string>(null);
    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const [search, setSearch] = React.useState("");
    const [assignSpotPermanentlyFormVisible, setAssignSpotPermanentlyFormVisible] = React.useState(false);
    const upload = useBoolean();
    const [assignLicensePlatesToSpotFormVisible, setAssignLicensePlatesToSpotFormVisible] = React.useState(false);
    const [selectedSpotAssignedLicensePlates, setSelectedSpotAssignedLicensePlates] = React.useState<string[]>([]);
    const [selectedSpotId, setSelectedSpotId] = React.useState<string>(null);


    React.useEffect(() => {
        if (parkingId) {
            setDataSource(getDataSource(parkingId));
        }
    }, [parkingId]);

    const onLevelChanged = (l: string) => {
        setLevel(l);
        if (!l) {
            dataSource.removeFilter("level");
        } else {
            dataSource.filter("level", FilterOperator.EQUALS, l);
        }
    };

    const onSectorChanged = (s: string) => {
        setSector(s);
        if (!s) {
            dataSource.removeFilter("sector");
        } else {
            dataSource.filter("sector", FilterOperator.EQUALS, s);
        }
    };

    const onAvailabilityStatusChanged = (s: filterType) => {
        setAvailabilityStatus(s);

        if (s === "all") {
            dataSource.removeFilter("availabilityStatus");
        } else if (s === "unavailable") {
            dataSource.filter("availabilityStatus",
                FilterOperator.EQUALS,
                GetParkingParkingSpotsViewStatus.Unavailable
            );
        } else if (s === "deactivated") {
            dataSource.filter("availabilityStatus",
                FilterOperator.EQUALS,
                GetParkingParkingSpotsViewStatus.Deactivated
            );
        } else if (s === "free") {
            dataSource.filter("availabilityStatus", FilterOperator.EQUALS, GetParkingParkingSpotsViewStatus.Free);
        } else if (s === "occupied") {
            dataSource.filter("availabilityStatus", FilterOperator.EQUALS, GetParkingParkingSpotsViewStatus.Occupied);
        }
    };

    const resetFilters = () => {
        setSearch("");
        setLevel(null);
        setSector(null);
        setAvailabilityStatus("all");
    }
    const getDataSource = (parkingId: string) => {
        resetFilters(); //reset filters when new data source is created e.g parking id is changed
        const result = new CustomDataSource<GetParkingParkingSpotsViewResultEntryDto, {
            jwt: string; parkingId: string
        }>({
            load: async (loadOptions, additional) => {
                const {
                    filter,
                    page,
                    pageSize,
                    sort
                } = loadOptions;
                const {
                    jwt,
                    parkingId
                } = additional;

                const {
                    isSuccess,
                    result,
                    error
                } = await new ParkingManagerService(new ServiceConfig({jwt})).getParkingParkingSpotsView(parkingId,
                    getFilters(filter),
                    getSorts(sort),
                    page,
                    pageSize
                );

                return isSuccess ? {
                    items: result.entries,
                    totalItems: result.paginationDto.totalElementsCount,
                } : error.code;
            },
            additionalInfo: {
                jwt,
                parkingId
            },
            initialSort: [
                {
                    key: "number",
                    order: SortOrder.Ascending
                }
            ],
        });

        return result;
    };

    const onAdd = () => {
        setCurrentSpotId(null);
        setParkingSpotFormVisible(true);
    };
    const onUploadImportedFiles = useCallback((async (file: (string | File), fileName: string): Promise<StandardResponseWrapperOfObjectAndErrorWrapperOfObject> => {
        const fileParameter: FileParameter = {
            fileName: fileName,
            data: file
        }
        const response = await new AutomationService(new ServiceConfig({jwt})).batchCreateParkingSpots(parkingId,
            fileParameter
        );
        return response;

    }), [
        jwt,
        parkingId
    ]);

    const onEdit = (item: GetParkingParkingSpotsViewResultEntryDto) => {
        setCurrentSpotId(item.id);
        setParkingSpotFormVisible(true);
    };

    const onRemoveClicked = async (item: GetParkingParkingSpotsViewResultEntryDto) => {
        const confirm = await ConfirmModal.show({
            text: Resources.Czy_na_pewno_chcesz_usunac_miejsce,
            confirmText: Resources.Usun,
        });
        if (confirm) {
            try {
                const {
                    isSuccess,
                    error
                } = await new ParkingSpotsService(new ServiceConfig({jwt})).deleteParkingSpot(item.id);
                if (isSuccess) {
                    dataSource.reload();
                } else {
                    Notify.Error(getErrorMessage(error.code));
                }
            } catch {
                Notify.Error(getErrorMessage());
            }
        }
    };

    const onToggleActivation = async (item: GetParkingParkingSpotsViewResultEntryDto) => {
        try {
            let parkingSpotsService = new ParkingSpotsService(new ServiceConfig({jwt}));
            let response: StandardResponseWrapperOfObjectAndErrorWrapperOfObject;
            if (inactiveSpotStatuses.includes(item.status)) {
                response = await parkingSpotsService.activate(item.id);
            } else {
                response = await parkingSpotsService.deactivate(item.id);
            }
            const {
                isSuccess,
                error
            } = await response;
            if (isSuccess) {
                dataSource.reload();
            } else {
                Notify.Error(getErrorMessage(error.code));
            }
        } catch {
            Notify.Error(getErrorMessage());
        }

    };

    const onTogglePermanentAssignment = async (item: GetParkingParkingSpotsViewResultEntryDto) => {

        if (item.isAssignedPermanently) {
            const confirm = await ConfirmModal.show({
                text: Resources.Czy_na_pewno_chesz_usunac_stale_przypisanie_i_zwrocic_miejsce_do_puli_miejsc_dostepnych_dla_wszystkich_uzytkownikow,
                confirmText: Resources.Usun,
            });
            if (!confirm) {
                return;
            }
            try {
                let managerService = new ParkingManagerService(new ServiceConfig({jwt}));
                const {
                    isSuccess,
                    error
                } = await managerService.returnParkingSpotToSpotsPool(new ReturnParkingSpotToSpotsPoolDto({
                    parkingSpotId: item.id
                }));

                if (isSuccess) {
                    dataSource.reload();
                } else {
                    Notify.Error(getErrorMessageNew(error));
                }
            } catch {
                Notify.Error(getErrorMessage());
            }

        } else {
            dataSource.reload();
            setCurrentSpotId(item.id);
            setAssignSpotPermanentlyFormVisible(true);
            return;
        }
    };

    const onDetails = (item: GetParkingParkingSpotsViewResultEntryDto) => {
        setDetailsParkingSpot(item.id);
        setDetailsBasicInfo({...item});
    };

    const onSearch = () => {
        const searchFields: Array<keyof IGetParkingParkingSpotsViewResultEntryDto> = [
            "number",
            "ownerEmail",
            "ownerFirstName",
            "ownerLastName"
        ];
        // multiFiltering implementation with Sieve or operator
        const multiKey = getMultiKeysValue(searchFields);
        if (!search || search === "all") {
            dataSource.removeFilter(multiKey as keyof IGetParkingParkingSpotsViewResultEntryDto)
        } else if (search && search !== "all") {
            dataSource.filter(multiKey as keyof IGetParkingParkingSpotsViewResultEntryDto,
                FilterOperator.CASE_INSENSITIVE_STRING_CONTAINS,
                search
            );
        }
    }

    const onShowQrCodeClicked = (item: GetParkingParkingSpotsViewResultEntryDto) => {
        showQrCodesInSeperateWindows(item.qrCodesGuids);
    };

    return (<>
        <ParkingSpotForm
            onClose={() => setParkingSpotFormVisible(false)}
            visible={parkingSpotFormVisible}
            mode="admin"
            spotId={currentSpotId}
            parkingId={parkingId}
            onSubmitted={() => {
                setAvailabilityStatus("all");
                dataSource.reload();
                setParkingSpotFormVisible(false);
            }}
        />
        <AssignSpotPermanentlyForm
            visible={assignSpotPermanentlyFormVisible}
            onClose={() => setAssignSpotPermanentlyFormVisible(false)}
            onSubmitted={() => {
                dataSource.reload();
                setAssignSpotPermanentlyFormVisible(false);
                setCurrentSpotId(null)
            }}
            parkingId={parkingId}
            parkingSpotId={currentSpotId}
        />
        <ParkingSpotDetailsModal
            visible={!!detailsParkingSpot && !!detailsBasicInfo}
            onClose={() => {
                setDetailsParkingSpot(null);
                setDetailsBasicInfo(null)
            }}
            mode="admin"
            spotId={detailsParkingSpot}
            spotBasicInfo={detailsBasicInfo}

        />
        <AssignLicensePlatesToSpotForm
            data={{
                plates: selectedSpotAssignedLicensePlates,
                newPlate: ""
            }}
            visible={assignLicensePlatesToSpotFormVisible}
            onClose={() => {
                setSelectedSpotAssignedLicensePlates([])
                setAssignLicensePlatesToSpotFormVisible(false)
            }}
            parkingSpotId={selectedSpotId}
            onSubmitted={() => dataSource.reload()}
        />
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: isMobile ? "flex-start" : "flex-end",
            }}
        >
            {!!levels?.length && (<>
                <div style={{width: isMobile ? "100%" : 250}}>
                    <DropdownList
                        value={level}
                        onChange={onLevelChanged}
                        label={Resources.Poziom}
                        actions={[
                            {
                                id: null,
                                text: Resources.Wszystkie
                            },
                            ...levels.map((l) => ({
                                id: l,
                                text: l
                            })),
                        ]}
                    />
                </div>
                <BaseSpaceSeparator size={20}/>
            </>)}
            {!!sectors?.length && (<>
                <div style={{width: isMobile ? "100%" : 250}}>
                    <DropdownList
                        value={sector}
                        onChange={onSectorChanged}
                        label={Resources.Sektor}
                        actions={[
                            {
                                id: null,
                                text: Resources.Wszystkie
                            },
                            ...sectors.map((s) => ({
                                id: s,
                                text: s
                            })),
                        ]}
                    />
                </div>
                <BaseSpaceSeparator size={20}/>
            </>)}

            <div style={{width: isMobile ? "100%" : 250}}>
                <DropdownList<filterType>
                    value={availabilityStatus}
                    onChange={onAvailabilityStatusChanged}
                    label={Resources.Status}
                    actions={[
                        {
                            text: Resources.Wszystkie,
                            id: "all"
                        },
                        {
                            text: Resources.Wolne,
                            id: "free"
                        },
                        {
                            text: Resources.Zajete,
                            id: "occupied"
                        },
                        {
                            text: Resources.Niedostepne,
                            id: "unavailable"
                        },
                        {
                            text: Resources.Nieaktywne,
                            id: "deactivated"
                        },


                    ]}
                />
            </div>
            <BaseSpaceSeparator size={20}/>
            <div style={{width: isMobile ? "100%" : 250}}>
                <AddOrImportActionsMenu onAdd={onAdd} onUploadImportedFiles={onUploadImportedFiles}
                                        buttonTitle={Resources.Dodaj_nowe_miejsce}
                                        importTitle={Resources.Importuj_z_csv}/>
            </div>
        </div>
        <BaseSpaceSeparator size={20}/>
        <div
            style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "center",
            }}
        >

            <div
                style={{
                    flex: isMobile ? undefined : 1,
                    width: isMobile ? "100%" : undefined,
                }}
            >
                <Input
                    value={search}
                    onChangeText={setSearch}
                    borderColor={Colors.very_light_pink}
                    placeholder={Resources.Wyszukaj_Po_Numerze_miejsca_nazwisku_emailu}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            onSearch();
                        }
                    }}
                />
            </div>
            <BaseSpaceSeparator size={10}/>
            <div style={{width: isMobile ? "100%" : 200}}>
                <StandardButton variant="big" onClick={onSearch}>
                    {Resources.Wyszukaj}
                </StandardButton>
            </div>
        </div>
        <BaseSpaceSeparator size={20}/>
        <PCDataGrid<GetParkingParkingSpotsViewResultEntryDto>
            dataSource={dataSource}
            columns={[
                {
                    label: Resources.Przypisanie,
                    width: 410,
                    renderCell: (item) => (<SpotAsigmentDescriptionWithIcon spot={item}/>),
                },
                {
                    label: Resources.Cechy_miejsca,
                    width: 360,
                    renderCell: (item) => (<SpotNotAsigmentRelatedFeaturesComponent spot={item}/>),
                },
                {
                    label: Resources.Przypisane_nr_rejestracyjne,
                    width: 250,
                    renderCell: (item) => (item.assignedLicensePlates && item.assignedLicensePlates.length > 0 ? (<>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <ActionIcon
                                icon={faQrcode}
                                title={Resources.Pokaz_kod_qr}
                                onClick={() => onShowQrCodeClicked(item)}
                            />
                            {item.assignedLicensePlates.join(";")}
                        </div>
                    </>) : null)
                },
                {
                    label: Resources.Numer,
                    dataField: "number",
                    width: 243,
                    renderCell: (item) => (<ParkingSpotNumberPresenter
                        level={item.level}
                        spotNumber={item.number}
                        sector={item.sector}
                    />),
                },
                {
                    label: Resources.Status,
                    dataField: "availabilityStatus",
                    width: 243,
                    renderCell: (item) => (<Stack direction={"row"} spacing={2} width={"100%"} alignItems={"center"}>
                        <StatusPresenter
                            color={item.availabilityStatus === GetParkingParkingSpotsViewStatus.Occupied ? Colors.light_royal_blue : item.availabilityStatus === GetParkingParkingSpotsViewStatus.Free ? Colors.green : GetParkingParkingSpotsViewStatus.Unavailable ? Colors.blue_grey : Colors.brownish_grey}
                            text={item.availabilityStatus === GetParkingParkingSpotsViewStatus.Occupied ? Resources.Zajete : item.availabilityStatus === GetParkingParkingSpotsViewStatus.Free ? Resources.Wolne : GetParkingParkingSpotsViewStatus.Unavailable ? Resources.Niedostepne : Resources.Nieaktywne}
                            flexGrow={'1'}
                        />
                        <ParkingSpotRealTimeOccupancyPresenter
                            spotRealTimeOccupancyStatus={item.realTimeOccupancyStatus}/>
                    </Stack>)
                },
                {
                    label: Resources.Akcje,
                    renderCell: (item) => (<ActionColumn>
                            <ActionIcon
                                icon={faTrashAlt}
                                title={Resources.Usun}
                                onClick={() => onRemoveClicked(item)}
                            />
                            <ActionIcon
                                icon={faEdit}
                                title={Resources.Edytuj}
                                onClick={() => onEdit(item)}
                            />
                            <ActionIcon
                                icon={faEye}
                                title={Resources.Szczegoly}
                                onClick={() => onDetails(item)}
                            />
                            <ActionIcon
                                icon={faCalendarAlt}
                                title={Resources.Kalendarz}
                                to={`/parkingmanagement/timeline/${item.id}/${ParkingSpotNumberPresenterUtils.formatSpotNumber(
                                    {
                                        spotNumber: item.number,
                                        level: item.level,
                                        sector: item.sector
                                    })}`}
                            />
                            <ActionIcon
                                icon={faPowerOff}
                                title={(() => getActivationDeactivationTitle(item))()}
                                onClick={() => onToggleActivation(item)}
                            />
                            <ActionIcon
                                icon={item.isAssignedPermanently ? faUsers : faUser}
                                title={item.isAssignedPermanently ? Resources.Zwolnij_do_puli_miejsc : Resources.Przypisz_na_stale_do_uzytkownika}
                                onClick={() => onTogglePermanentAssignment(item)}
                            />
                            {item.allowLicensePlatesToSpotAssignment && (<ActionIcon
                                icon={faCar}
                                title={Resources.Przypisz_numery_rejestracyjne}
                                onClick={() => {
                                    setSelectedSpotId(item.id);
                                    setSelectedSpotAssignedLicensePlates(item.assignedLicensePlates);
                                    setAssignLicensePlatesToSpotFormVisible(true);
                                }}
                            />)}
                        </ActionColumn>

                    ),
                    width: "231",
                },
            ]}
        />
    </>);
};

const mapStateToProps = (state: applicationState): Props => ({
    jwt: state.user.token,
    applicationMode: state.user.applicationMode,
});

export default connect(mapStateToProps)(Container);
