import {
  CreateExternalReservationDto,
  ErrorCode,
  ExternalReservationsService,
  ManagerRegisterExternalReservationDto,
  ParkingSpotsAvailibilityEntry,
  PaymentMethod,
  ServiceConfig,
  SortOrder,
  SpotSelectorType,
  TariffServiceType,
  TariffsService,
} from "parkcash-api";
import { addMinutes, adjustToGraduation } from "../utils/DateTimeUtils";
import {
  getAcceptRegulationsValidationSchema,
  getInvoiceData,
  getInvoiceValidationSchema,
  getTargetData,
  getTargetValidationSchema,
} from "./CommonUtils";
import ExternalReservationFormState, {
  AdminExternalReservationFormState,
  ExternalReservationSpecyficFormState,
} from "./formInterfaces/ExternalReservationFormState";
import { InvoiceType } from "./formInterfaces/InvoiceFormState";
import * as yup from "yup";
import Resources from "../../Resources";
import { FilterOperator } from "../utils/DataSource";
import { getFilters, getSorts } from "../utils/SieveUtils";
import {
  CURRENCY_REGEX,
  EMAIL_OR_EMPTY_REGEX,
  EMAIL_REGEX,
  PHONE_OR_EMPTY_REGEX,
} from "../utils/Constants";
import { parseFloatString } from "../utils/NumberUtils";
import ConfirmModal from "../components/ConfirmModal";
import {getErrorMessage, getErrorMessageNew} from "../utils/ErrorUtils";

export const getExternalReservationAvailableSpots = async (
  parkingId: string,
  start: Date,
  end: Date,
  level: string = null,
  sector: string = null
) => {
  try {
    const filter: Array<{
      key: keyof ParkingSpotsAvailibilityEntry;
      operator: FilterOperator;
      value: any;
    }> = [];
    if (level) {
      filter.push({
        key: "level",
        operator: FilterOperator.EQUALS,
        value: level,
      });
    }
    if (sector) {
      filter.push({
        key: "sector",
        operator: FilterOperator.EQUALS,
        value: sector,
      });
    }

    const response = await new ExternalReservationsService(
      new ServiceConfig()
    ).getExternalReservationAvailableSpots(
      parkingId,
      start,
      end,
      getFilters(filter),
      getSorts<ParkingSpotsAvailibilityEntry>([
        { key: "number", order: SortOrder.Ascending },
      ]),
      1,
      10000
    );
    if (response.isSuccess) {
      if (!response.result.availableSpots.length) {
        ConfirmModal.show({
          confirmText: "OK",
          text: Resources.Niestety_ale_nie_ma_juz_zadnych_dostepnych_miejsc,
        });
      }
      return { spots: response.result.availableSpots };
    } else {
      return { error: response.error.code };
    }
  } catch {
    return { error: ErrorCode.NetworkError };
  }
};

export const getExternalReservationPrice = async (
  parkingId: string,
  start: Date,
  end: Date,
  spotId: string,
  tarrifId: string
) => {
  try {
    const response = await new ExternalReservationsService(
      new ServiceConfig()
    ).getExternalReservationEffectivePrice(
      parkingId,
      start,
      end,
      tarrifId,
      spotId || undefined
    );
    if (response.isSuccess) {
      return { price: response.result.effectivePrice };
    } else {
      return { error: response.error.code };
    }
  } catch {
    return { error: ErrorCode.NetworkError };
  }
};

export const getExternalReservationParkingConfiguration = async (
  parkingId: string
) => {
  try {
    const response = await new ExternalReservationsService(
      new ServiceConfig()
    ).getExternalReservationParkingConfiguration(parkingId);
    if (response.isSuccess) {
      return { result: response.result };
    } else {
      return { error: response.error.code };
    }
  } catch {
    return { error: ErrorCode.NetworkError };
  }
};

export const getExternalReservationParkingTarrifs = async (
  parkingId: string,
  spotId: string
) => {
  try {
    const response = await new TariffsService(
      new ServiceConfig()
    ).getEffectiveTariffs(
      parkingId,
      spotId || null,
      TariffServiceType.ExternalReservation,
      false
    );
    if (response.isSuccess) {
      return { result: response.result.entries };
    } else {
      return { error: response.error.code };
    }
  } catch {
    return { error: ErrorCode.NetworkError };
  }
};

export const checkExternalReservationSpotAvailable = async (
  state: ExternalReservationSpecyficFormState
) => {
  try {
    if (!state.enableParticularSpotPurchase) {
      return { available: true };
    }
    const response = await new ExternalReservationsService(
      new ServiceConfig()
    ).checkExternalReservationSpotsAvailable(
      state.parkingId,
      state.spotId,
      state.start,
      state.end
    );
    if (response.isSuccess) {
      const { spotsAvailable } = response.result;
      return { available: spotsAvailable };
    } else {
      return { error: response.error.code };
    }
  } catch {
    return { error: ErrorCode.NetworkError };
  }
};

export const getExternalReservationPurchaseStatus = async (
  reservationId: string
) => {
  try {
    const response = await new ExternalReservationsService(
      new ServiceConfig()
    ).getExternalReservationPurchaseStatus(reservationId);
    if (response.isSuccess) {
      const { partnerWebsiteUrl, status } = response.result;
      return { partnerWebsiteUrl, status };
    } else {
      return { error: response.error.code };
    }
  } catch {
    return { error: ErrorCode.NetworkError };
  }
};

export const createExternalReservation = async (state: ExternalReservationFormState, blikCode: string) => {
  try {
    const { start, end, spotId, parkingId, tariffId: tarrifId } = state;
    const { email, phoneNumber, licencePlateNumber, name } =
      getTargetData(state);
    const response = await new ExternalReservationsService(
      new ServiceConfig()
    ).createExternalReservation(
      new CreateExternalReservationDto({
        start,
        end,
        invoiceData: getInvoiceData(state),
        selectedParkingSpotId: state.enableParticularSpotPurchase
          ? spotId
          : undefined,
        parkingId,
        email,
        licencePlateNumber,
        phoneNumber,
        targetName: name,
        selectedTariffId: tarrifId,
        blikCode:blikCode
      })
    );
    if (response.isSuccess) {
      const { webPaymentUrl } = response.result;
      return { webPaymentUrl };
    } else {
      return {
        isBlikError:response.error.code === ErrorCode.BlikError,
        message: getErrorMessageNew(response.error)
      };
    }
  } catch {
    return { message: getErrorMessage(ErrorCode.NetworkError) };
  }
};

export const managerCreateExternalReservation = async (
  state: AdminExternalReservationFormState,
  jwt: string
) => {
  try {
    const {
      start,
      end,
      spotId,
      parkingId,
      externalPaymentDocumentNumber,
      customPrice,
      paymentMethod,
      tariffId: tarrifId,
    } = state;
    const { email, phoneNumber, licencePlateNumber, name } =
      getTargetData(state);
    const response = await new ExternalReservationsService(
      new ServiceConfig({ jwt })
    ).managerRegisterExternalReservation(
      new ManagerRegisterExternalReservationDto({
        start,
        end,
        invoiceData: getInvoiceData(state),
        selectedParkingSpotId: state.enableParticularSpotPurchase
          ? spotId
          : undefined,
        parkingId,
        email,
        licencePlateNumber,
        phoneNumber,
        targetName: name,
        externalPaymentDocumentNumber:
          externalPaymentDocumentNumber || undefined,
        customPrice: parseFloatString(customPrice) || undefined,
        paymentMethod,
        selectedTariffId: tarrifId,
      })
    );
    if (response.isSuccess) {
      return { success: true };
    } else {
      return { error: response.error.code };
    }
  } catch {
    return { error: ErrorCode.NetworkError };
  }
};

export const getExternalReservationInitialFormState = (
  enableParticularSpotPurchase: boolean,
  spotSelectorType: SpotSelectorType,
  parkingId: string,
  licencePlateNumberRequired: boolean
): ExternalReservationFormState => {
  return {
    ...getAdminExternalReservationInitialFormState(
      enableParticularSpotPurchase,
      spotSelectorType,
      parkingId,
      licencePlateNumberRequired,
      null
    ),
    regulationsAccepted: false,
  };
};

export const getAdminExternalReservationInitialFormState = (
  enableParticularSpotPurchase: boolean,
  spotSelectorType: SpotSelectorType,
  parkingId: string,
  licencePlateNumberRequired: boolean,
  spotId: string
): AdminExternalReservationFormState => {
  return {
    city: "",
    wantInvoice: false,
    contrahent: "",
    email: "",
    enableParticularSpotPurchase,
    start: adjustToGraduation(new Date(), 15),
    end: addMinutes(adjustToGraduation(new Date(), 15), 60),
    licencePlateNumber: "",
    nameAndSurname: "",
    nip: "",
    spotSelectorType,
    parkingId,
    phoneNumber: "48",
    postalCode: "",
    spotId,
    flatNumber: "",
    streetName: "",
    streetNumber: "",
    type: InvoiceType.Company,
    currentPrice: null,
    licencePlateNumberRequired,
    currentPriceProgress: false,
    externalPaymentDocumentNumber: "",
    customPrice: "",
    paymentMethod: PaymentMethod.CreditCard,
    tariffId: null,
    tariffs: [],
  };
};

export const getExternalReservationValidationSchema = () => {
  return yup.object({
    ...getInvoiceValidationSchema(),
    ...getTargetValidationSchema(),
    ...getAcceptRegulationsValidationSchema(),
    spotId: yup
      .string()
      .notRequired()
      .nullable()
      .when("enableParticularSpotPurchase", {
        is: true,
        then: yup.string().nullable().required(Resources.Wymagane),
      }),
    start: yup.date().required(Resources.Wymagane),
    end: yup.date().required(Resources.Wymagane),
    licencePlateNumber: yup
      .string()
      .notRequired()
      .nullable()
      .when("licencePlateNumberRequired", {
        is: true,
        then: yup.string().required(Resources.Wymagane),
      }),
    email: yup
      .string()
      .nullable()
      .matches(EMAIL_OR_EMPTY_REGEX, Resources.Nieprawidlowy_format)
      .when("phoneNumber", {
        is: (phone) => !phone || phone.length,
        then: yup
          .string()
          .matches(EMAIL_REGEX, Resources.Nieprawidlowy_format)
          .required(Resources.Wymagane_jest_podanie_emaila_lub_telefonu),
      }),
    phoneNumber: yup
      .string()
      .nullable()
      .matches(PHONE_OR_EMPTY_REGEX, Resources.Nieprawidlowy_format),
  });
};

export const getAdminExternalReservationValidationSchema = () => {
  return yup.object({
    ...getInvoiceValidationSchema(),
    ...getTargetValidationSchema(),
    spotId: yup
      .string()
      .notRequired()
      .nullable()
      .when("enableParticularSpotPurchase", {
        is: true,
        then: yup.string().nullable().required(Resources.Wymagane),
      }),
    start: yup.date().required(Resources.Wymagane),
    end: yup.date().required(Resources.Wymagane),
    email: yup
      .string()
      .nullable()
      .matches(EMAIL_OR_EMPTY_REGEX, Resources.Nieprawidlowy_format),
    phoneNumber: yup
      .string()
      .nullable()
      .matches(PHONE_OR_EMPTY_REGEX, Resources.Nieprawidlowy_format),
    licencePlateNumber: yup
      .string()
      .notRequired()
      .nullable()
      .when("licencePlateNumberRequired", {
        is: true,
        then: yup.string().required(Resources.Wymagane),
      }),
    customPrice: yup
      .string()
      .required(Resources.Wymagane)
      .matches(CURRENCY_REGEX, Resources.Nieprawidlowy_format),
    paymentMethod: yup.number().required(Resources.Wymagane),
  });
};
