export const KRAKOW_LAT = 50.0646501;

export const KRAKOW_LON = 19.9449799;

export default class GeolocationHelper {
    static getLocation(): Promise<{longitude: number, latitude: number}>{
        return new Promise<{longitude: number, latitude: number}>(async (resolve) => {
            let persmissionState: PermissionState = "prompt";
            if(navigator?.permissions?.query){
                persmissionState = (await navigator.permissions.query({name: "geolocation"})).state
            }
            
            if(persmissionState === "denied"){
                resolve({
                    latitude: KRAKOW_LAT,
                    longitude: KRAKOW_LON
                });
            }
            else{
                navigator.geolocation.getCurrentPosition(pos => {
                    resolve({
                        latitude: pos.coords.latitude,
                        longitude: pos.coords.longitude
                    });
                }, () => {
                    resolve({
                        latitude: KRAKOW_LAT,
                        longitude: KRAKOW_LON
                    });
                })
            }
        });

    }
}