import {ISelectedPaymentInfo, PaymentMethodsTypes} from "../otherPages/formParts/PaymentMethod";
import {ErrorCode, PaymentsService, ServiceConfig} from "parkcash-api";
import Resources from "../../Resources";
import {ErrorWrapperOfObject} from "parkcash-api";

export const checkBlikError = (error: ErrorWrapperOfObject, blikErrorHandler: (blikError: string) => void): boolean => {
    if (error.code === ErrorCode.BlikError) {
        blikErrorHandler(error.message)
        return true;
    }
    return false;
}

export const validateBlik = (selectedPaymentMethodInfo: ISelectedPaymentInfo, blikErrorHandler: (blikError: string) => void): boolean => {
    if (selectedPaymentMethodInfo?.selectedMethod === "BlikInPlace" && !selectedPaymentMethodInfo?.isBlikCodeValid) {
        blikErrorHandler(Resources.Niepoprawny_kod_blik)
        return false;
    }
    return true;
}

export const getWebPaymentConfiguration = async (parkingId: string): Promise<PaymentMethodsTypes[]> => {
    try {
        const {
            isSuccess,
            result
        } = await new PaymentsService(new ServiceConfig()).getWebPaymentConfiguration(parkingId)

        if (isSuccess) {
            const allowedPaymentMethods: PaymentMethodsTypes[] = [];
            if (result.isInPlaceBlikEnabled) allowedPaymentMethods.push("BlikInPlace");
            if (result.isFastPaymentEnabled) allowedPaymentMethods.push("FastPayments");

            return allowedPaymentMethods;
        }
        return [];
    } catch {
        return [];
    }
};