import Enumerable from "linq";
import GeolocationHelper from "./GeolocationHelper";
import CancelablePromise from "./CancelablePromise";
import {GOOGLE_API_KEY} from "./Constants";
import LatLng = google.maps.LatLng;
import GeocoderStatus = google.maps.GeocoderStatus;
import GeocoderResult = google.maps.GeocoderResult;
import AutocompleteService = google.maps.places.AutocompleteService;
import Geocoder = google.maps.Geocoder;
import AutocompleteSessionToken = google.maps.places.AutocompleteSessionToken;
import PlacesServiceStatus = google.maps.places.PlacesServiceStatus;

export interface userAddress {
    country: string, // added
    city: string,
    streetName: string,
    streetNumber: string,
    lon: number,
    lat: number
}

export interface searchLocation {
    address: string,
    place_id: string
}

const autocompleteService = new AutocompleteService();
const geocoder = new Geocoder;

export default class GoogleMapsApiManager {

    public static async GetAddressFromCoordinates(lat: number, lon: number): Promise<userAddress> {
        try {
            const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${GOOGLE_API_KEY}`;
            const json = await fetch(url);
            const response: any = await json.json();
            const {
                results,
                status
            } = response;
            if (status === "OK") {
                const result = results[0];
                return this.GetCoordinatesCore(result);
            } else {
                return null;
            }
        } catch (e) {
            const error = e;
            return null;
        }
    }

    public static SearchForPlaces(search: string, sessiontoken: AutocompleteSessionToken): CancelablePromise<searchLocation[]> {
        return new CancelablePromise((res, rej) => {
            this.SearchForPlacesCore(search, sessiontoken)
                .then(l => {
                    res(l);
                })
                .catch(err => {
                    rej(err);
                })
        });
    }

    public static async GetCoordinatesForPlaceId(place_id: string, addressAlphaNumeralPart?: string): Promise<userAddress> {
        return new Promise((resolve, reject) => geocoder.geocode({
            placeId: place_id
        }, (results, status) => {
            if (status === GeocoderStatus.OK) {
                resolve(this.GetCoordinatesCore(results[0], addressAlphaNumeralPart));
            } else reject(null);
        }))
    }

    public static async GetCoordinatesForAddressString(address: string): Promise<userAddress> {
        return new Promise((resolve, reject) => geocoder.geocode({
            address: address,

            componentRestrictions: {}
        }, (results, status) => {
            if (status === GeocoderStatus.OK) {
                resolve(this.GetCoordinatesCore(results[0], this.GetStreetNumberDetailPart(address)));
            } else reject(null);
        }))
    }

    public static GetStreetNumberDetailPart(streetFullName: string): string {
        const streetNameAndNumber = streetFullName.split(',')[0];
        const streetNumberSlashSplit = streetNameAndNumber.split('/');
        const streetNumberContainsSlash = streetNumberSlashSplit.length > 1;
        // case e.g Karmelicka 1/30
        if (streetNumberContainsSlash) {
            return streetNumberSlashSplit[streetNumberSlashSplit.length - 1];
        }
        // case e.g Karmelicka 1a
        const lastChar = streetNameAndNumber[streetNameAndNumber.length - 1];
        if (isNaN(Number(lastChar))) {
            return lastChar;
        }

    }

    private static async SearchForPlacesCore(search: string, sessiontoken: AutocompleteSessionToken): Promise<searchLocation[]> {
        if (!search) {
            return [];
        }
        const {
            longitude,
            latitude
        } = await GeolocationHelper.getLocation();

        return new Promise((resolve, reject) => autocompleteService.getPlacePredictions({
            input: search,
            sessionToken: sessiontoken,
            radius: 50000,
            location: new LatLng(latitude, longitude)
        }, (p, status) => {
            if (status === PlacesServiceStatus.OK) {
                resolve(p.map<searchLocation>(p => ({
                    address: p.description,
                    place_id: p.place_id
                })))
            } else {
                reject([]);
            }
        }))
    }

    private static GetCoordinatesCore(result: GeocoderResult, streetNumberDetailPart?: string): userAddress {
        const location = result.geometry.location;
        const foundCity = Enumerable.from(result.address_components)
                                    .firstOrDefault(c => c.types.indexOf("locality") > -1);
        const foundStreetNumber = Enumerable.from(result.address_components)
                                            .firstOrDefault(c => c.types.indexOf("street_number") > -1);
        const foundStreet = Enumerable.from(result.address_components)
                                      .firstOrDefault(c => c.types.indexOf("route") > -1);
        const foundCountry = Enumerable.from(result.address_components)
                                       .firstOrDefault(c => c.types.indexOf("country") > -1);
        const lat: any = typeof location.lat === "number" ? location.lat : location.lat();
        const lon: any = typeof location.lng === "number" ? location.lng : location.lng();
        let streetNumberDisplay = foundStreetNumber && foundStreetNumber.long_name;
        const dontDisplayAdditionalPart = streetNumberDisplay && (streetNumberDisplay?.includes('/') || (isNaN(Number(
            streetNumberDisplay[streetNumberDisplay?.length - 1]))));
        if (streetNumberDisplay && streetNumberDetailPart && !dontDisplayAdditionalPart) {
            if (!isNaN(Number(streetNumberDetailPart))) {
                // case e.g Karmelicka 1/30
                streetNumberDisplay = streetNumberDisplay + '/' + streetNumberDetailPart;
            } else {
                // case e.g Karmelicka 1a
                streetNumberDetailPart
                streetNumberDisplay = streetNumberDisplay + streetNumberDetailPart;
            }
        }
        return {
            country: foundCountry && foundCountry.long_name,
            city: foundCity && foundCity.long_name,
            lat,
            lon,
            streetName: foundStreet && foundStreet.long_name,
            streetNumber: streetNumberDisplay
        };
    }
}