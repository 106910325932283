import * as React from "react";
import HomeButton from "../Common/HomeButton";
import Links from "./Links";
import UserDropdown from "../Common/UserDropdown";
import DesktopHeaderBase, {DESKTOP_HEADER_DEFAULT_HEIGHT} from "../../../Common/DesktopHeaderBase";
import LanguageSelector from "../../../../components/LanguageSelector/LanguageSelector";


export const DESKTOPHEADER_TOP_HEIGHT = DESKTOP_HEADER_DEFAULT_HEIGHT;
export const DESKTOPHEADER_SCROLLED_HEIGHT = 70;


export const DesktopHeader = (props: { scrolled: boolean }) => {
    const {scrolled} = props;
    return (

        <DesktopHeaderBase height={scrolled ? DESKTOPHEADER_SCROLLED_HEIGHT : DESKTOPHEADER_TOP_HEIGHT}>
            <HomeButton scrolled={scrolled}/>
            <Links scrolled={scrolled}/>
            <LanguageSelector className={"language-selector language-selector-desktop"} updateServerCulture={true}/>
            <UserDropdown scrolled={scrolled}/>
        </DesktopHeaderBase>
    );
}