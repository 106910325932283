import { faClock, faParking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isSameMonth } from "date-fns";
import { FormikProps } from "formik";
import memoizee from "memoizee";
import { GetSubscriptionProlongationInfoResult, SubscriptionPeriodType } from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import { formatSpot } from "../../components/ParkingSpotNumberPresenter";
import { validateWithUser } from "../../parkingSpots/ParkingSpotForm/Utils";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { addDays, formatDate, formatDateTime, formatTime, monthsMap } from "../../utils/DateTimeUtils";
import { getErrorMessage } from "../../utils/ErrorUtils";
import Notify from "../../utils/Notify";
import { ProlongSubscriptionSpecyficFormState } from "../ProlongSubscription/ProlongSubscriptionFormState";
import { getSubscriptionEffectivePrice, getTimeRangeForSubcriptionProlongation } from "../SubscriptionsUtils";
import FormLabel from "../../components/FormLabel";

const getAvailableNumberOfPeriods = memoizee((n: number) => {
    const result: {id: number, text: string}[] = [];
    for(let i = 1; i <= n; i++){
        result.push({id: i, text: i.toString()});
    }
    return result;
})

export const SubscriptionProlongationSpotInfo = (props: {
    width?: number | string,
    level: string,
    spotNumber: string,
    sector: string
}) => {
    const {level, spotNumber, width = '100%', sector} = props;

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width,
                height: 58,
                boxSizing: "border-box",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: Colors.light_royal_blue,
                borderRadius: 27,
                backgroundColor: Colors.white
            }}
        >
            <FontAwesomeIcon icon={faParking} style={{color: Colors.light_royal_blue, fontSize: 34}} />
            <BaseSpaceSeparator size={10} />
            <PCText semibold fontSize={16} color={Colors.light_royal_blue}>
                {formatSpot({level, spotNumber, sector})}
            </PCText>
        </div>
    )
}

export const SubscriptionTimeRangeInfo = (props: {
    validFrom: Date,
    validTo: Date,
    width?: string | number,
    periodType: SubscriptionPeriodType
}) => {
    const {validFrom, validTo, width = '100%', periodType} = props;

    const isOnlyOneMonth = periodType === SubscriptionPeriodType.GivenFullCalendarMonth && (!validFrom || isSameMonth(validFrom, validTo));

    const formatTimeElement = (d: Date, pT: SubscriptionPeriodType) => {
        if(!d){
            return "-"
        }
        else if(pT === SubscriptionPeriodType.GivenFullCalendarMonth){
            return `${monthsMap()[d.getMonth()]} ${d.getFullYear()}`;
        }
        else{
            return (
                <>
                    {formatDate(d)}
                    <br />
                    {formatTime(d)}
                </>
            )
        }
    }

    return (
        <div
            style={{
                width,
                height: 70,
                boxSizing: "border-box",
                backgroundColor: Colors.pale_grey_two,
                borderRadius: 27,
                display: 'flex',
                alignItems: 'center',
                justifyContent: isOnlyOneMonth ? 'center' : 'space-between',
                padding: "0px 20px"
            }}
        >
            {isOnlyOneMonth && (
                <>
                    <FontAwesomeIcon icon={faClock} style={{fontSize: 27, color: Colors.brownish_grey}} />
                    <BaseSpaceSeparator size={10} />
                    <div>
                        <PCText color={Colors.brownish_grey} fontSize={15}>
                            {Resources.Okres_trwania}
                        </PCText>
                        <BaseSpaceSeparator size={5} />
                        <PCText color={Colors.black} fontSize={15} semibold>
                            {formatTimeElement(validFrom, periodType)}
                        </PCText>
                    </div>
                </>
            )}
            {!isOnlyOneMonth &&(
                <>
                    <div>
                        <PCText color={Colors.brownish_grey} fontSize={15}>
                            {Resources.Poczatek}
                        </PCText>
                        <BaseSpaceSeparator size={5} />
                        <PCText color={Colors.black} fontSize={15} semibold>
                            {formatTimeElement(validFrom, periodType)}
                        </PCText>
                    </div>
                    <FontAwesomeIcon icon={faClock} style={{fontSize: 27, color: Colors.brownish_grey}} />
                    <div>
                        <PCText textAlign="right" color={Colors.brownish_grey} fontSize={15}>
                            {Resources.Koniec}
                        </PCText>
                        <BaseSpaceSeparator size={5} />
                        <PCText textAlign="right" color={Colors.black} fontSize={15} semibold>
                            {formatTimeElement(validTo, periodType)}
                        </PCText>
                    </div>
                </>
            )}
        </div>
    );
}

export default (props: {
    args: FormikProps<ProlongSubscriptionSpecyficFormState>,
    isMobile: boolean,
    info: GetSubscriptionProlongationInfoResult
}) => {
    const {args, isMobile, info} = props;
    const {values, setFieldValue, touched, errors, setFieldTouched} = args;
    const {prolongationValidFrom, prolongationValidTo, spotNumber, level} = info;
    const {sector} = (info as any);

    const infoWidth = isMobile ? '100%' : 300;

    

    return (
        <>
            <SubscriptionTimeRangeInfo periodType={info.periodType} validFrom={values.targetFrom} validTo={values.targetTo}/>               
            {!!spotNumber && (
                <>
                    <BaseSpaceSeparator size={20} />
                    <SubscriptionProlongationSpotInfo sector={sector} level={level} spotNumber={spotNumber} width={infoWidth} />
                </>
            )}       
            <BaseSpaceSeparator size={20} />
            <FormLabel>{info.periodType === SubscriptionPeriodType.NumberOfDays ? Resources.Liczba_okresow : Resources.Liczba_miesiecy}</FormLabel>
            <BaseSpaceSeparator size={5} />
            <div style={{width: 315}}>
                <RegularDropdownList 
                    maxContentHeight={250}
                    actions={getAvailableNumberOfPeriods(10)}
                    error={errors.numberOfPeriods}
                    name="numberOfPeriods"
                    touched={touched.numberOfPeriods}
                    value={values.numberOfPeriods}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    borderColor={Colors.brownish_grey}
                />
            </div>
        </>
    );
}