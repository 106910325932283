export default {
    white: '#ffffff',
    light_blue: '#8a9aff',
    light_royal_blue: '#334fff',
    saphire: '#1f32a9',
    heliotrope: '#b135cc',
    very_light_pink_two: '#efefef',
    brownish_grey: '#6c6c6c',
    greyLight: '#d7d7d7',
    very_light_pink: '#d8d8d8',
    greyInactive: '#e5e5e5',
    greyInactiveText: '#919191',
    greyVeryLight: '#d8d8d8',
    black: '#000000',
    greyishBrown: '#424242',
    green: "#2AB518",
    red: '#b00020',
    darkOverlay: 'rgba(0,0,0,0.7)',
    mango: "#ff9e33",
    shadow: "rgba(0, 0, 0, 0.08)",
    datePickerSelected: '#f1f3f9',
    very_light_grey: '#f4f4f4',
    pale_grey: "#dadfea",
    blue_grey: '#8c97b2',
    pale_grey_two: '#f1f3f9'
}