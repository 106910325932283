import { FormikProps } from "formik";
import * as React from "react";
import Resources from "../../../Resources";
import { TextButton } from "../../styles/Buttons";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import AcceptRegulationsFormState from "../formInterfaces/AcceptRegulationsFormState";

export default (props: {
    args: FormikProps<AcceptRegulationsFormState>,
    privacyPolicyUrl: string,
    saleRegulationsUrl: string,
    parkingRegulationsUrl: string
}) => {
    const {args, parkingRegulationsUrl, privacyPolicyUrl, saleRegulationsUrl} = props;
    const {values, setFieldValue, setFieldTouched, errors, touched} = args;

    return (
        <>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <input 
                style={{margin: 0}} 
                type="checkbox" 
                checked={values.regulationsAccepted} 
                onChange={() => {
                    setFieldTouched("regulationsAccepted", true);
                    setFieldValue("regulationsAccepted", !values.regulationsAccepted);
                }}
            />
            <BaseSpaceSeparator size={15} />
            <PCText fontSize={14} color={Colors.brownish_grey}>
                {Resources.Zapoznalem_sie_i_akceptuje} <TextButton underlined target="_blank" href={saleRegulationsUrl} fontSize={14}>{Resources.Regulamin_sprzedazy}</TextButton>, <TextButton underlined target="_blank" href={privacyPolicyUrl} fontSize={14}>{Resources.Polityke_prywatnosci}</TextButton> 
                {!!parkingRegulationsUrl && (
                    <>
                        &nbsp;{Resources.oraz} <TextButton underlined target="_blank" href={parkingRegulationsUrl} fontSize={14}>{Resources.Regulamin_parkingu}</TextButton>
                    </>
                )}             
            </PCText>
        </div>
        <div style={{paddingTop: 4, paddingBottom: 4, height: 20, boxSizing: "border-box"}}>
            <PCText 
                fontSize={12} 
                lineHeight={1} 
                letterSpacing={0} 
                color={Colors.red}
            >
                {!!errors.regulationsAccepted && !!touched.regulationsAccepted ? Resources.Wyrazenie_zgody_wymagane : ""}
            </PCText>
        </div>
        </>
    )
}