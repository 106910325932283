import {
  faArrowDown,
  faCaretLeft,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorCode } from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { formatDateTime } from "../../utils/DateTimeUtils";
import Dropdown from "../Dropdown";
import PageSizeDropdown from "./PageSizeDropdown";
import { Column } from "./Column";
import { BORDER_COLOR, DEFAULT_AVAILABLE_PAGE_SIZES } from "./Constants";
import InfoRow from "./InfoRow";
import { renderCell } from "./Utils";
import Pager from "./Pager";
import { getErrorMessage } from "../../utils/ErrorUtils";

export default class TableBody<T> extends React.Component<{
  columns: Array<Column<T>>;
  disablePaging: boolean;
  availablePageSizes: number[];
  progress: boolean;
  error: ErrorCode;
  items: T[];
  pageSize: number;
  page: number;
  totalItems: number;
  onPageSizeChanged: (p: number) => void;
  onPageChanged: (p: number) => void;
  additionalRows: T[];
  AdditionalRowComponent: React.ComponentType<{ item: T }>;
}> {
  render() {
    const {
      columns,
      disablePaging,
      availablePageSizes = DEFAULT_AVAILABLE_PAGE_SIZES,
      progress,
      error,
      items,
      pageSize,
      page,
      totalItems,
      additionalRows,
      AdditionalRowComponent = () => null,
    } = this.props;
    return (
      <tbody className="pc-datagrid-table-body">
        {items.map((item, rowIndex, arr) => {
          const hasAdditionalRow = additionalRows.indexOf(item) >= 0;
          return (
            <React.Fragment key={rowIndex}>
              <tr className="pc-datagrid-row" key={rowIndex}>
                {columns.map((column, i, a) => {
                  const isFirstColumn = i === 0;
                  const isLastColumn = i === a.length - 1;
                  const isFirstRow = rowIndex === 0;
                  const isLastRow = rowIndex === arr.length - 1;
                  return (
                    <td key={i}>
                      <div
                        key={i}
                        style={{
                          paddingLeft: isFirstColumn ? 30 : 0,
                          paddingRight: isLastColumn ? 30 : 0,
                          paddingTop: isFirstRow ? 35 : 0,
                          paddingBottom: isLastRow ? 30 : 0,
                          backgroundColor: Colors.white,
                          borderBottomLeftRadius:
                            isLastRow && isFirstColumn ? 10 : 0,
                          borderBottomRightRadius:
                            isLastRow && isLastColumn ? 10 : 0,
                        }}
                      >
                        <div
                          style={{
                            minHeight: 56,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            paddingRight: 15,
                            borderTop: `1px solid ${
                              !isFirstRow ? BORDER_COLOR : "transparent"
                            }`,
                          }}
                        >
                          {renderCell(item, hasAdditionalRow, rowIndex, column)}
                        </div>
                      </div>
                    </td>
                  );
                })}
              </tr>
              {hasAdditionalRow && (
                <tr>
                  <td colSpan={columns.length}>
                    <AdditionalRowComponent item={item} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
        {!disablePaging && !!items.length && !error && (
          <tr>
            <td colSpan={columns.length}>
              <div
                style={{
                  minHeight: 50,
                  backgroundColor: Colors.white,
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                  paddingLeft: 30,
                  paddingRight: 30,
                  display: "flex",
                  alignItems: "center",
                  overflow: "visible",
                }}
              >
                <PageSizeDropdown
                  availablePageSizes={availablePageSizes}
                  onPageSizeChanged={this.props.onPageSizeChanged}
                  pageSize={pageSize}
                />
                <div style={{ flex: 1 }}></div>
                <Pager
                  onPageChanged={this.props.onPageChanged}
                  page={page}
                  pageSize={pageSize}
                  totalItems={totalItems}
                />
                <div style={{ flex: 1 }}></div>
              </div>
            </td>
          </tr>
        )}
        {!items.length && !progress && !error && (
          <InfoRow color={Colors.black} colSpan={columns.length}>
            {Resources.Brak_danych}
          </InfoRow>
        )}
        {!items.length && !progress && error && (
          <InfoRow color={Colors.red} colSpan={columns.length}>
            {getErrorMessage(error)}
          </InfoRow>
        )}
      </tbody>
    );
  }
}
