import { Formik, FormikHelpers } from "formik";
import * as React from "react";
import Resources from "../../Resources";
import { PASSWORD_REGEX } from "../utils/Constants";
import * as yup from "yup";
import { PCText } from "../styles/Texts";
import { StandardButton } from "../styles/Buttons";
import Colors from "../styles/Colors";
import RegularInput from "../components/forms/RegularInput";
import { Redirect, useParams } from "react-router-dom";
import { ErrorCode, PasswordResetDto, ServiceConfig, SessionsService } from "parkcash-api";
import Notify from "../utils/Notify";
import { BaseSpaceSeparator } from "../styles/Separators";
import { getErrorMessage } from "../utils/ErrorUtils";
import {Asserts} from "yup";

const validationSchema = yup.object({
    code: yup.string().required(Resources.Wymagane),
    password: yup.string()
                 .required(Resources.Wymagane)
                 .matches(PASSWORD_REGEX, Resources.Haslo_musi_zawierac_co_najmniej_7_znakow)
});

interface FormState extends Asserts<typeof validationSchema> {}

export default () => {
    const [redirect,setRedirect] = React.useState(false);
    const {userId} = useParams<{userId: string}>();
   

    const initialValues: FormState = {code: "", password: ""};

    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {
        const {code, password} = state;
        const {setStatus, setSubmitting} = helpers;
        try{
            setSubmitting(true);
            const response = await new SessionsService(new ServiceConfig()).resetPassword(new PasswordResetDto({
                newPassword: password,
                resetCode: code,
                userId: userId
            }));
            if(response.isSuccess){
                Notify.Success(Resources.Haslo_zostalo_zresetowane);
                setRedirect(true);
            }
            else{
                setStatus(getErrorMessage(response.error.code));
            }
        }
        catch{
            setStatus(getErrorMessage(ErrorCode.NetworkError));
        }
        finally{
            setSubmitting(false);
        }
    }

    if(redirect){
        return <Redirect to="/login" />;
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {args => {
                const {values,errors,touched,setFieldTouched,setFieldValue,status,isSubmitting, handleSubmit} = args;
                return (
                    <form
                        noValidate={true}
                        autoComplete="off"
                        onSubmit={handleSubmit}
                        style={{alignSelf: 'stretch'}}
                    >
                        <RegularInput 
                            label={Resources.Kod}
                            name="code"
                            variant="big"
                            value={values.code}
                            error={errors.code}
                            touched={touched.code}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            showClearButton
                            showValidatedButton
                        />
                        <RegularInput 
                            label={Resources.Nowe_haslo}
                            name="password"
                            variant="big"
                            value={values.password}
                            error={errors.password}
                            touched={touched.password}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            password
                        />
                        <div style={{height: 50, display: 'flex', flexDirection: 'column', justifyContent: "flex-end", alignItems: 'center'}}>
                            <PCText fontSize={12} letterSpacing={0} color={Colors.red}>{status || ""}</PCText>
                            <BaseSpaceSeparator size={10} />
                        </div>


                        <StandardButton variant="big" progress={isSubmitting} type="submit" tabIndex={4}>{Resources.Ustaw_nowe_haslo}</StandardButton>
                    </form>
                );
            }}
        </Formik>
    );
}