import React from 'react';
import Grid from "@mui/material/Unstable_Grid2";

import Resources from "../../../../../../Resources";

import RadialBarComparisonChart
    from "../../../../../MUI/components/AddedOrModyfiedMuiComponents/Charts/RadialBarComparisonChart";
import WidGetSummariesWithCircularProgress from '../../../../../MUI/components/AddedOrModyfiedMuiComponents/Widgets/WidGetSummariesWithCircularProgress';
import MultiSeriesChartWithBarAndLines
    from "../../../../../MUI/components/AddedOrModyfiedMuiComponents/Charts/MultiSeriesChart";

interface ParkingSpotSectionProps {
}

export default function ParkingSpotSection(props: ParkingSpotSectionProps) {

    return (<Grid container spacing={3} xs={12} id={'ParkingSpotSection'}>

        <Grid xs={12} sm={4}>
            <RadialBarComparisonChart
                title={Resources.Miejsca}
                highlightedLabelName={Resources.Udostepnione}
                unit={Resources.Miejsc}
                chart={{
                    series: [
                        {
                            label: Resources.Udostepnione,
                            value: 120
                        },
                        {
                            label: Resources.Nie_udostepnione,
                            value: 66
                        }
                    ]
                }}
            />
        </Grid>
        <Grid xs={12} sm={8}>
            <MultiSeriesChartWithBarAndLines
                title={String(Resources.p_zajetosci)}
                chart={{
                    labels: [
                        '01/01/2003',
                        '02/01/2003',
                        '03/01/2003',
                        '04/01/2003',
                        '05/01/2003',
                        '06/01/2003',
                        '07/01/2003',
                        '08/01/2003',
                        '09/01/2003',
                        '10/01/2003',
                        '11/01/2003',
                    ],
                    series: [
                        {
                            name: Resources.Przychod,
                            type: 'column',
                            fill: 'solid',
                            yAxisunit: 'PLN',
                            separateYAxis: true,
                            data: [
                                23,
                                11,
                                22,
                                27,
                                13,
                                22,
                                37,
                                21,
                                44,
                                22,
                                30
                            ],
                        },
                        {
                            name: Resources.Ilosc_transakcji,
                            type: 'area',
                            fill: 'gradient',
                            data: [
                                44,
                                55,
                                41,
                                67,
                                22,
                                43,
                                21,
                                41,
                                56,
                                27,
                                43
                            ],
                        },
                        {
                            name: Resources.Ilosc_zwrotow,
                            type: 'line',
                            fill: 'solid',
                            data: [
                                30,
                                25,
                                36,
                                30,
                                45,
                                35,
                                64,
                                52,
                                59,
                                36,
                                39
                            ],

                        },
                    ],
                }}

            />
        </Grid>
        <Grid xs={12}>
            <WidGetSummariesWithCircularProgress
                chart={{
                    series: [
                        {
                            label: Resources.Zarezerwowane,
                            percent: 72,
                            total: 18472
                        },
                        {
                            label: Resources.Przypisane_na_stale,
                            percent: 64,
                            total: 18472
                        },
                        {
                            label: Resources.Wolne,
                            percent: 64,
                            total: 18472
                        },
                    ],
                }}
            />
        </Grid>
    </Grid>)
}