import React from 'react';
import {ApexOptions} from 'apexcharts';
import sumBy from 'lodash/sumBy';
// @mui
import {alpha, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import Card, {CardProps} from '@mui/material/Card';
// utils
import {fNumber} from '../../../../MUI/utils/format-number';
// components
import Chart, {useChart} from '../../../../MUI/components/chart';

// ----------------------------------------------------------------------

type ItemProps = {
    label: string; value: number;

};

interface Props extends CardProps {
    title?: string;
    subheader?: string;
    highlightedLabelName: string;
    unit: string;
    chart: {
        colors?: string[]; series: ItemProps[]; options?: ApexOptions;
    };
}

export default function RadialBarComparisonChart({
                                                     title,
                                                     subheader,
                                                     highlightedLabelName,
                                                     chart,
                                                     unit,
                                                     ...other
                                                 }: Props) {
    const theme = useTheme();

    const {
        colors = [
            theme.palette.primary.light,
            theme.palette.primary.main
        ],
        series,
        options,
    } = chart;

    const total = sumBy(series, 'value');

    const chartSeries = (series.filter((i) => i.label === highlightedLabelName)[0].value / total) * 100;

    const chartOptions = useChart({
        legend: {
            show: false,
        },
        grid: {
            padding: {
                top: -32,
                bottom: -32
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                colorStops: [
                    {
                        offset: 0,
                        color: colors[0],
                        opacity: 1,
                    },
                    {
                        offset: 100,
                        color: colors[1],
                        opacity: 1,
                    },
                ],
            },
        },
        plotOptions: {
            radialBar: {
                hollow: {size: '64%'},
                dataLabels: {
                    name: {offsetY: -16},
                    value: {offsetY: 8},
                    total: {
                        label: title,
                        formatter: () => fNumber(total),
                    },
                },
            },
        }, ...options,
    });

    return (<Card {...other}>
        <CardHeader title={title} subheader={subheader} sx={{mb: 8}}/>

        <Chart type="radialBar" series={[chartSeries]} options={chartOptions} height={310}/>

        <Stack spacing={2} sx={{p: 5}}>
            {series.map((item) => (<Stack
                key={item.label}
                spacing={1}
                direction="row"
                alignItems="center"
                sx={{
                    typography: 'subtitle2',
                }}
            >
                <Box
                    sx={{
                        width: 16,
                        height: 16,
                        bgcolor: alpha(theme.palette.grey[500], 0.16),
                        borderRadius: 0.75, ...(item.label === highlightedLabelName && {
                            bgcolor: colors[1],
                        }),
                    }}
                />
                <Box sx={{
                    color: 'text.secondary',
                    flexGrow: 1
                }}>{item.label}</Box>
                {item.value} {unit}
            </Stack>))}
        </Stack>
    </Card>);
}
