import { ErrorCode, ParkingSpotInfoDto } from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import Resources from "../../Resources";
import DropdownList from "../components/DropdownList";
import { formatSpot } from "../components/ParkingSpotNumberPresenter";
import Body from "../layouts/Main/Body";
import { applicationState } from "../redux/ApplicationState";
import { BaseSpaceSeparator } from "../styles/Separators";
import ParkingSpotsList from "./ParkingSpotsList";
import ParkingSpotTimeline from "./ParkingSpotTimeline";
import { activateParkingSpot, deactivateParkingSpot, getParkingSpots, removeParkingSpot } from "./Redux";
import * as uuid from "uuid";

import "./Styles.scss";


interface Props {
    progress: boolean,
    error: ErrorCode,
    items: ParkingSpotInfoDto[],

    onInit: (refresh: boolean) => void;
    onActivateParkingSpot: (id: string) => void;
    onDeactivateParkingSpot: (id: string) => void;
    onRemoveParkingSpot: (id: string) => void;
}

const Container = (props: Props) => {
    const {error,items,onActivateParkingSpot,onDeactivateParkingSpot,onInit,onRemoveParkingSpot,progress} = props;
    const [currentSpot, setCurrentSpot] = React.useState<string>(null);
    const [key, setKey] = React.useState(uuid.v1());


    React.useEffect(() => {
        onInit(false);
    }, []);

    React.useEffect(() => {
        if(items && items.length && !currentSpot){
            setCurrentSpot(items[0].id);
        }
    }, [items]);

    return (
        <Body 
            title={Resources.Miejsca}
            rigth={!!items && !!items.length ? (
                <div style={{width: 250}}>
                    <DropdownList 
                        maxContentHeight={400}
                        placeholder=""
                        variant="standard"
                        value={currentSpot}
                        onChange={setCurrentSpot}
                        actions={items.map(i => ({text: formatSpot(i), id: i.id}))}
                    />
                </div>
            ): null}
        >
            {!!items && !!items.length && (
                <ParkingSpotTimeline 
                    key={key}
                    spotId={currentSpot}
                />
            )}
            <BaseSpaceSeparator size={50} />
            <ParkingSpotsList 
                error={error}
                progress={progress}
                items={items}
                onActivateParkingSpot={onActivateParkingSpot}
                onDeactivateParkingSpot={onDeactivateParkingSpot}
                onReload={() => onInit(true)}
                onRemoveParkingSpot={onRemoveParkingSpot}
                onShared={() => setKey(uuid.v1())}
            />
        </Body>
    );
}

const mapStateToProps = (state: applicationState): Partial<Props> => ({
    error: state.parkingSpots.gettingParkingSpotsError,
    items: state.parkingSpots.parkingSpots || [],
    progress: state.parkingSpots.gettingParkingSpotsProgress
    
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
    onInit: (refresh) => {
        dispatch(getParkingSpots(refresh))
    },
    onActivateParkingSpot: (id: string) => dispatch(activateParkingSpot(id)),
    onDeactivateParkingSpot: (id: string) => dispatch(deactivateParkingSpot(id)),
    onRemoveParkingSpot: (id: string) => dispatch(removeParkingSpot(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Container)