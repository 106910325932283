import React, {useCallback, useState} from 'react';
import {ApexOptions} from 'apexcharts';
// @mui
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import CardHeader from '@mui/material/CardHeader';
import Card, {CardProps} from '@mui/material/Card';
// components
import Iconify from '../../../components/iconify';
import Chart, {useChart} from '../../../components/chart';
import CustomPopover, {usePopover} from '../../../components/custom-popover';

// ----------------------------------------------------------------------

interface Props extends CardProps {
    title?: string;
    subheader?: string;
    chart: {
        categories?: string[]; colors?: string[]; series: {
            type: string; data: {
                name: string; data: number[];
            }[];
        }[]; options?: ApexOptions;
    };
}

export default function BookingStatistics({
                                              title,
                                              subheader,
                                              chart,
                                              ...other
                                          }: Props) {
    const {
        categories,
        colors,
        series,
        options
    } = chart;

    const popover = usePopover();

    const [seriesData, setSeriesData] = useState('Year');

    const chartOptions = useChart({
        colors,
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories,
        },
        tooltip: {
            y: {
                formatter: (value: number) => `$${value}`,
            },
        }, ...options,
    });

    const handleChangeSeries = useCallback((newValue: string) => {
        popover.onClose();
        setSeriesData(newValue);
    }, [popover]);

    return (<>
        <Card {...other}>
            <CardHeader
                title={title}
                subheader={subheader}
                action={<ButtonBase
                    onClick={popover.onOpen}
                    sx={{
                        pl: 1,
                        py: 0.5,
                        pr: 0.5,
                        borderRadius: 1,
                        typography: 'subtitle2',
                        bgcolor: 'background.neutral',
                    }}
                >
                    {seriesData}

                    <Iconify
                        width={16}
                        icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                        sx={{ml: 0.5}}
                    />
                </ButtonBase>}
            />

            {series.map((item) => (<Box key={item.type} sx={{
                mt: 3,
                mx: 3
            }}>
                {item.type === seriesData && (
                    <Chart dir="ltr" type="bar" series={item.data} options={chartOptions} height={364}/>)}
            </Box>))}
        </Card>

        <CustomPopover open={popover.open} onClose={popover.onClose} sx={{width: 140}}>
            {series.map((option) => (<MenuItem
                key={option.type}
                selected={option.type === seriesData}
                onClick={() => handleChangeSeries(option.type)}
            >
                {option.type}
            </MenuItem>))}
        </CustomPopover>
    </>);
}
