import * as React from "react";
import { applicationState } from "./redux/ApplicationState";
import { connect } from "react-redux";
import pl from "date-fns/locale/pl";
import en from "date-fns/locale/en-US";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import Resources from "../Resources";

registerLocale('pl', pl);
registerLocale("en", en);
setDefaultLocale('pl');

interface OwnProps{
    children: React.ReactNode;
}

interface Props extends OwnProps {
    language: string;
}

const Container = (props: Props) => {
    
    Resources.setLanguage(props.language);

    return (
        <React.Fragment key={props.language}>
            {props.children}
        </React.Fragment>
    );
}

const mapStateToProps = (state: applicationState, ownProps: OwnProps): Props => ({
    ...ownProps,
    language: state.user.language
});

export default connect(mapStateToProps)(Container);