import { ErrorCode, ParkingSpotsService, ServiceConfig, UsersService } from "parkcash-api";
import Resources from "../../Resources";
import { getErrorMessage } from "../utils/ErrorUtils";
import { useJWT } from "../utils/JWTUtils";
import Notify from "../utils/Notify";

export const getBaseInfo = async (id: string, jwt: string) => {
    try{
        const {result, isSuccess, error} = await new UsersService(new ServiceConfig({jwt})).getUserReservationInfoByReservationId(id);
        return isSuccess? {success: true, result} : {success: false, message: getErrorMessage(error.code)};
    }
    catch{
        return {success: false, message: getErrorMessage()};
    }   
}

export const getProxiedReservationInfo = async (id: string, jwt: string) => {
    try{
        const {isSuccess, result, error} = await new ParkingSpotsService(new ServiceConfig({jwt})).getProxiedReservationConfirmation(id)
        return isSuccess? {success: true, result} : {success: false, message: getErrorMessage(error.code)};
    }
    catch{
        return {success: false, message: getErrorMessage()};
    }
}

export const getDetailsInfo = async (id: string, jwt: string) => {
    try{
        const {isSuccess, result, error} = await new UsersService(new ServiceConfig({jwt})).getUserReservationDetails(id);
        return isSuccess? {success: true, result} : {success: false, message: getErrorMessage(error.code)};
    }
    catch{
        return {success: false, message: getErrorMessage()};
    }
}

export const getPilotsInfo = async (id: string, jwt: string) => {
    try{
        const {isSuccess, result, error} = await new UsersService(new ServiceConfig({jwt})).getUserReservationPilots(id)
        return isSuccess ? {success: true, result} : {success: false, message: getErrorMessage(error.code)};
    }
    catch{
        return {success: false, message: getErrorMessage()};
    }
}

export const cancelReservation = async (reservationId: string): Promise<boolean> => {
    try{
        const jwt = useJWT();
        const response = await new ParkingSpotsService(new ServiceConfig({jwt: jwt})).cancelReservation(reservationId);
        if(response.isSuccess){
            return true;
        }
        else{
            if(response.error.code === ErrorCode.CancellingPendingReservationIsForbidden){
                Notify.Error(Resources.Nie_mozna_odwolac_rezerwacji_ktora_sie_juz_rozpoczela_lub_zaraz_rozpocznie);
            }
            else{
                Notify.Error(getErrorMessage(response.error.code));
            }
            return false;
        }
    }
    catch{
        Notify.Error(getErrorMessage());
        return false;
    }
}