import {defaultLanguage, supportedLanguages} from "../app.json";
import LocalStorageManager from "./utils/LocalStorageManager";
import {TwoWayMap} from "./utils/TwoWayMap";

export const cultureMap = new TwoWayMap<string, string>({
    "pl": "pl-PL",
    "en": "en-US"
})

function getLanguageCore() {
    const fromLocalStorage = LocalStorageManager.GetCurrentLang();
    if (fromLocalStorage && supportedLanguages.indexOf(fromLocalStorage) >= 0) {
        return fromLocalStorage;
    }

    const browserLanguage = navigator.language?.split("-")[0]?.toLowerCase() || defaultLanguage;
    if (supportedLanguages.indexOf(browserLanguage) >= 0) {
        return browserLanguage
    } else {
        return defaultLanguage;
    }
}

export function getInterfaceLanguage() {
    return getLanguageCore();
}

export function currentServerCulture() {
    let interfaceLanguage = getInterfaceLanguage().toLowerCase();
    return cultureMap.getValue(interfaceLanguage) || "en-US";
}