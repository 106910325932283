import React from 'react';
import FormControl from "@mui/material/FormControl";
import Select, {SelectProps} from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Colors from "../../../../styles/Colors";

export type SelectOption = {
    label: string; value: string;
};
export interface CustomizedSelectProps extends SelectProps  {
    label: string;
    options: SelectOption[];
    onChangeCustom: (value:string)=>void;
    backgroundColor?: string;
}

export const CustomizedSelect=React.forwardRef(function CustomizedSelect(props:CustomizedSelectProps, ref:React.Ref<HTMLDivElement>) {
    const {label, options,  value, onChangeCustom, backgroundColor=Colors.white, sx, ...other} = props;
    return (
        <FormControl
            
            sx={{
                flexShrink: 0,
                width: {
                    xs: 1,
                },
               
            }}
        >
            <InputLabel>{label}</InputLabel>
            <Select
                ref={ref}
                /*  multiple*/
                value={value??""}
                onChange={(event) => onChangeCustom(event.target.value.toString())}
                input={<OutlinedInput label={label} sx={{p:0}} />}
                sx={{
                    textTransform: 'capitalize',
                    backgroundColor: backgroundColor,
                  /*  p:0,
                    ...sx*/
                }}
            >
                {options?.map((option) => (<MenuItem sx={{}} key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>))}
            </Select>
        </FormControl>)}
    )
