import { ErrorCode } from "parkcash-api";
import * as React from "react";
import { StandardButton } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";

const error = require("../../assets/images/error.svg");

export default (props: {
    title: string,
    subtitle?: string,
    showButton?: boolean,
    onButtonClick?: () => void,
    buttonText?: string
    buttonTo?: string
}) => {
    const {title,onButtonClick,showButton = false,subtitle,buttonText, buttonTo} = props;

    return (
        <div 
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <img src={error} width={117} height={110} />
            <BaseSpaceSeparator size={50} />
            <div style={{width: 300}}>
                <PCText textAlign="center" fontSize={18} semibold color={Colors.black}>
                    {title}
                </PCText>
            </div>
            {!!subtitle && (
                <>
                    <BaseSpaceSeparator size={20} />
                    <div style={{width: 300}}>
                        <PCText textAlign="center" fontSize={14} color={Colors.brownish_grey}>
                            {subtitle}
                        </PCText>
                    </div>
                </>
            )}
            {showButton && (
                <>
                    <BaseSpaceSeparator size={70} />
                    <div style={{width: 300}}>
                        <StandardButton to={buttonTo} onClick={onButtonClick}>{buttonText}</StandardButton>
                    </div>
                </>
            )}
        </div>
    )
}