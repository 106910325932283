import memoizee from "memoizee";
import moment from "moment";
import {
  GetParkingReservationPolicyResult,
  ReservationPolicyType,
  TimeSpan,
} from "parkcash-api";
import { addDays, addMinutes } from "../../utils/DateTimeUtils";

class DateTimeUtils {
  public static withoutTime(dateTime: Date) {
    let date = new Date(dateTime.getTime());
    date.setHours(0, 0, 0, 0);
    return date;
  }

  public static addTimespan(dateTime: Date, timespan: TimeSpan) {
    return new Date(dateTime.valueOf() + timespan.totalMilliseconds);
  }
}

class ReservationPolicyAllowedPeriodCalculatorInfo {
  public PolicyType: ReservationPolicyType | null;
  public MinDuration: TimeSpan | null;
  public MaxDurationFromNow: TimeSpan | null;
  public MaxDuration: TimeSpan | null;
  public MaxReservationAdvance: TimeSpan | null;

  constructor(
    PolicyType: ReservationPolicyType | null,
    MinDuration: TimeSpan | null,
    MaxDuration: TimeSpan | null,
    MaxDurationFromNow: TimeSpan | null,
    MaxReservationAdvance: TimeSpan | null
  ) {
    this.MaxReservationAdvance = MaxReservationAdvance;
    this.MaxDurationFromNow = MaxDurationFromNow;
    this.MaxDuration = MaxDuration;
    this.MinDuration = MinDuration;
    this.PolicyType = PolicyType;
  }
}

class ReservationPolicyAllowedPeriodCalculatorResult {
  public AllowedMinStart: Date;
  public AllowedMaxStart: Date;
  public AllowedMinEnd: Date;
  public AllowedMaxEnd: Date;
}

class ReservationPolicyAllowedPeriodCalculator {
  public Calculate(
    info: ReservationPolicyAllowedPeriodCalculatorInfo | null,
    now: Date,
    selectedStart: Date | null
  ): ReservationPolicyAllowedPeriodCalculatorResult {
    let allowedMinStart: Date;
    let allowedMaxStart: Date;
    let allowedMinEnd: Date;
    let allowedMaxEnd: Date;
    if (selectedStart != null) {
      allowedMinStart = this.GetPolicyTypeAdjustedDate(
        info.PolicyType,
        selectedStart
      );
      if (info.MinDuration != null) {
        allowedMinEnd = this.GetPolicyTypeAdjustedDateWithModifier(
          info.PolicyType,
          selectedStart,
          info.MinDuration
        );
      }
      if (info.MaxDuration != null) {
        allowedMaxEnd = this.GetPolicyTypeAdjustedDateWithModifier(
          info.PolicyType,
          selectedStart,
          info.MaxDuration
        );
      }
    } else {
      allowedMinStart = this.GetPolicyTypeAdjustedDate(info.PolicyType, now);
    }
    if (info.MaxReservationAdvance != null) {
      allowedMaxStart = this.GetPolicyTypeAdjustedDate(
        info.PolicyType,
        DateTimeUtils.addTimespan(now, info.MaxReservationAdvance)
      );
    }
    if (info.MaxDurationFromNow != null) {
      let maxAllowedEnd = this.GetPolicyTypeAdjustedDateWithModifier(
        info.PolicyType,
        now,
        info.MaxDurationFromNow
      );
      if (allowedMaxEnd > maxAllowedEnd || allowedMaxEnd == null) {
        allowedMaxEnd = maxAllowedEnd;
      }
    }

    let result = new ReservationPolicyAllowedPeriodCalculatorResult();
    result.AllowedMinStart = allowedMinStart;
    result.AllowedMaxStart = allowedMaxStart;
    result.AllowedMinEnd =
      info.PolicyType === ReservationPolicyType.WholeDay && allowedMinEnd
        ? addDays(allowedMinEnd, -1)
        : allowedMinEnd;
    result.AllowedMaxEnd =
      info.PolicyType === ReservationPolicyType.WholeDay && allowedMaxEnd
        ? addDays(allowedMaxEnd, -1)
        : allowedMaxEnd;
    return result;
  }

  public GetPolicyTypeAdjustedDate(
    policyType: ReservationPolicyType,
    input: Date
  ): Date {
    return <ReservationPolicyType>policyType ==
      <ReservationPolicyType>ReservationPolicyType.WholeDay
      ? DateTimeUtils.withoutTime(input)
      : input;
  }

  public GetPolicyTypeAdjustedDateWithModifier(
    policyType: ReservationPolicyType,
    input: Date,
    modifier: TimeSpan
  ): Date {
    return <ReservationPolicyType>policyType ==
      <ReservationPolicyType>ReservationPolicyType.WholeDay
      ? DateTimeUtils.addTimespan(
          DateTimeUtils.withoutTime(input),
          TimeSpan.fromDays(Math.max(Math.floor(modifier.totalDays), 1))
        )
      : DateTimeUtils.addTimespan(input, modifier);
  }
}

const getTimespan = (val: string) => {
  return val
    ? TimeSpan.fromMilliseconds(moment.duration(val).asMilliseconds())
    : null;
};

export const getTimeRestrictions = memoizee(
  (policy: GetParkingReservationPolicyResult, start: Date) => {
    if (!policy || !policy.applyForManagers) {
      return null;
    }

    const calculator = new ReservationPolicyAllowedPeriodCalculator();
    const minDuration = getTimespan(policy.minDuration);
    const maxDuration = getTimespan(policy.maxDuration);
    const maxDurationFromNow = getTimespan(policy.maxDurationFromNow);
    const maxReservationAdvance = getTimespan(policy.maxReservationAdvance);

    const info = new ReservationPolicyAllowedPeriodCalculatorInfo(
      policy.policyType,
      minDuration,
      maxDuration,
      maxDurationFromNow,
      maxReservationAdvance
    );
    //return calculator.Calculate(info, new Date(), start);
    // changed to subtract 15 minutes from start in order to enable reservation from current time, especially if default time picker minute granulation is set to 15
    return calculator.Calculate(info, new Date(),  addMinutes(start, -15));
  }
);
