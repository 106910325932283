export enum InvoiceType {
    Company,
    PrivatePerson
}

export default interface InvoiceFormState {
    type?: InvoiceType,
    contrahent: string,
    nip: string,
    streetName?: string,
    streetNumber?: string,
    flatNumber?: string,
    city: string,
    postalCode: string,
    wantInvoice?: boolean
}