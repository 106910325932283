import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { faCar } from "@fortawesome/free-solid-svg-icons";
import { FormikProps } from "formik";
import { IVehicleExDto } from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import { TransactionType } from "../../payments/Enums";
import PaymentForm from "../../payments/PaymentForm";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { ReservationConfirmationFormState } from "./FormState";

const bluemedia_logo = require("../../../assets/images/bm.svg");

const formatVehicle = (v: IVehicleExDto) => {
    const {licensePlateNumber, make, model} = v;
    if(make && model){
        return  `${make} ${model}, ${licensePlateNumber}`
    }
    else{
        return licensePlateNumber;
    }
}

export default (props: {
    args: FormikProps<ReservationConfirmationFormState>
    vehicles: IVehicleExDto[],
    payments: PaymentForm[],
    shouldPay: boolean
}) => {
    const {args, payments, vehicles, shouldPay} = props;
    const {values, touched, errors, setFieldTouched, setFieldValue} = args;

    return (
        <React.Fragment>
            <BaseSpaceSeparator size={30} />
            {!!vehicles && !!vehicles.length && (
                <RegularDropdownList 
                    zIndex={10}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    error={errors.vehicle}
                    value={values.vehicle}
                    touched={touched.vehicle}
                    name="vehicle"                 
                    variant="standard"
                    borderColor={Colors.brownish_grey}
                    label={Resources.Pojazd}
                    actions={vehicles.map(v => ({
                        id: v?.id,
                        text: formatVehicle(v),
                        icon: faCar
                    }))}
                />
            )}
            {shouldPay && !!payments && !!payments.length && (
                <RegularDropdownList 
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    error={errors.payment}
                    value={values.payment}
                    touched={touched.payment}
                    name="payment"                 
                    variant="standard"
                    borderColor={Colors.brownish_grey}
                    label={Resources.Forma_zaplaty}
                    actions={payments.map(p => ({
                        id: p.getId(),
                        text: p.Type === TransactionType.PayWithSavedCreditCard ? `**** **** **** ${p.CreditCardInfo.lastFourDigits}` : Resources.Szybki_przelew,
                        icon: p.Type === TransactionType.PayWithSavedCreditCard ? faCreditCard : undefined,
                        image: p.Type !== TransactionType.PayWithSavedCreditCard ? bluemedia_logo : undefined
                    }))}
                />
            )}
        </React.Fragment>
    )
}