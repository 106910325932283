import { FormikProps } from "formik";
import { AvailibilityStatus, ErrorCode, GetEffectiveTariffsResultEntry, GetExternalReservationParkingConfigurationResult, ParkingSpotsAvailibilityEntry, SpotSelectorType } from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import Input from "../../components/forms/Input";
import RegularDateTimePicker from "../../components/forms/RegularDateTimePicker";
import { formatSpot } from "../../components/ParkingSpotNumberPresenter";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { formatAddress } from "../../utils/AddressUtils";
import { addMinutes } from "../../utils/DateTimeUtils";
import { ExternalReservationSpecyficFormState } from "../formInterfaces/ExternalReservationFormState";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import Notify from "../../utils/Notify";
import { getExternalReservationAvailableSpots, getExternalReservationParkingTarrifs, getExternalReservationPrice } from "../ExternalReservationsUtils";
import { getErrorMessage } from "../../utils/ErrorUtils";
import { PCText } from "../../styles/Texts";
import IconButton from "../../components/IconButton";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import ChoosePlaceFromMap from "../../components/ChoosePlaceFromMap";
import ConfirmModal from "../../components/ConfirmModal";
import { PCClasses } from "../../utils/CSSUtils";
import FormLabel from "../../components/FormLabel";

const TarrifTypeItem = (props: {
    id: string,
    name: string,
    description: string,
    onSelected: (i: string) => void,
    isSelected: boolean,
}) => {
    const {id, isSelected, onSelected, name, description} = props;

    return (
        <div
            className={PCClasses("pc-button")}
            style={{
                boxSizing: "border-box",
                height: 150,
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                backgroundColor: isSelected ? Colors.light_royal_blue : Colors.white,
                borderWidth: isSelected ? 0 : 1,
                borderStyle: "solid",
                borderColor: Colors.very_light_pink,
                borderRadius: 25,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            onClick={() => onSelected(id)}
        >
            <PCText semibold fontSize={16} textAlign="center" color={isSelected ? Colors.white : Colors.black}>
                {name}
            </PCText>
            <BaseSpaceSeparator size={5} />
            <PCText fontSize={11} textAlign="center" color={isSelected ? Colors.white : Colors.brownish_grey}>
                {description}
            </PCText>
        </div>
    )
}

export default (props: {
    args: FormikProps<ExternalReservationSpecyficFormState>,
    parkingInfo: GetExternalReservationParkingConfigurationResult,
    isMobile: boolean,
    onTarrifsError: (error: string | ErrorCode) => void
}) => {
    const {args, isMobile, parkingInfo, onTarrifsError} = props;
    const {values, touched, errors, setFieldValue, setFieldTouched} = args;
    const [spots, setSpots] = React.useState<ParkingSpotsAvailibilityEntry[]>([]);
    const [number, setNumber] = React.useState<string>(null);
    const [level, setLevel] = React.useState<string>(null);
    const [sector, setSector] = React.useState<string>(null);

    const onMap = async () => {
        const s = await ChoosePlaceFromMap.show({
            currentSpotId: values.spotId,
            parkingId: values.parkingId,
            getSpotsCallback: async (level, sector) => {
                const {error, spots} = await getExternalReservationAvailableSpots(values.parkingId, values.start, values.end, level, sector);
                if(error){
                    return {error};
                }
                else{
                    return {
                        spots: spots.map(s => ({
                            status: s.isAvailable ? AvailibilityStatus.Free : null,
                            id: s.id,
                            number: s.number,
                            level: s.level,
                            sector: s.sector
                        }))
                    }
                }
            } 
        });
        if(s){
            const {spotId, number, sector, level} = s;
            setFieldValue("spotId", spotId);
            setNumber(number);
            setSector(sector);
            setLevel(level);
        }
    }

    const retrieveSpots = async (parId: string, s: Date, e: Date) => {
        const {spots, error} = await getExternalReservationAvailableSpots(parId, s, e);
        if(error){
            Notify.Error(getErrorMessage(error));
            setSpots([])
        }
        else{
            setSpots(spots);
        }
    }

    const getPrice = async (parId: string, s: Date, e: Date, sId: string, tId: string) => {
        setFieldValue("currentPriceProgress", true);
        const {error, price} = await getExternalReservationPrice(parId, s, e, sId, tId);
        if(error){
            Notify.Error(getErrorMessage(error));
        }
        else{
            setFieldValue("currentPrice", price);
        }
        setFieldValue("currentPriceProgress", false);
    } 

    React.useEffect(() => {      
        if(values.enableParticularSpotPurchase){
            setFieldValue("spotId", null);
            setLevel(null);
            setNumber(null);
            setSector(null);
            setFieldValue("currentPrice", null);
        }
        setSpots([]);
        if(values.enableParticularSpotPurchase && values.parkingId && values.end && values.start && values.spotSelectorType === SpotSelectorType.List){
            retrieveSpots(values.parkingId, values.start, values.end)
        }
    }, [values.start, values.end, values.parkingId]);

    React.useEffect(() => { 
        setFieldValue("currentPrice", null);     
        if(values.parkingId && values.end && values.start && values.enableParticularSpotPurchase && values.spotId && values.tariffId){
            getPrice(values.parkingId, values.start, values.end, values.spotId, values.tariffId);
        }
        else if(values.parkingId && values.end && values.start && !values.enableParticularSpotPurchase && values.tariffId){
            getPrice(values.parkingId, values.start, values.end, undefined, values.tariffId);
        }
    }, [values.start, values.end, values.parkingId, values.spotId, values.tariffId]);

    React.useEffect(() => {
        const getTarrifs = async (pId: string, sId: string, onError: (e: string | ErrorCode) => void) => {
            setFieldValue("tariffId", null);
            setFieldValue("tariffs", []);
            const {error, result} = await getExternalReservationParkingTarrifs(pId, sId);
            if(error){
                onError(error);
            }            
            else if(!result || !result.length){
                onError(Resources.Dla_tego_parkingu_nie_zdefiniowano_zadnych_cennikow);
            }
            else{
                const tariffId = result[0].id;
                setFieldValue("tariffId", tariffId);
                setFieldValue("tariffs", result);
            }
        }

        getTarrifs(values.parkingId, values.spotId, onTarrifsError)
    }, [values.spotId, onTarrifsError]);

    return (
        <>            
            <div style={{display: 'flex', flexDirection: isMobile ? "column" : "row"}}>
                <div style={{width: isMobile ? '100%' : 200}}>
                    <RegularDateTimePicker 
                        label={Resources.Poczatek_parkowania}
                        value={values.start}
                        touched={touched.start}
                        error={errors.start}
                        mode="datetime"
                        name="start"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        onChange={v => {
                            if(v > values.end){
                                setFieldValue("end", addMinutes(v, 15))
                            }
                        }}
                    />
                </div>
                <BaseSpaceSeparator size={15} />
                <div style={{width: isMobile ? '100%' : 200}}>
                    <RegularDateTimePicker 
                        label={Resources.Koniec_parkowania}
                        value={values.end}
                        touched={touched.end}
                        error={errors.end}
                        mode="datetime"
                        name="end"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        onChange={v => {
                            if(v < values.start){
                                setFieldValue("start", addMinutes(v, -15))
                            }
                        }}
                    />
                </div>
            </div>
            <BaseSpaceSeparator size={30} />
            <div
                style={{
                    flexDirection: isMobile ? 'column' : 'row',
                    display: 'flex'
                }}
            >
                <div style={{flex: 1}}>
                    <Input 
                        readOnly
                        value={formatAddress(parkingInfo.address, true)}
                        label={Resources.Adres}
                    />
                </div>
                <BaseSpaceSeparator size={20} />
                <div style={{flex: 1}}>
                    {values.enableParticularSpotPurchase && (
                        <>
                            {values.spotSelectorType === SpotSelectorType.List && (
                                <div style={{position: 'relative', top: 10}}>
                                    <RegularDropdownList 
                                        zIndex={10}
                                        maxContentHeight={300}
                                        borderColor={Colors.brownish_grey}
                                        setFieldTouched={setFieldTouched}
                                        setFieldValue={setFieldValue}
                                        value={values.spotId}
                                        error={errors.spotId}
                                        touched={touched.spotId}
                                        name="spotId"
                                        label={Resources.Miejsce_rezerwacji}
                                        actions={spots.map(spot => ({
                                            id: spot.id,
                                            text: formatSpot({spotNumber: spot.number, level: spot.level})
                                        }))}
                                    />
                                </div>
                            )}
                            {values.spotSelectorType === SpotSelectorType.Map && (
                                <div>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <PCText fontSize={12} semibold color={Colors.brownish_grey}>
                                            {formatSpot(({spotNumber: number, level, sector})) || Resources.Nie_wybrales_miejsca}
                                        </PCText>
                                        <BaseSpaceSeparator size={15} />
                                        <IconButton icon={faMap} onClick={onMap} style={{width: 40, height: 40, backgroundColor: Colors.light_royal_blue, borderRadius: 20}}/>
                                    </div>
                                    <div style={{paddingTop: 4, paddingBottom: 4, height: 20, boxSizing: "border-box"}}>
                                        <PCText fontSize={12} lineHeight={1} letterSpacing={0} color={Colors.red}>{touched.spotId && errors.spotId ? errors.spotId : ""}</PCText>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            <BaseSpaceSeparator size={20} />
            {isMobile && (values.tariffs || []).length > 1 && (
                <>
                    <FormLabel>{Resources.Wybierz_cennik}:</FormLabel>
                    <BaseSpaceSeparator size={5} />
                    <RegularDropdownList
                        borderColor={Colors.brownish_grey}
                        zIndex={30}
                        value={values.tariffId}
                        touched={touched.tariffId}
                        error={errors.tariffId}
                        onChange={() => {
                            
                        }}
                        name="tariffId"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        actions={values.tariffs.sort((a, b) => a.orderIndex - b.orderIndex).map(a => ({
                            metadata: a,
                            id: a.id
                        }))}
                    >
                        {(action, isOption, handleClick) => {
                            const {metadata} = action;
                            const {description, name, } = metadata as GetEffectiveTariffsResultEntry;
                            return (
                                <div
                                    onClick={isOption ? handleClick : undefined}
                                    className={isOption ? PCClasses("pc-button") : undefined}
                                    style={{padding: isOption ? "10px 0px" : undefined}}
                                >
                                    <PCText semibold fontSize={18} color={Colors.light_royal_blue}>
                                        {name}
                                    </PCText>
                                    <BaseSpaceSeparator size={5} />
                                    <PCText fontSize={14} color={Colors.brownish_grey}>
                                        {description}
                                    </PCText>
                                </div>
                            );
                        }}
                    </RegularDropdownList>
                </>
            )}
            {!isMobile && (values.tariffs || []).length > 1 &&  (
                <>
                    <FormLabel>{Resources.Wybierz_cennik}:</FormLabel>
                    <BaseSpaceSeparator size={5} />
                    <div style={{display: "grid", alignItems: 'center', gridTemplateColumns: "1fr 1fr 1fr", gridGap: "15px"}}>
                        {values.tariffs.map((tar, index, arr) => {
                            const {id, description, name} = tar;
                            return (
                                <TarrifTypeItem 
                                    key={id}
                                    id={id}
                                    isSelected={values.tariffId === id}
                                    onSelected={() => setFieldValue("tariffId", id)}
                                    
                                    name={name}
                                    description={description}
                                />
                            );
                        })}
                    </div>
                </>
            )}
        </>
    );
}