import React from 'react';
// @mui
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

// ----------------------------------------------------------------------

type Props = {
    height?: number; emptyRows: number;
};

export default function TableEmptyRows({
                                           emptyRows,
                                           height
                                       }: Props) {
    if (!emptyRows) {
        return null;
    }

    return (<TableRow
        sx={{
            ...(height && {
                height: height * emptyRows,
            }),
        }}
    >
        <TableCell colSpan={9}/>
    </TableRow>);
}
