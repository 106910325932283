import * as React from "react";
import Resources from "../../Resources";
import Colors from "../styles/Colors";
import { PCText } from "../styles/Texts";

export default (props: {
    value: boolean
}) => {
    const {value} = props;

    return (
        <PCText fontSize={16} semibold color={value ? Colors.light_royal_blue : Colors.heliotrope}>
            {value ? Resources.Tak.toUpperCase() : Resources.Nie.toUpperCase()}
        </PCText>
    );
}