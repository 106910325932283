import React from 'react';
import orderBy from 'lodash/orderBy';
// @mui
import {alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Card, {CardProps} from '@mui/material/Card';
// utils
import {fShortenNumber} from '../../../../MUI/utils/format-number';
// components
import Iconify from '../../../../MUI/components/iconify';

// ----------------------------------------------------------------------

export type ItemProps = {
    id: string; name: string; avatarUrl: string; totalFavorites: number;
};

export interface TopItemsListProps extends CardProps {
    title?: string;
    subheader?: string;
    list: ItemProps[];
}

export default function TopItemsList({
                                         title,
                                         subheader,
                                         list,
                                         ...other
                                     }: TopItemsListProps) {
    return (<Card {...other}>
            <CardHeader title={title} subheader={subheader}/>

            <Stack spacing={3} sx={{p: 3}}>
                {orderBy(list, ['totalFavorites'], ['desc'])
                    .map((author, index) => (<AuthorItem key={author.id} author={author} index={index}/>))}
            </Stack>
        </Card>);
}

// ----------------------------------------------------------------------

type AuthorItemProps = {
    author: ItemProps; index: number;
};

function AuthorItem({
                        author,
                        index
                    }: AuthorItemProps) {
    return (<Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={author.name} src={author.avatarUrl}/>
            <Box sx={{flexGrow: 1}}>
                <Typography variant="subtitle2">{author.name}</Typography>
                <Typography
                    variant="caption"
                    sx={{
                        mt: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        color: 'text.secondary',
                    }}
                >
                    <Iconify icon="solar:heart-bold" width={14} sx={{mr: 0.5}}/>
                    {fShortenNumber(author.totalFavorites)}
                </Typography>
            </Box>
            <Iconify
                icon="solar:cup-star-bold"
                sx={{
                    p: 1,
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                    color: 'primary.main',
                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08), ...(index === 1 && {
                        color: 'info.main',
                        bgcolor: (theme) => alpha(theme.palette.info.main, 0.08),
                    }), ...(index === 2 && {
                        color: 'error.main',
                        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
                    }),
                }}
            />
        </Stack>);
}
