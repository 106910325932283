export function formatAddress(args: {streetName?: string, streetNumber?: string, city?: string}, oneLine: boolean = false){
    if(!args || (!args.city && !args.streetName && !args.streetNumber)){
        return "";
    }
    
    if(args.city && !args.streetName && args.streetNumber){
        return `${args.city} ${args.streetNumber}`;
    }

    if(args.city && !args.streetName && !args.streetNumber){
        return args.city;
    }
    
    if(oneLine){
        return `${args.streetName}${!!args.streetNumber ? ` ${args.streetNumber}` : ""}, ${args.city}`;
    }

    return `${args.streetName}${!!args.streetNumber ? ` ${args.streetNumber}` : ""}
${args.city}`;
}