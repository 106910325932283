import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import Resources from "../../../Resources";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import Dropdown from "../Dropdown";
import { BOTTOM_COLOR, BOTTOM_FONT_SIZE } from "./Constants";

export default (props: {
    pageSize: number,
    onPageSizeChanged: (p: number) => void;
    availablePageSizes: number[];
}) => {
    const {availablePageSizes,onPageSizeChanged,pageSize} = props;

    return (
        <Dropdown
            reversed
            actions={availablePageSizes.map(p => ({
                text: p.toString(),
                selected: p === pageSize,
                onClick: () => onPageSizeChanged(p)
            }))}
        >
            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                <PCText fontSize={BOTTOM_FONT_SIZE} color={BOTTOM_COLOR}>{Resources.Ilosc_wierszy}</PCText>
                <BaseSpaceSeparator size={25} />
                <PCText fontSize={BOTTOM_FONT_SIZE} color={BOTTOM_COLOR}>{pageSize}</PCText>
                <BaseSpaceSeparator size={5} />
                <FontAwesomeIcon icon={faArrowDown} color={BOTTOM_COLOR} style={{fontSize: BOTTOM_FONT_SIZE, lineHeight: 20/18}} />
            </div>
        </Dropdown>
    );
}