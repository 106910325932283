import {ParkingType} from "parkcash-api";
import Resources from "../../../../Resources";

export const MapParkingTypeEnumToLocalizedText= (parkingType: ParkingType ): string => { 
  
    switch (parkingType) {
        case ParkingType.Public:
            return Resources.Publiczny
        case ParkingType.Private:
            return Resources.Prywatny
        case ParkingType.Commercial:
            return Resources.Komercyjny
        case ParkingType.Office:
            return Resources.Biuro
        case ParkingType.OfficeEV:
            return Resources.BiuroEV
        default:
            return Resources.Nieznany
        }
        
}
    