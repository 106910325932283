import * as React from "react";
import Colors from "../../styles/Colors";
import {BaseSpaceSeparator} from "../../styles/Separators";
import {PCText} from "../../styles/Texts";

export default (props: {
    children: React.ReactNode,
    title: string
}) => {
    const {children, title} = props;

    return (
        <>
        <PCText semibold letterSpacing={0} color={Colors.black} fontSize={24} lineHeight={35/24}>{title}</PCText>
        <BaseSpaceSeparator size={16} />
        <div
            style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                borderRadius: 25,
                backgroundColor: Colors.white,
                boxShadow: "0 3px 26px 4px rgba(0, 0, 0, 0.08)",
                padding: "20px 0px"
            }}
        >
            {children}
        </div>
        </>
    )
}