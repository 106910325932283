import { ServiceConfig, SortOrder, TransactionDetailDto, TransactionsSortType, UserWalletService, UserWalletType } from "parkcash-api";
import * as React from "react";
import { useParams } from "react-router-dom";
import Resources from "../../Resources";
import PCDataGrid from "../components/PCDataGrid";
import Body from "../layouts/Main/Body";
import Colors from "../styles/Colors";
import { PCText } from "../styles/Texts";
import { CustomDataSource } from "../utils/DataSource";
import { useJWT } from "../utils/JWTUtils";

interface routeParams {
    type: string;
}

export default () => {
    const jwt = useJWT();

    
    const {type} = useParams<routeParams>();
    const walletType: UserWalletType = parseInt(type);
    const [dataSource] = React.useState(new CustomDataSource<TransactionDetailDto, {jwt: string}>({
        additionalInfo: {jwt},
        load: async (loadOptions, additional) => {
            const {page, pageSize} = loadOptions;
            const {jwt} = additional;

            const {isSuccess, result, error} = await new UserWalletService(new ServiceConfig({jwt})).getTransactionDetails
            (
                SortOrder.Descending,
                TransactionsSortType.ByDate,
                (page - 1)*pageSize,
                pageSize,
                0,
                walletType
            );
            if(isSuccess){
                const {transactionDetails, paginationInfo: {totalElementsCount}} = result;
                return {
                    items: transactionDetails,
                    totalItems: totalElementsCount
                }
            }
            else{
                return error.code;
            }
        }
    }))

    const title = walletType === UserWalletType.Points ? Resources.Lista_uzyskanych_parkcoinow : Resources.Lista_wplywow;

    return (
        <Body title={title}>
            <PCDataGrid<TransactionDetailDto> 
                dataSource={dataSource}
                disableSorting
                columns={[
                    {
                        width: 1,
                        dataField: "timestamp",
                        label: Resources.Data
                    },
                    {
                        width: 1,
                        dataField: "amount",
                        label: Resources.Kwota,
                        renderCell: item => (
                            <PCText fontSize={16} lineHeight={20/16} semibold color={Colors.black} letterSpacing={0}>
                                {item.amount} {walletType === UserWalletType.Points ? Resources.pkt : Resources.zl}
                            </PCText>
                        )
                    },
                    {
                        width: 2,
                        dataField: "title",
                        label: Resources.Tytul
                    }
                ]}
            />
        </Body>
    )
}