import { Formik, FormikHelpers } from "formik";
import { AddressDto, CreatePrivateParkingDto, ErrorCode, JoinParkingDto, ParkingInfo, ParkingsService, ServiceConfig } from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import Resources from "../../Resources";
import RegularInput from "../components/forms/RegularInput";
import RegularSearchAddressInput from "../components/forms/RegularSearchAddressInput";
import { searchAddressItem } from "../components/forms/SearchAddressInput";
import PCTabBar from "../components/PCTabBar";
import Spinner from "../components/Spinner";
import { applicationState } from "../redux/ApplicationState";
import { StandardButton } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { getErrorMessage } from "../utils/ErrorUtils";
import { useJWT } from "../utils/JWTUtils";
import Notify from "../utils/Notify";
import { getGroups } from "./Redux";

const groupsImg = require("../../assets/images/groups.svg");

interface OwnProps {

}

interface Props extends OwnProps {
    items: ParkingInfo[],
    progress: boolean,
    error: ErrorCode,
    onInit: (refresh: boolean) => void
}



const NoGroups = () => {
    return (
        <div style={{height: 346, width: '100%'}}>
            <PCText textAlign="center" semibold fontSize={24} color={Colors.light_royal_blue}>{Resources.Grupy}</PCText>
            
        </div>
    );
}

enum Tab {
    Create,
    Join
}

interface FormState {
    name: string,
    pin: string,
    code: string,
    address: searchAddressItem
}

const InviteToFirstGroup = (props: {onSubmitted: () => void}) => {
    const {onSubmitted} = props;
    const [tab, setTab] = React.useState(Tab.Create);
    const initialValues: FormState = {
        address: null,
        code: "",
        name: "",
        pin: ""
    } 

    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {
        const {pin,code,address,name} = state;
        const {setSubmitting, setStatus} = helpers;
        try{
            const jwt = useJWT();
            setSubmitting(true);
            const addressDTO = new AddressDto({
                city: address?.city,
                country: "Poland",
                latitude: address?.lat,
                longitude: address?.lon,
                streetName: address?.streetName,
                streetNumber: address?.streetNumber
            })
            if(tab === Tab.Create){
                const response = await new ParkingsService(new ServiceConfig({jwt})).createPrivateParking(new CreatePrivateParkingDto({
                    address: addressDTO,
                    friendlyName: name,
                    description: ""
                }));
                if(response.isSuccess){
                    Notify.Success(Resources.Utworzono_grupe);
                    onSubmitted();
                }
                else{
                    setStatus(getErrorMessage(response.error.code));
                }
            }
            else{
                const response = await new ParkingsService(new ServiceConfig({jwt})).joinParking(new JoinParkingDto({
                    pin: pin,
                    parkingPublicId: code  
                }));
                if(response.isSuccess){
                    Notify.Success(Resources.Dolaczyles_do_grupy);
                    onSubmitted();
                }
                else{
                    if(response.error.code === ErrorCode.IncorrectParkingIdOrPIN){
                        setStatus(Resources.Niepoprawny_identyfikator_lub_pin);
                    }
                    else{
                        setStatus(getErrorMessage(response.error.code));
                    }
                }
            }
        }
        catch{
            setStatus(getErrorMessage())
        }
        finally{
            setSubmitting(false);
        }
    }

    const validate = (state: FormState) => {
        const {address, name, pin, code} = state;
        const errors: any = {};

        if(tab === Tab.Create){
            if(!name){
                errors.name = Resources.Wymagane;
            }
    
            if(!address){
                errors.address = Resources.Wymagane;
            }
    
            if(!!address && !address.streetNumber){
                errors.address = Resources.Wymagane_jest_podanie_numeru_domu;
            }
        }
        else{
            if(!code){
                errors.code = Resources.Wymagane;
            }
            if(!pin){
                errors.pin = Resources.Wymagane;
            }
        }

        return errors;
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
        >
            {args => {
                const {status, isSubmitting, values, errors, touched, setFieldTouched, setFieldValue, handleSubmit} = args;
                return (
                    <div style={{height: 366, width: '100%'}}>
                        <form
                            noValidate={true}
                            autoComplete="off"
                            onSubmit={handleSubmit}
                        >
                            <PCText textAlign="center" semibold fontSize={24} color={Colors.light_royal_blue}>{Resources.Grupy}</PCText>
                            <BaseSpaceSeparator size={25} />
                            <PCTabBar
                                current={tab}
                                onChange={setTab}
                                itemWidth="full"
                                items={[
                                    {
                                        id: Tab.Create,
                                        text: Resources.Utworz_grupe
                                    },
                                    {
                                        id: Tab.Join,
                                        text: Resources.Dolacz_do_grupy
                                    }
                                ]}
                            >
                                {t => {
                                    if(t === Tab.Create){
                                        return (
                                            <>
                                                <BaseSpaceSeparator size={20} />
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <img src={groupsImg} width={40} height={36}/>
                                                    <BaseSpaceSeparator size={10} />
                                                    <div style={{flex: 1}}>
                                                        <PCText color={Colors.greyishBrown} fontSize={16}>
                                                            {Resources.Utworz_grupe_adresowa_podajac_jej_adres}
                                                        </PCText>
                                                    </div>
                                                </div>
                                                <BaseSpaceSeparator size={20} />
                                                <RegularInput 
                                                    name="name"
                                                    value={values.name}
                                                    error={errors.name}
                                                    touched={touched.name}
                                                    setFieldTouched={setFieldTouched}
                                                    setFieldValue={setFieldValue}
                                                    showClearButton
                                                    showValidatedButton
                                                    label={Resources.Nazwa_grupy}
                                                />
                                                <RegularSearchAddressInput 
                                                    label={Resources.Adres}
                                                    current={values.address}
                                                    name="address"
                                                    error={errors.address}
                                                    touched={touched.address}
                                                    setFieldTouched={setFieldTouched}
                                                    setFieldValue={setFieldValue}
                                                    borderColor={Colors.brownish_grey}
                                                />
                                                <div style={{height: 10}}>
                                                    <PCText color={Colors.red} fontSize={8}>{status || ""}</PCText>
                                                </div>
                                                <StandardButton variant="tiny" type="submit">{Resources.Utworz_grupe}</StandardButton>
                                            </>
                                        );
                                    }
                                    else{
                                        return (
                                            <>
                                                <BaseSpaceSeparator size={20} />
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <img src={groupsImg} width={40} height={36}/>
                                                    <BaseSpaceSeparator size={10} />
                                                    <div style={{flex: 1}}>
                                                        <PCText color={Colors.greyishBrown} fontSize={16}>
                                                            {Resources.Dolacz_do_grupy_adresowej_podajac_kod_wspoldzielenia}
                                                        </PCText>
                                                    </div>
                                                </div>
                                                <BaseSpaceSeparator size={20} />
                                                <RegularInput 
                                                    name="code"
                                                    value={values.code}
                                                    error={errors.code}
                                                    touched={touched.code}
                                                    setFieldTouched={setFieldTouched}
                                                    setFieldValue={setFieldValue}
                                                    showClearButton
                                                    showValidatedButton
                                                    label={Resources.Kod_wpoldzielenia}
                                                />
                                                <RegularInput 
                                                    name="pin"
                                                    value={values.pin}
                                                    error={errors.pin}
                                                    touched={touched.pin}
                                                    setFieldTouched={setFieldTouched}
                                                    setFieldValue={setFieldValue}
                                                    password
                                                    label={Resources.PIN}
                                                />
                                                <div style={{height: 10}}>
                                                    <PCText color={Colors.red} fontSize={8}>{status || ""}</PCText>
                                                </div>
                                                
                                                <StandardButton variant="tiny" type="submit">{Resources.Dolacz_do_grupy}</StandardButton>
                                            </>
                                        );
                                    }
                                }}
                            </PCTabBar>
                        </form>
                    </div>
                );
            }}
        </Formik>
    );
}

const Container = (props: Props) => {
    const {error, items, onInit, progress} = props;

    React.useEffect(() => {
        onInit(false);
    }, []);

    return (
        <div 
            style={{
                height: 404, 
                width: 300,
                boxSizing: "border-box",
                boxShadow: "0 3px 26px 4px rgba(0, 0, 0, 0.08)",
                backgroundColor: Colors.white,
                borderRadius: 25,
                display: 'flex',
                padding: 20
            }}
        >
            {progress && (
                <div style={{flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                    <Spinner size="extra-small" />
                </div>
            )}
            {!progress && typeof error === "number" && (
                <div style={{flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                    <PCText fontSize={14} textAlign="center" color={Colors.red} semibold>{getErrorMessage(error)}</PCText>
                </div>
            )}
            {!progress && !error && !items.length &&  <InviteToFirstGroup onSubmitted={() => onInit(true)}/>}
            {!progress && !error && !!items.length && <InviteToFirstGroup onSubmitted={() => onInit(true)}/>}
        </div>
    );
}

const mapStateToProps = (state: applicationState, ownProps: OwnProps): Partial<Props> => ({
    error: state.groups.gettingGroupsError,
    items: state.groups.groups,
    progress: state.groups.gettingGroupsProgress,
    ...ownProps
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
    onInit: (r) => dispatch(getGroups(r))
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);