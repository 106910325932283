import Resources from "../../Resources";
import Label from "../MUI/components/label";
import * as React from "react";
import {GetParkingParkingSpotsViewRealTimeOccupancyStatus} from "parkcash-api";

interface ParkingStatePresenterProps {
    spotRealTimeOccupancyStatus: GetParkingParkingSpotsViewRealTimeOccupancyStatus;
}
export const ParkingSpotRealTimeOccupancyPresenter = ({spotRealTimeOccupancyStatus}:ParkingStatePresenterProps)=>{
    // stats taken from ParkingSystem db
    let vehicleParkingStateLabel;
    let color;
    switch (spotRealTimeOccupancyStatus) {
        case GetParkingParkingSpotsViewRealTimeOccupancyStatus.Free:
            color = "success";
            vehicleParkingStateLabel = Resources.Wolne;
            break;
        case GetParkingParkingSpotsViewRealTimeOccupancyStatus.Occupied:
            color = "error";
            vehicleParkingStateLabel = Resources.Zajete;
            break;
        default:
            color = "info";
            vehicleParkingStateLabel = Resources.Status_nieznany;
    }
    return (
            <Label color={color} title={vehicleParkingStateLabel}
                   sx={{
                       width:"30px",
                       minWidth:0
            }}>
            </Label>
    )
}