import {faEdit, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {
    ErrorCode,
    GetParkingUsersViewResultEntryDto,
    ParkingManagerService,
    ParkingMemberType,
    ParkingsService,
    RemoveParkingMemberDto,
    ServiceConfig,
    StandardResponseWrapperOfObjectAndErrorWrapperOfObject
} from "parkcash-api";
import * as React from "react";
import {useCallback} from "react";
import Resources from "../../../Resources";
import DropdownList from "../../components/DropdownList";
import Input from "../../components/forms/Input";
import PCDataGrid from "../../components/PCDataGrid";
import ActionColumn, {ActionIcon} from "../../components/PCDataGrid/ActionColumn";
import StatusPresenter from "../../components/StatusPresenter";
import Body from "../../layouts/Main/Body";
import {StandardButton} from "../../styles/Buttons";
import Colors from "../../styles/Colors";
import {BaseSpaceSeparator} from "../../styles/Separators";
import {PCText} from "../../styles/Texts";
import {useUser} from "../../user/UseUserHook";
import {MOBILE_WIDTH_THRESHOLD} from "../../utils/Constants";
import {CustomDataSource, FilterOperator} from "../../utils/DataSource";
import {getFilters, getSorts} from "../../utils/SieveUtils";
import {useWindowSize} from "../../utils/WindowSizeHook";
import AddUserForm from "./AddUserForm";
import EditUserForm from "./EditUserForm";
import {getColor, getText, getTextColor} from "./Utils";
import {nameof} from "../../utils/NameOf";
import ConfirmModal from "../../components/ConfirmModal";
import Notify from "../../utils/Notify";
import {getErrorMessage, getErrorMessageNew} from "../../utils/ErrorUtils";
import {AddOrImportActionsMenu} from "../../MUI/sections/file-manager/AddOrImportActionsMenu";
import {AutomationService, FileParameter} from "parkcash-api";

function formatName(item: GetParkingUsersViewResultEntryDto) {

    const parts = [];
    parts.push(item.firstName);
    parts.push(item.lastName);

    if (item.externalProfileId) {
        parts.push(`(${item.externalProfileId.toUpperCase()})`);
    }

    return parts.join(" ");
}

export default () => {
    const {
        applicationMode,
        token: jwt
    } = useUser();
    const parkingId = applicationMode.getCurrentParking()?.parkingId || null;
    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const [search, setSearch] = React.useState("");
    const grid = React.useRef<PCDataGrid<GetParkingUsersViewResultEntryDto>>();
    const [roleFilter, setRoleFilter] = React.useState("all");
    const [addFormVisible, setAddFormVisible] = React.useState(false);
    const [editFormVisible, setEditFormVisible] = React.useState(false);
    const [editedUser, setEditedUser] = React.useState<GetParkingUsersViewResultEntryDto>();

    const dataSource = React.useMemo(() => {
        return new CustomDataSource<GetParkingUsersViewResultEntryDto>({
            load: async (loadOptions) => {
                try {

                    const {
                        filter,
                        page,
                        pageSize,
                        sort
                    } = loadOptions;
                    let targetFilter = getFilters(filter.filter(f => f.key !== "email"));
                    const emailFilterValue = filter.find(f => f.key === "email");
                    targetFilter = `${targetFilter}${targetFilter && emailFilterValue ? "," : ""}${emailFilterValue ? `${[
                        nameof<GetParkingUsersViewResultEntryDto>('email'),
                        nameof<GetParkingUsersViewResultEntryDto>('firstName'),
                        nameof<GetParkingUsersViewResultEntryDto>('lastName'),
                        nameof<GetParkingUsersViewResultEntryDto>('externalProfileId')
                    ].join("|")}@=*${emailFilterValue.value}` : ""}`
                    const sorts = getSorts(sort);
                    const {
                        isSuccess,
                        result,
                        error
                    } = await new ParkingManagerService(new ServiceConfig({jwt})).getParkingUsersView(parkingId,
                        targetFilter,
                        sorts,
                        page,
                        pageSize
                    );
                    if (isSuccess) {
                        return {
                            items: result.entries,
                            totalItems: result.paginationDto.totalElementsCount
                        }
                    } else {
                        return error.code;
                    }
                } catch {
                    return ErrorCode.NetworkError;
                }
            }
        });
    }, [
        jwt,
        parkingId
    ]);

    const onSearch = React.useCallback(() => {
        if (!search) {
            dataSource.removeFilter("email");
        } else {
            dataSource.filter("email", FilterOperator.EQUALS, search);
        }
    }, [
        dataSource,
        search
    ]);

    const onRoleFilterChanged = React.useCallback((v: string) => {
        if (v === "all") {
            dataSource.removeFilter("memberType");
        } else {
            dataSource.filter("memberType", FilterOperator.EQUALS, v);
        }
        setRoleFilter(v);
    }, [dataSource]);

    const onAddUser = React.useCallback(() => {
        setAddFormVisible(true);
    }, []);
    const onUploadImportedFiles = useCallback((async (file: (string | File), fileName: string): Promise<StandardResponseWrapperOfObjectAndErrorWrapperOfObject> => {
        const fileParameter: FileParameter = {
            fileName: fileName,
            data: file
        }
        const response = await new AutomationService(new ServiceConfig({jwt})).batchCreateParkingMember(parkingId,
            fileParameter
        );
        return response;

    }), [
        jwt,
        parkingId
    ]);

    const onEditUser = React.useCallback((item: GetParkingUsersViewResultEntryDto) => {
        setEditedUser(item);
        setEditFormVisible(true);
    }, []);

    const onRemoveUser = React.useCallback(async (item: GetParkingUsersViewResultEntryDto) => {
        const confirm = await ConfirmModal.show({
            text: Resources.Czy_na_pewno_chcesz_usunac_uzytkownika,
            confirmText: Resources.Usun,
        });
        if (!confirm) {
            return;
        }
        try {
            let parkingsService = new ParkingsService(new ServiceConfig({jwt}));
            const {
                isSuccess,
                error
            } = await parkingsService.removeParkingMember(new RemoveParkingMemberDto({
                parkingId: parkingId,
                userToRemoveId: item.userId
            }));

            if (isSuccess) {
                dataSource.reload();
            } else {
                Notify.Error(getErrorMessageNew(error));
            }
        } catch {
            Notify.Error(getErrorMessage());
        }

    }, []);

    return (<Body
        title={Resources.Uzytkownicy}
        rigth={(<>
            <div style={{width: isMobile ? "100%" : 250}}>
                <DropdownList
                    label={Resources.Rola}
                    value={roleFilter}
                    onChange={onRoleFilterChanged}
                    actions={[
                        {
                            id: "all",
                            text: Resources.Wszystkie
                        },
                        {
                            id: ParkingMemberType.User.toString(),
                            text: getText(ParkingMemberType.User)
                        },
                        {
                            id: ParkingMemberType.Manager.toString(),
                            text: getText(ParkingMemberType.Manager)
                        },
                        {
                            id: ParkingMemberType.Staff.toString(),
                            text: getText(ParkingMemberType.Staff)
                        },
                        {
                            id: ParkingMemberType.Moderator.toString(),
                            text: getText(ParkingMemberType.Moderator)
                        },
                    ]}
                />
            </div>
            <BaseSpaceSeparator size={20}/>
            <div style={{width: isMobile ? "100%" : 300}}>
                <AddOrImportActionsMenu onAdd={onAddUser}
                                        onUploadImportedFiles={onUploadImportedFiles}
                                        buttonTitle={Resources.Dodaj_uzytkownika}/>

            </div>
        </>)}
    >
        <AddUserForm
            onClose={() => setAddFormVisible(false)}
            onSubmitted={() => {
                setSearch("");
                setRoleFilter("all");
                setAddFormVisible(false);
                dataSource?.reload();
            }}
            visible={addFormVisible}
            parkingId={parkingId}
        />
        <EditUserForm
            onClose={() => setEditFormVisible(false)}
            onSubmitted={() => {
                setSearch("");
                setRoleFilter("all");
                setEditFormVisible(false);
                dataSource?.reload();
            }}
            visible={editFormVisible}
            user={editedUser}
            parkingId={parkingId}
        />
        <div
            style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: 'center'
            }}
        >
            <div style={{
                flex: isMobile ? undefined : 1,
                width: isMobile ? '100%' : undefined
            }}>
                <Input
                    value={search}
                    onChangeText={setSearch}
                    borderColor={Colors.very_light_pink}
                    placeholder={Resources.Wyszukaj_uzytkownika_po_imieniu_nazwisku_emailu_lub_identyfikatorze}
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            onSearch();
                        }
                    }}
                />
            </div>
            <BaseSpaceSeparator size={10}/>
            <div style={{width: isMobile ? '100%' : 200}}>
                <StandardButton variant="big" onClick={onSearch}>{Resources.Wyszukaj}</StandardButton>
            </div>
        </div>
        <BaseSpaceSeparator size={20}/>
        <PCDataGrid<GetParkingUsersViewResultEntryDto>
            ref={grid}
            dataSource={dataSource}
            disableSorting
            columns={[
                {
                    label: Resources.Imie_i_Nazwisko,
                    dataField: "firstName",
                    width: 403,
                    renderCell: item => (
                        <PCText fontSize={16} lineHeight={20 / 16} color={Colors.brownish_grey} letterSpacing={0}
                                semibold>
                            {formatName(item)}
                            <br/>
                            {item.email}
                        </PCText>)
                },
                {
                    label: Resources.Rola,
                    dataField: "memberType",
                    width: 200,
                    renderCell: (item) => <StatusPresenter color={getColor(item.memberType)}
                                                           text={getText(item.memberType)}
                                                           textColor={getTextColor(item.memberType)}/>
                },
                {
                    label: Resources.Waznosc_od,
                    dataField: "membershipValidFrom",
                    width: 238,
                    placeholder: "-"
                },
                {
                    label: Resources.Waznosc_do,
                    dataField: "membershipValidTo",
                    width: 238,
                    placeholder: "-"
                },
                {
                    label: Resources.Data_dolaczenia,
                    dataField: "assignmentDate",
                    width: 238
                },
                {
                    width: '170',
                    label: Resources.Akcje,
                    renderCell: (item) => (<ActionColumn>
                        <ActionIcon icon={faEdit} title={Resources.Edytuj} onClick={() => onEditUser(item)}/>
                        <ActionIcon icon={faTrashAlt} title={Resources.Usun} onClick={() => onRemoveUser(item)}/>
                    </ActionColumn>)
                }
            ]}
        />
    </Body>);
}