import { ErrorCode, IVirtualPilotDto } from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import { applicationState } from "../redux/ApplicationState";
import {ListAddButton, ListContainer, ListSeparator} from "../components/Lists";
import Resources from "../../Resources";
import Spinner from "../components/Spinner";
import { getVirtualPilots } from "./Redux";
import VirtualPilotsListItem from "./VirtualPilotsListItem";
import VirtualPilotsForm from "./VirtualPilotsForm";
import SharePilotForm from "./SharePilotForm";
import ErrorView from "../components/ErrorView";
import { getErrorMessage } from "../utils/ErrorUtils";
import { useQuery } from "../utils/QueryParametersUtils";

interface OwnProps {
    onPilotShared: () => void;
}

interface Props extends OwnProps {
    progress: boolean,
    error: ErrorCode,
    items: IVirtualPilotDto[],
    onInit: () => void
}

type initialMode = "add" | "share";

const Container = (props: Props) => {
    const {error, items, onInit, progress, onPilotShared} = props;
    const [virtualFormVisible, setVirtualFormVisible] = React.useState(false);
    const [pilotToShare, setPilotToShare] = React.useState<string>(null);
    const query = useQuery();
    const initialMode: initialMode = query.get("initialMode") as initialMode;
    const pilotId = query.get("pilotId");
    const initialized = React.useRef(false);

    React.useEffect(() => {
        onInit && onInit();
    }, []);

    React.useEffect(() => {
        if(items && !initialized.current){
            if(initialMode === "add"){
                setVirtualFormVisible(true);
                initialized.current = true;
            }
            else if(initialMode === "share" && items.length){
                setPilotToShare(pilotId || items[0].virtualPilotId);
                initialized.current = true;
            }

            
        }
    }, [items])

    const onAdd = () => {
        setVirtualFormVisible(true);
    }

    const onShare = (id: string) => {
        setPilotToShare(id);
    }

    return (
        <ListContainer>
            <SharePilotForm 
                mode="user"
                onClose={() => setPilotToShare(null)}
                pilotId={pilotToShare}
                visible={!!pilotToShare}
                onSubmitted={() => {
                    setPilotToShare(null);
                    onPilotShared();
                }}
            />
            <VirtualPilotsForm onClose={() => setVirtualFormVisible(false)} visible={virtualFormVisible} />
            <ListAddButton onClick={onAdd}>{Resources.Dodaj_nowego_pilota}</ListAddButton>
            {progress && <Spinner />}
            {progress && typeof error === "number" && <ErrorView title={getErrorMessage(error)} />}
             {!progress && !error && (
                <>
                    {items.map((pilot, index, arr) => (
                        <VirtualPilotsListItem item={pilot} onShare={onShare} />                            
                    ))}
                </>
            )}
        </ListContainer>
    );
}

const mapStateToProps = (state: applicationState, ownProps: OwnProps): Partial<Props> => ({
    progress: state.virtualPilots.gettingPilotsProgress,
    error: state.virtualPilots.gettingPilotsError,
    items: state.virtualPilots.pilots || [],
    ...ownProps
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
    onInit: () => dispatch(getVirtualPilots())
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);