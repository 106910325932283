import simplePayload from "../redux/SimplePayload";
import ParkCashApplicationMode, {
    IParkCashApplicationMode, NullParkCashApplicationMode
} from "../utils/ParkCashApplicationMode";
import ThunkFunction from "../redux/ThunkFunction";
import LocalStorageManager from "../utils/LocalStorageManager";
import {getUserInfo, getUserParkings, getUserRoles} from "./Utils";
import {ErrorCode, LoginType, ModifyUserDto, ServiceConfig, UsersService} from "parkcash-api";
import {History} from "history";
import {getErrorMessage} from "../utils/ErrorUtils";
import {getInterfaceLanguage} from "../culture-helper";

export type UserActions = "Logout" | "InitUser" | "ChangeUserMode" | "BankAccountChanged" | "UserInfoChanged" | "ProfileIdChanged";

export interface userState {
    language: string;
    token: string;
    userId: string;
    applicationMode: IParkCashApplicationMode;
    bankAccountNumber: string,
    email: string,
    firstName: string,
    lastName: string,
    loginType: LoginType,
    phoneNumber: string,
    profileId: string,
    emailConfirmed: boolean,

    hideGroups: boolean,
    hideParkingSpots: boolean,
    hideParkingSpotsCreationButton: boolean,
    hidePayments: boolean,
    hidePilotsManagement: boolean,
    hidePriceWhenFree: boolean,
    hideProfileId: boolean,
    hideQrCodeReader: boolean,
    hideWallet: boolean
} 

interface initUserPayload extends simplePayload {
    token: string,
    userId: string;
    applicationMode: IParkCashApplicationMode;
    bankAccountNumber: string,
    email: string,
    firstName: string,
    lastName: string,
    loginType: LoginType,
    phoneNumber: string,
    profileId: string,
    emailConfirmed: boolean,

    hideGroups: boolean,
    hideParkingSpots: boolean,
    hideParkingSpotsCreationButton: boolean,
    hidePayments: boolean,
    hidePilotsManagement: boolean,
    hidePriceWhenFree: boolean,
    hideProfileId: boolean,
    hideQrCodeReader: boolean,
    hideWallet: boolean
}

export const initUser = (jwt: string, userId: string, success: () => void, fail: (err: string) => void): ThunkFunction => async (dispatch) => {
    
    try{
        const parkingId = LocalStorageManager.GetCurrentParkingId();
        const [roles, parkings, userInfo] = await Promise.all([getUserRoles(jwt), getUserParkings(jwt), getUserInfo(jwt)]);
        if(typeof roles !== "string" && typeof parkings !== "string" && typeof userInfo !== "string"){
            LocalStorageManager.SetUserToken(jwt);
            const applicationMode = new ParkCashApplicationMode(roles, parkings, parkingId);
            dispatch<initUserPayload>({
                applicationMode,
                token: jwt,
                userId,
                type: "InitUser",
                ...userInfo
            });
            success(); 
        }
        else{
            LocalStorageManager.ClearAll();
            if(typeof roles === "string"){
                fail(roles);
            }
            else if(typeof parkings === "string"){
                fail(parkings);
            }
            else if(typeof userInfo === "string"){
                fail(userInfo);
            }
        }
    }
    catch{
        LocalStorageManager.ClearAll();
        fail(getErrorMessage(ErrorCode.NetworkError));
    }
}

export const logout = (): ThunkFunction => (dispatch) => {
    LocalStorageManager.ClearAll();
    dispatch(<simplePayload>{type: "Logout"});
}

interface changeUserModePayload extends simplePayload {
    mode: IParkCashApplicationMode;
}

export const setCurrentAdminParking = (id: string, history: History): ThunkFunction => (dispatch, getState) => {
    const {user: {applicationMode}} = getState();

    const newApplicationMode = applicationMode.changeCurrentParking(id);
    dispatch<changeUserModePayload>({
        mode: newApplicationMode,
        type: "ChangeUserMode"
    });

    if(id && !applicationMode.isInParkingManagementMode()){
        history.push("/parkingmanagement");
    }
    else if(!id){
        history.replace("/");
    }

    !!id ? LocalStorageManager.SetCurrentParkingId(id) : LocalStorageManager.ClearCurrentParking();
}

interface bankAccountChangedPayload extends simplePayload {
    bankAccount: string
}

interface profileIdChangedPayload extends simplePayload {
    profileId: string
}

interface userInfoChangedPayload extends simplePayload {
    name: string;
    surname: string; 
    email: string;
    phoneNumber: string;
}

export const setProfileId = (profileId: string, after: (result: boolean | string) => void): ThunkFunction => async (dispatch, getState) => {
    const {user: {firstName, lastName, phoneNumber, email, token, bankAccountNumber}} = getState();

    try{
        const response = await new UsersService(new ServiceConfig({jwt: token})).modifyUser(new ModifyUserDto({
            bankAccountNumber,
            email,
            firstName,
            lastName,
            phoneNumber,
            externalProfileId: profileId 
        }));
        if(response.isSuccess){
            dispatch<profileIdChangedPayload>({type: "ProfileIdChanged", profileId})
            after(true);
        }
        else{
            after(getErrorMessage(response.error.code));
        }
        
        
    }
    catch{
        after(getErrorMessage(ErrorCode.NetworkError));
    } 
}

export const setBankAccount = (bankAccount: string, after: (result: boolean | string) => void): ThunkFunction => async (dispatch, getState) => {
    const {user: {firstName, lastName, phoneNumber, email, token, profileId}} = getState();

    try{
        const response = await new UsersService(new ServiceConfig({jwt: token})).modifyUser(new ModifyUserDto({
            bankAccountNumber: bankAccount,
            email,
            firstName,
            lastName,
            phoneNumber,
            externalProfileId: profileId || undefined
        }));
        if(response.isSuccess){
            dispatch<bankAccountChangedPayload>({type: "BankAccountChanged", bankAccount})
            after(true);
        }
        else{
            after(getErrorMessage(response.error.code));
        }
        
        
    }
    catch{
        after(getErrorMessage(ErrorCode.NetworkError));
    } 
}

export const setUserInfo = (name: string, surname: string, email: string, phoneNumber: string, after: (result: boolean | string) => void): ThunkFunction => async (dispatch, getState) => {
    const {user: {token, bankAccountNumber, profileId}} = getState();
    try{
        const response = await new UsersService(new ServiceConfig({jwt: token})).modifyUser(new ModifyUserDto({
            bankAccountNumber,
            email,
            firstName: name,
            lastName: surname || undefined,
            phoneNumber,
            externalProfileId: profileId || undefined
        }));
        if(response.isSuccess){
            dispatch<userInfoChangedPayload>({type: "UserInfoChanged", name, surname, email, phoneNumber})
            after(true);        
        }
        else{
            after(getErrorMessage(response.error.code))
        }
    }
    catch{
        after(getErrorMessage(ErrorCode.NetworkError));
    } 
}

const initialState: userState = {
    language: getInterfaceLanguage(),
    token: null,
    userId: null,
    applicationMode: NullParkCashApplicationMode.getInstance(),
    bankAccountNumber: null,
    email: null,
    firstName: null,
    lastName: null,
    loginType: null,
    phoneNumber: null,
    profileId: null,
    emailConfirmed: false,

    hideGroups: undefined,
    hideParkingSpots: undefined,
    hideParkingSpotsCreationButton: undefined,
    hidePayments: undefined,
    hidePilotsManagement: undefined,
    hidePriceWhenFree: undefined,
    hideProfileId: undefined,
    hideQrCodeReader: undefined,
    hideWallet: undefined
}

export default (state: userState = initialState, action: simplePayload): userState  => {
    switch(action.type){
        case "InitUser":
            const {type, ...initUserData} = action as initUserPayload;
            return {
                ...state,
                ...initUserData
            }
        case "ChangeUserMode":
            return {
                ...state,
                applicationMode: (action as changeUserModePayload).mode
            }
        case "BankAccountChanged":
            return {
                ...state,
                bankAccountNumber: (action as bankAccountChangedPayload).bankAccount
            }
        case "ProfileIdChanged":
            return {
                ...state,
                profileId: (action as profileIdChangedPayload).profileId
            }
        case "UserInfoChanged":
            return {
                ...state,
                firstName: (action as userInfoChangedPayload).name,
                lastName: (action as userInfoChangedPayload).surname,
                email: (action as userInfoChangedPayload).email,
                phoneNumber: (action as userInfoChangedPayload).phoneNumber
            }
        case "Logout":
            return {
                ...initialState
            };       
        default:
            return state;
    }
}