import React from "react";
import {IGetParkingParkingSpotsViewResultEntryDto, ParkingSpotType} from "parkcash-api";
import Stack from "@mui/material/Stack";
import {faChargingStation, faUserCog, faUserShield, faUserTie, faWheelchair} from "@fortawesome/free-solid-svg-icons";
import {ActionIcon} from "../../../components/PCDataGrid/ActionColumn";
import Resources from "../../../../Resources";
import {getSpotsPossibleVehiclesIcons} from "./MapParkingSpotSizeTypeToIcon";
import {IconifyStyledForSpotsIcons} from "./IconifyStyledForSpotsIcons";

interface Props {
    spot: IGetParkingParkingSpotsViewResultEntryDto;
}

export const SpotNotAsigmentRelatedFeaturesComponent = (props: Props) => {
    const {
        electricCharger,
        isForHandicapped,
        isForMaintanceStaff,
        onlyAdminCanMakeReservations,
        isForGuest,
        sizeType,
        level,
        isMonitored,
        isGuarded,
        type,
        preferTariffPrice
    } = props?.spot ?? {};
    const VehicleTypeIcon = getSpotsPossibleVehiclesIcons(sizeType);

    const isGarrage = type === ParkingSpotType.Garage;
    return (<Stack direction={'row'} spacing={2} width={"auto"} height={"100%"} alignItems={"center"}>
        {VehicleTypeIcon}
        {preferTariffPrice &&
            <IconifyStyledForSpotsIcons icon="mdi:invoice-schedule" title={Resources.Cena_wyliczana_na_podstawie_cennika_parkingu}/>}
        {isGarrage && <IconifyStyledForSpotsIcons icon="mdi:garage-variant" title={Resources.Garaz_w_budynku}/>}
        {isMonitored && <IconifyStyledForSpotsIcons icon="icon-park-twotone:videocamera-one"
                                                    title={Resources.Miejsce_monitorowane}/>}
        {isGuarded &&
            <IconifyStyledForSpotsIcons icon="healthicons:security-worker" title={Resources.Miejsce_strzezone}/>}
        {electricCharger &&
            <ActionIcon icon={faChargingStation} title={Resources.Ladowarka_dla_pojazdow_elektrycznych}/>}
        {isForHandicapped && <ActionIcon icon={faWheelchair} title={Resources.Miejsce_dla_niepelnosprawnych}/>}
        {isForMaintanceStaff && <ActionIcon icon={faUserCog} title={Resources.Miejsce_dla_obslugi}/>}
        {isForGuest && <ActionIcon icon={faUserTie} title={Resources.Miejsce_dla_gosci}/>}
        {onlyAdminCanMakeReservations &&
            <ActionIcon icon={faUserShield} title={Resources.Rezerwacja_tylko_przed_administratora}/>}
    </Stack>)
}