import { FormikHelpers, FormikProps } from "formik";
import {
  AvailibilityStatus,
  CreateSubscriptionDto,
  ErrorCode,
  GetParkingSubscriptionsResultEntryDto,
  GetParkingSubscriptionsViewResultEntryDto,
  ModifySubscriptionDto,
  ParkingSpotsAvailibilityEntry,
  PaymentMethod,
  ServiceConfig,
  SpotSelectorType,
  SubscriptionPeriodType,
  SubscriptionsService,
} from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import ModalForm from "../../components/ModalForm";
import {
  CreateAdminSubscriptionFormState,
  CreateSubscriptionSpecyficFormState,
} from "../../otherPages/CreateSubscription/CreateSuscriptionFormState";
import {
  buyAdminSubscription,
  getAvailableSpotsForSubscription,
  getBuyForAdminFormState,
  getBuyForAdminFormValidationSchema,
  getSubscriptionEffectivePrice,
  getSubscriptions,
  getTimeRangeForSubscription,
} from "../../otherPages/SubscriptionsUtils";
import Spinner from "../../components/Spinner";
import ErrorView from "../../components/ErrorView";
import TargetPart from "../SubscriptionsCommon/TargetPart";
import InvoicePart from "../SubscriptionsCommon/InvoicePart";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import { validateWithUser } from "../../parkingSpots/ParkingSpotForm/Utils";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import Colors from "../../styles/Colors";
import { PCClasses } from "../../utils/CSSUtils";
import IconButton from "../../components/IconButton";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import { formatSpot } from "../../components/ParkingSpotNumberPresenter";
import RegularDateTimePicker from "../../components/forms/RegularDateTimePicker";
import Enumerable from "linq";
import moment from "moment";
import {
  generateMonths,
  getTime,
  monthsMap,
} from "../../utils/DateTimeUtils";
import FormLabel from "../../components/FormLabel";
import Notify from "../../utils/Notify";
import { getErrorMessage } from "../../utils/ErrorUtils";
import ChoosePlaceFromMap from "../../components/ChoosePlaceFromMap";
import memoizee from "memoizee";
import RegularInput from "../../components/forms/RegularInput";
import { getSpotInfo } from "../../components/ParkingMap/Data";
import { SubscriptionTimeRangeInfo } from "../../otherPages/formParts/PrologongSubscriptionSpecyficPart";
import { number } from "prop-types";

const getAvailableNumberOfPeriods = memoizee((n: number) => {
  const result: { id: number; text: string }[] = [];
  for (let i = 1; i <= n; i++) {
    result.push({ id: i, text: i.toString() });
  }
  return result;
});

const subscription_type = require("../../../assets/images/subscription_type.svg");

const getMonths = memoizee(() => {
  const currentMonth =new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  let date = new Date();
  date.setMonth(date.getMonth() - 6)
  return generateMonths(24,   date).map((m) => ({
    id: m,
    text: `${monthsMap()[m.getMonth()]} ${m.getFullYear()}`,
    className: m<currentMonth? "pc-dropdownlist-link-lower-opacity" : "",
  }));
});

const SpecyficPart = (props: {
  args: FormikProps<CreateAdminSubscriptionFormState>;
  availableSubscriptions: GetParkingSubscriptionsResultEntryDto[];
  parkingId: string;
  onSpotMetadata: (number: string, sector: string, level: string) => void;
}) => {
  const { args, availableSubscriptions, parkingId, onSpotMetadata } = props;
  const { values, touched, errors, setFieldTouched, setFieldValue } = args;
  const [spots, setSpots] = React.useState<ParkingSpotsAvailibilityEntry[]>([]);
  const [number, setNumber] = React.useState<string>(null);
  const [level, setLevel] = React.useState<string>(null);
  const [sector, setSector] = React.useState<string>(null);
  const subscription = Enumerable.from(availableSubscriptions).firstOrDefault(
    (s) => s.id === values.subscriptionId
  );
  const currentDate = getTime(new Date(), 0, 0);
  const fromMax = !!subscription?.maxAllowedForwardPurchase
    ? moment(currentDate)
        .add(moment.duration(subscription.maxAllowedForwardPurchase))
        .toDate()
    : undefined;

  const setSpotMetadata = React.useCallback(
    (number: string, sector: string, level: string) => {
      setNumber(number);
      setSector(sector);
      setLevel(level);
      onSpotMetadata(number, sector, level);
    },
    [onSpotMetadata]
  );

  const retrieveSpots = async (
    parId: string,
    subId: string,
    customStart: Date,
    numberOfPeriods: number
  ) => {
    const { spots, error } = await getAvailableSpotsForSubscription(
      parId,
      subId,
      numberOfPeriods,
      customStart
    );
    if (error) {
      Notify.Error(getErrorMessage(error));
      setSpots([]);
    } else {
      setSpots(spots);
    }
  };

  const onMap = async () => {
    const s = await ChoosePlaceFromMap.show({
      currentSpotId: values.spotId,
      parkingId,
      getSpotsCallback: async (level, sector) => {
        const { error, spots } = await getAvailableSpotsForSubscription(
          parkingId,
          values.subscriptionId,
          values.numberOfPeriods,
          values.from,
          level,
          sector
        );
        if (error) {
          return { error };
        } else {
          return {
            spots: spots.map((s) => ({
              status: s.isAvailable ? AvailibilityStatus.Free : null,
              id: s.id,
              number: s.number,
              level: s.level,
              sector: s.sector,
            })),
          };
        }
      },
    });
    if (s) {
      const { spotId, number, sector, level } = s;
      setFieldValue("spotId", spotId);
      setSpotMetadata(number, sector, level);
    }
  };

  React.useEffect(() => {
    setSpots([]);
    if (
      values.subscriptionId &&
      values.enableParticularSpotPurchase &&
      values.from &&
      values.spotSelectorType === SpotSelectorType.List
    ) {
      retrieveSpots(
        parkingId,
        values.subscriptionId,
        values.from,
        values.numberOfPeriods
      );
    }
  }, [
    values.subscriptionId,
    values.enableParticularSpotPurchase,
    values.from,
    values.numberOfPeriods,
  ]);

  React.useEffect(() => {
    const getTimeRange = async (sId: string, fr: Date, nOP: number) => {
      setFieldValue("targetFrom", null);
      setFieldValue("targetTo", null);
      const {error, from, to} = await getTimeRangeForSubscription(
          sId,
          nOP,
          fr
      );
      if (error) {
        Notify.Error(getErrorMessage(error));
      } else {
        setFieldValue("targetFrom", from);
        setFieldValue("targetTo", to);
      }
    };

    if (values.subscriptionId) {
      getTimeRange(values.subscriptionId, values.from, values.numberOfPeriods);
    }
  }, [values.subscriptionId, values.from, values.numberOfPeriods]);

  React.useEffect(() => {
    if (values.spotId) {
      getSpotInfo(values.spotId).then((v) => {
        const { error, spot } = v;
        if (error) {
          Notify.Error(getErrorMessage(error));
          setSpotMetadata(null, null, null);
        } else {
          setSpotMetadata(spot.number, spot.sector, spot.level);
        }
      });
    } else {
      setSpotMetadata(null, null, null);
    }
  }, [values.spotId]);

  React.useEffect(() => {
    if (
      subscription?.periodType === SubscriptionPeriodType.GivenFullCalendarMonth
    ) {
      const now = new Date();
      setFieldValue("from", new Date(now.getFullYear(), now.getMonth()));
    } else {
      setFieldValue("from", new Date());
    }
  }, [subscription?.periodType]);

  React.useEffect(() => {
    const getPrice = async (
      subId: string,
      spotId: string,
      enablePartSpotPur: boolean,
      numberOfPeriods: number
    ) => {
      setFieldValue("customPrice", "");
      // if(enablePartSpotPur && !spotId){
      //     return;
      // }
      const { price, message } = await getSubscriptionEffectivePrice(
        subId,
        null,
        spotId,
        numberOfPeriods
      );
      if (message) {
        //Notify.Error(message);
      } else {
        setFieldValue("customPrice", price.toString());
      }
    };

    getPrice(
      values.subscriptionId,
      values.spotId,
      values.enableParticularSpotPurchase,
      values.numberOfPeriods
    );
  }, [
    values.subscriptionId,
    values.numberOfPeriods,
    values.spotId,
    values.enableParticularSpotPurchase,
  ]);

  return (
    <>
      <FormLabel>{Resources.Wybierz_abonament}:</FormLabel>
      <BaseSpaceSeparator size={5} />
      <RegularDropdownList
        borderColor={Colors.brownish_grey}
        zIndex={100}
        minHeight={80}
        value={values.subscriptionId}
        touched={touched.subscriptionId}
        error={errors.subscriptionId}
        onChange={() => {
          setFieldValue("customPrice", "");
        }}
        name="subscriptionId"
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        actions={availableSubscriptions.map((a) => ({
          metadata: a,
          id: a.id,
        }))}
      >
        {(action, isOption, handleClick) => {
          const { metadata } = action;
          const { description, name, discountedPrice, price, periodType } =
            metadata as GetParkingSubscriptionsResultEntryDto;
          return (
            <div
              onClick={isOption ? handleClick : undefined}
              className={isOption ? PCClasses("pc-button") : undefined}
              style={{ padding: isOption ? "10px 0px" : undefined }}
            >
              <PCText semibold fontSize={18} color={Colors.light_royal_blue}>
                {name}
                {periodType === SubscriptionPeriodType.NumberOfDays
                  ? `: ${discountedPrice || price} ${Resources.zl}`
                  : ""}
              </PCText>
              <BaseSpaceSeparator size={5} />
              <PCText fontSize={14} color={Colors.brownish_grey}>
                {description}
              </PCText>
            </div>
          );
        }}
      </RegularDropdownList>
      <SubscriptionTimeRangeInfo
        periodType={subscription?.periodType}
        validFrom={values.targetFrom}
        validTo={values.targetTo}
      />
      <BaseSpaceSeparator size={20} />
      {values.enableParticularSpotPurchase && (
        <>
          <FormLabel>
            {Resources.Miejsce_dla_ktorego_chcesz_wykupic_abonament}
          </FormLabel>
          <BaseSpaceSeparator size={5} />
          <div style={{ display: "flex", alignItems: "center" }}>
            {values.spotSelectorType === SpotSelectorType.List && (
              <div style={{ flex: 1 }}>
                <RegularDropdownList
                  zIndex={50}
                  borderColor={Colors.brownish_grey}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  value={values.spotId}
                  error={errors.spotId}
                  touched={touched.spotId}
                  name="spotId"
                  actions={spots.map((spot) => ({
                    id: spot.id,
                    text: formatSpot({
                      spotNumber: spot.number,
                      level: spot.level,
                    }),
                  }))}
                  maxContentHeight={300}
                />
              </div>
            )}
            {values.spotSelectorType === SpotSelectorType.Map && (
              <>
                <PCText fontSize={12} semibold color={Colors.brownish_grey}>
                  {formatSpot({ spotNumber: number, level, sector }) ||
                    Resources.Nie_wybrales_miejsca}
                </PCText>
                <BaseSpaceSeparator size={15} />
                <IconButton
                  icon={faMap}
                  onClick={onMap}
                  style={{
                    width: 40,
                    height: 40,
                    backgroundColor: Colors.light_royal_blue,
                    borderRadius: 20,
                  }}
                />
              </>
            )}
          </div>
          {values.spotSelectorType === SpotSelectorType.Map && (
            <div
              style={{
                paddingTop: 4,
                paddingBottom: 4,
                height: 20,
                boxSizing: "border-box",
              }}
            >
              <PCText
                fontSize={12}
                lineHeight={1}
                letterSpacing={0}
                color={Colors.red}
              >
                {touched.spotId && errors.spotId ? errors.spotId : ""}
              </PCText>
            </div>
          )}
        </>
      )}
      <BaseSpaceSeparator size={20} />
      {subscription?.periodType ===
        SubscriptionPeriodType.GivenFullCalendarMonth && (
        <>
          <FormLabel>
            {Resources.Miesiac_od_ktorego_abonament_ma_obowiazywac}
          </FormLabel>
          <BaseSpaceSeparator size={5} />
          <RegularDropdownList<Date>
            zIndex={20}
            borderColor={Colors.brownish_grey}
            value={values.from}
            error={errors.from as any}
            touched={touched.from as any}
            name="from"
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            actions={getMonths()}
            maxContentHeight={300}
            comparator={(d1, d2) =>
              d1.getFullYear() === d2.getFullYear() &&
              d1.getMonth() === d2.getMonth()
            }
          />
        </>
      )}
      {subscription?.periodType !==
        SubscriptionPeriodType.GivenFullCalendarMonth && (
        <RegularDateTimePicker
          value={values.from}
          error={errors.from}
          touched={touched.from}
          name="from"
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          mode="date"
          label={Resources.Poczatek_parkowania}
          min={currentDate}
          max={fromMax}
        />
      )}
      <RegularDropdownList
        zIndex={10}
        maxContentHeight={250}
        borderColor={Colors.brownish_grey}
        actions={getAvailableNumberOfPeriods(10)}
        error={errors.numberOfPeriods}
        name="numberOfPeriods"
        touched={touched.numberOfPeriods}
        value={values.numberOfPeriods}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        label={
          subscription?.periodType === SubscriptionPeriodType.NumberOfDays
            ? Resources.Liczba_okresow
            : Resources.Liczba_miesiecy
        }
      />
      <RegularInput
        label={Resources.Cena + `(${Resources.zl})`}
        error={errors.customPrice}
        value={values.customPrice}
        touched={touched.customPrice}
        name="customPrice"
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
      />
    </>
  );
};

export default (props: {
  visible: boolean;
  onClose: () => void;
  onSubmitted: () => void;
  parkingId: string;
  spotId?: string;
  jwt: string;
}) => {
  const {
    visible,
    onClose,
    parkingId,
    jwt,
    onSubmitted,
    spotId = null,
  } = props;
  const [initialValues, setInitialValues] =
    React.useState<CreateAdminSubscriptionFormState>(
      getBuyForAdminFormState(false, SpotSelectorType.List, null, false, spotId)
    );
  const [error, setError] = React.useState<ErrorCode>(null);
  const [noSubscriptionsTypes, setNoSubscriptionsTypes] = React.useState(false);
  const [progress, setProgress] = React.useState(true);
  const [availableSubscriptions, setAvailableSubscriptions] = React.useState<
    GetParkingSubscriptionsViewResultEntryDto[]
  >([]);
  const [headerTitle, setHeaderTitle] = React.useState("");

  const onSpotMetadata = React.useCallback(
    (spotNumber: string, level: string, sector: string) => {
      setHeaderTitle(formatSpot({ spotNumber, level, sector }));
    },
    []
  );

  const init = async (pId: string, sId: string) => {
    setProgress(true);
    setError(null);
    setNoSubscriptionsTypes(false);
    const {
      enableParticularSpotPurchase,
      errorCode,
      spotSelectorType,
      subscriptions,
      requireLicensePlateNumber,
    } = await getSubscriptions(pId);
    if (typeof error === "number") {
      setError(errorCode);
    } else if (!subscriptions.length) {
      setNoSubscriptionsTypes(true);
    } else {
      setInitialValues(
        getBuyForAdminFormState(
          enableParticularSpotPurchase,
          spotSelectorType,
          subscriptions[0].id,
          requireLicensePlateNumber,
          sId
        )
      );
      setAvailableSubscriptions(subscriptions);
    }
    setProgress(false);
  };

  React.useEffect(() => {
    if (visible) {
      init(parkingId, spotId);
    }
  }, [parkingId, spotId, visible]);

  const onSubmit = async (
    state: CreateAdminSubscriptionFormState,
    helpers: FormikHelpers<CreateAdminSubscriptionFormState>
  ) => {
    helpers.setSubmitting(true);
    const { result, message } = await buyAdminSubscription(state, jwt);
    if (result) {
      onSubmitted();
      Notify.Success(Resources.Dokonano_zakupu);
    } else {
      helpers.setStatus(message);
    }
    helpers.setSubmitting(false);
  };

  return (
    <ModalForm
      icon={subscription_type}
      iconHeight={105}
      iconWidth={120}
      visible={visible}
      onClose={onClose}
      title={Resources.Zakup_abonamentu}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={getBuyForAdminFormValidationSchema()}
      zIndex={1999}
      showSubmitButton={!error && !noSubscriptionsTypes}
      headerTitle={headerTitle}
    >
      {(args) => {
        if (progress) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Spinner size="small" />
            </div>
          );
        }

        if (noSubscriptionsTypes) {
          return (
            <PCText
              semibold
              fontSize={18}
              textAlign="center"
              color={Colors.red}
            >
              {Resources.Nie_zdefiniowano_zadnego_rodzaju_abonamentu}
            </PCText>
          );
        }

        if (typeof error === "number") {
          return <ErrorView title={Resources.Nastapil_blad} />;
        }

        return (
          <>
            <SpecyficPart
              args={args}
              parkingId={parkingId}
              availableSubscriptions={availableSubscriptions}
              onSpotMetadata={onSpotMetadata}
            />
            <TargetPart args={args} />
            <InvoicePart args={args} />
            {!args.values.wantInvoice && (
              <RegularInput
                label={Resources.Nr_dokumentu}
                value={args.values.externalPaymentDocumentNumber}
                error={args.errors.externalPaymentDocumentNumber}
                touched={args.touched.externalPaymentDocumentNumber}
                setFieldTouched={args.setFieldTouched}
                setFieldValue={args.setFieldValue}
                name="externalPaymentDocumentNumber"
                showClearButton
                showValidatedButton
              />
            )}
            <RegularDropdownList
              value={args.values.paymentMethod}
              error={args.errors.paymentMethod}
              touched={args.touched.paymentMethod}
              name="paymentMethod"
              actions={[
                { id: PaymentMethod.BankTransfer, text: Resources.przelew },
                { id: PaymentMethod.Cash, text: Resources.gotowka },
                {
                  id: PaymentMethod.CreditCard,
                  text: Resources.karta_platnicza,
                },
              ]}
              setFieldTouched={args.setFieldTouched}
              setFieldValue={args.setFieldValue}
              label={Resources.Sposob_platnosci}
              borderColor={Colors.brownish_grey}
            />
          </>
        );
      }}
    </ModalForm>
  );
};
