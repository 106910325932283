import memoizee from "memoizee";


export const getAspectRatioFromViewBox = memoizee((viewBox: string) => {
    if(!viewBox){
        return 1;
    }

    const {height, width} = getWidthAndHeightFromViewBox(viewBox);

    if(!width){
        throw new Error("wrong viewBox width");
    }
    if(!height){
        throw new Error("wrong viewBox height");
    }

    return height/width;
});

export const getWidthAndHeightFromViewBox = memoizee((viewBox: string) => {
    const parts = viewBox.split(" ");
    if(parts.length !== 4){
        throw new Error("wrong viewBox format");
    }

    const width = parseInt(parts[2]);
    const height = parseInt(parts[3]);

    return {width, height}
});

const zoomMap = {
    [-5]: 0.1,
    [-4]: 0.3,
    [-3]: 0.5,
    [-2]: 0.7,
    [-1]: 0.8,
    0: 1,
    1: 1.25,
    2: 1.43,
    3: 2,
    4: 3,
    5: 10,
}

export const getTransformMatrix = (currentZoom: number, currentZoomX: number, currentZoomY: number, viewBox: string) => {
    const {height, width} = getWidthAndHeightFromViewBox(viewBox);
    const scale = zoomMap[currentZoom]
    const transformMatrix = [1, 0 ,0, 1, 0, 0];

    const centerX = width / 2;
    const centerY = height / 2;

    for (var i = 0; i < 5; i++) {
        transformMatrix[i] *= scale;
    }
    transformMatrix[4] += (1 - scale) * centerX + scale*(centerX-currentZoomX);
    transformMatrix[5] += (1 - scale) * centerY + scale*(centerY-currentZoomY);
                    
    return transformMatrix;
}
