import {useCallback, useState} from 'react';
// utils
import {durationIsBiggerThanAllowedDays, fDate, setTimeToDayEnd, setTimeToDayStart} from '../../utils/format-time';
//
import {shortDateLabel} from './utils';
import {CustomDateRangePickerProps} from "./custom-date-range-picker";
import Resources from "../../../../Resources";

// ----------------------------------------------------------------------
const getErrorMessages = (start: Date | null, end: Date | null): Array<string> => {
    const errorMessages = [];
    if (start && end) {
        if (new Date(start).getTime() > new Date(end).getTime()) {
            errorMessages.push(Resources.Data_rozpoczecia_musi_byc_mniejsza_od_daty_zakonczenia);
        }
        if (durationIsBiggerThanAllowedDays(start, end, 366)) {
            errorMessages.push(Resources.Maksymalny_zakres_wynosi__366_dni);
        }

    }
    return errorMessages;
}

type ReturnType = CustomDateRangePickerProps;

export default function useDateRangePicker(start: Date | null, end: Date | null): ReturnType {
    const [open, setOpen] = useState(false);

    const [endDate, setEndDate] = useState(setTimeToDayEnd(end));
    const [oldEndDate, setOldEndDate] = useState(setTimeToDayEnd(end));
    const [oldStartDate, setOldStartDate] = useState(setTimeToDayStart(start));
    const [startDate, setStartDate] = useState(setTimeToDayStart(start));
    const [dateRangeSelected, setDateRangeSelected] = useState<boolean>(!!startDate && !!endDate);
    const errorMessages = getErrorMessages(startDate, endDate);
    const error = errorMessages.length > 0;

    const onOpen = useCallback(() => {
        setDateRangeSelected(false);
        setOldEndDate(endDate);
        setOldStartDate(startDate);
        setOpen(true);
    }, [
        endDate,
        startDate
    ]);

    const onClose = useCallback(() => {
        setOpen(false);
    }, []);

    const onCanceled = useCallback(() => {
        setStartDate(oldStartDate);
        setEndDate(oldEndDate);
        onClose();

    }, [
        oldStartDate,
        oldEndDate,
        onClose
    ])
    const onConfirmed = useCallback(() => {
        setDateRangeSelected(true);
        onClose();
    }, [onClose]);

    const onChangeStartDate = useCallback((newValue: Date | null) => {
        setStartDate(setTimeToDayStart(newValue));
    }, [setTimeToDayStart]);

    const onChangeEndDate = useCallback(
        (newValue: Date | null) => {
            if (error) {
                setEndDate(null);
            }
            setEndDate(setTimeToDayEnd(newValue));
        },
        [
            error,
            setTimeToDayEnd
        ]
    );

    const onReset = useCallback(() => {
       
        setStartDate(null);
        setEndDate(null);
    }, []);

    const onResetSelectionState = useCallback(() => {
        setDateRangeSelected(!!startDate && !!endDate)
       
    }, [startDate, endDate]);
    
    

    return {
        startDate,
        endDate,
        dateRangeSelected,
        setDateRangeSelected,
        onChangeStartDate,
        onChangeEndDate, //
        open,
        onOpen,
        onCanceled,
        onConfirmed,
        onClose,
        onReset, //
        onResetSelectionState, //
        selected: !!startDate && !!endDate,
        error,
        errorMessages, //
        label: `${fDate(startDate)} - ${fDate(endDate)}`,
        shortLabel: shortDateLabel(startDate, endDate), //
        setStartDate,
        setEndDate,
    };
}
