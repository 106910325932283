import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import BigButton from "../components/BigButton";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { MOBILE_WIDTH_THRESHOLD } from "../utils/Constants";
import { useWindowSize } from "../utils/WindowSizeHook";

export default (props: {
    image: any,
    imageWidth: number,
    imageHeight: number,
    text: string,
    buttonText: React.ReactNode,
    onButtonClick: () => void
}) => {
    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const {buttonText, image, imageHeight, imageWidth, onButtonClick, text} = props;

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <img height={imageHeight} width={imageWidth} src={image} />
            <BaseSpaceSeparator size={isMobile ? 20 : 66} />
            <div style={{width: isMobile ? 280 : 600}}>
                <PCText semibold fontSize={36} color={Colors.black} textAlign="center">
                    {text}
                </PCText>
            </div>
            <BaseSpaceSeparator size={isMobile ? 20 : 100} />
            <BigButton 
                onClick={onButtonClick}
                icon={faPlusCircle}
                width={150}
                height={150}
                title={buttonText}
                titleFontSize={18}
                titleLetterSpacing={0}
                paddingHorizontal={15}
            />
        </div>
    )
}