import * as React from "react";
import Resources from "../../../Resources";
import Body from "../../layouts/Main/Body";
import {PCClasses} from "../../utils/CSSUtils";
import {useUser} from "../UseUserHook";
import Payments from "./Payments";
import "./Profile.scss";
import UserInfo from "./UserInfo";
import Vehicles from "./Vehicles";

export default () => {
    const {hidePayments} = useUser();

    return (
        <Body title={Resources.Profil_uzytkownika}>
            <div className={PCClasses("pc-profile-container")}>
                
                
                <UserInfo />
                <Vehicles />
                {!hidePayments && <Payments />}
                
            </div>
        </Body>
    )
}