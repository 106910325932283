import { FormikHelpers, FormikProps } from "formik";
import {
  ErrorCode,
  GetParkingReservationPolicyResult,
  IErrorWrapperOfObject,
  ManagerRegisterGuestReservationDto,
  ParkingManagerService,
  ParkingSpotsAvailibilityEntry,
  ParkingsService,
  ReservationPolicyType,
  ServiceConfig,
} from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import ErrorView from "../../components/ErrorView";
import ModalForm from "../../components/ModalForm";
import Spinner from "../../components/Spinner";
import { getErrorMessage } from "../../utils/ErrorUtils";
import RegularInput from "../../components/forms/RegularInput";
import { transformLicencePlateNumber } from "../../utils/LicencePlateNumberUtils";
import {EMAIL_REGEX, PHONE_MASK, PHONE_MASK_WITHOUT_COUNTRY_CODE, PHONE_REGEX} from "../../utils/Constants";
import RegularTextArea from "../../components/forms/RegularTextArea";
import RegularDateTimePicker from "../../components/forms/RegularDateTimePicker";
import { formatSpot } from "../../components/ParkingSpotNumberPresenter";
import Colors from "../../styles/Colors";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import { getExternalReservationAvailableSpots } from "../../otherPages/ExternalReservationsUtils";
import Notify from "../../utils/Notify";
import { getTimeRestrictions } from "./getTimeRestrictions";
import {
    addDays,
    addHours,
    addMinutes, endOfDay,
    getTime,
    roundToHours,
} from "../../utils/DateTimeUtils";
import { getServerPhoneNumber } from "../../utils/PhoneNumberUtils";

const subscription_type = require("../../../assets/images/subscription_type.svg");

interface FormState {
  autoSendToTarget: true;
  end: Date;
  start: Date;
  parkingSpotId: string;
  targetName: string;
  description: string;
  email: string;
  licensePlateNumber: string;
  phoneNumber: string;
}

const validate = (state: FormState) => {
  const errors: { [key in keyof FormState]?: string } = {};
  const { targetName, email, phoneNumber, parkingSpotId, licensePlateNumber } =
    state;
  if (!targetName) {
    errors.targetName = Resources.Wymagane;
  }

  if (!!phoneNumber && phoneNumber.length > 2 && !PHONE_REGEX.test(phoneNumber)) {
      errors.phoneNumber = Resources.Niepoprawny_format;
  }

  if (!!email && !EMAIL_REGEX.test(email)) {
    errors.email = Resources.Niepoprawny_format;
  }

  if (!licensePlateNumber) {
    errors.licensePlateNumber = Resources.Wymagane;
  }

  if (!parkingSpotId) {
    errors.parkingSpotId = Resources.Wymagane;
  }

  return errors;
};

function getTimeRangeFromPolicy(policy: GetParkingReservationPolicyResult) {
    const start = policy
        ? policy.policyType === ReservationPolicyType.CustomRange
            ? roundToHours(new Date(), true)
            : getTime(new Date(), 0, 0)
        : new Date();
    const end = policy
        ? policy.policyType === ReservationPolicyType.CustomRange
            ? addHours(start, 1)
            : start
        : new Date();
    return {start, end};
}

const getInitialFormState = (
  policy: GetParkingReservationPolicyResult
): FormState => {
    const {start, end} = getTimeRangeFromPolicy(policy);

    return {
    autoSendToTarget: true,
    description: "",
    email: "",
    end,
    start,
    licensePlateNumber: "",
    parkingSpotId: "",
    phoneNumber: "48",
    targetName: "",
  };
};

const retrieveSpots = async (parId: string, s: Date, e: Date) => {
    const { spots, error } = await getExternalReservationAvailableSpots(
        parId,
        s,
        e
    );
    if (error) {
        Notify.Error(getErrorMessage(error));
       return []
    } else {
        return spots;
    }
};

const getParkingReservationPolicy = async (
  parkingId: string,
  jwt: string
): Promise<{
  policy?: GetParkingReservationPolicyResult;
  error?: IErrorWrapperOfObject;
}> => {
  try {
    const { isSuccess, error, result } = await new ParkingsService(
      new ServiceConfig({ jwt })
    ).getParkingReservationPolicy(parkingId);
    if (isSuccess) {
      return { policy: result };
    } else {
      return { error };
    }
  } catch {
    return { error: { code: ErrorCode.NetworkError } };
  }
};

const FormInternal = ({
  args,
  parkingPolicy,
  parkingId,
  initialSpots,
}: {
  parkingId: string;
  args: FormikProps<FormState>;
  parkingPolicy: GetParkingReservationPolicyResult;
  initialSpots: ParkingSpotsAvailibilityEntry[];
}) => {
  const { values, errors, touched, setFieldTouched, setFieldValue } = args;
  const [spots, setSpots] = React.useState<ParkingSpotsAvailibilityEntry[]>(initialSpots);

  const timeRestrictions = React.useMemo(() => {
    const restrictions = getTimeRestrictions(parkingPolicy, values.start);
    return {
      startMin: restrictions?.AllowedMinStart,
      startMax: restrictions?.AllowedMaxStart,
      endMin: restrictions?.AllowedMinEnd,
      endMax: restrictions?.AllowedMaxEnd,
    };
  }, [values.start, parkingPolicy]);
  const initialTimeRestrictions = React.useRef(timeRestrictions).current;

  return (
    <>
      <RegularDateTimePicker
        label={Resources.Poczatek_parkowania}
        value={values.start}
        touched={touched.start}
        error={errors.start}
        mode={
          parkingPolicy?.policyType === ReservationPolicyType.CustomRange
            ? "datetime"
            : "date"
        }
        name="start"
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        granulationMinutes={15}
        min={initialTimeRestrictions.startMin}
        max={initialTimeRestrictions.startMax}
        onChange={async (v) => {

            // note that we send different end date to the server than the one we display to the user
            // to select whole day we need to send the start of the next day, but it would be confusing to the user

            let effectiveEnd = values.end;
            if (v >= values.end) {
                if (
                    parkingPolicy?.policyType === ReservationPolicyType.CustomRange
                ) {
                    setFieldValue("end", addMinutes(v, 15));
                    effectiveEnd = addMinutes(v, 15);
                } else {
                    setFieldValue("end", v);
                    effectiveEnd = addDays(v, 1);
                }

            }

            let spots = await retrieveSpots(parkingId, v, effectiveEnd);
            setSpots(spots);


        }}
      />
      <RegularDateTimePicker
        label={Resources.Koniec_parkowania}
        value={values.end}
        touched={touched.end}
        error={errors.end}
        mode={
          parkingPolicy?.policyType === ReservationPolicyType.CustomRange
            ? "datetime"
            : "date"
        }
        name="end"
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        min={timeRestrictions.endMin}
        max={timeRestrictions.endMax}
        onChange={async (v) => {

            // note that we send different end date to the server than the one we display to the user
            // to select whole day we need to send the start of the next day, but it would be confusing to the user

            let effectiveEnd = v;

            if (
                parkingPolicy?.policyType === ReservationPolicyType.WholeDay
            ) {
                effectiveEnd = addDays(v, 1);
            }

            if (values.end > timeRestrictions.endMax) {
                setFieldValue("end", timeRestrictions.endMax);
                effectiveEnd = timeRestrictions.endMax;
            }
            if (values.end < timeRestrictions.endMin) {
                setFieldValue("end", timeRestrictions.endMin);
                effectiveEnd = timeRestrictions.endMin;
            }
          let spots =  await  retrieveSpots(parkingId, values.start, effectiveEnd);
          setSpots(spots);
        }}
      />
      <RegularDropdownList
        zIndex={10}
        maxContentHeight={300}
        borderColor={Colors.brownish_grey}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        value={values.parkingSpotId}
        error={errors.parkingSpotId}
        touched={touched.parkingSpotId}
        name="parkingSpotId"
        label={Resources.Miejsce_rezerwacji}
        actions={spots.map((spot) => ({
          id: spot.id,
          text: formatSpot({
            spotNumber: spot.number,
            level: spot.level, 
              sector: spot.sector
              
          }),
        }))}
      />
      <RegularInput
        label={Resources.Imie_i_nazwisko}
        value={values.targetName}
        error={errors.targetName}
        touched={touched.targetName}
        name={"targetName"}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        showClearButton
        showValidatedButton
      />
      <RegularInput
        label={Resources.Nr_rejestracyjny}
        transformText={transformLicencePlateNumber}
        value={values.licensePlateNumber}
        error={errors.licensePlateNumber}
        touched={touched.licensePlateNumber}
        name={"licensePlateNumber"}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        showClearButton
        showValidatedButton
      />
    {/*  <RegularTextArea
        label={Resources.Opis}
        value={values.description}
        error={errors.description}
        touched={touched.description}
        name={"description"}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
      />*/}
      <RegularInput
        label={Resources.Email}
        value={values.email}
        error={errors.email}
        touched={touched.email}
        name={"email"}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        showClearButton
        showValidatedButton
      />
      <RegularInput
        label={Resources.Nr_telefonu}
        value={values.phoneNumber}
        error={errors.phoneNumber}
        touched={touched.phoneNumber}
        mask={PHONE_MASK}
        name={"phoneNumber"}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        showClearButton
        showValidatedButton
      />
    </>
  );
};



export default (props: {
  visible: boolean;
  onClose: () => void;
  onSubmitted: () => void;
  parkingId: string;
  jwt: string;
}) => {
  const { jwt, onClose, parkingId, visible, onSubmitted } = props;
  const [parkingPolicy, setParkingPolicy] =
    React.useState<GetParkingReservationPolicyResult>(null);
  const [initialSpots, setInitialSpots] = React.useState<ParkingSpotsAvailibilityEntry[]>([]);
  const [initialValues, setInitialValues] = React.useState<FormState>(
    getInitialFormState(parkingPolicy)
  );
  const [error, setError] = React.useState<ErrorCode | string>(null);
  const [progress, setProgress] = React.useState(true);
console.log("in guest reservation form visibleState=", visible);

  const onSubmit = async (
    {
      autoSendToTarget,
      description: desciption,
      email,
      end,
      licensePlateNumber,
      parkingSpotId,
      phoneNumber,
      start,
      targetName,
    }: FormState,
    helpers: FormikHelpers<FormState>
  ) => {
    try {
      helpers.setSubmitting(true);

        // note that we send different end date to the server than the one we display to the user
        // to select whole day we need to send the start of the next day, but it would be confusing to the user
     if(phoneNumber?.length<=2){
         phoneNumber=null;
     }

      const response = await new ParkingManagerService(
        new ServiceConfig({ jwt })
      ).managerRegisterGuestReservation(
        new ManagerRegisterGuestReservationDto({
          autoSendToTarget,
          start:
            parkingPolicy?.policyType === ReservationPolicyType.CustomRange
              ? start
              : getTime(start, 0, 0),
          end:
            parkingPolicy?.policyType === ReservationPolicyType.CustomRange
              ? end
              : addDays(getTime(end, 0, 0), 1),
          parkingSpotId,
          targetName,
          desciption,
          email,
          licensePlateNumber,
          phoneNumber: phoneNumber ? getServerPhoneNumber(phoneNumber) : "",
        })
      );
      if (response.isSuccess) {
        onSubmitted();
      } else {
        helpers.setStatus(getErrorMessage(response.error.code));
      }
    } catch {
      helpers.setStatus(getErrorMessage());
    } finally {
      helpers.setSubmitting(false);
    }
  };

  React.useEffect(() => {
    const init = async () => {
      if (visible) {
        setProgress(true);
        const { error, policy } = await getParkingReservationPolicy(
          parkingId,
          jwt
        );
        if (error) {
          setError(error.code);
        } else {
          setParkingPolicy(policy);
          const timeRange = getTimeRangeFromPolicy(policy);
            if (
                policy?.policyType === ReservationPolicyType.WholeDay
            ) {
                timeRange.end = addDays(timeRange.end, 1);
            }
          const spots = await retrieveSpots(parkingId, timeRange.start, timeRange.end);
          setInitialSpots(spots);
          const initialValues = getInitialFormState(policy);
          setInitialValues(initialValues);
        }
        setProgress(false);
      }
    };
    init();
  }, [visible, parkingId, jwt]);

  return (
    <ModalForm
      icon={subscription_type}
      iconHeight={105}
      iconWidth={120}
      visible={visible}
      onClose={onClose}
      title={Resources.Rezerwacja}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      zIndex={1999}
      validate={validate}
      showBottomSeparator={false}
    >
      {(args) => {
        if (progress) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Spinner size="small" />
            </div>
          );
        }

        if (typeof error === "number") {
          return <ErrorView title={getErrorMessage(error)} />;
        }

        if (typeof error === "string") {
          return <ErrorView title={error} />;
        }

          return (
          <FormInternal
            args={args}
            parkingPolicy={parkingPolicy}
            parkingId={parkingId}
            initialSpots={initialSpots}
          />
        );
      }}
    </ModalForm>
  );
};
