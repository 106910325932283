import { SortOrder } from "parkcash-api";

export type DataSourceEvents = "loadingStarted" | "loaded" | "loadingError";

export interface DataSourceLoadedArgs<T> {
  items: T[];
  page: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  sort: Array<{ key: keyof T; order: SortOrder }>;
  filter: Array<{ key: keyof T; value: any }>;
}

/*
==	Equals
!=	Not equals
>	Greater than
<	Less than
>=	Greater than or equal to
<=	Less than or equal to
@=	Contains
_=	Starts with
!@=	Does not Contains
!_=	Does not Starts with
@=*	Case-insensitive string Contains



*/

export enum FilterOperator {
  EQUALS = "==",
  NOT_EQUALS = "!=",
  GREATER_THAN = ">",
  LESS_THAN = "<",
  GREATER_THAN_OR_EQUAL_TO = ">=",
  LESS_THAN_OR_EQUAL_TO = "<=",
  CONTAINS = "@=",
  STARTS_WITH = "_=",
  DOES_NOT_CONTAINS = "!@=",
  DOES_NOT_STARTS_WITH = "!_=",
  CASE_INSENSITIVE_STRING_CONTAINS = "@=*",
  CASE_INSENSITIVE_STRING_STARTS_WITH = "_=*",
  CASE_INSENSITIVE_STRING_EQUALS = "==*",
  CASE_INSENSITIVE_STRING_NOT_EQUALS = "!=*",
  CASE_INSENSITIVE_STRING_DOES_NOT_CONTAINS = "!@=*",
  CASE_INSENSITIVE_STRING_DOES_NOT_STARTS_WITH = "!_=*",
}

export interface IDataSource<T> {
  on: (event: DataSourceEvents, callback: (args: any) => void) => void;
  off: (event: DataSourceEvents, callback: (args: any) => void) => void;
  clearAllEvents: () => void;

  load: (page: number, pageSize: number) => void;
  reload();

  filter: (key: keyof T, operator: FilterOperator, value: any) => void;
  removeFilter: (key: keyof T) => void;
  clearFilters: () => void;

  addSort: (key: keyof T, order: SortOrder) => void;
  removeSort: (key: keyof T) => void;
  clearSorts: () => void;

  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;

  getPage();
  getPageSize();
}
