import { FormikHelpers, FormikProps } from "formik";
import {
  ErrorCode,
  GetSubscriptionProlongationInfoResult,
  PaymentMethod,
  SubscriptionPeriodType,
} from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import ErrorView from "../../components/ErrorView";
import ModalForm from "../../components/ModalForm";
import Spinner from "../../components/Spinner";
import { ProlongAdminSubscriptionFormState } from "../../otherPages/ProlongSubscription/ProlongSubscriptionFormState";
import {
  getProlongForAdminFormState,
  getProlongForAdminFormValidationSchema,
  getSubscriptionEffectivePrice,
  getSubscriptionProlongationInfo,
  getTimeRangeForSubcriptionProlongation,
  prolongAdminSubscription,
} from "../../otherPages/SubscriptionsUtils";
import {
  SubscriptionProlongationSpotInfo,
  SubscriptionTimeRangeInfo,
} from "../../otherPages/formParts/PrologongSubscriptionSpecyficPart";
import { BaseSpaceSeparator } from "../../styles/Separators";
import Notify from "../../utils/Notify";
import InvoicePart from "../SubscriptionsCommon/InvoicePart";
import TargetPart from "../SubscriptionsCommon/TargetPart";
import RegularInput from "../../components/forms/RegularInput";
import { getErrorMessage } from "../../utils/ErrorUtils";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import memoizee from "memoizee";
import Colors from "../../styles/Colors";

const subscription_type = require("../../../assets/images/subscription_type.svg");

const getAvailableNumberOfPeriods = memoizee((n: number) => {
  const result: { id: number; text: string }[] = [];
  for (let i = 1; i <= n; i++) {
    result.push({ id: i, text: i.toString() });
  }
  return result;
});

const SpecyficPart = (props: {
  args: FormikProps<ProlongAdminSubscriptionFormState>;
  info: GetSubscriptionProlongationInfoResult;
}) => {
  const { args, info } = props;
  const { values, setFieldValue, setFieldTouched, touched, errors } = args;

  React.useEffect(() => {
    const getTimeRange = async (pId: string, nOP: number) => {
      setFieldValue("targetFrom", null);
      setFieldValue("targetTo", null);
      const { error, from, to } = await getTimeRangeForSubcriptionProlongation(
        pId,
        nOP
      );
      if (error) {
        Notify.Error(getErrorMessage(error));
      } else {
        setFieldValue("targetFrom", from);
        setFieldValue("targetTo", to);
      }
    };

    getTimeRange(info.id, values.numberOfPeriods);
  }, [values.numberOfPeriods, info.id]);

  return (
    <>
      <SubscriptionTimeRangeInfo
        periodType={info.periodType}
        validFrom={values.targetFrom}
        validTo={values.targetTo}
      />
      <BaseSpaceSeparator size={20} />
      <RegularDropdownList
        maxContentHeight={250}
        borderColor={Colors.brownish_grey}
        actions={getAvailableNumberOfPeriods(10)}
        error={errors.numberOfPeriods}
        name="numberOfPeriods"
        touched={touched.numberOfPeriods}
        value={values.numberOfPeriods}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        label={
          info.periodType === SubscriptionPeriodType.NumberOfDays
            ? Resources.Liczba_okresow
            : Resources.Liczba_miesiecy
        }
      />
      <RegularInput
        label={Resources.Cena}
        error={errors.customPrice}
        value={values.customPrice}
        touched={touched.customPrice}
        name="customPrice"
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
      />
      {!!info?.spotNumber && (
        <>
          <SubscriptionProlongationSpotInfo
            sector={(info as any).sector}
            level={info.level}
            spotNumber={info.spotNumber}
          />
          <BaseSpaceSeparator size={20} />
        </>
      )}
    </>
  );
};

export default (props: {
  visible: boolean;
  onClose: () => void;
  onSubmitted: () => void;
  purchaseId: string;
  jwt: string;
}) => {
  const { onClose, onSubmitted, purchaseId, visible, jwt } = props;
  const [error, setError] = React.useState<ErrorCode>(null);
  const [progress, setProgress] = React.useState(true);
  const [initialValues, setInitialValues] =
    React.useState<ProlongAdminSubscriptionFormState>(
      getProlongForAdminFormState(null)
    );
  const [info, setInfo] =
    React.useState<GetSubscriptionProlongationInfoResult>(null);

  const onSubmit = async (
    state: ProlongAdminSubscriptionFormState,
    helpers: FormikHelpers<ProlongAdminSubscriptionFormState>
  ) => {
    helpers.setSubmitting(true);
    const { result, message } = await prolongAdminSubscription(
      state,
      purchaseId,
      jwt
    );
    if (result) {
      onSubmitted();
    } else {
      helpers.setStatus(message);
    }
    helpers.setSubmitting(false);
  };

  React.useEffect(() => {
    const init = async () => {
      if (!purchaseId) {
        return;
      }
      setError(null);
      setProgress(true);
      const { error, info } = await getSubscriptionProlongationInfo(
        purchaseId,
        1
      );
      if (typeof error === "number") {
        setError(error);
      } else if (!info.isActive) {
        setError(ErrorCode.SubscriptionIsNotActive);
      } else {
        setInitialValues(getProlongForAdminFormState(info));
        setInfo(info);
      }
      setProgress(false);
    };

    init();
  }, [purchaseId]);

  return (
    <ModalForm
      icon={subscription_type}
      iconHeight={105}
      iconWidth={120}
      visible={visible}
      onClose={onClose}
      title={Resources.Przedluzenie_abonamentu}
      validationSchema={getProlongForAdminFormValidationSchema()}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(args) => {
        if (progress) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Spinner size="small" />
            </div>
          );
        }

        if (typeof error === "number") {
          return <ErrorView title={Resources.Nastapil_blad} />;
        }

        return (
          <>
            <SpecyficPart args={args} info={info} />
            <TargetPart args={args} />
            <InvoicePart args={args} />
            {!args.values.wantInvoice && (
              <RegularInput
                label={Resources.Nr_dokumentu}
                value={args.values.externalPaymentDocumentNumber}
                error={args.errors.externalPaymentDocumentNumber}
                touched={args.touched.externalPaymentDocumentNumber}
                setFieldTouched={args.setFieldTouched}
                setFieldValue={args.setFieldValue}
                name="externalPaymentDocumentNumber"
                showClearButton
                showValidatedButton
              />
            )}
            <RegularDropdownList
              value={args.values.paymentMethod}
              error={args.errors.paymentMethod}
              touched={args.touched.paymentMethod}
              name="paymentMethod"
              actions={[
                { id: PaymentMethod.BankTransfer, text: Resources.przelew },
                { id: PaymentMethod.Cash, text: Resources.gotowka },
                {
                  id: PaymentMethod.CreditCard,
                  text: Resources.karta_platnicza,
                },
              ]}
              setFieldTouched={args.setFieldTouched}
              setFieldValue={args.setFieldValue}
              label={Resources.Sposob_platnosci}
              borderColor={Colors.brownish_grey}
            />
          </>
        );
      }}
    </ModalForm>
  );
};
