import * as React from "react";
import Resources from "../../Resources";
import Colors from "../styles/Colors";
import {BaseSpaceSeparator} from "../styles/Separators";
import {getTextStyles, PCText} from "../styles/Texts";
import {capitalizeFirstLetter} from "../utils/StringUtils";

export class ParkingSpotNumberPresenterUtils {
    static formatSpotNumber(data: {
        spotNumber?: string, level?: string, sector?: string
    }) {
        const {
            level,
            spotNumber,
            sector
        } = data;
        if (!spotNumber) {
            return "";
        }
        if (!level && !sector) {
            return `${Resources.miejsce}: ${spotNumber}`;
        } else if (level && !sector) {
            return `${Resources.miejsce}: ${spotNumber}, ${Resources.poziom} ${level}`;
        } else if (!level && sector) {
            return `${Resources.miejsce}: ${spotNumber}, ${Resources.sektor}: ${sector}`;
        } else {
            return `${Resources.miejsce}: ${spotNumber}, ${Resources.poziom} ${level}, ${Resources.sektor}: ${sector}`
        }
    }
}

export default (props: {
    spotNumber: string, level: string, sector: string

}) => {
    const {
        level,
        spotNumber,
        sector
    } = props;

    const text = React.useMemo(
        () => {
            return capitalizeFirstLetter(formatSpot({
                level,
                sector,
                spotNumber
            }))
        },
        [
            level,
            spotNumber,
            sector
        ]
    );

    return (<div style={{
            display: 'flex',
            alignItems: 'center'
        }}>
            <PCText color={Colors.black} fontSize={16} letterSpacing={0}>
                {text}
            </PCText>
        </div>)
}

export const formatSpot = (props: {
    spotNumber?: string, level?: string, sector?: string
}) => {
    if (!props) {
        return "";
    }
    return ParkingSpotNumberPresenterUtils.formatSpotNumber(props);
}