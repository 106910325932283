import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faMap, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetUserReservationPilotResult, GetUserReservationPilotResultPilotInfo, ProxiedReservationConfirmationResult, ReservationParkingSpotDetailsDto, UserReservationInfoDto } from "parkcash-api";
import React from "react";
import Resources from "../../Resources";
import Spinner from "../components/Spinner";
import { openVirtualPilot } from "../mobilePilots/Utils";
import { StandardButton } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { PCClasses } from "../utils/CSSUtils";
import { getErrorMessage } from "../utils/ErrorUtils";
import { useJWT } from "../utils/JWTUtils";
import { getClientPhoneNumber } from "../utils/PhoneNumberUtils";
import { getBaseInfo, getDetailsInfo, getPilotsInfo, getProxiedReservationInfo } from "./BookingsUtils";
import "./ReservationDetails.scss";

const ANIMATION_DURATION = 500;

const Pilots = (props: {pilotsInfo: GetUserReservationPilotResult}) => {
    const {pilotsInfo} = props;
    const [progresses, setProgresses] = React.useState<string[]>([]);

    const onOpen = async (item: GetUserReservationPilotResultPilotInfo) => {
        const {linkId, pilotId} = item;
        const progressesCopy = [...progresses];
        setProgresses([...progressesCopy, linkId]);
        await openVirtualPilot(pilotId, linkId);
        setProgresses([...progressesCopy]);
    }

    return (
        <div style={{width: 200}}>
            {!!pilotsInfo && pilotsInfo.reservationHasPilot && !!pilotsInfo.pilots && !!pilotsInfo.pilots.length && (
                <>
                {pilotsInfo.pilots.map((pilot, index, arr) => (
                    <React.Fragment key={index}>
                        <StandardButton progress={progresses.indexOf(pilot.linkId) >= 0} disabled={!pilot.isActive} onClick={() => onOpen(pilot)}>{pilot.friendlyName}</StandardButton>
                        {arr.length > 1 && index < arr.length - 1 && <BaseSpaceSeparator size={25} />}
                    </React.Fragment>
                ))}
                </>
            )}
        </div>
    )
}

const ProxiedReservationItem = (props: {icon: IconProp, title: string, info: string}) => {
    return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
            <FontAwesomeIcon icon={props.icon} color={Colors.light_royal_blue} style={{fontSize: 32, letterSpacing: 0}} />
            <BaseSpaceSeparator size={20} />
            <div>
                <PCText fontSize={18} letterSpacing={0} lineHeight={20/18} color={Colors.black}>
                    {props.title}
                </PCText>
                <BaseSpaceSeparator size={10} />
                <PCText fontSize={16} letterSpacing={0} lineHeight={20/16} color={Colors.black} semibold>
                    {props.info}
                </PCText>
            </div>
        </div>
    )
}

const ProxiedReservation = (props: {proxiedReservationInfo: ProxiedReservationConfirmationResult}) => {
    const {proxiedReservationInfo} = props;

    return (
        <div style={{width: 330}}>
            {!!proxiedReservationInfo && (
                <>
                    {!!proxiedReservationInfo.email && <ProxiedReservationItem icon={faEnvelope} info={proxiedReservationInfo.email} title={Resources.Mail_na_ktory_wyslalismy_rezerwacje} />}
                    {!!proxiedReservationInfo.email && !!proxiedReservationInfo.phoneNumber && <BaseSpaceSeparator size={50} />}
                    {!!proxiedReservationInfo.phoneNumber && <ProxiedReservationItem icon={faPhone} info={getClientPhoneNumber(proxiedReservationInfo.phoneNumber)} title={Resources.Nr_telefonu_na_ktory_wyslalismy_rezerwacje} />}
                </>
            )}
        </div>
    )
}

const QRCode = (props: {proxiedReservationInfo: ProxiedReservationConfirmationResult}) => {
    const {proxiedReservationInfo} = props;

    return (
        <div
            style={{width: 200, height: 200}}
        >
            {!!proxiedReservationInfo?.qrCodeLink && <img width={200} height={200} src={proxiedReservationInfo.qrCodeLink} />}
        </div>
    )
}

const DirectionHints = (props: {detailsInfo: ReservationParkingSpotDetailsDto}) => {
    const {detailsInfo} = props;
    return (
        <div style={{width: 330}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <FontAwesomeIcon icon={faMap} color={Colors.light_royal_blue} style={{fontSize: 32, letterSpacing: 0}} />
                <BaseSpaceSeparator size={20} />
                <div style={{flex: 1}}>
                    <PCText fontSize={18} letterSpacing={0} lineHeight={20/18} color={Colors.black}>
                        {Resources.Wskazowki_dojazdu}
                    </PCText>
                    <BaseSpaceSeparator size={10} />
                    <div style={{flex: 1}}>
                        <PCText fontSize={14} color={Colors.black} semibold>
                            {detailsInfo?.directionHints || "-"}
                        </PCText>
                    </div>
                </div>
            </div>
        </div>
    )
}

const CancellationDate = (props: {detailsInfo: ReservationParkingSpotDetailsDto}) => {
    const {detailsInfo} = props;
    return (
        <div>
            <PCText fontSize={18} letterSpacing={0} lineHeight={20/18} color={Colors.black}>
                {Resources.Data_anulowania}:{detailsInfo?.cancellationTimestamp? detailsInfo.cancellationTimestamp.toLocaleString():"-"}
            </PCText>
        </div>
    )
}

export default (props: {reservationId: string}) => {
    const jwt = useJWT();
    const {reservationId: id} = props;
    const [height, setHeight] = React.useState(0);
    const [progress, setProgress] = React.useState(true);
    const [error, setError] = React.useState<string>(null);
    const [baseInfo, setBaseInfo] = React.useState<UserReservationInfoDto>(null);
    const [proxiedReservationInfo, setProxiedReservationInfo] = React.useState<ProxiedReservationConfirmationResult>(null);
    const [detailsInfo, setDetailsInfo] = React.useState<ReservationParkingSpotDetailsDto>(null);
    const [pilotsInfo, setPilotsInfo] = React.useState<GetUserReservationPilotResult>(null);

    const init = async () => {
        setProgress(true);
        const baseInfo = await getBaseInfo(id, jwt);
        if(baseInfo.success){
            setBaseInfo(baseInfo.result);
            const [detailsInfo, pilotsInfo, proxiedReservationInfo] = await Promise.all([
                getDetailsInfo(id, jwt),
                getPilotsInfo(id, jwt),
                getProxiedReservationInfo(id, jwt)
            ]);
            if(detailsInfo.success && pilotsInfo.success && proxiedReservationInfo.success){
                setProxiedReservationInfo(proxiedReservationInfo.result);
                setPilotsInfo(pilotsInfo.result);
                setDetailsInfo(detailsInfo.result);
            }
        }
        else{
            setError(baseInfo.message);
        }
        setProgress(false);
    }

    React.useEffect(() => {
        setHeight(420);
        setTimeout(() => {
            init();
        }, ANIMATION_DURATION);
    }, []);

    return (
        <div 
            style={{
                height,
                transition: `height ${ANIMATION_DURATION}ms ease`,
                backgroundColor: Colors.very_light_grey,
                overflow: progress ? "hidden" : "auto",
                display: 'flex',
                alignItems: progress || error ? "center" : undefined,
                justifyContent: progress || error ? "center" : undefined
            }}
        >
            {progress && (
                <Spinner size="medium" />
            )}
            {!progress && error && (
                <PCText semibold fontSize={16} color={Colors.red}>{error}</PCText>
            )}
            {!progress && !error && (
                <div style={{flex: 1, height: 420, overflow: 'auto', padding: 20, paddingRight: 50, boxSizing: "border-box"}}>
                    <PCText fontSize={21} color={Colors.light_royal_blue}>
                        {Resources.Informacje_o_rezerwacji}
                    </PCText>
                    <BaseSpaceSeparator size={30} />
                    <div className={PCClasses("pc-reservationdetailsgrid")}>
                        <DirectionHints detailsInfo={detailsInfo}/>
                        <Pilots pilotsInfo={pilotsInfo} />
                        <ProxiedReservation proxiedReservationInfo={proxiedReservationInfo} />
                        <QRCode proxiedReservationInfo={proxiedReservationInfo} />
                        <CancellationDate detailsInfo={detailsInfo} />
                    </div>
                </div>
            )}
        </div>
    )
}