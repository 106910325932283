import * as React from "react";
import Resources from "../../Resources";
import { ListAddButton } from "../components/Lists";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { useWindowSize } from "../utils/WindowSizeHook";

const groups = require("../../assets/images/groups.svg");

export default (props: {
    onJoin: () => void,
    onAdd: () => void
}) => {
    const {onAdd, onJoin} = props;
    const {windowWidth} = useWindowSize();

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <img src={groups} width={236} height={220} />
            <BaseSpaceSeparator size={30} />
            <div style={{width: windowWidth >= 450 ? 400 : 300}}>
                <PCText textAlign="center" fontSize={36} semibold color={Colors.black}>
                    {Resources.Nie_masz_jeszcze_zadnych_grup_adresowych}
                </PCText>
            </div>
            <BaseSpaceSeparator size={25} />
            <div style={{width: windowWidth >= 450 ? 400 : 300}}>
                <PCText textAlign="center" fontSize={28} color={Colors.brownish_grey}>
                    {Resources.Wystarcza} <PCText fontSize={28} color={Colors.brownish_grey} inline bold>{Resources["2_minuty"]}</PCText> {Resources.by_stworzyc_Grupe_adresowa}
                </PCText>
            </div>
            <BaseSpaceSeparator size={25} />
            <div style={{display: 'flex', alignItems: 'center'}}>
                <ListAddButton onClick={onAdd}>{Resources.Dodaj_nowa_grupe}</ListAddButton>
                <BaseSpaceSeparator size={windowWidth >= 450 ? 50 : 20} />
                <ListAddButton onClick={onJoin} backgroundColor={Colors.saphire}>{Resources.Dolacz_do_nowej_grupy}</ListAddButton>
            </div>
        </div>
    )
}