import React from "react"
import Resources from "../../../Resources";
import UserWidgetStatisticsSection from "./sections/UserReportSections/UserWidgetsStats/UserWidgetStatisticsSection";
import UserTopListSection from "./sections/UserReportSections/UsersTopListSections/UsersTopListSection";

import {useUser} from "../../user/UseUserHook";
import {DashboardService, ServiceConfig} from "parkcash-api";
import {GetUsersDashboardDataResult} from "parkcash-api";
import MinimalPageWraperWithDataFetcher
    from "../../MUI/components/AddedOrModyfiedMuiComponents/PageWrapers/MinimalPageWraperWithDataFetcher";

export const UsersReport = () => {
    const {token: jwt} = useUser();
    const dashBoardService = new DashboardService(new ServiceConfig({jwt}));
    return (<MinimalPageWraperWithDataFetcher
        pageTitle={Resources.Aktywnosc_uzytkownikow}
        fetchFunction={(parkingId, start, end, cancelToken) => dashBoardService.getUsersDashboardData(parkingId,
            start,
            end,
            cancelToken
        )}
    >
        {(data: GetUsersDashboardDataResult) => (<>
            <UserWidgetStatisticsSection {...data}/>
            <UserTopListSection {...data}/>
        </>)}
    </MinimalPageWraperWithDataFetcher>)
}

