import { TransactionType } from "./Enums";
import PaymentForm from "./PaymentForm";
import { PaymentTargetType } from "parkcash-api";
import Resources from "../../Resources";


export class BluemediaTransactionParametersUserInfo {
    constructor(public readonly email: string){
        
    }
}

export class BluemediaTransactionParametersArgs {
    constructor(
        public readonly token: string,
        public readonly serviceId: string,
        public readonly acceptorId: string
    ){

    }
}

export class BluemediaTransactionParametersTarget {
    constructor(
        public readonly paymentTargetType: PaymentTargetType,
        public readonly targetObjectId: string
    ){

    }
}

export default class BluemediaTransactionParameters {

    get PaymentTargetType(){ return this.target?.paymentTargetType; }

    get TargetObjectId(){ return this.target?.targetObjectId; }

    get TransactionType(){ return this.paymentForm.Type; }

    get CardId(){ return this.paymentForm.CreditCardInfo?.id; }

    get Token(){ return this.args.token; }

    get ServiceId(){ return this.args.serviceId; }

    get AcceptorId(){ return this.args.acceptorId; }

    get Amount(){ return this.amount; }

    get UserEmail(){ 
        return this.userInfo.email; 
    }


    private bluemediaParams:  {[key: string]: string};

    get BluemediaParams(){ return this.bluemediaParams; }

    private googlePayToken: string;

    get GooglePayToken(){ return this.googlePayToken; }

    private applePayPaymentId: string;

    get ApplePayPaymentId(){ return this.applePayPaymentId; }

    get PaymentForm(){
        return this.paymentForm;
    }

    constructor(
        private amount: number,
        private paymentForm: PaymentForm,
        private userInfo: BluemediaTransactionParametersUserInfo,
        private args: BluemediaTransactionParametersArgs,
        private target?: BluemediaTransactionParametersTarget
    ){
        //this.userPhone = /\+[0-9]{11}/.test(userPhone) ? userPhone.substr(3) : userPhone
    }

    updateGooglePayToken(token: string){
        this.googlePayToken = token;
    }

    updateBlumediaParams(params: {[key: string]: string}){
        this.bluemediaParams = params;
    }

    updateApplePayPaymentId(paymentId: string){
        this.applePayPaymentId = paymentId;
    }

    updatePaymentForm(form: PaymentForm){
        this.paymentForm = form;
    }
}