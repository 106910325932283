import * as React from "react";
import Colors from "../styles/Colors";

const sizes = {
    "extra-small": 19,
    "small": 30,
    "medium": 50,
    "large": 80,
    "extra-large": 120
}

export default (props: {
    color?: string;
    size?: "extra-small" | "small" | "medium" | "large" | "extra-large" | number
}) => {
    const {color = Colors.light_royal_blue, size = "medium"} = props;


    const targetSize = typeof size === "number" ? size : sizes[size];

    return (
        <svg 
            
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            style={{background: "none", display: "block", shapeRendering: "auto"}} 
            width={`${targetSize}px`} 
            height={`${targetSize}px`} 
            viewBox="0 0 100 100" 
            preserveAspectRatio="xMidYMid"
        >
        <circle 
            cx="50" 
            cy="50" 
            fill="none" 
            stroke={color} 
            strokeWidth="10" 
            r="35" 
            strokeDasharray="164.93361431346415 56.97787143782138"
        >
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.9345794392523364s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
        </circle>
        </svg>
    )
}