import {
    ErrorCode,
    GetMobilePilotUsageHistoryViewPilotAssignmentType,
    GetMobilePilotUsageHistoryViewResultEntryDto,
    MobilePilotUsageEntryType,
    ParkingManagerService,
    ServiceConfig,
    SortOrder
} from "parkcash-api";
import * as React from "react";
import {useEffect} from "react";
import Resources from "../../../Resources";
import PCDataGrid from "../../components/PCDataGrid";
import {SubBody} from "../../layouts/Main/Body";
import {CustomDataSource, FilterOperator, IDataSource} from "../../utils/DataSource";
import {IParkCashApplicationMode} from "../../utils/ParkCashApplicationMode";
import {getFilters, getSorts} from "../../utils/SieveUtils";

import {ActionIcon} from "../../components/PCDataGrid/ActionColumn";
import {faSatelliteDish} from "@fortawesome/free-solid-svg-icons";
import {faBluetooth} from "@fortawesome/free-brands-svg-icons";
import {PCText} from "../../styles/Texts";
import Colors from "../../styles/Colors";
import {addDaysToDate, isValidDate, setTimeToDayStart} from "../../MUI/utils/format-time";
import {
    CustomizedDatePicker
} from "../../MUI/components/AddedOrModyfiedMuiComponents/CustomizedInputs/CustomizedDatePicker";
import {BaseSpaceSeparator} from "../../styles/Separators";
import {useWindowSize} from "../../utils/WindowSizeHook";
import {MOBILE_WIDTH_THRESHOLD} from "../../utils/Constants";
import {SearchInputWithButton} from "../../components/SearCHInputWithButton";

export default (props: {
    jwt: string; applicationMode: IParkCashApplicationMode;
}) => {
    const {
        applicationMode,
        jwt
    } = props;
    const parkingId = applicationMode?.getCurrentParking()?.parkingId;
    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const [dataSource, setDataSource] = React.useState<IDataSource<GetMobilePilotUsageHistoryViewResultEntryDto>>(null);
    const [dataGridKey, setDataGridKey] = React.useState(0);
    const [startDateControl, setStartDateControl] = React.useState<Date | null>(setTimeToDayStart(new Date()));
    // end control is set to be not the end of the day, but start of the next day, so that all events from the day are
    // shown
    const [endDateControl, setEndDateControl] = React.useState<Date | null>(setTimeToDayStart(new Date()));
    const [search, setSearch] = React.useState("");
    const onSearch = (value: string) => {
        if (!value) {
            dataSource.removeFilter("pilotUserName");
        } else {
            dataSource.filter("pilotUserName", FilterOperator.CASE_INSENSITIVE_STRING_CONTAINS, value);
        }
    };

    function handleStartDateChange(date: Date | null) {
        if (!date) {
            setStartDateControl(date);
            dataSource.removeFilter("from" as keyof GetMobilePilotUsageHistoryViewResultEntryDto);
        } else if (date && !isValidDate(date)) {
            setStartDateControl(date);

        } else {
            const startDateDateStart = setTimeToDayStart(date);
            setStartDateControl(startDateDateStart);
            dataSource.filter(
                "from" as keyof GetMobilePilotUsageHistoryViewResultEntryDto,
                FilterOperator.GREATER_THAN_OR_EQUAL_TO,
                startDateDateStart
            );
        }
    }

    function handleEndDateChange(date: Date | null) {

        if (!date) {
            setEndDateControl(date);
            dataSource.removeFilter("to" as keyof GetMobilePilotUsageHistoryViewResultEntryDto);
        } else if (date && !isValidDate(date)) {
            setEndDateControl(date);

        } else {
            const endDateDateStart = setTimeToDayStart(date);
            setEndDateControl(endDateDateStart);
            dataSource.filter(
                "to" as keyof GetMobilePilotUsageHistoryViewResultEntryDto,
                FilterOperator.LESS_THAN,
                endDateDateStart
            );
        }
    }
    
    const renderTypeIcon = (type: MobilePilotUsageEntryType) => {
        //Is it right enum here?

        return type === MobilePilotUsageEntryType.Server ?
            <ActionIcon icon={faSatelliteDish} title={Resources.Serwer}/> :
            <ActionIcon icon={faBluetooth} title={"Bluetooth"}/>
    }

    const resetFilters = () => {
        setEndDateControl((setTimeToDayStart(new Date())));
        setStartDateControl(setTimeToDayStart(new Date()));
        setSearch("");

    };

    const getDataSource = React.useCallback(
        () => {
            resetFilters(); //reset filters on DataSource creation or recreation
            return new CustomDataSource<GetMobilePilotUsageHistoryViewResultEntryDto>({
                initialFilter: [

                    {
                        key: "from" as keyof GetMobilePilotUsageHistoryViewResultEntryDto,
                        value: setTimeToDayStart(new Date()),
                        operator: FilterOperator.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        key: "to" as keyof GetMobilePilotUsageHistoryViewResultEntryDto,
                        value: setTimeToDayStart(new Date()),
                        operator: FilterOperator.LESS_THAN
                    }

                ],
                load: async loadOptions => {
                    try {
                        const {
                            filter,
                            page,
                            pageSize,
                            sort
                        } = loadOptions;
                        const filtersExceptForDate = filter?.filter(f => f.key !== "from" as keyof GetMobilePilotUsageHistoryViewResultEntryDto && f.key !== "to" as keyof GetMobilePilotUsageHistoryViewResultEntryDto && f.key !== "usageTimestamp" as keyof GetMobilePilotUsageHistoryViewResultEntryDto);
                        const finalFilters = [...filtersExceptForDate];

                        const fromFilter = filter?.find(f => f.key === "from" as keyof GetMobilePilotUsageHistoryViewResultEntryDto);
                        const toFilter = filter?.find(f => f.key === "to" as keyof GetMobilePilotUsageHistoryViewResultEntryDto);

                        if (fromFilter) {
                            const usageTimeStampFromFilter = {...fromFilter};
                            usageTimeStampFromFilter.value = fromFilter?.value?.toISOString();
                            usageTimeStampFromFilter.key = "usageTimestamp" // name changed to represent actual field
                                                                            // name in API
                            finalFilters.push(usageTimeStampFromFilter);

                        }
                        if (toFilter) {
                            const usageTimeStampToFilter = {...toFilter};
                            usageTimeStampToFilter.value = addDaysToDate(toFilter?.value, 1);
                            usageTimeStampToFilter.value = usageTimeStampToFilter.value.toISOString()// note that we
                                                                                                     // add one day to
                                                                                                     // the end date,
                                                                                                     // so that all
                                                                                                     // events from the
                                                                                                     // day are shown
                                                                                                     // and actual
                                                                                                     // filter value
                                                                                                     // differs from
                                                                                                     // value seen by
                                                                                                     // user in  control.
                            usageTimeStampToFilter.key = "usageTimestamp" // name changed to represent actual field
                                                                          // name in API
                            finalFilters.push(usageTimeStampToFilter);

                        }

                        const {
                            error,
                            isSuccess,
                            result
                        } = await new ParkingManagerService(new ServiceConfig({jwt})).getMobilePilotUsageHistoryView

                                                                                     (parkingId,
                                                                                         getFilters(finalFilters),
                                                                                         getSorts(sort),
                                                                                         page,
                                                                                         pageSize
                                                                                     )
                        if (isSuccess) {
                            return {
                                items: result?.entries,
                                totalItems: result?.paginationDto?.totalElementsCount,

                            }
                        } else {
                            return error?.code;
                        }
                    } catch {
                        return ErrorCode.NetworkError;
                    }
                },
                initialSort: [
                    {
                        key: "usageTimestamp",
                        order: SortOrder.Descending
                    }
                ]
            })
        },
        [
            jwt,
            parkingId
        ]
    );

    useEffect(
        () => {
            setDataSource(getDataSource());
            setDataGridKey(prevState => prevState + 1)
        },
        [
            jwt,
            parkingId
        ]
    );

    return (<SubBody
            title={Resources.Historia_uzycia}
            subtitle={Resources.Historia_uzycia_mobilnych_pilotow_przez_uzytkownikow}
            rigth={<div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: "center",
                    justifyContent: isMobile ? "flex-start" : "flex-end",
                }}
            >
                {<>
                    <div style={{width: isMobile ? "100%" : 250}}>
                        <CustomizedDatePicker value={startDateControl} onChange={handleStartDateChange}
                                              label={Resources.Od}
                                              fullWidth
                        />
                    </div>
                    <BaseSpaceSeparator size={20}/>
                    <div style={{width: isMobile ? "100%" : 250}}>
                        <CustomizedDatePicker value={endDateControl} onChange={handleEndDateChange}
                                              label={Resources.Do}
                                              fullWidth
                        />
                    </div>

                </>}

            </div>}
        >
            <SearchInputWithButton value={search} setValue={setSearch} onSearchSubmitted={value => onSearch(value)}
                                   label={Resources.Wyszukaj_po_imieniu_nazwisku_uzytkonika}/>
            <BaseSpaceSeparator size={20}/>
            <PCDataGrid<GetMobilePilotUsageHistoryViewResultEntryDto>
                key={dataGridKey}
                dataSource={dataSource}
                columns={[
                    {
                        dataField: "pilotFriendlyName",
                        label: Resources.Szlaban_Brama,
                        disableSort: true,
                        width: 3
                    },
                    {
                        dataField: "usageTimestamp",
                        label: Resources.Data_zdarzenia,
                        width: 3
                    },
                    {
                        dataField: "type" as keyof GetMobilePilotUsageHistoryViewResultEntryDto,
                        label: Resources.Typ,
                        width: 3,
                        disableSort: true,
                        renderCell: item => (<PCText fontSize={16} lineHeight={20 / 16} color={Colors.brownish_grey}
                                                     letterSpacing={0}
                                                     semibold>
                            {Resources.Rezerwacja}
                        </PCText>)
                    },
                    {
                        dataField: "mobilePilotUsageEntryType",
                        label: Resources.Czytnik,
                        width: 3,
                        disableSort: true,
                        renderCell: item => (<div style={{
                                display: "flex",
                                alignItems: "center"
                            }}>
                                <PCText fontSize={16} lineHeight={20 / 16} color={Colors.brownish_grey}
                                        letterSpacing={0}
                                        semibold>
                                    {Resources.Mobilny_pilot}
                                </PCText>
                                {renderTypeIcon(item.mobilePilotUsageEntryType)}
                            </div>

                        )
                    },
                    {
                        dataField: "pilotUserName",
                        label: Resources.Uzytkownik,
                        disableSort: true,
                        width: 3
                    },
                    {
                        dataField: "details" as keyof GetMobilePilotUsageHistoryViewResultEntryDto,
                        label: Resources.Szczegoly,
                        disableSort: true,
                        width: 3
                    }

                ]}
            />
        </SubBody>)
}

function getTypeText(assignmentType: GetMobilePilotUsageHistoryViewPilotAssignmentType) {
    switch (assignmentType) {
        case GetMobilePilotUsageHistoryViewPilotAssignmentType.ParkingProcess:
            return Resources.Parkowanie;
        case GetMobilePilotUsageHistoryViewPilotAssignmentType.Shared:
            return Resources.Udostepnienie;
        case GetMobilePilotUsageHistoryViewPilotAssignmentType.Permanent:
            return Resources.Staly_dostep
    }
}
