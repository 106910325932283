import {
    ErrorCode,
    NotificationDto,
    NotificationsFilter,
    NotificationSortType,
    NotificationsService,
    NotificationType,
    ServiceConfig,
    SortOrder
} from "parkcash-api";
import * as React from "react";
import Resources from "../../Resources";
import PCDataGrid from "../components/PCDataGrid";
import Body from "../layouts/Main/Body";
import {CustomDataSource} from "../utils/DataSource";
import {useJWT} from "../utils/JWTUtils";

const getTitle = (type: NotificationType) => {
    switch(type) {
        case NotificationType.BookingSummary:
            return Resources.Podsumowanierezerwacji;
        case NotificationType.OwnerRewardedForRating:
            return Resources.Nagroda;
        case NotificationType.OwnerRewardedForSharing:
            return Resources.Nagroda;
        case NotificationType.ReservationCanceledByOwner:
            return Resources.Odwolanie;
        case NotificationType.ReservationProcessReminder:
            return Resources.Przypomnienie;
        case NotificationType.UserReservationSummary:
            return Resources.Podsumowanierezerwacji;
        case NotificationType.ReservationMessageCreated:
            return Resources.Wiadomosc;
        case NotificationType.WebView:
            return Resources.Oferta;    
        default:
            return Resources.Parkowanie;
    }
}

export default () => {
    const dataSource = React.useRef(new CustomDataSource<NotificationDto>({
        load: async (loadOptions) => {
            try{
                const {page, pageSize} = loadOptions;
                const {isSuccess, result, error} = await new NotificationsService(new ServiceConfig({jwt: useJWT()})).getNotifications
                (
                    SortOrder.Descending,
                    NotificationSortType.ByDate,
                    (page - 1)*pageSize,
                    pageSize,
                    0,
                    NotificationsFilter.All
                );
                if(isSuccess){
                    const {notifications, paginationInfo: {totalElementsCount}} = result;
                    return {
                        items: notifications,
                        totalItems: totalElementsCount
                    }                    
                }
                else{
                    return error.code;
                }
            }
            catch{
                return ErrorCode.NetworkError
            }  
        }
    })).current

    return (
        <Body title={Resources.Powiadomienia}>
            <PCDataGrid<NotificationDto> 
                dataSource={dataSource}
                disableSorting
                columns={[
                    {
                        label: Resources.Data_utworzenia,
                        dataField: "creationTimestamp",
                        width: 1
                    },
                    {
                        label: Resources.Typ,
                        dataField: "type",
                        renderCell: item => getTitle(item.type)
                    },
                    {
                        label: Resources.Tresc,
                        dataField: "message",
                        width: 3
                    }
                ]}
            />
        </Body>
    )
}