import { FormikHelpers } from "formik";
import { ExternalReservationTargetDto, IVehicleExDto } from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import Resources from "../../../Resources";
import ModalForm from "../../components/ModalForm";
import PCTabBar from "../../components/PCTabBar";
import Spinner from "../../components/Spinner";
import PaymentForm from "../../payments/PaymentForm";
import { getPaymentForms } from "../../payments/Redux";
import { applicationState } from "../../redux/ApplicationState";
import Colors from "../../styles/Colors";
import { PCText } from "../../styles/Texts";
import { formatAddress } from "../../utils/AddressUtils";
import Notify from "../../utils/Notify";
import { getServerPhoneNumber } from "../../utils/PhoneNumberUtils";
import { getNameAndSurname } from "../../utils/UserInfoUtils";
import { getVehicles } from "../../vehicles/Redux";
import VehicleForm from "../../vehicles/VehicleForm";
import ReservationParameters from "../ReservationParameters";
import CreateReservation from "./CreateReservation";
import {
  ReservationConfirmationFormState as FormState,
  getDefaultFormState,
} from "./FormState";
import ReservationForGuest from "./ReservationForGuest";
import ReservationForMe from "./ReservationForMe";
import { validateReservationConfirmation } from "./Validate";

const homeWithDrive = require("../../../assets/images/homeWithDrive.svg");

interface OwnProps {
  visible: boolean;
  onClose: () => void;
  parameters: ReservationParameters;
  onReservationMade: (
    reservationId: string,
    amount: number,
    paymentForm: string
  ) => void;
}

interface Props extends OwnProps {
  payments: PaymentForm[];
  vehicles: IVehicleExDto[];
  progress: boolean;
  error: boolean;
  onInit: () => void;
}

const Container = (props: Props) => {
  const {
    onClose,
    visible,
    parameters,
    progress,
    error,
    payments,
    vehicles,
    onInit,
    onReservationMade,
  } = props;
  const [showVehicleForm, setShowVehicleForm] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState(
    getDefaultFormState()
  );

  const onSubmit = async (
    state: FormState,
    helpers: FormikHelpers<FormState>
  ) => {
    const { setSubmitting, setStatus } = helpers;
    const {
      email,
      description,
      isMeParking,
      licencePlateNumber,
      nameAndSurname,
      payment,
      phoneNumber,
      vehicle,
    } = state;

    if (parameters.ShouldPay && !payment) {
      Notify.Error(
        Resources.Aby_moc_dokonac_platnej_rezerwacji_musisz_miec_dodana_forme_platnosci
      );
    }

    if (isMeParking && !vehicle) {
      setShowVehicleForm(true);
      return;
    }

    try {
      setSubmitting(true);
      const externalTarget = isMeParking
        ? undefined
        : new ExternalReservationTargetDto({
            autoSendToTarget: true,
            desciption: description || null,
            email: email || null,
            phoneNumber: getServerPhoneNumber(phoneNumber) || null,
            licensePlateNumber: licencePlateNumber || null,
            targetName: nameAndSurname,
          });
      const targetVehicle = isMeParking ? vehicle : null;
      const createReservation = await CreateReservation(
        parameters,
        externalTarget,
        targetVehicle
      );
      if (createReservation.result) {
        onReservationMade(
          createReservation.reservationId,
          createReservation.amount,
          payment
        );
      } else {
        setStatus(createReservation.message);
      }
    } finally {
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    onInit();
  }, []);

  React.useEffect(() => {
    if (!progress && !error) {
      const payment = payments[0]?.getId() || null;
      const vehicle = vehicles[0]?.id || null;
      setInitialValues({
        ...getDefaultFormState(),
        payment,
        vehicle,
      });
    }
  }, [progress, error, payments, vehicles]);

  return (
    <ModalForm
      enableReinitialize
      visible={visible}
      onClose={onClose}
      initialValues={initialValues}
      onSubmit={onSubmit}
      submitButtonText={
        parameters?.ShouldPay
          ? Resources.Rezerwuje_i_place
          : Resources.Rezerwuje
      }
      icon={homeWithDrive}
      iconWidth={90}
      iconHeight={110}
      title={
        <>
          {Resources.Rezerwuj_pod_adresem}
          <br />
          <span style={{ color: Colors.light_royal_blue }}>
            {formatAddress(parameters?.ParkingSpot, true)}
          </span>
        </>
      }
      validate={validateReservationConfirmation}
    >
      {(args) => {
        const { values, setFieldValue, handleSubmit } = args;
        return (
          <React.Fragment>
            <VehicleForm
              initialVehicle={null}
              visible={showVehicleForm}
              onClose={() => setShowVehicleForm(false)}
              onSaved={(vehicle) => {
                setShowVehicleForm(false);
                setFieldValue("vehicle", vehicle.id);
                handleSubmit();
              }}
              onVehicleRemoved={null}
            />
            {progress && (
              <div
                style={{
                  display: "flex",
                  height: 100,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spinner size="medium" />
              </div>
            )}
            {!progress && error && (
              <div
                style={{
                  display: "flex",
                  height: 100,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PCText semibold fontSize={16} color={Colors.red}>
                  {Resources.Nastapil_blad}
                </PCText>
              </div>
            )}
            {!progress && !error && (
              <PCTabBar
                current={values.isMeParking ? 1 : 2}
                onChange={(id) =>
                  id === 1
                    ? setFieldValue("isMeParking", true)
                    : setFieldValue("isMeParking", false)
                }
                items={[
                  { id: 1, text: Resources.Dla_siebie },
                  { id: 2, text: Resources.Dla_goscia },
                ]}
              >
                {(item) => {
                  if (item === 1) {
                    return (
                      <ReservationForMe
                        args={args}
                        vehicles={vehicles}
                        payments={payments}
                        shouldPay={parameters.ShouldPay}
                      />
                    );
                  } else {
                    return (
                      <ReservationForGuest
                        args={args}
                        payments={payments}
                        shouldPay={parameters.ShouldPay}
                      />
                    );
                  }
                }}
              </PCTabBar>
            )}
          </React.Fragment>
        );
      }}
    </ModalForm>
  );
};

const mapStateToProps = (
  state: applicationState,
  ownProps: OwnProps
): Partial<Props> => ({
  ...ownProps,
  payments: state.payments.paymentForms || [],
  vehicles: state.vehicles.vehicles || [],
  progress:
    state.payments.gettingPaymnentFormsProgress ||
    state.vehicles.gettingVehiclesProgress,
  error:
    !!state.payments.gettingPaymentFormsError ||
    !!state.vehicles.gettingVehclesError,
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
  onInit: () => {
    dispatch(getVehicles());
    dispatch(getPaymentForms());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
