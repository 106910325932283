import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { ErrorCode, GetSubscriptionPurchaseDetailsResult, InvoiceClientStatus, ServiceConfig, SubscriptionsService } from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import { ActionIcon } from "../../components/PCDataGrid/ActionColumn";
import Spinner from "../../components/Spinner";
import { getProlongForAdminFormState } from "../../otherPages/SubscriptionsUtils";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText, SOURCESANSPRO_SEMIBOLD } from "../../styles/Texts";
import { formatDate, formatDateTime } from "../../utils/DateTimeUtils";
import { getErrorMessage } from "../../utils/ErrorUtils";
import { useJWT } from "../../utils/JWTUtils";
import { getClientNipNumber } from "../../utils/NIPNumberUtils";
import { getClientPhoneNumber } from "../../utils/PhoneNumberUtils";
import { useWindowSize } from "../../utils/WindowSizeHook";

const ANIMATION_DURATION = 500;

export default (props: {purchaseId: string, invoiceUrl: string, invoiceStatus: InvoiceClientStatus}) => {
    const {purchaseId, invoiceUrl, invoiceStatus} = props;
    const {windowWidth} = useWindowSize();
    const [info, setInfo] = React.useState<GetSubscriptionPurchaseDetailsResult>(null);
    const [progress, setProgress] = React.useState(true);
    const [error, setError] = React.useState<ErrorCode>(null);
    const [height, setHeight] = React.useState(0);

    const init = async () => {
        const jwt = useJWT();
        try{
            setProgress(true);
            const {isSuccess, result, error} = await new SubscriptionsService(new ServiceConfig({jwt})).getSubscriptionPurchaseDetails(purchaseId)
            if(isSuccess){
                setInfo(result);
            }
            else{
                setError(error.code);
            }
        }
        catch{
            setError(ErrorCode.NetworkError);
        }
        finally{
            setProgress(false);
        }
        
    }

    const getTemplateColumns = () => {
        if(windowWidth >= 1350 ){
            return 'auto auto auto auto';
        }
        else if(windowWidth < 1350 && windowWidth >= 850){
            return 'auto auto';
        }
        else{
            return 'auto';
        }
    }

    React.useEffect(() => {
        setHeight(300);
        setTimeout(() => {
            init();
        }, ANIMATION_DURATION);
    }, []);

    return (
        <div 
            style={{
                height,
                transition: `height ${ANIMATION_DURATION}ms ease`,
                backgroundColor: Colors.very_light_grey,
                overflow: progress ? "hidden" : "auto",
                display: 'flex',
                alignItems: progress || error ? "center" : undefined,
                justifyContent: progress || error ? "center" : undefined
            }}
        >
            {progress && (
                <Spinner size="medium" />
            )}
            {!progress && error && (
                <PCText semibold fontSize={16} color={Colors.red}>{getErrorMessage(error)}</PCText>
            )}
            {!progress && !error && (
                <div style={{flex: 1, height: 300, overflow: 'auto', padding: 20, paddingRight: 50, boxSizing: "border-box"}}>
                    <PCText fontSize={21} color={Colors.light_royal_blue}>
                        {Resources.Informacje_o_zakupie}
                    </PCText>
                    <BaseSpaceSeparator size={30} />
                    <div
                        style={{
                            display: 'grid',
                            justifyContent: 'space-between',
                            gridGap: '100px 0px',
                            gridTemplateColumns: getTemplateColumns()
                        }}
                    >
                        <div>
                            <PCText semibold fontSize={18} letterSpacing={0} lineHeight={20/18} color={Colors.black}>
                                {Resources.Dane_kupujacego}
                            </PCText>
                            <BaseSpaceSeparator size={10} />
                            <PCText fontSize={16} letterSpacing={0} color={Colors.brownish_grey}>
                                {info.name}
                                <br />
                                {info.email} {getClientPhoneNumber(info.phoneNumber)}
                                <br />
                                <>
                                    {!!info.licencePlateNumber && (
                                        <>
                                            <span style={{fontFamily: SOURCESANSPRO_SEMIBOLD}}>{Resources.Numer_rejestracyjny}:</span> 
                                            {info.licencePlateNumber}
                                        </>
                                    )}
                                </>
                            </PCText>
                        </div>
                        <div>
                            <PCText semibold fontSize={18} letterSpacing={0} lineHeight={20/18} color={Colors.black}>
                                {Resources.Przedzial_czasowy}
                            </PCText>
                            <BaseSpaceSeparator size={10} />
                            <PCText fontSize={16} letterSpacing={0} color={Colors.brownish_grey}>
                                <span style={{fontFamily: SOURCESANSPRO_SEMIBOLD}}>{Resources.Poczatek}:</span> {formatDateTime(info.validFrom)}
                                <br />
                                <span style={{fontFamily: SOURCESANSPRO_SEMIBOLD}}>{Resources.Koniec}:</span> {formatDateTime(info.validTo)}                              
                            </PCText>
                        </div>
                        <div>
                            <PCText semibold fontSize={18} letterSpacing={0} lineHeight={20/18} color={Colors.black}>
                                {Resources.Cena}
                            </PCText>
                            <BaseSpaceSeparator size={10} />
                            <PCText fontSize={16} letterSpacing={0} color={Colors.brownish_grey}>
                                {info.price}{Resources.zl} {!!info.discountValue && `(${Resources.Obnizona_o} ${info.discountValue}${Resources.zl})`}                              
                            </PCText>
                        </div>
                        <div>
                            {!!info.invoiceData && (
                                <>
                                    <PCText semibold fontSize={18} letterSpacing={0} lineHeight={20/18} color={Colors.black}>
                                        {Resources.Dane_faktury}
                                    </PCText>
                                    <BaseSpaceSeparator size={10} />
                                    <PCText fontSize={16} letterSpacing={0} color={Colors.brownish_grey}>
                                        <span style={{fontFamily: SOURCESANSPRO_SEMIBOLD}}>{Resources.Nazwa_firmy}:</span> {info.invoiceData.name}
                                        <br />
                                        {info.invoiceData.nip && (
                                            <>
                                                <span style={{fontFamily: SOURCESANSPRO_SEMIBOLD}}>{Resources.NIP}: </span> {getClientNipNumber(info.invoiceData.nip)}
                                                <br />
                                            </>
                                        )}
                                    </PCText>
                                    <BaseSpaceSeparator size={10} />
                                    <ActionIcon visible={invoiceStatus === InvoiceClientStatus.Ready} href={invoiceUrl} title={Resources.Pobierz_fakture} target="_blank" icon={faFileInvoice}/>
                                </>
                            )}
                            
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}