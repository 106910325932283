import React from 'react';
import Stack, {StackProps} from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

interface BlockProps extends StackProps {
    label?: string;
    children: React.ReactNode;
    labelTextAlign?: 'left' | 'right';
}

export function Block({ label = 'RHFTextField', labelTextAlign="left", sx, children }: BlockProps) {
    return (
        <Stack
            spacing={1}
            sx={{
                width: 1,
                /* 
                bgcolor: 'background.default', 
               border: "1px solid rgba(0, 0, 0, 0.12)", 
                borderRadius: '8px', // Rounded border*/
                ...sx
            }}
            component={Paper}
           /* elevation={3}*/
        >
            <Typography
                variant="caption"
                sx={{
                    pr:1,
                    textAlign: labelTextAlign,
                    fontStyle: 'italic',
                    color: 'text.disabled',
                }}
            >
                {label}
            </Typography>
            {children}
        </Stack>
    );
}
