import { ErrorCode, ParkingSpotInfoDto } from "parkcash-api";
import React from "react";
import { connect } from "react-redux";
import Resources from "../../Resources";
import ConfirmModal, { YesNoModal } from "../components/ConfirmModal";
import ErrorView from "../components/ErrorView";
import { ListAddButton, ListContainer, ListSeparator } from "../components/Lists";
import Spinner from "../components/Spinner";
import { applicationState } from "../redux/ApplicationState";
import { useUser } from "../user/UseUserHook";
import { getErrorMessage } from "../utils/ErrorUtils";
import { useQuery } from "../utils/QueryParametersUtils";
import ParkingSpotForm from "./ParkingSpotForm";
import ParkingSpotsListItem from "./ParkingSpotsListItem";
import { activateParkingSpot, deactivateParkingSpot, getParkingSpots, removeParkingSpot } from "./Redux";
import ShareParkingSpotForm, { SpotSharingType } from "./ShareParkingSpotForm";

interface Props {  
    progress: boolean,
    error: ErrorCode,
    items: ParkingSpotInfoDto[],

    onReload: () => void;
    onActivateParkingSpot: (id: string) => void;
    onDeactivateParkingSpot: (id: string) => void;
    onRemoveParkingSpot: (id: string) => void;
    onShared: () => void;
}

type initialMode = "add" | "share";

type shareType = "onetime" | "cyclic";

export default (props: Props) => {
    const {error,onRemoveParkingSpot,onDeactivateParkingSpot,onActivateParkingSpot,items,progress, onReload, onShared} = props;
    const [shareFormVisible, setShareFormVisible] = React.useState(false);
    const [spotFormVisible, setSpotFormVisible] = React.useState(false);
    const [spotToShare, setSpotToShare] = React.useState<string>(null);
    const [spotSharingType, setSpotSharingType] = React.useState(SpotSharingType.OneTime);

    const [currentSpot, setCurrentSpot] = React.useState<string>(null);
    const query = useQuery();
    const spotId = query.get("spotId");
    const initialMode: initialMode = query.get("initialMode") as initialMode;
    const shareType: shareType = query.get("shareType") as shareType;
    const initialized = React.useRef(false);
    const {hideParkingSpotsCreationButton} = useUser();
    
    const onAdd = () => {
        setCurrentSpot(null);
        setSpotFormVisible(true);
    }

    const onEdit = (id: string) => {
        setCurrentSpot(id);
        setSpotFormVisible(true);
    }

    const onShare = (id: string) => {
        setSpotToShare(id);
        setShareFormVisible(true);
    }

    const onRemoveClicked = async (id: string) => {
        const confirm = await ConfirmModal.show({
            text: Resources.Czy_na_pewno_chcesz_usunac_miejsce,
            confirmText: Resources.Usun
        });
        if(confirm){
            onRemoveParkingSpot(id);
        }
    }

    const onDeactivateClicked = async (id: string) => {
        const confirm = await ConfirmModal.show({
            text: Resources.Czy_na_pewno_chcesz_dezaktywowac_miejsce,
            confirmText: Resources.Usun
        });
        if(confirm){
            onDeactivateParkingSpot(id);
        }
    }

    React.useEffect(() => {
        if(items && !initialized.current){
            if(initialMode === "add"){
                onAdd();
                initialized.current = true;
            }
            else if(initialMode === "share" && (spotId || items.length)){
                setSpotSharingType(shareType === "cyclic" ? SpotSharingType.Cyclic : SpotSharingType.OneTime);
                onShare(spotId || items[0].id);
                initialized.current = true;
            }
            
        }
    }, [items]);

    return (
        <ListContainer>
            <ShareParkingSpotForm 
                showSpotSelector={true}
                onClose={() => setShareFormVisible(false)} 
                onSubmitted={() => (onShared(), setShareFormVisible(false))}
                visible={shareFormVisible}
                spotToShare={spotToShare}
                initialSharingType={spotSharingType}
            />
            <ParkingSpotForm 
                onClose={() => setSpotFormVisible(false)} 
                visible={spotFormVisible}
                mode="user"
                onSubmitted={async (wasAdded, spotId) => {
                    setSpotFormVisible(false);
                    onReload();
                    if(wasAdded){
                        const shouldShare = await YesNoModal.show({text: Resources.Czy_chcesz_udostepnic_swoje_miejsce_parkingowe});
                        if(shouldShare){
                            onShare(spotId);
                        }
                    }
                    
                }}  
                spotId={currentSpot}
            />
            {!hideParkingSpotsCreationButton && <ListAddButton onClick={onAdd}>{Resources.Dodaj_nowe_miejsce}</ListAddButton>}
            {progress && <Spinner />}
            {progress && typeof error == "number" && <ErrorView title={getErrorMessage(error)} />}
            {!progress && !error && (
                <>
                    {items.map((spot, index, arr) => (
                        <ParkingSpotsListItem 
                            key={index}
                            item={spot}
                            onActivateParkingSpot={onActivateParkingSpot}
                            onDeactivateParkingSpot={onDeactivateClicked}
                            onRemoveParkingSpot={onRemoveClicked}
                            onEditParkingSpot={onEdit}
                            onShareParkingSpot={onShare}
                        />
                    ))}
                </>
            )}
        </ListContainer>
    );
}