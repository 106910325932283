export const NAME_AND_SURNAME_FORMAT = /(^[A-ZĄĘĆŁŃÓŚŹŻ][a-ząęćłńóśźż]+ [A-ZĄĘĆŁŃÓŚŹŻ][a-ząęćłńóśźż]+$)|(^[A-ZĄĘĆŁŃÓŚŹŻ][a-ząęćłńóśźż]+ [A-ZĄĘĆŁŃÓŚŹŻ][a-ząęćłńóśźż]+ [A-ZĄĘĆŁŃÓŚŹŻ][a-ząęćłńóśźż]+$)|(^[A-ZĄĘĆŁŃÓŚŹŻ][a-ząęćłńóśźż]+ [A-ZĄĘĆŁŃÓŚŹŻ][a-ząęćłńóśźż]+-[A-ZĄĘĆŁŃÓŚŹŻ][a-ząęćłńóśźż]+$)/;

export const getNameAndSurname = (nameAndSurname: string): {name: string, surname: string} => {
    if(!NAME_AND_SURNAME_FORMAT.test(nameAndSurname)){
        return {name: nameAndSurname, surname: undefined};
    }

    const lastSpace = nameAndSurname.lastIndexOf(" ");

    if(lastSpace < 0){
        return {name: nameAndSurname, surname: undefined}
    }

    return {
        name: nameAndSurname.substr(0, lastSpace),
        surname: nameAndSurname.substr(lastSpace + 1)
    }
}