import { ClientReservationStatus, GetUserReservationsReservationType, GetUserReservationsResultEntryDto, ParkingSpotsService, ServiceConfig, SortOrder, UserReservationInfoDto, UsersService } from "parkcash-api";
import * as React from "react";
import Resources from "../../Resources";
import DropdownList from "../components/DropdownList";
import Body from "../layouts/Main/Body";
import { StandardButton } from "../styles/Buttons";
import { BaseSpaceSeparator } from "../styles/Separators";
import { MOBILE_WIDTH_THRESHOLD } from "../utils/Constants";
import { useWindowSize } from "../utils/WindowSizeHook";
import { getFilters, getSorts } from "../utils/SieveUtils";
import Enumerable from "linq";
import { CustomDataSource, FilterOperator } from "../utils/DataSource";
import { useJWT } from "../utils/JWTUtils";
import PCDataGrid from "../components/PCDataGrid";
import Colors from "../styles/Colors";
import { PCText } from "../styles/Texts";
import ParkingSpotNumberPresenter from "../components/ParkingSpotNumberPresenter";
import StatusPresenter from "../components/StatusPresenter";
import ReservationDetails from "./ReservationDetails";
import ActionColumn, { ActionIcon } from "../components/PCDataGrid/ActionColumn";
import { faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import ReservationStatusPresenter from "./ReservationStatusPresenter";
import ConfirmModal from "../components/ConfirmModal";
import Notify from "../utils/Notify";
import { cancelReservation } from "./BookingsUtils";

const ReservationDetailsComponent = (props: {item: GetUserReservationsResultEntryDto}) => <ReservationDetails reservationId={props.item.id} />

export default () => {
    const jwt = useJWT();
    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const [reservationFilter, setReservationFilter] = React.useState("all");
    const [dataSource] = React.useState(new CustomDataSource<GetUserReservationsResultEntryDto, {jwt: string}>({
        load: async (loadOptions, additionalInfo) => {
            const {page,pageSize,filter, sort} = loadOptions;
            const {jwt} = additionalInfo;
            const statusFilter = Enumerable.from(filter).firstOrDefault(f => f.key === "status");
            const statusFilterValue: ClientReservationStatus = statusFilter?.value;
            
            const filters = typeof statusFilterValue === "string" ? getFilters<GetUserReservationsResultEntryDto>([
                {
                    key: "status",
                    operator: FilterOperator.EQUALS,
                    value: statusFilterValue
                }
            ]) : "";
            const sorts = getSorts(sort);
            const {error,isSuccess,result} = await new UsersService(new ServiceConfig({jwt})).getUserReservations(filters, sorts, page, pageSize); 

            if(isSuccess){
                const {entries, paginationDto: {totalElementsCount}} = result;
                return {
                    items: entries,
                    totalItems: totalElementsCount
                }
            }
            else{
                return error.code;
            }
            
        },
        additionalInfo: {jwt},
        initialSort: [
            {key: "startTimestamp", order: SortOrder.Descending}
        ]
    }));

    let grid: PCDataGrid<GetUserReservationsResultEntryDto> = null;

    const onReservationFilterChanged = (v: string) => {
        setReservationFilter(v);
        v === "all" ? dataSource.removeFilter("status") : dataSource.filter("status", FilterOperator.EQUALS, v);
    }

    const onDismiss = async (item: GetUserReservationsResultEntryDto) => {
        const confirm = await ConfirmModal.show({
            text: Resources.Czy_na_pewno_chcesz_odwolac_ta_rezerwacje,
            confirmText: Resources.Odwolaj
        });
        if(confirm){
            const result = await cancelReservation(item.id);
            if(result){
                item.status = ClientReservationStatus.Canceled;
                grid?.refresh();
            }
        }
    }

    const filterRow = (
        <>
            <div style={{ width: 215 }}>
                <DropdownList
                    value={reservationFilter}
                    onChange={onReservationFilterChanged}
                    actions={[
                        {
                            id: "all",
                            text: Resources.Wszystkie
                        },
                        {
                            id: ClientReservationStatus.Pending.toString(),
                            text: Resources.Aktywne
                        },
                        {
                            id: ClientReservationStatus.Completed.toString(),
                            text: Resources.Zakonczone
                        },
                        {
                            id: ClientReservationStatus.Canceled.toString(),
                            text: Resources.Anulowane
                        },
                        {
                            id: ClientReservationStatus.Confirmed.toString(),
                            text: Resources.Zaplanowane
                        }
                    ]} />
            </div>
            <BaseSpaceSeparator size={20} />
            <div style={{ width: 215 }}>
                <StandardButton variant="big" to="/makereservation">{Resources.Dodaj_rezerwacje}</StandardButton>
            </div>
        </>
    );
    return (
        <Body 
            title={Resources.Rezerwacje}
            rigth={filterRow}
        >
            <PCDataGrid<GetUserReservationsResultEntryDto>
                ref={i => {
                    if(i){
                        grid = i;
                    }
                }}
                dataSource={dataSource}
                //disableSorting
                AdditionalRowComponent={ReservationDetailsComponent}
                columns={[
                    {
                        width: 210,
                        label: Resources.Adres,
                        dataField: "streetName",
                        renderCell: item => (
                            <PCText fontSize={16} lineHeight={20/16} semibold color={Colors.brownish_grey} letterSpacing={0}>
                                {item.city}
                                <br />
                                <PCText inline fontSize={16} semibold lineHeight={20/16} color={Colors.black} letterSpacing={0}>
                                    {item.streetName} {item.streetNumber}
                                </PCText>
                            </PCText>
                        )
                    },
                    {
                        width: 165,
                        label: Resources.Data_rozpoczecia,
                        dataField: "startTimestamp"
                    },
                    {
                        width: 165,
                        label: Resources.Data_zakonczenia,
                        dataField: "endTimestamp"
                    },
                    {
                        width: 148,
                        label: Resources.Miejsce,
                        dataField: "spotNumber",
                        renderCell: item => {
                            const {level, spotNumber, status, reservationSensitiveDataAccessible, sector} = item;
                            if(reservationSensitiveDataAccessible){
                                return <ParkingSpotNumberPresenter level={level} spotNumber={spotNumber} sector={sector} />
                            }
                            else if(status === ClientReservationStatus.Confirmed && !reservationSensitiveDataAccessible){
                                return <PCText lineHeight={20/16} fontSize={16} letterSpacing={0} semibold color={Colors.heliotrope}>{Resources.Miejsce_zostanie_podane_15_min_przed_parkowaniem}</PCText>
                            }
                            else{
                                return null;
                            }
                        }
                    },
                    {
                        width: 148,
                        label: Resources.Koszt,
                        renderCell: item => {
                            const cost  = item.effectiveCost
                            return <PCText lineHeight={20/16} fontSize={16} semibold color={Colors.black}>{cost ? `${cost} ${Resources.zl}` : "-"}</PCText>
                        }
                    },
                    {
                        width: 148,
                        label: Resources.Parkujacy,
                        dataField: "targetName",
                        renderCell: item => {
                            const {targetName, licensePlateNumber} = item;
                            return (
                                <PCText fontSize={16} lineHeight={20/16} semibold color={Colors.brownish_grey} letterSpacing={0}>
                                    {licensePlateNumber}
                                    <br />
                                    <PCText inline fontSize={16} semibold lineHeight={20/16} color={Colors.black} letterSpacing={0}>
                                        {targetName}
                                    </PCText>
                                </PCText>
                            );
                        }
                    },
                    {
                        width: 148,
                        label: Resources.Status,
                        dataField: "status",
                        renderCell: item => <ReservationStatusPresenter status={item.status}/>
                    },
                    {
                        width: '140',
                        label: Resources.Akcje,
                        renderCell: (item, hasAdditionalRow) => (
                            <ActionColumn>
                                <ActionIcon 
                                    title={Resources.Szczegoly} 
                                    icon={faEye}
                                    visible={item.type === GetUserReservationsReservationType.ReservationProcess}
                                    iconColor={hasAdditionalRow ? Colors.brownish_grey : Colors.very_light_pink} 
                                    onClick={() => hasAdditionalRow ? grid.removeAdditionalRow(item) : grid.addAdditionalRow(item)}
                                />
                                <ActionIcon
                                    visible={item.canDismiss}
                                    icon={faTrashAlt}
                                    onClick={() => onDismiss(item)}
                                    title={Resources.Odwolaj}
                                />
                            </ActionColumn>
                        )
                    }
                ]}
            />
        </Body>
    )
}