import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { ErrorCode, GetParkingInvoicesViewResultEntryDto, InvoiceClientStatus, InvoicesService, ServiceConfig } from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import Resources from "../../Resources";
import PCDataGrid from "../components/PCDataGrid";
import ActionColumn, { ActionIcon } from "../components/PCDataGrid/ActionColumn";
import Body from "../layouts/Main/Body";
import { applicationState } from "../redux/ApplicationState";
import Colors from "../styles/Colors";
import { PCText } from "../styles/Texts";
import { CustomDataSource, IDataSource } from "../utils/DataSource";
import { IParkCashApplicationMode } from "../utils/ParkCashApplicationMode";
import { getFilters, getSorts } from "../utils/SieveUtils";

interface Props {
    jwt: string,
    applicationMode: IParkCashApplicationMode;
}

const Container = (props: Props) => {
    const {jwt, applicationMode} = props;
    const [dataSource, setDataSource] = React.useState<IDataSource<GetParkingInvoicesViewResultEntryDto>>(null);

    React.useEffect(() => {
        const parkingId = applicationMode.getCurrentParking()?.parkingId;
        if(parkingId){
            setDataSource(getDataSource(parkingId));
        }
    }, []);

    React.useEffect(() => {
        const parkingId = applicationMode.getCurrentParking()?.parkingId;
        if(parkingId){
            setDataSource(getDataSource(parkingId));
        }
    }, [applicationMode.getCurrentParking()?.parkingId]);

    const getDataSource = (parkingId: string) => {
        const result = new CustomDataSource<GetParkingInvoicesViewResultEntryDto, {jwt: string, parkingId: string}>({
            load: async (loadOptions, additional) => {
                const {filter, page, pageSize, sort} = loadOptions;
                const {jwt, parkingId} = additional;
                
                try{
                    const {isSuccess,error,result} = await new InvoicesService(new ServiceConfig({jwt})).getParkingInvoicesView
                    (
                        parkingId, 
                        getFilters(filter), 
                        getSorts(sort), 
                        page, 
                        pageSize
                    );

                    return isSuccess ? {
                        items: result.entries,
                        totalItems: result.paginationDto.totalElementsCount
                    } : error.code;
                }
                catch(e){
                    return ErrorCode.NetworkError;
                }
                
            },
            additionalInfo: {jwt, parkingId}
        });
        
        return result;
    }

    return (
        <Body title={Resources.Faktury}>
            <PCDataGrid<GetParkingInvoicesViewResultEntryDto>
                dataSource={dataSource}
                columns={[
                    {
                        label: Resources.Nr_faktury,
                        dataField: "fullNumber",
                        width: 100
                    },
                    {
                        label: Resources.Data_wystawienia,
                        dataField: "creationTimestamp",
                        width: 100
                    },
                    {
                        label: Resources.NIP,
                        dataField: "nip",
                        width: 100
                    },
                    {
                        label: Resources.Nazwa_firmy,
                        dataField: "companyName",
                        width: 100,
                        renderCell: item => (
                            <PCText color={Colors.black} lineHeight={20/16} semibold fontSize={16} letterSpacing={0}>{item.companyName}</PCText>
                        )
                    },
                    {
                        label: Resources.Cena_netto,
                        dataField: "netPrice",
                        width: 100,
                        renderCell: item => `${item.netPrice} zł`
                    },
                    {
                        label: Resources.VAT,
                        dataField: "vatRate",
                        width: 100
                    },
                    {
                        label: Resources.Cena_brutto,
                        dataField: "grossPrice",
                        width: 100,
                        renderCell: item => `${item.grossPrice} zł`
                    },
                    {
                        label: Resources.Status_faktury,
                        dataField: "invoiceStatus",
                        disableSort: true,
                        width: 100,
                        renderCell: item => {
                            if(item.invoiceStatus === InvoiceClientStatus.NoInvoice){
                                return "-";
                            }
                            else if(item.invoiceStatus === InvoiceClientStatus.WaitingForProcessing){
                                return Resources.W_przygotowaniu;
                            }
                            else {
                                return Resources.Gotowa;
                            }
                        }
                    },
                    {
                        label: Resources.Akcje,
                        width: '150',
                        renderCell: item => {
                            return (
                                <ActionColumn>
                                    <ActionIcon visible={item.invoiceStatus === InvoiceClientStatus.Ready} href={item.url} title={Resources.Pobierz_fakture} target="_blank" icon={faFileInvoice}/>
                                </ActionColumn>
                            )
                        }
                    }
                ]}    
            />
        </Body>
    );
}

const mapStateToProps = (state: applicationState): Props => ({
    applicationMode: state.user.applicationMode,
    jwt: state.user.token
});

export default connect(mapStateToProps)(Container);