import * as React from "react";
import Colors from "../styles/Colors";
import { PCClasses } from "../utils/CSSUtils";
import "./DropdownList.scss";
import { PCText, SOURCESANSPRO_REGULAR } from "../styles/Texts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Enumerable from "linq";
import Resources from "../../Resources";
import { BaseSpaceSeparator } from "../styles/Separators";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface DropdownListOption<T = string> {
  id: T;
  text?: string;
  icon?: IconProp;
  image?: any;
  metadata?: any;
  className?: string;
}

export interface DropdownListProps<T> {
  actions: DropdownListOption<T>[];
  value: T;
  onChange?: (value: T) => void;
  placeholder?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  isInvalid?: boolean;
  zIndex?: number;
  borderColor?: string;
  variant?: "big" | "standard";
  label?: string;
  disabled?: boolean;
  children?: (
    item: DropdownListOption<T>,
    isOption: boolean,
    handleClick: () => void
  ) => React.ReactNode;
  minHeight?: number;
  maxContentHeight?: number;
  comparator?: (t1: T, t2: T) => boolean;
  searchable?: boolean;
  searchCallback?: (t: DropdownListOption<T>, search: string) => boolean;
}

interface State {
  width: number;
  height: number;
  opacity: 0 | 1;
  display: "block" | "none";
  search: string;
  focused: boolean;
}

const ANIMATION_DURATION = 200;

export default class DropdownList<T = string> extends React.Component<
  DropdownListProps<T>,
  State
> {
  private wrapper: HTMLDivElement;

  private disableReshow = false;

  private input: HTMLInputElement;

  private readonly defaultComparator = (t1: T, t2: T) => t1 === t2;

  private readonly defaultSearchCallback = (
    t: DropdownListOption<T>,
    search: string
  ) => {
    if(!search) return true;
    return t.text.toLowerCase().indexOf(search.toLowerCase()) >= 0;
  };

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      display: "none",
      opacity: 0,
      search: "",
      focused: false,
    };
  }

  private onClick = (event: React.MouseEvent) => {
    if (!this.disableReshow) {
      this.showContent(event);
    }
  };

  componentDidUpdate() {
    this.updateDimensions();
  }

  componentDidMount() {
    this.updateDimensions();
  }

  private updateDimensions = () => {
    const { width, height } = this.state;
    const newHeight = this.wrapper?.clientHeight || 0;
    const newWidth = this.wrapper?.clientWidth || 0;
    if (newWidth !== width || newHeight !== height) {
      this.setState({ height: newHeight, width: newWidth });
    }
  };

  private showContent = (event: React.MouseEvent) => {
    document.body.click();
    event.stopPropagation();
    /*
    *  document.body.click();
    event.stopPropagation();- this is added to not hide menu immediately (React 18 different event behaviour)
    * but if propagation is stopped, the menu will not hide at all if clicked or other menu instance (event does not reach document.body)
    * 
    * */
    this.setState({ focused: true });
    if (this.props.searchable) {
      const {
        actions,
        comparator = this.defaultComparator,
        value,
      } = this.props;
      const current = Enumerable.from(actions).firstOrDefault((a) =>
        comparator(a.id, value)
      );
      this.setState({ search: current?.text });
      setTimeout(() => {
        this.input?.focus();
      }, 100);
    }
    this.disableReshow = true;
    document.body.addEventListener("click", this.hideContent);
    this.setState({ display: "block" });
    setImmediate(() => {
      this.setState({ opacity: 1 });
      this.props.onFocus && this.props.onFocus();
    });
  };

  private hideContent = () => {
    this.input?.blur();
    this.setState({ focused: false, search: "" });
  //  document.body.click()
    document.body.removeEventListener("click", this.hideContent);
    this.setState({ opacity: 0 });
    setTimeout(() => {
      this.setState({ display: "none" });
      setImmediate(() => {
        this.disableReshow = false;
        this.props.onBlur && this.props.onBlur();
      });
    }, ANIMATION_DURATION);
  };

  private onSelected = (id: T) => {
    const { onChange } = this.props;
    onChange && onChange(id);
    this.hideContent();
  };

  private getBorderColor = () => {
    const { borderColor = Colors.very_light_pink } = this.props;
    return borderColor;
  };

  private getFontSize = () => {
    const { variant = "big" } = this.props;
    return variant === "big" ? 20 : 16;
  };

  private renderContent() {
    const { width, height, opacity, display, search } = this.state;
    const {
      zIndex = 1000,
      children,
      maxContentHeight,
      searchCallback = this.defaultSearchCallback,
      searchable,
    } = this.props;
    if (!height || !width) {
      return null;
    }
    const menuItems = searchable
      ? this.props.actions.filter((a) => searchCallback(a, search))
      : this.props.actions;
    return (
      <div
        style={{
          transition: `opacity ${ANIMATION_DURATION}ms`,
          display,
          opacity,
          width: width,
          transform: `translate(${0}px, ${height - 39}px)`,
          zIndex,
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: Colors.white,
          borderRadius: 25,
          padding: "49px 20px 10px 20px",
          boxSizing: "border-box",
          border: `1px ${this.getBorderColor()} solid`,
          overflow: "auto",
          maxHeight: maxContentHeight,
        }}
      >
        {menuItems.map((item, index, arr) => {
          const { text, id, icon, image } = item;
          let classes:Array<any> = ["pc-dropdownlist-link", "pc-button"];
          if(item.className) classes.push(item.className);
          const className = PCClasses(classes);
          const separator = arr.length > 1 && index > 0;
          return (
            <React.Fragment key={id?.toString()??text}>
              {separator && (
                <div
                  style={{ height: 1, backgroundColor: Colors.very_light_pink }}
                />
              )}
              {!!children && children(item, true, () => this.onSelected(id))}
              {!children && (
                <div
                  className={"pc-button"}
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => this.onSelected(id)}
                >
                  {!!icon && (
                    <>
                      <FontAwesomeIcon
                        icon={icon}
                        color={Colors.light_royal_blue}
                        style={{ fontSize: 16 }}
                      />
                      <BaseSpaceSeparator size={5} />
                    </>
                  )}
                  {!icon && !!image && (
                    <>
                      <img src={image} width={18} height={16} />
                      <BaseSpaceSeparator size={5} />
                    </>
                  )}
                  <div
                    className={className}
                    style={{
                      fontFamily: "SourceSansProRegular",
                      padding: "10px 0px",
                      fontSize: 16,
                      lineHeight: 1.5,
                      cursor: "pointer",
                      textDecoration: "none",
                      outline: "none",
                      display: "block",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {text}
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  render() {
    const {
      minHeight = 58,
      actions,
      value,
      placeholder = Resources.Wybierz,
      isInvalid,
      zIndex = 1,
      label,
      variant,
      disabled,
      children,
      searchable = false,
      comparator = (t1: T, t2: T) => t1 === t2,
    } = this.props;
    const { focused, search } = this.state;
    const current = Enumerable.from(actions).firstOrDefault((a) =>
      comparator(a.id, value)
    );
    const fontSize = this.getFontSize();

    return (
      <div
        ref={(i) => (this.wrapper = i)}
        style={{
          width: "100%",
          minHeight,
          boxSizing: "border-box",
          position: "relative",
          pointerEvents: disabled ? "none" : undefined,
          opacity: disabled ? 0.5 : 1,
        }}
      >
        {!!label && (
          <div
            style={{
              backgroundColor: Colors.white,
              paddingLeft: 10,
              paddingRight: 10,
              position: "absolute",
              top: -5,
              left: 32,
              display: "inline-block",
              zIndex: zIndex + 2,
            }}
          >
            <PCText
              color={Colors.brownish_grey}
              fontSize={variant === "big" ? 14.4 : 12}
              lineHeight={variant === "big" ? 1.33 : 1.5}
              letterSpacing={0}
            >
              {label}
            </PCText>
          </div>
        )}
        <div
          onClick={this.onClick}
          style={{
            zIndex: zIndex + 1,
            position: "absolute",
            cursor: "pointer",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: 28,
            border: `1px ${
              isInvalid ? Colors.red : this.getBorderColor()
            } solid`,
            display: "flex",
            alignItems: "center",
            paddingLeft: 25,
            paddingRight: 25,
            paddingTop: 10,
            paddingBottom: 10,
            backgroundColor: Colors.white,
          }}
        >
          {!!current?.icon && (
            <>
              <FontAwesomeIcon
                icon={current.icon}
                color={Colors.light_royal_blue}
                style={{ fontSize }}
              />
              <BaseSpaceSeparator size={5} />
            </>
          )}
          {!current?.icon && !!current?.image && (
            <>
              <img src={current.image} width={18} height={16} />
              <BaseSpaceSeparator size={5} />
            </>
          )}
          <div
            style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {!!children && !!current && children(current, false, null)}
            {(!children || !current) && (
              <input
                ref={(i) => (this.input = i)}
                style={{
                  outline: "none",
                  border: "none",
                  padding: 0,
                  fontSize: fontSize,
                  color: Colors.brownish_grey,
                  fontFamily: SOURCESANSPRO_REGULAR,
                  cursor: "pointer",
                }}
                readOnly={!focused || !searchable}
                placeholder={placeholder}
                value={(focused && searchable ? search : current?.text) || ""}
                onChange={(e) => {
                  this.setState({ search: e.target.value });
                }}
              />
            )}
          </div>
          <BaseSpaceSeparator size={10} />
          <FontAwesomeIcon
            icon={faAngleDown}
            color={Colors.brownish_grey}
            style={{ fontSize }}
          />
        </div>
        {this.renderContent()}
      </div>
    );
  }
}
