import React from "react"
import Grid from "@mui/material/Unstable_Grid2";
import BookingIllustration from "../../../../../MUI/assets/illustrations/booking-illustration";
import CheckInIllustration from "../../../../../MUI/assets/illustrations/check-in-illustration";
import CheckOutIllustration from "../../../../../MUI/assets/illustrations/check-out-illustration";

import Resources from "../../../../../../Resources";
import {_bookingsOverview} from "../../../../../MUI/_mock";


import {HeatMapSection} from "./HeatMapSection";
import CircularPieChart from "../../../../../MUI/components/AddedOrModyfiedMuiComponents/Charts/CircularPieChart";
import MultiSeriesChartWithBarAndLines
    from "../../../../../MUI/components/AddedOrModyfiedMuiComponents/Charts/MultiSeriesChart";
import StyledListWithProgressBar
    from "../../../../../MUI/components/AddedOrModyfiedMuiComponents/StyledLists/StyledListWithProgressBar";
import WidgetSummaryWithAvatar
    from "../../../../../MUI/components/AddedOrModyfiedMuiComponents/Widgets/WidgetSummaryWithAvatar";

interface UserWidgetSectionProps {
    /*  data: DataItem[];*/
}

export default function ReservationSection(props: UserWidgetSectionProps) {

    const totalReservations = 10000;
    const usedReservations = 9000;
    const unusedReservations = 1000;
    const usedPercentage = usedReservations / totalReservations * 100;
    const unusedPercentage = unusedReservations / totalReservations * 100;

    return (<Grid container spacing={3} xs={12} disableEqualOverflow id={"ReservationSection"}>

        <Grid container spacing={3} xs={12} disableEqualOverflow id={'ReservationWidgetSummariesSection'}>
            <Grid xs={12} md={4}>
                <WidgetSummaryWithAvatar
                    title={Resources.Wszystkie_rezerwacje}
                    currentValue={10000}
                    icon={<BookingIllustration/>}
                />
            </Grid>

            <Grid xs={12} md={4}>
                <WidgetSummaryWithAvatar title={Resources.Wykorzystane} currentValue={9000} percentage={usedPercentage}
                                         icon={<CheckInIllustration/>}/>
            </Grid>

            <Grid xs={12} md={4}>
                <WidgetSummaryWithAvatar title={Resources.Nie_wykorzystane} currentValue={1000}
                                         percentage={unusedPercentage} icon={<CheckOutIllustration/>}/>
            </Grid>
        </Grid>

        <Grid container spacing={3} xs={12} disableEqualOverflow id={'ReservationProgressBarAndHeatMapSection'}>
            <Grid xs={12} md={6}>
                <StyledListWithProgressBar title={Resources.Statusy_rezerwacji} data={_bookingsOverview}
                                           sx={{height: '100%'}}/>
            </Grid>
            <Grid xs={12} md={6}>
                <HeatMapSection/>
                <Grid/>
            </Grid>
        </Grid>
        <Grid container spacing={3} xs={12} disableEqualOverflow id={'ReservationDetailedChartsSection'}>
            <Grid xs={12} md={6} lg={8}>
                <MultiSeriesChartWithBarAndLines
                    title={Resources.Liczba_rezerwacji_w_podziale_na_dni_tygodnia}
                    chart={{
                        labels: [
                            '01/01/2003',
                            '02/01/2003',
                            '03/01/2003',
                            '04/01/2003',
                            '05/01/2003',
                            '06/01/2003',
                            '07/01/2003',
                            '08/01/2003',
                            '09/01/2003',
                            '10/01/2003',
                            '11/01/2003',
                        ],
                        series: [
                            {
                                name: Resources.Przychod,
                                type: 'column',
                                fill: 'solid',
                                yAxisunit: 'PLN',
                                separateYAxis: true,
                                data: [
                                    23,
                                    11,
                                    22,
                                    27,
                                    13,
                                    22,
                                    37,
                                    21,
                                    44,
                                    22,
                                    30
                                ],
                            },
                            {
                                name: Resources.Ilosc_transakcji,
                                type: 'area',
                                fill: 'gradient',
                                data: [
                                    44,
                                    55,
                                    41,
                                    67,
                                    22,
                                    43,
                                    21,
                                    41,
                                    56,
                                    27,
                                    43
                                ],
                            },
                            {
                                name: Resources.Ilosc_zwrotow,
                                type: 'line',
                                fill: 'solid',
                                data: [
                                    30,
                                    25,
                                    36,
                                    30,
                                    45,
                                    35,
                                    64,
                                    52,
                                    59,
                                    36,
                                    39
                                ],

                            },
                        ],
                    }}

                />
            </Grid>
            <Grid xs={12} md={6} lg={4}>
                <CircularPieChart
                    title={Resources.Typ_rezerwacji}
                    chart={{
                        series: [
                            {
                                label: Resources.Platne,
                                value: 4344
                            },
                            {
                                label: Resources.Bezplatne,
                                value: 5435
                            },
                            {
                                label: Resources.Gosc,
                                value: 1443
                            },
                            {
                                label: Resources.Stale,
                                value: 4443
                            },

                        ],
                    }}
                />
            </Grid>
        </Grid>
    </Grid>)

}