import { ErrorCode, ISharingRequestsDto } from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import Resources from "../../Resources";
import ListGradient from "../components/ListGradient";
import PCScrollbars from "../components/PCScrollbars";
import Spinner from "../components/Spinner";
import { applicationState } from "../redux/ApplicationState";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { getErrorMessage } from "../utils/ErrorUtils";
import { loadSharingRequests } from "./Redux";
import RequestsListItem from "./RequestsListItem";

interface Props {
    items: ISharingRequestsDto[],
    progress: boolean,
    error: ErrorCode,
    onInit: () => void
}

const Container = (props: Props) => {
    const {error, progress, items, onInit} = props;

    React.useEffect(() => {
        onInit();
    }, []);

    return (
        <div style={{height: 404, width: 300}}>
            <div style={{height: 35}}>
                <PCText fontSize={24} lineHeight={35/24} semibold color={Colors.black}>
                    {Resources.Zapytania_o_miejsce}
                </PCText>
            </div>
            <BaseSpaceSeparator size={9} />
            <div
                style={{
                    width: 300,
                    height: 360,
                    boxSizing: "border-box",
                    boxShadow: "0 3px 26px 4px rgba(0, 0, 0, 0.08)",
                    backgroundColor: Colors.white,
                    borderRadius: 25,
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    paddingTop: 20,
                    paddingBottom: 20
                }}
            >
                {progress && (
                    <div style={{flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <Spinner size="extra-small" />
                    </div>
                )}
                {!progress && typeof error === "number" && (
                    <div style={{flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <PCText fontSize={14} textAlign="center" color={Colors.red} semibold>{getErrorMessage(error)}</PCText>
                    </div>
                )}
                {!progress && !error && !items.length &&  (
                    <div style={{flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <PCText fontSize={14} textAlign="center" color={Colors.black} semibold>{Resources.Brak_danych}</PCText>
                    </div>
                )}
                {!progress && !error && !!items.length && (
                    <PCScrollbars height={320} contentContainerStyle={{paddingLeft: 20, paddingRight: 20}}>
                        {items.map((item, index, arr) => {
                            return (
                                <React.Fragment
                                    key={item.id}
                                >
                                    <RequestsListItem item={item}/>
                                    {index !== arr.length && <BaseSpaceSeparator size={20} />}
                                </React.Fragment>
                            );
                        })}
                    </PCScrollbars>
                )}  
            </div>
        </div>
    );
}

const mapStateToProps = (state: applicationState): Partial<Props> => ({
    error: state.sharing.error,
    items: state.sharing.items || [],
    progress: state.sharing.progress
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
    onInit: () => dispatch(loadSharingRequests(true))
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);