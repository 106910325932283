import { ParkingMemberType } from "parkcash-api";
import Resources from "../../../Resources";
import Colors from "../../styles/Colors";

export const getTextColor = (memberType: ParkingMemberType) => {
    switch(memberType){
        case ParkingMemberType.Manager:
            return Colors.white;
        case ParkingMemberType.User:
            return Colors.white;
        case ParkingMemberType.Staff:
            return Colors.brownish_grey;
        case ParkingMemberType.Moderator:
            return Colors.white;
    }
}

export const getText = (memberType: ParkingMemberType) => {
    switch(memberType){
        case ParkingMemberType.Manager:
            return Resources.Zarzadca;
        case ParkingMemberType.User:
            return Resources.ZwyklyUzytkownik;
        case ParkingMemberType.Staff:
            return Resources.Obsluga;
        case ParkingMemberType.Moderator:
            return Resources.Moderator;
    }
}

export const getColor = (memberType: ParkingMemberType) => {
    switch(memberType){
        case ParkingMemberType.Manager:
            return Colors.red;
        case ParkingMemberType.User:
            return Colors.light_royal_blue;
        case ParkingMemberType.Staff:
            return Colors.very_light_pink_two;
        case ParkingMemberType.Moderator:
            return Colors.green;
    }
}