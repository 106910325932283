import { DataSourceLoadedArgs } from "./IDataSource";
import Enumerable from "linq";
import DataSource from "./DataSource";
import { SortOrder } from "parkcash-api";


export class ArrayDataSource<T> extends DataSource<T> {
    
    private data: T[];

    private customSorts: {[key in keyof T]?: (item1: T, item2: T) => number};

    private customFilters: {[key in keyof T]?: (item: T, value: any) => boolean};  

    constructor(args: {
        data: T[];
        customSorts?: {[key in keyof T]?: (item1: T, item2: T) => number},
        customFilters?: {[key in keyof T]?: (item: T, value: any) => boolean}
    }){
        super();
        this.data = args.data;
        this.customSorts = args.customSorts || {};
        this.customFilters = args.customFilters || {};
    }

    protected loadDataCore(): void {
        let current = Enumerable.from(this.data);
        this.currentFilter.forEach(filter => {
            const filterCallback = this.customFilters[filter.key] ? (item: T) => this.customFilters[filter.key](item, filter.value) : (item: T) => item[filter.key] === filter.value;
            current = current.where(filterCallback);
        });

        const totalItems = current.count();

        if(this.currentSort.length){
            const comparer = (index: number) => {
                return (item1: T, item2: T) => {
                    const sort = this.currentSort[index];
                    const customComparer = this.customSorts[sort.key];

                    const compareResult = customComparer ? customComparer(item1, item2) : ((i1: T, i2: T) => {
                        if(i1[sort.key] > i2[sort.key]){
                            return 1;
                        }
                        else if(i1[sort.key] < i2[sort.key]){
                            return -1;
                        }
                        return 0;
                    })(item1, item2);
                    const targetCompareResult = sort.order === SortOrder.Ascending ? compareResult : compareResult * (-1);
                
                    if(targetCompareResult !== 0 || !this.currentSort[index + 1]){
                        return targetCompareResult;
                    }
                    else{
                        return comparer(index + 1)(item1, item2);
                    }                   
                }  
            };

            current = Enumerable.from(current.toArray().sort(comparer(0)));
        }

        current = current.skip((this.currentPage - 1)*this.currentPageSize).take(this.currentPageSize);
        const items = current.toArray();

        this.onDataSourceLoaded(items, totalItems);
    }   
} 