import {GetParkingManagementContextEntryDto, ParkCashRole, ParkingMemberType,} from "parkcash-api";
import Resources from "../../Resources";
import Enumerable from "linq";

const logo = require("../../assets/images/logo.svg");

export interface IParkCashApplicationMode {
    isSuperAdmin(): boolean;

    isUser(): boolean;

    isParkingManager(): boolean;

    getParkings(): GetParkingManagementContextEntryDto[];

    hasParkingPlanInCurrentParking(): boolean;

    isInParkingManagementMode(): boolean;

    isInUserMode(): boolean;

    changeCurrentParking(parkingId: string): IParkCashApplicationMode;

    changeToUserMode(): IParkCashApplicationMode;

    getMenuConfiguration(hidePilotsManagement: boolean, hideGroups: boolean): Array<MenuConfiguration>;

    getCurrentParking(): GetParkingManagementContextEntryDto;

    getLogo(): string;

    hasExternalReservations(): boolean;

    isParkingStaff(): boolean;
}

export class NullParkCashApplicationMode implements IParkCashApplicationMode {
    private static instance = new NullParkCashApplicationMode();

    public static getInstance() {
        return this.instance;
    }

    isParkingStaff(): boolean {
        return false;
    }

    hasParkingPlanInCurrentParking(): boolean {
        return false;
    }

    getLogo(): string {
        return logo;
    }

    hasExternalReservations(): boolean {
        return false;
    }

    getMenuConfiguration(): MenuConfiguration[] {
        return [];
    }

    isSuperAdmin(): boolean {
        return false;
    }

    isUser(): boolean {
        return false;
    }

    isParkingManager(): boolean {
        return false;
    }

    getParkings(): GetParkingManagementContextEntryDto[] {
        return [];
    }

    isInParkingManagementMode(): boolean {
        return false;
    }

    isInUserMode(): boolean {
        return false;
    }

    changeCurrentParking(parkingId: string): IParkCashApplicationMode {
        return NullParkCashApplicationMode.getInstance();
    }

    changeToUserMode(): IParkCashApplicationMode {
        return NullParkCashApplicationMode.getInstance();
    }

    getCurrentParking(): GetParkingManagementContextEntryDto {
        return null;
    }
}

export interface ExternalRedirectConfig {
    href?: string;
    target?: "_blank" | "_self" | "_parent" | "_top";
}

export interface MenuConfiguration {
    text: string;
    to?: string;
    externalRedirect?: ExternalRedirectConfig;
    submenus?: MenuConfiguration[];
}

export default class ParkCashApplicationMode implements IParkCashApplicationMode {
    constructor(private readonly roles: ParkCashRole[], private readonly parkings: GetParkingManagementContextEntryDto[], private readonly parkingId: string) {
    }

    hasParkingPlanInCurrentParking(): boolean {
        return this.getCurrentParking()?.parkingPlanBasedManagementEnabled || false;
    }

    hasExternalReservations(): boolean {
        return this.getCurrentParking()?.externalReservationsEnabled || false;
    }

    getLogo(): string {
        return this.getCurrentParking()?.customLogoUrl || logo;
    }

    getCurrentParkingOSUrl(): string | null {
        return this.getCurrentParking().parkingSystemOsUrl || null;
    }

    getCurrentParking(): GetParkingManagementContextEntryDto {
        return Enumerable.from(this.parkings).firstOrDefault((p) => p.parkingId === this.parkingId);
    }

    getMenuConfiguration(hidePilotsManagement: boolean, hideGroups: boolean): MenuConfiguration[] {
        if (this.isSuperAdmin()) {
            return [
                {text: Resources.Dashboard, to: "/admin"},
                {text: Resources.Parkingi, to: "/admin/parkings"},
                {text: "Search Heat Map", to: "/admin/search-heat-map"},
            ];
        } else if (this.isInParkingManagementMode() && !this.isParkingStaff()) {
            let result: MenuConfiguration[] = [
                {
                    text: Resources.Parkowanie, to: "/parkingmanagement/parkingprocesses",
                },
                {text: Resources.Miejsca, to: "/parkingmanagement/spots"},
                {
                    text: Resources.Dostep, submenus: [
                        {text: Resources.Piloty, to: "/parkingmanagement/pilots"},
                        {text: Resources.LPR, to: "/parkingmanagement/lprmanagement"},
                    ]
                },
                {text: Resources.Faktury, to: "/parkingmanagement/invoices"},

                {
                    text: Resources.Konfiguracja, submenus: [
                        {text: Resources.Uzytkownicy, to: "/parkingmanagement/users"},
                        {
                            text: Resources.Rodzaje_abonamentow, to: "/parkingmanagement/subscriptions",
                        },
                        {
                            text: Resources.Kody_rabatowe, to: "/parkingmanagement/subscriptionspromotioncodes",
                        },
                        {text: Resources.Parkingi, to: "/parkingmanagement/parkingsConfig"},
                    ],
                },
                {
                    text: Resources.Raporty, submenus: [

                        {text: Resources.Wykaz_wolnych_miejsc, to: "/parkingmanagement/reports"},
                        /* { text: Resources.Statystyki_parkingu, to: "/parkingmanagement/mainDashboard" },*/
                        {text: Resources.Dane_finansowe, to: "/parkingmanagement/FinancialReport"},
                        {text: Resources.Aktywnosc_uzytkownikow, to: "/parkingmanagement/UsersReport"},
                        /*  { text: Resources.Wykorzystanie_parkingu, to: "/parkingmanagement/ParkingSpotsReport" },*/
                        {text: Resources.Raporty_Generowane, to: "/parkingmanagement/GeneratedReports"},
                    ],
                },
            ];
            if (this.getCurrentParkingOSUrl()) {
                result.push({
                    text: "ParkingOS", externalRedirect: {
                        href: this.getCurrentParkingOSUrl(), 
                        target: "_blank"
                    }
                })
            }
            return result;
        } else if (this.isInParkingManagementMode() && this.isParkingStaff()) {
            return [];
        } else {
            const result: MenuConfiguration[] = [];

            if (!hidePilotsManagement) {
                result.push({text: Resources.Piloty, to: "/mobilepilots"});
            }
            result.push({text: Resources.Miejsca, to: "/parkingspots"});
            result.push({text: Resources.Rezerwacje, to: "/bookings"});
            if (!hideGroups) {
                result.push({text: Resources.Grupy, to: "/groups"});
            }

            return result;
        }
    }

    isUser(): boolean {
        return !this.isSuperAdmin();
    }

    isSuperAdmin(): boolean {
        return this.roles.indexOf(ParkCashRole.SuperAdmin) >= 0;
    }

    isParkingManager(): boolean {
        return this.parkings.length > 0;
    }

    getParkings(): GetParkingManagementContextEntryDto[] {
        return this.parkings;
    }

    isInParkingManagementMode(): boolean {
        return !!this.parkingId;
    }

    isParkingStaff(): boolean {
        if (!this.isInParkingManagementMode()) {
            return false;
        }

        const parking = this.getCurrentParking();
        return parking.memberType === ParkingMemberType.Staff;
    }

    isInUserMode(): boolean {
        return !this.isInUserMode();
    }

    changeCurrentParking(parkingId: string): IParkCashApplicationMode {
        return new ParkCashApplicationMode(this.roles, this.parkings, parkingId);
    }

    changeToUserMode(): IParkCashApplicationMode {
        return new ParkCashApplicationMode(this.roles, this.parkings, null);
    }
}
