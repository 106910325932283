export const getClientNipNumber = (nip: string) => {
    if(!nip){
        return "";
    }

    return `${nip.substr(0, 3)}-${nip.substr(3, 3)}-${nip.substr(6, 2)}-${nip.substr(8, 2)}`
}

export const getSeverNipNumber = (nip: string) => {
    if(!nip){
        return "";
    }

    return nip.replace(/-/g, "");
}