import * as React from "react";
import Enumerable from "linq";
import { ErrorCode, SortOrder } from "parkcash-api";
import { DataSourceLoadedArgs, IDataSource } from "../../utils/DataSource";
import { MOBILE_WIDTH_THRESHOLD } from "../../utils/Constants";
import Spinner from "../Spinner";
import { PCText } from "../../styles/Texts";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { CONTAINER_MIN_HEIGHT, DEFAULT_AVAILABLE_PAGE_SIZES, DEFAULT_PAGE_SIZE } from "./Constants";
import { Column } from "./Column";
import Table from "./Table";
import List from "./List";


export const ProgressView = (props: {
    children?: string;
}) => { 
    const {children} = props;


    return (
        <div style={{width: '100%', height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <Spinner size="medium" />
            {children && (
                <div style={{marginTop: 20}}>
                    <label className="form-label">{children}</label>
                </div>
            )}    
        </div>
    );
}


interface Props<T> {
    dataSource: IDataSource<T>,
    columns: Array<Column<T>>,
    disableSorting?: boolean,
    disablePaging?: boolean;
    defaultPageSize?: number;
    disableChangePageSize?: number;
    availablePageSizes?: number[];
    children?: React.ReactNode;
    AdditionalRowComponent?: React.ComponentType<{item: T}>;
    EmptyComponent?: React.ComponentType<{}>;
}

interface State<T> {
    items: T[];
    additionalRows: T[];
    page: number;
    totalPages: number;
    totalItems: number;
    pageSize: number;
    progress: boolean;
    error: ErrorCode;
    wrapperWidth: number;
    windowWidth: number;
    sort: Array<{key: keyof T, order: SortOrder}>;
}



export default class PCDataGrid<T> extends React.Component<Props<T>, State<T>> {

    private wrapper: HTMLDivElement;
    
    constructor(props){
        super(props);
        this.state = {
            additionalRows: [],
            items: [],
            page: 1,
            totalPages: null,
            totalItems: null,
            pageSize: this.props.defaultPageSize || DEFAULT_PAGE_SIZE,
            progress: false,
            error: null,
            wrapperWidth: null,
            sort: [],
            windowWidth: window.innerWidth
        }
    }

    addAdditionalRow(item: T){
        const {additionalRows} = this.state;
        const index = additionalRows.indexOf(item);
        if(index >= 0){
            return;
        }
        this.setState({
            additionalRows: [
                ...additionalRows,
                item
            ]
        });
    }

    removeAdditionalRow(item: T){
        const {additionalRows} = this.state;
        this.setState({
            additionalRows: additionalRows.filter(r => r !== item)
        });
    }


    refresh(){
        const {items: currentItems} = this.state;
        this.setState({
            items: [
                ...currentItems
            ]
        });
    }

    componentDidMount(){
        this.loadDataSource(this.props.dataSource);
        window.addEventListener("resize", this.onWindowResize);
        this.setState({wrapperWidth: this.wrapper.clientWidth});
        setTimeout(() => {
            this.setState({wrapperWidth: this.wrapper.clientWidth});
        }, 500);
        
    }

    componentWillUnmount(){
        const {dataSource} = this.props;
        dataSource?.clearAllEvents();
        window.removeEventListener("resize", this.onWindowResize)
    }

    componentWillReceiveProps(newProps: Props<T>){
        if(this.props.dataSource !== newProps.dataSource){
            this.props.dataSource?.clearAllEvents();
            this.loadDataSource(newProps.dataSource);
        }
    }

    private onWindowResize = () => {
        setTimeout(() => {
            this.setState({wrapperWidth: this.wrapper.clientWidth, windowWidth: window.innerWidth});
        }, 100);
    }

    private loadDataSource = (dataSource: IDataSource<T>) => {
        if(!dataSource){
            return;
        }

        dataSource.on("loaded", this.onDataSourceLoaded);
        dataSource.on("loadingError", this.onDataSourceLoadingError);
        dataSource.on("loadingStarted", this.onDataSourceLoadingStarted);
        dataSource.load(1, this.state.pageSize);
    }

    private onDataSourceLoaded = (args: any) => {
        const {items, page, pageSize, totalPages, sort, totalItems} = args as DataSourceLoadedArgs<T>;
        this.setState({items, page, pageSize, totalPages, sort, progress: false, totalItems});
        this.onWindowResize();
    }

    private onDataSourceLoadingError = (error: ErrorCode) => {
        this.setState({progress: false, error, items: [], totalPages: 0});
        this.onWindowResize();
    }

    private onDataSourceLoadingStarted = () => {
        this.setState({progress: true});
        this.onWindowResize();
    }

    private onPageChanged = (page: number) => {
        this.setState({page});
        this.props.dataSource && this.props.dataSource.setPage(page);
    }

    private onPageSizeChanged = (pageSize: number) => {
        this.setState({pageSize});
        this.props.dataSource && this.props.dataSource.setPageSize(pageSize);
    }

    

    

    private onColumnClicked = (column: Column<T>) =>  {
        const {dataField} = column;
        const currentSort = Enumerable.from(this.state.sort).firstOrDefault(c => c.key === dataField);
        if(currentSort && currentSort.order === SortOrder.Ascending){
            this.props.dataSource.addSort(dataField, SortOrder.Descending);            
        }
        else if(currentSort && currentSort.order === SortOrder.Descending){
            this.props.dataSource.removeSort(dataField);
        }
        else{
            this.props.dataSource.addSort(dataField, SortOrder.Ascending);
        }
    }
    

    render() {
        const {
            columns, 
            disablePaging,
            availablePageSizes = DEFAULT_AVAILABLE_PAGE_SIZES,
            disableSorting = false,
            AdditionalRowComponent,
            EmptyComponent        
        } = this.props;
        const {
            items, 
            progress, 
            error,
            page,
            pageSize,
            wrapperWidth,
            sort,
            windowWidth,
            totalItems,
            additionalRows
        } = this.state;

        const isEmpty = !progress && !error && (!items || !items.length) && !!EmptyComponent;

        return (  
            <div>
                <div className="pc-datagrid-wrapper" ref={i => this.wrapper = i}>
                    {isEmpty && <EmptyComponent />}
                    {!isEmpty && (
                        <div style={{overflow: 'inherit', position: 'relative', minHeight: CONTAINER_MIN_HEIGHT}}> 
                            {progress && (
                                <div style={{position: "absolute",top: 0,bottom: 0,left: 0,right: 0,zIndex: 10000,backgroundColor: Colors.white,opacity: 0.5,display: "flex",justifyContent: "center",alignItems: "center"}}>
                                    <ProgressView />
                                </div>
                            )}     
                            {windowWidth >= MOBILE_WIDTH_THRESHOLD && (
                                <Table 
                                    availablePageSizes={availablePageSizes}
                                    columns={columns}
                                    disablePaging={disablePaging}
                                    disableSorting={disableSorting}
                                    error={error}
                                    onColumnClicked={this.onColumnClicked}
                                    items={items}
                                    onPageChanged={this.onPageChanged}
                                    onPageSizeChanged={this.onPageSizeChanged}
                                    page={page}
                                    pageSize={pageSize}
                                    progress={progress}
                                    sort={sort}
                                    totalItems={totalItems}
                                    wrapperWidth={wrapperWidth}
                                    additionalRows={additionalRows}
                                    AdditionalRowComponent={AdditionalRowComponent}
                                />
                            )}
                            {windowWidth < MOBILE_WIDTH_THRESHOLD && (
                                <List 
                                    onPageChanged={this.onPageChanged}
                                    onPageSizeChanged={this.onPageSizeChanged}
                                    availablePageSizes={availablePageSizes}
                                    columns={columns}
                                    items={items}
                                    page={page}
                                    pageSize={pageSize}
                                    totalItems={totalItems}
                                    additionalRows={additionalRows}
                                    AdditionalRowComponent={AdditionalRowComponent}
                                />
                            )}
                        </div>
                    )}
                    
                </div>
            </div>
        );
    } 
}