import {SpotSelectionType} from "parkcash-api";
import Resources from "../../../../Resources";

export const mapSpotSelectionTypeToDisplayText = (spotSelectOption: SpotSelectionType): string => {
    switch (spotSelectOption) {
        case SpotSelectionType.Manual:
            return Resources.Reczny
        case SpotSelectionType.Auto:
            return Resources.Automatyczny
        default:
            return Resources.Nieznany
    }
}