import {
    ClientReservationStatus,
    ErrorCode,
    GetParkingProcessesViewProcessType,
    GetParkingProcessesViewResultEntryDto,
    GetStaffParkingProcessesViewResultEntryDto,
    ParkingsService,
    ServiceConfig, SortOrder,
} from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import ReservationStatusPresenter from "../../bookings/ReservationStatusPresenter";
import PCDataGrid from "../../components/PCDataGrid";
import StatusPresenter from "../../components/StatusPresenter";
import Body from "../../layouts/Main/Body";
import {StandardButton} from "../../styles/Buttons";
import Colors from "../../styles/Colors";
import {BaseSpaceSeparator} from "../../styles/Separators";
import {MOBILE_WIDTH_THRESHOLD} from "../../utils/Constants";
import {CustomDataSource, FilterOperator} from "../../utils/DataSource";
import {IParkCashApplicationMode} from "../../utils/ParkCashApplicationMode";
import {getFilters, getMultiKeysValue, getSorts} from "../../utils/SieveUtils";
import {useWindowSize} from "../../utils/WindowSizeHook";
import {getTypeText} from "./Utils";
import ParkingSpotNumberPresenter from "../../components/ParkingSpotNumberPresenter";
import {useParkingLayout} from "../useParkingLayout";
import {setTimeToDayEnd, setTimeToDayStart} from "../../MUI/utils/format-time";
import {
    CustomizedDatePicker
} from "../../MUI/components/AddedOrModyfiedMuiComponents/CustomizedInputs/CustomizedDatePicker";
import {DatePickerStyles} from "../../MUI/components/AddedOrModyfiedMuiComponents/Styles/SavedSxStyles";
import {CustomizedSelect} from "../../MUI/components/AddedOrModyfiedMuiComponents/CustomizedInputs/CustomizedSelect";
import {
    CustomizedTextfield
} from "../../MUI/components/AddedOrModyfiedMuiComponents/CustomizedInputs/CustomizedTextfield";
import {SearchInputWithButton} from "../../components/SearCHInputWithButton";

interface Props {
    jwt: string;
    applicationMode: IParkCashApplicationMode;
}

export default ({
                    applicationMode,
                    jwt
                }: Props) => {
    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const parkingId = applicationMode.getCurrentParking()?.parkingId || null;
    const [reservationStatusFilter, setReservationStatusFilter] = React.useState("all");
    const [reservationTypeFilter, setReservationTypeFilter] = React.useState("all");
    const [search, setSearch] = React.useState("");
    const [level, setLevel] = React.useState<string>("all");
    const [sector, setSector] = React.useState<string>("all");
    const {
        levels,
        sectors
    } = useParkingLayout();
    const [startDateControl, setStartDateControl] = React.useState<Date | null>(setTimeToDayStart(new Date()));
    const [endDateControl, setEndDateControl] = React.useState<Date | null>(setTimeToDayEnd(new Date()));

    const resetFilters=()=>{
        setReservationStatusFilter("all");
        setReservationTypeFilter("all");
        setSearch("");
        setLevel("all");
        setSector("all");
        setStartDateControl(setTimeToDayStart(new Date()));
        setEndDateControl(setTimeToDayEnd(new Date()));
    }
    const dataSource = React.useMemo(() => {
        resetFilters(); // reset filters when dataSource is created or recreated
        return new CustomDataSource<GetStaffParkingProcessesViewResultEntryDto>({
            load: async ({
                             filter,
                             page,
                             pageSize,
                             sort
                         }) => {
                try {
                    const sorts = getSorts(sort);

                    let targetFilter = filter.filter((f) => f.key !== "licensePlateNumber" && f.key !== "start" && f.key !== "end");

                    const licensePlateNumberValue = filter.find((f) => f.key === "licensePlateNumber");

                    /*dla zakresu początek 8.01, koniec 9.01 powinny się pokazać rezerwację przecinające czyli te które się kończą pomiędzy przedziałem, zaczynają albo zawierają. Warunek: start rezerwacji <=koniec przedziału i koniec rezerwacji >= start przedziału
        
                    Wzór warunku
                        bool overlap = a.start < b.end && b.start < a.end;
                    zakładamy że przedział a to jest to co wybierzesz z kontrolki a przedział b to przedział opisujący strat i end rezerwacji
        
                    czyli równoważne zapisowei b.end> a.start && b.start < a.end
            */
                    const startDateSelectFilterValue = filter.find((f) => f.key === "start");
                    const endDateSelectFilterValue = filter.find((f) => f.key === "end");

                    const multiSearchFilterKeys = getMultiKeysValue([
                        "licensePlateNumber",
                        "spotNumber"
                    ]);

                    const startFilterValue: {
                        key: keyof GetStaffParkingProcessesViewResultEntryDto, operator: FilterOperator, value: any
                    } = {
                        key: "start", 
                        operator: FilterOperator.LESS_THAN,
                        value: endDateSelectFilterValue?.value
                    };
                    const endFilterValue: {
                        key: keyof GetStaffParkingProcessesViewResultEntryDto, operator: FilterOperator, value: any
                    } = {
                        key: "end", 
                        operator: FilterOperator.GREATER_THAN,
                        value: startDateSelectFilterValue?.value
                    };
                    const multiSearchFilterValue: {
                        key: keyof GetStaffParkingProcessesViewResultEntryDto, operator: FilterOperator, value: any
                    } = {
                        key: multiSearchFilterKeys as keyof GetStaffParkingProcessesViewResultEntryDto,
                        operator: FilterOperator.CASE_INSENSITIVE_STRING_CONTAINS,
                        value: licensePlateNumberValue?.value
                    };
                    const filters: Array<{
                        key: keyof GetStaffParkingProcessesViewResultEntryDto, operator: FilterOperator, value: any
                    }> = [
                        ...targetFilter,
                    ];
                    if (licensePlateNumberValue) {
                        filters.push(multiSearchFilterValue);
                    }
                    if (endDateSelectFilterValue) {
                        filters.push(startFilterValue);
                    }
                    if (startDateSelectFilterValue) {
                        filters.push(endFilterValue);
                    }

                    const finalFilters = getFilters(filters);

                    const {
                        isSuccess,
                        error,
                        result
                    } = await new ParkingsService(new ServiceConfig({jwt})).getStaffParkingProcessesView(parkingId,
                        finalFilters,
                        sorts,
                        page,
                        pageSize
                    );
                    if (isSuccess) {
                        return {
                            items: result.entries,
                            totalItems: result.paginationDto.totalElementsCount,
                        };
                    } else {
                        return error.code;
                    }
                } catch (e) {
                    return ErrorCode.NetworkError;
                }
            },
            initialSort: [
                {
                    key: "start",
                    order: SortOrder.Descending
                }
            ],
            initialFilter: [
                {
                    key: "end",
                    operator: FilterOperator.GREATER_THAN,
                    value: setTimeToDayEnd(new Date()).toISOString()
                },
                {
                    key: "start",
                    operator: FilterOperator.LESS_THAN,
                    value: setTimeToDayStart(new Date()).toISOString()
                },
            ]

        });
    }, [parkingId, jwt]);

    const onReservationStatusFilterChanged = (v: string) => {
        setReservationStatusFilter(v);
        v === "all" ? dataSource.removeFilter("status") : dataSource.filter("status", FilterOperator.EQUALS, v);
    };

    const onReservationTypeFilterChanged = (v: string) => {
        setReservationTypeFilter(v);
        v === "all" ? dataSource.removeFilter("type") : dataSource.filter("type", FilterOperator.EQUALS, v);
    };

    const onSearch = (value:string) => {
        if (!value) {
            dataSource.removeFilter("licensePlateNumber");
        } else {
            dataSource.filter("licensePlateNumber", FilterOperator.EQUALS, value);
        }
    };

    const onLevelChanged = (l: string) => {
        setLevel(l);
        if (!l || l === "all") {
            dataSource.removeFilter("level");
        } else {
            dataSource.filter("level", FilterOperator.EQUALS, l);
        }
    };

    const onSectorChanged = (s: string) => {
        setSector(s);
        if (!s || s === "all") {
            dataSource.removeFilter("sector");
        } else {
            dataSource.filter("sector", FilterOperator.EQUALS, s);
        }
    };

    function handleStartDateChange(date: Date | null) {
        if (!date) {
            setStartDateControl(date);
            dataSource.removeFilter("start");
        } else {
            const startDateDateStart = setTimeToDayStart(date);
            setStartDateControl(startDateDateStart);
            dataSource.filter("start", FilterOperator.GREATER_THAN, startDateDateStart.toISOString());
        }
    }

    function handleEndDateChange(date: Date | null) {
        if (!date) {
            setEndDateControl(date);
            dataSource.removeFilter("end");
        } else {
            const endDateDateEnd = setTimeToDayEnd(date);
            setEndDateControl(endDateDateEnd);
            dataSource.filter("end", FilterOperator.LESS_THAN, endDateDateEnd.toISOString());
        }
    }

    return (<Body title={Resources.Parkowanie}>
            <div
                style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: "center",
                    justifyContent: isMobile ? "flex-start" : "flex-end",
                }}
            >
                {<>
                    <div style={{width: isMobile ? "100%" : 250}} >
                        <CustomizedDatePicker value={startDateControl} onChange={handleStartDateChange}
                                              label={Resources.Od}
                                              fullWidth
                        />
                    </div>


                    <BaseSpaceSeparator size={20}/>
                    <div style={{width: isMobile ? "100%" : 250}}>
                        <CustomizedDatePicker value={endDateControl} onChange={handleEndDateChange}
                                              label={Resources.Do}
                                              fullWidth
                        />
                    </div>
                    <BaseSpaceSeparator size={20}/>

                </>}
                
                {!!levels?.length && (<>
                        <div style={{width: isMobile ? "100%" : 250}}>
                            <CustomizedSelect label={Resources.Poziom} options={[
                                {
                                    value: "all",
                                    label: Resources.Wszystkie
                                },
                                ...levels.map((l) => ({
                                    value: l,
                                    label: l
                                }))
                            ]} onChangeCustom={onLevelChanged} value={level}/>

                        </div>
                        <BaseSpaceSeparator size={20}/>
                    </>)}
                {!!sectors?.length && (<>
                        <div style={{width: isMobile ? "100%" : 250}}>
                            <CustomizedSelect label={Resources.Sektor} options={[
                                {
                                    value: "all",
                                    label: Resources.Wszystkie
                                },
                                ...sectors.map((s) => ({
                                    value: s,
                                    label: s
                                }))
                            ]} onChangeCustom={onSectorChanged} value={sector}/>
                        </div>
                        <BaseSpaceSeparator size={20}/>
                    </>)}

                <div style={{width: isMobile ? "100%" : 250}}>
                    <CustomizedSelect label={Resources.Status} options={[
                        {
                            value: "all",
                            label: Resources.Wszystkie
                        },
                        {
                            value: ClientReservationStatus.Pending.toString(),
                            label: Resources.Aktywne
                        },
                        {
                            value: ClientReservationStatus.Completed.toString(),
                            label: Resources.Zakonczone
                        },
                        {
                            value: ClientReservationStatus.Canceled.toString(),
                            label: Resources.Anulowane
                        },
                        {
                            value: ClientReservationStatus.Confirmed.toString(),
                            label: Resources.Zaplanowane
                        }
                    ]}
                                      onChangeCustom={onReservationStatusFilterChanged}
                                      value={reservationStatusFilter}/>
                </div>
                <BaseSpaceSeparator size={20}/>

                <div style={{width: isMobile ? "100%" : 250}}>

                    <CustomizedSelect label={Resources.Typ} options={[
                        {
                            value: "all",
                            label: Resources.Wszystkie
                        },
                        {
                            value: GetParkingProcessesViewProcessType.ExternalReservation.toString(),
                            label: Resources.rez_zewn
                        },
                        {
                            value: GetParkingProcessesViewProcessType.ReservationProcess.toString(),
                            label: Resources.rezerwacja
                        },
                        {
                            value: GetParkingProcessesViewProcessType.StartStopParkingProcess.toString(),
                            label: Resources.start_stop
                        },
                        {
                            value: GetParkingProcessesViewProcessType.Subscription.toString(),
                            label: Resources.abonament
                        }
                    ]}
                                      onChangeCustom={onReservationTypeFilterChanged}
                                      value={reservationTypeFilter}/>
                </div>
            </div>
            <BaseSpaceSeparator size={20}/>
            {/*<div
                style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        flex: isMobile ? undefined : 1,
                        width: isMobile ? "100%" : undefined,
                    }}
                >

                    <CustomizedTextfield value={search} onChange={(e) => setSearch(e.target.value)}
                                         label={Resources.Wyszukaj_zakup_po_numerze_rejestracyjnym}
                                         fullWidth
                                         onKeyDown={(e) => {
                                             if (e.key === "Enter") {
                                                 onSearch();
                                             }
                                         }}
                    />
                </div>
                <BaseSpaceSeparator size={10}/>
                <div style={{width: isMobile ? "100%" : 200}}>
                    <StandardButton variant="big" onClick={onSearch}>
                        {Resources.Wyszukaj}
                    </StandardButton>
                </div>
            </div>*/}
         <SearchInputWithButton value={search} setValue={setSearch} onSearchSubmitted={value=>onSearch(value)} label={Resources.Wyszukaj_zakup_po_numerze_rejestracyjnym}/>
            <BaseSpaceSeparator size={20}/>
            <PCDataGrid<GetStaffParkingProcessesViewResultEntryDto>
                dataSource={dataSource}
                columns={[
                    {
                        width: 280,
                        label: Resources.Parkujacy,
                        dataField: "licensePlateNumber",
                    },
                    {
                        width: 165,
                        label: Resources.Poczatek,
                        dataField: "start",
                    },
                    {
                        width: 165,
                        label: Resources.Koniec,
                        dataField: "end",
                    },
                    {
                        width: 148,
                        label: Resources.Miejsce,
                        dataField: "spotNumber",
                        renderCell: (item) => (<ParkingSpotNumberPresenter
                                spotNumber={item.spotNumber}
                                level={item.level}
                                sector={item.sector}
                            />),
                    },
                    {
                        width: 148,
                        label: Resources.Typ,
                        dataField: "type",
                        renderCell: (item) => {
                            return (<StatusPresenter
                                    color={Colors.very_light_pink_two}
                                    textColor={Colors.light_royal_blue}
                                    text={getTypeText(item.type)}
                                />);
                        },
                    },
                    {
                        width: 148,
                        label: Resources.Status,
                        dataField: "status",
                        renderCell: (item) => {
                            return <ReservationStatusPresenter status={item.status}/>;
                        },
                    },
                ]}
            />
        </Body>);
};
