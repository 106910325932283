import { FormikHelpers } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import * as yup from "yup";
import Resources from "../../Resources";
import RegularInput from "../components/forms/RegularInput";
import ModalForm from "../components/ModalForm";
import { applicationState } from "../redux/ApplicationState";
import { EMAIL_REGEX, PHONE_MASK, PHONE_REGEX } from "../utils/Constants";
import { getErrorMessage } from "../utils/ErrorUtils";
import {
  getClientPhoneNumber,
  getServerPhoneNumber,
} from "../utils/PhoneNumberUtils";
import {
  NAME_AND_SURNAME_FORMAT,
  getNameAndSurname,
} from "../utils/UserInfoUtils";
import { setUserInfo } from "./Redux";
import { Asserts } from "yup";
import { LoginType } from "parkcash-api";
import { stat } from "fs";

const driver = require("../../assets/images/driver.svg");

const validationSchema = yup.object({
  nameAndSurname: yup.string().required(Resources.Wymagane),
  phoneNumber: yup
    .string()
    .required(Resources.Wymagane)
    .matches(PHONE_REGEX, Resources.Nieprawidlowy_format),
  email: yup
    .string()
    .required(Resources.Wymagane)
    .matches(EMAIL_REGEX, Resources.Nieprawidlowy_format),
});

interface FormState extends Asserts<typeof validationSchema> {}

interface OwnProps {
  visible: boolean;
  onClose: () => void;
}

interface Props extends OwnProps {
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
  type: LoginType;
  onSubmit: (
    name: string,
    surname: string,
    email: string,
    phoneNumber: string,
    after: (result: boolean | string) => void
  ) => void;
}

const Container = (props: Props) => {
  const {
    email,
    name,
    onSubmit,
    phoneNumber,
    surname,
    onClose,
    visible,
    type,
  } = props;
  const initialValues: FormState = {
    email,
    nameAndSurname: name && surname ? `${name} ${surname}` : "",
    phoneNumber: getClientPhoneNumber(phoneNumber),
  };

  const onSubmitted = async (
    state: FormState,
    helpers: FormikHelpers<FormState>
  ) => {
    const { setStatus, setSubmitting } = helpers;
    const { phoneNumber, email, nameAndSurname } = state;
    const { surname, name } = getNameAndSurname(nameAndSurname);
    const result = await new Promise<boolean | string>((resolve) => {
      onSubmit(
        name,
        surname,
        email,
        getServerPhoneNumber(phoneNumber),
        (r) => (resolve(r), setSubmitting(false))
      );
    });
    if (typeof result !== "string") {
      onClose();
    } else {
      setStatus(result);
    }
  };

  return (
    <ModalForm
      initialValues={initialValues}
      onSubmit={onSubmitted}
      validationSchema={validationSchema}
      visible={visible}
      onClose={onClose}
      showCancelButton
      icon={driver}
      iconWidth={110}
      iconHeight={110}
      title={Resources.Dane_profilowe}
    >
      {(args) => {
        const {
          values,
          errors,
          touched,
          setFieldTouched,
          setFieldValue,
          handleSubmit,
        } = args;
        return (
          <>
            <RegularInput
              label={Resources.Imie_i_nazwisko}
              value={values.nameAndSurname}
              error={errors.nameAndSurname}
              touched={touched.nameAndSurname}
              name="nameAndSurname"
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              showClearButton
              showValidatedButton
            />
            <RegularInput
              label={Resources.Nr_telefonu}
              value={values.phoneNumber}
              error={errors.phoneNumber}
              touched={touched.phoneNumber}
              name="phoneNumber"
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              showClearButton
              showValidatedButton
              mask={PHONE_MASK}
            />
            <RegularInput
              label={Resources.Email}
              value={values.email}
              error={errors.email}
              touched={touched.email}
              name="email"
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              showClearButton
              showValidatedButton
              disabled={type === LoginType.Native}
            />
          </>
        );
      }}
    </ModalForm>
  );
};

const mapStateToProps = (
  state: applicationState,
  ownProps: OwnProps
): Partial<Props> => ({
  email: state.user.email,
  name: state.user.firstName,
  phoneNumber: state.user.phoneNumber,
  surname: state.user.lastName,
  type: state.user.loginType,
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
  onSubmit: (name, surname, email, phone, after) =>
    dispatch(setUserInfo(name, surname, email, phone, after)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
