import { ClientReservationStatus } from "parkcash-api";
import * as React from "react";
import Resources from "../../Resources";
import Colors from "../styles/Colors";
import StatusPresenter from "../components/StatusPresenter";

const getMainColor = (status: ClientReservationStatus) => {
  switch (status) {
    case ClientReservationStatus.Confirmed:
      return Colors.light_royal_blue;
    case ClientReservationStatus.WaitingForConfirmation:
      return Colors.brownish_grey;
    case ClientReservationStatus.Pending:
      return Colors.light_royal_blue;
    case ClientReservationStatus.Canceled:
      return Colors.heliotrope;
    case ClientReservationStatus.Completed:
      return Colors.brownish_grey;
    default:
      return Colors.brownish_grey;
  }
};

const getText = (status: ClientReservationStatus) => {
  switch (status) {
    case ClientReservationStatus.Confirmed:
      return Resources.potwierdzona;
    case ClientReservationStatus.WaitingForConfirmation:
      return Resources.zapytanie;
    case ClientReservationStatus.Pending:
      return Resources.trwajaca;
    case ClientReservationStatus.Canceled:
      return Resources.anulowana;
    case ClientReservationStatus.Completed:
      return Resources.zakonczona;
    case ClientReservationStatus.AwaitingPayment:
      return Resources.oczekuje_na_płatnosc;
    case ClientReservationStatus.PaymentRejected:
      return Resources.platnosc_odrzucona;
    case ClientReservationStatus.Outdated:
      return Resources.Odrzucone;
    case ClientReservationStatus.Temporary:
      return Resources.tymczasowa;
  }
};

export const getReservationStatusText = getText;

const getBackground = (status: ClientReservationStatus) => {
  const main = getMainColor(status);
  switch (status) {
    case ClientReservationStatus.WaitingForConfirmation:
      return Colors.white;
    case ClientReservationStatus.Confirmed:
      return Colors.very_light_pink_two;
    default:
      return main;
  }
};
const getTextColor = (status: ClientReservationStatus) => {
  switch (status) {
    case ClientReservationStatus.WaitingForConfirmation:
      return Colors.brownish_grey;
    case ClientReservationStatus.Confirmed:
      return Colors.light_royal_blue;
    default:
      return Colors.white;
  }
};

const getBorder = (status: ClientReservationStatus) => {
  switch (status) {
    case ClientReservationStatus.WaitingForConfirmation:
      return `1px dashed ${Colors.brownish_grey}`;
    default:
      return undefined;
  }
};

export default (props: { status: ClientReservationStatus }) => {
  return (
    <StatusPresenter
      text={getText(props.status)}
      color={getBackground(props.status)}
      border={getBorder(props.status)}
      textColor={getTextColor(props.status)}
    />
  );
};
