import React, {useCallback, useRef} from 'react';
import Box, {BoxProps} from '@mui/material/Box';
import ReportItem from '../../../../../MUI/sections/file-manager/file-manager-file-item';
import Grid from "@mui/material/Unstable_Grid2";
import {GetReportsContextParkingsResultEntry, ParkingReportEntry} from "parkcash-api";
import GeneratedReportsForm from "../GenerateReportFormSection/GenerateReportForm";

// ----------------------------------------------------------------------

interface Props extends BoxProps {
    reports: ParkingReportEntry[];
    parkingsContext: GetReportsContextParkingsResultEntry[];
}
interface FormPropsReportRelated{
    reportId: string;
    reportTitle: string;
}

export default function GenertedReportsGridViewSection({
                                                           reports,
                                                           parkingsContext,
                                                           sx,
                                                       }: Props) {
   
    const containerRef = useRef(null);
    const [selectedReportItemState, setSelectedReportItemState]= React.useState<FormPropsReportRelated | null>(null);
    const [showForm, setShowForm] = React.useState(false);
    
    const onGenerateReport = useCallback((reportId: string, reportTitle: string) => {
        setSelectedReportItemState({reportId, reportTitle});
        setShowForm(true);
        }, 
        []);
    return (<Box
        sx={{...sx}}
    >
        <Box ref={containerRef}
        >
            <GeneratedReportsForm visible={showForm} onClose={() => setShowForm(false)} parkingsContext={parkingsContext} reportId={selectedReportItemState?.reportId} reportTitle={selectedReportItemState?.reportTitle}/>
                <Grid container disableEqualOverflow  columnGap={2}  margin={0} width={"100%"} >
                    {reports
                        .map((report, index) => (
                            <Grid xs={12} sm={6} md={3} lg={2} key={`${report.id ?? index}_container`} 
                            sx={{p:0}}
                            >
                                <ReportItem
                                    key={`${report.id ?? index}_item`}
                                    parkingsContext={parkingsContext}
                                    onGenerateReport={onGenerateReport}
                                    item={{
                                        ...report,
                                    }}
                                />
                            </Grid>))}
                </Grid>
        </Box>
    </Box>);
}
