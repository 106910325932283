import * as React from "react";
import Resources from "../../Resources";
import SearchAddressInput, { searchAddressItem } from "../components/forms/SearchAddressInput";
import PCDateTimePicker from "../components/PCDatetimePicker";
import { StandardButton } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { useWindowSize } from "../utils/WindowSizeHook";

const THRESHHOLD = 641;

export default (props: {
    searchProgress: boolean,
    predefinedSearchItems: searchAddressItem[],
    searchItem: searchAddressItem
    arriving: Date,
    onArrivingChanged: (d: Date) => void,
    leaving: Date,
    onLeavingChanged: (d: Date) => void,
    onSearch: () => void,
    onSearhItemChanged: (s: searchAddressItem) => void,
    extraWidth: number
}) => {
    const {arriving,leaving,onArrivingChanged,onLeavingChanged,onSearch,onSearhItemChanged,predefinedSearchItems,searchItem,searchProgress,extraWidth} = props;
    const {windowWidth} = useWindowSize();

    const trueWindowWidth = windowWidth - extraWidth;

    const isMobile = trueWindowWidth <= THRESHHOLD;

    return (
        <div style={{display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row'}}>
            <div 
                style={{
                    flex: isMobile ? undefined : 1,
                    width: isMobile ? '100%' : undefined
                }}
            >
                <SearchAddressInput 
                    placeholder={Resources.Podaj_adres_parkowania}
                    borderColor={Colors.very_light_pink}
                    current={searchItem}
                    onSelected={onSearhItemChanged}
                    predefinedItems={predefinedSearchItems}
                    zIndex={1001}
                />
            </div>
            <BaseSpaceSeparator size={20} />
            <div style={{width: isMobile ? '100%' : 250}}>
                <PCDateTimePicker 
                    value={arriving}
                    onChange={onArrivingChanged}
                    placeholder={Resources.Poczatek}
                    borderColor={Colors.very_light_pink}
                />
            </div>
            <BaseSpaceSeparator size={20} />
            <div style={{width: isMobile ? '100%' : 250}}>
                <PCDateTimePicker 
                    value={leaving}
                    onChange={onLeavingChanged}
                    placeholder={Resources.Koniec}
                    borderColor={Colors.very_light_pink}
                />
            </div>
            <BaseSpaceSeparator size={20} />
            <div style={{width: isMobile ? '100%' : 155}}>
                <StandardButton variant="big" progress={searchProgress} onClick={onSearch}>{Resources.Wyszukaj}</StandardButton>
            </div>
        </div>
    );
}