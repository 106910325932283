import { ParkingSpotInfo } from "./SearchResult";
import moment from "moment";

export default class ReservationParameters {
    
    private readonly arriving: Date;
    private readonly leaving: Date;
    private readonly parkingSpot: ParkingSpotInfo;

    get Arriving() { return this.arriving }
    get Leaving() { return this.leaving }
    get ParkingSpot() { return this.parkingSpot } 

    get TargetPrice(){ 
        const {pricePerHour} = this.ParkingSpot;
        return Math.round(100*pricePerHour * moment.duration(moment(this.Leaving).diff(moment(this.Arriving))).asHours())/100;
    }

    get ShouldPay(){
        return !!this.TargetPrice;
    } 

    constructor(args: {
        arriving: Date;
        leaving: Date;
        parkingSpot: ParkingSpotInfo;
    }) {
        this.arriving = args.arriving;
        this.leaving = args.leaving;
        this.parkingSpot = args.parkingSpot;
    }

    

    clone(){
        return new ReservationParameters({arriving: this.Arriving, leaving: this.Leaving, parkingSpot: this.ParkingSpot});
    }
}