import React from "react";
import Colors from "../../styles/Colors";
import { PCText } from "../../styles/Texts";
import {
  formatDate,
  formatDateTime,
  formatTime,
} from "../../utils/DateTimeUtils";
import { Column } from "./Column";

export function renderCell<T>(
  item: T,
  hasAdditionalRow: boolean,
  rowIndex: number,
  column: Column<T>
): React.ReactNode {
  if (column.renderCell) {
    return column.renderCell(item, hasAdditionalRow, rowIndex);
  } else if (column.dataField) {
    const data = item[column.dataField];
    return renderRawCell(data, column);
  } else {
    throw new Error(
      "Cannot render cell. You must specify dataField or renderCell callback on column"
    );
  }
}

function renderRawCell<T>(data: any, column: Column<T>) {
  let text: string = "";
  if (typeof data === "boolean") {
    return (
      <i
        className={data ? "la la-check" : "la la-times"}
        style={{ color: data ? "green" : "red" }}
      />
    );
  } else if (data instanceof Date) {
    return (
      <PCText
        fontSize={16}
        lineHeight={20 / 16}
        semibold
        color={Colors.brownish_grey}
        letterSpacing={0}
      >
        {formatTime(data)}
        <br />
        <PCText
          inline
          fontSize={16}
          semibold
          lineHeight={20 / 16}
          color={Colors.black}
          letterSpacing={0}
        >
          {formatDate(data)}
        </PCText>
      </PCText>
    );
  } else if (data === null || data === undefined) {
    text = column.placeholder || "";
  } else {
    text = data.toString();
  }

  return (
    <PCText fontSize={16} color={Colors.black}>
      {text}
    </PCText>
  );
}
