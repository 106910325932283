import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Resources from "../../../../../../Resources";
import TopUsers, {ItemProps} from "../../../../../MUI/components/AddedOrModyfiedMuiComponents/StyledLists/StyledTopItemList";
import {_mock} from "../../../../../MUI/_mock";
import {GetUsersDashboardDataResultUserInfo} from "parkcash-api";

interface TopUsersListSectionProps {
    top10ActiveUsers?: GetUsersDashboardDataResultUserInfo[];
    top10CancelUsers?: GetUsersDashboardDataResultUserInfo[];
    top10EmptyUsers?: GetUsersDashboardDataResultUserInfo[];
}

export default function TopUsersListSection({
                                                top10ActiveUsers,
                                                top10EmptyUsers,
                                                top10CancelUsers
                                            }: TopUsersListSectionProps) {

    const top10ActiveUsersList = top10ActiveUsers?.map((user, index): ItemProps => ({
        name: user.email,
        id: String(index),
        totalFavorites: user.value,
        avatarUrl: _mock.image.avatar(index)
    }));
    const top10EmptyUsersList = top10EmptyUsers?.map((user, index): ItemProps => ({
        name: user.email,
        id: String(index),
        totalFavorites: user.value,
        avatarUrl: _mock.image.avatar(index)
    }));
    const top10CancelUsersList = top10CancelUsers?.map((user, index): ItemProps => ({
        name: user.email,
        id: String(index),
        totalFavorites: user.value,
        avatarUrl: _mock.image.avatar(index)
    }));

    return (<Grid container spacing={3} xs={12} disableEqualOverflow>
        <Grid xs={12} md={4}>
            <TopUsers title={Resources.Top_10_najczesciej_rezerwujacych} list={top10ActiveUsersList}/>
        </Grid>
        <Grid xs={12} md={4}>
            <TopUsers title={Resources.Top_10_niewykorzystanych_rezerwacji} list={top10EmptyUsersList}/>
        </Grid>
        <Grid xs={12} md={4}>
            <TopUsers title={Resources.Top_10_anulujacych_rezerwacje} list={top10CancelUsersList}/>
        </Grid>
    </Grid>)
}