import * as React from "react";
import {applicationState} from "../../redux/ApplicationState";
import {PCClasses} from "../../utils/CSSUtils";
import {connect} from "react-redux";
import {PCText} from "../../styles/Texts";
import {BaseSpaceSeparator} from "../../styles/Separators";
import Colors from "../../styles/Colors";
import Resources from "../../../Resources";
import ListContainer from "./ListContainer";
import {SmallListAddButton, SmallListItem, SmallListSeparator} from "../../components/Lists";
import {ErrorCode, IVehicleExDto} from "parkcash-api";
import {getVehicles} from "../../vehicles/Redux";
import Spinner from "../../components/Spinner";
import PCScrollbars from "../../components/PCScrollbars";
import VehicleForm from "../../vehicles/VehicleForm";
import {getErrorMessage} from "../../utils/ErrorUtils";

interface Props {
    progress: boolean,
    error: ErrorCode,
    items: IVehicleExDto[],
    onInit: (refresh: boolean) => void
}

const Container = (props: Props) => {
    const {error, progress, items, onInit} = props;
    const [vehicleFormVisible, setVehicleFormVisible] = React.useState(false);
    const [currentVehicle, setCurrentVehicle] = React.useState<IVehicleExDto>(null);

    const onAdd = () => {
        setCurrentVehicle(null);
        setVehicleFormVisible(true);
    }

    const onChosen = (v: IVehicleExDto) => {
        setCurrentVehicle(v);
        setVehicleFormVisible(true);
    }

    React.useEffect(() => {
        onInit(false);
    }, []);

    return (
        <div 
            className={PCClasses("pc-profilevehicles-container")}
            style={{display: 'flex', flexDirection: 'column'}}
        >
            <VehicleForm 
                visible={vehicleFormVisible}
                onClose={() => setVehicleFormVisible(false)}
                initialVehicle={currentVehicle}
                onVehicleRemoved={() => {
                    setVehicleFormVisible(false);
                    onInit(true);
                }}
                onSaved={() => {
                    setVehicleFormVisible(false);
                    onInit(true);
                }}
            />
            <ListContainer title={Resources.Pojazdy}>
                <SmallListAddButton title={Resources.Dodaj_nowy_pojazd} onClick={onAdd}/>
                <BaseSpaceSeparator size={10}/>
                {progress && (
                    <div style={{flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <Spinner size="extra-small" />
                    </div>
                )}
                {!progress && typeof error === "number" && (
                    <div style={{flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <PCText fontSize={14} textAlign="center" color={Colors.red} semibold>{getErrorMessage(error)}</PCText>
                    </div>
                )}
                {!progress && !error && !items.length &&  (
                    <div style={{flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <PCText fontSize={14} textAlign="center" color={Colors.black} semibold>{Resources.Brak_danych}</PCText>
                    </div>
                )} 
                {!progress && !error && !!items.length && (
                        <PCScrollbars height={520} contentContainerStyle={{paddingLeft: 20, paddingRight: 40}}>
                            {items.map((vehicle, index, arr) => {
                                return (
                                    <React.Fragment key={vehicle.id}>
                                        <SmallListItem 
                                            onClick={() => onChosen(vehicle)}
                                            upperText={vehicle.licensePlateNumber}
                                            bottomText={`${vehicle.make} ${vehicle.model}`}
                                        />
                                        {arr.length > 1 && index < arr.length - 1 && <SmallListSeparator />}
                                    </React.Fragment>
                                ) 
                            })}
                        </PCScrollbars>
                )}
            </ListContainer>
        </div>
    );
}

const mapStateToProps = (state: applicationState): Partial<Props> => ({
    error: state.vehicles.gettingVehclesError,
    items: state.vehicles.vehicles || [],
    progress: state.vehicles.gettingVehiclesProgress
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
    onInit: refresh => dispatch(getVehicles(refresh))
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);