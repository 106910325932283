import {faArrowAltCircleRight, faEdit, faEllipsisH, faTrashAlt,} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    AvailibilityStatus,
    ErrorCode,
    GetParkingProcessesViewProcessType,
    GetParkingProcessesViewResultEntryDto,
    ParkingManagerService,
    ServiceConfig,
    SortOrder,
} from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import ReservationStatusPresenter from "../../bookings/ReservationStatusPresenter";
import Dropdown from "../../components/Dropdown";
import ModalForm from "../../components/ModalForm";
import {getSpotInfo} from "../../components/ParkingMap/Data";
import {formatSpot} from "../../components/ParkingSpotNumberPresenter";
import PCDataGrid from "../../components/PCDataGrid";
import ActionColumn, {ActionIcon,} from "../../components/PCDataGrid/ActionColumn";
import StatusPresenter from "../../components/StatusPresenter";
import Colors from "../../styles/Colors";
import {PCClasses} from "../../utils/CSSUtils";
import {CustomDataSource, FilterOperator} from "../../utils/DataSource";
import {useJWT} from "../../utils/JWTUtils";
import {getFilters, getSorts} from "../../utils/SieveUtils";
import {getTypeText} from "../ParkingProcesses/Utils";
import * as config from "../../../app.json";

interface Props {
    onClose: () => void;
    spotId: string;
    parkingId: string;
    status: AvailibilityStatus;
    hasExternalReservations: boolean;

    onFreePlace: () => void;
    onProlongAbonament: (pId: string) => void;
    onCancelAbonament: (pId: string) => void;
    onBuyAbonament: () => void;
    onEditAbonament: (pId: string) => void;

    onExternalReservation: () => void;
}

export default ({
                    onClose,
                    spotId,
                    status,
                    hasExternalReservations,
                    onBuyAbonament,
                    onFreePlace,
                    onExternalReservation,
                    onProlongAbonament,
                    onCancelAbonament,
                    onEditAbonament,
                    parkingId,
                }: Props) => {
    const [spotNumber, setSpotNumber] = React.useState<string>(null);
    const [spotSector, setSpotSector] = React.useState<string>(null);
    const [spotLevel, setSpotLevel] = React.useState<string>(null);

    const dataSource = React.useMemo(
        () => {
            if (!spotId || !parkingId) {
                return null;
            }

            return new CustomDataSource<GetParkingProcessesViewResultEntryDto>({
                load: async ({
                                 page,
                                 pageSize
                             }) => {
                    try {
                        const jwt = useJWT();
                        const filter: Array<{
                            key: keyof GetParkingProcessesViewResultEntryDto; operator: FilterOperator; value: any;
                        }> = [
                            {
                                key: "parkingSpotId",
                                operator: FilterOperator.EQUALS,
                                value: spotId,
                            },
                        ];
                        const sort: Array<{
                            key: keyof GetParkingProcessesViewResultEntryDto; order: SortOrder;
                        }> = [
                            {
                                key: "start",
                                order: SortOrder.Descending
                            }
                        ];
                        const {
                            isSuccess,
                            result,
                            error
                        } = await new ParkingManagerService(new ServiceConfig({jwt})).getParkingProcessesView(parkingId,
                            getFilters(filter),
                            getSorts(sort),
                            page,
                            pageSize
                        );
                        if (isSuccess) {
                            return {
                                items: result.entries,
                                totalItems: result.paginationDto.totalElementsCount,
                            };
                        } else {
                            return error.code;
                        }
                    } catch {
                        return ErrorCode.NetworkError;
                    }
                },
            });
        },
        [
            spotId,
            parkingId
        ]
    );

    React.useEffect(() => {
        const setNull = () => (setSpotNumber(null), setSpotSector(null), setSpotLevel(null));

        const getSpot = async (sId: string) => {
            if (!sId) {
                setNull();
                return;
            }

            const {
                error,
                spot
            } = await getSpotInfo(sId);
            if (error) {
                setNull();
            } else {
                setSpotLevel(spot.level);
                setSpotNumber(spot.number);
                setSpotSector(spot.sector);
            }
        };

        getSpot(spotId);
    }, [spotId]);

    return (<ModalForm
            initialValues={{}}
            onSubmit={null}
            noForm
            onClose={onClose}
            visible={!!spotId}
            paddingBottom={10}
            paddingLeft={10}
            paddingRight={10}
            paddingTop={10}
            width="90%"
            headerTitle={formatSpot({
                level: spotLevel,
                sector: spotSector,
                spotNumber: spotNumber,
            })}
            top={() => (<div style={{}}>
                    <Dropdown
                        contentWidth={200}
                        contentTranslateX={-100}
                        actions={[
                            {
                                text: Resources.Stworz_abonament,
                                onClick: onBuyAbonament,
                            },

                            {
                                text: Resources.Zrob_rezerwacje,
                                onClick: onExternalReservation,
                                visible: hasExternalReservations,
                            },
                            {
                                text: Resources.Zwolnij_miejsce,
                                onClick: onFreePlace,
                            },
                            {
                                text: Resources.Drukuj_historie,
                                target: "_blank",
                                href: `${window.location.origin}${config.routePrefix}/op/spothistory/${spotId}`,
                            },
                        ]}
                    >
                        <div className={PCClasses("pc-button")}>
                            <FontAwesomeIcon
                                icon={faEllipsisH}
                                style={{
                                    color: Colors.brownish_grey,
                                    fontSize: 24
                                }}
                            />
                        </div>
                    </Dropdown>
                </div>)}
        >
            {() => (<>
                    <PCDataGrid<GetParkingProcessesViewResultEntryDto>
                        dataSource={dataSource}
                        columns={[
                            {
                                label: Resources.Typ,
                                dataField: "type",
                                renderCell: (item) => {
                                    return (<StatusPresenter
                                            color={Colors.very_light_pink_two}
                                            textColor={Colors.light_royal_blue}
                                            text={getTypeText(item.type)}
                                        />);
                                },
                                width: 1,
                            },
                            {
                                label: Resources.Nazwa,
                                dataField: "targetName",
                                width: 1
                            },
                            {
                                label: Resources.Nr_dokumentu,
                                dataField: "documentNumber",
                                width: 1,
                            },
                            {
                                label: Resources.Kwota,
                                dataField: "effectiveTotalPrice",
                                width: 1,
                                renderCell: ({effectiveTotalPrice}) => effectiveTotalPrice ? `${effectiveTotalPrice.toFixed(
                                    2)} ${Resources.zl}` : "-",
                            },
                            {
                                label: Resources.Data_wplaty,
                                dataField: "purchaseDate",
                                width: 1,
                            },
                            {
                                label: Resources.Poczatek,
                                dataField: "start",
                                width: 1,
                            },
                            {
                                label: Resources.Koniec,
                                dataField: "end",
                                width: 1,
                            },
                            {
                                label: Resources.Status,
                                dataField: "status",
                                renderCell: (item) => {
                                    return <ReservationStatusPresenter status={item.status}/>;
                                },
                            },
                            {
                                label: Resources.Akcje,
                                width: "200",
                                renderCell: (item, _) => {
                                    return (<ActionColumn>
                                            <ActionIcon
                                                icon={faArrowAltCircleRight}
                                                title={Resources.Przedluz}
                                                onClick={() => onProlongAbonament(item.id)}
                                                visible={item.type === GetParkingProcessesViewProcessType.Subscription}
                                            />
                                            <ActionIcon
                                                icon={faEdit}
                                                title={Resources.Edytuj}
                                                onClick={() => {
                                                    onEditAbonament(item.id);
                                                }}
                                                visible={item.type === GetParkingProcessesViewProcessType.Subscription}
                                            />
                                            <ActionIcon
                                                icon={faTrashAlt}
                                                title={Resources.Usun}
                                                onClick={() => {
                                                    onCancelAbonament(item.id);
                                                }}
                                                visible={item.type === GetParkingProcessesViewProcessType.Subscription}
                                            />
                                        </ActionColumn>);
                                },
                            },
                        ]}
                        disableSorting
                    />
                </>)}
        </ModalForm>);
};
