import { FormikHelpers } from "formik";
import { CreateSubscriptionDiscountCodeDto, GetSubscriptionsDiscountCodesViewResultEntryDto, ModifySubscriptionDiscountCodeDto, ServiceConfig, SubscriptionsService } from "parkcash-api";
import * as React from "react";
import { addMonth } from "../../utils/DateTimeUtils";
import * as yup from "yup";
import Resources from "../../../Resources";
import ModalForm from "../../components/ModalForm";
import RegularInput from "../../components/forms/RegularInput";
import RegularDateTimePicker from "../../components/forms/RegularDateTimePicker";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import Colors from "../../styles/Colors";
import { parseFloatString } from "../../utils/NumberUtils";
import { CURRENCY_REGEX } from "../../utils/Constants";
import { getErrorMessage } from "../../utils/ErrorUtils";
import {SchemaOf} from "yup";

const procent_circle = require("../../../assets/images/procent_circle.svg");

interface FormState {
    code: string,
    validTo: Date,
    discountValue: string,
    type: "onetime" | "manytimes",
    subscriptionId: string    
}

const getFormState = (item: GetSubscriptionsDiscountCodesViewResultEntryDto): FormState => {
    if(!item){
        return {
            code: "",
            validTo: addMonth(new Date(), 1),
            type: "onetime",
            discountValue: "",
            subscriptionId: null
        }
    }

    const {code, validTo, isOneTime, discountValue, subscriptionId} = item;
    return {
        code,
        validTo,
        type: isOneTime ? "onetime" : "manytimes",
        discountValue: discountValue.toString(),
        subscriptionId
    }
}

export default (props: {
    visible: boolean,
    item: GetSubscriptionsDiscountCodesViewResultEntryDto,
    onSubmitted: () => void,
    jwt: string,
    onClose: () => void,
    parkingId: string
}) => {
    const {item, onSubmitted, visible, jwt, onClose, parkingId} = props;
    const [initialValues, setInitialValues] = React.useState<FormState>(getFormState(item));
    const [subscriptions, setSubscriptions] = React.useState<{text: string, id: string}[]>([]);

    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {
        try{
            helpers.setSubmitting(true);
            const {isSuccess, error} = !!item ? (await new SubscriptionsService(new ServiceConfig({jwt})).modifySubscriptionDiscountCode(new ModifySubscriptionDiscountCodeDto({
                codeId: item.id,
                code: state.code,
                discountValue: parseFloatString(state.discountValue),
                isOneTime: state.type === "onetime",
                validTo: state.validTo,
                description: item.description
            })) ) : ((await new SubscriptionsService(new ServiceConfig({jwt})).createSubscriptionDiscountCode(new CreateSubscriptionDiscountCodeDto({
                code: state.code,
                discountValue: parseFloatString(state.discountValue),
                isOneTime: state.type === "onetime",
                validTo: state.validTo,
                description: "",
                subscriptionId: state.subscriptionId
            }))))

            if(isSuccess){
                onSubmitted();
            }
            else{
                helpers.setStatus(getErrorMessage(error.code));
            }
        }
        catch(e){
            helpers.setStatus(getErrorMessage());
        }
        finally{
            helpers.setSubmitting(false);
        }
    }

    const validationSchema= yup.object({
        code: yup.string().required(Resources.Wymagane),
        subscriptionId: yup.string().nullable().required(Resources.Wymagane),
        discountValue: yup.string().required(Resources.Wymagane).matches(CURRENCY_REGEX, Resources.Niepoprawny_format),
        type:yup.mixed().nullable().notRequired(),
        validTo:yup.string().nullable().notRequired()
    });

    const getSubscriptions = async (pId: string) => {
        try{
            const {isSuccess, result} = await new SubscriptionsService(new ServiceConfig({jwt})).getParkingSubscriptionsView
            (
                pId,
                "",
                "",
                1,
                10000
            );
            if(isSuccess){
                setSubscriptions(result.entries.map(e => ({
                    id: e.id,
                    text: e.name
                })));
            }
            else{
                setSubscriptions([]);
            }
        }
        catch{
            setSubscriptions([]);
        }
        
    }

    React.useEffect(() => {
        getSubscriptions(parkingId)
    }, [parkingId]);

    React.useEffect(() => {
        setInitialValues(getFormState(item));
    }, [item, visible]);

    return (
        <ModalForm
            icon={procent_circle}
            iconHeight={118}
            iconWidth={121}
            visible={visible}
            onClose={onClose}
            initialValues={initialValues}
            onSubmit={onSubmit}
            title={!!item ? Resources.Kod_rabatowy : Resources.Nowy_kod_rabatowy}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {args => {
                const {values, touched, errors, setFieldTouched, setFieldValue} = args;

                return (
                    <>
                        <RegularInput 
                            value={values.code}
                            error={errors.code}
                            touched={touched.code}
                            name="code"
                            label={Resources.Kod}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            showClearButton
                            showValidatedButton
                        />
                        <RegularDateTimePicker 
                            value={values.validTo}
                            error={errors.validTo}
                            touched={touched.validTo}
                            name="validTo"
                            label={Resources.Data_waznosci}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            mode="date"
                        />
                        <RegularInput 
                            value={values.discountValue}
                            error={errors.discountValue}
                            touched={touched.discountValue}
                            name="discountValue"
                            label={Resources.Kwota}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            showClearButton
                            showValidatedButton
                        />
                        <RegularDropdownList
                            value={values.type}
                            error={errors.type}
                            touched={touched.type}
                            name="type"
                            label={Resources.Typ}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            actions={[
                                {
                                    text: Resources.Jednorazowy,
                                    id: "onetime"
                                },
                                {
                                    text: Resources.Bez_limitu,
                                    id: "manytimes"
                                }
                            ]}
                            borderColor={Colors.brownish_grey}
                            zIndex={20}
                        />
                        {!item && (
                            <RegularDropdownList 
                                value={values.subscriptionId}
                                error={errors.subscriptionId}
                                touched={touched.subscriptionId}
                                name="subscriptionId"
                                label={Resources.Abonament}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                actions={subscriptions}
                                borderColor={Colors.brownish_grey}
                                zIndex={10}
                            />
                        )}                       
                    </>
                )
            }}
        </ModalForm>
    );
}