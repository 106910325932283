import React from 'react';
import Container, {ContainerProps} from "@mui/material/Container";
import {useSettingsContext} from "../../../../MUI/components/settings";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {MOBILE_WIDTH_THRESHOLD} from "../../../../utils/Constants";
import {useWindowSize} from "../../../../utils/WindowSizeHook";

export interface PageWrapperProps extends ContainerProps{
    title?: string;
    titleLeftPadding?: number;
    children: React.ReactNode;
    rigth?: React.ReactNode;
    
  
}

export const MinimalPageWrapper = ({
                                       title,
                                       children,
                                       titleLeftPadding,
                                         rigth,     
                                        sx
                                    
                                   }: PageWrapperProps) => {
    const settings = useSettingsContext();
    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    return (<Container
        maxWidth={settings.themeStretch ? false : 'xl'}
        sx={{
            padding: {
                xs: 1,
                sm: 1,
                md: 4
            },
            py:{
                xs: 1,
                sm: 1,
                md: 2
            },
            
            ...sx
        }
            
    }
    >
        <Stack direction={ !isMobile?'row':"column"} justifyContent={!isMobile?'space-between':"start"}  width={"100%"}
        sx={{py:2}}
        >
        <Typography
            width={'100%'}
            paddingLeft={{
                xs: 0,
                sm: titleLeftPadding ? titleLeftPadding : 0
            }}
            pb={2}
            variant="h4"
            textAlign={{
                xs: 'center',
                sm: 'left'
            }}
        >
            {title}
        </Typography>
            {rigth?rigth:null}
            </Stack>
        {children}
    </Container>);
}