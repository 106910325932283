import {ReservationPolicyPredefinedRangePeriodType} from "parkcash-api";
import Resources from "../../../../Resources";

export const mapReservationPolicyPredefinedRangePeriodTypeTodisplayText = (value: ReservationPolicyPredefinedRangePeriodType): string => {
  switch (value) {
      case ReservationPolicyPredefinedRangePeriodType.Minute:
      return Resources.Minuty;
    case ReservationPolicyPredefinedRangePeriodType.Hour:
        return Resources.Godziny;
    case ReservationPolicyPredefinedRangePeriodType.Day:
        return Resources.Dni;
    default:
        return Resources.Nieznany
  }
}