import * as React from "react";
import { PCClasses } from "../../utils/CSSUtils";
import { getScrollbarWidth } from "../../utils/DOMUtils";

export const DESKTOP_HEADER_DEFAULT_HEIGHT = 100;

export default (props: {
    children: React.ReactNode,
    height?: number,
    adjustHeaderToScroll?: boolean,
}) => {
    const {children, height = DESKTOP_HEADER_DEFAULT_HEIGHT, adjustHeaderToScroll} = props;

    return (
        <div
            className={PCClasses("pc-desktopheader-container")}
            style={{
                height,
                right: adjustHeaderToScroll ? getScrollbarWidth() : undefined
            }}
        >
            {children}
        </div>
    )
}