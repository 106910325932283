import { SortOrder } from "parkcash-api";
import { FilterOperator } from "./DataSource";

export function getSorts<T>(sort: Array<{key: keyof T, order: SortOrder}>): string {
    if(!sort || !sort.length){
        return ""
    }

    const result = sort.map(s => {
        return `${s.order === SortOrder.Ascending ? "" : "-"}${s.key as string}`;
    })
    .join(",");
    return result;  
}

export function getFilters<T>(filter: Array<{key: keyof T, operator: FilterOperator, value: any}>): string {
    if(!filter || !filter.length){
        return ""
    }

    return filter.map(f => {
        if(Array.isArray(f.value)){
            return `${f.key.toString()}${f.operator}${f.value.map(v => encodeValue(v)).join("|")}`;
        }
        else{
            return `${f.key.toString()}${f.operator}${encodeValue(f.value)}`;
        }
        
    })
    .join(", ");
}

const encodeValue = (v: any) => {
    const stringValue = `${v}`;
    return stringValue.replace(/,/g, "\\,");
}
export function getMultiKeysValue<T> (multipleKeys: Array<keyof T>) {
    var result = "";
    if (!multipleKeys || !multipleKeys.length) {
        return ""
    }
    multipleKeys.forEach((key, index) => {
        if (index !== multipleKeys.length - 1) {
            result = result + `${String(key)}|`;
        } else {
            result = result + `${String(key)}`;
        }

    });
    return result;
}
