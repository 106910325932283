import {endOfDay, format, formatDistanceToNow, getTime, startOfDay} from 'date-fns';
import getDay from "date-fns/getDay";

// ----------------------------------------------------------------------

export enum DayOfWeek {
    MONDAY = "Monday",
    TUESDAY = "Tuesday",
    WEDNESDAY = "Wednesday",
    THURSDAY = "Thursday",
    FRIDAY = "Friday",
    SATURDAY = "Saturday",
    SUNDAY = "Sunday"
}

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
    const fm = newFormat || 'dd MMM yyyy';

    return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
    const fm = newFormat || 'dd MMM yyyy p';

    return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
    return date ? getTime(new Date(date)) : '';
}

export function getDayOfWeek(date: Date): DayOfWeek {
    const dayNumber = getDay(date);
    switch (dayNumber) {
        case 0:
            return DayOfWeek.SUNDAY;
        case 1:
            return DayOfWeek.MONDAY;
        case 2:
            return DayOfWeek.TUESDAY;
        case 3:
            return DayOfWeek.WEDNESDAY;
        case 4:
            return DayOfWeek.THURSDAY;
        case 5:
            return DayOfWeek.FRIDAY;
        case 6:
            return DayOfWeek.SATURDAY;
        default:
            throw new Error('Invalid day number.');
    }
}

export function fToNow(date: InputValue) {
    return date ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
    }) : '';
}

export function sortObjectsByDateAscending<T extends { timestamp?: Date }>(objects: T[]) {
    return objects.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
}

export function sortObjectsByDateDescending<T extends { timestamp: Date }>(objects: T[]) {
    return objects.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
}

export function sortDatesAscending(dates: Date[]) {
    return dates.sort((a, b) => a.getTime() - b.getTime());
}
export function setTimeToDayEnd(date: Date) {
    // use date-fns
    return endOfDay(date);
}

export function setTimeToDayStart(date: Date) {
    // use date-fns\
    return startOfDay(date);
}
export function addDaysToDate(date: Date, days: number) {
    // use date-fns
    return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
}
export function isValidDate(date:any) {
    return date instanceof Date && !isNaN(Number(date));
}


export function durationIsBiggerThanAllowedDays(start: Date, end: Date, allowedDays: number) {
    const days = (end.getTime() - start.getTime()) / (1000 * 3600 * 24);
    return days > allowedDays;
}
    

