import {styled} from "@mui/material/styles";
import TextField from "@mui/material/TextField";

export const CustomTextField = styled(TextField)(({ theme }) => ({
   /* '& .MuiOutlinedInput-root': {
        // Target the root: Apply focus styles to the notched outline
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.text.primary, // Optional: change border color on hover
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main, // Change border color on focus
        },
        // Correct targeting for the label within a focused TextField
        '&.Mui-focused + .MuiFormControl-root .MuiInputLabel-outlined': {
            color: 'blue', // Change label color to maintain visibility
            zIndex: 1, // Ensure label is above the input field
            backgroundColor: 'white', // Background to mimic the cut-out effect
            padding: '0 5px', // Add padding to the background for a cleaner look
            marginLeft: '-5px', // Adjust based on padding to align correctly
            // Ensure the label moves correctly
            transform: 'translate(14px, -6px) scale(0.75) !important',
        }
    },*/
    '& .MuiOutlinedInput-root': {
               
               '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                   // Remove border or make adjustments when focused
                  /* outline: 'none',*/
                   borderColor: 'transparent', //
                   // You might want to adjust the border or outline specifically here
               },
             
           },
    // Additional custom styles for the TextField can go here
}));

