import React, {useCallback, useEffect} from "react";
import ModalForm from "../../../../../components/ModalForm";
import * as Yup from 'yup';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Resources from "../../../../../../Resources";
import Grid from "@mui/material/Unstable_Grid2";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import FormProvider, {RHFMultiSelect} from "../../../../../MUI/components/hook-form";
import {StandardButton} from "../../../../../styles/Buttons";
import {
    ErrorWrapperOfObject,
    GenerateReportDto,
    GetReportsContextParkingsResultEntry,
    IGenerateReportDto,
    ReportsService,
    ServiceConfig
} from "parkcash-api";
import {useUser} from "../../../../../user/UseUserHook";
import ErrorView from "../../../../../components/ErrorView";
import {getErrorMessageNew} from "../../../../../utils/ErrorUtils";
import {useSnackbar} from '../../../../../MUI/components/snackbar';
import {setTimeToDayEnd} from "../../../../../MUI/utils/format-time";
import {DateRangeSettersButtons} from "./DateRangeSettersButtons";
import {
    ChangeValue
} from "../../../../../components/Common/DateUtilsComponent/quickSetDateRangeValueRHFHelperComponent";
import {getFirstDateOfCurentMonth} from "../../../../../utils/DateTimeUtils";
import {Block} from "../../../../../MUI/components/hook-form/formBlock";

const subscription_type = require("../../../../../../assets/images/reports.svg");

export interface GenerateReportFormProps {
    parkingsContext: GetReportsContextParkingsResultEntry[],
    reportTitle: string,
    reportId: string,
    visible: boolean;
    onClose: () => void;
}

interface FormValues {
    start: Date;
    end: Date;
    parkingIds: string[];
}

export default function GeneratedReportsForm({
                                                 visible,
                                                 onClose,
                                                 parkingsContext,
                                                 reportId,
                                                 reportTitle
                                             }: GenerateReportFormProps) {
    const {
        token: jwt,
        applicationMode
    } = useUser();
    const [
        error, setError
    ] = React.useState<ErrorWrapperOfObject>(null);
    const {enqueueSnackbar} = useSnackbar();
    const parkingOptions = parkingsContext?.map((parking) => ({
        value: parking.id,
        label: parking.name
    })).sort((a, b) => a.label.localeCompare(b.label));
    const intiallySelectedParking = applicationMode.getCurrentParking().parkingId
    const validationSchema = Yup.object().shape({
        start: Yup.mixed<any>().nullable().required(Resources.To_pole_jest_wymagane),
        end: Yup.mixed<any>().nullable().required(Resources.To_pole_jest_wymagane),
        parkingIds: Yup.array().min(1, Resources.Wybierz_przynajmniej_jedna_opcje),

    });
    const [quickDateSettersSelectedSetToOwnConfig, setQuickDateSettersSelectedSetToOwnConfig] = React.useState<boolean>(
        false);

    const onQuickDateSettersValueCHange = useCallback((selected: ChangeValue | null) => {
        if (selected === "OwnConfig") {
            setQuickDateSettersSelectedSetToOwnConfig(true)
        } else {
            setQuickDateSettersSelectedSetToOwnConfig(false)
        }
    }, []);

    const defaultValues = {
        start: getFirstDateOfCurentMonth(),
        end: new Date(),
        parkingIds: [intiallySelectedParking],

    };

    const methods = useForm({
        /* updating yup version to  "yup": "^1.2.0" resolves this problem, but cause many problems in other form files, cause breaking changes are introduced*/
        /* @ts-ignore */
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: {
            isSubmitting,
            isLoading
        },
        control,
        reset,
        getValues

    } = methods;
    const initialValues: FormValues = {
        start: null,
        end: null,
        parkingIds: []
    }
    console.log('start', getValues('start'))
    console.log('end', getValues('end'))

    const onsubmit = handleSubmit(async (values: FormValues) => {

        const dtoData: IGenerateReportDto = {
            ...values,
            end: setTimeToDayEnd(values.end),
            reportId
        };
        console.log(dtoData)
        const generateReportDto = new GenerateReportDto(dtoData);
        try {
            const generateResult = await new ReportsService(new ServiceConfig({jwt})).generateReport(generateReportDto);
            if (generateResult.isSuccess) {
                setTimeout(() => {
                    enqueueSnackbar(Resources.Raport_pomyslnie_wygenerowany_i_wyslany_na_adres_email, {
                        variant: 'success',
                        autoHideDuration: 10 * 1000,
                        anchorOrigin: {
                            horizontal: "right",
                            vertical: "bottom"
                        }
                    });
                }, 1500);
                onClose();
            }
        } catch (catchedError) {
            let errorMessage = getErrorMessageNew(catchedError);
            setError(catchedError);
        }
    })
    useEffect(() => {
        const resetFormAndStates = () => {
            setError(null);
            reset(defaultValues);
            setQuickDateSettersSelectedSetToOwnConfig(false);
        }
        if (visible) {
            resetFormAndStates();
        }
    }, [visible]);

    return (<ModalForm
        title={Resources.Generuj_raport}
        headerTitle={reportTitle}
        onSubmit={() => {
        }}
        visible={visible}
        onClose={onClose}
        noForm={true}
        initialValues={initialValues}
        zIndex={1200}
        width={'600px'}
        icon={subscription_type}
        iconHeight={105}
        iconWidth={120}
    >
        {(args) => {

            if (error) {

                return <ErrorView title={getErrorMessageNew(error)}/>;
            }
            return (<FormProvider methods={methods} onSubmit={onsubmit}>
                <Grid container xs={12} justifyContent={'center'} margin={0} spacing={1}
                >
                    <Grid xs={12}>
                        <DateRangeSettersButtons useFormMethods={methods}
                                                 onSelectedChange={onQuickDateSettersValueCHange}
                                                 useOwnConfig={quickDateSettersSelectedSetToOwnConfig}
                        />
                    </Grid>
                    <Block label={Resources.Wlasny_zakres_dat}>

                        <Grid xs={12}>
                            <Controller
                                name="start"
                                control={control}
                                render={({
                                             field: {
                                                 onChange,
                                                 ...fieldProps
                                             },
                                             fieldState: {error}
                                         }) => {
                                    const handleDateChange = (dateValue) => {
                                        onChange(dateValue);
                                        setQuickDateSettersSelectedSetToOwnConfig(true)
                                    };

                                    return (<DatePicker
                                        {...fieldProps}
                                        onChange={handleDateChange}
                                        label={Resources.Poczatek}
                                        format="dd/MM/yyyy"
                                        sx={{'width': '100%'}}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: !!error,
                                                helperText: error?.message || " "
                                            },
                                        }}
                                    />)
                                }}
                            />

                        </Grid>
                        <Grid xs={12}>
                            <Controller
                                name="end"
                                control={control}
                                render={({
                                             field: {
                                                 onChange,
                                                 ...fieldProps
                                             },
                                             fieldState: {error}
                                         }) => {
                                    const handleDateChange = (dateValue) => {
                                        onChange(dateValue);
                                        setQuickDateSettersSelectedSetToOwnConfig(true)

                                    };

                                    return (<DatePicker
                                        {...fieldProps}
                                        onChange={handleDateChange}
                                        label={Resources.Koniec}
                                        format="dd/MM/yyyy"
                                        sx={{'width': '100%'}}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: !!error,
                                                helperText: error?.message || " "
                                            },
                                        }}
                                    />)
                                }}
                            />
                        </Grid>

                    </Block>

                    <Grid xs={12}>
                        <RHFMultiSelect
                            checkbox
                            chip
                            fullWidth
                            name="parkingIds"
                            label={Resources.Wybierz_parkingi}
                            options={parkingOptions}

                        />
                    </Grid>
                    <Grid xs={12}
                          paddingTop={{
                              xs: 1,
                              md: 3
                          }}
                    >
                        <StandardButton
                            type="submit"
                            progress={isSubmitting}
                        >
                            {Resources.Wygeneruj_i_wyslij}
                        </StandardButton>
                    </Grid>
                </Grid>
            </FormProvider>)
        }}
    </ModalForm>)
}