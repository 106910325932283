import * as React from "react";
import Colors from "../../styles/Colors";
import { PCText, SOURCESANSPRO_REGULAR } from "../../styles/Texts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes, faEye } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import MaskedInput from "./MaskedInput";

type NativeInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export interface InputProps extends NativeInputProps {
    isInvalid?: boolean;
    label?: string;
    showClearButton?: boolean;
    password?: boolean;
    showValidatedButton?: boolean;
    mask?: string;
    onChangeText?: (value: string) => void;
    variant?: "standard" | "big";
    borderColor?: string;
    backgroundColor?: string,
    color?: string,
    icon?: IconProp;
} 

interface State{
    focused: boolean;
    type: "text" | "password";
}

const InputIcon = (props: {onClick?: () => void, icon: IconProp, color: string, variant: "standard" | "big"}) => {

    return (
        <div 
            style={{
                paddingLeft: 10,
                paddingRight: 15,
                backgroundColor: "transparent",
                cursor: props.onClick ? "pointer" : "inherit",
            }}
            onMouseDown={e => {
                props.onClick && props.onClick();
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <FontAwesomeIcon color={props.color} fontSize={props.variant === "big" ? 16 : 14} icon={props.icon} />
        </div>
    );
}

export default class Input extends React.Component<InputProps, State> {

    private input: HTMLInputElement;

    constructor(props: InputProps){
        super(props);
        this.state = {
            focused: false,
            type: props.password ? "password" : "text"
        };
    }

    focus = () => {
        this.input?.focus();
    }

    blur = () => {
        this.input?.blur();
    }

    render(){
        const {
            onChangeText, 
            isInvalid, 
            onBlur, 
            onFocus, 
            label, 
            password, 
            showClearButton, 
            showValidatedButton, 
            onChange, 
            mask, 
            style,
            value,
            disabled,
            icon,
            variant = "standard",
            borderColor,
            backgroundColor,
            color,
            ...rest
        } = this.props;
        const {focused, type} = this.state; 

        const mainColor = isInvalid ? Colors.red : focused ? Colors.heliotrope : Colors.brownish_grey;
    
        const textInputProps: NativeInputProps = {
            type,
            style: {
                height: '100%', 
                width: '100%', 
                backgroundColor: backgroundColor || 'transparent', 
                outline: "none", 
                padding: 0, 
                margin:0, 
                borderWidth: 0, 
                color: color || Colors.brownish_grey, 
                fontFamily: SOURCESANSPRO_REGULAR, 
                letterSpacing: 0, 
                lineHeight: 1.5, 
                fontSize: variant === "big" ? 19.2 : 16,
                ...style
            },
            onBlur: e => {
                onBlur && onBlur(e);
                this.setState({focused: false});
            },
            onFocus: e => {
                onFocus && onFocus(e);
                this.setState({focused: true});
            },
            value,
            onChange: e => {
                onChangeText && onChangeText(e.target.value);
            },
            disabled,
            ...rest
        }

        const hasPasswordButton = !icon && password;
        const hasClearButton = !icon && !hasPasswordButton && focused && showClearButton;
        const hasValidatedButton = !icon && !hasPasswordButton && !hasClearButton && showValidatedButton;

        const hasAnyButton = !!icon || hasPasswordButton || hasClearButton || hasValidatedButton;

    

        return (
            <div
                style={{
                    backgroundColor: "transparent", 
                    //paddingTop: !!label ? 10 : 0, 
                    position: 'relative',
                    pointerEvents: disabled ? "none" : undefined,
                    opacity: disabled ? 0.5 : 1,
                    // boxSizing: "border-box",
                    // minHeight: 58
                }}
            >


                {!!label && (
                    <div
                        style={{
                            backgroundColor: Colors.white, 
                            paddingLeft: 10, 
                            paddingRight: 10,
                            position: 'absolute',
                            top: -5,
                            left: 32,
                            display: 'inline-block'
                        }}

                    >
                        <PCText color={borderColor || mainColor} fontSize={ variant === "big" ? 14.4 : 12} lineHeight={variant === "big" ? 1.33: 1.5} letterSpacing={0}>
                            {label}
                        </PCText>
                    </div>
                )}




                <div
                    style={{
                        borderColor: borderColor || mainColor,
                        borderWidth: 1,
                        borderRadius: 33,
                        borderStyle: "solid",
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: variant === "big" ? 30 : 25,
                        paddingRight: hasAnyButton ? 10 : variant === "big" ? 30 : 25,
                        paddingTop: variant === "big" ? 18 : 16,
                        paddingBottom: variant === "big" ? 17 : 16,
                        backgroundColor: backgroundColor || Colors.white,

                        cursor: "text",
                    }}
                    onClick={this.focus}
                >
                    <div style={{backgroundColor: Colors.white, height: variant === "big" ? 29 : 24, flex: 1}}>
                        {!mask && (
                            <input
                                ref={i => {
                                    if(i){
                                        this.input = i;
                                    }
                                }}
                                {...textInputProps}
                            />
                        )}
                        {!!mask && (
                            <MaskedInput 
                                mask={mask}
                                {...textInputProps}
                            />
                        )}
                    </div>
                    {!!icon && (
                        <InputIcon 
                        icon={icon}
                        color={Colors.brownish_grey}
                        variant={variant}
                    />
                    )}
                    {hasClearButton && (
                        <InputIcon 
                            onClick={() => {
                                onChangeText && onChangeText("");
                                if(focused){
                                    this.focus();
                                }
                            }}
                            icon={faTimes}
                            color={Colors.brownish_grey}
                            variant={variant}
                        />
                    )}
                    {hasValidatedButton && (
                        <InputIcon 
                            icon={faCheckCircle}
                            color={Colors.brownish_grey}
                            variant={variant}
                        />
                    )}
                    {hasPasswordButton && (
                        <InputIcon 
                            onClick={() => this.setState({type: type === "password" ? "text" : "password"})}
                            icon={faEye}
                            color={type === "password" ? Colors.very_light_pink : Colors.brownish_grey}
                            variant={variant}
                        />
                    )} 
                </div>
            </div>
        );
    }

    
}