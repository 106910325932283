import { GetActiveSubscriptionsResultEntryDto } from "parkcash-api";
import simplePayload from "../redux/SimplePayload";

export type OtherPagesActionTypes = "SetTempActiveSubscriptionsPurchases" | "ResetTempActiveSubscriptionsPurchases";

export interface otherPagesState {
    tempActiveSubscriptionsPurchases: GetActiveSubscriptionsResultEntryDto[]
}

interface setTempActiveSubscriptionsPayload extends simplePayload {
    purchases: GetActiveSubscriptionsResultEntryDto[]
}

export const setTempActiveSubscriptionsPurchases = (purchases: GetActiveSubscriptionsResultEntryDto[]): setTempActiveSubscriptionsPayload => ({
    type: "SetTempActiveSubscriptionsPurchases",
    purchases: purchases
});

export const resetTempActiveSubscriptionsPurchases = (): simplePayload => ({
    type: "ResetTempActiveSubscriptionsPurchases"
});

const initialState: otherPagesState = {
    tempActiveSubscriptionsPurchases: []
}

export default (state: otherPagesState = initialState, action: simplePayload): otherPagesState => {
    switch(action.type){
        case "ResetTempActiveSubscriptionsPurchases":
            return {...state, tempActiveSubscriptionsPurchases: []}
        case "SetTempActiveSubscriptionsPurchases":
            return {...state, tempActiveSubscriptionsPurchases: (action as setTempActiveSubscriptionsPayload).purchases}
        case "Logout":
            return {...initialState};
        default:
            return state;
    }
}