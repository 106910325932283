import React from 'react'
import {
    IAssignAuthorizedDomainsToParkingDomeinEntry, IReservationPolicyPredefinedRangePeriodEntry,
    ReservationPolicyPredefinedRangePeriodType
} from "parkcash-api";
import {mapEnumToSelectOptions} from "../../../utils/EnumUtils/MapEnumToSelectOptions";
import {
    mapReservationPolicyPredefinedRangePeriodTypeTodisplayText
} from "../../../utils/EnumUtils/ParkingConfigRelatedEnums/ReservationPolicyPredefinedRangePeriodTypeHelpers";
import RegularInput from "../../../components/forms/RegularInput";
import Resources from "../../../../Resources";
import RegularDropdownList from "../../../components/forms/RegularDropdownList";
import Colors from "../../../styles/Colors";

import {FormikErrors, FormikTouched} from "formik";
import {useBoolean} from "../../../MUI/hooks/use-boolean";
import ButtonBase from "@mui/material/ButtonBase";
import {StandardButton} from "../../../styles/Buttons";
import {PolicyFormValues} from "../ParkingPolicyForm/policyFormTypes";
import {ListContainer, SmallListAddButton, SmallListItem} from "../../../components/Lists";
import {PCText} from "../../../styles/Texts";
import Stack from "@mui/material/Stack";
import {faPlusCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BaseSpaceSeparator} from "../../../styles/Separators";
import {ParkingDomainFormValues} from "./ParkingDomenFormTypes";

interface Props {
    values: ParkingDomainFormValues
    errors: FormikErrors<ParkingDomainFormValues>
    touched: FormikTouched<ParkingDomainFormValues>
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;

}

export const ParkingDomainListFormSection = ({
                                                              values,
                                                              setFieldValue,
                                                              setFieldTouched,
                                                              errors,
                                                              touched,

                                                          }: Props) => {
    const periodsSelectOptions = mapEnumToSelectOptions(ReservationPolicyPredefinedRangePeriodType,
        mapReservationPolicyPredefinedRangePeriodTypeTodisplayText,
        false
    );
    const showForm = useBoolean(false);
    const domains: IAssignAuthorizedDomainsToParkingDomeinEntry[] = values.domains;


    return (<div>

        <Stack direction={"column"}  flexWrap={"wrap"}>
            <Stack direction={"row"}  width={"100%"} justifyContent={"space-between"}>
                <PCText color={Colors.black} fontSize={18} lineHeight={24/16}>{Resources.Autoryzowane_domeny}</PCText>
                <FontAwesomeIcon icon={faPlusCircle} color={Colors.light_royal_blue} style={{ verticalAlign:'top',textAlign: 'center', fontSize: 20, letterSpacing: 0}} onClick={showForm.onToggle} title={Resources.Dodaj} />
            </Stack>
            <BaseSpaceSeparator size={20}/>
            {showForm.value ? (<>
                <RegularInput
                    variant={"standard"}
                    label={Resources.Nazwa_domeny}
                    value={values.newDomain}
                    error={errors.newDomain}
                    touched={touched.newDomain}
                    name={"newDomain"}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    showClearButton
                    showValidatedButton
                />

               
                <StandardButton type={"button"} variant={"tiny"} onClick={() => {
                    setFieldValue("domains", [
                        ...values.domains,
                        {
                            domain: values.newDomain
                            
                        }
                    ]);
                    showForm.onFalse();
                }
                }>
                    {Resources.Dodaj}
                </StandardButton>
                <BaseSpaceSeparator size={20}/>
            </>) : null}
            {domains.map((domain, index) => {
                return (
                    <SmallListItem
                        key={index}
                        upperText={domain.domain}
                        bottomText={""}
                        onIconClick={() => {
                            domains.splice(index, 1);
                            setFieldValue("domains", domains);
                        }}
                        iconTitle={Resources.Usun}
                        icon={faTrash}
                    />
                )
            })}
        </Stack>

    </div>)
}
    
    

    
    