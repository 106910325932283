import {PolicyFormValues} from "./policyFormTypes";
import {
    IGetParkingReservationPolicyResult,
    IModifyReservationPolicyDto,
    IReservationPolicyPredefinedRangePeriodEntry,
    ReservationPolicyPredefinedPeriodInfo,
    ReservationPolicyPredefinedRangePeriodEntry,
    ReservationPolicyPredefinedRangePeriodType,
    ReservationPolicyType,
    SpotSelectionType
} from "parkcash-api";
import {TimeSpan} from "../../../utils/TimeSpanUtils";

export const getPolicyFormInitialValues = (policy?: IGetParkingReservationPolicyResult): PolicyFormValues => {

    if (!policy) {
        //create new policy mode
        return {
            policyType: ReservationPolicyType.WholeDay,
            minDuration:"",
            maxDuration: "",
            maxDurationFromNow: "",
            maxReservationAdvance: "",
            latestCancellationTime: "",
            phoneNumberConfirmationRequired: false,
            emailConfirmationRequired: false,
            externalProfileIdRequired: false,
            allowConcurrentReservations: false,
            applyForManagers: false,
            spotSelectionType: SpotSelectionType.Manual,
            allowElectricVehiclesInUndergroundParkings: false,
            allowLpgVehiclesInUndergroundParkings: false,
            reservationPolicyPredefinedRangePeriodEntries: [],
            activeReservationsLimit: "",
            // below controls which dont have direct mapping to Dto
            reservationPolicyPredefinedRangePeriodType: ReservationPolicyPredefinedRangePeriodType.Day,
            reservationPolicyPredefinedRangePeriodValue: "0",
        }
    }
    return {
        //update policy mode
        policyType: policy.policyType,
        minDuration: policy?.minDuration,
        maxDuration: policy?.maxDuration,
        maxDurationFromNow: policy?.maxDurationFromNow,
        maxReservationAdvance:  policy?.maxReservationAdvance,
        latestCancellationTime: policy?.latestCancellationTime,
        phoneNumberConfirmationRequired: policy?.phoneNumberConfirmationRequired,
        emailConfirmationRequired: policy?.emailConfirmationRequired,
        externalProfileIdRequired: policy?.externalProfileIdRequired,
        allowConcurrentReservations: policy?.allowConcurrentReservations,
        applyForManagers: policy?.applyForManagers,
        spotSelectionType: policy?.spotSelectionType,
        activeReservationsLimit: policy?.activeReservationLimit ?? "",
        allowElectricVehiclesInUndergroundParkings: policy?.allowElectricVehiclesInUndergroundParkings,
        allowLpgVehiclesInUndergroundParkings: policy?.allowLpgVehiclesInUndergroundParkings,
        reservationPolicyPredefinedRangePeriodEntries: getPredefinedRangeFromPolicyInfo(policy?.reservationPolicyPredefinedPeriodInfo!),
        // below to controls which dont have direct mapping from api
        reservationPolicyPredefinedRangePeriodType: ReservationPolicyPredefinedRangePeriodType.Day,
        reservationPolicyPredefinedRangePeriodValue: "0",
    };
}

export const mamFormValuesToDtoValues = (policyFormValues: PolicyFormValues): IModifyReservationPolicyDto => {
    return {
        policyType: policyFormValues?.policyType,
        minDuration:policyFormValues.minDuration || undefined,
        maxDuration: policyFormValues.maxDuration || undefined,
        maxDurationFromNow: policyFormValues.maxDurationFromNow || undefined,
        maxReservationAdvance: policyFormValues.maxReservationAdvance || undefined,
        latestCancellationTime: policyFormValues.latestCancellationTime || undefined,
        activeReservationsLimit: policyFormValues?.activeReservationsLimit ? Number(policyFormValues.activeReservationsLimit) : undefined,
        phoneNumberConfirmationRequired: policyFormValues?.phoneNumberConfirmationRequired,
        emailConfirmationRequired: policyFormValues?.emailConfirmationRequired,
        externalProfileIdRequired: policyFormValues?.externalProfileIdRequired,
        allowConcurrentReservations: policyFormValues?.allowConcurrentReservations,
        applyForManagers: policyFormValues?.applyForManagers,
        spotSelectionType:policyFormValues?.spotSelectionType,
        allowElectricVehiclesInUndergroundParkings: policyFormValues?.allowElectricVehiclesInUndergroundParkings,
        allowLpgVehiclesInUndergroundParkings: policyFormValues?.allowLpgVehiclesInUndergroundParkings,
        reservationPolicyPredefinedPeriodInfo: getPredefinedRangeFromFormValues(policyFormValues.reservationPolicyPredefinedRangePeriodEntries!),
    }
}


export const getPredefinedRangeFromPolicyInfo = (info: ReservationPolicyPredefinedPeriodInfo): IReservationPolicyPredefinedRangePeriodEntry[] => {
    return info.orderedPeriods.map((p): IReservationPolicyPredefinedRangePeriodEntry => {
        return {
            ...p
        }
    });
}
export const getPredefinedRangeFromFormValues = (values: IReservationPolicyPredefinedRangePeriodEntry[]): ReservationPolicyPredefinedPeriodInfo => {
    const orderedPeriods = values.map((p): ReservationPolicyPredefinedRangePeriodEntry => (new ReservationPolicyPredefinedRangePeriodEntry(
            {
                type: p.type,
                value: p.value
            })));

    return new ReservationPolicyPredefinedPeriodInfo({
        orderedPeriods: orderedPeriods
    });
}
export const transformDurationFromApiToFitCurrentMask = (duration: string): string => {
    // mask="999.99:99:99"
    let result = "";
    if (!duration) {
        return result;
    }
    let days = duration.includes(".") ? duration?.split(".")[0] : "";
    const time = duration.includes(".") ? duration.split(".")[1] : duration ?? "";
    let hours = time?.split(":")[0] ?? "";
    let minutes = time?.split(":")[1] ?? "";
    let seconds = time?.split(":")[2] ?? "";

    if (days?.length < 3) {
        days = days.padStart(3, "0");
    }

    if (hours?.length === 1) {
        hours = hours.padStart(2, "0");
    }

    if (minutes?.length === 1) {
        minutes = minutes.padStart(2, "0");
    }

    if (seconds?.length === 1) {
        seconds = seconds.padStart(2, "0");
    }

    result = `${days}.${hours}:${minutes}:${seconds}`;
    return result;
}






