import { FormikProps } from "formik";
import * as React from "react";
import Resources from "../../../Resources";
import RegularInput from "../../components/forms/RegularInput";
import { SwitchWithLabel } from "../../components/Switch";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { NIP_MASK, POSTAL_CODE_MASK } from "../../utils/Constants";
import InvoiceFormState from "../formInterfaces/InvoiceFormState";
import InvoiceTypeChooser from "./InvoiceTypeChooser";

export default (props: {
    isMobile: boolean,
    args: FormikProps<InvoiceFormState>
}) => {
    const {args, isMobile} = props;
    const {values, errors, touched, setFieldTouched, setFieldValue} = args;

    return (
        <>
            <div style={{width: isMobile ? '100%' : 300}}>
                <SwitchWithLabel 
                    value={values.wantInvoice}
                    label={Resources.Chce_otrzymac_fakture}
                    onChange={v => setFieldValue("wantInvoice", v)}
                />
            </div>
            {values.wantInvoice && (
                <>
                    <BaseSpaceSeparator size={20} />
                    <InvoiceTypeChooser 
                        isMobile={isMobile}
                        value={values.type}
                        onChange={v => setFieldValue("type", v)}
                    />

                    <BaseSpaceSeparator size={20} />

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: isMobile ? "column" : "row"
                        }}
                    >
                        <div
                            style={{
                                width: isMobile ? "100%" : 310
                            }}
                        >
                            <RegularInput 
                                showClearButton
                                showValidatedButton
                                value={values.contrahent}
                                error={errors.contrahent}
                                touched={touched.contrahent}
                                name="contrahent"
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                label={`${Resources.Kontrahent}*`}
                            />
                            <PCText color={Colors.brownish_grey} fontSize={14}>
                                * {Resources.Dla_firmy}: <PCText inline color={Colors.brownish_grey} fontSize={14} semibold>{Resources.nazwa_firmy}.</PCText>
                            </PCText>
                            <PCText color={Colors.brownish_grey} fontSize={14}>
                                {Resources.Dla_osoby_fizycznej}: <PCText inline color={Colors.brownish_grey} fontSize={14} semibold>{Resources.imie_i_nazwisko}</PCText>
                            </PCText>
                        </div>
                        <BaseSpaceSeparator size={15} />
                        <div
                            style={{
                                width: isMobile ? "100%" : 200
                            }}
                        >
                            <RegularInput 
                                showClearButton
                                showValidatedButton
                                value={values.nip}
                                error={errors.nip}
                                touched={touched.nip}
                                name="nip"
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                label={`${Resources.NIP}*`}
                                mask={NIP_MASK}
                            />
                            <PCText color={Colors.brownish_grey} fontSize={14}>
                                * {Resources.Obowiazkowe_tylko_dla_faktur_na_firme}
                            </PCText>
                        </div>
                    </div>

                    <BaseSpaceSeparator size={20} />

                    <div style={{display: 'flex', alignItems: isMobile ? undefined : 'center', flexDirection: isMobile ? "column" : "row"}}>
                        <div style={{width: isMobile ? '100%' : 300}}>
                            <RegularInput 
                                value={values.streetName}
                                error={errors.streetName}
                                touched={touched.streetName}
                                name="streetName"
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                showClearButton
                                showValidatedButton
                                label={Resources.Ulica}
                            />
                        </div>
                        {!isMobile && <BaseSpaceSeparator size={15} />}
                        <div style={{width: isMobile ? '100%' : 150}}>
                            <RegularInput 
                                value={values.streetNumber}
                                error={errors.streetNumber}
                                touched={touched.streetNumber}
                                name="streetNumber"
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                showClearButton
                                showValidatedButton
                                label={Resources.Nr_domu}
                            />
                        </div >
                        {!isMobile && <BaseSpaceSeparator size={15} />}
                        <div style={{width: isMobile ? '100%' : 150}}>
                            <RegularInput 
                                value={values.flatNumber}
                                error={errors.flatNumber}
                                touched={touched.flatNumber}
                                name="flatNumber"
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                showClearButton
                                showValidatedButton
                                label={Resources.Nr_lokalu}
                            />
                        </div>
                    </div>

                    <BaseSpaceSeparator size={20} />

                    <div style={{display: 'flex', alignItems: isMobile ? undefined : 'center', flexDirection: isMobile ? "column" : "row"}}>
                        <div style={{width: isMobile ? '100%' : 300}}>
                            <RegularInput 
                                value={values.city}
                                error={errors.city}
                                touched={touched.city}
                                name="city"
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                showClearButton
                                showValidatedButton
                                label={Resources.Miejscowosc}
                            />
                        </div>
                        {!isMobile && <BaseSpaceSeparator size={15} />}
                        <div style={{width: isMobile ? '100%' : 150}}>
                            <RegularInput 
                                value={values.postalCode}
                                error={errors.postalCode}
                                touched={touched.postalCode}
                                name="postalCode"
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                showClearButton
                                showValidatedButton
                                label={Resources.Kod_pocztowy}
                                mask={POSTAL_CODE_MASK}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
}