import { SubscriptionPeriodType } from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import Input from "../../components/forms/Input";
import Spinner from "../../components/Spinner";
import { StandardButton, StandardButton2 } from "../../styles/Buttons";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import Notify from "../../utils/Notify";
import { SubscriptionTimeRangeInfo } from "./PrologongSubscriptionSpecyficPart";
import {ISelectedPaymentInfo, PaymentMethod, PaymentMethodsTypes} from "./PaymentMethod";

export const PAYMENT_PART_HEADER_HEIGHT = 40;

const payment = require("../../../assets/images/payment.svg");

export default (props: {
    isMobile: boolean,
    onPay: (selectedPaymentMethodInfo:ISelectedPaymentInfo) => void,
    onBlikErrorClear?: () => void
    isValid: boolean,
    maxHeight?: number,
    noPaymentText?: string,
    price?: number,
    lowerPrice?: number,
    showPromotionCode?: boolean,
    onPromotionCode?: (code: string) => Promise<string>,
    submitProgress: boolean,
    priceProgress?: boolean
    status?: string,
    periodType?: SubscriptionPeriodType,
    validFrom?: Date,
    validTo?: Date,
    showTimeRange?: boolean,
    submitText?: string,
    title?: string,
    hideFreePart?: boolean
    allowedPaymentMethods: PaymentMethodsTypes[]
    blikError?:string
}) => {
    const {
        isValid,
        onPay,
        onPromotionCode,
        showPromotionCode = true,
        maxHeight,
        submitProgress,
        lowerPrice,
        price,
        priceProgress,
        isMobile,
        status,
        periodType, 
        validFrom,
        validTo,
        showTimeRange = false,
        noPaymentText = "-",
        submitText = Resources.Potwierdz_platnosc,
        title = Resources.Platnosc,
        hideFreePart,
        allowedPaymentMethods,
        blikError,
        onBlikErrorClear
    } = props;
    const [code, setCode] = React.useState("");
    const [selectedPaymentMethodInfo, setSelectedPaymentMethodInfo] = React.useState<ISelectedPaymentInfo>();
   

    const checkPromotionCode = async () => {
        if(!code){
            Notify.Error(Resources.Wpisz_kod_znizkowy);
            return;
        }

        const error = await onPromotionCode(code);
        if(error){
            Notify.Error(error);
        }
        else{
            setCode("");
        }
        
    }

    const content = (
        <>
            {showTimeRange && (
                <>
                    <SubscriptionTimeRangeInfo periodType={periodType} validFrom={validFrom} validTo={validTo}/>
                    <BaseSpaceSeparator size={30} />
                </>
            )}
            
            <img src={payment} width={isMobile ? 103 : 60} height={isMobile ? 65 : 110} />
            <BaseSpaceSeparator size={15} />
            {(!hideFreePart || price > 0) && (
                <PCText color={Colors.black} textAlign="center" semibold fontSize={18}>
                    {Resources.Do_zaplaty}:
                </PCText>
            )}
            {(!showPromotionCode || (showPromotionCode && typeof lowerPrice !== "number")) && !hideFreePart && (
                <>
                    <div style={{height: 54, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        {!priceProgress && (
                            <PCText color={Colors.light_royal_blue} fontSize={38} textAlign="center" semibold>
                                {!price ? noPaymentText : `${price} ${Resources.zl}`}
                            </PCText>
                        )}
                        {priceProgress && (
                            <Spinner size="extra-small" />
                        )}
                    </div>
                </>
            )}
            {showPromotionCode && typeof lowerPrice === "number" && (
                <>
                    <BaseSpaceSeparator size={15} />
                    <div style={{height: 32, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <PCText color={Colors.brownish_grey} textDecoration="line-through" fontSize={22} textAlign="center" semibold>
                            {price} {Resources.zl}
                        </PCText>
                    </div>
                    <div style={{height: 54, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <PCText color={Colors.light_royal_blue} fontSize={38} textAlign="center" semibold>
                            {lowerPrice} {Resources.zl}
                        </PCText>
                    </div>
                    <div 
                        style={{
                            width: 100, 
                            height: 18, 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            backgroundColor: Colors.light_royal_blue,
                            borderRadius: 9
                        }}
                    >
                        <PCText fontSize={12} color={Colors.white} semibold >
                            {Resources.Cena_po_obnizce}
                        </PCText>
                    </div>
                </>
            )}

            {showPromotionCode && typeof lowerPrice !== "number" && (
                <div style={{width: '100%', marginTop: 25}}>
                    <Input 
                        value={code}
                        onChangeText={setCode}
                        label={Resources.Kod_rabatowy}
                        placeholder={Resources.Wpisz_kod_znizkowy}
                    />
                    <BaseSpaceSeparator size={10} />
                    <StandardButton2 
                        disabled={!isValid} 
                        onClick={checkPromotionCode}
                    >
                        {Resources.Uzyj_kodu}
                    </StandardButton2>
                </div>
            )}

            {price> 0 &&<>
                <BaseSpaceSeparator size={15} />
                <PaymentMethod 
                allowedMethods={allowedPaymentMethods}
                blikError={blikError}
                onPaymentMethodChanged={info => {
                    setSelectedPaymentMethodInfo(info);
                }}
                onBlikErrorClear={() => props?.onBlikErrorClear()}
            ></PaymentMethod>
            </>
            }

                <div style={{height: 30, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <PCText textAlign="center" fontSize={12} lineHeight={1} letterSpacing={0} color={Colors.red}>{status || ""}</PCText>
                </div>
            <div style={{width: '100%'}}>
                <StandardButton 
                    disabled={!isValid} 
                    progress={submitProgress}
                    onClick={()=>onPay(selectedPaymentMethodInfo)}
                >
                    {submitText}
                </StandardButton>
            </div>
        </>
    );

    if(isMobile){
        return (
            <div 
                style={{
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center',
                    padding: "0px 60px 30px 60px"
                }}
            >
                {content}
            </div>
        );
    }
    else{
        return (
            <div
                style={{
                    width: 300,
                    backgroundColor: Colors.white,
                    borderRadius: 10,
                    boxShadow: "0 3px 26px 4px rgba(0, 0, 0, 0.08)",
                    height: "min-content"
                }}
            >
                <div
                    style={{
                        height: PAYMENT_PART_HEADER_HEIGHT, 
                        backgroundColor: Colors.light_royal_blue, 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10
                    }}
                >
                    <PCText textAlign="center" semibold fontSize={21} color={Colors.white} >
                        {title}
                    </PCText>
                </div>
                <div
                    style={{
                        maxHeight,
                        overflow: "auto",
                        boxSizing: "border-box",
                        padding: "30px 30px 25px 30px",
                        display: 'flex',
                        flexDirection: "column",
                        alignItems: 'center'
                    }}
                >
                    {content}
                </div>
            </div>
        );
    }
}