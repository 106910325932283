import React from 'react';
import {useSettingsContext} from "../../../../MUI/components/settings";
import {useTheme} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {useDateRangePicker} from "../../../../MUI/components/custom-date-range-picker";
import Grid from "@mui/material/Unstable_Grid2";
import {
    CustomDateRangePickerWithSelectButton
} from "../../../../components/CustomDateRangePickerWithButton/CustomDateRangePickerWithSelectButton";
import {CancelToken} from 'axios';
import {DashboardService} from "parkcash-api";
import {useUser} from "../../../../user/UseUserHook";
import {useDataFetcher} from "../../../../MUI/hooks/useDataFetcher";
import {LoadingErrorAndContentWrapper} from "../../../../components/Common/LoadingErrorAndContentWrapper";
import {getFirstDateOfCurentMonth} from "../../../../utils/DateTimeUtils";

interface MinimalPageWraperProps {
    useFilters?: boolean;
    children: (data: any | null) => React.ReactNode;
    dashboardService?: DashboardService;
    pageTitle?: string;
    fetchFunction?: (parkingId: string | null | undefined, start: Date | undefined, end: Date | undefined, cancelToken?: CancelToken | undefined) => Promise<any>;
}

export default function MinimalPageWraperWithDataFetcher({
                                              children,
                                              pageTitle,
                                              fetchFunction = undefined,
                                              useFilters = true
                                          }: MinimalPageWraperProps) {
    const theme = useTheme();
    const settings = useSettingsContext();
    const startDate =getFirstDateOfCurentMonth();
    const endDate = new Date();
    const dateRangePickerState = useDateRangePicker(startDate, endDate);
    const {
        token: jwt,
        applicationMode
    } = useUser();
    const parkingId = applicationMode.getCurrentParking()?.parkingId
    const {
        data,
        loading,
        error
    } = useDataFetcher(
        dateRangePickerState.startDate,
        dateRangePickerState.endDate,
        parkingId,
        dateRangePickerState.dateRangeSelected,
        dateRangePickerState.setDateRangeSelected,
        fetchFunction
    );
    return (
        <Container maxWidth={settings.themeStretch ? false : 'xl'}
                   sx={{
                       height:'100%',
                       padding: {
                           xs: 0.5,
                           sm: 1,
                           md: 2
                       }
                   }}
        >
            <LoadingErrorAndContentWrapper loading={loading} errors={[error]} data={!!data}>
                <Grid container
                    /* spacing={3}*/
                      rowGap={2}
                      direction="row"
                      justifyContent="center"
                      disableEqualOverflow
                >
                    <Grid container xs={12} justifyContent={'space-between'}>
                        <Grid xs={12} sm={4} md={4} paddingLeft={2} display={'flex'} alignItems={'center'}>
                            <Typography
                                width={'100%'}
                                variant="h4"
                                textAlign={{
                                    xs: 'center',
                                    sm: 'left'
                                }}
                            >
                                {pageTitle}
                            </Typography>
                        </Grid>
                        {useFilters && <Grid xs={12} sm={4} md={2} p={2} display={'flex'}
                                             justifyContent={'right'}
                                             alignItems={'center'}>
                            <CustomDateRangePickerWithSelectButton
                                dateRangePickerProps={{
                                    ...dateRangePickerState,
                                    variant: "calendar",
                                }}
                            />
                        </Grid>
                        }
                    </Grid>
                    { data && children(data)}
                </Grid>
            </LoadingErrorAndContentWrapper>
        </Container>

    );
}