// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/images/authentication/facebook.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pc-facebook-login{width:150px;height:58px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");border:none;cursor:pointer;outline:none}.language-selector>button{border:none}\n", "",{"version":3,"sources":["webpack://./src/authentication/Login.scss"],"names":[],"mappings":"AAAA,mBACI,WAAY,CACZ,WAAY,CACZ,kDAAkE,CAClE,WAAY,CACZ,cAAe,CACf,YAAa,CAChB,0BAGG,WAAY","sourcesContent":[".pc-facebook-login {\r\n    width: 150px;\r\n    height: 58px;\r\n    background: url(\"../../assets/images/authentication/facebook.svg\");\r\n    border: none;\r\n    cursor: pointer;\r\n    outline: none;\r\n}\r\n\r\n.language-selector > button{\r\n    border: none;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
