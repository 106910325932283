import { FormikProps } from "formik";
import React from "react";
import Resources from "../../../Resources";
import RegularInput from "../../components/forms/RegularInput";
import TargetFormState from "../../otherPages/formInterfaces/TargetFormState";
import { PHONE_MASK } from "../../utils/Constants";
import { transformLicencePlateNumber } from "../../utils/LicencePlateNumberUtils";

export default (props: { args: FormikProps<TargetFormState> }) => {
  const { args } = props;
  const { values, errors, touched, setFieldTouched, setFieldValue } = args;

  return (
    <>
      <RegularInput
        label={Resources.Imie_i_nazwisko}
        value={values.nameAndSurname}
        error={errors.nameAndSurname}
        touched={touched.nameAndSurname}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        name="nameAndSurname"
        showValidatedButton
        showClearButton
      />
      {values.licencePlateNumberRequired && (
        <RegularInput
          transformText={transformLicencePlateNumber}
          label={Resources.Nr_rejestracyjny}
          value={values.licencePlateNumber}
          error={errors.licencePlateNumber}
          touched={touched.licencePlateNumber}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          name="licencePlateNumber"
          showValidatedButton
          showClearButton
        />
      )}
      <RegularInput
        label={Resources.Nr_telefonu}
        value={values.phoneNumber}
        error={errors.phoneNumber}
        touched={touched.phoneNumber}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        name="phoneNumber"
        showValidatedButton
        showClearButton
        mask={PHONE_MASK}
      />
      <RegularInput
        label={Resources.Email}
        value={values.email}
        error={errors.email}
        touched={touched.email}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        name="email"
        showValidatedButton
        showClearButton
      />
    </>
  );
};
