import {FormikHelpers} from "formik";
import * as React from "react";
import ModalForm from "../../components/ModalForm";
import * as yup from "yup";
import {SchemaOf} from "yup";
import Resources from "../../../Resources";
import {getErrorMessage, getErrorMessageNew} from "../../utils/ErrorUtils";
import {useJWT} from "../../utils/JWTUtils";
import {ParkingManagerService, ServiceConfig} from "parkcash-api";
import {AssignParkingSpotToUserPermanentlyDto} from "parkcash-api";
import {nameof} from "../../utils/NameOf";
import RegularInput from "../../components/forms/RegularInput";
import ConfirmModal from "../../components/ConfirmModal";

const driver = require("../../../assets/images/driver.svg");

interface FormState {
    userEmailOrIdentifier: string,
}

export default (props: {
    visible: boolean, onClose: () => void; onSubmitted: () => void; parkingId: string, parkingSpotId: string;
}) => {
    const {
        onClose,
        visible,
        onSubmitted,
        parkingId,
        parkingSpotId,
    } = props;

   

    const jwt = useJWT();
    const initialValues: FormState = {
        userEmailOrIdentifier: "",
    }

    const onSubmit = async ({
                                userEmailOrIdentifier,
                            }: FormState, {
                                setStatus,
                                setSubmitting
                            }: FormikHelpers<FormState>) => {
        try {
            setSubmitting(true);

            const confirm = await ConfirmModal.show({
                text: Resources.Czy_napewno_chesz_przypisac_miejsce_na_stale,
                confirmText: Resources.Przypisz,
            });
            if (!confirm) {
                setSubmitting(false);
                return;
            }

            const {
                isSuccess,
                error
            } = await new ParkingManagerService(new ServiceConfig({jwt})).assignParkingSpotToUserPermanently(new AssignParkingSpotToUserPermanentlyDto(
                {
                    parkingId: parkingId,
                    parkingSpotId: parkingSpotId,
                    userEmailOrIdentifier: userEmailOrIdentifier,
                }));
            if (isSuccess) {
                onSubmitted();
            } else {
                setStatus(getErrorMessageNew(error));
            }
        } catch {
            setStatus(getErrorMessage());
        } finally {
            setSubmitting(false);
        }
    }

    const validationSchema: SchemaOf<FormState> = yup.object().shape({
        userEmailOrIdentifier: yup.string().required(Resources.Wymagane),

    })

    return (<ModalForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        icon={driver}
        iconHeight={110}
        iconWidth={110}
        visible={visible}
        onClose={onClose}
        title={Resources.Przypisz_miejsce}
        showCancelButton
    >
        {({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue
          }) => {
            return (<>
                <RegularInput
                    label={Resources.Email_lub_Identyfikator}
                    value={values.userEmailOrIdentifier}
                    error={errors.userEmailOrIdentifier}
                    touched={touched.userEmailOrIdentifier}
                    name={nameof<FormState>('userEmailOrIdentifier')}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                />

            </>)
        }}
    </ModalForm>)
}