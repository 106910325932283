import { faMap } from "@fortawesome/free-solid-svg-icons";
import { FormikProps } from "formik";
import Enumerable from "linq";
import {
  AddressDto,
  AvailibilityStatus,
  ErrorCode,
  GetParkingSubscriptionsResultEntryDto,
  ParkingSpotsAvailibilityEntry,
  SpotSelectorType,
  SubscriptionPeriodType,
} from "parkcash-api";
import React from "react";
import Resources from "../../../Resources";
import ChoosePlaceFromMap from "../../components/ChoosePlaceFromMap";
import ErrorView from "../../components/ErrorView";
import FormLabel from "../../components/FormLabel";
import Input from "../../components/forms/Input";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import IconButton from "../../components/IconButton";
import { formatSpot } from "../../components/ParkingSpotNumberPresenter";
import Spinner from "../../components/Spinner";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { PCClasses } from "../../utils/CSSUtils";
import { getErrorMessage } from "../../utils/ErrorUtils";
import Notify from "../../utils/Notify";
import BigForm from "../formParts/BigForm";
import { getAvailableSpotsForSubscription } from "../SubscriptionsUtils";
import CreateSubscriptionFormState from "./CreateSuscriptionFormState";

const SubscriptionTypeItem = (props: {
  id: string;
  price: number;
  name: string;
  description: string;
  discountedPrice: number;
  onSelected: (i: string) => void;
  isSelected: boolean;
  periodType: SubscriptionPeriodType;
}) => {
  const {
    id,
    isSelected,
    onSelected,
    discountedPrice,
    price,
    name,
    description,
    periodType,
  } = props;

  return (
    <div
      className={PCClasses("pc-button")}
      style={{
        boxSizing: "border-box",
        height: 150,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 10,
        backgroundColor: isSelected ? Colors.light_royal_blue : Colors.white,
        borderWidth: isSelected ? 0 : 1,
        borderStyle: "solid",
        borderColor: Colors.very_light_pink,
        borderRadius: 25,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      onClick={() => onSelected(id)}
    >
      <PCText
        fontSize={40}
        textAlign="center"
        semibold
        color={isSelected ? Colors.white : Colors.light_royal_blue}
      >
        {periodType === SubscriptionPeriodType.NumberOfDays
          ? `${discountedPrice || price}${Resources.zl}`
          : ""}
      </PCText>
      <BaseSpaceSeparator size={5} />
      <PCText
        semibold
        fontSize={14}
        textDecoration="line-through"
        textAlign="center"
        color={
          !discountedPrice
            ? "transparent"
            : isSelected
            ? Colors.white
            : Colors.brownish_grey
        }
      >
        {discountedPrice ? `${price} ${Resources.zl}` : <>&nbsp;</>}
      </PCText>

      <BaseSpaceSeparator size={10} />
      <PCText
        semibold
        fontSize={16}
        textAlign="center"
        color={isSelected ? Colors.white : Colors.black}
      >
        {name}
      </PCText>
      <BaseSpaceSeparator size={5} />
      <PCText
        fontSize={11}
        textAlign="center"
        color={isSelected ? Colors.white : Colors.brownish_grey}
      >
        {description}
      </PCText>
    </div>
  );
};

export default (props: {
  isMobile: boolean;
  initialProgress: boolean;
  initialError: ErrorCode | string;
  parkingId: string;
  args: FormikProps<CreateSubscriptionFormState>;
  availableSubscriptions: GetParkingSubscriptionsResultEntryDto[];
  parkingAddress: AddressDto;
}) => {
  const {
    initialError,
    initialProgress,
    isMobile,
    parkingId,
    args,
    availableSubscriptions,
    parkingAddress,
  } = props;
  const { values, setFieldValue, setFieldTouched, touched, errors } = args;
  const [spots, setSpots] = React.useState<ParkingSpotsAvailibilityEntry[]>([]);
  const [number, setNumber] = React.useState<string>(null);
  const [level, setLevel] = React.useState<string>(null);
  const [sector, setSector] = React.useState<string>(null);

  const subscription = Enumerable.from(availableSubscriptions).firstOrDefault(
    (s) => s.id === values.subscriptionId
  );

  React.useEffect(() => {
    if (
      subscription?.periodType === SubscriptionPeriodType.GivenFullCalendarMonth
    ) {
      const now = new Date();
      setFieldValue("from", new Date(now.getFullYear(), now.getMonth()));
    } else {
      setFieldValue("from", new Date());
    }
  }, [subscription?.periodType]);

  const onMap = async () => {
    const s = await ChoosePlaceFromMap.show({
      parkingId,
      currentSpotId: values.spotId,
      getSpotsCallback: async (level, sector) => {
        const { error, spots } = await getAvailableSpotsForSubscription(
          parkingId,
          values.subscriptionId,
          values.numberOfPeriods,
          values.from,
          level,
          sector
        );
        if (error) {
          return { error };
        } else {
          return {
            spots: spots.map((s) => ({
              status: s.isAvailable ? AvailibilityStatus.Free : null,
              id: s.id,
              number: s.number,
              level: s.level,
              sector: s.sector,
            })),
          };
        }
      },
    });
    if (s) {
      const { spotId, number, sector, level } = s;
      setFieldValue("spotId", spotId);
      setNumber(number);
      setSector(sector);
      setLevel(level);
    }
  };

  const retrieveSpots = async (
    parId: string,
    subId: string,
    customStart: Date,
    numberOfPeriods: number
  ) => {
    const { spots, error } = await getAvailableSpotsForSubscription(
      parId,
      subId,
      numberOfPeriods,
      customStart
    );
    if (error) {
      Notify.Error(getErrorMessage(error));
      setSpots([]);
    } else {
      setSpots(spots);
    }
  };

  React.useEffect(() => {
    //setFieldValue("spotId", null);
    setSpots([]);
    if (
      values.subscriptionId &&
      values.enableParticularSpotPurchase &&
      values.from &&
      values.spotSelectorType === SpotSelectorType.List
    ) {
      retrieveSpots(
        parkingId,
        values.subscriptionId,
        values.from,
        values.numberOfPeriods
      );
    }
  }, [
    values.subscriptionId,
    values.enableParticularSpotPurchase,
    values.from,
    values.spotSelectorType,
    values.numberOfPeriods,
  ]);

  return (
    <BigForm
      isMobile={isMobile}
      title={Resources.Parametry_abonamentu}
      noOverflow
    >
      {initialProgress && (
        <div
          style={{
            height: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner size="medium" />
        </div>
      )}
      {!initialProgress && initialError && (
        <ErrorView
          title={
            typeof initialError === "number"
              ? getErrorMessage(initialError)
              : initialError
          }
        />
      )}
      {!initialProgress && !initialError && (
        <>
          {isMobile && (
            <>
              <FormLabel>{Resources.Wybierz_abonament}:</FormLabel>
              <BaseSpaceSeparator size={5} />
              <RegularDropdownList
                zIndex={400}
                value={values.subscriptionId}
                touched={touched.subscriptionId}
                error={errors.subscriptionId}
                onChange={() => {
                  setFieldValue("discountCode", "");
                  setFieldValue("discountCodePrice", null);
                }}
                name="subscriptionId"
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                actions={availableSubscriptions.map((a) => ({
                  metadata: a,
                  id: a.id,
                }))}
              >
                {(action, isOption, handleClick) => {
                  const { metadata } = action;
                  const {
                    description,
                    name,
                    discountedPrice,
                    price,
                    periodType,
                  } = metadata as GetParkingSubscriptionsResultEntryDto;
                  return (
                    <div
                      onClick={isOption ? handleClick : undefined}
                      className={isOption ? PCClasses("pc-button") : undefined}
                      style={{ padding: isOption ? "10px 0px" : undefined }}
                    >
                      <PCText
                        semibold
                        fontSize={18}
                        color={Colors.light_royal_blue}
                      >
                        {name}
                        {periodType === SubscriptionPeriodType.NumberOfDays
                          ? `: ${discountedPrice || price} ${Resources.zl}`
                          : ""}
                      </PCText>
                      <BaseSpaceSeparator size={5} />
                      <PCText fontSize={14} color={Colors.brownish_grey}>
                        {description}
                      </PCText>
                    </div>
                  );
                }}
              </RegularDropdownList>
            </>
          )}
          {!isMobile && (
            <div
              style={{
                display: "grid",
                alignItems: "center",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridGap: "15px",
              }}
            >
              {!!availableSubscriptions &&
                availableSubscriptions.length > 1 &&
                availableSubscriptions.map((sub, index, arr) => {
                  const {
                    id,
                    discountedPrice,
                    price,
                    description,
                    name,
                    periodType,
                  } = sub;
                  return (
                    <SubscriptionTypeItem
                      key={id}
                      id={id}
                      isSelected={values.subscriptionId === id}
                      onSelected={() => setFieldValue("subscriptionId", id)}
                      price={price}
                      discountedPrice={discountedPrice}
                      name={name}
                      description={description}
                      periodType={periodType}
                    />
                  );
                })}
            </div>
          )}
          <BaseSpaceSeparator size={40} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <div style={{ flex: 1, width: isMobile ? "100%" : undefined }}>
              <Input
                readOnly
                value={`${parkingAddress.streetName} ${parkingAddress.streetNumber}`}
                label={Resources.Adres}
                borderColor={Colors.brownish_grey}
                backgroundColor={"#e5e5e5"}
                color={Colors.black}
              />
              <BaseSpaceSeparator size={20} />
            </div>
            <BaseSpaceSeparator size={20} />
            <div style={{ flex: 1, width: isMobile ? "100%" : undefined }}>
              {values.enableParticularSpotPurchase && (
                <>
                  {values.spotSelectorType === SpotSelectorType.List && (
                    <RegularDropdownList
                      zIndex={300}
                      borderColor={Colors.brownish_grey}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      value={values.spotId}
                      error={errors.spotId}
                      touched={touched.spotId}
                      name="spotId"
                      maxContentHeight={300}
                      actions={spots.map((spot) => ({
                        id: spot.id,
                        text: formatSpot({
                          spotNumber: spot.number,
                          level: spot.level,
                          sector: spot.sector,
                        }),
                      }))}
                      label={Resources.Miejsce}
                    />
                  )}
                  {values.spotSelectorType === SpotSelectorType.Map && (
                    <>
                      <FormLabel>{Resources.Miejsce}</FormLabel>
                      <BaseSpaceSeparator size={5} />
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <PCText
                          fontSize={12}
                          semibold
                          color={Colors.brownish_grey}
                        >
                          {formatSpot({ spotNumber: number, level, sector }) ||
                            Resources.Nie_wybrales_miejsca}
                        </PCText>
                        <BaseSpaceSeparator size={15} />
                        <IconButton
                          icon={faMap}
                          onClick={onMap}
                          style={{
                            width: 40,
                            height: 40,
                            backgroundColor: Colors.light_royal_blue,
                            borderRadius: 20,
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </BigForm>
  );
};
