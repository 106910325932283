import * as React from "react";

export default (props: {
    width: number,
    height: number,
    color: string,
    rotate?: number,
    className?: string,
    onClick?: () => void
}) => {
    const {color, height, width, rotate = 0, className, onClick} = props;

    return (
        <div className={className} onClick={onClick} style={{height, width}}>
            <div 
                style={{
                    width: 0,
                    height: 0,
                    borderLeft: `${width/2}px solid transparent`,
                    borderRight: `${width/2}px solid transparent`,
                    borderBottom: `${height}px solid ${color}`,
                    transform: rotate ? `rotate(${rotate}deg)` : undefined
                }} 
            />
        </div>
    )
}