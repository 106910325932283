import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { FormikProps } from "formik";
import { IVehicleExDto } from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import RegularInput from "../../components/forms/RegularInput";
import RegularTextArea from "../../components/forms/RegularTextArea";
import { TransactionType } from "../../payments/Enums";
import PaymentForm from "../../payments/PaymentForm";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PHONE_MASK } from "../../utils/Constants";
import { transformLicencePlateNumber } from "../../utils/LicencePlateNumberUtils";
import { ReservationConfirmationFormState } from "./FormState";

const bluemedia_logo = require("../../../assets/images/bm.svg");

export default (props: {
  args: FormikProps<ReservationConfirmationFormState>;
  payments: PaymentForm[];
  shouldPay: boolean;
}) => {
  const { args, payments, shouldPay } = props;
  const { values, touched, errors, setFieldValue, setFieldTouched } = args;

  return (
    <>
      <BaseSpaceSeparator size={30} />
      <RegularInput
        label={Resources.Imie_i_nazwisko}
        value={values.nameAndSurname}
        error={errors.nameAndSurname}
        touched={touched.nameAndSurname}
        name={"nameAndSurname"}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        showClearButton
        showValidatedButton
      />
      <RegularInput
        label={Resources.Nr_rejestracyjny}
        transformText={transformLicencePlateNumber}
        value={values.licencePlateNumber}
        error={errors.licencePlateNumber}
        touched={touched.licencePlateNumber}
        name={"licencePlateNumber"}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        showClearButton
        showValidatedButton
      />
      <RegularTextArea
        label={Resources.Wiadomosc}
        value={values.description}
        error={errors.description}
        touched={touched.description}
        name={"description"}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
      />
      <RegularInput
        label={Resources.Email}
        value={values.email}
        error={errors.email}
        touched={touched.email}
        name={"email"}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        showClearButton
        showValidatedButton
      />
      <RegularInput
        label={Resources.Nr_telefonu}
        value={values.phoneNumber}
        error={errors.phoneNumber}
        touched={touched.phoneNumber}
        mask={PHONE_MASK}
        name={"phoneNumber"}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        showClearButton
        showValidatedButton
      />
      <BaseSpaceSeparator size={30} />
      {shouldPay && !!payments && !!payments.length && (
        <RegularDropdownList
          setFieldTouched={setFieldTouched}
          error={errors.payment}
          value={values.payment}
          touched={touched.payment}
          name="payment"
          setFieldValue={setFieldValue}
          variant="standard"
          borderColor={Colors.brownish_grey}
          label={Resources.Forma_zaplaty}
          actions={payments.map((p) => ({
            id: p.getId(),
            text:
              p.Type === TransactionType.PayWithSavedCreditCard
                ? `**** **** **** ${p.CreditCardInfo.lastFourDigits}`
                : Resources.Szybki_przelew,
            icon:
              p.Type === TransactionType.PayWithSavedCreditCard
                ? faCreditCard
                : undefined,
            image:
              p.Type !== TransactionType.PayWithSavedCreditCard
                ? bluemedia_logo
                : undefined,
          }))}
        />
      )}
    </>
  );
};
