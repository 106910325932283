import * as React from "react";
import Resources from "../../../Resources";
import { Chips } from "../../components/Chips";
import FormLabel from "../../components/FormLabel";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { SwitchWithLabel } from "../../components/Switch";
import PCDateTimePicker from "../../components/PCDatetimePicker";
import DropdownList from "../../components/DropdownList";
import { FormikProps } from "formik";
import { OneTimeFormState } from "./ShareParkingSpotFormState";
import { addDays, addMinutes, getTime, nextDate } from "../../utils/DateTimeUtils";
import { DayOfWeek } from "parkcash-api";
import { TimePresenter } from "./TimePresenter";

export default (props: {
    args: FormikProps<OneTimeFormState>,
    spotDropdown: JSX.Element,
    availableUsers: {id: string, text: string}[]
}) => {
    const {args, spotDropdown, availableUsers} = props;
    const {setFieldValue, setFieldTouched, values, errors, touched} = args;

    const isTomorrowChosen = () => {
        const tomorrow = addDays(new Date(), 1);
        const tomorrowStart = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate()),
        tomorrowEnd = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate(), 23, 59);

        return values.start.getTime() === tomorrowStart.getTime() && 
        values.end.getTime() === tomorrowEnd.getTime();
    }

    const isDayAfterTommorowChosen = () => {
        const dayAfterTomorrow = addDays(new Date(), 2);
        const dayAfterTomorrowStart = new Date(dayAfterTomorrow.getFullYear(), dayAfterTomorrow.getMonth(), dayAfterTomorrow.getDate()),
        dayAfterTomorrowEnd = new Date(dayAfterTomorrow.getFullYear(), dayAfterTomorrow.getMonth(), dayAfterTomorrow.getDate(), 23, 59);

        return values.start.getTime() === dayAfterTomorrowStart.getTime() && 
        values.end.getTime() === dayAfterTomorrowEnd.getTime();
    }

    const isWeekendChosen = () => {
        const nextSaturday = nextDate(DayOfWeek.Saturday);
        const nextSunday = nextDate(DayOfWeek.Sunday, nextSaturday);
        const saturdayStart = new Date(nextSaturday.getFullYear(), nextSaturday.getMonth(), nextSaturday.getDate());
        const saturdayEnd = new Date(nextSunday.getFullYear(), nextSunday.getMonth(), nextSunday.getDate(), 23, 59);

        return values.start.getTime() === saturdayStart.getTime() && 
        values.end.getTime() === saturdayEnd.getTime();
    }

    const onTomorrowChosen = () => {
        const tomorrow = addDays(new Date(), 1);
        setFieldValue("start", new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate()));
        setFieldValue("end", new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate(), 23, 59));
        setFieldValue("wholeDay", true);
    }

    const onDayAfterTomorrowChosen = () => {
        const dayAfterTomorrow = addDays(new Date(), 2);
        setFieldValue("start", new Date(dayAfterTomorrow.getFullYear(), dayAfterTomorrow.getMonth(), dayAfterTomorrow.getDate()));
        setFieldValue("end", new Date(dayAfterTomorrow.getFullYear(), dayAfterTomorrow.getMonth(), dayAfterTomorrow.getDate(), 23, 59));
        setFieldValue("wholeDay", true);
    }

    const onWeekendChosen = () => {
        const nextSaturday = nextDate(DayOfWeek.Saturday);
        const nextSunday = nextDate(DayOfWeek.Sunday, nextSaturday);
        setFieldValue("start", new Date(nextSaturday.getFullYear(), nextSaturday.getMonth(), nextSaturday.getDate()));
        setFieldValue("end", new Date(nextSunday.getFullYear(), nextSunday.getMonth(), nextSunday.getDate(), 23, 59));
        setFieldValue("wholeDay", true);
    }

    return (
        <>
            <BaseSpaceSeparator size={30} />
            {spotDropdown}
            <BaseSpaceSeparator size={30} />
            <FormLabel>{Resources.Ustaw_dostepnosc_miejsca_parkingowego}</FormLabel>
            <BaseSpaceSeparator size={5} />
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Chips isSelected={isTomorrowChosen()} text={Resources.jutro} onClick={onTomorrowChosen} />
                <BaseSpaceSeparator size={10} />
                <Chips isSelected={isDayAfterTommorowChosen()} text={Resources.pojutrze} onClick={onDayAfterTomorrowChosen} />
                <BaseSpaceSeparator size={10} />
                <Chips isSelected={isWeekendChosen()} text={Resources.weekend} onClick={onWeekendChosen} />
            </div>
            <BaseSpaceSeparator size={30} />
            <SwitchWithLabel 
                label={Resources.Ustaw_na_caly_dzien}
                value={values.wholeDay}
                onChange={v => setFieldValue("wholeDay", v)}
            />
            <BaseSpaceSeparator size={30} />
            <div style={{display: 'flex'}}>
                <div>
                    <PCDateTimePicker
                        mode={"date"}
                        value={values.start}
                        onChange={v => {
                            const candidate = values.wholeDay ? new Date(v.getFullYear(), v.getMonth(), v.getDate()) : new Date(v.getFullYear(), v.getMonth(), v.getDate(), values.start.getHours(), values.start.getMinutes());
                            setFieldValue("start", candidate);
                            if(candidate >= values.end && values.wholeDay){
                                setFieldValue("end", getTime(candidate, 23, 45));
                            }
                            else if(candidate >= values.end && !values.wholeDay){
                                setFieldValue("end", addMinutes(candidate, 15));
                            }
                        }}
                    >
                        {text => (
                            <TimePresenter 
                                icon="calendar"
                                label={Resources.Data_rozpoczecia}
                                text={text}
                            />
                        )}
                    </PCDateTimePicker>
                    <BaseSpaceSeparator size={15} />
                    <PCDateTimePicker
                        mode={"date"}
                        value={values.end}
                        onChange={v => {
                            const candidate = values.wholeDay ? new Date(v.getFullYear(), v.getMonth(), v.getDate(), 23, 45) : new Date(v.getFullYear(), v.getMonth(), v.getDate(), values.start.getHours(), values.start.getMinutes());
                            setFieldValue("end", candidate);
                            if(candidate <= values.start && values.wholeDay){
                                setFieldValue("start", getTime(candidate, 0 , 0));
                            }  
                            else if(candidate <= values.start && !values.wholeDay){
                                setFieldValue("start", addMinutes(candidate, -15));
                            }                                                  
                        }}
                    >
                        {text => (
                            <TimePresenter 
                                icon="calendar"
                                label={Resources.Data_zakonczenia}
                                text={text}
                            />
                        )}
                    </PCDateTimePicker>
                </div>
                <div style={{flex: 1}} />
                {!values.wholeDay && (
                    <div>                                       
                        <PCDateTimePicker
                            mode={"time"}
                            value={values.start}
                            onChange={v => {
                                const candidate = new Date(values.start.getFullYear(), values.start.getMonth(), values.start.getDate(), v.getHours(), v.getMinutes());
                                setFieldValue("start", candidate);
                                if(candidate >= values.end){
                                    setFieldValue("end", addMinutes(candidate, 15));
                                }
                            }}
                        >
                            {text => (
                                <TimePresenter 
                                    icon="clock"
                                    label={Resources.Godzina_rozpoczecia}
                                    text={text}
                                />
                            )}
                        </PCDateTimePicker>
                        <BaseSpaceSeparator size={15} />
                        <PCDateTimePicker
                            mode={"time"}
                            value={values.end}
                            onChange={v => {
                                const candidate = new Date(values.end.getFullYear(), values.end.getMonth(), values.end.getDate(), v.getHours(), v.getMinutes());
                                setFieldValue("end", candidate);
                                if(candidate <= values.start){
                                    setFieldValue("start", addMinutes(candidate, -15));
                                }                                                 
                            }}
                        >
                            {text => (
                                <TimePresenter 
                                    icon="clock"
                                    label={Resources.Godzina_zakonczenia}
                                    text={text}
                                />
                            )}
                        </PCDateTimePicker>
                    </div>
                )}
            </div>
            {values.enableReservationTranfer && (
                <>
                    <BaseSpaceSeparator size={30} />
                    <FormLabel>{Resources.Komu_chcesz_udostepnic}</FormLabel>
                    <BaseSpaceSeparator size={5} />
                    <DropdownList 
                        actions={availableUsers}
                        value={values.userToShare}
                        onChange={v => setFieldValue("userToShare", v)}
                    />
                </>
            )}
            
        </>
    );
}