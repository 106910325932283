import { ErrorCode, ParkingInfo, ParkingsService, ServiceConfig } from "parkcash-api";
import errorPayload from "../redux/ErrorPayload";
import simplePayload from "../redux/SimplePayload";
import ThunkFunction from "../redux/ThunkFunction";

export type GroupsActionTypes = "GettingGroups" | "GettingGroupsSuccess" | "GettingGroupsFailure";

export interface groupsState {
    gettingGroupsProgress: boolean,
    gettingGroupsError: ErrorCode;
    groups: ParkingInfo[]
}

export const getGroups = (forceRefresh: boolean = false, after: (g: ParkingInfo[]) => void = () => {}): ThunkFunction => async (dispatch, getState) => {
    const {groups: {gettingGroupsProgress, groups}} = getState();

    if(gettingGroupsProgress || (!!(groups || []).length) && !forceRefresh){
        after(groups);
        return;
    }

    try{
        dispatch(gettingGroups());
        const jwt = getState().user.token;
        const {error, isSuccess, result} = await new ParkingsService(new ServiceConfig({jwt})).getUserParkings();
        if(isSuccess){
            dispatch(getGroupsSuccess(result));
            after(result);
        }
        else{
            dispatch(getGroupsFailure(error.code));
            after([]);
        }
    }
    catch{
        dispatch(getGroupsFailure(ErrorCode.NetworkError));
        after([]);
    }
}

const gettingGroups = (): simplePayload => ({
    type: "GettingGroups"
})

interface getGroupsSuccessPayload extends simplePayload {
    groups: ParkingInfo[]
}

const getGroupsSuccess = (groups: ParkingInfo[]): getGroupsSuccessPayload => ({
    groups,
    type: "GettingGroupsSuccess"
})

const getGroupsFailure = (errorCode: ErrorCode): errorPayload => ({
    errorCode,
    type: "GettingGroupsFailure"
});

const initialState: groupsState = {
    gettingGroupsError: null,
    gettingGroupsProgress: false,
    groups: []
}

export default (state: groupsState = initialState, action: simplePayload): groupsState => {
    switch(action.type){
        case "GettingGroups":
            return {
                ...state,
                gettingGroupsError: null,
                gettingGroupsProgress: true
            }
        case "GettingGroupsSuccess":
            return {
                ...state,
                gettingGroupsProgress: false,
                groups: (action as getGroupsSuccessPayload).groups
            }
        case "GettingGroupsFailure":
            return {
                ...state,
                gettingGroupsError: (action as errorPayload).errorCode,
                gettingGroupsProgress: false
            }
        case "Logout":
            return {
                ...initialState
            }
        default:
            return state;
    }
}