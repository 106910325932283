import * as React from "react";
import { createPortal } from "react-dom";
import { connect } from "react-redux";
import { applicationState } from "../../../../redux/ApplicationState";
import Colors from "../../../../styles/Colors";
import { IParkCashApplicationMode, MenuConfiguration } from "../../../../utils/ParkCashApplicationMode";
import { useWindowSize } from "../../../../utils/WindowSizeHook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faBars } from '@fortawesome/free-solid-svg-icons';
import HomeButton from "../Common/HomeButton";
import { BaseSpaceSeparator } from "../../../../styles/Separators";
import Link from "../Common/Link";
import { useLocation } from "react-router-dom";
import { useUser } from "../../../../user/UseUserHook";

const ANIMATION_DURATION = 200;

const MAX_CONTENT_WIDTH = 300;

const mobileNavigator = document.getElementById("mobileNavigator");

interface OwnProps {
    visible: boolean;
    onClose: () => void;
}

interface Props extends OwnProps {
    applicationMode: IParkCashApplicationMode;
}

const MobileLink = (props: {
    item: MenuConfiguration,
    onClose: () => void, 
}) => {
    const {item, onClose} = props;
    const {pathname} = useLocation();

    const isActive = item.to === pathname || (item.submenus && item.submenus.length && item.submenus.some(i => i.to === pathname));

    return (
        <>
            <Link
                isActive={isActive} 
                onClick={onClose}
                text={item.text}
                to={item.to}
            />
            <BaseSpaceSeparator size={30}/>
            {!!item.submenus && !!item.submenus.length && (
                <div style={{paddingLeft: 20}}>
                    {item.submenus.map( (i, index) => <MobileLink key={index} onClose={onClose} item={i} />   )}
                </div>
            )}           
        </>
    )
}

const MobileNavigator = (props: Props) => {
    const {visible, onClose, applicationMode} = props;
    const {pathname} = useLocation();
    const [showed, setShowed] = React.useState(false);
    const {windowWidth} = useWindowSize();
    const contentWidth = Math.min(MAX_CONTENT_WIDTH, windowWidth);
    const {hidePilotsManagement, hideGroups} = useUser();
    const [width, setWidth] = React.useState(-contentWidth);
    

    const beginShowing = () => {
        setShowed(true);
        setImmediate(() => {
            setWidth(0);
        });        
    }

    const beginHiding = () => {
        setWidth(-contentWidth);
        setTimeout(() => {
            setShowed(false);
        }, ANIMATION_DURATION);
    }

    React.useEffect(() => {
        if(visible){
            beginShowing();
        }
        else{
            beginHiding();
        }
    }, [visible]);

    if(!showed){
        return null;
    }

    return createPortal((
        <div 
            style={{
                position: 'fixed',
                top: 0,
                bottom: 0, 
                left: 0, 
                right: 0, 
                zIndex: 1000000,
                backgroundColor: Colors.darkOverlay, 
                display: 'flex'
            }}
        >
            <div
                style={{
                    backgroundColor: Colors.white,
                    width: contentWidth,
                    transform: `translateX(${width}px)`,
                    transition: `transform ${ANIMATION_DURATION}ms`,
                    minHeight: '100%',
                    boxSizing: 'border-box',
                    overflow: 'auto',
                    position: 'relative',
                    paddingTop: 80,
                    paddingLeft: 20,
                    paddingRight: 20
                }}
            >
                <div style={{cursor: "pointer", padding: 10, position: 'absolute', left: 10, top: 15}} onClick={onClose}>
                    <FontAwesomeIcon color={Colors.light_royal_blue} size="lg" icon={faBars}  />
                </div>

                <HomeButton scrolled={false} onClick={onClose}/>
                <BaseSpaceSeparator size={50}/>
                {applicationMode.getMenuConfiguration(hidePilotsManagement, hideGroups).map((item, index) => <MobileLink key={index} item={item} onClose={onClose}  />)}
            </div>
            <div style={{flex: 1, height: '100%', backgroundColor: 'transparent'}} onClick={onClose} />
        </div>
    ), mobileNavigator)
}

const mapStateToProps = (state: applicationState, ownProps: OwnProps): Props => ({
    applicationMode: state.user.applicationMode,
    ...ownProps
});

export default connect(mapStateToProps)(MobileNavigator as any);