import {useSnackbar} from "../../snackbar";
import Resources from "../../../../../Resources";
import {useCallback} from "react";

interface Params {

}

export const useCustomizedNotification = () => {
    const snackbar = useSnackbar();

    const enqueSuccess = useCallback((message: string = Resources.Akcja_zostala_pomyslnie_ukonczona, autoHideDuration: number = 2) => {
        snackbar.enqueueSnackbar(message, {
            variant: 'success',
            autoHideDuration: autoHideDuration * 1000,
            anchorOrigin: {
                horizontal: "right",
                vertical: "bottom"
            }
        })
    }, [snackbar])
    const enqueError = useCallback((message: string = Resources.Wystapil_blad, autoHideDuration: number = 3) => {
        snackbar.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: autoHideDuration * 1000,
            anchorOrigin: {
                horizontal: "right",
                vertical: "bottom"
            }
        })

    }, [snackbar])

    return {
        enqueError,
        enqueSuccess

    }
}