import React, {useMemo} from "react";
import {ReportsService, ServiceConfig} from "parkcash-api";
import Grid from "@mui/material/Unstable_Grid2";
import {Button} from "@mui/material";
import {useUser} from "../../user/UseUserHook";
import {
    FetchOptions, useFetchDataUsingDataSourceClass
} from "../../MUI/components/AddedOrModyfiedMuiComponents/DataFetcher/useFetchUsingDataSourceClass.";
import {GetReportsContextParkingsResultEntry, ParkingReportEntry} from "parkcash-api";
import {useBoolean} from "../../MUI/hooks/use-boolean";
import Resources from "../../../Resources";
import {LoadingErrorAndContentWrapper} from "../../components/Common/LoadingErrorAndContentWrapper";
import MainParkingDashboardKPIWidgetStatisticSection from "./sections/MainDashboardSections/WidgetStatisticSection";
import MainParkingDashboardReservationsChartSection from "./sections/MainDashboardSections/ReservationsChartSection";
import AppWelcome from "./sections/MainDashboardSections/AppWelcomeSection";
import {SeoIllustration} from "../../MUI/assets/illustrations";
import { MinimalPageWrapper } from "../../MUI/components/AddedOrModyfiedMuiComponents/PageWrapers/MinimalPageWraper";


export default function MainDashboardPage() {

    const {
        token: jwt,
        applicationMode
    } = useUser();
    const parkingId = applicationMode.getCurrentParking()?.parkingId || null;
    const fetchReportsOptions: FetchOptions<ParkingReportEntry, any> = useMemo(
        (): FetchOptions<ParkingReportEntry, any> => {
            return {
                additionalInfo: {
                    jwt,
                    parkingId
                },
                load: async (loadOptions, additionalInfo) => {
                    // Fetch data logic goes here...
                    const {
                        filter,
                        page,
                        pageSize,
                        sort
                    } = loadOptions;
                    const {
                        jwt,
                        parkingId
                    } = additionalInfo;

                    const {
                        isSuccess,
                        result,
                        error
                    } = await new ReportsService(new ServiceConfig({jwt})).getParkingReports(parkingId);

                    return isSuccess ? {
                        items: result.entries,
                        totalItems: result?.entries?.length
                    } : error.code;
                }
            }
        },
        [
            jwt,
            parkingId
        ]
    );

    const {
        data: reportsItems,
        loading,
        error
    } = useFetchDataUsingDataSourceClass<ParkingReportEntry, any>(fetchReportsOptions);
    const fetchParkingsContextsOption: FetchOptions<GetReportsContextParkingsResultEntry, any> = useMemo((): FetchOptions<GetReportsContextParkingsResultEntry, any> => {
        return {
            additionalInfo: {
                jwt,
                notUsed: "notUsed"
            },
            load: async (loadOptions, additionalInfo) => {
                // Fetch data logic goes here...
                const {
                    filter,
                    page,
                    pageSize,
                    sort
                } = loadOptions;
                const {
                    jwt,
                    notUsed
                } = additionalInfo;

                const {
                    isSuccess,
                    result,
                    error
                } = await new ReportsService(new ServiceConfig({jwt})).getReportsContextParkings(notUsed);

                return isSuccess ? {
                    items: result.entries,
                    totalItems: result?.entries?.length
                } : error.code;
            }
        }
    }, [jwt]);
    const {
        data: parkingsContext,
        loading: loadingParkingsContext,
        error: errorLoadingParkingsContext
    } = useFetchDataUsingDataSourceClass<ParkingReportEntry, any>(fetchParkingsContextsOption);
    const reservationCount = 10;
    const userCount = 10000;
    const spotsCount = 2000000;
    const confirm = useBoolean();
    const isDataAvaliable = (!!reportsItems && reportsItems?.length > 0) && (!!parkingsContext && parkingsContext?.length > 0);
    return (<MinimalPageWrapper title={Resources.Statystyki_dzienne} >
            <LoadingErrorAndContentWrapper loading={loading || loadingParkingsContext} errors={[
                error,
                errorLoadingParkingsContext
            ]} data={isDataAvaliable}>
                <Grid container spacing={2}  disableEqualOverflow justifyContent={"space-between"} width={"auto"}   >
                    <Grid xs={12}>
                        <MainParkingDashboardKPIWidgetStatisticSection
                            reservationsCount={reservationCount}
                            spotsCount={spotsCount}
                            usersCount={userCount}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} md={8}>
                        <MainParkingDashboardReservationsChartSection parkingId={1}/>
                    </Grid>
                    <Grid xs={12} md={4}>
                        <AppWelcome
                            title={`Welcome back`}
                            description="If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything."
                            img={<SeoIllustration />}
                            action={
                                <Button variant="contained" color="primary">
                                    Go Now
                                </Button>
                            }
                        />
                    </Grid>
                    
                </Grid>
                
            </LoadingErrorAndContentWrapper>

        </MinimalPageWrapper>

    )
}





