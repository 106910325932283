import { faCar, faMapMarkedAlt, faMapMarkerAlt, faParking, faWallet } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import Resources from "../../Resources";
import { BigKPI, StandardKPI } from "../components/DashboardKPI";
import Body from "../layouts/Main/Body";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import BookingPanel from "./BookingPanel";

const driverWhite = require("../../assets/images/driverWhite.svg");
const markerWithParking = require("../../assets/images/markerWithParking.svg");

export default () => {
    return (
        <Body
            title={Resources.Rezerwacja}
        >
            <BookingPanel />
            {/* <BigKPI 
                title="Użytkownicy"
                value="197 009"
                color={Colors.light_royal_blue}
                icon={driverWhite}
            />
            <BaseSpaceSeparator size={20} />
            <BigKPI 
                title="Miejsca w pobliżu"
                value="73 385"
                color={Colors.heliotrope}
                icon={markerWithParking}
            />
            <BaseSpaceSeparator size={100} />
            <StandardKPI 
                icon={faParking} 
                iconBackgroundColor="#e9f5ff"
                iconColor={Colors.light_royal_blue}
                title="Wykonane rezerwacje"
                value="359"
            />
            <BaseSpaceSeparator size={20} />
            <StandardKPI 
                icon={faCar} 
                iconBackgroundColor="#fff6f2"
                iconColor={"#ff9a61"}
                title="Otrzymane rezerwacje"
                value="12"
            />
            <BaseSpaceSeparator size={20} />
            <StandardKPI 
                icon={faWallet} 
                iconBackgroundColor="#fef2f4"
                iconColor="#ff6b92"
                title="Uzyskane środki"
                value="750 zł"
            />
            <BaseSpaceSeparator size={20} />
            <StandardKPI 
                icon={faMapMarkerAlt} 
                iconBackgroundColor="#f5effc"
                iconColor="#8152e2"
                title="Dodane miejsca"
                value="46"
            /> */}
        </Body>
    )
}