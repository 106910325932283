import { CalendarKey } from "moment";
import * as React from "react";
import Resources from "../../../Resources";
import Colors from "../../styles/Colors";
import { PCText } from "../../styles/Texts";
import { formatTime, getDayOffCurrentWeek, getDayOfWeek, getMonthDopelniacz, isToday } from "../../utils/DateTimeUtils";
import { CalendarType } from "./Interfaces";
import Timeline, { BigCalendarEvent } from "./Timeline";
import pl from "date-fns/locale/pl";
import { Calendar, dateFnsLocalizer, EventWrapperProps } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import { StandardButton, StandardButton2 } from "../../styles/Buttons";
import "./CalendarCustomizations.scss";
import { PCClasses } from "../../utils/CSSUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { faCar, faParking } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { ParkingSpotTimelineDaySegmentInfo, TimelineMemberCategory } from "parkcash-api";

const locales = {
  'en-US': pl,
  'pl-PL': pl,
  "en": pl,
  "pl": pl
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const getNow = () => {
    return new Date(2100, 1, 1);
}

const Event = (props: EventWrapperProps<BigCalendarEvent>) => {
    const { event: {type, title, start ,end}, onClick } = props;
    const duration = moment.duration(moment(end).diff(start)).asMinutes();
    const children = (props as any).children;
    const divTitle = `${title} ${formatTime(start)}-${formatTime(end)}`
    return (
        <div title={divTitle} className={PCClasses("pc-button")} onClick={onClick} style={{...children.props.style, position: 'absolute'}}>
            <div style={{
                width: '100%', 
                height: '100%', 
                backgroundColor: type === TimelineMemberCategory.Free ? Colors.pale_grey_two : Colors.light_royal_blue,
                border: type === TimelineMemberCategory.Free ? `2px solid ${Colors.light_royal_blue}` : undefined,
                display: "flex",
                flexDirection: "column",
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {duration > 30 && <FontAwesomeIcon icon={type === TimelineMemberCategory.Free ? faCar : faParking} color={type === TimelineMemberCategory.Free ? Colors.light_royal_blue : Colors.white} style={{fontSize: 24, lineHeight: 24/20, letterSpacing: 0}}/>}
                {duration >= 60 && ( 
                    <>
                        <BaseSpaceSeparator size={5}/>
                        <PCText 
                            color={type === TimelineMemberCategory.Free ? Colors.light_royal_blue : Colors.white} 
                            semibold 
                            textAlign="center" 
                            fontSize={16} 
                            lineHeight={20/16} 
                            letterSpacing={0}
                        >
                            {title}
                        </PCText>
                    </>
                )}
            </div>
        </div>
    );
}



export default (props: {
    timeline: Timeline,
    type: CalendarType,
    day: Date,
    onEventSelected: (item: ParkingSpotTimelineDaySegmentInfo) => void,
    onShare: () => void
}) => {
    const {timeline, type, day, onEventSelected, onShare} = props;
    const now = new Date();

    const getTopText = () => {
        if(type === CalendarType.Day){
            const year = day.getFullYear(), month = day.getMonth(), date = day.getDate();
            return `${date} ${getMonthDopelniacz(month)} ${year}`;
        }
        else{
            const monday = getDayOffCurrentWeek(1, day);
            const sunday = getDayOffCurrentWeek(0, day);
            const isMondayAndSundayInSameMonth = monday.getMonth() === sunday.getMonth();
            const isMondayAndSundayInSameYear = monday.getFullYear() === sunday.getFullYear();
            if(!isMondayAndSundayInSameYear){
                return `${monday.getDate()} ${getMonthDopelniacz(monday.getMonth())} ${monday.getFullYear()}-${sunday.getDate()} ${getMonthDopelniacz(sunday.getMonth())} ${sunday.getFullYear()}`
            }
            else if(!isMondayAndSundayInSameMonth){
                return `${monday.getDate()} ${getMonthDopelniacz(monday.getMonth())}-${sunday.getDate()} ${getMonthDopelniacz(sunday.getMonth())}, ${sunday.getFullYear()}`
            }
            else{
                return `${monday.getDate()}-${sunday.getDate()} ${getMonthDopelniacz(sunday.getMonth())}, ${sunday.getFullYear()}`
            }
        }
    }

    return (
        <div
            style={{flex: 1, backgroundColor: Colors.white, display: 'flex', flexDirection: 'column'}}
        >
            <div
                style={{
                    height: 80,
                    backgroundColor: Colors.light_royal_blue,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 100,
                    paddingRight: 20,
                    borderTopRightRadius: 10
                }}
            >
                {type === CalendarType.Day && (
                    <div
                        style={{
                            padding: "5px 28px",
                            borderColor: Colors.white,
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderRadius: 20,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: 30
                        }}
                    >
                        <PCText
                            color={Colors.white}
                            fontSize={14}
                            letterSpacing={0}
                            lineHeight={20/14}
                            semibold
                        >
                            {isToday(now) ? Resources.Dzisiaj : getDayOfWeek(now.getDate())}
                        </PCText>
                    </div> 
                )}
                <PCText fontSize={24} semibold color={Colors.white}>
                    {getTopText()}
                </PCText>
                <div style={{flex: 1}} />
                <div style={{width: 198}}>
                    <StandardButton2 onClick={onShare}>{Resources.Zarzadzaj_dostepnoscia}</StandardButton2>
                </div>
            </div>
            <div style={{flex: 1,borderBottomRightRadius: 10}}>
                <Calendar 
                    className={PCClasses("pc-custom-big-calendar")}
                    localizer={localizer}
                    date={day}
                    view={type === CalendarType.Day ? "day" : "week"}
                    events={timeline.getBigCalendarEvents()}
                    toolbar={false}
                    style={{height: '100%', borderBottomRightRadius: 10}}
                    culture="pl"
                    onSelectEvent={(event, e) => {
                        onEventSelected(event.resource);
                    }}
                    formats={{
                        timeGutterFormat: "H",
                        dayFormat: "EEEE dd.M.yyyy"
                    }}
                    getNow={getNow}
                    components={{
                        eventWrapper: Event
                    }}
                />
            </div>
        </div>
    );
}