import * as React from "react";

export default () => {
    return (
        <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current" style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
}