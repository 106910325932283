import * as React from "react";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { Column } from "./Column";
import Pager from "./Pager";
import PageSizeDropdown from "./PageSizeDropdown";
import { renderCell } from "./Utils";

export default class List<T> extends React.Component<{
  items: T[];
  columns: Array<Column<T>>;
  pageSize: number;
  page: number;
  totalItems: number;
  availablePageSizes: number[];
  onPageSizeChanged: (p: number) => void;
  onPageChanged: (p: number) => void;
  additionalRows: T[];
  AdditionalRowComponent: React.ComponentType<{ item: T }>;
}> {
  render() {
    const {
      items,
      columns,
      availablePageSizes,
      page,
      pageSize,
      totalItems,
      AdditionalRowComponent = () => null,
      additionalRows,
    } = this.props;

    return (
      <div
        className="pc-datagrid-list"
        style={{
          backgroundColor: Colors.white,
          padding: 30,
          borderRadius: 10,
        }}
      >
        {items.map((item, rowIndex, arr) => {
          const isLastRow = rowIndex === arr.length - 1;
          const hasAdditionalRow = additionalRows.indexOf(item) >= 0;
          return (
            <React.Fragment key={rowIndex}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  borderBottom: isLastRow
                    ? undefined
                    : `1px solid ${Colors.greyVeryLight}`,
                  paddingBottom: 30,
                  paddingTop: 30,
                }}
              >
                {columns.map((column, columnIndex, a) => {
                  const isLastColumn = columnIndex === a.length - 1;

                  return (
                    <div
                      style={{
                        marginRight: isLastColumn ? 0 : 20,
                        marginBottom: 30,
                      }}
                    >
                      <PCText
                        semibold
                        color={Colors.light_royal_blue}
                        fontSize={14}
                        lineHeight={1}
                      >
                        {column.label}
                      </PCText>
                      <BaseSpaceSeparator size={5} />
                      {renderCell(item, hasAdditionalRow, rowIndex, column)}
                    </div>
                  );
                })}
              </div>
              {hasAdditionalRow && <AdditionalRowComponent item={item} />}
            </React.Fragment>
          );
        })}
        <div
          style={{
            height: 50,
            backgroundColor: Colors.white,
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            paddingLeft: 30,
            paddingRight: 30,
            display: "flex",
            alignItems: "center",
          }}
        >
          <PageSizeDropdown
            availablePageSizes={availablePageSizes}
            onPageSizeChanged={this.props.onPageSizeChanged}
            pageSize={pageSize}
          />
          <div style={{ flex: 1 }}></div>
          <Pager
            onPageChanged={this.props.onPageChanged}
            page={page}
            pageSize={pageSize}
            totalItems={totalItems}
          />
          <div style={{ flex: 1 }}></div>
        </div>
      </div>
    );
  }
}
