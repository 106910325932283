import Iconify from "../../../MUI/components/iconify";
import Colors from "../../../styles/Colors";
import React from "react";
import Box from "@mui/material/Box";

export const IconifyStyledForSpotsIcons = (props: { icon: string, title: string }) => {
    const {
        icon,
        title
    } = props;
    return (<Box display={"inline"} title={title}>
            <Iconify icon={icon} width={35} color={Colors.brownish_grey}/>
        </Box>);
}
