import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ISharingRequestsDto } from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import Resources from "../../Resources";
import { applicationState } from "../redux/ApplicationState";
import { StandardButton, StandardButton2, StandardButton3, StandardButton4 } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { DateFormat, formatDate, formatTime } from "../utils/DateTimeUtils";
import { acceptRequestFromCommunity, dismissRequestFromCommunity } from "./Redux";

interface OwnProps {
    item: ISharingRequestsDto
}

interface Props extends OwnProps {
    onAccept: (requestId: string, after: () => void) => void,
    onDismiss: (requestId: string, after: () => void) => void
}

const TimePresenter = (props: {
    time: Date,
    title: string
}) => {
    const {time, title} = props;

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <FontAwesomeIcon icon={faClock} color={Colors.light_royal_blue} style={{fontSize: 13, letterSpacing: 0}} />
            <BaseSpaceSeparator size={5} />
            <PCText letterSpacing={0} fontSize={12} color={Colors.black}>
                {title}: <PCText inline semibold letterSpacing={0} fontSize={12} color={Colors.black}>{formatDate(time, DateFormat.WthoutYear)}/{formatTime(time)}</PCText>
            </PCText>
        </div>
    );
}

const Container = (props: Props) => {
    const {item: {id, startTimestamp, endTimestamp}, onAccept, onDismiss} = props;
    const [acceptProgress, setAcceptProgress] = React.useState(false);
    const [dismissProgress, setDismissProgress] = React.useState(false);

    const onAcceptClicked = async () => {
        setAcceptProgress(true);
        await new Promise<void>(resolve => {
            onAccept(id, resolve);
        })
        setAcceptProgress(false);    
    }

    const onDismissClicked = async () => {
        setDismissProgress(true);
        await new Promise<void>(resolve => {
            onDismiss(id, resolve);
        })
        setDismissProgress(false);    
    }

    return (
        <div
            style={{
                backgroundColor: Colors.light_royal_blue,
                border: `1px solid ${Colors.light_royal_blue}`,
                width: '100%',
                borderRadius: 28
            }}
        >
            <div
                style={{
                    backgroundColor: Colors.white,
                    borderRadius: 28,
                    padding: 20,
                    display: 'flex',
                    alignItems: "center"
                }}
            >
                <TimePresenter title={Resources.Poczatek} time={startTimestamp} />
                <div style={{flex: 1}} />
                <TimePresenter title={Resources.Koniec} time={endTimestamp} />
            </div>
            <div
                style={{
                    padding: "15px 20px",
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <div style={{width: 85}}>
                    <StandardButton2 variant="tiny" onClick={onAcceptClicked} progress={acceptProgress}>{Resources.Zaakceptuj}</StandardButton2>
                </div>
                <BaseSpaceSeparator size={10} />
                <div style={{width: 85}}>
                    <StandardButton4 variant="tiny" onClick={onDismissClicked} progress={dismissProgress}>{Resources.Odrzuc}</StandardButton4>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (_: applicationState, ownProps: OwnProps): Partial<Props> => ({

});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
    onAccept: (requestId, after) => dispatch(acceptRequestFromCommunity(requestId, after)),
    onDismiss: (requestId, after) => dispatch(dismissRequestFromCommunity(requestId, after))
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);