import {getYear, isSameDay, isSameMonth} from 'date-fns';
// utils
import {fDate} from '../../utils/format-time';

// ----------------------------------------------------------------------

export function shortDateLabel(startDate: Date | null, endDate: Date | null) {
    const getCurrentYear = new Date().getFullYear();

    const startDateYear = startDate ? getYear(startDate) : null;

    const endDateYear = endDate ? getYear(endDate) : null;

    const currentYear = getCurrentYear === startDateYear && getCurrentYear === endDateYear;

    const sameDay = startDate && endDate ? isSameDay(new Date(startDate), new Date(endDate)) : false;

    const sameMonth = startDate && endDate ? isSameMonth(new Date(startDate), new Date(endDate)) : false;

    if (currentYear) {
        if (sameMonth) {
            if (sameDay) {
                return fDate(endDate, 'dd MM yy');
            }
            return `${fDate(startDate, 'dd')} - ${fDate(endDate, 'dd.MM.yy')}`;
        }
        return `${fDate(startDate, 'dd.MM')} - ${fDate(endDate, 'dd.MM.yy')}`;
    }

    return `${fDate(startDate, 'dd.MM.yy')} - ${fDate(endDate, 'dd.MM.yy')}`;
}
