import {  ParkingSpotInfoDto, ParkingSpotStatus } from "parkcash-api";
import * as React from "react";
import {PCClasses} from "../utils/CSSUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { BaseSpaceSeparator } from "../styles/Separators";
import Colors from "../styles/Colors";
import { PCText } from "../styles/Texts";
import { StandardButton, StandardButton2 } from "../styles/Buttons";
import Resources from "../../Resources";
import GlobalProgress from "../components/GlobalProgress";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "../components/Dropdown";

const pilot = require("../../assets/images/pilot.svg");

interface Props {
    item: ParkingSpotInfoDto,
    onActivateParkingSpot: (id: string) => void;
    onDeactivateParkingSpot: (id: string) => void;
    onRemoveParkingSpot: (id: string) => void;
    onEditParkingSpot: (id: string) => void;
    onShareParkingSpot: (id: string) => void;
}

export default (props: Props) => {
    const {item: {id, status, level, sector, spotNumber, addressDto: {streetName, streetNumber, city}},onActivateParkingSpot,onDeactivateParkingSpot,onRemoveParkingSpot,onEditParkingSpot, onShareParkingSpot} = props;
    const isActive = status === ParkingSpotStatus.Active;

    const onRemove = () => {
        onRemoveParkingSpot(id);
    }

    const onActivate = () => {
        onActivateParkingSpot(id);
    }

    const onDeactivate = () => {
        onDeactivateParkingSpot(id);
    }

    const onEditClicked = () => {
        onEditParkingSpot(id);
    }

    const onShareClicked = () => {
        onShareParkingSpot(id);
    }

    return (
        <div className={PCClasses("pc-parkinspotslist-item", {
            "pc-parkinspotslist-item-inactive": !isActive
        })}>
            <div style={{position: 'absolute', right: 30, top: 50}}>
                <Dropdown
                    contentWidth={140}
                    actions={[
                        {
                            text: Resources.Udostepnij,
                            onClick: onShareClicked
                        },
                        {
                            text: Resources.Oceny,
                            to: `/parkingspotratings/${id}`
                        },  
                        {
                            text: Resources.Usun,
                            onClick: onRemove
                        }
                    ]}
                >
                    <div className={PCClasses("pc-parkinspotslist-item-remove")}>
                        <FontAwesomeIcon color={Colors.white} icon={faCog} style={{fontSize: 15, letterSpacing: 0}} />
                    </div>
                </Dropdown>
            </div>

            <div style={{display: 'flex', alignItems: 'center'}}>
                {!!level && (
                    <>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 56, height: 56, borderRadius: 14, backgroundColor: Colors.brownish_grey}}>
                            <PCText color={Colors.white} letterSpacing={0} fontSize={30} semibold>{level}</PCText>
                        </div>
                        <BaseSpaceSeparator size={20} />
                    </>
                )}               
                <PCText fontSize={22} color={Colors.black} semibold>
                    {Resources.Miejsce} {spotNumber}
                </PCText>
                {!!sector && (
                    <PCText fontSize={22} color={Colors.black} semibold>
                        , {Resources.sektor} {sector}
                    </PCText>
                )}
            </div>

            <div style={{display: "flex", flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <PCText color={Colors.brownish_grey} fontSize={28} textAlign="center">{city}</PCText>
                <PCText color={Colors.black} semibold fontSize={28} textAlign="center">{streetName} {streetNumber}</PCText>
            </div>

            <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
                <div style={{flex: 1}}>
                    <StandardButton2 variant="tiny" onClick={() => isActive ? onDeactivate() : onActivate()}>{isActive ? Resources.Dezaktywuj : Resources.Aktywuj}</StandardButton2>
                </div>
                <div style={{width: 60}}/>
                <div style={{flex: 1}}>
                    <StandardButton variant="tiny" onClick={onEditClicked}>{Resources.Edytuj}</StandardButton>
                </div>
            </div>
        </div>
    )
}