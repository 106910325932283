import { FormikHelpers } from "formik";
import { ErrorCode, JoinParkingDto, ParkingsService, ServiceConfig } from "parkcash-api";
import * as React from "react";
import Resources from "../../Resources";
import ModalForm from "../components/ModalForm";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { getErrorMessage } from "../utils/ErrorUtils";
import { useJWT } from "../utils/JWTUtils";

const groups = require("../../assets/images/groups.svg");

export default (props: {
    visible: boolean,
    onClose: () => void,
    onSubmitted: () => void,
    code: string,
    pin: string
}) => {
    const {code, onClose, onSubmitted, pin, visible} = props;
    const jwt = useJWT();

    const onSubmit = async (_, {setSubmitting, setStatus}: FormikHelpers<{}>) => {
        try{
            setSubmitting(true);
            const response = await new ParkingsService(new ServiceConfig({jwt})).joinParking(new JoinParkingDto({
                pin: pin,
                parkingPublicId: code  
            }));
            if(response.isSuccess){
                onSubmitted();
            }
            else{
                if(response.error.code === ErrorCode.IncorrectParkingIdOrPIN){
                    setStatus(Resources.Niepoprawny_identyfikator_lub_pin);
                }
                else{
                    setStatus(getErrorMessage(response.error.code));
                }
            }
        }
        catch{
            setStatus(getErrorMessage());
        }
        finally{
            setSubmitting(false);
        }
    }

    return (
        <ModalForm
            initialValues={{}}
            onSubmit={onSubmit}
            icon={groups}
            iconWidth={120}
            iconHeight={112}
            title={Resources.Grupy}
            visible={visible}
            onClose={onClose}
            submitButtonText={Resources.Dolacz}
            showCancelButton
        >
            {() => (
                <>
                    <BaseSpaceSeparator size={30} />
                    <PCText semibold fontSize={18} textAlign="center" color={Colors.black}>
                        {Resources.Czy_chcesz_dolaczyc_do_grupy} {code}?
                    </PCText>
                    <BaseSpaceSeparator size={50} />
                </>
            )}
        </ModalForm>
    )
}