import {Formik, FormikHelpers} from "formik";
import {ErrorCode, GeneratePasswordResetCodeDto, ServiceConfig, SessionsService} from "parkcash-api";
import * as React from "react";
import {Redirect} from "react-router-dom";
import * as yup from "yup";
import Resources from "../../Resources";
import RegularInput from "../components/forms/RegularInput";
import {StandardButton} from "../styles/Buttons";
import Colors from "../styles/Colors";
import {BaseSpaceSeparator} from "../styles/Separators";
import {PCText} from "../styles/Texts";
import {EMAIL_REGEX} from "../utils/Constants";
import {getErrorMessage} from "../utils/ErrorUtils";

const validationSchema = yup.object({
    email: yup.string().required(Resources.Wymagane).matches(EMAIL_REGEX, Resources.Nieprawidlowy_format)

});

interface FormState extends yup.Asserts<typeof validationSchema> {
}

export default () => {
    const initialValues: FormState = {email: "",};
    const [userId, setUserId] = React.useState<string>(null);
    const [redirectToImpersonation, setRedirectToImpersonation] = React.useState(false);

    React.useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            if (e.altKey && e.ctrlKey && e.key === "p") {
                setRedirectToImpersonation(true);
            }
        }
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        }
    }, []);

    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {
        const {email} = state;
        const {
            setStatus,
            setSubmitting
        } = helpers;
        try {
            setSubmitting(true);
            const response = await new SessionsService(new ServiceConfig()).generatePasswordResetCode(new GeneratePasswordResetCodeDto(
                {login: email}));
            if (response.isSuccess) {
                setUserId(response.result.userId)
            } else {
                setStatus(getErrorMessage(response.error.code));
            }
        } catch {
            setStatus(getErrorMessage(ErrorCode.NetworkError));
        } finally {
            setSubmitting(false);
        }
    }

    if (redirectToImpersonation) {
        return <Redirect to="/impersonate"/>;
    }

    if (userId) {
        return <Redirect to={`/resetpassword/${userId}`}/>
    }

    return (<Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {args => {
                const {
                    values,
                    errors,
                    touched,
                    setFieldTouched,
                    setFieldValue,
                    status,
                    isSubmitting,
                    handleSubmit
                } = args;
                return (<form
                        noValidate={true}
                        autoComplete="off"
                        onSubmit={handleSubmit}
                        style={{alignSelf: 'stretch'}}
                    >
                        <RegularInput
                            label={Resources.Email}
                            name="email"
                            variant="big"
                            value={values.email}
                            error={errors.email}
                            touched={touched.email}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            showClearButton
                            showValidatedButton
                        />
                        <div style={{
                            height: 50,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: "flex-end",
                            alignItems: 'center'
                        }}>
                            <PCText fontSize={12} letterSpacing={0} color={Colors.red}>{status || ""}</PCText>
                            <BaseSpaceSeparator size={10}/>
                        </div>


                        <StandardButton variant="big" progress={isSubmitting} type="submit"
                                        tabIndex={4}>{Resources.Wyslij_kod_do_zresetowania_hasla}</StandardButton>
                    </form>);
            }}
        </Formik>)
}