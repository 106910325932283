import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetMobilePilotsLinkIssuanceHistoryViewResultEntryDto, IVirtualPilotDto, LinkSource, LinkStatus, MobilePilotLinkResendDto, ServiceConfig, VirtualPilotDto, VirtualPilotsService } from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import Resources from "../../Resources";
import DropdownList from "../components/DropdownList";
import Input from "../components/forms/Input";
import PCDataGrid from "../components/PCDataGrid";
import ActionColumn, { ActionIcon, MoreDropdown } from "../components/PCDataGrid/ActionColumn";
import StatusPresenter from "../components/StatusPresenter";
import { applicationState } from "../redux/ApplicationState";
import { StandardButton } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { PCClasses } from "../utils/CSSUtils";
import { CustomDataSource, CustomDataSourceLoadOptions, FilterOperator } from "../utils/DataSource";
import { useJWT } from "../utils/JWTUtils";
import Notify from "../utils/Notify";
import { getFilters, getSorts } from "../utils/SieveUtils";
import PilotLinkStatusPresenter from "./PilotLinkStatusPresenter";

interface Props {
    jwt: string;
    pilots: IVirtualPilotDto[];
}

const Container = (props: Props) => {
    const {jwt,pilots} = props;
    let grid: PCDataGrid<GetMobilePilotsLinkIssuanceHistoryViewResultEntryDto> = null;
    const load = async (loadOptions: CustomDataSourceLoadOptions<GetMobilePilotsLinkIssuanceHistoryViewResultEntryDto>) => {
        const {filter, page, pageSize, sort} = loadOptions;


        const {isSuccess, result, error} = await new VirtualPilotsService(new ServiceConfig({jwt})).getMobilePilotsLinkIssuanceHistoryView
        (
            undefined,
            getFilters(filter),
            getSorts(sort),
            page,
            pageSize
        );

        return isSuccess ? {
            items: result.entries,
            totalItems: result.paginationDto.totalElementsCount
        } : error.code;
        
    }

    const [dataSource] = React.useState(new CustomDataSource<GetMobilePilotsLinkIssuanceHistoryViewResultEntryDto>({load}));
    const [search, setSearch] = React.useState("");
    const [status, setStatus] = React.useState("");
    const [pilot, setPilotId] = React.useState("");

    const onSendAgain = async (el: GetMobilePilotsLinkIssuanceHistoryViewResultEntryDto) => {
        try{
            const {isSuccess} = await new VirtualPilotsService(new ServiceConfig({jwt})).resendLink(new MobilePilotLinkResendDto({
                autoSend: true,
                linkId: el.linkId
            }));

            isSuccess ? Notify.Success(Resources.Udostepnienie_zostalo_wyslane) : Notify.Error(Resources.Nastapil_blad);
        }
        catch{
            Notify.Error(Resources.Nastapil_blad);
        }
    }

    const onBlock = async (el: GetMobilePilotsLinkIssuanceHistoryViewResultEntryDto) => {
        try{
            const id = el.linkId;
            const response = await new VirtualPilotsService(new ServiceConfig({jwt})).block(id);
            if(response.isSuccess){
                el.status = LinkStatus.BlockedByIssuer;
                grid && grid.refresh();
            }
            else{
                Notify.Error(Resources.Blad);
            }
        }
        catch{
            Notify.Error(Resources.Blad);
        }
    }

    const onUnBlock = async (el: GetMobilePilotsLinkIssuanceHistoryViewResultEntryDto) => {
        try{
            const id = el.linkId;
            const response = await new VirtualPilotsService(new ServiceConfig({jwt})).unblock(id);
            if(response.isSuccess){
                el.status = LinkStatus.Active;
                grid && grid.refresh();
            }
            else{
                Notify.Error(Resources.Blad);
            }
        }
        catch{
            Notify.Error(Resources.Blad);
        }
    } 

    const onStatusChanged = (v: string) => {
        setStatus(v);
        v === "all" ? dataSource.removeFilter("status") : dataSource.filter("status", FilterOperator.EQUALS, v)
    }

    const onSearch = () => {
        !!search ? dataSource.filter("description", FilterOperator.CASE_INSENSITIVE_STRING_CONTAINS, search) : dataSource.removeFilter("description");
    }

    const onPilotChanged = (v: string) => {
        setPilotId(v);
        v === "all" ? dataSource.removeFilter("pilotId") : dataSource.filter("pilotId", FilterOperator.EQUALS, v);
    }
    

    return (
        <div>
            <PCText color={Colors.black} letterSpacing={0} fontSize={24} semibold>{Resources.Twoje_udostepnienia}</PCText>
            <BaseSpaceSeparator size={20} />
            <div className={PCClasses("pc-sharinggrid-filter")}>
                <Input 
                    value={search}
                    onChangeText={setSearch}
                    borderColor={Colors.very_light_pink}
                    placeholder={Resources.Wpisz_nazwe}
                    onKeyDown={e => {
                        if(e.key === "Enter"){
                            onSearch();
                        }
                    }}
                />
                <StandardButton variant="big" onClick={onSearch}>{Resources.Wyszukaj}</StandardButton>
                <DropdownList 
                    zIndex={20}
                    placeholder={Resources.Status}
                    actions={[
                        {
                            id: "all",
                            text: Resources.Wszystko
                        },
                        {
                            id: LinkStatus.Active.toString(),
                            text: Resources.Aktywny
                        },
                        {
                            id: LinkStatus.Outdated.toString(),
                            text: Resources.Niewazny
                        },
                        {
                            id: LinkStatus.BlockedByIssuer.toString(),
                            text: Resources.Zablokowany
                        },
                    ]}
                    onChange={onStatusChanged}
                    value={status}
                />
                <DropdownList 
                    zIndex={10}
                    placeholder={Resources.Pilot}
                    actions={[
                        {
                            id: "all",
                            text: Resources.Wszystkie
                        },
                        ...(pilots.map(p => ({
                            id: p.virtualPilotId,
                            text: p.description || p.friendlyName
                        })))
                    ]}
                    onChange={onPilotChanged}
                    value={pilot}
                />
            </div>
            <BaseSpaceSeparator size={20} />

            <PCDataGrid<GetMobilePilotsLinkIssuanceHistoryViewResultEntryDto>
                ref={i => grid = i}
                dataSource={dataSource}
                columns={[
                    {
                        label: Resources.Nazwa,
                        dataField: "description",
                    },
                    {
                        label: Resources.Typ,
                        dataField: "linkSource",
                        renderCell: item => (
                            <PCText color={Colors.black} lineHeight={20/16} semibold fontSize={16} letterSpacing={0}>{item.linkSource === LinkSource.OrdinarySharing ? Resources.Udostepnianie : Resources.Rezerwacja}</PCText>
                        )
                    },
                    {
                        label: Resources.Data_udostepnienia,
                        dataField: "creationTimestamp"
                    },
                    // {
                    //     label: Resources.Udostepnione_dla,
                    //     dataField: "targetUserLastName",
                    //     renderCell: item => `${item.targetUserFirstName} ${item.targetUserLastName}`
                    // },
                    {
                        label: Resources.Adres_email,
                        dataField: "targetUserEmail"
                    },
                    {
                        label: Resources.Nr_telefonu,
                        dataField: "targetUserPhone"
                    },
                    {
                        label: Resources.Data_rozpoczecia,
                        dataField: "from"
                    },
                    {
                        label: Resources.Data_zakonczenia,
                        dataField: "to"
                    },
                    {
                        label: Resources.Status,
                        dataField: "status",
                        renderCell: item => <PilotLinkStatusPresenter status={item.status} />
                    },
                    {
                        label: Resources.Akcje,
                        width: '150',
                        renderCell: el => {
                            const {linkSource, status} = el;
                            return (
                                <ActionColumn>
                                    <ActionIcon
                                        icon={faPaperPlane} 
                                        onClick={() => onSendAgain(el)}
                                        visible={linkSource === LinkSource.OrdinarySharing}
                                        title={Resources.Wyslij_ponownie}
                                    />
                                    <MoreDropdown 
                                        actions={[
                                            {
                                                text: Resources.Aktywuj,
                                                onClick: () => onUnBlock(el),
                                                visible: status === LinkStatus.BlockedByIssuer
                                            },
                                            {
                                                text: Resources.Dezaktywuj,
                                                onClick: () => onBlock(el),
                                                visible: status === LinkStatus.Active
                                            }
                                        ]}
                                    />
                                </ActionColumn>
                            )
                        }
                    }
                ]}
            />
        </div>
    )
}

const mapStateToProps = (state: applicationState): Props => ({
    jwt: state.user.token,
    pilots: state.virtualPilots.pilots || []
});

export default connect(mapStateToProps)(Container);