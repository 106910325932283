import * as React from "react";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { MOBILE_WIDTH_THRESHOLD } from "../../utils/Constants";
import { PCClasses } from "../../utils/CSSUtils";
import { useWindowSize } from "../../utils/WindowSizeHook";

export default (props: {
    children: React.ReactNode,
    rigth?: React.ReactNode
    noSpaceInRight?: boolean,
    rightFullWidth?: boolean
    title?: string,
    paddingLeft?: number,
    paddingRight?: number,
    paddingTop?: number,
    paddingBottom?: number 
    titleFontSize?: number
    titleFontWeightBold?: boolean
}) => {
    const {children, title, rigth, paddingBottom, paddingLeft, paddingRight, paddingTop, rightFullWidth = false,noSpaceInRight = false, titleFontSize=46, titleFontWeightBold=false} = props;
    const {windowWidth, windowHeight} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;

    return (
        <div
            className={PCClasses("pc-body", {
                "pc-body-mobile": isMobile
            })}
            style={{paddingTop, paddingRight, paddingLeft, paddingBottom}}
        >
            {!!title && (
                <div 
                    style={{
                        display: 'flex',
                        flexDirection: isMobile ? "column" : "row", 
                        alignItems: isMobile ? 'flex-start' : "center", 
                        justifyContent: 'space-between'
                    }}
                >
                    <PCText inline fontSize={titleFontSize} lineHeight={58/46} color={Colors.greyishBrown} bold={titleFontWeightBold}>{title}</PCText>
                    {!noSpaceInRight && <BaseSpaceSeparator size={20} />}
                    <div
                        style={{flex: rightFullWidth && !isMobile ? 1 : undefined, width: isMobile ? "100%" : undefined, display: 'flex', flexDirection: isMobile ? "column-reverse" : "row", alignItems: isMobile ? 'flex-start' : 'center'}}
                    >
                        {rigth}
                    </div>               
                </div>
            )}      
            {!!title && <BaseSpaceSeparator size={30} />}
            {children}
        </div>
    )
}

export const SubBody = (props: {
    children: React.ReactNode,
    rigth?: React.ReactNode,
    title: string,
    subtitle?: string
}) => {
    const {children, title, rigth, subtitle} = props;
    const {windowWidth, windowHeight} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;

    return (
        <div>
            <div 
                style={{
                    display: 'flex',
                    flexDirection: isMobile ? "column" : "row", 
                    alignItems: isMobile ? 'flex-start' : "center", 
                    justifyContent: 'space-between'
                }}
            >
                <div>
                    <PCText fontSize={30} color={Colors.greyishBrown}>{title}</PCText>
                    {!!subtitle && <PCText fontSize={20} color={Colors.greyishBrown}>{subtitle}</PCText>}
                </div>
                <BaseSpaceSeparator size={20} />
                <div
                    style={{display: 'flex', flexDirection: isMobile ? "column-reverse" : "row", alignItems: isMobile ? 'flex-start' : 'center', width: isMobile ? "100%" : "initial"}}
                >
                    {rigth}
                </div>               
            </div>      
            <BaseSpaceSeparator size={20} />
            {children}
        </div>
    )
}