import * as React from "react";
import { Scrollbars } from 'react-custom-scrollbars';
import Colors from "../styles/Colors";

const renderThumb = (props) => {
    const {style, ...rest} = props;
    return (
        <div 
            style={{
                ...style,
                backgroundColor: Colors.light_royal_blue,
                width: '3px',
                float: 'right',
                borderRadius: 1
            }}
            {...rest}
        />
    )
}

const renderTrack = (props) => {
    const {style, ...rest} = props;
    return (
        <div 
            style={{
                ...style,
                height: '100%',
                top: 0,
                right: 5,
                backgroundColor: Colors.very_light_pink,
                width:"3px",
                borderRadius: 1
            }}
            {...rest}
        />
    )
}

export default (props: {
    height: number,
    children: React.ReactNode;
    contentContainerStyle?: React.CSSProperties,
    contentContainerClassName?: string,
}) => {
    const {children, height, contentContainerStyle, contentContainerClassName} = props;

    return (
        <Scrollbars 
            style={{height}}
            renderTrackVertical={renderTrack}
            renderThumbVertical={renderThumb}
            hideTracksWhenNotNeeded
        >
            <div style={contentContainerStyle} className={contentContainerClassName}>
                {children}    
            </div>       
        </Scrollbars>
    );
}