import React, {ReactNode} from "react";
import {ParkingSpotSizeType} from "parkcash-api";
import {faBicycle, faCarSide, faMotorcycle} from "@fortawesome/free-solid-svg-icons";
import {ActionIcon} from "../../../components/PCDataGrid/ActionColumn";
import Resources from "../../../../Resources";
import {IconifyStyledForSpotsIcons} from "./IconifyStyledForSpotsIcons";

export const getSpotsPossibleVehiclesIcons = (parkingSpotSizeType: ParkingSpotSizeType): ReactNode => {

    switch (parkingSpotSizeType) {
        case ParkingSpotSizeType.Bicycle:
            return <ActionIcon icon={faBicycle} title={Resources.Rower}/>;
        case ParkingSpotSizeType.Motorcycle:
            return <ActionIcon icon={faMotorcycle} title={Resources.Motocykl}/>;
        case ParkingSpotSizeType.Car:
            return <ActionIcon icon={faCarSide} title={Resources.Samochod}/>;
        case ParkingSpotSizeType.Scooter:
            return <IconifyStyledForSpotsIcons icon={"material-symbols:electric-scooter-outline"}
                                               title={Resources.Hulajnoga}/>
    }

}