import React from "react";
import Resources from "../../../../../Resources";
import Box from "@mui/material/Box";
import MultiSeriesChartWithBarAndLines
    from "../../../../MUI/components/AddedOrModyfiedMuiComponents/Charts/MultiSeriesChart";
import {SelectOption} from "../../../../utils/EnumUtils/MapEnumToSelectOptions";

interface Props {

    parkingId: number;
}

export default function MainParkingDashboardReservationsChartSection({
                                                                         parkingId,
                                                                     }: Props) {

    const dataScopeOptions: SelectOption[] = [
        {
            value: "7days",
            label: Resources.Siedem_dni
        },
        {
            value: "30days",
            label: Resources.Trzydziesci_dni
        },
        {
            value: "12months",
            label: Resources.Dwanascie_miesiecy
        },
    ]
    const [dataScope, setDataScope] = React.useState<string>("7days");

    function onDataScopeSelectChange(value: string) {
        setDataScope(value);
        console.log("onDataScopeSelectChange, newvalue=", value)
    }

    return (<Box width={"auto"}
                 /*mb={3}*/
               /*  height={"100%"}*/
    >
        <MultiSeriesChartWithBarAndLines
            sx={{position: "relative"}}
            title={String(Resources.Statystyki_rezerwacji)}
            useDataScopeSelectOnChart={true}
            selectedDataScope={dataScope}
            setDataScope={onDataScopeSelectChange}
            dataScopeOptions={dataScopeOptions}


            chart={{
                labels: [
                    '01/01/2003',
                    '02/01/2003',
                    '03/01/2003',
                    '04/01/2003',
                    '05/01/2003',
                    '06/01/2003',
                    '07/01/2003',
                    '08/01/2003',
                    '09/01/2003',
                    '10/01/2003',
                    '11/01/2003',
                ],
                series: [
                    {
                        name: Resources.Wszystkie,
                        type: 'line',
                        fill: 'solid',
                        yAxisunit: 'PLN',
                        separateYAxis: true,
                        data: [
                            23,
                            11,
                            22,
                            27,
                            13,
                            22,
                            37,
                            21,
                            44,
                            22,
                            30
                        ],
                    },
                    {
                        name: Resources.Wykorzystane,
                        type: 'line',
                        fill: 'solid',
                        data: [
                            44,
                            55,
                            41,
                            67,
                            22,
                            43,
                            21,
                            41,
                            56,
                            27,
                            43
                        ],
                    },
                    {
                        name: Resources.Anulowane,
                        type: 'line',
                        fill: 'solid',
                        data: [
                            30,
                            25,
                            36,
                            30,
                            45,
                            35,
                            64,
                            52,
                            59,
                            36,
                            39
                        ],

                    },
                ],
            }}

        />


    </Box>)

}