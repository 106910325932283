import { AddressDto, ErrorCode, GetSubscriptionProlongationInfoResult } from "parkcash-api"
import React from "react"
import Resources from "../../../Resources"
import ErrorView from "../../components/ErrorView"
import Input from "../../components/forms/Input"
import Spinner from "../../components/Spinner"
import Colors from "../../styles/Colors"
import { BaseSpaceSeparator } from "../../styles/Separators"
import { getErrorMessage } from "../../utils/ErrorUtils"
import BigForm from "../formParts/BigForm"
import { SubscriptionProlongationSpotInfo } from "../formParts/PrologongSubscriptionSpecyficPart"

export default (props: {
    isMobile: boolean,
    initialProgress: boolean,
    initialError: ErrorCode | string,
    errorMessage: string,
    info: GetSubscriptionProlongationInfoResult
}) => {
    const {errorMessage,initialError,initialProgress,isMobile,info} = props;

    return (
        <BigForm 
            isMobile={isMobile}
            title={Resources.Parametry_abonamentu}
        >
            {initialProgress && (
                <div style={{height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Spinner size="medium"/>
                </div>
            )}
            {!initialProgress && typeof initialError === "number" && (
                    <ErrorView title={getErrorMessage(initialError)} />
            )}
            {!initialProgress && typeof initialError !== "number" && !!errorMessage &&  (
                    <ErrorView title={errorMessage} showButton buttonText={Resources.Kup_nowy_abonament} buttonTo={`/op/createsubscription/${info.parkingId}`}/>
            )}
            {!initialProgress && typeof initialError !== "number" && !errorMessage && (
                <div style={{display: 'flex', flexDirection: isMobile ? "column" : "row"}}>
                    <div style={{flex: 1, width: isMobile ? '100%' : undefined}}>
                            <Input 
                                readOnly
                                value={`${info?.address?.streetName} ${info?.address?.streetNumber}`}
                                label={Resources.Adres}
                                borderColor={Colors.brownish_grey}
                                backgroundColor={"#e5e5e5"}
                                color={Colors.black}
                            />
                            <BaseSpaceSeparator size={20} />
                        </div>
                        <BaseSpaceSeparator size={20} />
                        <div style={{flex: 1, width: isMobile ? '100%' : undefined}}>
                            {!!info.spotNumber && (
                                <SubscriptionProlongationSpotInfo sector={info.sector} level={info.level} spotNumber={info.spotNumber} />
                            )}
                        </div>
                </div>
            )}
        </BigForm>
    )
}