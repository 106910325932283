export enum TransactionType {
    GooglePay,
    PayWithSavedCreditCard,
    PayWithNewCreditCard,
    AddNewCreditCard,
    ApplePay,
    BlumediaGate
}

export enum TransactionStatus {
    SUCCESS,
    SUCCESS_MANY,
    PENDING,
    FAILURE,
    UNKNOWN
}

export enum TransactionError {
    BANK_DISABLED,
    BLOCK_MULTIPLE_TRANSACTIONS,
    BLOCK_PAID_TRANSACTIONS,
    CONNECTION_ERROR,
    EMPTY_TRANSACTION_ERROR,
    EMPTY_VIEW_ERROR,
    GENERAL_ERROR,
    INSUFFICIENT_START_AMOUNT,
    INTERNAL_SERVER_ERROR,
    PARSING_ERROR,
    NOT_CONFIRMED_ERROR,
    OUTDATED_ERROR,
    SAVED_CREDIT_CARD_TRANSACTION_FAILED = 495,
    CHECKING_ITN_FAILED = 496,
    RPAN_NOT_RECEIVED = 497,
    REGISTER_ORDERID_ERROR = 498,
    INVALID_GATEWAYS = 499,
    UNKNOWN = 500
}