import { Duration } from "moment";
import Resources from "../../Resources";
import { DayOfWeek } from "parkcash-api";
import { formatNumber } from "./NumberUtils";
import moment from "moment";
import memoizee from "memoizee";

export function addMonth(date: Date, months: number){
    const year = date.getFullYear(), month = date.getMonth(), day = date.getDate();
    const result = new Date(year, month, day);
    result.setMonth(month + months);
    return result;
}

export function addDays(date: Date, days: number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function addHours(date: Date, hours: number){
    return new Date(date.getTime() + hours*60*60*1000);
}

export function addMinutes(date: Date, minutes: number){
    return new Date(date.getTime() + minutes*60*1000);
}

export function endOfDay(date: Date){
    return new Date(date.getTime() - 1);
}

export function getTime(date: Date, hours: number, minutes: number = 0, seconds: number = 0, miliseconds: number = 0){
    date = date || new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, seconds, miliseconds);
}

export function getTodayTime(hours: number, minutes: number = 0, seconds: number = 0, miliseconds: number = 0){
    return getTime(new Date(), hours, minutes, seconds, miliseconds);
}

export function getTodayTimeFromMomentDuration(duration: Duration){
    return getTodayTime(duration.hours(), duration.minutes(), duration.seconds(), duration.milliseconds());
}

export function nextDate(dayIndex: number, baseDate: Date = new Date()) {
    var today = new Date(baseDate.valueOf());
    today.setDate(today.getDate() + (dayIndex - 1 - today.getDay() + 7) % 7 + 1);
    if(today.getTime() === baseDate.getTime()){
        return addDays(today, 7);
    }
    else{
        return today;
    }   
}


export function roundToHours(date: Date, overflow: boolean = false){
    const year = date.getFullYear(), month = date.getMonth(), day = date.getDate(), hours = date.getHours();
    const result = new Date(year, month, day, hours);
    if(overflow){
        return addHours(result, 1);
    }
    else{
        return result;
    }
}

export function isSameDate(date1: Date, date2: Date){
    if(!date1 || !date2){
        return false;
    }

    return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
}

export function getDate(d: Date){
    return new Date(d.getFullYear(), d.getMonth(), d.getDate());
}

export const isToday = (someDate: Date) => {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
}

export const isYesterday = (someDate: Date) => isToday(addDays(someDate, 1));

export const isTomorrow = (someDate: Date) => isToday(addDays(someDate, -1));

export function adjustToGraduation(date: Date, graduationMinutes: number){
    const minutes = date.getMinutes();
    const minutesToAdd = graduationMinutes - minutes%graduationMinutes;

    return addMinutes(date, minutesToAdd);
}

export const dayOfWeekAbbrMap = memoizee(() => ({
    1: Resources.poniedzialek_abbr,
    2: Resources.wtorek_abbr,
    3: Resources.sroda_abbr,
    4: Resources.czwartek_abbr,
    5: Resources.piatek_abbr,
    6: Resources.sobota_abbr,
    0: Resources.niedz_abbr
}));


const monthsDopelniaczeMap = memoizee(() => ({
    0: Resources.stycznia,
    1: Resources.lutego,
    2: Resources.marca,
    3: Resources.kwietnia,
    4: Resources.maja,
    5: Resources.czerwca,
    6: Resources.lipca,
    7: Resources.sierpnia,
    8: Resources.wrzesnia,
    9: Resources.pazdziernika,
    10: Resources.listopada,
    11: Resources.grudnia
}));

export const getMonthDopelniacz = (month: number) => {
    return monthsDopelniaczeMap()[month];
}

export const monthsMap = memoizee(() => ({
    0: Resources.styczen,
    1: Resources.luty,
    2: Resources.marzec,
    3: Resources.kwiecien,
    4: Resources.maj,
    5: Resources.czerwiec,
    6: Resources.lipiec,
    7: Resources.sierpien,
    8: Resources.wrzesien,
    9: Resources.pazdziernik,
    10: Resources.listopad,
    11: Resources.grudzien
}));

const DayOfWeekOneLetterMap = memoizee(() =>  ({
    [DayOfWeek.Monday]: Resources.poniedzialekOneLetter,
    [DayOfWeek.Tuesday]: Resources.wtorekOneLetter,
    [DayOfWeek.Wednesday]: Resources.srodaOneLetter,
    [DayOfWeek.Thursday]: Resources.czwartekOneLetter,
    [DayOfWeek.Friday]: Resources.piatekOneLetter,
    [DayOfWeek.Saturday]: Resources.sobotaOneLetter,
    [DayOfWeek.Sunday]: Resources.niedzielaOneLetter
}));


const DayOfWeekMap =  memoizee(() =>  ({
    [DayOfWeek.Monday]: Resources.poniedzialek,
    [DayOfWeek.Tuesday]: Resources.wtorek,
    [DayOfWeek.Wednesday]: Resources.sroda,
    [DayOfWeek.Thursday]: Resources.czwartek,
    [DayOfWeek.Friday]: Resources.piatek,
    [DayOfWeek.Saturday]: Resources.sobota,
    [DayOfWeek.Sunday]: Resources.niedziela
}));

export function getDayOfWeek(day: number){
    return DayOfWeekMap()[day];
}

export function getDayOfWeekOneLetter(day: number){
    
    return DayOfWeekOneLetterMap()[day];   
} 

export function formatTime(date: Date){
    let hour = formatNumber(date.getHours());
    let minutes = formatNumber(date.getMinutes());
    
    return `${hour}:${minutes}`
}

export enum DayOfWeekFormatType {
    None,
    Full,
    Abbr
}

export enum DateFormat {
    Year,
    WthoutYear,
    SpecialDaysYear,
    SpecialDaysWithoutYear
}

export function formatDate(date: Date, dateFormat: DateFormat = DateFormat.Year, dayOfWeekFormatType = DayOfWeekFormatType.None){
    if(!date){
        return "";
    }

    if(dateFormat === DateFormat.SpecialDaysYear || dateFormat === DateFormat.SpecialDaysWithoutYear){
        if(isToday(date)){
            return Resources.Dzisiaj;
        }
        if(isYesterday(date)){
            return Resources.Wczoraj;
        }
        if(isTomorrow(date)){
            return Resources.Jutro;
        }
    }
    
    const month = date.getMonth(),
    day = date.getDate(),
    year = date.getFullYear(),
    dayOfWeek = date.getDay()
    
    const dayOfWeekPart = dayOfWeekFormatType === DayOfWeekFormatType.None ? "" : 
    dayOfWeekFormatType === DayOfWeekFormatType.Full ? `${DayOfWeekMap()[dayOfWeek]} ` :
    `${dayOfWeekAbbrMap()[dayOfWeek]} `;

    const yearPart = dateFormat === DateFormat.Year || dateFormat === DateFormat.SpecialDaysYear ? `.${year}` : "";

    const formattedDay = formatNumber(day),
    formattedMonth = formatNumber(month + 1);

    return `${dayOfWeekPart}${formattedDay}.${formattedMonth}${yearPart}`
}

export function formatDateTime(date: Date, dateFormat: DateFormat = DateFormat.Year, dayOfWeekFormatType = DayOfWeekFormatType.None){
    if(!date){
        return "";
    }
    
    return `${formatDate(date, dateFormat, dayOfWeekFormatType)} / ${formatTime(date)}`
}

export enum DurationFormat {
    HoursPretty,
    MinutesPretty,
    SecondsPretty,
    Hours,
    Minutes,
    Seconds
}

export function formatDuration(duration: moment.Duration, format: DurationFormat = DurationFormat.Minutes){
    if(format === DurationFormat.HoursPretty){
        return `${duration.hours()} ${Resources.godz}`;
    }
    else if(format === DurationFormat.MinutesPretty) {
        return `${duration.hours()} ${Resources.godz} ${duration.minutes()} ${Resources.min}`;
    }
    else if(format === DurationFormat.SecondsPretty){
        return `${duration.hours()} ${Resources.godz} ${duration.minutes()} ${Resources.min} ${duration.seconds()} ${Resources.sek}`;
    }

    const hours = formatNumber(duration.hours()), 
    minutes = formatNumber(duration.minutes()), 
    seconds = formatNumber(duration.seconds());

    if(format === DurationFormat.Hours){
        return hours;
    }
    else if(format === DurationFormat.Minutes){
        return `${hours}:${minutes}`;
    }
    else{
        return `${hours}:${minutes}:${seconds}`;
    }
}

export const getFirstDayOfMonth = (date: Date) => {
    const year = date.getFullYear(), month = date.getMonth();

    return new Date(year, month, 1);
}

export const getWeeksOfMonth = (date: Date): Date[][] => {
    const result: Date[][] = [];  
    const month = date.getMonth();


    let currentDay = getFirstDayOfMonth(date);
    let currentWeek: Date[] = [];
    do{
        currentWeek = [
            getDayOffCurrentWeek(1, currentDay),
            getDayOffCurrentWeek(2, currentDay),
            getDayOffCurrentWeek(3, currentDay),
            getDayOffCurrentWeek(4, currentDay),
            getDayOffCurrentWeek(5, currentDay),
            getDayOffCurrentWeek(6, currentDay),
            getDayOffCurrentWeek(0, currentDay),
        ];

        currentDay = addDays(currentDay, 7);
        result.push(currentWeek);
    }   
    while(currentWeek[6].getMonth() === month);


    return result;
}

export const getDayOffCurrentWeek = (dayIndex: number, date: Date) => {
    const targetDayOfWeek = date.getDay();
    
    if(targetDayOfWeek === dayIndex){
        return date;
    }

    if(targetDayOfWeek === 0){
        return addDays(date, dayIndex - 7);
    }
    else{
        if(dayIndex === 0){
            return nextDate(0, date);
        }

        if(dayIndex > targetDayOfWeek){
            return nextDate(dayIndex, date);
        }
        else{
            return addDays(nextDate(dayIndex, date), -7);
        }
    }
}

export const generateMonths = memoizee((m: number, from: Date = new Date()) => {
    const result: Date[] = [];
    let current = new Date(from.getFullYear(), from.getMonth(), 1);
    for(let i = 0; i < m; i++){
        result.push(current);
        current = addMonth(current, 1);
    }
    return result;
});
export const getFirstDateOfCurentMonth= ()=>{
    return moment().startOf('month').toDate();
}

export const getFirstDateOfPreviousMonth= ()=>{
    return moment().subtract(1, 'months').startOf('month').toDate();
}
export const getLastDateOfPreviousMonth = () => {
    return moment().subtract(1, 'months').endOf('month').toDate();
}
export const getFirstDateOfCurrentYear= () => {
    return moment().startOf('year').toDate();
}
export const getLastDateOfCurrentYear = () => {
    return moment().endOf('year').toDate();
}
export const getFirstDateOfPreviousYear= () => {
    return moment().subtract(1, 'years').startOf('year').toDate();
}
export const getLastDateOfPreviousYear = () => {
    return moment().subtract(1, 'years').endOf('year').set({hour:23, minute:59, second:59, millisecond:999}).toDate();
}