import { AddressDto, ErrorCode, ParkingSpotAvailibilityType, ParkingSpotInfoDto, ParkingSpotSizeType, ParkingSpotsService, ParkingSpotStatus, ParkingSpotType, ServiceConfig, UsersService } from "parkcash-api";
import Resources from "../../Resources";
import errorPayload from "../redux/ErrorPayload";
import simplePayload from "../redux/SimplePayload";
import Notify from "../utils/Notify";
import ThunkFunction from "../redux/ThunkFunction";


export type ParkingSpotsActions = "GettingParkingSpots" | "GettingParkingSpotsSuccess" | "GettingParkingSpotsFailure" |
"ParkingSpotActivated" | "ParkingSpotDeactivated" | "ParkingSpotRemoved";

export interface parkingSpotsState{
    gettingParkingSpotsProgress: boolean,
    gettingParkingSpotsError: ErrorCode,
    parkingSpots: ParkingSpotInfoDto[];
}

export const getParkingSpots = (forceRefresh: boolean = false, after: (spots: ParkingSpotInfoDto[]) => void = () => {}): ThunkFunction => async (dispatch, getState) => {
    const {user: {token: jwt}, parkingSpots: {gettingParkingSpotsProgress, parkingSpots}} = getState();
    if(gettingParkingSpotsProgress){
        return;
    }

    if(parkingSpots && !forceRefresh){
        after(parkingSpots);
        return;
    }

    try{
        dispatch(gettingParkingSpots())
        const response = await new UsersService(new ServiceConfig({jwt})).getOwnerParkingSpots(null);
        if(response.isSuccess){
            dispatch(gettingParkingSpotsSuccess(response.result));
            after(response.result);
        }
        else{
            dispatch(gettingParkingSpotsError(response.error.code));
        }
    }
    catch{
        dispatch(gettingParkingSpotsError(ErrorCode.NetworkError));
    }
}

const gettingParkingSpots = (): simplePayload => ({
    type: "GettingParkingSpots"
});

const gettingParkingSpotsError = (errorCode: ErrorCode): errorPayload => ({
    errorCode,
    type: "GettingParkingSpotsFailure"
});

export interface gettingParkingSpotsSuccessPayload extends simplePayload {
    spots: ParkingSpotInfoDto[];
}

const gettingParkingSpotsSuccess = (spots: ParkingSpotInfoDto[]): gettingParkingSpotsSuccessPayload => ({
    spots,
    // spots: [
    //     new ParkingSpotInfoDto({
    //         additionalInfo: "",
    //         addressDto: new AddressDto({
    //             streetNumber: "7",
    //             city: "Kraków",
    //             streetName: "Macieja Miechowity"
    //         }),
    //         availibilityType: ParkingSpotAvailibilityType.Limited,
    //         directionHints: "",
    //         id: "test",
    //         isLongTermRentingEnabled: false,
    //         enableFreeBookingWithinGroup: false,
    //         isShortTermRentingEnabled: false,
    //         level: "4",
    //         spotNumber: "1",
    //         longTermRentingPrice: 500,
    //         ownerId: "fsdafsd",
    //         parkingId: "fasdtqwer",
    //         shortTermRentingPrice: 5,
    //         sizeType: ParkingSpotSizeType.Car,
    //         status: ParkingSpotStatus.Active,
    //         type: ParkingSpotType.Garage
    //     })
    // ],
    type: "GettingParkingSpotsSuccess"
});

export interface parkingSpotActionPayload extends simplePayload {
    spotId: string;
}

export const activateParkingSpot = (id: string): ThunkFunction => async (dispatch, getState) => {
    const {user: {token: jwt}} = getState();
    const response = await new ParkingSpotsService(new ServiceConfig({jwt})).activate(id);

    if(response.isSuccess){
        const payload: parkingSpotActionPayload = {
            spotId: id,
            type: "ParkingSpotActivated"
        };
        dispatch(payload);
    }
    else{
        Notify.Error(Resources.Nastapil_blad);
    }
}

export const deactivateParkingSpot = (id: string): ThunkFunction => async (dispatch, getState) => {
    const {user: {token: jwt}} = getState();
    const response = await new ParkingSpotsService(new ServiceConfig({jwt})).deactivate(id);

    if(response.isSuccess){
        const payload: parkingSpotActionPayload = {
            spotId: id,
            type: "ParkingSpotDeactivated"
        };
        dispatch(payload);
    }
    else{
        Notify.Error(Resources.Nastapil_blad);
    }
}

export const removeParkingSpot = (id: string): ThunkFunction => async (dispatch, getState) => {
    const {user: {token: jwt}} = getState();
    const response = await new ParkingSpotsService(new ServiceConfig({jwt})).deleteParkingSpot(id);


    if(response.isSuccess){
        const payload: parkingSpotActionPayload = {
            spotId: id,
            type: "ParkingSpotRemoved"
        };
        dispatch(payload);
    }
    else{
        Notify.Error(Resources.Nastapil_blad);
    }
}

const initialState: parkingSpotsState = {
    gettingParkingSpotsError: null,
    gettingParkingSpotsProgress: false,
    parkingSpots: null
}

export default (state: parkingSpotsState = initialState, action: simplePayload): parkingSpotsState => {
    switch(action.type){
        case "GettingParkingSpots":
            return {
                ...state,
                gettingParkingSpotsProgress: true
            }
        case "GettingParkingSpotsSuccess":
            return {
                ...state,
                gettingParkingSpotsError: null,
                gettingParkingSpotsProgress: false,
                parkingSpots: (action as gettingParkingSpotsSuccessPayload).spots
            }
        case "GettingParkingSpotsFailure":
            return {
                ...state,
                gettingParkingSpotsError: (action as errorPayload).errorCode,
                parkingSpots: null,
                gettingParkingSpotsProgress: false
            }  
        case "ParkingSpotActivated":
            const activatedId = (action as parkingSpotActionPayload).spotId;
                     
            return {
                ...state,
                parkingSpots: state.parkingSpots.map<ParkingSpotInfoDto>(p => new ParkingSpotInfoDto({
                    ...p,
                    status: p.id === activatedId ? ParkingSpotStatus.Active : p.status
                }))
            }
        case "ParkingSpotDeactivated":
            const deactivatedId = (action as parkingSpotActionPayload).spotId;
           

            return {
                ...state,
                parkingSpots: state.parkingSpots.map<ParkingSpotInfoDto>(p => new ParkingSpotInfoDto({
                    ...p,
                    status: p.id === deactivatedId ? ParkingSpotStatus.Deactivated : p.status
                }))
            }
        case "ParkingSpotRemoved":
            const removedId = (action as parkingSpotActionPayload).spotId;
            const removedItemIndex = state.parkingSpots.findIndex(p => p.id === removedId);

            return {
                ...state,
                parkingSpots: state.parkingSpots.filter((_, index) => index !== removedItemIndex)
            }              
        case "Logout":
            return {
                ...initialState
            }
        default:
            return state;   
    }
}