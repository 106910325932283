import Enumerable from "linq";
import moment from "moment";
import {
    ArtifactType,
    CreateArtifactDto,
    DayOfWeek,
    DayOfWeekDescriptor,
    ErrorCode,
    IParkingSpotInfoDto,
    ModifyOwnerOccupationTimelineDto,
    OwnerWorkingHoursDescriptorsSource,
    ParkingInfo,
    ParkingSpotsService,
    ServiceConfig,
    StandardResponseWrapperOfOwnerWorkingHoursDescriptorsSourceAndErrorWrapperOfObject,
    TimeSpan,
    TransferReservationDto,
    TransferReservationRange,
    WorkDayType
} from "parkcash-api";
import {addHours, getTodayTime, getTodayTimeFromMomentDuration, roundToHours} from "../../utils/DateTimeUtils";
import {CyclicFormState, OneTimeFormState, ShareParkingSpotFormState} from "./ShareParkingSpotFormState";
import ConfirmModal from "../../components/ConfirmModal";
import Resources from "../../../Resources";
import {ErrorWrapperOfObject} from "parkcash-api";

export const getFormState = (args?: {
    initialStart: Date,
    initialEnd: Date,
    spot: IParkingSpotInfoDto,
    groups: ParkingInfo[],
    workingHoursResponse: StandardResponseWrapperOfOwnerWorkingHoursDescriptorsSourceAndErrorWrapperOfObject,
    isOneTime: boolean
}): ShareParkingSpotFormState => {
    const {
        initialEnd = roundToHours(addHours(new Date(), 1), true),
        initialStart = roundToHours(new Date, true), 
        groups: parkings = [], 
        workingHoursResponse,
        isOneTime,
        spot
    } = args || {};
    const spotId = spot?.id;

    if(!spotId){
        return {
            commonEnd: new Date(),
            commonStart: new Date(),
            dayAvailability: [],
            dayEnds: [],
            dayStarts: [],
            end: new Date(),
            isOneTime: true,
            spotId: null,
            sameHours: false,
            shareWholeTime: false,
            start: new Date(),
            userToShare: null,
            wholeDay: null,
            enableReservationTranfer: false,
            cyclicDetailedHours: false
        }
    }

    const cyclicFormState = getCyclicFormState(workingHoursResponse.result);
    const oneTimeFormState: OneTimeFormState = {
        wholeDay: false,
        start: initialStart,
        end: initialEnd,
        userToShare: null,
        enableReservationTranfer: Enumerable.from(parkings).firstOrDefault(p => p.id === spot.parkingId)?.allowReservationTransfer
    }

    return {
        ...cyclicFormState,
        ...oneTimeFormState,
        spotId,
        isOneTime
    }
}

const getDefaultCyclicFormState = () => {
    const eightAM = getTodayTime(8);
    const fourPM = getTodayTime(16);

    return {
        cyclicDetailedHours:false,
        shareWholeTime: false,
        sameHours: true,
        commonStart: eightAM,
        commonEnd: fourPM,
        dayAvailability: [false, false, false, false, false, false, false],
        dayStarts: [eightAM, eightAM, eightAM, eightAM, eightAM, eightAM, eightAM],
        dayEnds: [fourPM, fourPM, fourPM, fourPM, fourPM, fourPM, fourPM],
    }
}

const getCyclicFormState = (workingHours: OwnerWorkingHoursDescriptorsSource): CyclicFormState => {
    const {descriptors} = workingHours;

    const eightAM = getTodayTime(8);
    const fourPM = getTodayTime(16);

    if(isDefaultHarmonogram(workingHours)){
        return getDefaultCyclicFormState();
    }


    if(Enumerable.from(descriptors).all(d => d.workDayType === WorkDayType.WorkingAllDay)){
        return {
            cyclicDetailedHours:false,
            shareWholeTime: true,
            sameHours: true,
            commonStart: eightAM,
            commonEnd: fourPM,
            dayAvailability: [false, true, true, true, true, true, false],
            dayStarts: [eightAM, eightAM, eightAM, eightAM, eightAM, eightAM, eightAM],
            dayEnds: [fourPM, fourPM, fourPM, fourPM, fourPM, fourPM, fourPM],
        }
    }

    

    if(Enumerable.from(descriptors).all(d => d.workDayType === WorkDayType.WorkingAllDay ||d.workDayType === WorkDayType.NotWorkingAllDay)){
        return {
            cyclicDetailedHours:false,
            shareWholeTime: false,
            sameHours: true,
            commonStart: eightAM,
            commonEnd: fourPM,
            dayAvailability: Enumerable.from(descriptors)
                                       .orderBy(d => d.dayOfWeek)
                                       .select(d => d.workDayType === WorkDayType.WorkingAllDay)
                                       .toArray(),
            dayStarts: [eightAM, eightAM, eightAM, eightAM, eightAM, eightAM, eightAM],
            dayEnds: [fourPM, fourPM, fourPM, fourPM, fourPM, fourPM, fourPM],
        }
    }

    const dayAvailability = Enumerable.from(descriptors)
                                      .orderBy(d => d.dayOfWeek)
                                      .select(d => d.workDayType === WorkDayType.WorkingWithinRange)
                                      .toArray();
    
    const dayStarts = Enumerable.from(descriptors)
    .orderBy(d => d.dayOfWeek)
    .select(d => d.workDayType === WorkDayType.WorkingWithinRange ? getTodayTimeFromMomentDuration(moment.duration(d.start)) : eightAM)
    .toArray();

    const dayEnds = Enumerable.from(descriptors)
    .orderBy(d => d.dayOfWeek)
    .select(d => d.workDayType === WorkDayType.WorkingWithinRange ? getTodayTimeFromMomentDuration(moment.duration(d.end)) : fourPM)
    .toArray();

    const firstDayWithRange = Enumerable.from(descriptors).first(d => d.workDayType === WorkDayType.WorkingWithinRange);
    if(Enumerable.from(descriptors)
        .where(d => d.workDayType === WorkDayType.WorkingWithinRange)
        .all(d => d.start === firstDayWithRange.start && d.end === firstDayWithRange.end)
    ){
        const commonStart = getTodayTimeFromMomentDuration(moment.duration(firstDayWithRange.start));
        const commonEnd = getTodayTimeFromMomentDuration(moment.duration(firstDayWithRange.end));

        return {
            cyclicDetailedHours:true,
            shareWholeTime: false,
            sameHours: true,
            commonStart: commonStart,
            commonEnd: commonEnd,
            dayAvailability,
            dayStarts: [commonStart, commonStart, commonStart, commonStart, commonStart, commonStart, commonStart],
            dayEnds: [commonEnd, commonEnd, commonEnd, commonEnd, commonEnd, commonEnd, commonEnd],
        }
    }

    return {
        cyclicDetailedHours:true,
        shareWholeTime: false,
        sameHours: false,
        commonStart: eightAM,
        commonEnd: fourPM,
        dayAvailability,
        dayStarts,
        dayEnds
    }
}

const isDefaultHarmonogram = (workingHours: OwnerWorkingHoursDescriptorsSource): boolean => {
    return Enumerable.from(workingHours.descriptors).all(d => d.workDayType === WorkDayType.NotWorkingAllDay);
}

export const saveSharing =async (state: ShareParkingSpotFormState, jwt: string): Promise<true | ErrorWrapperOfObject> => {
    const userId = state.userToShare, spotId = state.spotId;
    if (state.isOneTime) {
        const oneTimeState: OneTimeFormState = state;
        return saveOneTime(jwt, spotId, userId, oneTimeState);
    } else {

        const confirm = await ConfirmModal.show({
            text: Resources.Czy_napewno_chcesz_zmienic_linie_czasowa_miejsca,
            confirmText: Resources.Zmien
        });
        if (confirm) {
            const cyclicState: CyclicFormState = state;
            return saveCyclic(jwt, spotId, cyclicState);
        }

    }
}

const saveOneTime = async (jwt: string, spotId: string, userId: string, state: OneTimeFormState): Promise<true | ErrorWrapperOfObject> => {
    const {end, start, wholeDay} = state;

    const targetStart = wholeDay ? new Date(start.getFullYear(), start.getMonth(), start.getDate()) : start;
    const targetEnd = wholeDay ? new Date(end.getFullYear(), end.getMonth(), end.getDate() + 1) : end;

    try{
        if(userId){
            const reservationResponse = await new ParkingSpotsService(new ServiceConfig({jwt})).transferReservation(new TransferReservationDto({
                parkingSpotId: spotId,
                targetUserId: userId,
                range: new TransferReservationRange({
                    start: targetStart,
                    end: targetEnd
                })
            }));
            return reservationResponse.isSuccess || reservationResponse.error;
        }
        else{
            const artifactResponse = await new ParkingSpotsService(new ServiceConfig({jwt})).createArtifact(spotId, new CreateArtifactDto({
                artifactType: ArtifactType.Free,
                start: targetStart,
                end: targetEnd
            }));
            return artifactResponse.isSuccess || artifactResponse.error;
        }
    }
    catch{
        return new ErrorWrapperOfObject({code: ErrorCode.NetworkError});
    }       
}

const saveCyclic = (jwt: string, spotId: string, state: CyclicFormState): Promise<true | ErrorWrapperOfObject> => {
    const dto = getModifyOwnerOccupationTimelineDto(state)
    return new ParkingSpotsService(new ServiceConfig({jwt}))
    .modifyOwnerWorkingHours(spotId, dto)
    .then(response => {
        return response.isSuccess || response.error
    })
    .catch(e => {
        return new ErrorWrapperOfObject({code: ErrorCode.NetworkError})
    })
}

const getModifyOwnerOccupationTimelineDto = (state: CyclicFormState): ModifyOwnerOccupationTimelineDto => {
    const dayOfWeeks = [DayOfWeek.Sunday, DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday, DayOfWeek.Saturday];
    const {cyclicDetailedHours,commonEnd, commonStart, dayAvailability, dayEnds, dayStarts, sameHours, shareWholeTime} = state;
    
    let descriptors: DayOfWeekDescriptor[];
    if (shareWholeTime) {
        descriptors = dayOfWeeks.map<DayOfWeekDescriptor>(dayOfWeek => new DayOfWeekDescriptor({
            dayOfWeek,
            workDayType: WorkDayType.WorkingAllDay
        }));
    } else {
        
        if(!cyclicDetailedHours){
            descriptors = dayOfWeeks.map<DayOfWeekDescriptor>(dayOfWeek => new DayOfWeekDescriptor({
                dayOfWeek,
                workDayType: dayAvailability[dayOfWeek] ? WorkDayType.WorkingAllDay : WorkDayType.NotWorkingAllDay
            }));
        }
        else {
            if (sameHours) {
                descriptors = dayOfWeeks.map<DayOfWeekDescriptor>(dayOfWeek => new DayOfWeekDescriptor({
                    dayOfWeek,
                    start: getTimeSpan(commonStart),
                    end: getTimeSpan(commonEnd),
                    workDayType: dayAvailability[dayOfWeek] ? WorkDayType.WorkingWithinRange : WorkDayType.NotWorkingAllDay
                }));
            } else {
                descriptors = dayOfWeeks.map<DayOfWeekDescriptor>(dayOfWeek => new DayOfWeekDescriptor({
                    dayOfWeek,
                    start: getTimeSpan(dayStarts[dayOfWeek]),
                    end: getTimeSpan(dayEnds[dayOfWeek]),
                    workDayType: dayAvailability[dayOfWeek] ? WorkDayType.WorkingWithinRange : WorkDayType.NotWorkingAllDay
                }));
            }
        }
       
    }

    return new ModifyOwnerOccupationTimelineDto({
        workingHoursDescriptorsSource: new OwnerWorkingHoursDescriptorsSource({
            descriptors,
            timezoneOffset: new Date().getTimezoneOffset()
        })
    });
}

function getTimeSpan(date: Date) {
    return new TimeSpan([date.getHours(), date.getMinutes(), 0]).toString();
}

export const getWorkingHours = async (spotId: string, jwt: string) => {
    try{
        const workingHoursResponse = await new ParkingSpotsService(new ServiceConfig({jwt})).getOwnerWorkingHoursInfo(spotId);
        if(workingHoursResponse.isSuccess){
            return {workingHoursResponse}
        }
        else{
            return {error: workingHoursResponse.error.code}
        }
    }
    catch{
        return { error: ErrorCode.NetworkError }
    }
}

export const getUsers = async (groupId: string, jwt: string) => {
    try{
        const usersResponse = await new ParkingSpotsService(new ServiceConfig({jwt})).getReservationTransferTargets(groupId);
        if(usersResponse.isSuccess){
            return {
                users: usersResponse.result.entries.map(i => ({
                    text: `${i.firstName} ${i.lastName}`,
                    id: i.userId
                }))
            }
        }
        else{
            return {error: usersResponse.error.code};
        }
    }
    catch{
        return {error: ErrorCode.NetworkError}
    }
}
