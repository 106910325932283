import {LoginType} from "parkcash-api";
import * as React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import Resources from "../../Resources";
import RegularInput from "../components/forms/RegularInput";
import GlobalProgress from "../components/GlobalProgress";
import {applicationState} from "../redux/ApplicationState";
import {StandardButton, TextButton} from "../styles/Buttons";
import Colors from "../styles/Colors";
import {BaseSpaceSeparator} from "../styles/Separators";
import {PCText} from "../styles/Texts";
import {initUser} from "../user/Redux";
import {initNativeRegistration, initThirdPartyRegistration} from "./Redux";
import GoogleLogin, {GoogleLoginResponse, GoogleLoginResponseOffline,} from "react-google-login";
import FacebookLogin, {ReactFacebookFailureResponse, ReactFacebookLoginInfo,} from "react-facebook-login";
import * as yup from "yup";
import {Asserts} from "yup";
import {loginNative, loginToThirdParty} from "./Utils";
import {PCClasses} from "../utils/CSSUtils";
import Notify from "../utils/Notify";
import {getNameAndSurname} from "../utils/UserInfoUtils";
import {Formik, FormikHelpers} from "formik";
import "./Login.scss";
import LanguageSelector from "../components/LanguageSelector/LanguageSelector";

const facebook = require("../../assets/images/authentication/facebook.svg");
const google = require("../../assets/images/authentication/google.svg");

const validationSchema = yup.object({
    login: yup.string().required(Resources.Wymagane),
    password: yup.string().required(Resources.Wymagane),
});

interface FormState extends Asserts<typeof validationSchema> {
}

interface OwnProps {
    getRegistrationUrl?: () => string;
    showRemindPassword?: boolean;
    afterUserInitialized?: (jwt: string) => Promise<void>;
}

interface Props extends OwnProps {
    afterLogin: (
        jwt: string,
        userId: string,
        success: () => void,
        fail: (err: string) => void
    ) => void;
    onNativeRegistration: () => void;
    onThirdPartyRegistration: (
        oauthToken: string,
        loginType: LoginType,
        name: string,
        surname: string,
        email: string
    ) => void;
}

const SocialButton = (props: { image: any; onClick: () => void }) => {
    return (
        <div
            style={{
                cursor: "pointer",
                width: 150,
            }}
            onClick={props.onClick}
        >
            <img src={props.image} width={150} height={58}/>
        </div>
    );
};

const Container = (props: Props) => {
    const {
        getRegistrationUrl = () => "/registration",
        showRemindPassword = true,
        afterUserInitialized = () => Promise.resolve(),
    } = props;
    const [logged, setLogged] = React.useState(false);
    const [redirectToRegistration, setRedirectToRegistration] =
        React.useState(false);
    const [globalProgress, setGlobalProgress] = React.useState(false);

    const initialValues: FormState = {
        login: "",
        password: "",
    };

    const onSubmit = async (
        state: FormState,
        helpers: FormikHelpers<FormState>
    ) => {
        const {setStatus, setSubmitting} = helpers;
        const {login, password} = state;

        setSubmitting(true);
        const loginResult = await loginNative(login, password);
        if (typeof loginResult === "string") {
            setStatus(loginResult);
        } else {
            await onLoginSuccess(loginResult.jwt, loginResult.userId, (m) =>
                setStatus(m)
            );
        }
        setSubmitting(false);
    };

    const onLoginSuccess = async (
        jwt: string,
        userId: string,
        errorCallback: (m: string) => void
    ) => {
        const afterResult = await afterUserLogged(jwt, userId);
        if (typeof afterResult !== "string") {
            await afterUserInitialized(jwt);
            setLogged(true);
        } else {
            errorCallback(afterResult);
        }
    };

    const afterUserLogged = (jwt: string, userId: string) => {
        return new Promise<boolean | string>((resolve) => {
            props.afterLogin(
                jwt,
                userId,
                () => resolve(true),
                (err) => resolve(err)
            );
        });
    };

    const loginThirdPary = async (
        oauthToken: string,
        type: LoginType,
        name: string,
        surname: string,
        email: string
    ) => {
        setGlobalProgress(true);
        const loginResponse = await loginToThirdParty(oauthToken, type);
        if (typeof loginResponse === "string") {
            Notify.Error(loginResponse);
        } else if (loginResponse === true) {
            props.onThirdPartyRegistration(oauthToken, type, name, surname, email);
            setRedirectToRegistration(true);
        } else {
            await onLoginSuccess(loginResponse.jwt, loginResponse.userId, (m) =>
                Notify.Error(m)
            );
        }
        setGlobalProgress(false);
    };

    const onFacebookLogin = (
        userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse
    ) => {
        if (!userInfo["accessToken"]) {
            Notify.Error(Resources.Nastapil_blad);
        } else {
            const {
                accessToken,
                email,
                name: userName,
            } = userInfo as ReactFacebookLoginInfo;
            const {name, surname} = getNameAndSurname(userName);
            loginThirdPary(accessToken, LoginType.Facebook, name, surname, email);
        }
    };

    const onGoogleLogin = (
        response: GoogleLoginResponse | GoogleLoginResponseOffline
    ) => {
        if (!(response as GoogleLoginResponse).tokenObj) {
            Notify.Error(Resources.Nastapil_blad);
        } else {
            const {
                tokenObj: {id_token},
                profileObj: {email, familyName, givenName},
            } = response as GoogleLoginResponse;
            loginThirdPary(id_token, LoginType.Google, givenName, familyName, email);
        }
    };

    if (redirectToRegistration) {
        return <Redirect to={getRegistrationUrl()} push/>;
    }

    if (logged) {
        return <Redirect to="/"/>;
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {(args) => {
                const {
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    status,
                    isSubmitting,
                } = args;

                return (
                    <form
                        noValidate={true}
                        autoComplete="off"
                        onSubmit={handleSubmit}
                        style={{alignSelf: "stretch"}}
                    >
                        <>
                            <GlobalProgress visible={globalProgress}/>
                            <RegularInput
                                label={Resources.Email}
                                name="login"
                                variant="big"
                                value={values.login}
                                error={errors.login}
                                touched={touched.login}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                showClearButton
                                autoTrim
                            />
                            <RegularInput
                                label={Resources.Haslo}
                                name="password"
                                variant="big"
                                value={values.password}
                                error={errors.password}
                                touched={touched.password}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                password
                            />

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    position: "relative",
                                    top: -10,
                                }}
                            >
                                {showRemindPassword && (
                                    <TextButton to="/forgotpassword">
                                        {Resources.Zapomniales_hasla}
                                    </TextButton>
                                )}
                            </div>

                            <div
                                style={{
                                    height: 50,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                <PCText fontSize={12} letterSpacing={0} color={Colors.red}>
                                    {status || ""}
                                </PCText>
                                <BaseSpaceSeparator size={10}/>
                            </div>

                            <StandardButton
                                variant="big"
                                progress={isSubmitting}
                                type="submit"
                                tabIndex={4}
                            >
                                {Resources.Zaloguj_sie}
                            </StandardButton>

                            <BaseSpaceSeparator size={10}/>
                            <PCText
                                color={Colors.brownish_grey}
                                lineHeight={1}
                                fontSize={17}
                                textAlign="center"
                            >
                                {Resources.Nie_masz_jeszcze_konta}{" "}
                                <TextButton
                                    onClick={props.onNativeRegistration}
                                    to={getRegistrationUrl()}
                                >
                                    {Resources.Zaloz_je}
                                </TextButton>
                            </PCText>

                            <BaseSpaceSeparator size={50}/>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                }}
                            >
                                <div
                                    style={{
                                        flex: 1,
                                        height: 1,
                                        backgroundColor: Colors.very_light_pink,
                                    }}
                                />
                                <BaseSpaceSeparator size={25}/>
                                <PCText
                                    color={Colors.brownish_grey}
                                    inline
                                    fontSize={17}
                                    textAlign="center"
                                >
                                    {Resources.lub}
                                </PCText>
                                <BaseSpaceSeparator size={25}/>
                                <div
                                    style={{
                                        flex: 1,
                                        height: 1,
                                        backgroundColor: Colors.very_light_pink,
                                    }}
                                />
                            </div>
                            <BaseSpaceSeparator size={25}/>

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <FacebookLogin
                                    appId="287914222090492"
                                    callback={onFacebookLogin}
                                    containerStyle={{width: 150}}
                                    fields="email,name,first_name,middle_name,last_name"
                                    cssClass={PCClasses("pc-facebook-login")}
                                    textButton=""
                                />
                                <BaseSpaceSeparator size={12}/>
                                <GoogleLogin
                                    clientId="796604582011-7uqhhidj4kdgabc4opa6nodbo2fdfo4e.apps.googleusercontent.com"
                                    render={(renderProps) => (
                                        <SocialButton
                                            image={google}
                                            onClick={renderProps.onClick}
                                        />
                                    )}
                                    buttonText="Login"
                                    onSuccess={onGoogleLogin}
                                    onFailure={onGoogleLogin}
                                    cookiePolicy={"single_host_origin"}
                                />

                            </div>
                            <BaseSpaceSeparator size={50}/>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >

                                <PCText
                                    color={Colors.brownish_grey}
                                    inline
                                    fontSize={17}
                                    textAlign="center"
                                >
                                    {Resources.Jezyk}: &nbsp;&nbsp;&nbsp;
                                </PCText>

                                <LanguageSelector className={"language-selector"} updateServerCulture={false}/>
                            </div>
                        </>
                    </form>
                );
            }}
        </Formik>
    );
};

const mapStateToProps = (
    state: applicationState,
    ownProps: OwnProps
): Partial<Props> => ({
    ...ownProps,
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
    afterLogin: (jwt, userId, success, fail) =>
        dispatch(initUser(jwt, userId, success, fail)),
    onNativeRegistration: () => dispatch(initNativeRegistration()),
    onThirdPartyRegistration: (oauthToken, loginType, name, surname, email) =>
        dispatch(
            initThirdPartyRegistration(oauthToken, loginType, name, surname, email)
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
