import { ErrorCode, IVirtualPilotDto, ServiceConfig, VirtualPilotsService } from "parkcash-api";
import errorPayload from "../redux/ErrorPayload";
import simplePayload from "../redux/SimplePayload";
import ThunkFunction from "../redux/ThunkFunction";

export interface virtualPilotsState {
    gettingPilotsError: ErrorCode;
    gettingPilotsProgress: boolean;
    pilots: IVirtualPilotDto[];
}

export type VirtualPilotsActions = "GettingVirtualPilots" | "GettingVirtualPilotsSuccess" | 
"GettingVirtualPilotsFailure" | "VirtualPilotRemoved";

export const getVirtualPilots = (forceRefresh?: boolean, after: (pilots: IVirtualPilotDto[]) => void = () => {}): ThunkFunction => async (dispatch, getState) => {
    const {user: {token}, virtualPilots: {pilots, gettingPilotsProgress}} = getState();

    if(gettingPilotsProgress){
        return;
    }

    if(pilots && !forceRefresh){
        after(pilots);
        return;
    }

    try{
        dispatch(gettingPilots());
        const response = await new VirtualPilotsService(new ServiceConfig({jwt: token})).getUserPilots();
        if(response.isSuccess){
            dispatch(gettingPilotsSuccess(response.result.data));
            after(response.result.data);
        }
        else{
            dispatch(gettingPilotsFailure(response.error.code));
        }
    }
    catch {
        dispatch(gettingPilotsFailure(ErrorCode.NetworkError));
    }
}

const gettingPilots = (): simplePayload => ({type: "GettingVirtualPilots"});

interface gettingPilotsSuccessPayload extends simplePayload { pilots: IVirtualPilotDto[] }

const gettingPilotsSuccess = (pilots: IVirtualPilotDto[]): gettingPilotsSuccessPayload => ({pilots, type: "GettingVirtualPilotsSuccess"});

const gettingPilotsFailure = (errorCode: ErrorCode): errorPayload => ({errorCode, type: "GettingVirtualPilotsFailure"});

interface virtualPilotAddedPayload extends simplePayload {pilot: IVirtualPilotDto}

export const virtualPilotAdded = (): ThunkFunction => (dispatch, getState) => {
    dispatch(getVirtualPilots(true));
}

//virtualPilotAddedPayload => ({pilot, type: "VirtualPilotAdded"});

interface virtualPilotRemovedPayload extends simplePayload {id: string}

export const virtualPilotRemoved = (id: string): virtualPilotRemovedPayload => ({id, type: "VirtualPilotRemoved"});

const initialState: virtualPilotsState = {
    gettingPilotsError: null,
    gettingPilotsProgress: false,
    pilots: null
};

export default (state: virtualPilotsState = initialState, action: simplePayload): virtualPilotsState => {
    switch(action.type){
        case "GettingVirtualPilots":
            return {
                ...state,
                gettingPilotsError: null,
                gettingPilotsProgress: true,
                pilots: null
            }
        case "GettingVirtualPilotsSuccess":
            return {
                ...state,
                gettingPilotsError: null,
                gettingPilotsProgress: false,
                pilots: (action as gettingPilotsSuccessPayload).pilots
            }
        case "GettingVirtualPilotsFailure":
            return {
                ...state,
                gettingPilotsError: (action as errorPayload).errorCode,
                pilots: null,
                gettingPilotsProgress: false
            }
        case "VirtualPilotRemoved":
            return {
                ...state,
                pilots: state.pilots.filter(p => p.id !== (action as virtualPilotRemovedPayload).id)
            }    
        case "Logout":
            return {
                ...initialState
            }
        default:
            return state;    
    }
}