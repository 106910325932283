import { FormikHelpers } from "formik";
import Enumerable from "linq";
import {
    ErrorCode,
    IParkingSpotInfoDto,
    ParkingInfo,
    ParkingSpotsService,
    ServiceConfig, UsersService
} from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import Resources from "../../../Resources";
import DropdownList from "../../components/DropdownList";
import FormLabel from "../../components/FormLabel";
import ModalForm from "../../components/ModalForm";
import { formatSpot } from "../../components/ParkingSpotNumberPresenter";
import PCTabBar from "../../components/PCTabBar";
import Spinner from "../../components/Spinner";
import { getGroups } from "../../groups/Redux";
import { applicationState } from "../../redux/ApplicationState";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { getErrorMessage, getErrorMessageNew, isErrorWrapper } from "../../utils/ErrorUtils";
import { getParkingSpots } from "../Redux";
import Cyclic from "./Cyclic";
import OneTime from "./OneTime";
import {ShareParkingSpotFormState as FormState} from "./ShareParkingSpotFormState";
import { getFormState, getUsers, getWorkingHours, saveSharing } from "./Utils";
import {ErrorWrapperOfObject} from "parkcash-api";

const homeWithDrive = require("../../../assets/images/homeWithDrive.svg");

interface OwnProps {
    visible: boolean,
    onClose: () => void,
    onSubmitted: () => void,
    initialStart?: Date,
    initialEnd?: Date,
    spotToShare?: string,
    initialSharingType?: SpotSharingType
    showSpotSelector:boolean;
}

interface Props extends OwnProps {
    spots: IParkingSpotInfoDto[];
    spotsProgress: boolean;
    spotsError: ErrorCode;
    groups: ParkingInfo[];
    groupsProgress: boolean,
    groupsError: ErrorCode,
    jwt: string;
    onInit: () => void;
    
}

export enum SpotSharingType {
    OneTime = 1,
    Cyclic = 2
}

const Container = (props: Props) => {
    const {initialSharingType = SpotSharingType.OneTime, onSubmitted, onClose,visible, onInit, spotsError, spotsProgress, spots, jwt, initialEnd, initialStart, spotToShare, groups, groupsError, groupsProgress, showSpotSelector} = props;
    const [progress, setProgress] = React.useState(false);
    const [cyclicOnlyProgress, setCyclicOnlyProgress] = React.useState(false);
    const [error, setError] = React.useState<ErrorCode>(null);
    const [availableUsers, setAvailableUsers] = React.useState<{id: string, text: string}[]>([]);

    const [initialValues, setInitialValues] = React.useState(getFormState())

    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {
        const {setStatus, setSubmitting} = helpers;
        setSubmitting(true);
        const response = await saveSharing(state, jwt);
        if(isErrorWrapper(response)){
            setStatus(getErrorMessageNew(response as ErrorWrapperOfObject));
        }
        else{
            onSubmitted();
        }   
        setSubmitting(false);
    }

    const init = async (spotId: string, sharingType: SpotSharingType, progressType: "global" | "cyclicOnly") => {
        let spot = Enumerable.from(spots).firstOrDefault(s => s.id === spotId);
        if(!spot){
            //getting current spot for manager
            const response = await new UsersService(new ServiceConfig({jwt})).getOwnerParkingSpots(spotId);
            if(response.isSuccess){
                spot = response.result[0];
            }
            else {
                return;
            }
           
        }
        const group = Enumerable.from(groups).firstOrDefault(p => p.id === spot.parkingId);
        progressType === "global" ? setProgress(true) : setCyclicOnlyProgress(true);

        const {error: workingHoursError, workingHoursResponse} = await getWorkingHours(spotId, jwt);
        if(workingHoursError){
            setError(workingHoursError)
        }
        else{
            setInitialValues(getFormState({initialStart, initialEnd, groups, workingHoursResponse, isOneTime: sharingType === SpotSharingType.OneTime, spot}));
            if(group?.allowReservationTransfer){
                const {error: usersError, users} = await getUsers(group?.id, jwt);
                if(usersError){
                    setError(usersError);
                }
                else{
                    setAvailableUsers(users);
                }
            }
        }
        progressType === "global" ? setProgress(false) : setCyclicOnlyProgress(false);
    }

    React.useEffect(() => {
        onInit();
    }, []);

    React.useEffect(() => {
        if(visible && spotToShare){
            init(spotToShare, initialSharingType, "cyclicOnly");
        }    
    }, [spotToShare, visible, initialSharingType]);

    React.useEffect(() => {
        if(spots.length && !groupsProgress && !spotsProgress ){
            if(typeof groupsError === "number" || typeof spotsError === "number"){
                setError(groupsError || spotsError);
                return;
            }
            else{
                const spotId = spotToShare || spots[0].id;
                init(spotId, SpotSharingType.OneTime,"global");
            }       
        }
    }, [spots, groupsProgress, spotsProgress]);

    return (
        <ModalForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            icon={homeWithDrive}
            iconWidth={90}
            iconHeight={110}
            visible={visible}
            onClose={onClose}
            title={Resources.Udostepnij_miejsce_parkingowe}
            paddingLeft={40}
            paddingRight={40}
            enableReinitialize
        >
        {args => {
            const {values, setFieldValue} = args;

            const onSpotChanged = (spotId: string) => {
                setFieldValue("spotId", spotId);
                init(spotId, values.isOneTime ? SpotSharingType.OneTime : SpotSharingType.Cyclic, "cyclicOnly");
            }

            let spotDropdown: JSX.Element = (<></>);
            if(showSpotSelector){
                 spotDropdown = (
                    <>
                        <FormLabel>{Resources.Wybierz_miejsce_parkingowe_do_udostepnienia}</FormLabel>
                        <BaseSpaceSeparator size={5} />
                        <DropdownList
                            zIndex={10}
                            actions={spots.map(s => ({id: s.id, text: formatSpot({level: s.level, spotNumber: s.spotNumber})}))}
                            value={values.spotId}
                            onChange={onSpotChanged}
                            variant="big"
                        />
                    </>
                );
            }

            if(error){
                return (
                    <PCText semibold color={Colors.red} fontSize={16}>{getErrorMessage(error)}</PCText>
                );
            }
            if(progress){
                return (
                    <div style={{height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <Spinner size={"medium"} />
                    </div>
                )
            }

            return (
                <PCTabBar
                    items={[
                        {
                            id: SpotSharingType.OneTime,
                            text: Resources.Jednorazowo
                        },
                        {
                            id: SpotSharingType.Cyclic,
                            text: Resources.Cyklicznie
                        }
                    ]}
                    current={values.isOneTime ? SpotSharingType.OneTime : SpotSharingType.Cyclic}
                    onChange={t => t === SpotSharingType.OneTime ? setFieldValue("isOneTime", true) : setFieldValue("isOneTime", false)}
                >
                    {id => {
                        if(id === 1){
                            return <OneTime args={args} spotDropdown={spotDropdown} availableUsers={availableUsers} />
                        }
                        else{
                            return <Cyclic args={args} spotDropdown={spotDropdown} cyclicOnlyProgress={cyclicOnlyProgress} />
                        }
                    }}
                 </PCTabBar>
            )
        }}
        </ModalForm>
    );
}

const mapStateToProps = (state: applicationState, ownProps: OwnProps): Partial<Props> => ({
    ...ownProps,
    spots: state.parkingSpots.parkingSpots || [],
    spotsError: state.parkingSpots.gettingParkingSpotsError,
    spotsProgress: state.parkingSpots.gettingParkingSpotsProgress,
    groups: state.groups.groups || [],
    groupsError: state.groups.gettingGroupsError,
    groupsProgress: state.groups.gettingGroupsProgress,
    jwt: state.user.token
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
    onInit: () => {
        dispatch(getParkingSpots());
        dispatch(getGroups())
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);