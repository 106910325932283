import { faCalendarAlt, faChevronRight, faParking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetActiveSubscriptionsResultEntryDto } from "parkcash-api";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Resources from "../../Resources";
import { applicationState } from "../redux/ApplicationState";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { MOBILE_WIDTH_THRESHOLD } from "../utils/Constants";
import { PCClasses } from "../utils/CSSUtils";
import { formatDate } from "../utils/DateTimeUtils";
import Notify from "../utils/Notify";
import { capitalizeFirstLetter } from "../utils/StringUtils";
import { useWindowSize } from "../utils/WindowSizeHook";

const subscription_type = require("../../assets/images/subscription_type.svg");


interface Props {
    subscriptionPurchases: GetActiveSubscriptionsResultEntryDto[]
}

const Container = (props: Props) => {
    const {subscriptionPurchases} = props;
    const {windowHeight, windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: isMobile ? 20 : 120,
                paddingBottom: isMobile ? 20 : 50,
                paddingLeft: 20,
                paddingRight: 20
            }}
        >
            <img src={subscription_type} width={220} height={193} />
            <BaseSpaceSeparator size={40} />
            <div style={{width: isMobile ? 300 : 600}}>
                <PCText textAlign="center" semibold fontSize={isMobile ? 28 : 36} color={Colors.black}>
                    {Resources.Wybierz_abonament_ktory_chcesz_przedluzyc}
                </PCText>
            </div>
            <BaseSpaceSeparator size={isMobile ? 40 : 100} />
            
            <div
                style={{
                    boxSizing: 'border-box',
                    width: isMobile ? 300 : 520,
                    paddingTop: 15,
                    paddingBottom: 30,
                    paddingLeft: isMobile ? 20 : 30,
                    paddingRight: isMobile ? 20 : 30,
                    boxShadow: "0 3px 26px 4px rgba(0, 0, 0, 0.08)",
                    backgroundColor: Colors.white,
                    borderRadius: 10
                }}
            >
                {subscriptionPurchases.map((purchase, index, arr) => {
                    const {name, validTo, level, spotNumber, id, canProlong} = purchase;

                    return (
                        <React.Fragment key={id}>
                            <Link
                                to={`/op/prolongsubscription/${id}`}
                                style={{
                                    paddingTop: 15,
                                    paddingBottom: 15,
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    opacity: canProlong ? 1 : 0.5
                                }}
                                className={PCClasses("pc-button")}
                                onClick={e => {
                                    if(!canProlong){
                                        Notify.Warning(Resources.Nie_mozesz_przedluzyc_tego_abonamentu_Administrator_parkingu_wylaczyl_abonament);
                                        e.preventDefault();
                                        e.stopPropagation;
                                        return false;
                                    }

                                    return true;
                                }}
                            >
                                <div>
                                    <div
                                        style={{height: 36, flexDirection: 'column', display: 'flex', justifyContent: 'center'}}
                                    >
                                        <PCText color={Colors.black} fontSize={24}>
                                            {name}
                                        </PCText>
                                    </div>
                                    <BaseSpaceSeparator size={5} />
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: isMobile ? 'column' : 'row',
                                            alignItems: isMobile ? "flex-start" : 'center'
                                        }}
                                    >
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <FontAwesomeIcon icon={faCalendarAlt} style={{fontSize: 18, color: Colors.light_royal_blue}} />
                                            &nbsp;
                                            <PCText color={Colors.greyishBrown} fontSize={18}>
                                                {Resources.Wazny_do}: {formatDate(validTo)}
                                            </PCText>
                                            
                                        </div>
                                        <BaseSpaceSeparator size={isMobile ? 15 : 20} />
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            {(!!spotNumber || !!level) && (
                                                <>
                                                    <FontAwesomeIcon icon={faParking} style={{fontSize: 18, color: Colors.light_royal_blue}} />
                                                    &nbsp;
                                                </>
                                            )}
                                            {!!spotNumber && (
                                                <>
                                                    <PCText color={Colors.greyishBrown} fontSize={18}>
                                                        {capitalizeFirstLetter(Resources.miejsce)}
                                                    </PCText>
                                                    &nbsp;
                                                    <PCText color={Colors.light_royal_blue} semibold fontSize={24}>
                                                        {spotNumber}
                                                    </PCText>
                                                </>
                                            )}
                                            {!!spotNumber && !!level && (
                                                <PCText semibold fontSize={24} color={Colors.light_royal_blue}>
                                                    ,&nbsp;
                                                </PCText>
                                            )}
                                            {!!level && (
                                                <>
                                                    <PCText color={Colors.greyishBrown} fontSize={18}>
                                                        {capitalizeFirstLetter(Resources.poziom)}
                                                    </PCText>
                                                    &nbsp;
                                                    <PCText color={Colors.light_royal_blue} semibold fontSize={24}>
                                                        {level}
                                                    </PCText>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <FontAwesomeIcon icon={faChevronRight} style={{fontSize: 25, color: Colors.light_royal_blue}} />
                            </Link>
                            {index < arr.length -1 && <div style={{backgroundColor: Colors.very_light_pink, height: 1}} />}
                        </React.Fragment>
                    );
                })}
            </div>

        </div>
    );
}

const mapStateToProps = (state: applicationState): Props => ({
    subscriptionPurchases: state.otherPages.tempActiveSubscriptionsPurchases
});

export default connect(mapStateToProps)(Container);
