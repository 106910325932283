import { GetParkingProcessesViewProcessType } from "parkcash-api";
import Resources from "../../../Resources";

export const getTypeText = (type: GetParkingProcessesViewProcessType) => {
    if(type === GetParkingProcessesViewProcessType.ExternalReservation){
        return Resources.rez_zewn
    }
    else if(type === GetParkingProcessesViewProcessType.ReservationProcess){
        return Resources.rezerwacja;
    }
    else if(type === GetParkingProcessesViewProcessType.StartStopParkingProcess){
        return Resources.start_stop;
    }
    else if(type === GetParkingProcessesViewProcessType.ExternalParkingTicket){
        return Resources.bilet;
    }
    else{
        return Resources.abonament;
    }
}