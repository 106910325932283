import {useCallback, useEffect, useState} from 'react';

type FetchFunctionType = () => Promise<any>;

// Define the hook with a generic type parameter <R>
export const useFetch = <R, >(fetchFunction: FetchFunctionType, onSuccess?: (data: R) => void, onError?: (error: Error) => void, loadingInitial = false, ) => {
    const [data, setData] = useState<R | null>(null);
    const [loading, setLoading] = useState(loadingInitial);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        /* const cancelTokenSource = axios.CancelToken.source();*/
        if ( !loading ) {
            return
        }
        fetchFunction()
            .then((response) => {
                setData(response.result);
                if (onSuccess) {
                    onSuccess(response.result);
                }
                setError(null);
            })
            .catch((error: Error) => {
                if (onError) {
                    onError(error);
                }
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });

        // Cleanup function to cancel the request if the component unmounts
        /* return () => cancelTokenSource.cancel("Operation canceled by the user.");*/

    }, [
       loading
    ]);

    const fetch =  useCallback(() => {
        setLoading(true)
    }, []);

    return {
        data,
        loading,
        setLoading,
        error,
        fetch
    };
};
