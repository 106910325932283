import * as React from "react";
import Resources from "../../../Resources";
import Body from "../../layouts/Main/Body";
import {StandardButton} from "../../styles/Buttons";
import {useWindowSize} from "../../utils/WindowSizeHook";
import {MOBILE_WIDTH_THRESHOLD} from "../../utils/Constants";
import {useBoolean} from "../../MUI/hooks/use-boolean";
import {CustomDataSource, FilterOperator} from "../../utils/DataSource";
import {ErrorCode, ParkingManagerService, ServiceConfig, SortOrder} from "parkcash-api";
import {useUser} from "../../user/UseUserHook";
import {
    GetManagerParkingsViewResultEntryDto, IGetManagerParkingsViewResultEntryDto, ParkingInfo
} from "parkcash-api";
import PCDataGrid from "../../components/PCDataGrid";
import Typography from "@mui/material/Typography";
import {ParkingPolicyForm} from "./ParkingPolicyForm/ParkingPolicyForm";
import {MapParkingTypeEnumToLocalizedText} from "../../utils/EnumUtils/ParkingConfigRelatedEnums/ParkingTypeHelpers";
import {getFilters, getMultiKeysValue, getSorts} from "../../utils/SieveUtils";
import {
    CustomizedTextfield
} from "../../MUI/components/AddedOrModyfiedMuiComponents/CustomizedInputs/CustomizedTextfield";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {BaseSpaceSeparator} from "../../styles/Separators";
import ActionColumn, {ActionIcon} from "../../components/PCDataGrid/ActionColumn";
import {faEdit, faGear, faGlobe} from "@fortawesome/free-solid-svg-icons";
import {ParkingConfigForm} from "./ParkingConfigForm/AddParkingForm";
import {UpdateParkingForm} from "./ParkingConfigForm/UpdateParkingForm";
import {ParkingDomeinsForm} from "./ParkingDomensForm/parkingDomeinsForm";

export default function ParkingConfigPage() {

    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const addParkingFormVisible = useBoolean(false);
    const updateParkingFormVisible = useBoolean(false);
    const addOrUppdateParkingPolicyFormVisible = useBoolean(false);
    const updateParkingsDomainsVisible = useBoolean(false);
    const [selectedParkingId, setSelectedParkingId] = React.useState<string | null>(null);
    const jwt = useUser().token;
    const [search, setSearch] = React.useState("");
    const [selectedParkingFriendlyName, setSelectedParkingFriendlyName] = React.useState<string>("");

    const dataSource = React.useMemo(() => {
        return new CustomDataSource<GetManagerParkingsViewResultEntryDto>({
            load: async ({
                             filter,
                             page,
                             pageSize,
                             sort
                         }) => {
                try {
                    const {
                        isSuccess,
                        error,
                        result
                    } = await new ParkingManagerService(new ServiceConfig({jwt})).getManagerParkingsView(getFilters(
                        filter), getSorts(sort), page, pageSize)

                    if (isSuccess) {
                        return {
                            items: result.entries,
                            totalItems: result.paginationDto.totalElementsCount,
                        };
                    } else {
                        return error.code;
                    }
                } catch (e) {
                    return ErrorCode.NetworkError;
                }
            },
            initialSort: [
                {
                    key: "friendlyName",
                    order: SortOrder.Ascending
                }
            ],

        });
    }, [jwt]);

    const getAddresDisplay = ({address}: ParkingInfo) => {
        return `${address.city}, ${address.streetName} ${address.streetNumber}`;
    }

    const onAddNewParking = () => {
        addParkingFormVisible.setValue(true);
    }
    const onSearch = () => {
        const searchFields: Array<keyof IGetManagerParkingsViewResultEntryDto> = [
            "friendlyName",
        ];
        // multiFiltering implementation with Sieve or operator
        const multiKey = getMultiKeysValue(searchFields);
        if (!search || search === "all") {
            console.log('value', search)
            console.log('removing filter', multiKey)
            dataSource.removeFilter(multiKey as keyof IGetManagerParkingsViewResultEntryDto)
        } else if (search && search !== "all") {
            dataSource.filter(
                multiKey as keyof IGetManagerParkingsViewResultEntryDto,
                FilterOperator.CASE_INSENSITIVE_STRING_CONTAINS,
                search
            );
        }
    }

    return (<Body
        title={Resources.Parkingi}
        rigth={(<div style={{width: isMobile ? "100%" : 215}}>
            <StandardButton onClick={onAddNewParking}>
                {Resources.Dodaj}
            </StandardButton>
        </div>)}
    >
        <Stack direction={"row"} spacing={2} width={'100%'} flexWrap={"wrap"}>
            <Box
                sx={{
                    flex: isMobile ? undefined : 1,
                    width: isMobile ? "100%" : undefined,
                }}
            >
                <CustomizedTextfield value={search} onChange={(e) => setSearch(e.target.value)}
                                     label={Resources.Wyszukaj_po_nazwie_parkingu}
                                     fullWidth
                                     onKeyDown={(e) => {
                                         if (e.key === "Enter") {
                                             onSearch();
                                         }
                                     }}
                />
            </Box>
            <Box
                sx={{
                    width: isMobile ? "100%" : 200,
                }}
            >
                <StandardButton onClick={onSearch}>
                    {Resources.Wyszukaj}
                </StandardButton>
            </Box>
        </Stack>
        <BaseSpaceSeparator size={20}/>
        <ParkingConfigForm visible={addParkingFormVisible.value}
                           onSubmitted={() => dataSource.reload()}
                           onClose={() => {
                               addParkingFormVisible.onFalse();
                           }}/>
        <ParkingPolicyForm visible={addOrUppdateParkingPolicyFormVisible.value}
                           onSubmitted={() => dataSource.reload()}
                           parkingId={selectedParkingId}
                           parkingFriendlyName={selectedParkingFriendlyName}
                           onClose={() => {
                               setSelectedParkingId(null)
                               addOrUppdateParkingPolicyFormVisible.onFalse();
                           }}/>
        <UpdateParkingForm visible={updateParkingFormVisible.value}
                           onSubmitted={() => dataSource.reload()}
                           editedParkingId={selectedParkingId}
                           onClose={() => {
                               setSelectedParkingId(null);
                               updateParkingFormVisible.onFalse();
                           }}/>
        <ParkingDomeinsForm
            visible={updateParkingsDomainsVisible.value}
            onClose={()=>{
                setSelectedParkingId(null)
                updateParkingsDomainsVisible.onFalse()
            }}
            parkingId={selectedParkingId}
            parkingFriendlyName={selectedParkingFriendlyName}
            onSubmitted={() => dataSource.reload()}
        />

        <PCDataGrid<GetManagerParkingsViewResultEntryDto>
            dataSource={dataSource}
            columns={[
                {
                    width: 150,
                    label: Resources.Identyfikator,
                    dataField: "id",
                },
                {
                    width: 150,
                    label: Resources.Nazwa,
                    dataField: "friendlyName",
                },
                {
                    width: 100,
                    label: Resources.Rodzaj_parkingu,
                    dataField: "parkingType",
                    renderCell: (item) => (
                        <Typography> {MapParkingTypeEnumToLocalizedText(item.parkingType)} </Typography>)
                },
                {
                    width: 165,
                    label: Resources.Adres,
                    disableSort: true,
                    dataField: "address",
                    renderCell: (item) => (<Typography> {getAddresDisplay(item)}</Typography>)
                },
                {
                    width: 100,
                    label: Resources.Akcje,
                    renderCell: (item, hasAdditionalRow) => (<ActionColumn>
                        <ActionIcon
                            icon={faEdit}
                            title={Resources.Edytuj}
                            onClick={() => {
                                setSelectedParkingId(item.id);
                                updateParkingFormVisible.onTrue();
                                setSelectedParkingFriendlyName(item.friendlyName);

                            }}
                        />
                        <ActionIcon
                            icon={faGear}
                            title={Resources.Polityka_parkingu}
                            onClick={() => {
                                setSelectedParkingId(item.id);
                                setSelectedParkingFriendlyName(item.friendlyName);
                                addOrUppdateParkingPolicyFormVisible.onTrue();
                            }}
                        />
                        <ActionIcon
                            icon={faGlobe} 
                            title={Resources.Edytuj_Autoryzowane_domeny}
                            onClick={() => {
                                setSelectedParkingId(item.id);
                                setSelectedParkingFriendlyName(item.friendlyName);
                                updateParkingsDomainsVisible.onTrue();
                            }}
                        />
                    </ActionColumn>),
                }
            ]}
        />
    </Body>);
}





