import { FormikHelpers } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import * as yup from "yup";
import Resources from "../../Resources";
import RegularInput from "../components/forms/RegularInput";
import ModalForm from "../components/ModalForm";
import { applicationState } from "../redux/ApplicationState";
import { BANK_ACCOUNT_MASK, BANK_ACCOUNT_REGEX } from "../utils/Constants";
import { setBankAccount } from "./Redux";
import {Asserts} from "yup";

const piggybank = require("../../assets/images/piggybank.svg");

interface OwnProps {
    visible: boolean,
    onClose: () => void;
}

interface Props extends OwnProps {
    bankAccount: string;
    onSubmitted: (bankAccount: string, after: (result: boolean | string) => void) => void;
}



interface FormState {bankAccount: string}

const Container = (props: Props) => {
    const {bankAccount, onClose, onSubmitted, visible} = props;

    const initialValues: FormState = {
        bankAccount
    };
  

    const validationSchema = yup.object({
        bankAccount: yup.string().required(Resources.Wymagane).matches(BANK_ACCOUNT_REGEX, Resources.Niepoprawny_format)
    });

    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {
        const {setSubmitting, setStatus} = helpers;
        const {bankAccount} = state;
        setSubmitting(true);
        const result = await new Promise<boolean | string>(resolve => {
            onSubmitted(bankAccount, resolve);
        });
        if(typeof result !== "string"){
            onClose();
        }
        else{
            setStatus(result);
        }
        setSubmitting(false);
    }

    return (
        <ModalForm
            icon={piggybank}
            iconWidth={114}
            iconHeight={110}
            showCancelButton
            title={Resources.Konto_do_wyplat}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            visible={visible}
            onClose={onClose}
        >
            {args => {
                const {values, errors, touched, setFieldTouched, setFieldValue} = args;

                return (
                    <RegularInput 
                        value={values.bankAccount}
                        error={errors.bankAccount}
                        touched={touched.bankAccount}
                        name="bankAccount"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        showValidatedButton
                        showClearButton
                        mask={BANK_ACCOUNT_MASK}
                        label={Resources.Konto_bankowe}
                    />
                )
            }}
        </ModalForm>
    )
}

const mapStateToProps = (state: applicationState, ownProps: OwnProps): Partial<Props> => ({
    ...ownProps,
    bankAccount: state.user.bankAccountNumber
});

const mapDipsatchToProps = (dispatch): Partial<Props> => ({
    onSubmitted: (bankAccount, after) => dispatch(setBankAccount(bankAccount, after))
});

export default connect(mapStateToProps, mapDipsatchToProps)(Container);