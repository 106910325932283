import React from 'react';
import TextField, {TextFieldProps} from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Stack from "@mui/material/Stack";
import {NotNullOrUndefined} from "../../utils/ImportResponseAsFile/generalUtils";

export interface NumberInputProps extends TextFieldProps<"standard"> {
    onValueChanged: (value: string) => void;
    setTouched?: (isTouched?: boolean, shouldValidate?: boolean) => void;
    value: string;
    min?: number;
    max?: number;
    onlyPositive?: boolean;
    errorDisplay?: boolean;

}

export default function NumberInput({onValueChanged, value, onChange, min, max, onlyPositive, setTouched, ...others}: NumberInputProps) {
    
    const handleIncrement = (value: string) => {
      
        if (!value) {
            onValueChanged('0');
            return;
        }
        let isValid=processSilentValidation(String(Number(value)+1)); //checking not for cureent value but for the value after increment
        if (!isValid){
           return;
        }
        onValueChanged(String(Number(value) + 1));
    };

    const handleDecrement = (value: string) => {
        if (!value) {
            onValueChanged('0');
            return;
        }
        let isValid=processSilentValidation(String(Number(value)-1)); //checking not for cureent value but for the value after decrement
        if (!isValid){
           return;
        }
        onValueChanged(String(Number(value) - 1));
        
    };
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const isValid= processSilentValidation(inputValue);
        if(!isValid) {
            return
        }
        onValueChanged(inputValue);
        
     
        
    }
    const processSilentValidation=(inputValue:string):boolean=>{
        let valid = true;
        
        const standardSilentValidation = standardSiilentValidationOfNumberInput(inputValue)
        if (!standardSilentValidation) {
            valid= false;
        }
        if (NotNullOrUndefined(min)) {
            const minValidation = optionalSilentMinValidation(inputValue, min)
            if (!minValidation) {
                valid= false;
            }
        }
        if (NotNullOrUndefined(max)) {
            const maxValidation = optionalSilentMaxValidation(inputValue, max)
            if (!maxValidation) {
              
                valid= false;
            }
        }
       
        
       return valid
    }
        
    return (<TextField
            {...others}
            value={value}
            onChange={handleOnChange}
            InputLabelProps={{shrink: true}}
            InputProps={{
                endAdornment: (<InputAdornment position="end">
                        <Stack direction="column">
                            <IconButton
                                onClick={() => handleIncrement(value)}
                                edge="end"
                                aria-label="increment value"
                                sx={{
                                    p: 0, '&.MuiIconButton-root': {p: 0},
                                }}
                            >
                                <ArrowDropUpIcon/>
                            </IconButton>
                            <IconButton
                                onClick={() => handleDecrement(value)}
                                edge="end"
                                aria-label="decrement value"
                                sx={{
                                    p: 0, '&.MuiIconButton-root': {p: 0},
                                }}
                            >
                                <ArrowDropDownIcon/>
                            </IconButton>
                        </Stack>
                    </InputAdornment>)
            }}
        />);
}

const optionalSilentMinValidation = (value: string, min?: number, max?: number): boolean => {

    if (NotNullOrUndefined(min)) {

        if (Number(value) < min) {
            return false;
        }
    }
    return true;

}
const optionalSilentMaxValidation = (value: string,  max?: number): boolean => {
    if (NotNullOrUndefined(max) ) {
        if (Number(value) > max) {
            return false;
        }

    }
    return true;
}

const standardSiilentValidationOfNumberInput = (value: string): boolean => {
    if (value === "") { //enable empty value
        return true;
    } else if (isNaN(Number(value))) {
        return false;
    }
    return true;
}

