import {
    CustomDataSource, CustomDataSourceLoadOptions, CustomDataSourceLoadResult, DataSourceLoadedArgs, FilterOperator
} from "../../../../utils/DataSource";
import {ErrorCode, IErrorWrapperOfObject, SortOrder} from "parkcash-api";
import {useEffect, useState} from "react";

export interface FetchOptions<T, FetchParams> {
    load: (loadOptions: CustomDataSourceLoadOptions<T>, additionalInfo: FetchParams) => Promise<CustomDataSourceLoadResult<T> | ErrorCode>;
    additionalInfo: FetchParams;
    initialSort?: Array<{ key: keyof T, order: SortOrder }>;
    initialFilter?: Array<{ key: keyof T, value: any, operator: FilterOperator }>;
}

export function useFetchDataUsingDataSourceClass<T, FetchParams = any>(options: FetchOptions<T, FetchParams>) {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<T[]>([]);
    const [error, setError] = useState<IErrorWrapperOfObject | null>(null);

    useEffect(() => {
        const dataSource = new CustomDataSource<T, FetchParams>({
            load: options.load,
            additionalInfo: options.additionalInfo,
            initialSort: options.initialSort,
            initialFilter: options.initialFilter
        });

        const onDataSourceLoaded = (args: any) => {
            const {items} = args as DataSourceLoadedArgs<T>;
            setData(items);
            setLoading(false);
        };

        const onDataSourceLoadingError = (err: IErrorWrapperOfObject) => {
            setError(err);
            setLoading(false);
        };

        const onDataSourceLoadingStarted = () => {
            setLoading(true);
            setData([])
        }

        dataSource.on("loadingStarted", onDataSourceLoadingStarted);
        dataSource.on("loaded", onDataSourceLoaded);
        dataSource.on("loadingError", onDataSourceLoadingError);

        setLoading(true);
        dataSource.reload();

        // Cleanup
        return () => {
            dataSource.off("loadingStarted", onDataSourceLoadingStarted);
            dataSource.off("loaded", onDataSourceLoaded);
            dataSource.off("loadingError", onDataSourceLoadingError);
        };
    }, [options]);

    return {
        data,
        loading,
        error
    };
}
