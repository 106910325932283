export const POST_PUBLISH_OPTIONS = [
    {
        value: 'published',
        label: 'Published',
    },
    {
        value: 'draft',
        label: 'Draft',
    },
];

export const POST_SORT_OPTIONS = [
    {
        value: 'latest',
        label: 'Latest'
    },
    {
        value: 'popular',
        label: 'Popular'
    },
    {
        value: 'oldest',
        label: 'Oldest'
    },
];
