import { ErrorCode, ParkingsService, ServiceConfig } from "parkcash-api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { applicationState } from "../redux/ApplicationState";

const getParkingLayout = async (parkingId: string, jwt: string) => {
  try {
    const { isSuccess, error, result } = await new ParkingsService(
      new ServiceConfig({ jwt })
    ).getParkingLayoutInfo(parkingId);
    if (isSuccess) {
      return {
        levels: result?.levels?.map((l) => l.name) || [],
        sectors: result?.sectors?.map((s) => s.name) || [],
      };
    } else {
      return {
        error: error.code,
      };
    }
  } catch {
    return { error: ErrorCode.NetworkError };
  }
};

const useParkingLayout = () => {
  const [progress, setProgress] = useState(true);
  const [error, setError] = useState(false);
  const [levels, setLevels] = useState<string[]>([]);
  const [sectors, setSectors] = useState<string[]>([]);
  const { jwt, parkingId } = useSelector<
    applicationState,
    { parkingId: string; jwt: string }
  >((s) => ({
    jwt: s.user.token,
    parkingId: s.user.applicationMode.getCurrentParking()?.parkingId,
  }));

  useEffect(() => {
    const getLayout = async () => {
      setProgress(true);
      const { error, levels, sectors } = await getParkingLayout(parkingId, jwt);
      if (error) {
        setError(true);
        setLevels([]);
        setSectors([]);
      } else {
        setError(false);
        setLevels(levels);
        setSectors(sectors);
      }
      setProgress(false);
    };
    getLayout();
  }, [parkingId, jwt]);

  return { levels, sectors, error, progress };
};

export { useParkingLayout };
