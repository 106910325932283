import memoizee from "memoizee";
import * as React from "react";
import Colors from "../../styles/Colors";
import { PCText } from "../../styles/Texts";
import { MOBILE_WIDTH_THRESHOLD } from "../../utils/Constants";
import { PCClasses } from "../../utils/CSSUtils";
import { addMinutes, formatTime, getTime } from "../../utils/DateTimeUtils";
import { useWindowSize } from "../../utils/WindowSizeHook";

const getValues = memoizee((granulationMinutes: number) => {
    const now = new Date();
    const year = now.getFullYear(), month = now.getMonth(), day = now.getDate();

    const result: Date[] = [];
    let current = new Date(year, month, day, 0, 0);
    do{
        result.push(current);
        current = addMinutes(current, granulationMinutes);
    }
    while(current.getDate() === day);

    return result;
});

const HEIGHT = 274;

const WIDTH = 100;

const ITEM_HEIGHT = 50;

const isItemActive = (item: Date, value: Date, min: Date, max: Date, mode: "date" | "time" | "datetime") => {
    if(mode === "time"){
        const now = new Date();
        const todayItem = getTime(now, item.getHours(), item.getMinutes());
        const todayMin = getTime(now, min.getHours(), min.getMinutes());
        const todayMax = getTime(now, max.getHours(), max.getMinutes());

        return todayItem >= todayMin && todayItem <= todayMax;
    }
    else{
        const valueItem = getTime(value, item.getHours(), item.getMinutes());
        return valueItem >= min && valueItem <= max;
    }
}

export default (props: {
    value: Date,
    onChange: (v: Date) => void;
    mode: "date" | "time" | "datetime";
    granulationMinutes: number,
    min?: Date,
    max?: Date
}) => {
    const {onChange, value, granulationMinutes, max, min, mode} = props;
    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const container = React.useRef<HTMLDivElement>();

    const items = getValues(granulationMinutes);

    React.useEffect(() => {
        const index = items.findIndex(i => value && i.getMinutes() === value.getMinutes() && i.getHours() === value.getHours())
        if(index >= 0){
            container.current.scroll({
                top: (index - 2)*ITEM_HEIGHT
            });
        }    
    }, []);

    return (
        <div ref={container} style={{width: WIDTH, height: HEIGHT, overflow: 'auto', backgroundColor: Colors.white}}>
            {items.map((item, index) => {
                const selected = !!value && value.getHours() === item.getHours() && value.getMinutes() === item.getMinutes();
                const isActive = isItemActive(item, value, min, max, mode)

                return (
                    <div 
                        key={index}
                        className={PCClasses("pc-button")}
                        onClick={() => onChange(item)} 
                        style={{
                            pointerEvents: isActive ? undefined : "none",
                            opacity: isActive ? 1 : 0.5,
                            height: ITEM_HEIGHT, 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center',
                            backgroundColor: selected ? Colors.datePickerSelected : 'transparent'
                        }}
                    >
                        <PCText
                            semibold 
                            fontSize={12}
                            color={selected ? Colors.light_royal_blue : Colors.black}
                        >
                            {formatTime(item)}
                        </PCText>
                    </div>
                )
            })}
        </div>
    );
}