import Resources from "../../../Resources";
import { EMAIL_REGEX, PHONE_REGEX } from "../../utils/Constants";
import { NAME_AND_SURNAME_FORMAT } from "../../utils/UserInfoUtils";
import {ReservationConfirmationFormState} from "./FormState";

export const validateReservationConfirmation = (state: ReservationConfirmationFormState) => {
    const errors: {[key in keyof ReservationConfirmationFormState]?: string} = {};
    const {nameAndSurname, email, phoneNumber, isMeParking} = state;
    if(!isMeParking){
        if(!nameAndSurname){
            errors.nameAndSurname = Resources.Wymagane
        }
        
        if(!phoneNumber && !email){
            errors.email = Resources.Wymagane_jest_podanie_emaila_lub_telefonu;
        }
    
        if(!!phoneNumber && !PHONE_REGEX.test(phoneNumber)){
            errors.phoneNumber = Resources.Niepoprawny_format;
        }
    
        if(!!email && !EMAIL_REGEX.test(email)) {
            errors.email = Resources.Niepoprawny_format;
        }
    }

    return errors;
}