import React from "react"
import Resources from "../../../Resources";

import ParkingSpotSection from "./sections/ParkingSpotsReportSections/ParkingSpotSections/ParkingSpotSection";
import ReservationSection from "./sections/ParkingSpotsReportSections/ReservationSections/ReservationSection";
import {useUser} from "../../user/UseUserHook";
import {DashboardService, ServiceConfig} from "parkcash-api";
import MinimalPageWraperWithDataFetcher
    from "../../MUI/components/AddedOrModyfiedMuiComponents/PageWrapers/MinimalPageWraperWithDataFetcher";

export const ParkingSpotsAndReservationsReport = () => {
    const {
        token: jwt,
        applicationMode
    } = useUser();
    const dashBoardService = new DashboardService(new ServiceConfig({jwt}));
    return (<MinimalPageWraperWithDataFetcher
            pageTitle={Resources.Wykorzystanie_parkingu}
            fetchFunction={(parkingId, start, end, cancelToken) => dashBoardService.getFinancialDashboradData(
                parkingId,
                start,
                end,
                cancelToken
            )}
        >
            {() => (<>
                <ParkingSpotSection/>
                <ReservationSection/>
            </>)}

        </MinimalPageWraperWithDataFetcher>)
}

