import * as React from "react";
import Resources from "../../Resources";
import ListGradient from "../components/ListGradient";
import PCScrollbars from "../components/PCScrollbars";
import { StandardButton } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { formatAddress } from "../utils/AddressUtils";
import { PCClasses } from "../utils/CSSUtils";
import { ParkingTargetInfo } from "./SearchResult";

interface Props {
    targets: ParkingTargetInfo[];
    onChosen: (target: ParkingTargetInfo) => void;    
}

const NoPlaceFound = (props: {onAsk: () => void}) => {
    return (
        <div
            style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                padding: 20,
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <PCText textAlign="center" fontSize={20} bold color={Colors.black}>
                    {Resources.Nie_znaleziono_miejsca_parkingowego}
            </PCText>
            {/* <BaseSpaceSeparator size={5} />
            <PCText textAlign="center" fontSize={14} color={Colors.brownish_grey}>
                {Resources.Nie_przejmuj_sie_wynikiem_wyszukiwania_i_zapytaj_okolicznych_wlascicieli_o_wolne_miejsce}
            </PCText>
            <BaseSpaceSeparator size={20} />
            <div style={{paddingLeft: 30, paddingRight: 30}}>
                <StandardButton onClick={props.onAsk}>{Resources.Zapytaj_o_miejsce}</StandardButton>
            </div> */}
        </div>
    )
}

const Item = (props: {
    onChosen: (target) => void;
    target: ParkingTargetInfo
}) => {
    const {onChosen, target} = props;
    const {spotInfo, startStopParkingInfo, type} = target;

    const price = type === "parkingSpot" ? spotInfo.price : startStopParkingInfo.effectivePrice;

    return (
        <div
            className={PCClasses("pc-button")}
            onClick={() => onChosen(target)}
            style={{
                height: 95,
               
                boxShadow: "0 3px 26px 4px rgba(0, 0, 0, 0.08)",
                borderRadius: 25,
                display: 'flex'
            }}
        >
            <div
                style={{
                    flex: 1,
                    backgroundColor: Colors.white,
                    borderTopLeftRadius: 25,
                    borderBottomLeftRadius: 25,
                    paddingLeft: 20,
                    paddingTop: 20,

                }}
            >
                <PCText fontSize={17} lineHeight={20/17} letterSpacing={0} color={Colors.brownish_grey} semibold>
                    {type === "parkingSpot" ? spotInfo.ownerName : startStopParkingInfo.friendlyName}
                </PCText>
                <BaseSpaceSeparator size={10} />
                <PCText fontSize={12} lineHeight={16/12} color={Colors.greyishBrown} letterSpacing={0}>
                    {formatAddress(type === "parkingSpot" ? spotInfo: startStopParkingInfo, true)}
                </PCText>
            </div>
            <div
                style={{
                    width: 80,
                    borderTopRightRadius: 25,
                    borderBottomRightRadius: 25,
                    backgroundColor: Colors.light_royal_blue,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                {!!price && (
                    <>
                        <PCText semibold color={Colors.white} letterSpacing={0} fontSize={32} lineHeight={40/32}>
                            {price}
                        </PCText>
                        <PCText semibold color={Colors.very_light_pink} fontSize={14} letterSpacing={0} lineHeight={20/14}>
                            {type === "parkingSpot" ? Resources.zl : Resources.zl_h}
                        </PCText>
                    </>
                )}
                {!price && (
                    <PCText semibold color={Colors.very_light_pink} fontSize={14} letterSpacing={0} lineHeight={20/14}>
                        {Resources.darmo}
                    </PCText>
                )}
            </div>
        </div>
    )
}

export default (props: Props) => {
    const {onChosen, targets} = props;

    const onAsk = () => {
    
    }

    return (
        <div
            style={{
                height: 750,
                width: 300
            }}
        >
            <div style={{height: 35}}>
                <PCText fontSize={24} semibold color={Colors.black}>
                    {Resources.Wyniki_wyszukiwania}
                </PCText>
            </div>
            <BaseSpaceSeparator size={9} />
            <div
                style={{
                    width: 300,
                    height: 706,
                    boxSizing: "border-box",
                    boxShadow: "0 3px 26px 4px rgba(0, 0, 0, 0.08)",
                    backgroundColor: Colors.white,
                    borderRadius: 25,
                    paddingTop: 20,
                    paddingBottom: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative'
                }}
            >
                {!targets.length && <NoPlaceFound onAsk={onAsk} />}
                {!!targets.length && (
                    <PCScrollbars height={605} contentContainerStyle={{paddingLeft: 20, paddingRight: 20, paddingTop: 30, paddingBottom: 30}}>
                        {targets.map((item, index, arr) => {
                            return (
                                <React.Fragment
                                    key={item.getId()}
                                >
                                    <Item onChosen={onChosen} target={item} />
                                    {index !== arr.length - 1 && <BaseSpaceSeparator size={30} />}
                                </React.Fragment>
                            );
                        })}
                    </PCScrollbars>
                )}  
                <ListGradient />          
            </div>
        </div>
    )
}