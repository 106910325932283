import { ErrorCode, IVehicleExDto, ServiceConfig, VehicleDto, VehiclesService } from "parkcash-api";
import Resources from "../../Resources";
import errorPayload from "../redux/ErrorPayload";
import simplePayload from "../redux/SimplePayload";
import ThunkFunction from "../redux/ThunkFunction";
import Notify from "../utils/Notify";

export type VehiclesActionTypes = "GettingVehicles" | "GettingVehiclesSuccess" | "GettingVehiclesError";

export interface initVeviclesChoosingPayload extends simplePayload {
    spotId: string; 
    arriving: Date; 
    leaving: Date;
}

export interface gettingVehiclesSuccessPayload extends simplePayload {
    vehicles: IVehicleExDto[]
}

export const getVehicles = (forceRefresh: boolean = false, after: (vehicles: IVehicleExDto[]) => void = () => {}): ThunkFunction => async (dispatch, getState) => {
    const {vehicles} = getState().vehicles;
    if(vehicles && !forceRefresh){
        after(vehicles);
        return;
    }
    
    try{
        dispatch(<simplePayload>{
            type: "GettingVehicles"
        });
        const {token} = getState().user;
        const response = await new VehiclesService(new ServiceConfig({jwt: token})).getUserVehicles();
        if(response.isSuccess){
            dispatch(<gettingVehiclesSuccessPayload>{
                type: "GettingVehiclesSuccess",
                vehicles: response.result
            });
            after(response.result);
        }
        else{
            dispatch(<errorPayload>{
                type: "GettingVehiclesError",
                errorCode: response.error.code
            });
        }
    }
    catch{
        dispatch(<errorPayload>{
            type: "GettingVehiclesError",
            errorCode: ErrorCode.NetworkError
        });
    }
}

export interface vehiclesState {
    gettingVehiclesProgress: boolean;
    gettingVehclesError: ErrorCode;
    vehicles: IVehicleExDto[];
}

const initialState: vehiclesState = {   
    gettingVehclesError: null,
    gettingVehiclesProgress: false,
    vehicles: null
};

export default function(state: vehiclesState = initialState, action: simplePayload): vehiclesState {
    if(action.type === "Logout"){
        return {...initialState};
    }
    
    switch(action.type){
        
        case "GettingVehicles":
            return {
                ...state,
                gettingVehclesError: null,
                gettingVehiclesProgress: true,
                vehicles: null
            }
        case "GettingVehiclesSuccess":
            return {
                ...state,
                gettingVehclesError: null,
                gettingVehiclesProgress: false,
                vehicles: (action as gettingVehiclesSuccessPayload).vehicles
            }
        case "GettingVehiclesError":      
            return {
                ...state,
                gettingVehclesError: (action as errorPayload).errorCode,
                gettingVehiclesProgress: false,
                vehicles: null
            }  
        default:
            return state;
    }
}