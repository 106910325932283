import React from 'react';
import {IErrorWrapperOfObject} from "parkcash-api";
import Stack from "@mui/material/Stack";
import Spinner from "../Spinner";
import Typography from "@mui/material/Typography";
import Resources from "../../../Resources";
import ErrorsView from "./ErrorView/ErrorView";

interface Props {
    children: React.ReactNode;
    loading: boolean;
    errors: Array<IErrorWrapperOfObject> | null
    data: boolean
}

export const LoadingErrorAndContentWrapper = ({
                                                  loading,

                                                  children,
                                                  data,
                                                  errors
                                              }: Props) => {

    const showError = errors?.filter(e => !!e)?.length > 0;
    return (<>
        {loading && 
            <Stack 
                direction={'row'} 
                justifyContent={'center'} 
                alignItems={'center'}
                sx={{height: '100vh'}}>
            <Spinner size={'extra-large'}/>
        </Stack>}
        {(!loading && showError) && 
            <ErrorsView 
                errors={errors}/>}
        {(!loading && !showError && !data) &&
            <Stack 
                direction={'row'} 
                justifyContent={'center'} 
                alignItems={'center'}
                sx={{height: '100%'}}>
            <Typography variant={'h3'} justifyContent={'center'}>{Resources.Brak_danych}</Typography>
        </Stack>}
        {(!loading && !showError && !!data) && 
            children
        }
    </>)
}
