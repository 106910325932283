import * as React from "react";
import Resources from "../../../Resources";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { useWindowSize } from "../../utils/WindowSizeHook";

const dark_blue_square = require("../../../assets/images/authentication/dark_blue_square.svg");
const pink_triangle = require("../../../assets/images/authentication/pink_triangle.svg");
const white_circle = require("../../../assets/images/authentication/white_circle.svg");
const white_oval = require("../../../assets/images/authentication/white_oval.svg");

const pink_oval = require("../../../assets/images/authentication/pink_oval.svg");
const light_blue_oval = require("../../../assets/images/authentication/light_blue_oval.svg");

const dev1 = require("../../../assets/images/authentication/dev-1.png");
const dev2 = require("../../../assets/images/authentication/dev-2.png");
const dev3 = require("../../../assets/images/authentication/dev-3.png");

const apple = require("../../../assets/images/authentication/apple.svg");
const google_play = require("../../../assets/images/authentication/google_play.svg");

const ShopButton = (props: {
    image: any,
    href: string,
    shop: string
}) => {
    return (
        <a
            style={{
                backgroundColor: Colors.light_royal_blue,
                borderRadius: 6,
                border: `solid 1px ${Colors.white}`,
                width: 128,
                padding: 0,
                paddingLeft: 10,
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 0,
                display: 'flex',
                alignItems: 'center',
                textDecoration: "none"
            }}
            target="_blank"
            href={props.href}
        >   
            <img src={props.image} width={22} height={22} />
            <BaseSpaceSeparator size={10}/>
            <div>
                <PCText color={Colors.white} letterSpacing={0} fontSize={10}>
                    {Resources.Do_pobrania_z}
                </PCText>
                <PCText color={Colors.white} letterSpacing={0} fontSize={17}>
                    {props.shop}
                </PCText>
            </div>
        </a>
    );
}

const MainText = () => {
    const biur = <PCText fontWeight="bold" semibold inline color={Colors.heliotrope} fontSize={34}>{Resources.biur}</PCText>;
    const osiedli = <PCText fontWeight="bold" semibold inline color={Colors.heliotrope} fontSize={34}>{Resources.osiedli}</PCText>;
    const darmowe_konto = <PCText fontSize={20} inline semibold fontWeight={600} color={Colors.white} textDecoration="underline">{Resources.darmowe_konto}</PCText>; 
    return (
        <div style={{position: 'absolute', left: 100, top: 100, zIndex: 100}}>
            <div style={{width: 375}}>
                <PCText
                    color={Colors.white}
                    fontSize={34}
                    lineHeight={1.18}
                >
                    {Resources.Platforma_wspoldzielenia_miejsc_parkingowych} <br /> {Resources.dla} {biur} {Resources.i} {osiedli}.
                </PCText>
            </div>
            <BaseSpaceSeparator size={10}/>
            <div style={{width: 375, height: 80, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <PCText
                    color={Colors.white}
                    fontSize={20}
                >
                    {Resources.Zaloz} {darmowe_konto} {Resources.i_zobacz_jak_działa_aplikacja}
                </PCText>
            </div>
            <BaseSpaceSeparator size={10}/>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <ShopButton
                    href="https://apps.apple.com/pl/app/parkcash-io/id1468303821?l=pl"
                    image={apple}
                    shop="App Store"
                />
                <BaseSpaceSeparator size={25} />
                <ShopButton
                    href="https://play.google.com/store/apps/details?id=com.parkcashmobile&hl=pl"
                    image={google_play}
                    shop="Google play"
                />
            </div>
        </div>
    )
}

export default () => {
    const {windowHeight} = useWindowSize();
    return (
        <div 
            
            style={{
                position: 'relative',
                width: 800,
                height: '100%',
                backgroundColor: Colors.light_royal_blue,
                overflow: 'hidden'
            }}
        >
            <MainText />
            <img src={white_oval} height={307} width={347} style={{position: 'absolute', top: 0, left: 0, zIndex: 1}}/>
            <img src={dark_blue_square} height={49} width={49} style={{position: 'absolute', top: 88, right: 99, zIndex: 1}}/>

            <img src={pink_triangle} width={62} height={46} style={{position: 'absolute', top: 338, right: 153}}/>
            <img src={white_circle} width={18} height={18} style={{position: 'absolute', top: 378, right: 324}}/>
            <img src={pink_triangle} width={22} height={16} style={{position: 'absolute', top: 428, left: 111}}/>

            {windowHeight >= 885 && (
                <>
                    <img src={dev2} width={160} height={338} style={{position: 'absolute', bottom: 142, left: 195, zIndex: 100}}/>
                    <img src={dev1} width={200} height={422} style={{position: 'absolute', bottom: 100, left: 300, zIndex: 200}}/>
                    <img src={dev3} width={160} height={338} style={{position: 'absolute', bottom: 142, left: 445, zIndex: 100}}/>

                    <img src={light_blue_oval} width={253} height={226} style={{position: 'absolute', bottom: 290, left: 130, zIndex: 50}}/>
                    <img src={white_circle} width={35} height={35} style={{position: 'absolute', bottom: 465, left: 215, zIndex: 60}}/>

                    <img src={pink_oval} width={484} height={430} style={{position: 'absolute', bottom: 50, right: 140, zIndex: 50}}/>
                    <img src={white_circle} width={45} height={45} style={{position: 'absolute', bottom: 80, right: 280, zIndex: 60}}/>
                </>
            )}

            <img src={dark_blue_square} height={24} width={24} style={{position: 'absolute', bottom: 30, left: 144, zIndex: 1}}/>  
        </div>
    )
}