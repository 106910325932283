import { faCalendarAlt, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import FormLabel from "../../components/FormLabel";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";

export const TimePresenter = (props: {text: string, icon: "calendar" | "clock", label: string}) => {
    const {text, icon, label} = props;
    return (
        <div>
            <FormLabel>{label}</FormLabel>
            <BaseSpaceSeparator size={5} />
            <div style={{display: 'flex', alignItems: 'center'}}>
                <FontAwesomeIcon color={Colors.light_royal_blue} style={{fontSize: 16}} icon={icon === "calendar" ? faCalendarAlt : faClock}/>
                <BaseSpaceSeparator size={10} />
                <PCText color={Colors.light_royal_blue} fontSize={14} >
                    {text}
                </PCText> 
            </div>
        </div>
    )
}