import { FormikHelpers } from "formik";
import { ErrorCode, ManagerAssignPilotToUserDto, ParkingManagerService, ServiceConfig } from "parkcash-api";
import * as React from "react";
import * as yup from "yup";
import Resources from "../../../Resources";
import RegularDateTimePicker from "../../components/forms/RegularDateTimePicker";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import ModalForm from "../../components/ModalForm";
import Colors from "../../styles/Colors";
import { addDays, addMonth } from "../../utils/DateTimeUtils";
import { getErrorMessage } from "../../utils/ErrorUtils";
import { useJWT } from "../../utils/JWTUtils";
import Notify from "../../utils/Notify";
import {Asserts} from "yup";


const pilot = require("../../../assets/images/pilot.svg");

const validationSchema = yup.object({
    pilot: yup.string().required(Resources.Wymagane),
    user: yup.string().required(Resources.Wymagane),
    validTo: yup.date().required(Resources.Wymagane)
});

interface FormState extends Asserts<typeof validationSchema> {}

export default (props: {
    visible: boolean,
    onClose: () => void,
    parkingId: string,
    onSubmitted: () => void
}) => {
    const jwt = useJWT();
    const {onClose,visible,parkingId, onSubmitted} = props;
    const [users, setUsers] = React.useState<{text: string, id: string}[]>([]);
    const [pilots, setPilots] = React.useState<{text: string, id: string}[]>([]);

    const init = async () => {
        try{
            //TODO JS
            const [usersResponse, pilotsResponse] = await Promise.all([
                await new ParkingManagerService(new ServiceConfig({jwt})).getParkingUsersView
                (
                    parkingId,
                    "",
                    "",
                    1,
                    10000
                ),
                await new ParkingManagerService(new ServiceConfig({jwt})).getParkingMobilePilotsList(parkingId)
            ]);
            if(usersResponse.isSuccess && pilotsResponse.isSuccess){
                setUsers(usersResponse.result.entries.map(e => ({
                    text: `${e.firstName} ${e.lastName} (${e.email})`,
                    id: e.userId
                })));
                setPilots(pilotsResponse.result.entries.map(e => ({
                    id: e.id,
                    text: e.friendlyName
                })));
            }
            else{
                setPilots([]);
                setUsers([]);
            }
        }
        catch{
            setPilots([]);
            setUsers([]);
        }
    }

    React.useEffect(() => {
        init();
    }, [parkingId]);


    const initialValues: FormState = {
        pilot: "",
        user: "",
        validTo: addMonth(new Date(), 6)
    }
   

    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {
        const {pilot, validTo, user} = state;
        const {setSubmitting, setStatus} = helpers;
        try{
            setSubmitting(true);
            const response = await new ParkingManagerService(new ServiceConfig({jwt})).assignPilotToUser(pilot, new ManagerAssignPilotToUserDto({
                userId: user,
                accessFrom: addDays(new Date(), -1),
                accessTo: new Date(validTo.getFullYear(), validTo.getMonth(), validTo.getDate())
            }));
    
            if(response.isSuccess){
                onSubmitted();
                Notify.Success(Resources.Dostep_przydzielony);
            }
            else{
                if(response.error.code === ErrorCode.VirtualPilotAlreadyAssignedToUser){
                    setStatus(Resources.Uzytkownik_posiada_juz_dostep_do_tego_pilota);
                }
                else{
                    setStatus(getErrorMessage(response.error.code));
                }
            }
        }
        catch(e){
            const error = e;
            setStatus(getErrorMessage());
        }
        finally{
            setSubmitting(false);
        }
    }

    return (
        <ModalForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            icon={pilot}
            iconWidth={100}
            iconHeight={114}
            visible={visible}
            onClose={onClose}
            
            title={Resources.Przydziel_mobilnego_pilota}
        >
        {args => {
            const {values, errors, touched, setFieldTouched, setFieldValue} = args;

            console.log('values: ', values);
            console.log('errors: ', errors);
            console.log('touched: ', touched);

            return (
                <React.Fragment>
                    <RegularDropdownList 
                        value={values.pilot}
                        error={errors.pilot}
                        touched={touched.pilot}
                        name="pilot"
                        actions={pilots}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        placeholder={Resources.Wybierz_pilota}
                        zIndex={3}
                        borderColor={Colors.brownish_grey}
                        variant="standard"
                    />
                    <RegularDropdownList 
                        value={values.user}
                        error={errors.user}
                        touched={touched.user}
                        name="user"
                        actions={users}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        placeholder={Resources.Wybierz_uzytkownika}
                        borderColor={Colors.brownish_grey}
                        variant="standard"
                    />
                    <RegularDateTimePicker 
                        value={values.validTo}
                        error={errors.validTo}
                        touched={touched.validTo}
                        name="validTo"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        mode="date"
                        label={Resources.Data_waznosci}
                    />
                </React.Fragment>
            );
        }}
        </ModalForm>
    );
}