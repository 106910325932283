import {
  ErrorCode,
  ManagerModifySubscriptionPurchaseDto,
  PaymentMethod,
  ServiceConfig,
  SubscriptionsService,
} from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import ModalForm from "../../components/ModalForm";
import * as yup from "yup";
import { CURRENCY_REGEX, PHONE_MASK } from "../../utils/Constants";
import RegularInput from "../../components/forms/RegularInput";
import { transformLicencePlateNumber } from "../../utils/LicencePlateNumberUtils";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import Colors from "../../styles/Colors";
import InitialProgress from "../../other-pages/start-stop/InitialProgress";
import ErrorView from "../../components/ErrorView";
import { getErrorMessage } from "../../utils/ErrorUtils";
import { FormikHelpers } from "formik";
import { parseFloatString } from "../../utils/NumberUtils";
import {
  getClientPhoneNumber,
  getServerPhoneNumber,
} from "../../utils/PhoneNumberUtils";

const subscription_type = require("../../../assets/images/subscription_type.svg");

const validationSchema = yup.object({
  externalPaymentDocumentNumber: yup.string().notRequired().nullable(),
  licencePlateNumber: yup
    .string()
    .notRequired()
    .nullable()
    .when("licencePlateNumberRequired", {
      is: true,
      then: yup.string().required(Resources.Wymagane),
    }),
  paymentMethod: yup.number().required(Resources.Wymagane),
  phoneNumber: yup.string().nullable(),
  name: yup.string().required(Resources.Wymagane),
  price: yup
    .string()
    .required(Resources.Wymagane)
    .matches(CURRENCY_REGEX, Resources.Nieprawidlowy_format),
});

interface FormState {
  externalPaymentDocumentNumber: string;
  licencePlateNumber: string;
  paymentMethod: PaymentMethod;
  phoneNumber: string;
  name: string;
  price: string;
  licencePlateNumberRequired: boolean;
}

export default (props: {
  visible: boolean;
  onClose: () => void;
  onSubmitted: () => void;
  purchaseId: string;
  jwt: string;
  parkingId: string;
}) => {
  const { onClose, onSubmitted, purchaseId, visible, jwt, parkingId } = props;
  const [progress, setProgress] = React.useState(false);
  const [error, setError] = React.useState<ErrorCode>(null);
  const [initialState, setInitialState] = React.useState<FormState>({} as any);

  const onSubmit = async (
    state: FormState,
    { setSubmitting, setStatus }: FormikHelpers<FormState>
  ) => {
    try {
      setSubmitting(true);
      const { isSuccess, error } = await new SubscriptionsService(
        new ServiceConfig({ jwt })
      ).managerModifySubscriptionPurchase(
        new ManagerModifySubscriptionPurchaseDto({
          name: state.name,
          paymentMethod: state.paymentMethod,
          price: parseFloatString(state.price),
          subscriptionPurchaseId: purchaseId,
          externalPaymentDocumentNumber: state.externalPaymentDocumentNumber,
          licencePlateNumber: state.licencePlateNumber || "",
          phoneNumber: getServerPhoneNumber(state.phoneNumber),
        })
      );
      if (isSuccess) {
        onSubmitted();
      } else {
        setStatus(getErrorMessage());
      }
    } catch (e) {
      const error = e;
      setStatus(getErrorMessage());
    } finally {
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    const getPurchase = async () => {
      try {
        setProgress(true);
        const config = new ServiceConfig({ jwt });
        const { isSuccess, error, result } = await new SubscriptionsService(
          config
        ).getSubscriptionPurchaseModificationInfo(purchaseId);
        if (isSuccess) {
          const {
            externalPaymentDocumentNumber,
            licencePlateNumber,
            paymentMethod,
            phoneNumber,
            name,
            price,
          } = result;
          setInitialState({
            externalPaymentDocumentNumber: externalPaymentDocumentNumber || "",
            licencePlateNumber,
            name,
            paymentMethod,
            phoneNumber: getClientPhoneNumber(phoneNumber),
            price: price.toString(),
            licencePlateNumberRequired: true,
          });
        } else {
          setError(error.code);
        }
      } catch {
        setError(ErrorCode.NetworkError);
      } finally {
        setProgress(false);
      }
    };
    if (purchaseId) {
      getPurchase();
    }
  }, [parkingId, purchaseId, jwt]);

  return (
    <ModalForm
      icon={subscription_type}
      iconHeight={105}
      iconWidth={120}
      visible={visible}
      onClose={onClose}
      title={Resources.Edytuj_zakup_abonamentu}
      enableReinitialize
      initialValues={initialState}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      zIndex={1999}
    >
      {({ values, errors, touched, setFieldTouched, setFieldValue }) => {
        if (progress) {
          return <InitialProgress />;
        }
        if (error) {
          return <ErrorView title={getErrorMessage(error)} />;
        }

        return (
          <>
            <RegularInput
              value={values.name}
              touched={touched.name}
              error={errors.name}
              name="name"
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              showValidatedButton
              showClearButton
            />
            {values.licencePlateNumberRequired && (
              <RegularInput
                label={Resources.Nr_rejestracyjny}
                transformText={transformLicencePlateNumber}
                value={values.licencePlateNumber}
                error={errors.licencePlateNumber}
                touched={touched.licencePlateNumber}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                name="licencePlateNumber"
                showValidatedButton
                showClearButton
              />
            )}
            <RegularInput
              label={Resources.Nr_telefonu}
              value={values.phoneNumber}
              error={errors.phoneNumber}
              touched={touched.phoneNumber}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              name="phoneNumber"
              showValidatedButton
              showClearButton
              mask={PHONE_MASK}
            />
            <RegularInput
              label={Resources.Cena + `(${Resources.zl})`}
              error={errors.price}
              value={values.price}
              touched={touched.price}
              name="price"
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
            />
            <RegularInput
              label={Resources.Nr_dokumentu}
              value={values.externalPaymentDocumentNumber}
              error={errors.externalPaymentDocumentNumber}
              touched={touched.externalPaymentDocumentNumber}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              name="externalPaymentDocumentNumber"
              showClearButton
              showValidatedButton
            />
            <RegularDropdownList
              value={values.paymentMethod}
              error={errors.paymentMethod}
              touched={touched.paymentMethod}
              name="paymentMethod"
              actions={[
                { id: PaymentMethod.BankTransfer, text: Resources.przelew },
                { id: PaymentMethod.Cash, text: Resources.gotowka },
                {
                  id: PaymentMethod.CreditCard,
                  text: Resources.karta_platnicza,
                },
              ]}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              label={Resources.Sposob_platnosci}
              borderColor={Colors.brownish_grey}
            />
          </>
        );
      }}
    </ModalForm>
  );
};
