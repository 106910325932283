import * as React from "react";
import { connect } from "react-redux";
import Resources from "../../../../../Resources";
import { applicationState } from "../../../../redux/ApplicationState";
import Colors from "../../../../styles/Colors";
import { BaseSpaceSeparator } from "../../../../styles/Separators";
import { PCText } from "../../../../styles/Texts";
import { PCClasses } from "../../../../utils/CSSUtils";
import { IParkCashApplicationMode } from "../../../../utils/ParkCashApplicationMode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faParking } from "@fortawesome/free-solid-svg-icons";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { History } from "history";
import { setCurrentAdminParking } from "../../../../user/Redux";
import Dropdown, { DropdownAction } from "../../../../components/Dropdown";

const TRIANGLE_WIDTH = 40;

const TRIANGLE_HEIGHT = 20;

const CONTENT_WIDTH = 210;

const CONTENT_OFFSET = 26;

const ANIMATION_DURATION = 200;

interface OwnProps {
  scrolled: boolean;
}

interface Props extends OwnProps, RouteComponentProps<any> {
  applicationMode: IParkCashApplicationMode;
  userName: string;
  userSurname: string;
  onParkingChanged: (id: string, history: History) => void;
}

class UserDropdown extends React.Component<Props> {
  private onParkingSelected = (parkingId: string) => {
    const { onParkingChanged, history, applicationMode } = this.props;
    const currentParkingId =
      applicationMode.getCurrentParking()?.parkingId || null;
    if (parkingId !== currentParkingId) {
      onParkingChanged && onParkingChanged(parkingId, history);
    }
  };

  private getMenuItems(): DropdownAction[] {
    const result: DropdownAction[] = [];
    const { applicationMode } = this.props;
    if (!applicationMode.isSuperAdmin()) {
      result.push({ text: Resources.Moj_profil, to: "/profile" });
    }

    if (applicationMode.isParkingManager()) {
      const currentParking = applicationMode.getCurrentParking();
      result.push({
        text: Resources.Moje_parkowanie,
        onClick: () => this.onParkingSelected(null),
        selected: !currentParking,
      });

      applicationMode.getParkings().forEach((parking) => {
        const { friendlyName, parkingId } = parking;
        result.push({
          text: `${friendlyName}`,
          onClick: () => this.onParkingSelected(parkingId),
          selected: parkingId === currentParking?.parkingId,
        });
      });
    }

    result.push({ text: Resources.Pomoc, href: "https://parkcash.io/help" });
    result.push({ text: Resources.Kontakt, href: "https://help.parkcash.io/contact-pl" });
    result.push({ text: Resources.Wyloguj, to: "/logout" });
    return result;
  }

  render() {
    const { applicationMode, scrolled, userName, userSurname } = this.props;

    const firstLetter =
      userName && userName[0] ? userName[0]?.toUpperCase() : "";
    const secondLetter =
      userSurname && userSurname[0] ? userSurname[0]?.toUpperCase() : "";

    const firstText = applicationMode.isSuperAdmin()
      ? Resources.Administrator
      : `${userName} ${userSurname}`;
    const lastText = applicationMode.isSuperAdmin()
      ? ""
      : applicationMode.isInParkingManagementMode()
      ? applicationMode.getCurrentParking().friendlyName
      : Resources.Profil_indywidualny;
    const initials = applicationMode.isSuperAdmin()
      ? "A"
      : applicationMode.isInParkingManagementMode()
      ? "P"
      : `${firstLetter}${secondLetter}`;

    return (
      <Dropdown
        actions={this.getMenuItems()}
        contentWidth={270}
        maxHeight={500}
      >

        <div className={PCClasses("pc-desktopheader-userdropdown")}>
          <PCText color={Colors.black} semibold fontSize={18} textAlign="right">
            {firstText}
            <br />
            <PCText
              inline
              color={Colors.brownish_grey}
              semibold
              fontSize={18}
              textAlign="right"
            >
              {lastText}
            </PCText>
          </PCText>
          <BaseSpaceSeparator size={20} />
          {!applicationMode.isInParkingManagementMode() && (
            <div
              className={PCClasses("pc-desktopheader-userdropdown-initials")}
              style={{
                width: scrolled ? 50 : 55,
                height: scrolled ? 50 : 55,
              }}
            >
              <PCText
                inline
                textAlign="center"
                semibold
                color={Colors.white}
                fontSize={28}
              >
                {initials}
              </PCText>
            </div>
          )}
          {applicationMode.isInParkingManagementMode() && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 50,
                height: 50,
              }}
            >
              <FontAwesomeIcon
                size={"3x"}
                icon={faParking}
                color={Colors.light_royal_blue}
              />
            </div>
          )}
          <BaseSpaceSeparator size={15} />
          <FontAwesomeIcon
            color={Colors.light_royal_blue}
            fontSize={20}
            icon={faChevronDown}
          />
        </div>
      </Dropdown>
    );
  }
}

const mapStateToProps = (
  state: applicationState,
  ownProps: OwnProps
): Partial<Props> => ({
  ...ownProps,
  applicationMode: state.user.applicationMode,
  userName: state.user?.firstName,
  userSurname: state.user?.lastName,
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
  onParkingChanged: (id, history) =>
    dispatch(setCurrentAdminParking(id, history)),
});

const exported: React.ComponentClass<OwnProps> = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserDropdown) as any
) as any;

export default exported;
