import {
    CreateReservationPolicyDto,
    ICreateReservationPolicyDto,
    IGetParkingReservationPolicyResult,
    IUpdateReservationPolicyDto,
    ReservationPolicyType,
    SpotSelectionType,
    StandardResponseWrapperOfGetParkingReservationPolicyResultAndErrorWrapperOfObject,
    UpdateReservationPolicyDto
} from "parkcash-api";
import ModalForm from "../../../components/ModalForm";
import Resources from "../../../../Resources";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import {FormikHelpers} from "formik";
import {useUser} from "../../../user/UseUserHook";
import {SwitchWithLabel} from "../../../components/Switch";
import {mapEnumToSelectOptions} from "../../../utils/EnumUtils/MapEnumToSelectOptions";
import {
    mapSpotSelectionTypeToDisplayText
} from "../../../utils/EnumUtils/ParkingConfigRelatedEnums/SpotSelectionTypeHelpers";
import {
    MapPolicyTypeEnumToLocalizedText,
} from "../../../utils/EnumUtils/ParkingConfigRelatedEnums/ReservationPolicyTypeHelpers";
import Colors from "../../../styles/Colors";
import RegularDropdownList from "../../../components/forms/RegularDropdownList";
import RegularInput from "../../../components/forms/RegularInput";
import {BaseSpaceSeparator} from "../../../styles/Separators";
import {ParkingsService, ServiceConfig} from "parkcash-api";
import {
    ReservationPolicyPredefinedPeriodsSection
} from "../SpecialFormSections/ReservationPolicyPredefinedPeriodsSection";
import {
    useCustomizedNotification
} from "../../../MUI/components/AddedOrModyfiedMuiComponents/CustomizedNotification/useCustomizedNotification";
import {PolicyFormValues} from "./policyFormTypes";
import {getPolicyFormInitialValues, mamFormValuesToDtoValues} from "./parkingPolicyFormUtils";
import {useFetch} from "../../../MUI/hooks/useFetch";
import {LoadingErrorAndContentWrapper} from "../../../components/Common/LoadingErrorAndContentWrapper";
import {TimeSpanPickerWithFormik} from "../../../components/forms/timeSpanPickerWithFormik";

interface Props {
    visible: boolean;
    onClose: () => void;
    parkingId: string;
    parkingFriendlyName: string;
    onSubmitted?: () => void;
}

export const ParkingPolicyForm = ({
                                      visible,
                                      onClose,
                                      parkingId,
                                      parkingFriendlyName,
                                      onSubmitted

                                  }: Props) => {
    const {
        applicationMode,
        token: jwt
    } = useUser();
    const {
        enqueError,
        enqueSuccess
    } = useCustomizedNotification();
    const fetchFunction = useCallback(async (): Promise<StandardResponseWrapperOfGetParkingReservationPolicyResultAndErrorWrapperOfObject> | null => {
        return await new ParkingsService(new ServiceConfig({jwt})).getParkingReservationPolicy(parkingId);
    }, [
        jwt,
        parkingId
    ])
    const {
        data: initialFormDataSource,
        loading,
        error,
        fetch
    } = useFetch<IGetParkingReservationPolicyResult>(fetchFunction,  (data) => {
        setinitialFormValues(getPolicyFormInitialValues(data))
    });
    const [initialFormValues, setinitialFormValues] = useState(getPolicyFormInitialValues(initialFormDataSource));
    const policyTypeSelectOptions = mapEnumToSelectOptions(ReservationPolicyType,
        MapPolicyTypeEnumToLocalizedText,
        false,
    );
    const spotSelectionTypeOptions = mapEnumToSelectOptions(SpotSelectionType,
        mapSpotSelectionTypeToDisplayText,
        false
    );
    const isUpdateMode = initialFormDataSource !== undefined && initialFormDataSource !== null && initialFormDataSource.isDefault === false;
    const formTitle = isUpdateMode ? Resources.Edytuj_polityke_rezerwacji : Resources.Dodaj_polityke_rezerwacji;
    useEffect(() => {
        if (visible && parkingId) {
            fetch();
        }
    }, [visible, parkingId]);
    return (<ModalForm
        initialValues={initialFormValues}
        iconWidth={90}
        width={"500px"}
        iconHeight={110}
        visible={visible}
        onClose={onClose}
        title={formTitle}
        subtitle={!isUpdateMode ? Resources.Parking_nie_posiada_polityki_rezerwacji : ""}
        headerTitle={parkingFriendlyName}
        enableReinitialize={true}
        onSubmit={async (values: PolicyFormValues, actions: FormikHelpers<PolicyFormValues>) => {
            console.log("formikValues", values);
            if (!isUpdateMode) {
                const iDto: ICreateReservationPolicyDto = {
                    ...mamFormValuesToDtoValues(values),
                    parkingId: parkingId,
                    isDefault: false
                }
                const dto: CreateReservationPolicyDto = new CreateReservationPolicyDto(iDto);
                try {
                    const addResult = await new ParkingsService(new ServiceConfig({jwt})).createReservationPolicy(dto);
                    enqueSuccess(Resources.Dane_zostaly_pomyslnie_zapisane);
                    if (!addResult.isSuccess) {
                        throw new Error("An Errorr during saving occured")
                    }
                    onClose();
                    if (onSubmitted) {
                        onSubmitted();
                    }

                } catch (e) {
                    enqueError(Resources.Wystapil_blad_podczas_zapisu_danych)
                }
            } else {
                const iDto: IUpdateReservationPolicyDto = {
                    ...mamFormValuesToDtoValues(values),
                    id: initialFormDataSource.id,
                    parkingId: parkingId,
                    isDefault: false
                }
                const dto: UpdateReservationPolicyDto = new UpdateReservationPolicyDto(iDto);
                try {
                    const updateResult = await new ParkingsService(new ServiceConfig({jwt})).updateReservationPolicy(dto);
                    if (!updateResult.isSuccess) {
                        throw new Error("An Errorr during saving occured")
                      
                    }
                    enqueSuccess(Resources.Dane_zostaly_pomyslnie_zapisane);
                    onClose();
                    if (onSubmitted) {
                        onSubmitted();
                    }
                } catch (e) {
                    onClose();
                    enqueError(Resources.Wystapil_blad_podczas_zapisu_danych)
                }
            }
        }}
    >
        {({
              setFieldTouched,
              setFieldValue,
              values,
              errors,
              touched
          }) => <Stack>
            <LoadingErrorAndContentWrapper loading={loading} errors={[error]} data={!!initialFormDataSource}>

                <TimeSpanPickerWithFormik
                    label={Resources.Minimalna_dlugosc_rezerwacji} name={'minDuration'}
                    initialValue={initialFormValues?.minDuration}/>


                <TimeSpanPickerWithFormik
                    label={Resources.Maksymalna_dlugosc_rezerwacji} name={'maxDuration'}
                    initialValue={initialFormValues?.maxDuration}/>
                <TimeSpanPickerWithFormik
                    label={Resources.Maksymalna_dlugosc_rezerwacji_od_teraz} name={'maxDurationFromNow'}
                    initialValue={initialFormValues?.maxDurationFromNow}/>

                <TimeSpanPickerWithFormik label={Resources.Maksymalny_Okres_wyprzedzenia_pozwalajacy_na_rezerwacje}
                                          name={'maxReservationAdvance'}
                                          initialValue={initialFormValues?.maxReservationAdvance}/>

                <TimeSpanPickerWithFormik label={Resources.Najpozniejszy_czas_anulowania}
                                          name={'latestCancellationTime'}
                                          initialValue={initialFormValues?.latestCancellationTime}/>

                <RegularInput
                    label={Resources.Limit_aktywnych_rezerwacji}
                    type={"number"}
                    placeholder=""
                    value={String(values.activeReservationsLimit)}
                    error={errors.activeReservationsLimit}
                    touched={touched.activeReservationsLimit}
                    name={"activeReservationsLimit"}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    showClearButton
                />

                <RegularDropdownList
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    error={errors.policyType}
                    touched={touched.policyType}
                    value={values.policyType?.toString()}
                    name="policyType"
                    actions={policyTypeSelectOptions.map((option) => ({
                        id: option.value,
                        text: option.label
                    }))}
                    borderColor={Colors.brownish_grey}
                    label={Resources.Polityka_rezerwacji}
                />
                <RegularDropdownList
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    error={errors.spotSelectionType}
                    touched={touched.spotSelectionType}
                    value={values.spotSelectionType?.toString()}
                    name="spotSelectionType"
                    actions={spotSelectionTypeOptions.map((option) => ({
                        id: option.value,
                        text: option.label
                    }))}
                    borderColor={Colors.brownish_grey}
                    label={Resources.Sposob_wyboru_miejsca}
                />
                <SwitchWithLabel
                    label={Resources.Wymagane_potwierdzenie_numeru_telefonu}
                    value={values.phoneNumberConfirmationRequired}
                    onChange={(v) => setFieldValue("phoneNumberConfirmationRequired", v)}
                />
                <SwitchWithLabel
                    label={Resources.Wymagane_potwierdzenie_adresu_email}
                    value={values.emailConfirmationRequired}
                    onChange={(v) => setFieldValue("emailConfirmationRequired", v)}
                />
                <SwitchWithLabel
                    label={Resources.Wymagane_jest_podanie_id_profilu_zewnetrznego}
                    value={values.externalProfileIdRequired}
                    onChange={(v) => setFieldValue("externalProfileIdRequired", v)}
                />
                <SwitchWithLabel
                    label={Resources.Pozwol_na_rownoczesne_rezerwacje}
                    value={values.allowConcurrentReservations}
                    onChange={(v) => setFieldValue("allowConcurrentReservations", v)}
                />
                <SwitchWithLabel
                    label={Resources.Zastosuj_dla_menadzerow}
                    value={values.applyForManagers}
                    onChange={(v) => setFieldValue("applyForManagers", v)}
                />
                <SwitchWithLabel
                    label={Resources.Pozwol_na_pojazdy_elektryczne_w_parkingu_podziemnym}
                    value={values.allowElectricVehiclesInUndergroundParkings}
                    onChange={(v) => setFieldValue("allowElectricVehiclesInUndergroundParkings", v)}
                />
                <SwitchWithLabel
                    label={Resources.Pozwol_na_pojazdy_LPG_w_parkingu_podziemnym}
                    value={values.allowLpgVehiclesInUndergroundParkings}
                    onChange={(v) => setFieldValue("allowLpgVehiclesInUndergroundParkings", v)}
                />
                <BaseSpaceSeparator size={20}/>
                <ReservationPolicyPredefinedPeriodsSection setFieldValue={setFieldValue}
                                                           setFieldTouched={setFieldTouched}
                                                           values={values} touched={touched} errors={errors}/>
            </LoadingErrorAndContentWrapper>
        </Stack>}
    </ModalForm>);

}