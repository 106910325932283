import { IVirtualPilotDto, ServiceConfig, VirtualPilotLinkOpenDto, VirtualPilotsService } from "parkcash-api";
import * as React from "react";
import {PCClasses} from "../utils/CSSUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { BaseSpaceSeparator } from "../styles/Separators";
import Colors from "../styles/Colors";
import { PCText } from "../styles/Texts";
import { StandardButton, StandardButton2 } from "../styles/Buttons";
import Resources from "../../Resources";
import Notify from "../utils/Notify";
import { applicationState } from "../redux/ApplicationState";
import { connect } from "react-redux";
import { virtualPilotRemoved } from "./Redux";
import ConfirmModal from "../components/ConfirmModal";
import GlobalProgress from "../components/GlobalProgress";
import { openVirtualPilot } from "./Utils";
import moment from "moment";
import { addDays, DateFormat, DayOfWeekFormatType, formatDateTime } from "../utils/DateTimeUtils";

const pilot = require("../../assets/images/pilot.svg");

interface OwnProps {
    item: IVirtualPilotDto,
    onShare: (id: string) => void
}

interface Props extends OwnProps {
    onPilotRemoved: (id: string) => void;
    jwt: string;

}

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const VirtualPilotsListItem = (props: Props) => {
    const {
        onShare,
        item: {
            id, 
            friendlyName, 
            description, 
            virtualPilotId, 
            expiration, 
            validFrom,
            canShare, 
            address: {city, streetName, streetNumber}, 
            isReservation
        },
        jwt,
        onPilotRemoved
    } = props;
    const [openProgress, setOpenProgress] = React.useState(false);
    const [removeProgress, setRemoveProgress] = React.useState(false);

    const now = new Date();
    const isNotValidYet = now < validFrom;
    const isExpired = !isNotValidYet && now > expiration;
    const isActive = !isNotValidYet && !isExpired;
    const isLimitless = moment.duration(moment(expiration).diff(moment(new Date()))).asMonths() > 6;

    const onRemove = async () => {
        const confirm = await ConfirmModal.show({text: Resources.Czy_na_pewno_chcesz_usunac_pilota, confirmText: Resources.Usun});
        if(confirm){
            try{
                setRemoveProgress(true);
                const response = await new VirtualPilotsService(new ServiceConfig({jwt})).removeVirtualPilotV2(virtualPilotId);
                if(response.isSuccess){
                    onPilotRemoved(id);
                }
                else{
                    Notify.Error(Resources.Blad_usuniecia_pilota);
                }
            }
            catch{
                Notify.Error(Resources.Blad_usuniecia_pilota);
            }
            finally{
                setRemoveProgress(false);
            }
        }
    }

    const onOpen = async () => {
        try{
            setOpenProgress(true);
            const result = await openVirtualPilot(virtualPilotId, id);
            if(result){
                Notify.Success(Resources.Otwarto);
            }
        }
        catch(e){
            const error = e;
            Notify.Error(Resources.Blad_otwarcia);
        }
        finally{
            setOpenProgress(false);
        }
    }

    return (
        <div className={PCClasses("pc-virtualpilotslist-item", {
            "pc-virtualpilotslist-item-inactive": !isActive
        })}>
            
            <GlobalProgress visible={removeProgress}/>
            <div className={PCClasses("pc-virtualpilotslist-item-remove")} onClick={onRemove}>
                <FontAwesomeIcon color={Colors.white} icon={faTrashAlt} style={{fontSize: 15, letterSpacing: 0.27}} />
            </div>
            <img width={70} height={80} src={pilot} />
            <BaseSpaceSeparator size={20} />
            <PCText color={Colors.brownish_grey} fontSize={28}>{description || friendlyName}</PCText>
            <PCText color={Colors.black} semibold fontSize={28}>{streetName} {streetNumber}</PCText>
            <PCText color={Colors.brownish_grey} semibold fontSize={22}>{city}</PCText>
            <div style={{flex: 1, justifyContent: 'center', display: 'flex', flexDirection: 'column'}}>
                {!isActive && (                    
                    <PCText color={Colors.black} fontSize={12}>
                        {isNotValidYet ? Resources.Aktywny_od : Resources.Waznosc_uplynela} <PCText inline semibold color={Colors.black} fontSize={12}>{formatDateTime(isNotValidYet ?  validFrom : expiration, DateFormat.WthoutYear, DayOfWeekFormatType.None)}</PCText>
                    </PCText>
                )}
                {isActive && !isLimitless && (
                    <PCText color={Colors.black} fontSize={12}>
                        {Resources.Aktywny_do} <PCText inline semibold color={Colors.black} fontSize={12}>{formatDateTime(expiration, DateFormat.WthoutYear, DayOfWeekFormatType.None)}</PCText>
                    </PCText>
                )}
            </div>
            <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                <div style={{flex: 1}}>
                    <StandardButton2 disabled={!canShare || !isActive} variant="tiny" onClick={() => onShare(virtualPilotId)}>{Resources.Udostepnij}</StandardButton2>
                </div>
                <div style={{width: 60}}/>
                <div style={{flex: 1}}>
                    <StandardButton disabled={!isActive} variant="tiny" onClick={onOpen} progress={openProgress}>{Resources.Otworz}</StandardButton>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: applicationState, ownProps: OwnProps): Partial<Props> => ({
    ...ownProps,
    jwt: state.user.token
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
    onPilotRemoved: (id) => dispatch(virtualPilotRemoved(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(VirtualPilotsListItem)