import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Link } from "react-router-dom";
import Colors from "../../styles/Colors";
import Dropdown, { DropdownAction } from "../Dropdown";

import "./ActionColumn.scss";

interface ActionSpecyficProps {
    icon: IconProp,
    iconColor?: string,
    title?: string,
    onClick?: () => void,
    to?: string
    visible?: boolean
    href?: string
    target?: "_self" | "_blank" | "_parent" | "_top"
}



export const ActionIcon = (props: ActionSpecyficProps) => {
    const {icon,onClick,to,visible = true, title,href,target = "_self", iconColor=Colors.brownish_grey} = props;

    if(!visible){
        return null;
    }

    if(href){
        return (
            <a target={target} href={href} className="pc-action-icon" onClick={onClick} title={title}>
                <FontAwesomeIcon icon={icon} color={iconColor} style={{fontSize: 20, textAlign: 'center'}}/>
            </a>
        );
    }

    if(to){
        return (
            <Link to={to} className="pc-action-icon" onClick={onClick} title={title}>
                <FontAwesomeIcon icon={icon} color={iconColor} style={{fontSize: 20, textAlign: 'center'}}/>
            </Link>
        );
    }

    return (
        <div className="pc-action-icon" onClick={onClick} title={title}>
            <FontAwesomeIcon icon={icon} color={iconColor} style={{fontSize: 24, textAlign: 'center'}}/>
        </div>
    );
    
}

export const MoreDropdown = (props: {
    actions: DropdownAction[]
}) => {
    if(!props.actions && !props.actions.length || props.actions.every(p => p.visible === false)){
        return null;
    }

    return (
        <Dropdown
            actions={props.actions}
        >
            <ActionIcon 
                icon={faEllipsisH}
            />
        </Dropdown>
    );
    
}

export default (props: {
    children: React.ReactNode
}) => {
    return (
        <div className="pc-action-column">
            {props.children}
        </div>
    )
}