import  React from 'react';
import TimeSpanPicker, {InputConfig} from "./timeSpanPicker";
import {useField} from "formik";

interface TimeSpanPickerWithFormikProps {
    label: string;
    name: string;
    initialValue?: string;
    dayInputConfig?: InputConfig;
    hourInputConfig?: InputConfig;
    minuteInputConfig?: InputConfig;
    secondInputConfig?: InputConfig;
}

export const TimeSpanPickerWithFormik = ({
                                            name, label, dayInputConfig, hourInputConfig, minuteInputConfig, secondInputConfig, initialValue
                                        }: TimeSpanPickerWithFormikProps) => {
    const [field, meta, helpers] = useField(name);
    const {setValue} = helpers;
    const isErrored: boolean = meta.touched && Boolean(meta.error);
    console.log("TimeSpanPickerFieldValue",field.value);
    return (<TimeSpanPicker
       /* error={isErrored}*/
      /*  setTouched={helpers.setTouched}*/
        label={label}
        name={name}
        dayInputConfig={dayInputConfig}
        hourInputConfig={hourInputConfig}
        minuteInputConfig={minuteInputConfig}
        secondInputConfig={secondInputConfig}
        {...field}
        {...meta}
        onChange={setValue}
        initialValue={initialValue}
    />)
}   