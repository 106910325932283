import React, {useEffect} from 'react';
import {
    ChangeValue, QuickSetDateRangeValueButton
} from "../../../../../components/Common/DateUtilsComponent/quickSetDateRangeValueRHFHelperComponent";
import {UseFormReturn} from "react-hook-form";
import Box from "@mui/material/Box";
import Resources from "../../../../../../Resources";

interface Props {
    useFormMethods: UseFormReturn<any>
    onSelectedChange: (selected: ChangeValue | null) => void
    useOwnConfig?:boolean
  /*  shouldReset: boolean;*/
}

export const DateRangeSettersButtons = ({
                                            useFormMethods,
                                            onSelectedChange,
                                         /*   shouldReset*/
                                            useOwnConfig
                                           

                                        }: Props) => {
    const [selected, setSelected] = React.useState<ChangeValue | null>('CM');
    const checkIfSelected = (value: ChangeValue) => {
        if (value === selected) {
            return true
        } else return false
    }
    useEffect(() => {
        if (useOwnConfig) {
            setSelected("OwnConfig"); // Reset selected to null when shouldReset is true
        }
    }, [useOwnConfig]);

    useEffect(() => {
        onSelectedChange(selected); // Inform the parent when selected changes
    }, [selected, onSelectedChange]);
    return (<Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 0.5,
        mb: 1,
        justifyContent: 'center'
    }}>
        <QuickSetDateRangeValueButton buttonLabel={Resources.Obecny_miesiac} changeValue={"CM"}
                                      useFormMethods={useFormMethods} selected={checkIfSelected("CM")}
                                      setSelectedInParent={setSelected}/>
        <QuickSetDateRangeValueButton buttonLabel={Resources.Poprzedni_miesiac} changeValue={"PM"}
                                      useFormMethods={useFormMethods} selected={checkIfSelected("PM")}
                                      setSelectedInParent={setSelected}/>
        <QuickSetDateRangeValueButton buttonLabel={Resources.Obecny_rok} changeValue={"CY"} useFormMethods={useFormMethods}
                                      selected={checkIfSelected("CY")} setSelectedInParent={setSelected}/>
        <QuickSetDateRangeValueButton buttonLabel={Resources.Poprzedni_rok} changeValue={"PY"}
                                      useFormMethods={useFormMethods} selected={checkIfSelected("PY")}
                                      setSelectedInParent={setSelected}/>
        <QuickSetDateRangeValueButton buttonLabel={Resources.Wlasny_zakres_dat} changeValue={"OwnConfig"}
                                      useFormMethods={useFormMethods} selected={checkIfSelected("OwnConfig")}
                                      setSelectedInParent={setSelected}/>
        
    </Box>)

}
