import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { ChangeSubscriptionDiscountCodeActivationStatusDto, GetSubscriptionsDiscountCodesViewResultEntryDto, ServiceConfig, SubscriptionsService } from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import Resources from "../../../Resources";
import DropdownList from "../../components/DropdownList";
import PCDataGrid from "../../components/PCDataGrid";
import ActionColumn, { ActionIcon } from "../../components/PCDataGrid/ActionColumn";
import StatusPresenter from "../../components/StatusPresenter";
import PCSwitch from "../../components/Switch";
import Body from "../../layouts/Main/Body";
import { validateWithUser } from "../../parkingSpots/ParkingSpotForm/Utils";
import { applicationState } from "../../redux/ApplicationState";
import { StandardButton } from "../../styles/Buttons";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { MOBILE_WIDTH_THRESHOLD } from "../../utils/Constants";
import { CustomDataSource, FilterOperator, IDataSource } from "../../utils/DataSource";
import { formatDate } from "../../utils/DateTimeUtils";
import { getErrorMessage } from "../../utils/ErrorUtils";
import Notify from "../../utils/Notify";
import { IParkCashApplicationMode } from "../../utils/ParkCashApplicationMode";
import { getFilters, getSorts } from "../../utils/SieveUtils";
import { useWindowSize } from "../../utils/WindowSizeHook";
import EmptyTable from "../EmptyTable";
import CreateSubscriptionPromotionCodeForm from "./CreateSubscriptionPromotionCodeForm";

const procent_circle = require("../../../assets/images/procent_circle.svg");

interface Props {
    jwt: string,
    applicationMode: IParkCashApplicationMode;
}

const getDataSource = (parkingId: string, jwt: string) => new CustomDataSource<GetSubscriptionsDiscountCodesViewResultEntryDto, {jwt: string, parkingId: string}>({
    load: async (loadOptions, additional) => {
        const {filter, page, pageSize, sort} = loadOptions;
        const {jwt, parkingId} = additional;

        const {isSuccess, result, error} = await new SubscriptionsService(new ServiceConfig({jwt})).getSubscriptionsDiscountCodesView
        (
            parkingId,
            getFilters(filter),
            getSorts(sort),
            page,
            pageSize
        );
        return isSuccess ? {
            items: result.entries,
            totalItems: result.paginationDto.totalElementsCount
        } : error.code;
    },
    additionalInfo: {jwt, parkingId}
})

const Container = (props: Props) => {
    const {jwt, applicationMode} = props;
    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const [dataSource, setDataSource] = React.useState<IDataSource<GetSubscriptionsDiscountCodesViewResultEntryDto>>(null);
    const grid = React.useRef<PCDataGrid<GetSubscriptionsDiscountCodesViewResultEntryDto>>(null);
    const [status, setStatus] = React.useState<"active" | "notactive" | "all">(null);
    const [subscriptions, setSubscriptions] = React.useState<{text: string, id: string}[]>([]);
    const [subscriptionFilter, setSubscriptionFilter] = React.useState<string>(null);
    const [createSubscriptionPromotionCodeFormVisible, setCreateSubscriptionPromotionCodeFormVisible] = React.useState(false);
    const [editedItem, setEditedItem] = React.useState<GetSubscriptionsDiscountCodesViewResultEntryDto>(null);

    const onAdd = () => {
        setEditedItem(null);
        setCreateSubscriptionPromotionCodeFormVisible(true);
    }

    const onEdit = (i: GetSubscriptionsDiscountCodesViewResultEntryDto) => {
        setEditedItem(i);
        setCreateSubscriptionPromotionCodeFormVisible(true);
    }

    const onStatusChanged = (s: "active" | "notactive" | "all") => {
        setStatus(s);
        if(s === "all"){
            dataSource.removeFilter("isActive");
        }
        else if(s === "active"){
            dataSource.filter("isActive", FilterOperator.EQUALS, true);
        }
        else{
            dataSource.filter("isActive", FilterOperator.EQUALS, false);
        }
    }

    const onSubscriptionFilterChanged = (s: string) => {
        setSubscriptionFilter(s);
        if(s === "all"){
            dataSource.removeFilter("subscriptionName");
        }
        else{
            dataSource.filter("subscriptionName", FilterOperator.EQUALS, s);
        }
    }

    const changeActivation = async (item: GetSubscriptionsDiscountCodesViewResultEntryDto) => {
        try{
            const response = await new SubscriptionsService(new ServiceConfig({jwt})).changeSubscriptionDiscountCodeActivationStatus(new ChangeSubscriptionDiscountCodeActivationStatusDto({
                isActive: item.isActive,
                codeId: item.id
            }));
            if(response.isSuccess){
                return true;
            }
            else{
                Notify.Error(getErrorMessage(response.error.code));
            }
        }
        catch{
            Notify.Error(getErrorMessage());
            return false;
        }
    }

    const getSubscriptions = async (parkingId: string) => {
        try{
            const {isSuccess, result} = await new SubscriptionsService(new ServiceConfig({jwt})).getParkingSubscriptionsView
            (
                parkingId,
                "",
                "",
                1,
                10000
            );
            if(isSuccess){
                setSubscriptions(result.entries.map(e => ({
                    id: e.name,
                    text: e.name
                })));
            }
            else{
                setSubscriptions([]);
            }
        }
        catch{
            setSubscriptions([]);
        }
        
    }

    React.useEffect(() => {
        const parkingId = applicationMode.getCurrentParking()?.parkingId;
        setStatus(null);
        setSubscriptionFilter(null);
        if(parkingId){
            setDataSource(getDataSource(parkingId, jwt));
        }
        else{
            setDataSource(null);
        }
        getSubscriptions(parkingId);
    }, [applicationMode.getCurrentParking()?.parkingId]);

    return (
        <Body title={Resources.Kody_rabatowe}>
            <CreateSubscriptionPromotionCodeForm 
                visible={createSubscriptionPromotionCodeFormVisible}
                onClose={() => setCreateSubscriptionPromotionCodeFormVisible(false)}
                onSubmitted={() => {
                    setCreateSubscriptionPromotionCodeFormVisible(false);
                    dataSource.reload();
                }}
                parkingId={applicationMode.getCurrentParking()?.parkingId}
                jwt={jwt}
                item={editedItem}
            />
            <div
                style={{
                    marginBottom: 20,
                    display: 'flex',
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "space-between",
                    alignItems: 'center'
                }}
            >
                <div 
                    style={{
                        display: 'flex',
                        flexDirection: isMobile ? "column" : "row",
                        width: isMobile ? '100%' : undefined
                    }}
                >
                    <div style={{width: isMobile ? '100%' : 180}}>
                        <DropdownList 
                            value={status}
                            onChange={onStatusChanged}
                            actions={[
                                {
                                    text: Resources.Aktywne,
                                    id: "active"
                                },
                                {
                                    text: Resources.Dezaktywowane,
                                    id: "notactive"
                                },
                                {
                                    text: Resources.Wszystkie,
                                    id: "all"
                                }
                            ]}
                            placeholder={Resources.Status}
                        />
                    </div>
                    <BaseSpaceSeparator size={20} />
                    <div style={{width: isMobile ? '100%' : 215}}>
                        <DropdownList 
                            value={subscriptionFilter}
                            onChange={onSubscriptionFilterChanged}
                            actions={[
                                ...subscriptions,
                                {
                                    id: "all",
                                    text: Resources.Wszystkie
                                }
                            ]}
                            placeholder={Resources.Abonament}
                        />
                    </div>
                </div>

                <BaseSpaceSeparator size={20} />

                <div style={{width: isMobile ? '100%' : 200}}>
                    <StandardButton onClick={onAdd}>{Resources.Stworz_kod}</StandardButton>
                </div>
            </div>
            <PCDataGrid<GetSubscriptionsDiscountCodesViewResultEntryDto> 
                ref={grid}
                dataSource={dataSource}
                columns={[
                    {
                        label: Resources.Kod,
                        dataField: "code",
                        width: 300,
                        renderCell: item => <PCText semibold fontSize={16} letterSpacing={0} color={Colors.black}>{item.code}</PCText>
                    },
                    {
                        label: Resources.Data_waznosci,
                        dataField: "validTo",
                        width: 150,
                        renderCell: item => <PCText semibold fontSize={16} letterSpacing={0} color={Colors.black}>{formatDate(item.validTo)}</PCText>
                    },
                    {
                        label: Resources.Kwota,
                        dataField: "discountValue",
                        width: 150,
                        renderCell: item => <PCText bold fontSize={20} letterSpacing={0} color={Colors.black}>{item.discountValue} PLN</PCText>
                    },
                    {
                        label: Resources.Typ,
                        dataField: "isOneTime",
                        width: 150,
                        renderCell: item => <PCText semibold fontSize={16} letterSpacing={0} color={Colors.greyishBrown}>{item.isOneTime ? Resources.Jednorazowy : Resources.Bez_limitu}</PCText>
                    },
                    {
                        label: Resources.Abonament,
                        dataField: "subscriptionName",
                        width: 250,
                        renderCell: item => <PCText semibold fontSize={16} letterSpacing={0} color={Colors.greyishBrown}>{item.subscriptionName}</PCText>
                    },
                    {
                        label: Resources.Status,
                        dataField: "isActive",
                        width: 150,
                        renderCell: item => <StatusPresenter textColor={Colors.white} text={item.isActive ? Resources.Aktywny : Resources.Nieaktywny} color={item.isActive ? Colors.light_royal_blue : Colors.brownish_grey} />
                    },
                    {
                        label: Resources.Ostatnie_uzycie,
                        dataField: "lastUsedAt",
                        width: 150
                    },
                    {
                        label: Resources.Akcje,
                        width: "150",
                        renderCell: item => {
                            return (
                                <ActionColumn>
                                    <ActionIcon iconColor={Colors.light_royal_blue} icon={faEdit} onClick={() => onEdit(item)} title={Resources.Edytuj} />
                                </ActionColumn>
                            )
                        }
                    },
                    {
                        label: Resources.Aktywacja,
                        width: "150",
                        renderCell: item => {
                            return (
                                <PCSwitch 
                                    value={item.isActive} 
                                    onChange={async () => {
                                        item.isActive = !item.isActive;
                                        grid.current.refresh();
                                        const success = await changeActivation(item);
                                        if(!success){
                                            item.isActive = !item.isActive;
                                            grid.current.refresh();
                                        }
                                    }}
                                />
                            )
                        }
                    }
                ]}
                EmptyComponent={() => (
                    <EmptyTable 
                        image={procent_circle}
                        imageHeight={217}
                        imageWidth={220}
                        text={Resources.Nie_stworzyles_jeszcze_kodow_rabatowych}
                        onButtonClick={onAdd}
                        buttonText={(
                            <>
                                {Resources.Stworz_kod_rabatowy}
                            </>
                        )}
                    />
                )}    
            />
        </Body>
    );
}

const mapStateToProps = (state: applicationState): Props => ({
    applicationMode: state.user.applicationMode,
    jwt: state.user.token
});

export default connect(mapStateToProps)(Container);