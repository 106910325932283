import DataSource from "./DataSource";
import { SortOrder, ErrorCode } from "parkcash-api";
import { FilterOperator } from "./IDataSource";

export interface CustomDataSourceLoadOptions<T>{
    page: number,
    pageSize: number,
    sort: Array<{key: keyof T, order: SortOrder}>;
    filter: Array<{key: keyof T, operator: FilterOperator, value: any}>;
}

 export interface CustomDataSourceLoadResult<T>{
    items: T[];
    totalItems: number;
}

export class CustomDataSource<T, E = any> extends DataSource<T>{

    private loadCore: (loadOptions: CustomDataSourceLoadOptions<T>, additionalInfo: E) => Promise<CustomDataSourceLoadResult<T> | ErrorCode>;

    private currentLoadOptions: CustomDataSourceLoadOptions<T>; 
    
    private additionalInfo: E;

    constructor(args: {
        load: (loadOptions: CustomDataSourceLoadOptions<T>, additionalInfo: E) => Promise<CustomDataSourceLoadResult<T> | ErrorCode>,
        additionalInfo?: E,
        initialSort?: Array<{key: keyof T, order: SortOrder}>,
        initialFilter?: Array<{key: keyof T, value: any, operator: FilterOperator}>
    }){
        super(args.initialSort || [], args.initialFilter || []);
        this.loadCore = args.load;
        this.additionalInfo = args.additionalInfo;
    }

    protected loadDataCore(): void {
        this.onDataSourceLoadingStarted();
        const loadOptions = {
            filter: this.currentFilter,
            page: this.currentPage,
            pageSize: this.currentPageSize,
            sort: this.currentSort
        };
        this.currentLoadOptions = loadOptions;
        this.loadCore(loadOptions, this.additionalInfo)
        .then(result => {
            if(this.currentLoadOptions !== loadOptions){
                return;
            }

            if(typeof result === "number"){
                this.onDataSourceLoadingError(result);
            }
            else{
                this.onDataSourceLoaded(result.items, result.totalItems);
            }        
        })
        .catch(() => {
            this.onDataSourceLoadingError(ErrorCode.NetworkError)
        });
    }

}