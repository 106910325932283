import * as React from "react";
import { CSSProperties } from "react";
import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";
import Colors from "./Colors";
import { PCText } from "./Texts";
import "./Buttons.scss"
import { PCClasses } from "../utils/CSSUtils";

type buttonProps = {
    to?: string;
    href?: string;
    target?: "_blank" | "_self" | "_parent"
    onClick?: () => void;
    children?: string;
    progress?: boolean;
    disabled?: boolean;
    type?: "submit" | "button" | "reset";
    tabIndex?: number;
    variant?: "tiny" | "standard" | "big"
}



type buttonStyleProps = {
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    color: string;
}

export const StandardButton = (props: buttonProps) => {
    return (
        <ButtonCore 
            {...props}
            backgroundColor={Colors.light_royal_blue}
            borderColor="transparent"
            borderWidth={0}
            color={Colors.white}
        />
    );
}

export const StandardButton2 = (props: buttonProps) => {
    return (
        <ButtonCore 
            {...props}
            backgroundColor={Colors.white}
            borderColor={Colors.light_royal_blue}
            borderWidth={1}
            color={Colors.light_royal_blue}
        />
    );
}

export const StandardButton3 = (props: buttonProps) => {
    return (
        <ButtonCore 
            {...props}
            backgroundColor={Colors.white}
            borderColor={Colors.very_light_pink}
            borderWidth={1}
            color={Colors.brownish_grey}
        />
    );
}

export const StandardButton4 = (props: buttonProps) => {
    return (
        <ButtonCore 
            {...props}
            backgroundColor={Colors.light_royal_blue}
            borderColor={Colors.white}
            borderWidth={1}
            color={Colors.white}
        />
    );
}

const ButtonCore = (props: buttonProps & buttonStyleProps) => {
    const {
        backgroundColor,
        borderColor,
        borderWidth,
        children,
        color,
        href,
        onClick,
        progress,
        target,
        to,
        disabled,
        tabIndex,
        type = "button",
        variant = "standard"
    } = props;
    
    const isParkCashLink = !!to;
    const isCustomLink = !isParkCashLink && !!href;
    const isButton = !isParkCashLink && !isCustomLink;

    const disabledCore = progress || disabled;

    const containerStyles: CSSProperties = {        
        borderWidth,
        borderColor,
        backgroundColor,
        outline: 'none'
    }

    const ch = (
        <>
            {progress && <Spinner size="extra-small" color={color} />}
            {!progress && (
                <PCText semibold textAlign="center" fontSize={variant === "big" ? 19.2 : variant === "standard" ? 16 : 14}  lineHeight={1} color={color}>
                    {children}
                </PCText>
            )}
        </>
    )

    const className = PCClasses("pc-button-container", {
        "pc-button-container-disabled": disabledCore,
        "pc-button-container-big": variant === "big",
        "pc-button-container-tiny": variant === "tiny"
    });

    if(isParkCashLink){
        return (
            <Link 
                to={to}
                style={containerStyles}
                onClick={onClick}
                className={className}
            >
                {ch}
            </Link>
        )
    }
    else if(isCustomLink){
        return (
            <a
                href={href}
                target={target}
                onClick={onClick}
                style={containerStyles}
                className={className}
            >
                {ch}
            </a>
        )
    }
    else if(isButton){
        return (
            <button
                type={type}
                onClick={onClick}
                style={containerStyles}
                disabled={disabledCore}
                tabIndex={tabIndex}
                className={className}
            >
                {ch}
            </button>
        )
    }
}

export const TextButton = (props: buttonProps & {fontSize?: number, color?: string, underlined?: boolean}) => {
    const {
        type="button",
        children,
        href,
        onClick,
        tabIndex,
        target,
        to,
        underlined = false,
        color = Colors.light_royal_blue,
        fontSize = 17
    } = props;

    const isParkCashLink = !!to;
    const isCustomLink = !isParkCashLink && !!href;
    const isButton = !isParkCashLink && !isCustomLink;

    const text = <PCText inline color={color} fontSize={fontSize} lineHeight={1}>{children}</PCText>;
    const className = PCClasses("pc-textbutton-container");
    const style: CSSProperties = {textDecoration: underlined ? "underline" : undefined}

    if(isParkCashLink){
        return (
            <Link 
                to={to}
                className={className}
                onClick={onClick}
                style={style}
            >
                {text}
            </Link>
        )
    }
    else if(isCustomLink){
        return (
            <a
                href={href}
                target={target}
                onClick={onClick}
                className={className}
                style={style}
            >
                {text}
            </a>
        )
    }
    else if(isButton){
        return (
            <button
                type={type}
                onClick={onClick}
                className={className}
                tabIndex={tabIndex}
            >
                {text}
            </button>
        )
    }
}