import * as React from "react";
import {SettingsDrawer, SettingsProvider} from "../../../../MUI/components/settings";

import ThemeProvider from "../../../../MUI/theme";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import SnackbarProvider from "../../../../MUI/components/snackbar/snackbar-provider";
import ProgressBar from "../../../../MUI/components/progress-bar";
import MotionLazy from "../../../../MUI/components/animate/motion-lazy";
// i18n
import '../../../../MUI/locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';
// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';
// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import pl from "date-fns/locale/pl";
import en from "date-fns/locale/en-US";
import {useUser} from "../../../../user/UseUserHook";

require("setimmediate"); //webpack 5 do not have polyfill for node setimmediate, have to add this manually

interface Props {
    children: React.ReactNode;
}

export const MinimalContextWraper = ({children}: Props) => {
    const charAt = `

  ░░░    ░░░ 
  ▒▒▒▒  ▒▒▒▒ 
  ▒▒ ▒▒▒▒ ▒▒ 
  ▓▓  ▓▓  ▓▓ 
  ██      ██ 
  
  `;

    console.info(`%c${charAt}`, 'color: #5BE49B');

    /* useScrollToTop();*/
    const userLanguage = useUser().language

    return (<LocalizationProvider dateAdapter={AdapterDateFns}
                                  adapterLocale={userLanguage?.toLowerCase() === 'pl' ? pl : en}

    >
        <SettingsProvider
            defaultSettings={{
                themeMode: 'light', // 'light' | 'dark'
                themeDirection: 'ltr', //  'rtl' | 'ltr'
                themeContrast: 'default', // 'default' | 'bold'
                themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
                themeColorPresets: 'blue', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                themeStretch: true,
            }}
        >
            <ThemeProvider>
                <MotionLazy>
                    <SnackbarProvider>
                        <SettingsDrawer/>
                        <ProgressBar/>
                        {children}
                    </SnackbarProvider>
                </MotionLazy>
            </ThemeProvider>
        </SettingsProvider>
    </LocalizationProvider>);
}


   

