import {
    ErrorCode, GetMobilePilotUsageHistoryViewPilotAssignmentType, ParkingManagerService, ServiceConfig, SortOrder
} from "parkcash-api";
import * as React from "react";
import {useEffect} from "react";
import Resources from "../../../Resources";
import PCDataGrid from "../../components/PCDataGrid";
import {SubBody} from "../../layouts/Main/Body";
import {CustomDataSource, FilterOperator, IDataSource} from "../../utils/DataSource";
import {IParkCashApplicationMode} from "../../utils/ParkCashApplicationMode";
import {getSorts} from "../../utils/SieveUtils";
import {GetAccessHistoryViewReaderType, GetAccessHistoryViewResultEntryDto} from "parkcash-api";
import {PCText} from "../../styles/Texts";
import Colors from "../../styles/Colors";
import {
    mapGetAccessHistoryViewReaderTypeToLocalizedString, mapGetAccessHistoryViewReservationTypeToLocalizedString
} from "../../utils/EnumUtils/LprAndPilotsHistoryRelatedEnums/LprAndPilotsTypeHelpers";
import {mapEnumToSelectOptions} from "../../utils/EnumUtils/MapEnumToSelectOptions";
import {isValidDate, setTimeToDayEnd, setTimeToDayStart} from "../../MUI/utils/format-time";
import {
    CustomizedDatePicker
} from "../../MUI/components/AddedOrModyfiedMuiComponents/CustomizedInputs/CustomizedDatePicker";
import {BaseSpaceSeparator} from "../../styles/Separators";
import {CustomizedSelect} from "../../MUI/components/AddedOrModyfiedMuiComponents/CustomizedInputs/CustomizedSelect";
import {useWindowSize} from "../../utils/WindowSizeHook";
import {MOBILE_WIDTH_THRESHOLD} from "../../utils/Constants";
import {SearchInputWithButton} from "../../components/SearCHInputWithButton";

export const LprAndQRUsageHistoryGrid = (props: {
    jwt: string; applicationMode: IParkCashApplicationMode;
}) => {
    const {
        applicationMode,
        jwt
    } = props;
    const parkingId = applicationMode?.getCurrentParking()?.parkingId;
    const [readerTypeSelect, setReaderTypeSelect] = React.useState<string>("all");
    const [search, setSearch] = React.useState("");
    const [startDateControl, setStartDateControl] = React.useState<Date | null>(setTimeToDayStart(new Date()));
    const [endDateControl, setEndDateControl] = React.useState<Date | null>(setTimeToDayEnd(new Date()));
    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const [dataSource, setDataSource] = React.useState<IDataSource<GetAccessHistoryViewResultEntryDto>>(null);
    const [dataGridKey, setDataGridKey] = React.useState(0);

    const resetFilters = () => {
        setReaderTypeSelect("all");
        setSearch("");
        setStartDateControl(setTimeToDayStart(new Date()))
        setEndDateControl(setTimeToDayEnd(new Date()))

    }
    const onSearch = (value: string) => {
        if (!value) {
            dataSource.removeFilter("licencePlateNumber" as keyof GetAccessHistoryViewResultEntryDto);
        } else {
            dataSource.filter("licencePlateNumber" as keyof GetAccessHistoryViewResultEntryDto,
                FilterOperator.CASE_INSENSITIVE_STRING_CONTAINS,
                value
            );
        }
    };

    function handleStartDateChange(date: Date | null) {
        if (!date) {
            setStartDateControl(date);
            dataSource.removeFilter("from" as keyof GetAccessHistoryViewResultEntryDto);
        } else if (date && !isValidDate(date)) {
            setStartDateControl(date);

        } else {
            const startDateDateStart = setTimeToDayStart(date);
            setStartDateControl(startDateDateStart);
            dataSource.filter(
                "from" as keyof GetAccessHistoryViewResultEntryDto,
                FilterOperator.EQUALS,
                startDateDateStart
            );
        }
    }

    function handleEndDateChange(date: Date | null) {
        if (!date) {
            setEndDateControl(date);
            dataSource.removeFilter("to" as keyof GetAccessHistoryViewResultEntryDto);
        } else if (date && !isValidDate(date)) {
            setEndDateControl(date);

        } else {
            const endDateDateEnd = setTimeToDayEnd(date);
            setEndDateControl(endDateDateEnd);
            dataSource.filter("to" as keyof GetAccessHistoryViewResultEntryDto, FilterOperator.EQUALS, endDateDateEnd);
        }
    }

    const getDataSource = React.useCallback(() => {
        resetFilters(); // reset filters when dataSource is recreated e.g. after changing parkingId
        // API does not support sorting and filtering by sieve, so all dataSource filter methods need to have as keyof
        // GetAccessHistoryViewResultEntryDto to avoid types errors.  It is temporary solution until API will be
        // updated or dataSource class refactored to not be as tightly coupled with  Sieve filters and sorts

        return new CustomDataSource<GetAccessHistoryViewResultEntryDto>({
            initialSort: [
                {
                    key: "eventTimestamp",
                    order: SortOrder.Descending
                }
            ],
            initialFilter: [
                {
                    key: "from" as keyof GetAccessHistoryViewResultEntryDto,
                    value: setTimeToDayStart(new Date()),
                    operator: FilterOperator.EQUALS
                },
                {
                    key: "to" as keyof GetAccessHistoryViewResultEntryDto,
                    value: setTimeToDayEnd(new Date()),
                    operator: FilterOperator.EQUALS
                }

            ],

            load: async (loadOptions) => {
                try {

                    const {
                        filter,
                        page,
                        pageSize,
                        sort
                    } = loadOptions;
                    // assumption is made that only LPR and QR history is shown and API does not return pilots history
                    const from = filter.find(f => f.key === "from" as keyof GetAccessHistoryViewResultEntryDto)?.value as Date;
                    const to = filter.find(f => f.key === "to" as keyof GetAccessHistoryViewResultEntryDto)?.value as Date;
                    const licencePlateNumber = filter.find(f => f.key === "licencePlateNumber" as keyof GetAccessHistoryViewResultEntryDto)?.value;
                    const readerType = filter.find(f => f.key === "readerType" as keyof GetAccessHistoryViewResultEntryDto)?.value as GetAccessHistoryViewReaderType;
                    const sorts = getSorts(sort);
                    const {
                        isSuccess,
                        result,
                        error
                    } = await new ParkingManagerService(new ServiceConfig({jwt})).getAccessHistoryView(parkingId,
                        licencePlateNumber,
                        readerType,
                        from,
                        to,
                        null,
                        null, //sorts,
                        page,
                        pageSize
                    );
                    if (isSuccess) {
                        return {
                            items: result?.entries,
                            totalItems: result?.paginationDto?.totalElementsCount,

                        }
                    } else {
                        return error.code;
                    }
                } catch {
                    return ErrorCode.NetworkError;
                }
            }
        });
    }, [
        jwt,
        parkingId
    ]);

    useEffect(
        () => {
            setDataSource(getDataSource());
            setDataGridKey(pre => pre + 1);
        },
        [
            parkingId,
            jwt
        ]
    );

    const readerTypeSelectOptions = mapEnumToSelectOptions(GetAccessHistoryViewReaderType,
        mapGetAccessHistoryViewReaderTypeToLocalizedString,
        true,
        true
    )
        .filter(o => o.value !== GetAccessHistoryViewReaderType.MobileRemote.toString());

    const onReaderTypeSelectChanged = (v: string) => {
        setReaderTypeSelect(v);
        v === "all" ? dataSource.removeFilter("readerType") : dataSource.filter("readerType", FilterOperator.EQUALS, v)
    }

    return (<SubBody
        title={Resources.Historia_uzycia}
        subtitle={Resources.Historia_uzycia_LPR_QR_przez_uzytkownikow}
        rigth={<>

            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: "center",
                    justifyContent: isMobile ? "flex-start" : "flex-end",
                }}
            >
                {<>
                    <div style={{width: isMobile ? "100%" : 250}}>
                        <CustomizedDatePicker value={startDateControl} onChange={handleStartDateChange}
                                              label={Resources.Od}
                                              fullWidth
                        />
                    </div>


                    <BaseSpaceSeparator size={20}/>
                    <div style={{width: isMobile ? "100%" : 250}}>
                        <CustomizedDatePicker value={endDateControl} onChange={handleEndDateChange}
                                              label={Resources.Do}
                                              fullWidth
                        />
                    </div>
                    <BaseSpaceSeparator size={20}/>

                </>}

                <BaseSpaceSeparator size={20}/>
                <div style={{width: isMobile ? "100%" : 250}}>

                    <CustomizedSelect label={Resources.Typ_czytnika} options={readerTypeSelectOptions}
                                      onChangeCustom={onReaderTypeSelectChanged}
                                      value={readerTypeSelect}/>
                </div>
            </div>

        </>}
    >
        <SearchInputWithButton label={Resources.Wyszukaj_po_numerze_rejestracyjnym} value={search} setValue={setSearch}
                               onSearchSubmitted={value => onSearch(value)}/>
        <BaseSpaceSeparator size={20}/>
        <PCDataGrid<GetAccessHistoryViewResultEntryDto>
            /*   ref={grid}*/
            key={dataGridKey}
            dataSource={dataSource}
            columns={[

                {
                    disableSort: true,
                    label: Resources.Szlaban_Brama,
                    dataField: "accessPointId",
                    width: 200,
                    renderCell: item => (<PCText fontSize={16} lineHeight={20 / 16} color={Colors.brownish_grey}
                                                 letterSpacing={0}
                                                 semibold>
                        {item.accessPointId}
                    </PCText>)
                },
                {
                    disableSort: true,
                    label: Resources.Data_zdarzenia,
                    dataField: "eventTimestamp",
                    width: 100,
                },

                {
                    disableSort: true,
                    label: Resources.Typ,
                    dataField: "reservationType",
                    width: 150,
                    renderCell: item => (<PCText fontSize={16} lineHeight={20 / 16} color={Colors.brownish_grey}
                                                 letterSpacing={0}
                                                 semibold>
                        {mapGetAccessHistoryViewReservationTypeToLocalizedString(item.reservationType)}
                    </PCText>)
                },
                {
                    disableSort: true,
                    label: Resources.Czytnik,
                    dataField: "readerType",
                    width: 150,
                    renderCell: item => (<div style={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <PCText fontSize={16} lineHeight={20 / 16} color={Colors.brownish_grey}
                                letterSpacing={0}
                                semibold>
                            {mapGetAccessHistoryViewReaderTypeToLocalizedString(item.readerType)}
                        </PCText>
                    </div>)
                },
                {
                    disableSort: true,
                    label: Resources.Uzytkownik,
                    dataField: "userInfo",
                    width: 150,
                    renderCell: item => (<PCText fontSize={16} lineHeight={20 / 16} color={Colors.brownish_grey}
                                                 letterSpacing={0}
                                                 semibold>
                        {item.userInfo}
                    </PCText>)
                },
                {
                    disableSort: true,
                    label: Resources.Szczegoly,
                    dataField: "details",
                    width: 150,
                    renderCell: item => (<PCText fontSize={16} lineHeight={20 / 16} color={Colors.brownish_grey}
                                                 letterSpacing={0}
                                                 semibold>
                        {item.details}
                    </PCText>)
                },
            ]}
        />
    </SubBody>)
}

function getTypeText(assignmentType: GetMobilePilotUsageHistoryViewPilotAssignmentType) {
    switch (assignmentType) {
        case GetMobilePilotUsageHistoryViewPilotAssignmentType.ParkingProcess:
            return Resources.Parkowanie;
        case GetMobilePilotUsageHistoryViewPilotAssignmentType.Shared:
            return Resources.Udostepnienie;
        case GetMobilePilotUsageHistoryViewPilotAssignmentType.Permanent:
            return Resources.Staly_dostep
    }
}
