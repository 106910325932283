import { FormikProps } from "formik";
import * as React from "react";
import Resources from "../../../Resources";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import RegularInput from "../../components/forms/RegularInput";
import { SwitchWithLabel } from "../../components/Switch";
import InvoiceFormState, { InvoiceType } from "../../otherPages/formInterfaces/InvoiceFormState";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { NIP_MASK, POSTAL_CODE_MASK } from "../../utils/Constants";

export default (props: {
    args: FormikProps<InvoiceFormState>
}) => {
    const {values, touched, errors, setFieldTouched, setFieldValue} = props.args;

    return (
        <>
            <SwitchWithLabel 
                    value={values.wantInvoice}
                    label={Resources.Chce_otrzymac_fakture}
                    onChange={v => setFieldValue("wantInvoice", v)}
            />
            <BaseSpaceSeparator size={10} />
            {values.wantInvoice && (
                <>
                    <RegularDropdownList 
                        value={values.type}
                        touched={touched.type}
                        error={errors.type}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        label={Resources.Typ_faktury}
                        borderColor={Colors.brownish_grey}
                        name="type"
                        actions={[
                            {
                                id: InvoiceType.Company,
                                text: Resources.Firma
                            },
                            {
                                id: InvoiceType.PrivatePerson,
                                text: Resources.Osoba_prywatna
                            }
                        ]}
                    />
                    <RegularInput 
                        value={values.contrahent}
                        touched={touched.contrahent}
                        error={errors.contrahent}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        label={Resources.Nazwa}
                        name="contrahent"
                        showValidatedButton
                        showClearButton
                    />
                    <RegularInput 
                        showClearButton
                        showValidatedButton
                        value={values.nip}
                        error={errors.nip}
                        touched={touched.nip}
                        name="nip"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        label={`${Resources.NIP}`}
                        mask={NIP_MASK}
                    />
                    <RegularInput 
                        value={values.streetName}
                        error={errors.streetName}
                        touched={touched.streetName}
                        name="streetName"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        showClearButton
                        showValidatedButton
                        label={Resources.Ulica}
                    />
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{flex: 1}}>
                            <RegularInput 
                                value={values.streetNumber}
                                error={errors.streetNumber}
                                touched={touched.streetNumber}
                                name="streetNumber"
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                showClearButton
                                showValidatedButton
                                label={Resources.Nr_domu}
                            />
                        </div>
                        <BaseSpaceSeparator size={10} />
                        <div style={{flex: 1}}>
                            <RegularInput 
                                value={values.flatNumber}
                                error={errors.flatNumber}
                                touched={touched.flatNumber}
                                name="flatNumber"
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                showClearButton
                                showValidatedButton
                                label={Resources.Nr_lokalu}
                            />
                        </div>
                    </div>
                    <RegularInput 
                        value={values.city}
                        error={errors.city}
                        touched={touched.city}
                        name="city"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        showClearButton
                        showValidatedButton
                        label={Resources.Miejscowosc}
                    />
                    <RegularInput 
                        value={values.postalCode}
                        error={errors.postalCode}
                        touched={touched.postalCode}
                        name="postalCode"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        showClearButton
                        showValidatedButton
                        label={Resources.Kod_pocztowy}
                        mask={POSTAL_CODE_MASK}
                    />
                </>

            )}
        </>
    )
}