import { EnsurePaymentStatusDto, ErrorCode, PaymentOrderStatus, PaymentsService, ServiceConfig } from "parkcash-api";
import * as React from "react";
import { useLocation } from "react-router-dom";
import Resources from "../../Resources";
import Spinner from "../components/Spinner";
import { StandardButton } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { MOBILE_WIDTH_THRESHOLD } from "../utils/Constants";
import { getErrorMessage } from "../utils/ErrorUtils";
import { useWindowSize } from "../utils/WindowSizeHook";

const subscription_type = require("../../assets/images/subscription_type.svg");
const errorImg = require("../../assets/images/error.svg");

const getOrderId = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("OrderID")
}

const getPaymentOperatorId = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("PaymentOperatorID")
}

const getPaymentOrderId = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("PaymentOrderID")
}

const getOperator = (): "p24" | undefined => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("Operator") as "p24" | undefined || undefined;
}

const getPaymentStatus = async (orderId: string): Promise<{result: "pending" | "success" | "failure" | "rejected", redirectUrl?: string, webPaymentUrl?: string, error?: ErrorCode}> => {
    try{
        const response = await new PaymentsService(new ServiceConfig()).getPaymentStatusByOrderId(orderId)
        if(response.isSuccess){
            const {status, redirectUrl, webPaymentUrl} = response.result;

            if(status === PaymentOrderStatus.Completed){
                return {result: "success", redirectUrl}
            }
            else if(status === PaymentOrderStatus.Rejected){
                return {result: 'rejected', webPaymentUrl}
            }
            else{
                return {result: 'pending', webPaymentUrl}
            }
        }
        else{
            return {error: response.error.code, result: "failure"}
        }
    }
    catch{
        return {error: ErrorCode.NetworkError, result: "failure"}
    }
}

const ensurePaymentStatus = async (paymentOperatorId: string, paymentOrderId: string) => {
    try{
        const response = await new PaymentsService(new ServiceConfig()).ensurePaymentStatus(new EnsurePaymentStatusDto({
            paymentOperatorId,
            paymentOrderId
        }));
        if(response.isSuccess){
            return {result: true}
        }
        else{
            return {result: false, error: response.error.code}
        }
    }
    catch{
        return {result: false, error: ErrorCode.NetworkError}
    }
}

const MAX_TRIES = 200;

const INTERVAL = 5000;

function setIntervalImmediately(func: () => void, interval: number) {
    func();
    return setInterval(func, interval);
}

export default () => {
    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const [progress, setProgress] = React.useState(true);
    const [error, setError] = React.useState<string>(null);
    const [webPaymentUrl, setWebPaymentUrl] = React.useState<string>(null);
    const tries = React.useRef(0);
    const interval = React.useRef<any>();

    const onError = (m: string) => {
        clearInterval(interval.current);
        setProgress(false);
        setError(m);
    }

    const startChecking = () => {
        interval.current = setIntervalImmediately(async () => {
            const {result, redirectUrl, webPaymentUrl: wpUrl, error} = await getPaymentStatus(getOrderId());
            if(!webPaymentUrl){
                setWebPaymentUrl(wpUrl);
            }

            if(result === "success"){
                window.location.href = redirectUrl;
            }
            else if(result === "pending"){
                if(tries.current >= MAX_TRIES){
                    onError(Resources.Przekroczono_czas_oczekiwania);
                }
                else{
                    tries.current = tries.current + 1;
                }
            }
            else if(result === "rejected"){
                onError(Resources.Transakcja_odrzucona);
            }   
            else{
                onError(getErrorMessage(error));
            }           
        }, INTERVAL);
    }

    const init = async () => {
        const operator = getOperator();
        if(operator === "p24"){
            const {result, error, webPaymentUrl: wpUrl, redirectUrl} = await getPaymentStatus(getOrderId());
            if(!webPaymentUrl){
                setWebPaymentUrl(wpUrl);
            }

            if(result === "success"){
                window.location.href = redirectUrl;
            }
            else if(result === "pending"){
                const {result, error: err} = await ensurePaymentStatus(getPaymentOperatorId(), getPaymentOrderId());
                if(result){
                    startChecking();
                }
                else{
                    onError(getErrorMessage(err));
                }
            }
            else if(result === "rejected"){
                onError(Resources.Transakcja_odrzucona);
            }
            else{
                onError(getErrorMessage(error));
            }
        }
        else{
            startChecking();
        }
    }

    React.useEffect(() => {
        init();
        return () => {
            if(interval.current){
                clearInterval(interval.current);
            }
        }
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: isMobile ? 20 : 120,
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: isMobile ? 20 : 50
            }}
        >
            {!error && <img src={subscription_type} width={220} height={193} />}
            {error && <img src={errorImg} width={220} height={207} />}

            <BaseSpaceSeparator size={isMobile ? 30 : 50} />

            <div style={{width: isMobile ? 300 : 600}}>
                <PCText fontSize={36} color={Colors.black} textAlign="center">
                    {progress && Resources.Transakcja_w_trakcie_realizownia}
                    {!progress && error && Resources.Transakcja_nie_zostala_zrealizowana}
                </PCText>
                <PCText fontSize={36} color={Colors.black} textAlign="center" semibold>
                    {progress && Resources.Oczekujemy_na_odpowiedz_z_Twojego_banku}
                    {!progress && error && !!webPaymentUrl && Resources.Sprobuj_jeszcze_raz}
                </PCText>
            </div>

            <BaseSpaceSeparator size={isMobile ? 30 : 100} />

            {progress && <Spinner size="medium" />}
            {!progress && error && !!webPaymentUrl && (
                <div style={{width: isMobile ? 250 : 300}}>
                    <StandardButton
                        onClick={() => {
                            window.location.href = webPaymentUrl;
                        }}
                    >
                        {Resources.Ponow_platnosc}
                    </StandardButton>
                </div>
            )}
        </div>
    )
}