import { Button } from '@mui/material';
import React, { useState } from 'react';
import Iconify from "../../MUI/components/iconify";
import CustomDateRangePicker, {
    DateRangePickerProps,
    shortDateLabel
} from "../../MUI/components/custom-date-range-picker";
import Resources from "../../../Resources";
import {CustomDateRangePickerProps} from "../../MUI/components/custom-date-range-picker/custom-date-range-picker";

export interface CustomDateRangePickerWithButtonProps {
    dateRangePickerProps: CustomDateRangePickerProps;
   /* onOpenDateRange: () => void;*/
}
export const CustomDateRangePickerWithSelectButton = ( props:CustomDateRangePickerWithButtonProps) => {
    
    
    const {open, onOpen, onClose, onCanceled, onConfirmed,  onChangeStartDate, onChangeEndDate, startDate, endDate, error, errorMessages, dateRangeSelected} = props.dateRangePickerProps;
    
    return (

    <>
        <Button
            color="inherit"
            onClick={onOpen}
            endIcon={
                <Iconify
                    icon={open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                    sx={{ ml: -0.5 }}
                />
            }
        >
            {!!startDate && !!endDate
                ? shortDateLabel(startDate, endDate)
                : Resources.Wybierz_zakres_dat}
        </Button>

        <CustomDateRangePicker
            title={Resources.Wybierz_zakres_dat}
            variant="calendar"
            startDate={startDate}
            endDate={endDate}
            onChangeStartDate={onChangeStartDate}
            onChangeEndDate={onChangeEndDate}
            open={open}
            onClose={onCanceled}
            onCanceled={onCanceled}
            onConfirmed={onConfirmed}
            selected={!!startDate && !!endDate}
           /* selected={dateRangeSelected}*/
            error={error}
            errorMessages={errorMessages ?? []}
        />
    </>

    )
}