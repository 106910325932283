import { ISharingRequestsDto, ErrorCode, SharingService, ServiceConfig, SortOrder, SharingRequestsSortType, IgnoreSharingRequestDto, AcceptParkingSpotSharingRequestDto } from "parkcash-api";
import Resources from "../../Resources";
import errorPayload from "../redux/ErrorPayload";
import simplePayload from "../redux/SimplePayload";
import ThunkFunction from "../redux/ThunkFunction";
import { getErrorMessage } from "../utils/ErrorUtils";
import Notify from "../utils/Notify";

export type SharingActionTypes = "LoadSharingRequests" | "LoadSharingRequestsSuccess" | "LoadSharingRequestsError" |
"SharingRequestOutdated";

export interface sharingState{
    error: ErrorCode;
    progress: boolean;
    items: ISharingRequestsDto[];
}

interface sharingRequestsReceivedPayload extends simplePayload {
    items: ISharingRequestsDto[]
}

export const loadSharingRequests = (forceRefresh: boolean): ThunkFunction => async (dispatch, getState) => {
    const {user: {token}, sharing: {progress, items}} = getState();
    if(progress || (!!items && !forceRefresh)){
        return;
    }

    try{
        dispatch<simplePayload>({type: "LoadSharingRequests"});
        const response = await new SharingService(new ServiceConfig({jwt: token})).getActiveSharingRequests(
            SortOrder.Descending,
            SharingRequestsSortType.ByDate,
            0,
            100,
            0
        );
        if(response.isSuccess){
            dispatch<sharingRequestsReceivedPayload>({type: "LoadSharingRequestsSuccess", items: response.result.sharingRequests})
        }
        else{
            dispatch<errorPayload>({type: "LoadSharingRequestsError", errorCode: response.error.code})
        }
    }
    catch{
        dispatch<errorPayload>({type: "LoadSharingRequestsError", errorCode: ErrorCode.NetworkError});        
    }
}

interface singleRequestPayload extends simplePayload {
    requestId: string
}

export const acceptRequestFromCommunity = (requestId: string, after: (r: boolean) => void): ThunkFunction => async (dispatch, getState) => {
    try{
        const {user: {token: jwt}} = getState();
        const response = await new SharingService(new ServiceConfig({jwt})).acceptParkingSpotSharingRequest(new AcceptParkingSpotSharingRequestDto({requestId}));
        if(response.isSuccess){
            dispatch<singleRequestPayload>({type: "SharingRequestOutdated", requestId});
            after(true);
        }
        else{
            const code = response.error.code;
            after(false);
            dispatch(afterRequestFailed(code, requestId));
        }
    }
    catch{
        after(false);
    }
}

export const dismissRequestFromCommunity = (requestId: string, after: (r: boolean) => void): ThunkFunction => async (dispatch, getState) => {
    try{
        const {user: {token: jwt}} = getState();
        const response = await new SharingService(new ServiceConfig({jwt})).ignoreParkingSpotSharingRequest(new IgnoreSharingRequestDto({
            requestId
        }));
        if(response.isSuccess){
            dispatch<singleRequestPayload>({type: "SharingRequestOutdated", requestId});
            after(true);
        }
        else{
            const code = response.error.code;
            after(false);
            dispatch(afterRequestFailed(code, requestId));
        }
    }
    catch{
        after(false);
    }
}

const afterRequestFailed = (code: ErrorCode, requestId: string): ThunkFunction => (dispatch) => {
    if (code === ErrorCode.SharingRequestWasDeleted) {
        Notify.Error(Resources.Zapytanie_zostalo_juz_usuniete);
        dispatch<singleRequestPayload>({type: "SharingRequestOutdated", requestId})
    } 
    else if (code === ErrorCode.SharingRequestAlreadyAccepted) {
        Notify.Error(Resources.Zapytanie_zostalo_juz_zaakceptowane);
        dispatch<singleRequestPayload>({type: "SharingRequestOutdated", requestId})
    } 
    else if (code === ErrorCode.SharingRequestAlreadyOutdated) {
        Notify.Error(Resources.Zapytanie_o_miejsce_jest_juz_nieaktualne);
        dispatch<singleRequestPayload>({type: "SharingRequestOutdated", requestId})
    } 
    else {
        Notify.Error(getErrorMessage(code));
    }
}

const initialState: sharingState = {
    error: null,
    progress: false,
    items: null
}

export default (state: sharingState = initialState, action: simplePayload): sharingState => {
    switch(action.type){
        case"LoadSharingRequests": 
            return {
                ...state,
                progress: true,
                error: null
            }
        case "LoadSharingRequestsSuccess":
            return {
                ...state,
                progress: false,
                error: null,
                items: (action as sharingRequestsReceivedPayload).items
            }
        case "LoadSharingRequestsError":
            return {
                ...state,
                progress: false,
                error: (action as errorPayload).errorCode
            }
        case "SharingRequestOutdated":
            const {requestId} = (action as singleRequestPayload);
            return {
                ...state,
                items: state.items.filter(r => r.id !== requestId)
            }
        case "Logout":
            return {
                ...initialState
            }
        default: 
            return state;
    }
}