import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {
    GetMobilePilotsLinkIssuanceHistoryViewResultEntryDto, LinkSource, LinkStatus, ServiceConfig, VirtualPilotsService
} from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import PCDataGrid from "../../components/PCDataGrid";
import ActionColumn, {ActionIcon, MoreDropdown} from "../../components/PCDataGrid/ActionColumn";
import {SubBody} from "../../layouts/Main/Body";
import PilotLinkStatusPresenter from "../../mobilePilots/PilotLinkStatusPresenter";
import SharePilotForm from "../../mobilePilots/SharePilotForm";
import {StandardButton} from "../../styles/Buttons";
import Colors from "../../styles/Colors";
import {BaseSpaceSeparator} from "../../styles/Separators";
import {PCText} from "../../styles/Texts";
import {CustomDataSource, FilterOperator, IDataSource} from "../../utils/DataSource";
import {formatDateTime} from "../../utils/DateTimeUtils";
import {getErrorMessage} from "../../utils/ErrorUtils";
import Notify from "../../utils/Notify";
import {IParkCashApplicationMode} from "../../utils/ParkCashApplicationMode";
import {getFilters, getSorts} from "../../utils/SieveUtils";
import ChangePilotSharingForm from "./ChangePilotSharingForm";
import {
    mapLinkSourceToLocalizedString
} from "../../utils/EnumUtils/LprAndPilotsHistoryRelatedEnums/LprAndPilotsTypeHelpers";
import {CustomizedSelect} from "../../MUI/components/AddedOrModyfiedMuiComponents/CustomizedInputs/CustomizedSelect";
import {useWindowSize} from "../../utils/WindowSizeHook";
import {MOBILE_WIDTH_THRESHOLD} from "../../utils/Constants";

export default (props: {
    jwt: string; applicationMode: IParkCashApplicationMode; pilots: { text: string, id: string }[]
}) => {
    const {
        applicationMode,
        jwt,
        pilots
    } = props;
    const parkingId = applicationMode.getCurrentParking()?.parkingId;
    const [pilot, setPilot] = React.useState<string>("all");
    const [type, setType] = React.useState<string>("all");
    const [status, setStatus] = React.useState<string>("all");
    const [sharePilotFormVisible, setSharePilotFormVisible] = React.useState(false);
    const [changePilotSharingFormVisible, setChangePilotSharingFormVisible] = React.useState(false);
    const [sharingGridDataSource, setSharingGridDataSource] = React.useState<IDataSource<GetMobilePilotsLinkIssuanceHistoryViewResultEntryDto>>(
        null);
    const [dataGridKey, setDataGridKey] = React.useState(0);
    const [editedEntry, setEditedEntry] = React.useState<GetMobilePilotsLinkIssuanceHistoryViewResultEntryDto>(null);
    let sharingGrid: PCDataGrid<GetMobilePilotsLinkIssuanceHistoryViewResultEntryDto> = null;
    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;

    const resetFilters = () => {
        setStatus("all");
        setPilot("all");
        setType("all")
    }
    const getSharingGridDataSource = React.useCallback(
        () => {
            resetFilters(); // reset filters when new parkingId is set 

            return new CustomDataSource<GetMobilePilotsLinkIssuanceHistoryViewResultEntryDto, {
                jwt: string, parkingId: string
            }>({
                additionalInfo: {
                    jwt,
                    parkingId
                },

                load: async (loadOptions, additional) => {
                    const {
                        filter,
                        page,
                        pageSize,
                        sort
                    } = loadOptions;
                    const {
                        jwt,
                        parkingId
                    } = additional;

                    // Assumption is made that only LinkSource.OrdinarySharing, LinkSource.ExternalReservationSharing
                    // are allowed in the view so rest of the types are filtered out (LinkSource.ReservationSharing at
                    // this moment)
                    const AllowedLinkSourceFilters = {
                        key: "linkSource",
                        value: [
                            LinkSource.OrdinarySharing,
                            LinkSource.ExternalReservationSharing
                        ],
                        operator: FilterOperator.EQUALS
                    }

                    //let finalFilterArray= filter?.length === 0 ? [noPilotFilter] : filter;
                    let finalFilterArray = filter?.length === 0 ? [AllowedLinkSourceFilters] : filter;
                    let targetFilter = getFilters(finalFilterArray);

                    const {
                        error,
                        isSuccess,
                        result
                    } = await new VirtualPilotsService(new ServiceConfig({jwt})).getMobilePilotsLinkIssuanceHistoryView(parkingId,
                        targetFilter,
                        getSorts(sort),
                        page,
                        pageSize
                    );

                    return isSuccess ? {
                        items: result.entries,
                        totalItems: result.paginationDto.totalElementsCount
                    } : error.code
                }
            })
        },
        [
            jwt,
            parkingId
        ]
    );

    React.useEffect(() => {
        if (parkingId) {
            setSharingGridDataSource(getSharingGridDataSource());
            setDataGridKey(prev => prev + 1);
        }
    }, [parkingId]);

    const onPilotFilter = (v: string) => {
        setPilot(v);
        v !== "all" ? sharingGridDataSource.filter("pilotId",
            FilterOperator.EQUALS,
            v
        ) : sharingGridDataSource.removeFilter("pilotId");
    }

    const onTypeFilter = (v: string) => {
        setType(v);
        // there is requirement to always filter out result from reservation sharing so type all does not include it
        v !== "all" ? sharingGridDataSource.filter("linkSource",
            FilterOperator.EQUALS,
            v
        ) : sharingGridDataSource.removeFilter("linkSource");
    }

    const onStatusFilter = (v: string) => {
        setStatus(v);
        v !== "all" ? sharingGridDataSource.filter("status",
            FilterOperator.EQUALS,
            v
        ) : sharingGridDataSource.removeFilter("status");
    }

    const onShare = () => {
        setSharePilotFormVisible(true);
    }

    const onEdit = (item: GetMobilePilotsLinkIssuanceHistoryViewResultEntryDto) => {
        setEditedEntry(item);
        setChangePilotSharingFormVisible(true);
    }

    const blockPilotSharing = async (item: GetMobilePilotsLinkIssuanceHistoryViewResultEntryDto) => {
        try {
            const {
                isSuccess,
                error
            } = await new VirtualPilotsService(new ServiceConfig({jwt})).block(item.linkId);
            if (isSuccess) {
                item.status = LinkStatus.BlockedByIssuer;
                sharingGrid?.refresh();
            } else {
                Notify.Error(getErrorMessage(error.code));
            }
        } catch {
            Notify.Error(getErrorMessage());
        }
    }

    const unBlockPilotSharing = async (item: GetMobilePilotsLinkIssuanceHistoryViewResultEntryDto) => {
        try {
            const {
                isSuccess,
                error
            } = await new VirtualPilotsService(new ServiceConfig({jwt})).unblock(item.linkId);
            if (isSuccess) {
                item.status = LinkStatus.Active;
                sharingGrid?.refresh();
            } else {
                Notify.Error(getErrorMessage(error.code));
            }
        } catch {
            Notify.Error(getErrorMessage());
        }
    }

    return (<SubBody
        title={Resources.Lista_udostenien_czasowych}
        subtitle={Resources.Czasowe_dostepy_pozwalajace_na_wykorzystanie_mobilnego_pilota}
        rigth={<>
            <div style={{width: isMobile ? "100%" : 300}}>
                <CustomizedSelect
                    label={Resources.Typ}
                    options={[
                        {
                            label: Resources.Wszystkie,
                            value: "all"
                        },
                        {
                            label: Resources.Rezerwacja_zewnetrzna,
                            value: LinkSource.ExternalReservationSharing.toString()
                        },
                        {
                            label: Resources.Udostepnianie,
                            value: LinkSource.OrdinarySharing.toString()
                        }
                    ]}
                    onChangeCustom={onTypeFilter}
                    value={type}/>
            </div>
            <BaseSpaceSeparator size={20}/>
            <div style={{width: isMobile ? "100%" : 200}}>
                <CustomizedSelect
                    label={Resources.Status}
                    options={[
                        {
                            label: Resources.Wszystkie,
                            value: "all"
                        },
                        {
                            label: Resources.Aktywny,
                            value: LinkStatus.Active.toString()
                        },
                        {
                            label: Resources.Niewazny,
                            value: LinkStatus.Outdated.toString()
                        },
                        {
                            label: Resources.Zablokowany,
                            value: LinkStatus.BlockedByIssuer.toString()
                        },
                        {
                            label: Resources.Uzyty,
                            value: LinkStatus.Used.toString()
                        }
                    ]}
                    onChangeCustom={onStatusFilter}
                    value={status}/>

            </div>
            <BaseSpaceSeparator size={20}/>
            <div style={{width: isMobile ? "100%" : 200}}>
                <CustomizedSelect
                    label={Resources.Pilot}
                    options={[
                        {
                            label: Resources.Wszystkie,
                            value: "all"
                        },
                        ...pilots.map(p => ({
                            label: p.text,
                            value: p.id
                        }))
                    ]}
                    value={pilot}
                    onChangeCustom={onPilotFilter}
                />
            </div>
            <BaseSpaceSeparator size={20}/>
            <div style={{
                width: 200,
                height: '100%'
            }}>
                <StandardButton onClick={onShare} variant={"big"}>{Resources.Udostepnij_Pilota}</StandardButton>
            </div>
        </>}
    >
        <SharePilotForm
            onClose={() => setSharePilotFormVisible(false)}
            visible={sharePilotFormVisible}
            mode="admin"
            pilotId={null}
            onSubmitted={() => (setSharePilotFormVisible(false), sharingGridDataSource?.reload())}
        />
        <ChangePilotSharingForm
            onClose={() => setChangePilotSharingFormVisible(false)}
            visible={changePilotSharingFormVisible}
            linkId={editedEntry?.linkId}
            validFrom={editedEntry?.from}
            validTo={editedEntry?.to}
            onSubmitted={(from, to) => {
                setChangePilotSharingFormVisible(false);
                editedEntry.from = from;
                editedEntry.to = to;
                setPilot(null);
                setStatus(null);
                setType(null);
                sharingGridDataSource?.reload();
            }}
        />
        <PCDataGrid<GetMobilePilotsLinkIssuanceHistoryViewResultEntryDto>
            ref={i => {
                if (i) {
                    sharingGrid = i
                }

            }}
            key={dataGridKey}
            dataSource={sharingGridDataSource}
            columns={[
                {
                    label: Resources.Pilot,
                    width: 369,
                    dataField: "pilotFriendlyName",
                    renderCell: item => (
                        <PCText fontSize={16} lineHeight={20 / 16} semibold color={Colors.brownish_grey}
                                letterSpacing={0}>
                            {item.pilotFriendlyName}
                            <br/>
                            <PCText semibold inline fontSize={16} lineHeight={20 / 16} color={Colors.black}
                                    letterSpacing={0}>
                                {`ul. ${item.address.streetName} ${item.address.streetNumber}`}
                            </PCText>
                        </PCText>)
                },
                {
                    label: Resources.Waznosc_od,
                    width: 218,
                    dataField: "from",
                    renderCell: item => (
                        <PCText semibold fontSize={16} lineHeight={20 / 16} color={Colors.brownish_grey}
                                letterSpacing={0}>
                            {formatDateTime(item.from)}
                            <br/>
                            <PilotLinkStatusPresenter status={item.status}/>
                        </PCText>)
                },
                {
                    label: Resources.Waznosc_do,
                    width: 218,
                    dataField: "to",
                    renderCell: item => (
                        <PCText fontSize={16} lineHeight={20 / 16} color={Colors.brownish_grey} letterSpacing={0}>
                            {formatDateTime(item.to)}
                            <br/>
                            <PilotLinkStatusPresenter status={item.status}/>
                        </PCText>)
                },
                {
                    label: Resources.Typ,
                    width: 196,
                    dataField: "linkSource",
                    renderCell: item => <PCText color={Colors.black} lineHeight={20 / 16} semibold fontSize={16}
                                                letterSpacing={0}>{mapLinkSourceToLocalizedString(item?.linkSource)}</PCText>
                },
                {
                    label: Resources.Akcje,
                    width: '166',
                    renderCell: (item) => (<ActionColumn>
                        <MoreDropdown
                            actions={[
                                {
                                    visible: item.status === LinkStatus.Active,
                                    onClick: () => blockPilotSharing(item),
                                    text: Resources.Zablokuj
                                },
                                {
                                    visible: item.status === LinkStatus.BlockedByIssuer,
                                    onClick: () => unBlockPilotSharing(item),
                                    text: Resources.Odblokuj
                                }
                            ]}
                        />
                        <ActionIcon
                            icon={faPencilAlt}
                            title={Resources.Edytuj}
                            onClick={() => onEdit(item)}
                        />

                    </ActionColumn>)
                }
            ]}
        />
    </SubBody>)
}