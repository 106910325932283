
import Resources from "../../../../../../Resources";
import React from "react";
import HeatMapChart from "../../../../../MUI/components/AddedOrModyfiedMuiComponents/Charts/HeatMap";

function getRandomY() {
    return Math.floor(Math.random() * 100); // assuming you want random numbers between 0 and 99
}

export const HeatMapSection = () => {
    const labels = [];
    for (let i = 1; i <= 31; i++) {
        labels.push(i.toString());
    }

    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];
    const series = [];

    months.forEach(month => {
        const data = [];
        for (let day = 1; day <= 31; day++) {
            // Note: For simplicity, we are generating data for all 31 days for each month.
            // In reality, some months have fewer days.
            if (month === 'Feb' && day > 29) continue; // skipping non-existent days for February
            if ((month === 'Apr' || month === 'Jun' || month === 'Sep' || month === 'Nov') && day > 30) continue; // skipping
                                                                                                                  // the
                                                                                                                  // 31st
                                                                                                                  // day
                                                                                                                  // for
                                                                                                                  // these
                                                                                                                  // months

            const yValue = (month === 'Jan' && (day === 1 || day === 2)) ? (day === 1 ? 22 : 17) : getRandomY();

            data.push({
                x: day.toString(),
                y: yValue
            });
        }

        series.push({
            name: month,
            data: data
        });
    });
    return (

        <HeatMapChart
            title={Resources.Wykorzystanie_parkingu}
            chart={{
                labels: labels,
                series: series
            }}
        />)
}