import Enumerable from "linq";
import { IParkingSearchResult, IParkingSearchInfo, IParkingSpotSearchInfo, UnnumberedSpotsParkingSearchResultEntry, ParkingStatus, UnnumberedSpotsParkingSearchResult, StartStopTariffType } from "parkcash-api";
import SearchTarget from "./SearchTarget";
import * as uuid from "uuid";

export interface ParkingSpotInfo {
    spotId: string;
    parkingId: string;
    distance: number;
    city: string;
    streetName: string;
    streetNumber: string;
    averageTotalRating: number;
    pricePerHour: number;
    price: number;
    lat: number;
    lon: number;
    ownerName: string;
    isMySpot: boolean;
    spotNumber?: string;
    level?: string;
}

export interface ParkingInfo {
    parkingId: string;
    city: string;
    streetName: string;
    streetNumber: string;
    lat: number;
    lon: number;
    hasFreePlaces: boolean;
    distance: number;
    hasMySpot: boolean;
    hasOnlyMySpots: boolean;
}

export interface StartStopParkingInfo {
    parkingId: string;
    friendlyName: string;
    city: string;
    streetName: string;
    streetNumber: string;
    lat: number;
    lon: number;
    distance: number, 
    effectivePrice: number, 
    hasVirtualPilot: boolean,
    parkingStatus: ParkingStatus, 
    tariffType: StartStopTariffType,
}

type status = "showPlaces" | "showAsk";

export interface ExtendedParkingInfo {
    type: "startStop" | "normal";
    parkingId: string;
    lat: number;
    lon: number;
    hasFreePlaces: boolean;
    hasMyPlaces: boolean;
    distance: number;
}

export class ParkingTargetInfo {
    constructor(
        public readonly type: "parkingSpot" | "startStopParking",
        public readonly spotInfo: ParkingSpotInfo,
        public startStopParkingInfo: StartStopParkingInfo
    ){

    }

    getId(){
        return this.type === "parkingSpot" ? this.spotInfo.spotId : this.startStopParkingInfo.parkingId;
    }

    getParkingId(){
        return this.type === "parkingSpot" ? this.spotInfo.parkingId : this.startStopParkingInfo.parkingId;
    }

    equals(other: ParkingTargetInfo){
        if(this.type !== other.type){
            return false;
        }

        return this.getId() === other.getId();
    }
}

export class SearchResult {

    private readonly key: string;
    
    private readonly AllParkings: ParkingInfo[] = [];

    private readonly AllFreeSpots: ParkingSpotInfo[] = [];

    private readonly AllNonFreeSpots: ParkingSpotInfo[] = [];

    private readonly StartStopParkings: StartStopParkingInfo[] = [];

    private status: status;

    private previousResults: SearchResult[];
    
    private readonly OriginalResult: IParkingSearchResult;
    
    private readonly FreeSpotsOnMyParkings: ParkingSpotInfo[] = [];

    private readonly FreeSpotsOnNoMyParkings: ParkingSpotInfo[] = [];

    private readonly MyParkings: ParkingInfo[] = [];

    private readonly noMyParkings: ParkingInfo[] = [];

    private readonly searchTarget: SearchTarget;

    private extendedParkingInfo: ExtendedParkingInfo[] = [];

    private parkingTargets: ParkingTargetInfo[] = [];

    constructor(result: IParkingSearchResult, searchTarget: SearchTarget) {
        this.key = uuid.v1();
        this.OriginalResult = result;
        this.previousResults = [];
        this.searchTarget = searchTarget;
        this.load();
        this.status = this.determineStatus();
    }

    public static createEmpty(searchTarget: SearchTarget){
        return new SearchResult({
            nearbyParkings: [],
            userParkings: [],
            unnumberedSpotsParkings: new UnnumberedSpotsParkingSearchResult({
                entries: []
            })
        }, searchTarget);
    }

    public getSearchTarget(){
        return this.searchTarget;
    }

    getKey(){
        return this.key;
    }

    getSheetHeight(){
        switch(this.getStatus()){
            case "showAsk":
                if(!!this.getFreeTargetsAround()){
                    return 270;
                }
                return 230;
            case "showPlaces":
                return 265;
            default:
                return 265;
        }
    }

    getStatus() {
        return this.status;
    }

    getFreeTargetsAround(): number {
        const parkingId = this.searchTarget.getParkingId();
        if(parkingId){
            return this.StartStopParkings.length + this.FreeSpotsOnNoMyParkings.length + Enumerable.from(this.FreeSpotsOnMyParkings).where(p => p.parkingId !== parkingId).count();
        }
        else {
            return null;
        }
    }

    applyNewStatus(status: status): SearchResult {
        const newResult = new SearchResult(this.OriginalResult, this.searchTarget);
        newResult.status = status;
        newResult.previousResults = [
            ...this.previousResults,
            this
        ];
        return newResult
    }

    getPreviousResult(): SearchResult {
        if(this.previousResults.length){
            return Enumerable.from(this.previousResults).last();
        }
        else{
            return null;
        }
    }

    getFreeTargetByParkingId(parkingId: string) {
        return Enumerable.from(this.parkingTargets).firstOrDefault(t => t.getParkingId() === parkingId);
    }

    getParkings(){
        return this.extendedParkingInfo;
    }

    getParkingTargets(){
        return this.parkingTargets;
    }

    private load() {
        if(this.OriginalResult.userParkings){
            this.loadMyParking();
        }

        if(this.OriginalResult.nearbyParkings){
            this.loadNearbyResults();
        }

        if(this.OriginalResult.unnumberedSpotsParkings && this.OriginalResult.unnumberedSpotsParkings.entries){
            this.loadStartStopParkings();
        }

        this.loadExtendedParkings();
        this.loadParkingTargets();
    }

    private loadParkingTargets(){
        const result: ParkingTargetInfo[] = [];

        for(let spotInfo of this.AllFreeSpots){
            

            result.push(new ParkingTargetInfo("parkingSpot", spotInfo, null));
        }

        for(let startStopParkingInfo of this.StartStopParkings){
            result.push(new ParkingTargetInfo("startStopParking", null, startStopParkingInfo));
        }

        this.parkingTargets = Enumerable
        .from(result)
        .orderBy(i => {
            if(i.type === "parkingSpot"){
                return i.spotInfo.distance;
            }
            else{
                return i.startStopParkingInfo.distance;
            }
        })
        .toArray();
    }

    private loadExtendedParkings(){
        const result: ExtendedParkingInfo[] = [];
        for(let parking of this.AllParkings){
            result.push({
                distance: parking.distance,
                hasFreePlaces: parking.hasFreePlaces,
                lat: parking.lat,
                lon: parking.lon,
                parkingId: parking.parkingId,
                type: "normal",
                hasMyPlaces: this.AllFreeSpots.some(spot => spot.isMySpot && spot.parkingId === parking.parkingId) || this.AllNonFreeSpots.some(spot => spot.isMySpot && spot.parkingId === parking.parkingId)
            });
        }

        for(let startStopParking of this.StartStopParkings){
            result.push({
                distance: startStopParking.distance,
                hasFreePlaces: null,
                lat: startStopParking.lat,
                lon: startStopParking.lon,
                parkingId: startStopParking.parkingId,
                type: "startStop",
                hasMyPlaces: false
            });
        }

        this.extendedParkingInfo = Enumerable.from(result).orderBy(d => d.distance).toArray();
    }

    private loadStartStopParkings(){
        for(let startStopParking of this.OriginalResult.unnumberedSpotsParkings.entries){
            this.StartStopParkings.push(getStartStopParkingInfo(startStopParking));            
        }
    }

    private determineStatus(): status {
        const anyPlaces = !!this.AllFreeSpots.length;
        const parkingId = this.searchTarget.getParkingId();
        if(parkingId){
            const anyPlaceOnSearchedParking: boolean = Enumerable.from(this.FreeSpotsOnMyParkings).any(place => place.parkingId === parkingId);
            return anyPlaceOnSearchedParking ? "showPlaces" : "showAsk";
        }

        if(anyPlaces){
            return "showPlaces";
        }
        if(!anyPlaces){
            return "showAsk";
        }

        throw new Error("undetermined status");
    }

    private loadMyParking(){
        for(let parking of this.OriginalResult.userParkings){
            const parkingInfo = getParkingInfo(parking);

            this.MyParkings.push(parkingInfo);
            this.AllParkings.push(parkingInfo);

            for(let parkingSpot of parking.spots){
                if(parkingSpot.isAvailable){
                    const parkingSpotInfo = getParkingSpotInfo(parking, parkingSpot);

                    this.FreeSpotsOnMyParkings.push(parkingSpotInfo);
                    this.AllFreeSpots.push(parkingSpotInfo);
                }
                else{
                    this.AllNonFreeSpots.push(getParkingSpotInfo(parking, parkingSpot));
                }
            }
        }
    }

    private loadNearbyResults(){
        for(let parking of this.OriginalResult.nearbyParkings){
            const parkingInfo = getParkingInfo(parking);

            this.noMyParkings.push(parkingInfo);
            this.AllParkings.push(parkingInfo);

            for(let parkingSpot of parking.spots){
                if(parkingSpot.isAvailable){
                    const parkingSpotInfo = getParkingSpotInfo(parking, parkingSpot);
    
                    this.FreeSpotsOnNoMyParkings.push(parkingSpotInfo);
                    this.AllFreeSpots.push(parkingSpotInfo);
                }
                else{
                    this.AllNonFreeSpots.push(getParkingSpotInfo(parking, parkingSpot));
                }
            }
        }
    }
}

const getParkingInfo = (parking: IParkingSearchInfo): ParkingInfo => ({
    city: parking.city,
    hasFreePlaces: parking.hasFreePlacesLeft,
    lat: parking.latitude,
    lon: parking.longitude,
    parkingId: parking.parkingId,
    streetName: parking.streetName,
    streetNumber: parking.streetNumber,
    distance: parking.distance,
    hasMySpot: parking.includesMySpots,
    hasOnlyMySpots: (parking.spots || []).every(ps => ps.isMyParkingSpot)
});

const getParkingSpotInfo = (parking: IParkingSearchInfo, parkingSpot: IParkingSpotSearchInfo): ParkingSpotInfo => ({
    averageTotalRating: parkingSpot.averageTotalRating,
    pricePerHour: parkingSpot.effectivePricePerHour,
    price: parkingSpot.effectivePrice,
    spotId: parkingSpot.id,
    ownerName: parkingSpot.ownerName,
    city: parking.city,
    distance: parking.distance,
    streetName: parking.streetName,
    streetNumber: parking.streetNumber,
    lat: parking.latitude,
    lon: parking.longitude,
    parkingId: parking.parkingId,
    isMySpot: parkingSpot.isMyParkingSpot
});

const getStartStopParkingInfo = (startStopParking: UnnumberedSpotsParkingSearchResultEntry): StartStopParkingInfo => {
    const {friendlyName, address: {city, latitude, longitude, streetName, streetNumber}, distance, effectivePrice, hasVirtualPilot, parkingId,  parkingStatus, tariffType} = startStopParking;

    return {
        friendlyName,
        city,
        streetName,
        streetNumber,
        lat: latitude,
        lon: longitude,
        parkingId,
        distance,
        effectivePrice,
        hasVirtualPilot,
        parkingStatus,
        tariffType
    }
}