import * as React from "react";
import { useWindowSize } from "../../utils/WindowSizeHook";
import { StandardButton2 } from "../../styles/Buttons";
import Resources from "../../../Resources";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { MOBILE_WIDTH_THRESHOLD } from "../../utils/Constants";
import { ErrorCode } from "parkcash-api";
import { getErrorMessage } from "../../utils/ErrorUtils";
import Notify from "../../utils/Notify";
import { getSpotInfo, ParkingMapDataResponseResult, ParkingMapSpotInfo } from "../ParkingMap/Data";
import ParkingMap from "../ParkingMap";
import GlobalProgress from "../GlobalProgress";

export default (props: {
    mapResult: ParkingMapDataResponseResult,    
    initialSpotId: string,
    onCancel: () => void,
    onConfirm: (spotId: string, number: string, level: string, sector: string) => void,
    getSpotsCallback: (level: string, sector: string) => Promise<{error?: ErrorCode, spots?: ParkingMapSpotInfo[]}>
}) => {
    const {mapResult, onCancel, onConfirm, initialSpotId, getSpotsCallback} = props;
    const container = React.useRef<HTMLDivElement>();
    const {windowWidth, windowHeight} = useWindowSize();


    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    
    const [currentSpot, setCurrentSpot] = React.useState<string>(initialSpotId);


    const [submitProgress, setSubmitProgress] = React.useState(false);  

    const onConfirmed = async (id: string) => {
        if(!id){
            return;
        }

        setSubmitProgress(true);
        const {error, spot} = await getSpotInfo(id);
        if(error){
            Notify.Error(getErrorMessage(error));
        }
        else{
            onConfirm(spot.id, spot.number, spot.level, spot.sector);
        }
        setSubmitProgress(false);
    }
    
    return (
        <>
            <GlobalProgress visible={submitProgress} />
            <ParkingMap 
                getSpotsCallback={getSpotsCallback}
                mapResult={mapResult}
                onSpotSelected={(id) => (setCurrentSpot(id), setImmediate(() => onConfirmed(id)))}
                spotId={currentSpot}
                height={windowHeight - (isMobile ? 180 : 150)}
            />
            <BaseSpaceSeparator size={20} />
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{width: isMobile ? 170 : 200}}>
                    <StandardButton2 variant="tiny" onClick={onCancel}>{Resources.Powrot_do_formularza}</StandardButton2>
                </div>
            </div>
        </>
    );
}