import {ErrorCode} from "parkcash-api";
import Resources from "../../Resources";
import {ErrorWrapperOfObject} from "parkcash-api";

export const isErrorWrapper = (obj: any): obj is ErrorWrapperOfObject => obj && typeof obj.code === 'number';

export const getErrorMessageNew = (err: ErrorWrapperOfObject): string => {
    if(err.message) return err.message;
    return getErrorMessage(err.code)
}

export const getErrorMessage = (err: ErrorCode = ErrorCode.NetworkError): string => {
    
    
    switch (err) {
        case ErrorCode.NotAnError:
            return Resources.To_nie_jest_blad;
        case ErrorCode.NetworkError:
            return Resources.Blad_polaczenia_sieciowego_lub_wewnetrzny_blad_serwera;
        case ErrorCode.Unknown:
            return Resources.Nieznany_blad;
        case ErrorCode.DuplicatedUser:
            return Resources.Zduplikowany_uzytkownik;
        case ErrorCode.ThirdPartyLoginFailed:
            return Resources.Blad_logowania_zewnetrznego;
        case ErrorCode.AccountNotActivated:
            return Resources.Konto_uzytkownika_nie_jest_aktywne;
        case ErrorCode.ValidationError:
            return Resources.Blad_walidacji;
        case ErrorCode.UnhandledException:
            return Resources.Blad_wewnetrzny_serwera;
        case ErrorCode.DuplicatedParkingSpot:
            return Resources.Zduplikowane_miejsce_parkingowe;
        case ErrorCode.ReservationMakingCollison:
            return Resources.Nastapil_konflikt_w_robieniu_rezerwacji;
        case ErrorCode.ParkingSpotNotFound:
            return Resources.Miejsce_parkingowe_nie_zostalo_znalezione;
        case ErrorCode.UserAccountDoesNotExist:
            return Resources.Konto_nie_istnieje;
        case ErrorCode.UserDoesNotExist:
            return Resources.Uzytkownik_nie_istnieje;
        case ErrorCode.ReservationDoNotExist:
            return Resources.Rezerwacja_nie_istnieje;
        case ErrorCode.NativeLoginFailed:
            return Resources.Niepoprawne_email_lub_haslo;
        case ErrorCode.InvalidCredentials:
            return Resources.Niepoprawne_email_lub_haslo;
        case ErrorCode.RatingTicketAlreadyUsed:
            return Resources.Rezerwacja_zostala_juz_oceniona;
        case ErrorCode.CancellingPendingReservationIsForbidden:
            return Resources.Nie_mozna_odwolac_rezerwacji_ktora_sie_juz_rozpoczela_lub_zaraz_rozpocznie;
        case ErrorCode.ReservationDetailsRetrievalForbidden:
            return Resources.Nie_mozna_pobrac_szczegolow_rezerwacji;
        case ErrorCode.PasswordResetCodeInvalid:
            return Resources.Podany_kod_resetujacy_jest_niepoprawny;
        case ErrorCode.PasswordResetCodeExpired:
            return Resources.Wyslany_kod_nie_jest_juz_aktualny;
        case ErrorCode.UserIsBanned:
            return Resources.Uzytkownik_zostal_zbanowany;
        case ErrorCode.SharingRequestAlreadyAccepted:
            return Resources.Zapytanie_zostalo_juz_zaakceptowane;
        case ErrorCode.AllPlacesAlreadyShared:
            return Resources.Wszystkie_miejsca_zostaly_juz_udostepnione;
        case ErrorCode.SharingRequestAlreadyOutdated:
            return Resources.Zapytanie_o_miejsce_jest_juz_nieaktualne;
        case ErrorCode.ParkingSpotNotAvailable:
            return Resources.Miejsce_parkingowe_nie_jest_dostepne;
        case ErrorCode.UserNotFound:
            return Resources.Nie_ma_takiego_uzytkownika;
        case ErrorCode.PrivateParkingIdentifierIsNotUnique:
            return Resources.Identyfikator_prywatnego_parkingu_nie_jest_unikalny;
        case ErrorCode.IncorrectParkingIdOrPIN:
            return Resources.Niepoprawny_identyfikator_lub_pin;
        case ErrorCode.UserIsAlreadyParkingMember:
            return Resources.Uzytkownik_jest_juz_czlonkiem_grupy;
        case ErrorCode.IncorrectVirtualPilotPIN:
            return Resources.Niepoprawny_identyfikator_lub_pin;
        case ErrorCode.AccessToVirtualPilotExpired:
            return Resources.Dostep_do_wirtualnego_pilota_wygasl;
        case ErrorCode.VirtualPilotDriverError:
            return Resources.Blad_sterownika_mobilnego_pilota;
        case ErrorCode.AggregateDoesNotExist:
            return Resources.Obiekt_nie_zostal_znaleziony; 
        case ErrorCode.DuplicatedPrivateParkingPublicId:
            return Resources.Zduplikowany_identyfikator_publiczny_parkingu;
        case ErrorCode.MandatoryAgreementsNotAccepted:
            return Resources.Obowiazkowa_zgoda_nie_zostala_zaakceptowana;
        case ErrorCode.VirtualPilotAlreadyAssignedToUser:
            return Resources.Uzytkownik_posiada_juz_mobilnego_pilota;
        case ErrorCode.SharingRequestWasDeleted:
            return Resources.Zapytanie_zostalo_juz_usuniete;
        case ErrorCode.SharingRequestWasIgnored:
            return Resources.Zapytanie_o_miejsce_zostalo_odrzucone;
        case ErrorCode.InvitationAlreadyAccepted:
        case ErrorCode.Unautharized:
            return Resources.Brak_dostepu;
        case ErrorCode.VirtualPilotIsNotYetActive:
            return Resources.Mobilny_pilot_nie_jest_jeszcze_aktywny;
        case ErrorCode.OneTimeLinkAlreadyUsed:
            return Resources.Udostepnienie_pilota_zostalo_juz_wykorzystane;
        case ErrorCode.OneTimeLinkMemberAlreadyUsed:
            return Resources.Nastapil_blad;
        case ErrorCode.InvitationLinkNotFound:
            
        case ErrorCode.MobilePilotLinkNotFound:
            return Resources.Udostepnienie_pilota_nie_zostalo_znalezione;
        case ErrorCode.UnauthorizedVirtualPilotSharingAttempt:
            return Resources.Uzytkownik_nie_moze_udostepniac_pilota;
        case ErrorCode.UserIsNotAssignedToVirtualPilot:
            return Resources.Uzytkownik_nie_ma_dostepu_do_pilota;
        case ErrorCode.VirtualPilotLinkWasBlockedByIssuer:
            return Resources.Link_do_pilota_zostal_zablokowany_przez_wystawce;
        case ErrorCode.QrCodeNotFound:
            return Resources.Kod_QR_nie_zostal_znaleziony;
        case ErrorCode.ReservationTransferIsDisabled:
            return Resources.Przekazanie_miejsca_jest_wylaczone;
        case ErrorCode.UserIsNotParkingMember:
            return Resources.Uzytkownik_nie_jest_czlonkiem_parkingu;
        case ErrorCode.UserIsToFarFromParking:
            return Resources.Uzytkownik_jest_zbyt_daleko_od_parkingu;
        case ErrorCode.StartWebPaymentFailed:
            return Resources.Blad_inicjalizacji_platnosci_webowej;
        case ErrorCode.ExecuteCreditCardTransactionFailed:
            return Resources.Blad_transakcji_karty_kredytowej;
        case ErrorCode.StaleData:
            return Resources.Nieaktualne_dane;
        case ErrorCode.ParkingIsClosed:
            return Resources.Parking_jest_juz_zamkniety;
        case ErrorCode.UnfinishedStartStopProcess:
            return Resources.Masz_niezakonczone_parkowanie;
        case ErrorCode.StartStopAccessCodeAlreadyUsed:
            return Resources.Kod_dostepu_do_parkowania_zostal_juz_uzyty;
        case ErrorCode.AccessCodeAlreadyUsed:
            return Resources.Kod_dostepu_zostal_juz_uzyty;
        case ErrorCode.SubscriptionProlongationLinkExpired:
            return Resources.Link_do_przedluzenia_abonamentu_wygasl;
        case ErrorCode.XControlApiError:
            return Resources.Blad_integracji_XControl;
        case ErrorCode.SubscriptionRegisteringCollison:
            return Resources.Konflikt_przy_tworzeniu_abonamentu;
        case ErrorCode.SubscriptionProlongationLinkAlreadyUsed:
            return Resources.Link_do_przedluzenia_zostal_juz_wykorzystany;
        case ErrorCode.SubscriptionDiscountCodeExpired:
            return Resources.Podany_kod_promocyjny_wygasl;
        case ErrorCode.SubscriptionDiscountCodeAlreadyUsed:
            return Resources.Podany_kod_promocyjny_zostal_juz_uzyty;
        case ErrorCode.SubscriptionDiscountCodeNotFound:
            return Resources.Nieprawidlowy_kod_promocyjny;
        case ErrorCode.MultiplePromotionsUse:
            return Resources.Nie_mozna_skorzystac_z_wielu_promocji_na_raz;
        case ErrorCode.QueryResultIsEmpty:
            return Resources.Nie_znaleziono_zadnych_elementow;
        case ErrorCode.InvalidParkingInvitationCode:
            return Resources.Nieprawidlowy_kod_zaproszenia_do_parkingu;
        case ErrorCode.PowerUserCanNotLeaveGroupByHimself:
            return Resources.Administrator_lub_moderator_nie_moze_opuscic_grupy;
        case ErrorCode.SmsVerificationAllowedFrequencyExceeded:
            return Resources.Zbyt_duzo_prob_weryfikacji_numeru_Sprobuj_za_kilka_minut;
        case ErrorCode.SmsVerificationCodeExpired:
            return Resources.Nieprawidlowy_kod_weryfikacyjny;
        case ErrorCode.SmsVerificationCodeInvalid:
            return Resources.Nieprawidlowy_kod_weryfikacyjny;
        case ErrorCode.SmsVerificationCodeAlreadyUsed:
            return Resources.Podany_kod_weryfikacyjny_zostal_juz_uzyty;
        case ErrorCode.SubscriptionCustomStartNotAllowed:
            return Resources.Nie_mozna_kupic_abonamentu_od_podanego_dnia;
        case ErrorCode.SubscriptionRegistrationFailed:
            return Resources.Blad_zakupu_abonamentu;
        case ErrorCode.SubscriptionPurchaseGeneralError:
            return Resources.Blad_zakupu_abonamentu;
        case ErrorCode.SubscriptionIsNotActive:
            return Resources.Administrator_parkingu_wylaczyl_abonament;
        case ErrorCode.SubscriptionDiscountCodeNotActive:
            return Resources.Kod_promocyjny_zostal_wylaczony_przez_administratora_parkingu;
        case ErrorCode.ExternalReservationRegisteringCollision:
            return Resources.Ktos_zarezerwowal_juz_wybrane_miejsce;
        case ErrorCode.SubscriptionAlreadyProlongedExtensionIsForbbiden:
            return Resources.Nie_mozna_przedluzyc_abonamentu_juz_przedluzonego_przez_uzytkownika;
        case ErrorCode.SubscriptionPurchaseExtensionInvalidDate:
            return Resources.Podana_data_musi_byc_pozniejsza_niz_aktualna_data_zakonczenia_abonamentu;
        case ErrorCode.TechnicalBufferRegisteringCollision:
            return Resources.Nastapil_blad;
        case ErrorCode.ExternalReservationsServiceIsDisabled:
            return Resources.Usluga_rezerwacji_zewnetrznej_jest_obecnie_wylaczona;
        case ErrorCode.DiscountCodeIsNotUnique:
            return Resources.Kod_promocyjny_musi_byc_unikalny;
        case ErrorCode.ExternalTicketPaymentIsDisabled:
            return Resources.usluga_platnosci_zostala_wylaczona_dla_parkingu;
        case ErrorCode.ExternalAccessControlProviderCommunicationFailed:
            return Resources.blad_komunikacji_z_systemem_kontroli_dostepu;
        case ErrorCode.ExternalTicketCanNotBePaid:
            return Resources.bilet_nie_moze_zostac_oplacony;
        case ErrorCode.ExternalTicketNotFound:
            return Resources.nie_znaleziono_biletu_dla_tej_rejestracji;
        case ErrorCode.EmailAddressAlreadyConfirmed:
            return Resources.Adres_email_zostal_juz_potwierdzony;
        case ErrorCode.InvalidEmailAddressConfirmationCode:
            return Resources.Nieprawidlowy_kod_potwierdzajacy_email;
        case ErrorCode.ReservationPolicyViolation:
            return Resources.Naruszenie_polityki_rezerwacji;
        case ErrorCode.EmailConfirmationRequired:
            return Resources.Wymagane_potwierdzenie_adresu_email;
        case ErrorCode.PhoneNumberConfirmationRequired:
            return Resources.Wymagane_potwierdzenie_numeru_telefonu;
        case ErrorCode.ExternalProfileIdSetRequired:
            return Resources.Wymagane_ustawienie_identyfikatora_profilu;
        case ErrorCode.StartStopParkingCapicityLimitExceeded:
            return Resources.Brak_wolnych_miejsc_na_parkingu;
        case ErrorCode.SpotCreationAllowedOnlyForParkingManager:
            return Resources.Nastapil_blad;
        case ErrorCode.PasswordNotSet:
            return Resources.Haslo_do_konta_nie_zostalo_ustawione_skorzystaj_z_instrukcji_jaka_wyslalismy_na_Twoj_email_po_utworzeniu_konta_lub_skontaktuj_sie_z_administratorem_parkingu_firmowego;
        case ErrorCode.PasswordCreationCodeDoesNotExist:
            return Resources.Kod_ustawiajacy_haslo_nie_istnieje;
        case ErrorCode.PasswordCreationForbidden:
            return Resources.Ustawienie_hasla_zabronione;
        case ErrorCode.PasswordCreationCodeAlreadyUsed:
            return Resources.Kod_ustawiajacy_haslo_zostal_juz_wykorzystany;
        case ErrorCode.PasswordCreationCodeInvalid:
            return Resources.Nieprawidlowy_kod_ustawiajacy_haslo;
        case ErrorCode.ReservationTemporarilyBlockedForParkingMember:
            return Resources.Możliwosc_rezerwacji_zostala_zablokowana_przez_administratora_parkingu;
        case ErrorCode.ReservationForbiddenForCurrentVehicleEngineType:
            return Resources.Autem_o_tym_typie_silnika_nie_mozna_parkowac_na_tym_miejscu;
        case ErrorCode.CanceledSubscriptionCanNotBeProlonged:
            return Resources.Anulowany_abonament_nie_moze_zostac_przedluzony;
        case ErrorCode.BlikError:
            return Resources.Blad_platnosci_blik;
        case ErrorCode.UniquenessViolation:
            return Resources.Naruszenie_unikalnosci;
        case ErrorCode.GenericErrorWithMessage:
            return Resources.Nastapil_blad;
        default:
            return Resources.Nastapil_blad;
    }
};
