import { ServiceConfig, VirtualPilotLinkOpenDto, VirtualPilotsService } from "parkcash-api";
import Resources from "../../Resources";
import { getErrorMessage } from "../utils/ErrorUtils";
import { useJWT } from "../utils/JWTUtils";
import Notify from "../utils/Notify";

export const openVirtualPilot = async (pilotId: string, linkId: string): Promise<boolean> => {
    const jwt = useJWT();
    try{
        await new Promise(resolve => setTimeout(resolve, 500));
        const response = await new VirtualPilotsService(new ServiceConfig({jwt})).openV2(false, new VirtualPilotLinkOpenDto({
            virtualPilotId: pilotId,
            linkId,
            operationType: undefined
        }));
        if(!response.isSuccess){
            Notify.Error(getErrorMessage(response.error.code));
            return false;
        }
        else{
            return true;
        }
    }
    catch{
        Notify.Error(getErrorMessage());
        return false;
    }
}