import * as React from "react";
import {unmountComponentAtNode, render} from "react-dom";
import Resources from "../../Resources";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { PCClasses } from "../utils/CSSUtils";
import "./ConfirmModal.scss";
import { useWindowSize } from "../utils/WindowSizeHook";
import { StandardButton, StandardButton2 } from "../styles/Buttons";
import { PCText } from "../styles/Texts";

const modal = document.getElementById("modal");
const szlaban = require("../../assets/images/szlaban.svg");

interface ShowArgs {
    text: string,
    confirmText?: string,
    icon?: string,
    iconWidth?: number,
    iconHeight?: number
}



export default class ConfirmModal {
    static show(args: ShowArgs){
        return new Promise<boolean>(resolve => {

            const onConfirm = () => {
                resolve(true);
                unmountComponentAtNode(modal);
            }

            const onCancel = () => {
                resolve(false);
                unmountComponentAtNode(modal);
            }

            render((
                <TargetModal 
                    onCancel={onCancel}
                    onConfirm={onConfirm}
                    {...args}
                />
            ), modal)
        });
    }
}



interface ModalProps extends ShowArgs {
    onConfirm: () => void;
    onCancel: () => void;
}

const TargetModal = (props: ModalProps) => {
    const {windowHeight, windowWidth} = useWindowSize();
    const {
        icon = szlaban,
        iconWidth = 100,
        iconHeight = 59,
        text,
        onCancel,
        onConfirm,
        confirmText=Resources.Potwierdz
    } = props;
    const hideOffset = windowHeight/2 + 200;
    const [offset, setOffset] = React.useState(hideOffset);

    const show = () => {
        setOffset(0);
    }

    const hide = (result: boolean) => {
        setOffset(hideOffset);
        setTimeout(() => {
            result ? onConfirm() : onCancel();
        }, 200);
        
    }

    React.useEffect(() => {
        show();
    }, []);

    return (
        <div 
            className={PCClasses("pc-confirmmodal-wrapper")}
        >
            <div className={PCClasses("pc-confirmmodal-container")} style={{transform: `translateY(${offset}px)`}}>
                <div className={PCClasses("pc-confirmodal-contentcontainer")}>
                    <PCText color={Colors.black} textAlign="center" fontSize={24} semibold>{text}</PCText>
                    <div />
                    <div style={{flex: 1, minHeight: 30}} />
                    <img src={icon} height={iconHeight} width={iconWidth} />
                    <div style={{flex: 1, minHeight: 30}}/>
                    <StandardButton onClick={() => hide(true)}>{confirmText}</StandardButton>
                </div>
                <BaseSpaceSeparator size={40} />
                <div 
                    className={PCClasses("pc-confirmmodal-closebutton")}
                    onClick={() => hide(false)}
                >
                    <FontAwesomeIcon icon={faTimes} color={Colors.white} style={{fontSize: 18}}/>
                </div>
            </div>
        </div>
    )
}




interface YesNoShowArgs {
    text: string,
    yesText?: string,
    noText?: string,
    icon?: string,
    iconWidth?: number,
    iconHeight?: number,
    width?: number
}


export class YesNoModal {
    static show(args: YesNoShowArgs){
        return new Promise<boolean>(resolve => {

            const onYes = () => {
                resolve(true);
                unmountComponentAtNode(modal);
            }

            const onNo = () => {
                resolve(false);
                unmountComponentAtNode(modal);
            }

            render((
                <YesNoTargetModal 
                    onNo={onNo}
                    onYes={onYes}
                    {...args}
                />
            ), modal)
        });
    }
}

interface YesNoModalProps extends YesNoShowArgs {
    onYes: () => void;
    onNo: () => void;
}

const YesNoTargetModal = (props: YesNoModalProps) => {
    const {windowHeight, windowWidth} = useWindowSize();
    const {
        icon = szlaban,
        iconWidth = 100,
        iconHeight = 59,
        text,
        noText = Resources.Nie,
        yesText = Resources.Tak,
        onNo,
        onYes,
        width
    } = props;
    const hideOffset = windowHeight/2 + 200;
    const [offset, setOffset] = React.useState(hideOffset);

    const show = () => {
        setOffset(0);
    }

    const hide = (result: boolean) => {
        setOffset(hideOffset);
        setTimeout(() => {
            result ? onYes() : onNo();
        }, 200);
        
    }

    React.useEffect(() => {
        show();
    }, []);

    return (
        <div 
            className={PCClasses("pc-confirmmodal-wrapper")}
        >
            <div className={PCClasses("pc-confirmmodal-container")} style={{transform: `translateY(${offset}px)`}}>
                <div className={PCClasses("pc-confirmodal-contentcontainer")} style={{width}}>
                    <PCText color={Colors.black} textAlign="center" fontSize={24} semibold>{text}</PCText>
                    <div />
                    <div style={{flex: 1, minHeight: 30}} />
                    <img src={icon} height={iconHeight} width={iconWidth} />
                    <div style={{flex: 1, minHeight: 30}}/>
                    <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                        <div style={{flex: 1}}><StandardButton2 onClick={() => hide(false)}>{noText}</StandardButton2></div>
                        <BaseSpaceSeparator size={10} />
                        <div style={{flex: 1}}><StandardButton onClick={() => hide(true)}>{yesText}</StandardButton></div>
                    </div>
                </div>
            </div>
        </div>
    )
}