import * as React from "react";
import Colors from "../styles/Colors";
import { PCText } from "../styles/Texts";

export default (props: {children: React.ReactNode}) => {
    return (
        <PCText color={Colors.greyishBrown} semibold fontSize={14}>
            {props.children}
        </PCText>
    );
}

export const BigFormLabel = (props: {children: React.ReactNode}) => {
    return (
        <PCText color={Colors.greyishBrown} semibold fontSize={18}>
            {props.children}
        </PCText>
    );
}