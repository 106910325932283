import { SortOrder } from "parkcash-api";
import React from "react";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { Column } from "./Column";
import { BORDER_RADIUS, DEFAULT_COLUMN_WIDTH, HEADER_BACKGROUND_COLOR } from "./Constants";

const arrows_neutral = require("../../../assets/images/icon-arrows.svg");
const arrows_asc = require("../../../assets/images/icon-arrows-asc.svg");
const arrows_desc = require("../../../assets/images/icon-arrows-desc.svg");

const getColumnsWidths = (columns: Column<any>[], totalWidth: number) => {
    const allWidths = columns.map(c => c.width || DEFAULT_COLUMN_WIDTH);
    const fixedColumnsWidth = allWidths.reduce<number>((prev, current) => {
        if(typeof current === "string"){
            prev += parseInt(current);
        }

        return prev;
    }, 0);

    const nonFixedColumnsWidth = allWidths.reduce<number>((prev, current) => {
        if(typeof current === "number"){
            prev += current;
        }

        return prev;
    }, 0);

    return allWidths.map(w => {
        if(typeof w === "number"){
            return w/nonFixedColumnsWidth * (totalWidth - fixedColumnsWidth);
        }
        else{
            return parseInt(w);
        }
    });
};

export default class TableHeader<T> extends React.Component<{
    disableSorting: boolean;
    sort: Array<{key: keyof T, order: SortOrder}>;
    columns: Array<Column<T>>;
    wrapperWidth: number;
    onColumnClicked: (column: Column<T>) => void;
}> {

    private isColumnSortable(column: Column<T>): boolean {
        const {disableSorting} = this.props;
        const {disableSort, dataField} = column;
        return !disableSorting && !disableSort && !!dataField;
    }

    private columnSortOrder(column: Column<T>): SortOrder {
        const sortIndex = this.props.sort.findIndex(v => v.key === column.dataField);
        if(sortIndex >= 0){
            return this.props.sort[sortIndex].order;
        }
        else{
            return null;
        }
    }

    private columnSortIndex(column: Column<T>): number {
        const sortIndex = this.props.sort.findIndex(v => v.key === column.dataField);
        if(sortIndex >= 0){
            return sortIndex + 1;
        }
        else{
            return null;
        }
    }

    render(){
        const {columns, disableSorting, sort, wrapperWidth} = this.props;
        const columnsWidth = getColumnsWidths(columns, wrapperWidth);
        const hasMultipleSorts = sort.length > 1;
        return (
            <thead>
                <tr 
                    style={{textAlign: "left"}}
                >
                    {columns.map((column, index, arr) => {
                        const {label} = column;
                        const isColumnSortable = this.isColumnSortable(column);
                        const columnSortIndex = this.columnSortIndex(column);
                        const columnSortOrder = this.columnSortOrder(column);
                        const isFirst = index === 0;
                        const isLast = index === arr.length - 1;
                        
                        return (
                            <th 
                                
                                key={index} 
                                style={{
                                    width: columnsWidth[index],
                                    paddingTop: 15,
                                    paddingBottom: 15,                   
                                    paddingRight: 30,                   
                                    backgroundColor: HEADER_BACKGROUND_COLOR,
                                    paddingLeft: isFirst ? 30 : 0,
                                    borderTopLeftRadius: isFirst ? BORDER_RADIUS : 0,
                                    borderTopRightRadius: isLast ? BORDER_RADIUS : 0
                                }}
                            >
                                <div 
                                    style={{
                                        height: 20, 
                                        display: 'inline-flex', 
                                        flexDirection: 'row', 
                                        alignItems: 'center',
                                        cursor: isColumnSortable ? "pointer" : undefined
                                    }}
                                    onClick={() => isColumnSortable && this.props.onColumnClicked(column)} 
                                >
                                    <PCText semibold color={Colors.white} fontSize={14} lineHeight={1.42}>{label}</PCText>
                                    {isColumnSortable && (
                                        <>
                                            <BaseSpaceSeparator size={10} />
                                            {columnSortOrder === null && <img width={8} height={14} src={arrows_neutral} />}
                                            {columnSortOrder === SortOrder.Ascending && <img width={8} height={14} src={arrows_asc} />}
                                            {columnSortOrder === SortOrder.Descending && <img width={8} height={14} src={arrows_desc} />}
                                            {hasMultipleSorts && !!columnSortIndex && (
                                                <>
                                                    <BaseSpaceSeparator size={10} />
                                                    <PCText color={Colors.white} fontSize={10}>{columnSortIndex}</PCText>
                                                </>
                                            )}
                                        </>
                                    )}
                                    
                                </div>
                            </th>
                        );
                    })}                                                
                </tr>
            </thead>
        );
    }
}