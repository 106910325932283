import { Formik, FormikHelpers } from "formik";
import { ErrorCode, GetExternalReservationParkingConfigurationResult, SpotSelectorType } from "parkcash-api";
import * as React from "react";
import { useParams } from "react-router";
import Resources from "../../Resources";
import { BaseSpaceSeparator } from "../styles/Separators";
import { MOBILE_WIDTH_THRESHOLD } from "../utils/Constants";
import { getErrorMessage } from "../utils/ErrorUtils";
import { useWindowSize } from "../utils/WindowSizeHook";
import { checkExternalReservationSpotAvailable, createExternalReservation, getExternalReservationInitialFormState, getExternalReservationParkingConfiguration, getExternalReservationValidationSchema } from "./ExternalReservationsUtils";
import ExternalReservationFormState from "./formInterfaces/ExternalReservationFormState";
import BigForm, { BIG_FORM_HEADER_HEIGHT } from "./formParts/BigForm";
import FormWrapper from "./formParts/FormWrapper";
import PaymentPart, { PAYMENT_PART_HEADER_HEIGHT } from "./formParts/PaymentPart";
import { DESKTOP_HEADER_DEFAULT_HEIGHT } from "../layouts/Common/DesktopHeaderBase";
import ErrorView from "../components/ErrorView";
import Spinner from "../components/Spinner";
import TargetFormPart from "./formParts/TargetFormPart";
import InvoiceFormPart from "./formParts/InvoiceFormPart";
import AcceptRegulations from "./formParts/AcceptRegulations";
import ExternalReservationSpecyficPart from "./formParts/ExternalReservationSpecyficPart";
import { ISelectedPaymentInfo, PaymentMethodsTypes } from "./formParts/PaymentMethod";
import {getWebPaymentConfiguration, validateBlik} from "../utils/PaymentConfigurationUtils";


export default () => {
    const {parkingId} = useParams<{parkingId: string}>();
    const {windowHeight, windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const [progress, setProgress] = React.useState(true);
    const [error, setError] = React.useState<ErrorCode | string>(null);
    const [initialValues, setInitialValues] = React.useState<ExternalReservationFormState>(getExternalReservationInitialFormState(false, SpotSelectorType.List, null, false));
    const [validationSchema] = React.useState(getExternalReservationValidationSchema());
    const [parkingInfo, setParkingInfo] = React.useState<GetExternalReservationParkingConfigurationResult>(null);
    const [selectedPaymentMethodInfo, setSelectedPaymentMethodInfo] = React.useState<ISelectedPaymentInfo>();
    const [allowedPaymentMethods, setAllowedPaymentMethods] = React.useState<PaymentMethodsTypes[]>();
    const [blikError, setBlikError] = React.useState<string>();

    const onSubmit = async (state: ExternalReservationFormState, helpers: FormikHelpers<ExternalReservationFormState>) => {

        if (!validateBlik(selectedPaymentMethodInfo, e => setBlikError(e))) {
            return;
        }

        helpers.setSubmitting(true);

        const availableResponse = await checkExternalReservationSpotAvailable(state);
        if (availableResponse.error) {
            helpers.setStatus(getErrorMessage(availableResponse.error))
        } else if (!availableResponse.available) {
            helpers.setStatus(Resources.Wybrane_miejsce_nie_jest_dostepne);
        } else {
            const {
                isBlikError,
                message,
                webPaymentUrl
            } = await createExternalReservation(state, selectedPaymentMethodInfo.blikCode);
            if (message) {
                if (isBlikError) {
                    setBlikError(message)
                }
                else {
                    helpers.setStatus(message)
                }
               
            } else {
                window.location.href = webPaymentUrl;
            }
        }

        helpers.setSubmitting(false);
    }

    React.useEffect(() => {
        const init = async () => {
            setProgress(true);
            const {error, result} = await getExternalReservationParkingConfiguration(parkingId);
            if(error){
                setError(error);
            }
            else{
                setParkingInfo(result);
                setInitialValues(getExternalReservationInitialFormState(result.enableParticularSpotPurchase, result.spotSelectorType, parkingId, result.requireLicensePlateNumber));

                const allowedPaymentMethods = await getWebPaymentConfiguration(parkingId);
                setAllowedPaymentMethods(allowedPaymentMethods);
            }
            setProgress(false);
        }
        init();
    }, []);

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {args => {
                const {isSubmitting, status, handleSubmit, values, errors, touched, setFieldValue, setFieldTouched} = args;
                return (
                    <FormWrapper isMobile={isMobile}>
                        {progress && (
                            <BigForm 
                                isMobile={isMobile}
                                maxHeight={windowHeight - BIG_FORM_HEADER_HEIGHT - DESKTOP_HEADER_DEFAULT_HEIGHT - 100}
                                title={Resources.Parametry_rezerwacji}

                            >
                                <div style={{height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <Spinner size="medium"/>
                                </div>
                            </BigForm>
                        )}
                        {!!error && (
                            <BigForm 
                                isMobile={isMobile}
                                maxHeight={windowHeight - BIG_FORM_HEADER_HEIGHT - DESKTOP_HEADER_DEFAULT_HEIGHT - 100}
                                title={Resources.Parametry_rezerwacji}

                            >
                                <div style={{height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <ErrorView title={typeof error === "number" ? getErrorMessage(error) : error} />
                                </div>
                            </BigForm>
                        )}
                        {!progress && !error && (
                            <>
                                <div>
                                    <BigForm
                                        isMobile={isMobile}
                                        maxHeight={10000}
                                        title={Resources.Parametry_rezerwacji}
                                        noOverflow
                                    >
                                        <ExternalReservationSpecyficPart 
                                            args={args}
                                            isMobile={isMobile}
                                            parkingInfo={parkingInfo}
                                            onTarrifsError={setError}
                                        />
                                    </BigForm>
                                    <BaseSpaceSeparator size={isMobile ? 7 : 30} />
                                    <BigForm
                                        isMobile={isMobile}
                                        maxHeight={10000}
                                        title={Resources.Dane_rezerwacji}
                                    >
                                        <TargetFormPart 
                                            args={args}
                                            isMobile={isMobile}    
                                        />
                                        <BaseSpaceSeparator size={40} />
                                        <AcceptRegulations 
                                            args={args}
                                            parkingRegulationsUrl={parkingInfo.generalParkingRegulationUrl}
                                            privacyPolicyUrl={parkingInfo.privacyPolicyRegulationUrl}
                                            saleRegulationsUrl={parkingInfo.salesRegulationUrl}
                                        />
                                        <BaseSpaceSeparator size={40} />
                                        <InvoiceFormPart 
                                            isMobile={isMobile}
                                            args={args}
                                        />
                                    </BigForm>
                                </div>
                                <BaseSpaceSeparator size={isMobile ? 7 : 30} />
                                <PaymentPart 
                                    isMobile={isMobile}
                                    maxHeight={windowHeight - PAYMENT_PART_HEADER_HEIGHT - DESKTOP_HEADER_DEFAULT_HEIGHT - 100}
                                    status={status}                          
                                    onPay={selectedPaymentMethodInfo => {
                                        setSelectedPaymentMethodInfo(selectedPaymentMethodInfo)
                                        handleSubmit();
                                    }}
                                    price={values.currentPrice}
                                    showPromotionCode={false}
                                    submitProgress={isSubmitting}
                                    priceProgress={values.currentPriceProgress}
                                    isValid
                                    allowedPaymentMethods={allowedPaymentMethods}
                                    onBlikErrorClear={() => setBlikError('')}
                                    blikError={blikError}
                                />
                            </>
                        )}
                    </FormWrapper>
                )
            }}
        </Formik>
    )
}