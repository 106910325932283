import React, { CSSProperties } from "react"

export const SOURCESANSPRO_REGULAR = "SourceSansProRegular";

export const SOURCESANSPRO_SEMIBOLD = "SourceSansProSemibold";

export const SOURCESANSPRO_BOLD = "SourceSansProBold";

interface TextProps {
    color: string;
    fontSize: number;
    children: React.ReactNode|any;
    onClick?: () => void;
    inline?: boolean;
    textAlign?: "center" | "left" | "right";
    fontWeight?: number | "bold" | "normal";
    semibold?: boolean;
    bold?: boolean;
    lineHeight?: number | "auto";
    textDecoration?: "underline" | "overline" | "line-through",
    fontStretch?: | "condensed"| "expanded" | "extra-condensed" | "extra-expanded" | "normal" | "semi-condensed" | "semi-expanded" | "ultra-condensed" | "ultra-expanded",
    fontStyle?: "normal"|"italic"|"oblique"|"initial"|"inherit",
    letterSpacing?: number | "normal",
    lineBreak?: "-moz-initial" | "inherit" | "initial" | "revert" | "unset" | "anywhere" | "auto" | "loose" | "normal" | "strict"

}

export const getTextStyles = (props: TextProps): CSSProperties => {
    const {
        color,
        fontSize,
        fontWeight = "normal",
        textAlign= "left",
        semibold = false,
        bold = false,
        lineHeight,
        textDecoration,
        fontStretch = "normal",
        fontStyle = "normal",
        letterSpacing = "normal",
        lineBreak
    } = props;

    return {
        fontFamily: semibold ? SOURCESANSPRO_SEMIBOLD : bold ? SOURCESANSPRO_BOLD : SOURCESANSPRO_REGULAR,
        color,
        fontSize,
        fontWeight,
        textAlign,
        lineHeight,
        textDecoration,
        fontStretch,
        fontStyle,
        letterSpacing,
        margin: 0,
        lineBreak
    }
}

export const PCText = (props: TextProps) => {
    const {inline = false, children, onClick = () => {}} = props;

    if(inline){
        return <span style={getTextStyles(props)} onClick={onClick}>{children}</span>
    }
    else{
        return <p className="pc-text" style={getTextStyles(props)} onClick={onClick}>{children}</p>
    }
}

