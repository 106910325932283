import { faPlusCircle, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { useParams } from "react-router-dom";
import Resources from "../../Resources";
import BigButton from "../components/BigButton";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { MOBILE_WIDTH_THRESHOLD } from "../utils/Constants";
import { useWindowSize } from "../utils/WindowSizeHook";

const subscription_type = require("../../assets/images/subscription_type.svg");

export default () => {
    const {windowHeight, windowWidth} = useWindowSize();
    const {parkingId, initialSubscriptionId} = useParams<{parkingId: string, initialSubscriptionId: string}>();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: isMobile ? 20 : 120,
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: isMobile ? 20 : 50
            }}
        >
            <img src={subscription_type} width={220} height={193} />
            <BaseSpaceSeparator size={40} />
            <div style={{width: isMobile ? 300 : 600}}>
                <PCText textAlign="center" semibold fontSize={isMobile ? 28 : 36} color={Colors.black}>
                    {Resources.Wybierz_czy_chcesz_przedluzyc_abonament_czy_wykupic_nowy}
                </PCText>
            </div>
            <BaseSpaceSeparator size={isMobile ? 40 : 100} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row'
                }}
            >
                <BigButton 
                    to={`/op/entersubscriptionphonenumber/${parkingId}`}
                    icon={faSyncAlt}
                    width={250}
                    height={150}
                    title={Resources.Przedluz}
                    subtitle={Resources.abonament}
                />
                <BaseSpaceSeparator size={isMobile ? 30 : 50} />
                <BigButton
                    to={`/op/createsubscription/${parkingId}${initialSubscriptionId ? `/${initialSubscriptionId}` : ""}`}
                    icon={faPlusCircle}
                    backgroundColor={Colors.saphire}
                    width={250}
                    height={150}
                    title={Resources.Nowy}
                    subtitle={Resources.abonament}
                />
            </div>
        </div>
    );
}