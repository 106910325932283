import * as React from "react";
import Colors from "../../styles/Colors";
import { PCText } from "../../styles/Texts";

export default (props: {
    color: string,
    children: React.ReactNode;
    colSpan: number;
}) => {
    const {children, colSpan, color} = props;

    return (
        <tr>
            <td colSpan={colSpan}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        backgroundColor: Colors.white,
                        height: 200,
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10
                    }}
                >
                    <PCText textAlign="center" color={color} semibold fontSize={20}>{children}</PCText>   
                </div> 
            </td>
        </tr>
    )
}