import React from 'react';
import {format} from 'date-fns';
// @mui
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import Card, {CardProps} from '@mui/material/Card';
import ListItemText from '@mui/material/ListItemText';
import TableContainer from '@mui/material/TableContainer';
// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import {TableHeadCustom} from '../../../components/table';
import CustomPopover, {usePopover} from '../../../components/custom-popover';

// ----------------------------------------------------------------------

type RowProps = {
    id: string; checkIn: Date; checkOut: Date; status: string; destination: {
        name: string; coverUrl: string;
    }; customer: {
        avatarUrl: string; name: string; phoneNumber: string;
    };
};

interface Props extends CardProps {
    title?: string;
    subheader?: string;
    tableLabels: any;
    tableData: RowProps[];
}

export default function BookingDetails({
                                           title,
                                           subheader,
                                           tableLabels,
                                           tableData,
                                           ...other
                                       }: Props) {
    return (<Card {...other}>
        <CardHeader title={title} subheader={subheader} sx={{mb: 3}}/>

        <TableContainer sx={{overflow: 'unset'}}>
            <Scrollbar>
                <Table sx={{minWidth: 960}}>
                    <TableHeadCustom headLabel={tableLabels}/>

                    <TableBody>
                        {tableData.map((row) => (<BookingDetailsRow key={row.id} row={row}/>))}
                    </TableBody>
                </Table>
            </Scrollbar>
        </TableContainer>

        <Divider sx={{borderStyle: 'dashed'}}/>

        <Box sx={{
            p: 2,
            textAlign: 'right'
        }}>
            <Button
                size="small"
                color="inherit"
                endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={18} sx={{ml: -0.5}}/>}
            >
                View All
            </Button>
        </Box>
    </Card>);
}

// ----------------------------------------------------------------------

type BookingDetailsRowProps = {
    row: RowProps;
};

function BookingDetailsRow({row}: BookingDetailsRowProps) {
    const theme = useTheme();

    const isLight = theme.palette.mode === 'light';

    const popover = usePopover();

    const handleDownload = () => {
        popover.onClose();
        console.info('DOWNLOAD', row.id);
    };

    const handlePrint = () => {
        popover.onClose();
        console.info('PRINT', row.id);
    };

    const handleShare = () => {
        popover.onClose();
        console.info('SHARE', row.id);
    };

    const handleDelete = () => {
        popover.onClose();
        console.info('DELETE', row.id);
    };

    return (<>
        <TableRow>
            <TableCell sx={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <Avatar
                    variant="rounded"
                    alt={row.destination.name}
                    src={row.destination.coverUrl}
                    sx={{
                        mr: 2,
                        width: 48,
                        height: 48
                    }}
                />
                {row.destination.name}
            </TableCell>

            <TableCell>
                <ListItemText
                    primary={row.customer.name}
                    secondary={row.customer.phoneNumber}
                    primaryTypographyProps={{
                        typography: 'body2',
                        noWrap: true
                    }}
                    secondaryTypographyProps={{
                        mt: 0.5,
                        component: 'span',
                        typography: 'caption',
                    }}
                />
            </TableCell>

            <TableCell>
                <ListItemText
                    primary={format(new Date(row.checkIn), 'dd MMM yyyy')}
                    secondary={format(new Date(row.checkIn), 'p')}
                    primaryTypographyProps={{
                        typography: 'body2',
                        noWrap: true
                    }}
                    secondaryTypographyProps={{
                        mt: 0.5,
                        component: 'span',
                        typography: 'caption',
                    }}
                />
            </TableCell>

            <TableCell>
                <ListItemText
                    primary={format(new Date(row.checkOut), 'dd MMM yyyy')}
                    secondary={format(new Date(row.checkOut), 'p')}
                    primaryTypographyProps={{
                        typography: 'body2',
                        noWrap: true
                    }}
                    secondaryTypographyProps={{
                        mt: 0.5,
                        component: 'span',
                        typography: 'caption',
                    }}
                />
            </TableCell>

            <TableCell>
                <Label
                    variant={isLight ? 'soft' : 'filled'}
                    color={(row.status === 'Paid' && 'success') || (row.status === 'Pending' && 'warning') || 'error'}
                >
                    {row.status}
                </Label>
            </TableCell>

            <TableCell align="right" sx={{pr: 1}}>
                <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                    <Iconify icon="eva:more-vertical-fill"/>
                </IconButton>
            </TableCell>
        </TableRow>

        <CustomPopover
            open={popover.open}
            onClose={popover.onClose}
            arrow="right-top"
            sx={{width: 160}}
        >
            <MenuItem onClick={handleDownload}>
                <Iconify icon="eva:cloud-download-fill"/>
                Download
            </MenuItem>

            <MenuItem onClick={handlePrint}>
                <Iconify icon="solar:printer-minimalistic-bold"/>
                Print
            </MenuItem>

            <MenuItem onClick={handleShare}>
                <Iconify icon="solar:share-bold"/>
                Share
            </MenuItem>

            <Divider sx={{borderStyle: 'dashed'}}/>

            <MenuItem onClick={handleDelete} sx={{color: 'error.main'}}>
                <Iconify icon="solar:trash-bin-trash-bold"/>
                Delete
            </MenuItem>
        </CustomPopover>
    </>);
}
