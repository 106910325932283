export const MOBILE_WIDTH_THRESHOLD = 961;
export const BIGSCREEN_WIDTH_THRESHOLD = 1025;

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const EMAIL_OR_EMPTY_REGEX = /(^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;

export const BANK_ACCOUNT_REGEX = /[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}/;

export const PHONE_REGEX = /\(\+[0-9]{2}\) [0-9]{3} - [0-9]{3} - [0-9]{3}/;

export const PHONE_OR_EMPTY_REGEX = /(^$|^\(\+[0-9]{2}\) [0-9]{3} - [0-9]{3} - [0-9]{3}$)/;

export const PHONE_MASK = "(+99) 999 - 999 - 999";
export const PHONE_MASK_WITHOUT_COUNTRY_CODE = "999 - 999 - 999";

export const POSTAL_CODE_MASK = "99-999";

export const POSTAL_CODE_REGEX = /[0-9]{2}-[0-9]{3}/;

export const NIP_MASK = "999-999-99-99";

export const NIP_REGEX = /[0-9]{3}-[0-9]{3}-[0-9]{2}-[0-9]{2}/;

export const BANK_ACCOUNT_MASK = "99 9999 9999 9999 9999 9999 9999";

export const BLIK_CODE_MASK = "999 999";

export const CURRENCY_REGEX = /^\d+((,|\.)\d{1,2})?$/;

export const INTEGER_REGEX = /^\d+$/;

export const FAKE_HOME_PARKING_ID = "fakeHomeParking";

export const PASSWORD_REGEX = /.{7,}/;

export const GOOGLE_API_KEY = "AIzaSyB8i1p8WxQ3IhBLs9EUKX7sLaHJLpGYnLg";

export const VERYFICATION_CODE_REGEX = /[0-9] [0-9] [0-9] [0-9]/;

export const VERYFICATION_CODE_MASK = "9 9 9 9";