import Grid from "@mui/material/Unstable_Grid2";
import BookingIllustration from "../../../../../MUI/assets/illustrations/booking-illustration";
import CheckInIllustration from "../../../../../MUI/assets/illustrations/check-in-illustration";
import CheckOutIllustration from "../../../../../MUI/assets/illustrations/check-out-illustration";
import React from "react";

import Resources from "../../../../../../Resources";
import Iconify from "../../../../../MUI/components/iconify";
import {useTheme} from "@mui/material/styles";
import WidgetSummaryWithAvatar
    from "../../../../../MUI/components/AddedOrModyfiedMuiComponents/Widgets/WidgetSummaryWithAvatar";

interface UserWidgetSectionProps {
    allUsersCount?: number;
    newUsersCount?: number;
    uniqueUsersCount?: number;
}

export default function UserWidgetStatisticsSection({
                                                        allUsersCount,
                                                        uniqueUsersCount,
                                                        newUsersCount
                                                    }: UserWidgetSectionProps) {
    const theme = useTheme();

    return (<Grid container spacing={3} xs={12} disableEqualOverflow>
        <Grid xs={12} md={4}>
            <WidgetSummaryWithAvatar
                title={Resources.Wszyscy_zarejestrowani}
                currentValue={allUsersCount}
                icon={<BookingIllustration/>}
            />
        </Grid>
        <Grid xs={12} md={4}>
            <WidgetSummaryWithAvatar title={Resources.Zarejestrowani_w_danym_okresie} currentValue={newUsersCount}
                                     icon={<CheckOutIllustration/>}
                                     valueIcon={<Iconify icon="typcn:plus" color={theme.palette.success.main}/>}/>
        </Grid>
        <Grid xs={12} md={4}>
            <WidgetSummaryWithAvatar title={Resources.Aktywni_w_danym_okresie} currentValue={uniqueUsersCount}
                                     icon={<CheckInIllustration/>}/>
        </Grid>
    </Grid>)

}