import { faMap } from "@fortawesome/free-solid-svg-icons";
import { FormikHelpers, FormikProps } from "formik";
import {
  AddressDto,
  AvailibilityStatus,
  ErrorCode,
  GetEffectiveTariffsResultEntry,
  ParkingSpotsAvailibilityEntry,
  PaymentMethod,
  SpotSelectorType,
} from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import ErrorView from "../../components/ErrorView";
import Input from "../../components/forms/Input";
import RegularDateTimePicker from "../../components/forms/RegularDateTimePicker";
import IconButton from "../../components/IconButton";
import ModalForm from "../../components/ModalForm";
import ChoosePlaceFromMap from "../../components/ChoosePlaceFromMap";
import Spinner from "../../components/Spinner";
import {
  checkExternalReservationSpotAvailable,
  getAdminExternalReservationInitialFormState,
  getAdminExternalReservationValidationSchema,
  getExternalReservationAvailableSpots,
  getExternalReservationParkingConfiguration,
  getExternalReservationParkingTarrifs,
  getExternalReservationPrice,
  managerCreateExternalReservation,
} from "../../otherPages/ExternalReservationsUtils";
import { AdminExternalReservationFormState } from "../../otherPages/formInterfaces/ExternalReservationFormState";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { formatAddress } from "../../utils/AddressUtils";
import { addMinutes } from "../../utils/DateTimeUtils";
import { getErrorMessage } from "../../utils/ErrorUtils";
import Notify from "../../utils/Notify";
import InvoicePart from "../SubscriptionsCommon/InvoicePart";
import TargetPart from "../SubscriptionsCommon/TargetPart";
import { formatSpot } from "../../components/ParkingSpotNumberPresenter";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import RegularInput from "../../components/forms/RegularInput";
import { PCClasses } from "../../utils/CSSUtils";
import { getSpotInfo } from "../../components/ParkingMap/Data";

const subscription_type = require("../../../assets/images/subscription_type.svg");

const SpecyficPart = (props: {
  args: FormikProps<AdminExternalReservationFormState>;
  parkingAddress: AddressDto;
  onTarrifsError: (error: string | ErrorCode) => void;
  onlySpecyficPlace: boolean;
  onSpotInfoRetrieved: (number: string, sector: string, level: string) => void;
}) => {
  const {
    args,
    parkingAddress,
    onTarrifsError,
    onlySpecyficPlace,
    onSpotInfoRetrieved,
  } = props;
  const { values, touched, errors, setFieldTouched, setFieldValue } = args;
  const [spots, setSpots] = React.useState<ParkingSpotsAvailibilityEntry[]>([]);
  const [number, setNumber] = React.useState<string>(null);
  const [level, setLevel] = React.useState<string>(null);
  const [sector, setSector] = React.useState<string>(null);

  const onMap = async () => {
    const s = await ChoosePlaceFromMap.show({
      currentSpotId: values.spotId,
      parkingId: values.parkingId,
      getSpotsCallback: async (level, sector) => {
        const { error, spots } = await getExternalReservationAvailableSpots(
          values.parkingId,
          values.start,
          values.end,
          level,
          sector
        );
        if (error) {
          return { error };
        } else {
          return {
            spots: spots.map((s) => ({
              status: s.isAvailable ? AvailibilityStatus.Free : null,
              id: s.id,
              number: s.number,
              level: s.level,
              sector: s.sector,
            })),
          };
        }
      },
    });
    if (s) {
      const { spotId, number, sector, level } = s;
      setFieldValue("spotId", spotId);
      setNumber(number);
      setSector(sector);
      setLevel(level);
    }
  };

  const retrieveSpots = async (parId: string, s: Date, e: Date) => {
    const { spots, error } = await getExternalReservationAvailableSpots(
      parId,
      s,
      e
    );
    if (error) {
      Notify.Error(getErrorMessage(error));
      setSpots([]);
    } else {
      setSpots(spots);
    }
  };

  React.useEffect(() => {
    if (!onlySpecyficPlace) {
      setFieldValue("spotId", null);
      setSpots([]);
      if (
        values.enableParticularSpotPurchase &&
        values.parkingId &&
        values.end &&
        values.start &&
        values.spotSelectorType === SpotSelectorType.List
      ) {
        retrieveSpots(values.parkingId, values.start, values.end);
      }
    }
  }, [values.start, values.end, values.parkingId, onlySpecyficPlace]);

  React.useEffect(() => {
    const getInfo = async () => {
      if (values.spotId) {
        const sInfo = await getSpotInfo(values.spotId);
        if (sInfo.error) {
          setNumber(null);
          setLevel(null);
          setSector(null);
        } else {
          setNumber(sInfo.spot.number);
          setSector(sInfo.spot.number);
          setLevel(sInfo.spot.level);
        }
      } else {
        setNumber(null);
        setLevel(null);
        setSector(null);
      }
    };

    getInfo();
  }, [values.spotId]);

  React.useEffect(() => {
    if (number) {
      onSpotInfoRetrieved(number, sector, level);
    }
  }, [number, sector, level]);

  React.useEffect(() => {
    const getPrice = async (
      pId: string,
      sId: string,
      s: Date,
      e: Date,
      tId: string
    ) => {
      setFieldValue("customPrice", "");
      const { error, price } = await getExternalReservationPrice(
        pId,
        s,
        e,
        sId,
        tId
      );
      if (!error) {
        setFieldValue("customPrice", price.toString());
      }
    };

    if (
      (!values.enableParticularSpotPurchase || values.spotId) &&
      values.tariffId
    ) {
      getPrice(
        values.parkingId,
        values.spotId,
        values.start,
        values.end,
        values.tariffId
      );
    } else {
      setFieldValue("customPrice", "");
    }
  }, [
    values.spotId,
    values.start,
    values.end,
    values.enableParticularSpotPurchase,
    values.parkingId,
    values.tariffId,
  ]);

  React.useEffect(() => {
    const getTarrifs = async (
      pId: string,
      sId: string,
      onError: (e: string | ErrorCode) => void
    ) => {
      setFieldValue("tariffId", null);
      setFieldValue("tariffs", []);
      const { error, result } = await getExternalReservationParkingTarrifs(
        pId,
        sId
      );
      if (error) {
        onError(error);
      } else if (!result || !result.length) {
        onError(Resources.Dla_tego_parkingu_nie_zdefiniowano_zadnych_cennikow);
      } else {
        const tariffId = result[0].id;
        setFieldValue("tariffId", tariffId);
        setFieldValue("tariffs", result);
      }
    };

    getTarrifs(values.parkingId, values.spotId, onTarrifsError);
  }, [values.spotId, onTarrifsError]);

  return (
    <>
      <RegularDateTimePicker
        label={Resources.Poczatek_parkowania}
        value={values.start}
        touched={touched.start}
        error={errors.start}
        mode="datetime"
        name="start"
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        onChange={(v) => {
          if (v > values.end) {
            setFieldValue("end", addMinutes(v, 15));
          }
        }}
      />
      <RegularDateTimePicker
        label={Resources.Koniec_parkowania}
        value={values.end}
        touched={touched.end}
        error={errors.end}
        mode="datetime"
        name="end"
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        onChange={(v) => {
          if (v < values.start) {
            setFieldValue("start", addMinutes(v, -15));
          }
        }}
      />
      <Input
        readOnly
        value={formatAddress(parkingAddress, true)}
        label={Resources.Adres}
      />
      <BaseSpaceSeparator size={20} />
      {values.enableParticularSpotPurchase && !onlySpecyficPlace && (
        <>
          {values.spotSelectorType === SpotSelectorType.List && (
            <RegularDropdownList
              zIndex={10}
              maxContentHeight={300}
              borderColor={Colors.brownish_grey}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              value={values.spotId}
              error={errors.spotId}
              touched={touched.spotId}
              name="spotId"
              label={Resources.Miejsce_rezerwacji}
              actions={spots.map((spot) => ({
                id: spot.id,
                text: formatSpot({
                  spotNumber: spot.number,
                  level: spot.level,
                }),
              }))}
            />
          )}
          {values.spotSelectorType === SpotSelectorType.Map && (
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <PCText fontSize={12} semibold color={Colors.brownish_grey}>
                  {formatSpot({ spotNumber: number, level, sector }) ||
                    Resources.Nie_wybrales_miejsca}
                </PCText>
                <BaseSpaceSeparator size={15} />
                <IconButton
                  icon={faMap}
                  onClick={onMap}
                  style={{
                    width: 40,
                    height: 40,
                    backgroundColor: Colors.light_royal_blue,
                    borderRadius: 20,
                  }}
                />
              </div>
              <div
                style={{
                  paddingTop: 4,
                  paddingBottom: 4,
                  height: 20,
                  boxSizing: "border-box",
                }}
              >
                <PCText
                  fontSize={12}
                  lineHeight={1}
                  letterSpacing={0}
                  color={Colors.red}
                >
                  {touched.spotId && errors.spotId ? errors.spotId : ""}
                </PCText>
              </div>
            </div>
          )}
        </>
      )}
      {(values.tariffs || []).length > 1 && (
        <>
          <BaseSpaceSeparator size={20} />
          <RegularDropdownList
            borderColor={Colors.brownish_grey}
            label={Resources.Wybierz_cennik}
            zIndex={30}
            value={values.tariffId}
            touched={touched.tariffId}
            error={errors.tariffId}
            onChange={() => {}}
            name="tariffId"
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            actions={values.tariffs
              .sort((a, b) => a.orderIndex - b.orderIndex)
              .map((a) => ({
                metadata: a,
                id: a.id,
              }))}
          >
            {(action, isOption, handleClick) => {
              const { metadata } = action;
              const { description, name } =
                metadata as GetEffectiveTariffsResultEntry;
              return (
                <div
                  onClick={isOption ? handleClick : undefined}
                  className={isOption ? PCClasses("pc-button") : undefined}
                  style={{ padding: isOption ? "10px 0px" : undefined }}
                >
                  <PCText
                    semibold
                    fontSize={18}
                    color={Colors.light_royal_blue}
                  >
                    {name}
                  </PCText>
                  <BaseSpaceSeparator size={5} />
                  <PCText fontSize={14} color={Colors.brownish_grey}>
                    {description}
                  </PCText>
                </div>
              );
            }}
          </RegularDropdownList>
        </>
      )}
      <RegularInput
        label={Resources.Cena + `(${Resources.zl})`}
        error={errors.customPrice}
        value={values.customPrice}
        touched={touched.customPrice}
        name="customPrice"
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
      />
    </>
  );
};

export default (props: {
  visible: boolean;
  onClose: () => void;
  onSubmitted: () => void;
  parkingId: string;
  jwt: string;
  spotId?: string;
  onlySpecyficPlace?: boolean;
}) => {
  const {
    jwt,
    onClose,
    parkingId,
    visible,
    onSubmitted,
    spotId = null,
    onlySpecyficPlace,
  } = props;
  const [title, setTitle] = React.useState("");
  const [initialValues, setInitialValues] =
    React.useState<AdminExternalReservationFormState>(
      getAdminExternalReservationInitialFormState(
        false,
        SpotSelectorType.List,
        null,
        false,
        spotId
      )
    );
  const [error, setError] = React.useState<ErrorCode | string>(null);
  const [progress, setProgress] = React.useState(true);
  const [validationSchema] = React.useState(
    getAdminExternalReservationValidationSchema()
  );
  const [parkingAddress, setParkingAddress] = React.useState<AddressDto>(null);

  const onSubmit = async (
    state: AdminExternalReservationFormState,
    helpers: FormikHelpers<AdminExternalReservationFormState>
  ) => {
    helpers.setSubmitting(true);
    const availableResponse = await checkExternalReservationSpotAvailable(
      state
    );
    if (availableResponse.error) {
      helpers.setStatus(getErrorMessage(availableResponse.error));
    } else if (!availableResponse.available) {
      helpers.setStatus(Resources.Wybrane_miejsce_nie_jest_dostepne);
    } else {
      const { error } = await managerCreateExternalReservation(state, jwt);
      if (error) {
        helpers.setStatus(getErrorMessage(error));
      } else {
        onSubmitted();
      }
    }
    helpers.setSubmitting(false);
  };

  const init = async (pId: string, sId: string) => {
    setProgress(true);
    const { error, result } = await getExternalReservationParkingConfiguration(
      pId
    );
    if (error) {
      setError(error);
    } else {
      setParkingAddress(result.address);
      setInitialValues(
        getAdminExternalReservationInitialFormState(
          result.enableParticularSpotPurchase,
          result.spotSelectorType,
          pId,
          result.requireLicensePlateNumber,
          sId
        )
      );
    }
    setProgress(false);
  };

  React.useEffect(() => {
    if (parkingId) {
      init(parkingId, spotId);
    }
  }, [parkingId, spotId]);
  console.log("in create external reservation form visibleState=", visible);

  return (
    <ModalForm
      icon={subscription_type}
      iconHeight={105}
      iconWidth={120}
      visible={visible}
      onClose={onClose}
      title={Resources.Rezerwacja}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      zIndex={1999}
      headerTitle={title}
    >
      {(args) => {
        if (progress) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Spinner size="small" />
            </div>
          );
        }

        if (typeof error === "number") {
          return <ErrorView title={getErrorMessage(error)} />;
        }

        if (typeof error === "string") {
          return <ErrorView title={error} />;
        }

        return (
          <>
            <SpecyficPart
              args={args}
              parkingAddress={parkingAddress}
              onTarrifsError={setError}
              onlySpecyficPlace={onlySpecyficPlace}
              onSpotInfoRetrieved={(spotNumber, sector, level) => {
                setTitle(formatSpot({ spotNumber, sector, level }));
              }}
            />
            <TargetPart args={args} />
            <InvoicePart args={args} />
            {!args.values.wantInvoice && (
              <RegularInput
                label={Resources.Nr_dokumentu}
                value={args.values.externalPaymentDocumentNumber}
                error={args.errors.externalPaymentDocumentNumber}
                touched={args.touched.externalPaymentDocumentNumber}
                setFieldTouched={args.setFieldTouched}
                setFieldValue={args.setFieldValue}
                name="externalPaymentDocumentNumber"
                showClearButton
                showValidatedButton
              />
            )}
            <RegularDropdownList
              value={args.values.paymentMethod}
              error={args.errors.paymentMethod}
              touched={args.touched.paymentMethod}
              name="paymentMethod"
              actions={[
                { id: PaymentMethod.BankTransfer, text: Resources.przelew },
                { id: PaymentMethod.Cash, text: Resources.gotowka },
                {
                  id: PaymentMethod.CreditCard,
                  text: Resources.karta_platnicza,
                },
              ]}
              setFieldTouched={args.setFieldTouched}
              setFieldValue={args.setFieldValue}
              label={Resources.Sposob_platnosci}
              borderColor={Colors.brownish_grey}
            />
          </>
        );
      }}
    </ModalForm>
  );
};
