import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { ParkingSpotTimelineDaySegmentInfo } from "parkcash-api";
import React from "react";
import Resources from "../../../Resources";
import IconButton from "../../components/IconButton";
import StatusPresenter from "../../components/StatusPresenter";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { formatTime } from "../../utils/DateTimeUtils";

export default (props: {
    onRemoveSharing: (start: Date, end: Date) => void;
    item: ParkingSpotTimelineDaySegmentInfo;
}) => {
    const {item: {startTimestampDisplay, endTimestampDisplay, startTimestamp, endTimestamp}, onRemoveSharing} = props;
    return (
        <div style={{paddingTop: 12, paddingBottom: 12, display: 'flex', alignItems: 'center'}}>
            <StatusPresenter 
                text={Resources.Wolne}
                textColor={Colors.light_royal_blue}
                color={Colors.very_light_pink_two}
                fontSize={12}
                width={90}
            />
            <BaseSpaceSeparator size={15} />
            <PCText semibold color={Colors.black} fontSize={16}>
                {formatTime(startTimestampDisplay)}-{formatTime(endTimestampDisplay)}
            </PCText>
            <div style={{flex: 1}} />
            <IconButton onClick={() => onRemoveSharing(startTimestamp, endTimestamp)} icon={faTrashAlt} />
        </div>
    );
}