import * as React from "react";
import Resources from "../../Resources";
import BookingPanel from "../bookings/BookingPanel";
import GroupsHome from "../groups/GroupsHome";
import Body from "../layouts/Main/Body";
import RequestsList from "../sharing/RequestsList";
import {BaseSpaceSeparator} from "../styles/Separators";
import {useWindowSize} from "../utils/WindowSizeHook";
import {useUser} from "./UseUserHook";

export default () => {
    const {windowWidth} = useWindowSize();
    const {hideGroups} = useUser();

    const firstVariant = windowWidth > 1025;
    const secondVariant = !firstVariant && windowWidth > 641;
    const thirdVariant = !firstVariant && !secondVariant;

    const additionalPanels = (
        <>
        {!hideGroups && (
            <>
                <GroupsHome />
                <BaseSpaceSeparator size={30} />
            </>
        )}
        
        <RequestsList />
        </>
    )

    return (
        <Body title={Resources.Dashboard}>
            <div style={{display: 'flex', flexDirection: firstVariant ? 'row' : 'column'}}>
                {firstVariant && (
                    <>
                        <div
                            style={{
                                height: 838,
                                width: 300
                            }}
                        >
                            {additionalPanels}  
                        </div>
                        <BaseSpaceSeparator size={30} />                    
                    </>
                )}
                <div style={{flex: 1}}>
                    <BookingPanel extraWidth={330}/>
                </div>  
                {(secondVariant || thirdVariant) && (
                    <>
                        <BaseSpaceSeparator size={30} />
                        <div style={{display: 'flex', alignItems: secondVariant ? 'center' : undefined, flexDirection: secondVariant ? 'row' : 'column'}}>
                            {additionalPanels}  
                        </div>                    
                    </>
                )}
            </div>
        </Body>
    )
}