import * as React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import NotFound from "./components/NotFound";
import Bookings from "./bookings";
import ParkingSpots from "./parkingSpots";
import Login from "./authentication/Login";
import ForgotPassword from "./authentication/ForgotPassword";
import MobilePilots from "./mobilePilots";
import Home from "./user/Home";
import Profile from "./user/Profile";
import Logout from "./authentication/Logout";
import { AdminHome, AdminParkings, AdminSearchHeatMap } from "./admin";
import {
  ParkingManagementParkingProcesses,
  ParkingManagementHome,
  ParkingManagementParkingSpots,
  ParkingManagementPilots,
  ParkingManagementUsers,
  ParkingManagementInvoices,
  ParkingManagementSubscriptions,
  ParkingManagementSubsriptionsPromotionCodes,
  ParkingManagementTimeline,
  ParkingManagementFreSpotsReports, LptManagement,
} from "./parkingManagement";
import PrivateRoute, { RouteType } from "./components/PrivateRoute";
import Registration from "./authentication/Registration";
import WalletList from "./points/WalletList";
import MakeReservation from "./bookings/MakeReservation";
import ResetPassword from "./authentication/ResetPassword";
import Impersonate from "./authentication/Impersonate";
import { routePrefix } from "../app.json";
import GroupsList from "./groups/GroupsList";
import Invitations from "./groups/Invitations";
import ChooseInvitationLinkRegistrationType from "./authentication/ChooseInvitationLinkRegistrationType";
import JoinGroupFromLink from "./groups/JoinGroupFromLink";
import { AuthenticationLayout, MainLayout, OtherPagesLayout } from "./layouts";
import ChooseSubscriptionAction from "./otherPages/ChooseSubscriptionAction";
import ProlongSubscription from "./otherPages/ProlongSubscription";
import CreateSubscription from "./otherPages/CreateSubscription";
import Resources from "../Resources";
import ChooseSubscriptionToProlong from "./otherPages/ChooseSubscriptionToProlong";
import EnterSubscriptionPhoneNumber from "./otherPages/EnterSubscriptionPhoneNumber";
import EnterSubscriptionSMSCode from "./otherPages/EnterSubscriptionSMSCode";
import PaymentStatus from "./otherPages/PaymentStatus";
import TestPage from "./otherPages/TestPage";
import SubscriptionPurchaseStatus from "./otherPages/SubscriptionPurchaseStatus";
import ParkingSpotRatings from "./parkingSpots/ParkingSpotRatings";
import ExternalReservationPurchaseStatus from "./otherPages/ExternalReservationPurchaseStatus";
import ExternalReservation from "./otherPages/ExternalReservation";
import ChangeLanguage from "./authentication/ChangeLanguage";
import Notifications from "./user/Notifications";
import ExternalTicketPayment from "./otherPages/ExternalTickets/ExternalTicketPayment";
import ExternalTicketPaymentStatus from "./otherPages/ExternalTickets/ExternalTicketPaymentStatus";
import SetPassword from "./otherPages/SetPassword";
import { ListOfVacantSpots } from "./otherPages/ListOfVacantSpots";
import { SpotHistory } from "./otherPages/SpotHistory";
import {FinancialReport} from "./parkingManagement/Reports/FinancialReport";
import OverviewBookingPage from "./MUI/pages/dashboard/booking";
import { UsersReport } from "./parkingManagement/Reports/UserReport";
import {ParkingSpotsAndReservationsReport} from "./parkingManagement/Reports/ParkingSpotsAndRezervationsReport";
import GeneratedReportsPage from "./parkingManagement/Reports/GeneratedReportsPage";
import ParkingConfigPage from "./parkingManagement/ParkingConfig";
import MainDashboardPage from "./parkingManagement/Reports/MainDashboardPage";



export default () => {
  return (
    <BrowserRouter basename={routePrefix}>
      <Switch>
        <Route path="/logout">
          <Logout />
        </Route>
        <Route path="/login" exact>
          <AuthenticationLayout>
            <Login />
          </AuthenticationLayout>
        </Route>
        <Route path="/forgotpassword">
          <AuthenticationLayout>
            <ForgotPassword />
          </AuthenticationLayout>
        </Route>
        <Route path="/resetpassword/:userId">
          <AuthenticationLayout>
            <ResetPassword />
          </AuthenticationLayout>
        </Route>
        <Route path="/registration/:invitationGuid?">
          <AuthenticationLayout>
            <Registration />
          </AuthenticationLayout>
        </Route>
        <Route path="/impersonate">
          <AuthenticationLayout>
            <Impersonate />
          </AuthenticationLayout>
        </Route>
        <Route path="/changelanguage">
          <AuthenticationLayout>
            <ChangeLanguage />
          </AuthenticationLayout>
        </Route>
        <Route exact path="/op/password-creation/:setcode">
          <AuthenticationLayout>
            <SetPassword />
          </AuthenticationLayout>
        </Route>
        <Route
          exact
          path="/chooseinvitationlinkregistrationtype/:invitationGuid"
        >
          <AuthenticationLayout>
            <ChooseInvitationLinkRegistrationType />
          </AuthenticationLayout>
        </Route>
        <Route exact path="/invitations/:invitationGuid">
          <Invitations />
        </Route>
        <Route
          exact
          path="/op/choosesubscriptionaction/:parkingId/:initialSubscriptionId?"
        >
          <OtherPagesLayout title={Resources.Abonament_parkingowy}>
            <ChooseSubscriptionAction />
          </OtherPagesLayout>
        </Route>
        <Route exact path="/op/prolongsubscription/:purchaseId">
          <OtherPagesLayout
            title={Resources.Przedluzenie_abonamentu_parkingowego}
          >
            <ProlongSubscription />
          </OtherPagesLayout>
        </Route>
        <Route
          exact
          path="/op/createsubscription/:parkingId/:initialSubscriptionId?"
        >
          <OtherPagesLayout title={Resources.Zakup_abonamentu_parkingowego}>
            <CreateSubscription />
          </OtherPagesLayout>
        </Route>
        <Route exact path="/op/choosesubscriptiontoprolong">
          <OtherPagesLayout title={Resources.Abonament_parkingowy}>
            <ChooseSubscriptionToProlong />
          </OtherPagesLayout>
        </Route>
        <Route exact path="/op/entersubscriptionphonenumber/:parkingId">
          <OtherPagesLayout title={Resources.Abonament_parkingowy}>
            <EnterSubscriptionPhoneNumber />
          </OtherPagesLayout>
        </Route>
        <Route exact path="/op/entersubscriptionsmscode/:codeId/:parkingId">
          <OtherPagesLayout title={Resources.Abonament_parkingowy}>
            <EnterSubscriptionSMSCode />
          </OtherPagesLayout>
        </Route>
        <Route exact path="/op/subscription-purchase-status/:purchaseId">
          <OtherPagesLayout title={Resources.Abonament_parkingowy}>
            <SubscriptionPurchaseStatus />
          </OtherPagesLayout>
        </Route>
        <Route exact path="/op/external-reservation/:parkingId">
          <OtherPagesLayout title={Resources.Rezerwacja_miejsca_parkingowego}>
            <ExternalReservation />
          </OtherPagesLayout>
        </Route>
        <Route
          exact
          path="/op/external-reservation-status/:externalReservationId"
        >
          <OtherPagesLayout title={Resources.Rezerwacja_miejsca_parkingowego}>
            <ExternalReservationPurchaseStatus />
          </OtherPagesLayout>
        </Route>

        <Route exact path="/op/external-ticket-payment/:parkingId">
          <ExternalTicketPayment />
        </Route>
        <Route exact path="/op/external-ticket-payment-status/:ticketId">
          <ExternalTicketPaymentStatus />
        </Route>

        <Route exact path="/op/payment-status">
          <OtherPagesLayout title={Resources.Potwierdzenie_transakcji}>
            <PaymentStatus />
          </OtherPagesLayout>
        </Route>
        <Route exact path="/op/testpage">
          <OtherPagesLayout title={"Test"}>
            <TestPage />
          </OtherPagesLayout>
        </Route>
        <Route exact path="/op/listofvacantspots">
          <ListOfVacantSpots />
        </Route>
        <Route exact path="/op/spothistory/:spotId">
          <SpotHistory />
        </Route>
        {/* DEEP LINKS */}
        <Route exact path="/addparkingspot">
          <Redirect to={`/parkingspots?initialMode=add`} />
        </Route>
        <Route
          exact
          path="/shareperiodically/:spotId?"
          render={({ match }) => {
            const { spotId } = match.params || {};

            return (
              <Redirect
                to={`/parkingspots?initialMode=share${
                  spotId ? `&groupId=${spotId}` : ""
                }&shareType=cyclic`}
              />
            );
          }}
        />

        <Route
          exact
          path="/shareonetime/:spotId?"
          render={({ match }) => {
            const { spotId } = match.params || {};

            return (
              <Redirect
                to={`/parkingspots?initialMode=share${
                  spotId ? `&groupId=${spotId}` : ""
                }&shareType=onetime`}
              />
            );
          }}
        />

        <Route exact path="/askforplaceingroup/:groupId?">
          <h1>not implemented</h1>
        </Route>
        <Route exact path="/askforplace">
          <h1>not implemented</h1>
        </Route>
        <Route exact path="/addgroup">
          <Redirect to="/groups?initialMode=add" />
        </Route>
        <Route
          exact
          path="/forceaddgroup/:code/:pin"
          render={({ match }) => {
            const { code, pin } = match.params;
            if (!code || !pin) {
              return <Redirect to="/groups" />;
            } else {
              return (
                <Redirect
                  to={`/groups?initialMode=forceadd&pin=${pin}&code=${code}`}
                />
              );
            }
          }}
        />
        <Route
          exact
          path="/sharegroup/:groupId?"
          render={({ match }) => {
            const { groupId } = match.params || {};

            return (
              <Redirect
                to={`/groups?initialMode=share${
                  groupId ? `&groupId=${groupId}` : ""
                }`}
              />
            );
          }}
        />
        <Route exact path="/creategroup">
          <Redirect to="/groups?initialMode=create" />
        </Route>
        <Route exact path="/addmobilepilot">
          <Redirect to="/mobilepilots?initialMode=add" />
        </Route>
        <Route
          exact
          path="/sharepilot/:pilotId?"
          render={({ match }) => {
            const { pilotId } = match.params || {};

            return (
              <Redirect
                to={`/mobilepilots?initialMode=share${
                  pilotId ? `&pilotId=${pilotId}` : ""
                }`}
              />
            );
          }}
        />
        <Route exact path="/searchingroup/:groupId?">
          <h1>not implemented</h1>
        </Route>

        <Route exact path="/vehicles">
          <Redirect to="/profile" />
        </Route>
        <Route exact path="/paymentforms">
          <Redirect to="/profile" />
        </Route>
        <Route exact path="/choosepaymentform">
          <h1>not implemented</h1>
        </Route>
        <Route exact path="/addcreditcard">
          <h1>not implemented</h1>
        </Route>
        {/* DEEP LINKS */}
        <Route exact path="/notfound">
          <NotFound />
        </Route>
        <Route>
          <MainLayout>
            <Switch>
              <PrivateRoute exact path="/">
                <Home />
              </PrivateRoute>
              <PrivateRoute exact path="/notifications">
                <Notifications />
              </PrivateRoute>
              <PrivateRoute exact path="/parkingspots">
                <ParkingSpots />
              </PrivateRoute>
              <PrivateRoute exact path="/parkingspotratings/:spotId">
                <ParkingSpotRatings />
              </PrivateRoute>
              <PrivateRoute exact path="/groups">
                <GroupsList />
              </PrivateRoute>
              <PrivateRoute exact path="/bookings">
                <Bookings />
              </PrivateRoute>
              <PrivateRoute exact path="/mobilepilots">
                <MobilePilots />
              </PrivateRoute>
              <PrivateRoute exact path="/wallet/:type">
                <WalletList />
              </PrivateRoute>
              <PrivateRoute exact path="/profile">
                <Profile />
              </PrivateRoute>
              <PrivateRoute exact path="/makereservation">
                <MakeReservation />
              </PrivateRoute>
              <PrivateRoute exact path="/joingroupfromlink/:invitationGuid">
                <JoinGroupFromLink />
              </PrivateRoute>
              <PrivateRoute access={RouteType.SuperAdmin} exact path="/admin">
                <AdminHome />
              </PrivateRoute>
              <PrivateRoute
                access={RouteType.SuperAdmin}
                exact
                path="/admin/parkings"
              >
                <AdminParkings />
              </PrivateRoute>
              <PrivateRoute
                access={RouteType.SuperAdmin}
                exact
                path="/admin/search-heat-map"
              >
                <AdminSearchHeatMap />
              </PrivateRoute>
              <PrivateRoute
                access={RouteType.ParkingManager}
                exact
                path="/parkingmanagement"
              >
                <ParkingManagementHome />
              </PrivateRoute>
              <PrivateRoute
                access={RouteType.ParkingManager}
                exact
                path="/parkingmanagement/users"
              >
                <ParkingManagementUsers />
              </PrivateRoute>
              <PrivateRoute
                  access={RouteType.ParkingManager}
                  exact
                  path="/parkingmanagement/parkingsConfig"
              >
                <ParkingConfigPage />
              </PrivateRoute>
              <PrivateRoute
                access={RouteType.ParkingManager}
                exact
                path="/parkingmanagement/timeline/:spotId/:spotDescription"
              >
                <ParkingManagementTimeline />
              </PrivateRoute>
              <PrivateRoute
                access={RouteType.ParkingManager}
                exact
                path="/parkingmanagement/reports"
              >
                <ParkingManagementFreSpotsReports />
              </PrivateRoute>
              <PrivateRoute
                  access={RouteType.ParkingManager}
                  exact
                  path="/parkingmanagement/minimalBookingDashboard"
              >
                <OverviewBookingPage />
              </PrivateRoute>
              <PrivateRoute
                  access={RouteType.ParkingManager}
                  exact
                  path="/parkingmanagement/FinancialReport"
              >
                <FinancialReport />
              </PrivateRoute>
              <PrivateRoute
                  access={RouteType.ParkingManager}
                  exact
                  path="/parkingmanagement/UsersReport"
              >
                <UsersReport />
              </PrivateRoute>
              <PrivateRoute
                  access={RouteType.ParkingManager}
                  exact
                  path="/parkingmanagement/ParkingSpotsReport"
              >
                <ParkingSpotsAndReservationsReport />
              </PrivateRoute>
              <PrivateRoute
                  access={RouteType.ParkingManager}
                  exact
                  path="/parkingmanagement/GeneratedReports"
              >
                <GeneratedReportsPage/>
              </PrivateRoute>
              <PrivateRoute
                  access={RouteType.ParkingManager}
                  exact
                  path="/parkingmanagement/mainDashboard"
              >
                <MainDashboardPage />
              </PrivateRoute>
              <PrivateRoute
                access={RouteType.ParkingManager}
                exact
                path="/parkingmanagement/spots"
              >
                <ParkingManagementParkingSpots />
              </PrivateRoute>
              <PrivateRoute
                access={RouteType.ParkingManager}
                exact
                path="/parkingmanagement/pilots"
              >
                <ParkingManagementPilots />
              </PrivateRoute>
              <PrivateRoute
                access={RouteType.ParkingManager}
                exact
                path="/parkingmanagement/parkingprocesses"
              >
                <ParkingManagementParkingProcesses />
              </PrivateRoute>
              <PrivateRoute
                access={RouteType.ParkingManager}
                exact
                path="/parkingmanagement/invoices"
              >
                <ParkingManagementInvoices />
              </PrivateRoute>

              <PrivateRoute
                access={RouteType.ParkingManager}
                exact
                path="/parkingmanagement/subscriptions"
              >
                <ParkingManagementSubscriptions />
              </PrivateRoute>
              <PrivateRoute
                access={RouteType.ParkingManager}
                exact
                path="/parkingmanagement/subscriptionspromotioncodes"
              >
                <ParkingManagementSubsriptionsPromotionCodes />
              </PrivateRoute>
              <PrivateRoute
                  access={RouteType.ParkingManager}
                  exact
                  path="/parkingmanagement/lprmanagement"
              >
                <LptManagement />
              </PrivateRoute>
            
              <Route>
                <Redirect to="/notfound" />
              </Route>
            </Switch>
          </MainLayout>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};
