import { GetActiveSubscriptionsResultEntryDto } from "parkcash-api";
import React from "react";
import { connect } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import Resources from "../../Resources";
import Input from "../components/forms/Input";
import { applicationState } from "../redux/ApplicationState";
import { StandardButton, StandardButton2 } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { MOBILE_WIDTH_THRESHOLD, VERYFICATION_CODE_MASK, VERYFICATION_CODE_REGEX } from "../utils/Constants";
import Notify from "../utils/Notify";
import { useWindowSize } from "../utils/WindowSizeHook";
import { sendSMSCode } from "./SubscriptionsUtils";
import { resetTempActiveSubscriptionsPurchases, setTempActiveSubscriptionsPurchases } from "./Redux";
import * as yup from "yup";
import { Formik, FormikHelpers } from "formik";
import RegularInput from "../components/forms/RegularInput";
import {Asserts} from "yup";

const subscription_type = require("../../assets/images/subscription_type.svg");

interface Props {
    onInit: () => void,
    onSubscriptions: (s: GetActiveSubscriptionsResultEntryDto[]) => void
}

const validationSchema = yup.object({
    code: yup.string().required(Resources.Wymagane).matches(VERYFICATION_CODE_REGEX, Resources.Niepoprawny_format)
})

interface FormState extends Asserts<typeof validationSchema> {}

const Container = (props: Props) => {
    const {onInit, onSubscriptions} = props;
    const {windowWidth} = useWindowSize();
    const history = useHistory();
    const {codeId, parkingId} = useParams<{codeId: string, parkingId: string}>();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const [redirectUrl, setRedirectUrl] = React.useState<string>(null);

    const initialValues: FormState = {
        code: ""
    }

   

    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {
        helpers.setSubmitting(true);
        const {message, subscriptions} = await sendSMSCode(parkingId, state.code.replace(/ /g, ""), codeId);
        if(message){
            Notify.Error(message);
            
        }
        else{
            onSubscriptions && onSubscriptions(subscriptions);
            if(subscriptions.length === 1){
                setRedirectUrl(`/op/prolongsubscription/${subscriptions[0].id}`)
            }
            else{              
                setRedirectUrl("/op/choosesubscriptiontoprolong")
            }
        }
        helpers.setSubmitting(false);
    }

    const onSendAgain = () => {
        history.goBack();
    }

    React.useEffect(() => {
        onInit && onInit();
    }, []);

    if(redirectUrl){
        return <Redirect to={redirectUrl} />
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {args => {
                const {values, touched, errors, setFieldValue, setFieldTouched, handleSubmit, isSubmitting, isValid} = args;

                return (
                    <form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingTop: isMobile ? 20 : 120,
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingBottom: isMobile ? 20 : 50
                        }}
                        noValidate={true}
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <img src={subscription_type} width={220} height={193} />
                        <BaseSpaceSeparator size={40} />
                        <div style={{width: isMobile ? 300 : 600}}>
                            <PCText textAlign="center" semibold fontSize={isMobile ? 28 : 36} color={Colors.black}>
                                {Resources.Potwierdz_kodem_z_wiadomości_SMS_ktora_do_Ciebie_wyslalismy}
                            </PCText>
                        </div>
                        <BaseSpaceSeparator size={isMobile ? 30 : 60} />
                        <div style={{width: isMobile ? 250 : 320}}>
                            <RegularInput 
                                label={Resources.Kod_sms}
                                name="code"
                                value={values.code}
                                touched={touched.code}
                                error={errors.code}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                mask={VERYFICATION_CODE_MASK}
                                style={{textAlign: 'center'}}
                            />
                        </div>
                        <BaseSpaceSeparator size={50} />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: isMobile ? "column" : "row"
                            }}
                        >
                            <div style={{width: 150}}>
                                <StandardButton2 onClick={onSendAgain}>{Resources.Wyslij_ponownie}</StandardButton2>
                            </div>
                            <BaseSpaceSeparator size={30} />
                            <div style={{width: 150}}>
                                <StandardButton progress={isSubmitting} type="submit" disabled={!isValid} >{Resources.Potwierdzam}</StandardButton>
                            </div>
                        </div>
                        
                    </form>
                )
            }}
        </Formik>
    );
}

const mapStateToProps = (state: applicationState): Partial<Props> => ({

});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
    onInit: () => dispatch(resetTempActiveSubscriptionsPurchases()),
    onSubscriptions: s => dispatch(setTempActiveSubscriptionsPurchases(s))
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);