import React from 'react';
import {ApexOptions} from 'apexcharts';
// @mui
import {alpha, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card, {CardProps} from '@mui/material/Card';
// utils
import {fNumber, fPercent} from '../../../../MUI/utils/format-number';
// components
import Iconify from '../../../../MUI/components/iconify';
import Chart, {useChart} from '../../../../MUI/components/chart';
import Resources from '../../../../../Resources';

// ----------------------------------------------------------------------

interface Props extends CardProps {
    title: string;
    total: number;
    percent: number;
    chart: {
        colors?: string[]; series: number[]; options?: ApexOptions;
    };
}

export default function WidgetSummaryWithSparkLine({
                                                       title,
                                                       percent,
                                                       total,
                                                       chart,
                                                       sx,
                                                       ...other
                                                   }: Props) {
    const theme = useTheme();

    const {
        colors = [
            theme.palette.primary.light,
            theme.palette.primary.main
        ],
        series,
        options,
    } = chart;

    const chartOptions = useChart({
        colors: [colors[1]],
        fill: {
            type: 'gradient',
            gradient: {
                colorStops: [
                    {
                        offset: 0,
                        color: colors[0]
                    },
                    {
                        offset: 100,
                        color: colors[1]
                    },
                ],
            },
        },
        chart: {
            animations: {
                enabled: true,
            },
            sparkline: {

                enabled: true,
            },
        },
        tooltip: {
            x: {
                show: false,
            },
            y: {
                formatter: (value: number) => fNumber(value),
                title: {
                    formatter: () => '',
                },

            },
            marker: {
                show: false,
            },
        }, ...options,
    });

    const renderTrending = (<Stack direction="row" alignItems="center" sx={{
            mt: 2,
            mb: 1
        }}>
            <Iconify
                icon={percent < 0 ? 'eva:trending-down-fill' : 'eva:trending-up-fill'}
                sx={{
                    mr: 1,
                    p: 0.5,
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    color: 'success.main',
                    bgcolor: alpha(theme.palette.success.main, 0.16), ...(percent < 0 && {
                        color: 'error.main',
                        bgcolor: alpha(theme.palette.error.main, 0.16),
                    }),
                }}
            />

            <Typography variant="subtitle2" component="div" noWrap>
                {percent > 0 && '+'}

                {fPercent(percent)}

                <Box component="span" sx={{
                    color: 'text.secondary',
                    typography: 'body2'
                }}>
                    {' ' + Resources.Poprzedni_okres}
                </Box>
            </Typography>
        </Stack>);

    return (<Card sx={{
            display: 'flex',
            alignItems: 'center',
            p: 3, ...sx
        }} {...other}>
            <Box sx={{flexGrow: 1}}>
                <Typography variant="subtitle2" paragraph>
                    {title}
                </Typography>

                <Typography variant="h3" gutterBottom>
                    {fNumber(total)}
                </Typography>

                {renderTrending}
            </Box>

            <Chart
                type="line"
                series={[{data: series}]}
                options={chartOptions}
                width={96}
                height={64}
            />
        </Card>);
}
