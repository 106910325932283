import { TransactionType } from "./Enums";
import { ICreditCardBindingDto } from "parkcash-api";
import * as React from "react";

export default class PaymentForm {
    private type: TransactionType;
    private creditCardInfo: ICreditCardBindingDto;
    private bluemediaParams: {[key: string]: string};
    private creditCardNumber: string;

    public get Type(){ return this.type };

    public get CreditCardInfo() { return this.creditCardInfo; }

    public get BluemediaParams(){ return this.bluemediaParams };

    public get CreditCardNumber() { return this.creditCardNumber; }

    constructor(args: {
        type: TransactionType, 
        creditCardInfo?: ICreditCardBindingDto, 
        bluemediaParams?: {[key: string]: string}, 
        creditCardNumber?: string
    }){
        const {type, bluemediaParams,creditCardInfo,creditCardNumber} = args;
        this.type = type;
        this.bluemediaParams = bluemediaParams;
        this.creditCardInfo = creditCardInfo;
        this.creditCardNumber = creditCardNumber;
    }

    equals(other: PaymentForm){
        if(!other){
            return false;
        }

        if(this.type !== other.type){
            return false;
        }

        switch(this.type){
            case TransactionType.PayWithNewCreditCard:
                return false;

            case TransactionType.AddNewCreditCard:
                return true;
            
            case TransactionType.ApplePay:
            case TransactionType.GooglePay:
                return true;
            case TransactionType.PayWithSavedCreditCard:
                return this.creditCardInfo.id === other.creditCardInfo.id;
        }
    }

    getId(){
        if(this.type === TransactionType.PayWithSavedCreditCard){
            return this.creditCardInfo?.id;
        }
        else{
            return this.type.toString();
        }
    }
}