import React from 'react'
import RegularInput from "../../../components/forms/RegularInput";
import Resources from "../../../../Resources";
import Colors from "../../../styles/Colors";

import {FormikErrors, FormikTouched} from "formik";
import {useBoolean} from "../../../MUI/hooks/use-boolean";
import {StandardButton} from "../../../styles/Buttons";
import {SmallListItem} from "../../../components/Lists";
import {PCText} from "../../../styles/Texts";
import Stack from "@mui/material/Stack";
import {faPlusCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BaseSpaceSeparator} from "../../../styles/Separators";
import {AssignLicensePlatesToSpotFormValues} from "./AssignLicensePlatesToSpotFormTypes";
import {nameof} from "../../../utils/NameOf";

interface Props {
    values: AssignLicensePlatesToSpotFormValues
    errors: FormikErrors<AssignLicensePlatesToSpotFormValues>
    touched: FormikTouched<AssignLicensePlatesToSpotFormValues>
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;

}

export const AssignLicensePlatesToSpotFormFormSection = ({
                                                             values,
                                                             setFieldValue,
                                                             setFieldTouched,
                                                             errors,
                                                             touched,

                                                         }: Props) => {
    const showForm = useBoolean(false);
    const plates = values.plates;


    return (<div>

        <Stack direction={"column"} flexWrap={"wrap"}>
            <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
                <PCText color={Colors.black} fontSize={18} lineHeight={24 / 16}>{Resources.Numery_rejestracyjne}</PCText>
                <FontAwesomeIcon icon={faPlusCircle} color={Colors.light_royal_blue}
                                 style={{verticalAlign: 'top', textAlign: 'center', fontSize: 20, letterSpacing: 0}}
                                 onClick={showForm.onToggle} title={Resources.Dodaj}/>
            </Stack>
            <BaseSpaceSeparator size={20}/>
            {showForm.value ? (<>
                <RegularInput
                    variant={"standard"}
                    label={Resources.Numer_rejestracyjny}
                    value={values.newPlate}
                    error={errors.newPlate}
                    touched={touched.newPlate}
                    name={nameof<AssignLicensePlatesToSpotFormValues>('newPlate')}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    showClearButton
                    showValidatedButton
                />


                <StandardButton type={"button"} variant={"tiny"} onClick={() => {
                    if (values.newPlate) {

                        setFieldValue(nameof<AssignLicensePlatesToSpotFormValues>('plates'), [
                            ...values.plates,
                            values.newPlate

                        ]);
                        showForm.onFalse();
                    }
                }
                }>
                    {Resources.Dodaj}
                </StandardButton>
                <BaseSpaceSeparator size={20}/>
            </>) : null}
            {plates.map((plate, index) => {
                return (
                    <SmallListItem
                        key={index}
                        upperText={plate}
                        bottomText={""}
                        onIconClick={() => {
                            plates.splice(index, 1);
                            setFieldValue(nameof<AssignLicensePlatesToSpotFormValues>('plates'), plates);
                        }}
                        iconTitle={Resources.Usun}
                        icon={faTrash}
                    />
                )
            })}
        </Stack>

    </div>)
}
    
    

    
    