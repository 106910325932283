import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { PCClasses } from "../utils/CSSUtils";
import {ReactNode} from "react";
import Stack from "@mui/material/Stack";
import Resources from "../../Resources";
import {faChargingStation, faUserCog, faUserShield, faUserTie, faWheelchair} from "@fortawesome/free-solid-svg-icons";
import Iconify from "../MUI/components/iconify";
import {FormikProps} from "formik";
import {FormState} from "../parkingSpots/ParkingSpotForm/FormState";

export const ChipsChooser = (props: {
    label: string,
    wrap?:boolean,
    flex?:string|number
    options: Array<{
        id: number,
        text: string,
        iconFontAwesome?: IconProp
        iconOtherSource?: ReactNode
      
      
       
    }>;
    value: number,
    onChange: (v: number) => void
}) => {
    const {label, wrap, flex="initial", onChange, options, value} = props;

    return (
        <div>
            <PCText color={Colors.greyishBrown} semibold fontSize={14}>
                {label}
            </PCText>
            <BaseSpaceSeparator size={5} />
            <div style={{display: 'flex',
                alignItems: 'center',
                justifyContent:wrap?"flex-start":"initial",
                flexWrap: wrap?"wrap":"nowrap",
                rowGap:wrap?"10px":0,
                columnGap:wrap?"20px":0
            }}>
                {options.map((option, index, arr) => {
                    const {iconFontAwesome, iconOtherSource, id, text, } = option;
                    const isSelected = id === value;

                    return (
                        <React.Fragment key={id}>
                            <Chips 
                                iconFontAwesome={iconFontAwesome? iconFontAwesome : undefined}
                                iconOtherSource={iconOtherSource? iconOtherSource : undefined}
                                text={text}
                                onClick={() => onChange(id)}
                                isSelected={isSelected}
                                flex={flex}
                            />
                            {(arr.length > 1 && index !== arr.length -1 &&!wrap) && <BaseSpaceSeparator size={20} />}
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    );
}

export const Chips =  (props: {isSelected: boolean, onClick: () => void, iconFontAwesome?: IconProp, iconOtherSource?:ReactNode, text: string, flex?:string|number}) => {
    const {isSelected, onClick, text, iconFontAwesome, iconOtherSource, flex="initial"} = props;

    return (
        <div
            className={PCClasses([], {"pc-button": !isSelected})}
            onClick={() => {
                if(!isSelected){
                    onClick();
                }
            }}
            style={{
                padding: "8px 15px",
                display: 'flex',
                flex:flex,
                alignItems: 'center',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: isSelected ? Colors.light_royal_blue : Colors.very_light_pink,
                borderRadius: 20,
              
            }}
        >
            {!!iconFontAwesome && (
                <>
                <FontAwesomeIcon icon={iconFontAwesome} color={isSelected ? Colors.light_royal_blue : Colors.brownish_grey} style={{fontSize: 14}}/>
                <BaseSpaceSeparator size={10} />
                </>
            )}
            {!!iconOtherSource && 
                <>
                    <div style={{ height:"14px", color: isSelected ? Colors.light_royal_blue : Colors.brownish_grey}}>
                        {iconOtherSource }
                    </div>
                    <BaseSpaceSeparator size={10} />
                </>
           
            }
            <PCText semibold fontSize={14} color={isSelected ? Colors.light_royal_blue : Colors.brownish_grey}>
                {text}
            </PCText>
        </div>
    )
}
export const ToggleChips =  (props: { isSelected:boolean,
        onToggleValue: (value:boolean) => void, iconFontAwesome?: IconProp, iconOtherSource?:ReactNode, text: string, flex?:string|number}) => {
    const { isSelected, onToggleValue, text, iconFontAwesome, iconOtherSource, flex="initial"} = props;
 

    return (
        <div
            className={PCClasses([], {"pc-button": !isSelected})}
            onClick={() => {
            /*    setIsSelected(!isSelected);*/
                onToggleValue(!isSelected);
            }}
            style={{
                padding: "8px 15px",
                display: 'flex',
                flex:flex,
                alignItems: 'center',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: isSelected ? Colors.light_royal_blue : Colors.very_light_pink,
                borderRadius: 20,
                width: "auto"
            }}
        >
            {!!iconFontAwesome && (
                <>
                    <FontAwesomeIcon icon={iconFontAwesome} color={isSelected ? Colors.light_royal_blue : Colors.brownish_grey} style={{fontSize: 14}}/>
                    <BaseSpaceSeparator size={10} />
                </>
            )}
            {!!iconOtherSource &&
                <>
                    <div style={{ height:"14px", color: isSelected ? Colors.light_royal_blue : Colors.brownish_grey}}>
                    {iconOtherSource }
                    </div>
                    <BaseSpaceSeparator size={10} />
                </>

            }
            <PCText semibold fontSize={14} color={isSelected ? Colors.light_royal_blue : Colors.brownish_grey}>
                {text}
            </PCText>
        </div>
    )
}

export const SpotFeaturesChipsSection = ({formikArgs}:{formikArgs: FormikProps<FormState>})=> {
    
    const {values, setFieldValue} = formikArgs;
    return (
  <>
      <PCText color={Colors.greyishBrown} semibold fontSize={14}>
          {Resources.Cechy_miejsca_parkingowego}
      </PCText>
      <BaseSpaceSeparator size={5} />
 
        <Stack direction={'row'} spacing={"10px"} width={"auto"} flexWrap={"wrap"} >
            <ToggleChips isSelected={values.isForHandicapped} onToggleValue={(value)=>setFieldValue("isForHandicapped", value)} text={Resources.Miejsce_dla_niepelnosprawnych} iconFontAwesome={faWheelchair}/>
            <ToggleChips isSelected={values.electricCharger} onToggleValue={(value)=>setFieldValue("electricCharger", value)} text={Resources.Ladowarka_dla_pojazdow_elektrycznych} iconFontAwesome={faChargingStation}/>
            <ToggleChips isSelected={values.isMonitored} onToggleValue={(value)=>setFieldValue("isMonitored", value)} text={Resources.Miejsce_monitorowane} iconOtherSource={<Iconify icon="icon-park-twotone:videocamera-one" width={17.5} sx={{height:'14px', padding:0}} color={"inherit"}/>}/>
            <ToggleChips isSelected={values.isGuarded} onToggleValue={(value)=>setFieldValue("isGuarded", value)} text={Resources.Miejsce_strzezone} iconOtherSource={<Iconify icon="healthicons:security-worker" width={17.5} sx={{height:'14px',  padding:0}} color={"inherit"}/>} />
            <ToggleChips isSelected={values.isForMaintanceStaff} onToggleValue={(value)=>setFieldValue("isForMaintanceStaff", value)} text={Resources.Miejsce_dla_obslugi} iconFontAwesome={faUserCog}/>
            <ToggleChips isSelected={values.isForGuest} onToggleValue={(value)=>setFieldValue("isForGuest", value)} text={Resources.Miejsce_dla_gosci} iconFontAwesome={faUserTie}/>
            {/*<ToggleChips isSelected={values.onlyAdminCanMakeReservations} onToggleValue={(value)=>setFieldValue("onlyAdminCanMakeReservations", value)} text={Resources.Rezerwacja_tylko_przed_administratora} iconFontAwesome={faUserShield}/>*/}
        </Stack>
      <BaseSpaceSeparator size={20} />
  </>
        
        
    )
    
    
}