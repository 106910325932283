import * as React from "react";
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import Spinner from "../components/Spinner";
import { applicationState } from "../redux/ApplicationState";

interface Props {
    jwt: string
}

const Container = (props: Props) => {
    const {jwt} = props;
    const {invitationGuid} = useParams<{invitationGuid: string}>()
    const [redirect, setRedirect] = React.useState<string>(null);

    const init = () => {
        if(jwt){
            setRedirect(`/joingroupfromlink/${invitationGuid}`)
        }
        else{
            setRedirect(`/chooseinvitationlinkregistrationtype/${invitationGuid}`)
        }
    }

    React.useEffect(() => {
        init();
    }, []);

    if(redirect){
        return <Redirect to={redirect} />
    }

    return (
        <div
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}
        >
            <Spinner size="extra-large" />
        </div>
    )
}

const mapStateToProps = (state: applicationState): Partial<Props> => ({
    jwt: state.user.token
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);