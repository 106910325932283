import { faCalendar, faCalendarAlt, faClock } from "@fortawesome/free-regular-svg-icons";
import * as React from "react";
import Colors from "../../styles/Colors";
import { PCClasses } from "../../utils/CSSUtils";
import { addMinutes, formatDate, formatDateTime, formatTime } from "../../utils/DateTimeUtils";
import { hasSomeParentTheClass } from "../../utils/DOMUtils";
import Input from "../forms/Input";
import Triangle from "../Triangle";
import PCDatePickerContent1 from "./PCDatePickerContent";
import PCTimePickerContent from "./PCTimePickerContent";
import { DEFAULT_MIN, DEFAULT_MAX, DEFAULT_GRANULATION_MINUTES } from "./Constants";
import "./Styles.scss";

const TRIANGLE_WIDTH = 40;

const TRIANGLE_HEIGHT = 20;

export const PCDatePickerContent = PCDatePickerContent1;

export interface PCDateimePickerProps {
    value: Date,
    onChange?: (v: Date) => void;
    max?: Date;
    min?: Date;
    mode?: "date" | "time" | "datetime";
    label?: string;
    placeholder?: string;
    isInvalid?: boolean;
    children?: (text: string) => React.ReactNode;
    onFocus?: () => void;
    onBlur?: () => void;
    borderColor?: string;
    granulationMinutes?: number;
}

interface State {
    showContent: boolean,
    contentOpacity: number,
    width: number,
    height: number
}



export default class PCDateTimePicker extends React.Component<PCDateimePickerProps, State> {

    private wrapper: HTMLDivElement = null;

    private isShown: boolean = false;



    constructor(props){
        super(props);
        this.state = {
            contentOpacity: 0,
            showContent: false,
            height: 0,
            width: 0
        }
    }

    private show = (event: React.MouseEvent) => {
        this.props.onFocus && this.props.onFocus();
        this.isShown = true;
        document.body.click();
        event.stopPropagation();
        /*
         document.body.click();
        event.stopPropagation();
        This is a hack to make the datepicker open and hide correctly when clicking on the input.
        The issue is caused by react 17 and higher, which treats events differently than react 16.
        * */
        document.body.addEventListener("click", this.onBodyClicked);
        this.setState({
            showContent: true
        }, () => {
            setImmediate(() => {
                this.setState({contentOpacity: 1});
            })
        })
    }

    private hide = () => {
        this.props.onBlur && this.props.onBlur();
        document.body.removeEventListener("click", this.onBodyClicked);
        this.setState({contentOpacity: 0});
        setTimeout(() => {
            this.setState({
                showContent: false
            });
            this.isShown = false;
        }, 200);
    }

    componentDidMount(){
        this.updateDimensions();
    }

    componentWillUnmount(){
        document.body.removeEventListener("click", this.onBodyClicked);
    }

    componentDidUpdate(){
        this.updateDimensions();
    }

    private onBodyClicked = (e: MouseEvent) => {
        if(!hasSomeParentTheClass(e.target, "pc-datetimepicker-content")){
            this.hide();
        }
    }

    private onClick = (event:React.MouseEvent) => {
        if(!this.isShown){
            this.show(event);
        }
    }

    private getMin = () => {
        return this.props.min || DEFAULT_MIN;
    }

    private getMax = () => {
        return this.props.max || DEFAULT_MAX;
    }

    private updateDimensions = () => {
        const {width, height} = this.state;
        const newHeight = this.wrapper?.clientHeight || 0;
        const newWidth = this.wrapper?.clientWidth || 0;
        if(newWidth !== width || newHeight !== height){
            this.setState({height: newHeight, width: newWidth});
        }
    }

    private getText = () => {
        const {mode = "datetime", value} = this.props;
        if(!value){
            return "";
        }

        if(mode === "time"){
            return formatTime(value) || "";
        }
        else if(mode === "date"){
            return formatDate(value) || "";
        }
        else{
            return formatDateTime(value) || "";
        }
    }

    private getIcon = () => {
        const {mode = "datetime"} = this.props;
        if(mode === "time"){
            return faClock;
        }
        else{
            return faCalendarAlt;
        }
    }

    private getGranulationMinutes = () => {
        return this.props.granulationMinutes || DEFAULT_GRANULATION_MINUTES;
    }

    private onDatePickerSelected = (d: Date) => {
        const {mode = "datetime", onChange} = this.props;
        const value = this.getValue();
        const min = this.getMin();
        const max = this.getMax();
        if(mode === "date"){
            onChange && onChange(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
            this.hide();
        }
        else{
            const valueCandidate = new Date(d.getFullYear(), d.getMonth(), d.getDate(), value.getHours(), value.getMinutes());
            const moduloMin = min.getMinutes()%(this.getGranulationMinutes());
            const moduloMax = max.getMinutes()%(this.getGranulationMinutes());

            const targetMin = addMinutes(min, moduloMin === 0 ? 0 : 15 - moduloMin);
            const targetMax = addMinutes(max, -1*moduloMax);

            if(valueCandidate < targetMin){
                onChange && onChange(targetMin);
            }
            else if(valueCandidate > targetMax){
                onChange && onChange(targetMax);
            }
            else{
                onChange && onChange(valueCandidate);
            }
        }
    }

    private onTimePickerSelected = (d: Date) => {
        const {onChange} = this.props;
        const value = this.getValue();
        const min = this.getMin();
        const max = this.getMax();
        const newValue = new Date(value.getFullYear(), value.getMonth(), value.getDate(), d.getHours(), d.getMinutes());
        onChange && onChange(newValue);
        this.hide();
    }

    private getValue = () => {
        return this.props.value || new Date();
    }

    private renderContent(){
        const {mode = "datetime"} = this.props;
        const {contentOpacity, height} = this.state;
        const value = this.getValue();

        return (
            <div
                className={PCClasses("pc-datetimepicker-content")}
                style={{opacity: contentOpacity, top: height + 19, left: 0}}
            >
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: -TRIANGLE_HEIGHT,
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <Triangle height={TRIANGLE_HEIGHT} width={TRIANGLE_WIDTH} color={Colors.white} />
                </div>
                {(mode === "date" || mode === "datetime") && (
                    <PCDatePickerContent
                        mobileWidth={210}
                        desktopWidth={210}
                        currentDay={value}
                        onDaySelected={this.onDatePickerSelected}
                        max={this.getMax()}
                        min={this.getMin()}
                    />
                )}
                {(mode === "time" || mode === "datetime") && (
                    <PCTimePickerContent
                        value={value}
                        onChange={this.onTimePickerSelected}
                        max={this.getMax()}
                        min={this.getMin()}
                        mode={mode}
                        granulationMinutes={this.getGranulationMinutes()}
                    />
                )}
            </div>
        )
    }

    render(){
        const {children, placeholder, label, isInvalid, borderColor} = this.props;
        const {showContent} = this.state;
        const text = this.getText();

        return (
            <div
                className={PCClasses(["pc-datetimepicker-wrapper"])}
                ref={i => this.wrapper = i}
                onClick={this.onClick}
            >
                {!!children && children(text || placeholder)}
                {!children && (
                    <Input
                        readOnly
                        value={text}
                        placeholder={placeholder}
                        icon={this.getIcon()}
                        label={label}
                        isInvalid={isInvalid}
                        borderColor={borderColor}
                    />
                )}
                {showContent && this.renderContent()}
            </div>
        )
    }
}