import { CreateUserSessionFromNativeCredentialsDto, CreateUserSessionFromThirdPartyCredentialsDto, ErrorCode, LoginType, ServiceConfig, SessionsService } from "parkcash-api";
import Resources from "../../Resources";
import { getErrorMessage } from "../utils/ErrorUtils";
import Notify from "../utils/Notify";

export const loginNative = async (login: string, password: string) => {
    try{
        const response = await new SessionsService(new ServiceConfig()).createUserSessionFromNativeCredentials(new CreateUserSessionFromNativeCredentialsDto({
            login,
            password
        }));
        if(response.isSuccess){
            const {jwt, userId} = response.result;
            return {jwt, userId};
        }
        else{
            return getErrorMessage(response.error.code);
        }
    }
    catch{
        return getErrorMessage(ErrorCode.NetworkError);
    }
    
}

export const loginToThirdParty = async (oauthToken: string, type: LoginType) => {
    try{
        const response = await new SessionsService(new ServiceConfig()).createUserSessionFromThirdPartyCredentials(new CreateUserSessionFromThirdPartyCredentialsDto({
            loginType: type,
            oAuthAccessToken: oauthToken
        }));
        if(response.isSuccess){
            const {jwt, userId} = response.result;
            return {jwt, userId};
        }
        else{
            return response.error.code === ErrorCode.ThirdPartyLoginFailed ? true : getErrorMessage(response.error.code);
        }
    }
    catch{
        return getErrorMessage(ErrorCode.NetworkError);
    }
}