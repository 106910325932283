import { ParkingMapSpotInfo } from "./Data";

export interface IParkingSpotsMetadata {
    getSpotInfo(number: string, sector: string, level: string): ParkingMapSpotInfo;
    getSpotInfoById(spotId: string): ParkingMapSpotInfo;
    getAllAvailableSpots(): ParkingMapSpotInfo[];
}

export class NullParkingSpotsMetadata implements IParkingSpotsMetadata {
    getSpotInfoById(spotId: string): ParkingMapSpotInfo {
        throw new Error("Method not implemented.");
    }
    getSpotInfo(number: string, sector: string, level: string): ParkingMapSpotInfo {
        return null;
    }
    getAllAvailableSpots(): ParkingMapSpotInfo[] {
        return [];
    }

}

export default class ParkingSpotsMetadata implements IParkingSpotsMetadata {

    constructor(private info: ParkingMapSpotInfo[], private hasManyLevels: boolean, private hasManySectors: boolean, private spotUniquenessType: "number" | "numberAndSector", private isLevelsAsSupersectors: boolean){

    }
    getSpotInfoById(spotId: string): ParkingMapSpotInfo {
        return this.info.find(s => s.id === spotId);
    }

    getSpotInfo(number: string, sector: string, level: string): ParkingMapSpotInfo {
        if(this.spotUniquenessType === "numberAndSector"){
            return this.info.find(i => i.number === number && i.sector === sector);
        }
        if(this.hasManyLevels && this.hasManySectors){
            return this.info.find(i => i.number === number && i.sector === sector && i.level === level);
        }
        else if(!this.hasManyLevels && this.hasManySectors){
            return this.info.find(i => i.number === number && i.sector === sector);
        }
        else if(this.hasManyLevels && !this.hasManySectors){
            return this.info.find(i => i.number === number && i.level === level);
        }
        else{
            return this.info.find(i => i.number === number);
        }
    }

    getAllAvailableSpots(): ParkingMapSpotInfo[] {
        return this.info.filter(i => typeof i.status === "number");
    }
}