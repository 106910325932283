import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import Colors from "../styles/Colors";
import { PCClasses } from "../utils/CSSUtils";

export default (props: {
    onClick: () => void,
    icon: IconProp,
    style?: React.CSSProperties
}) => {
    const {icon, onClick, style = {}} = props;

    return (
        <div
            onClick={onClick}
            className={PCClasses("pc-button")}
            style={{
                backgroundColor: Colors.saphire,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 34,
                width: 34,
                borderRadius: 17,
                ...style
            }}
        >
            <FontAwesomeIcon icon={icon} color={Colors.white} style={{fontSize: 15, letterSpacing: 0.27}} />
        </div>
    )
}