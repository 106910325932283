import * as React from "react";
import Colors from "../styles/Colors";
import { PCText } from "../styles/Texts";
import { PCClasses } from "../utils/CSSUtils";

export default (props: {
    items: Array<{id: number, text: string}>,
    current: number,
    onChange: (id: number) => void;
    children: (id: number) => React.ReactNode,
    itemWidth?: "auto" | "full"
}) => {
    const {children, current, items, onChange, itemWidth = "full"} = props;
    const [contentWidth, setContentWidth] = React.useState<number>(null);
    const container = React.useRef<HTMLDivElement>();

    React.useEffect(() => {
        setContentWidth(container.current.clientWidth);
    }, []);

    if(itemWidth === "full"){
        return (
            <div
                style={{width: '100%'}}
                ref={container}
            >
                {!!contentWidth && (
                    <div
                        style={{width: contentWidth}}
                    >
                        <div style={{display: "flex", alignItems:"center"}}>
                            {items.map(item => {
                                const {text, id} = item;
                                const isSelected = id === current;
    
                                return (
                                    <div 
                                        key={id} 
                                        style={{
                                            flex: 1, 
                                            paddingBottom: 5, 
                                            borderBottomStyle: "solid",
                                            borderBottomWidth: 1,
                                            borderBottomColor: isSelected ? Colors.light_royal_blue : Colors.very_light_pink
                                        }}
                                        className={PCClasses([], {"pc-button": !isSelected})}
                                        onClick={isSelected ? undefined : () => onChange(id)}
                                    >
                                        <PCText textAlign={"center"} semibold fontSize={14} color={isSelected ? Colors.light_royal_blue : Colors.very_light_pink}>
                                            {text}
                                        </PCText>
                                    </div>
                                )
                            })}
                        </div>
                        {children(current)}
                    </div>                    
                )}
            </div>
        )
    }
    else{
        return (
            <div ref={container}>
                <div style={{display: 'flex'}}>
                    {items.map(item => {
                        const {text, id} = item;
                        const isSelected = id === current;
                        return (
                            <div  
                                key={id} 
                                style={{width: 70, marginBottom: 5}}
                                className={PCClasses([], {"pc-button": !isSelected})}
                                onClick={isSelected ? undefined : () => onChange(id)}
                            >
                                <PCText textAlign="center" semibold fontSize={14} color={isSelected ? Colors.light_royal_blue : Colors.brownish_grey}>
                                    {text}
                                </PCText>
                            </div>
                        );
                    })}
                </div>
                <div style={{display: 'flex'}}>
                    {items.map(item => {
                        const {id} = item;
                        const isSelected = id === current;
                        return (
                            <div key={id} style={{width: 70, height: 1, backgroundColor: isSelected ? Colors.light_royal_blue : Colors.very_light_pink}} />
                        );
                    })}
                    <div style={{flex: 1, height: 1, backgroundColor: Colors.very_light_pink}} />
                </div>
                {children(current)}
            </div>
        )
    }
}