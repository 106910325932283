import React from "react"
import {PCText} from "../../styles/Texts";
import Colors from "../../styles/Colors"
import RegularInput from "../../components/forms/RegularInput";
import Resources from "../../../Resources";
import {BLIK_CODE_MASK} from "../../utils/Constants";

export type PaymentMethodsTypes = 'None' | 'BlikInPlace' | 'FastPayments';

const blikImage = require("../../../assets/images/Blik_logo.svg");
const fastPaymentImageBlue = require("../../../assets/images/fast_payment_blue.svg");
const fastPaymentImageWhite = require("../../../assets/images/fast_payment_white.svg");

const blikRegex = /^[0-9]{1,6}$/;

const formatBlikCode = (code: string): string => {
    return code.trim().split(" ").join("")
}

export interface ISelectedPaymentInfo {
    selectedMethod: PaymentMethodsTypes,
    blikCode?: string,
    isBlikCodeValid?: boolean
}

export const PaymentMethod = (_p: {
    allowedMethods?: PaymentMethodsTypes[]
    selectedMethod?: PaymentMethodsTypes
    blikError?: string

    onPaymentMethodChanged?: (info: ISelectedPaymentInfo) => void
    onBlikErrorClear?: () => void

}) => {

    const allowedMethod = _p.allowedMethods ?? [
        'FastPayments',
        'BlikInPlace'
    ]

    const [selectedMethod, setSelectedMethod] = React.useState<PaymentMethodsTypes>("None");
    const [blikCode, setBlikCode] = React.useState<string>("");
    const [isBlikCodeValid, setIsBlikCodeValid] = React.useState<boolean>(false);

    function selectMethod(method: PaymentMethodsTypes, blikCode?: string) {
        setSelectedMethod(method);
        setBlikCode(blikCode);
        let formattedBlikCode = blikCode;
        if (formattedBlikCode) {
            formattedBlikCode = formatBlikCode(formattedBlikCode);
            setIsBlikCodeValid(blikRegex.test(formattedBlikCode))
        }
        let info = {
            selectedMethod: method,
            blikCode: formattedBlikCode,
            isBlikCodeValid: blikRegex.test(formattedBlikCode)
        };
        _p?.onPaymentMethodChanged(info)

    }

    if (selectedMethod === "None" || allowedMethod.indexOf(selectedMethod) === -1) {
        if (allowedMethod.indexOf('BlikInPlace') !== -1) {
            selectMethod("BlikInPlace", '')
        } else {
            if (allowedMethod.indexOf('FastPayments') !== -1) {
                selectMethod("FastPayments", '')
            }

        }
    }

    const onMethodSelected = (method: PaymentMethodsTypes, blikCode: string) => {
        selectMethod(method, blikCode);
    }

    return (<div>

            <div style={{
                display: "flex",
                "justifyContent": "space-evenly"
            }}>
                {allowedMethod.indexOf("BlikInPlace") !== -1 && <div
                    style={{
                        padding: "10px",
                        backgroundColor: selectedMethod === "BlikInPlace" ? Colors.light_royal_blue : "initial",
                    }}
                    onClick={() => onMethodSelected("BlikInPlace", "")}
                >
                    <img style={{
                        width: "90px",
                        height: "42px",
                        cursor:"pointer"
                    }} src={blikImage} alt="BLIK"/>
                    <PCText textAlign={"center"}
                            color={selectedMethod === "BlikInPlace" ? Colors.white : Colors.light_royal_blue}
                            fontSize={12}
                            semibold={true}>BLIK</PCText>
                </div>}

                {allowedMethod.indexOf("FastPayments") !== -1 && <div
                    style={{
                        padding: "10px",
                        backgroundColor: selectedMethod === "FastPayments" ? Colors.light_royal_blue : "initial",
                    }}
                    onClick={() => onMethodSelected("FastPayments", "")}
                >
                    <img style={{
                        width: "90px",
                        height: "42px",
                        cursor:"pointer"
                    }} src={selectedMethod === "FastPayments" ? fastPaymentImageWhite : fastPaymentImageBlue}
                         alt="BLIK"/>
                    <PCText textAlign={"center"}
                            color={selectedMethod === "FastPayments" ? Colors.white : Colors.light_royal_blue}
                            fontSize={12} semibold={true}>{Resources.SZYBKA_PLATNOSC}</PCText>
                </div>}

            </div>
            <br/>
            {selectedMethod == "BlikInPlace" && <div>
                <RegularInput
                    value={blikCode}
                    error={_p.blikError}
                    touched={true}
                    name="blikCode"
                    setFieldValue={(key, value) => {
                        selectMethod("BlikInPlace", value)
                    }}
                    setFieldTouched={(key, touched) => {
                        _p.onBlikErrorClear()
                    }}
                    label={Resources.Kod_blik}
                    showClearButton
                    showValidatedButton={isBlikCodeValid}
                    mask={BLIK_CODE_MASK}
                    style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "xx-large"
                    }}
                    onPaste={e =>{
                        let clipBoardText = e.clipboardData.getData('Text');
                        if (clipBoardText.length < 100 //to prevent processing long clipboard texts
                            && blikRegex.test(formatBlikCode(clipBoardText))) {
                            selectMethod("BlikInPlace", clipBoardText);
                        }
                        
                    }  }
                    

                />
                <PCText
                    onClick={async () => {
                        let clipBoardText = (await navigator.clipboard.readText());
                        if (clipBoardText.length < 100 //to prevent processing long clipboard texts
                            && blikRegex.test(formatBlikCode(clipBoardText))) {
                            selectMethod("BlikInPlace", clipBoardText);
                        }
                    }}
                    textAlign={"center"}
                    color={Colors.light_royal_blue} fontSize={20}>{Resources.Wklej_kod}</PCText>
            </div>}


        </div>

    )

}

