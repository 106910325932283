import { FormikErrors, FormikTouched } from "formik";
import React from "react";
import Colors from "../../styles/Colors";
import { PCText } from "../../styles/Texts";
import PCDateTimePicker, {PCDateimePickerProps} from "../PCDatetimePicker";

interface Props extends PCDateimePickerProps {
    error: FormikErrors<Date>,
    name: string;
    touched: FormikTouched<Date>;
    
    setFieldValue: (key: string, value) => void;
    setFieldTouched: (key: string, touched: boolean) => void;
}

export default (props: Props) => {
    const {error, name, setFieldValue, touched, setFieldTouched, onBlur, onFocus,  onChange,  value, ...rest} = props;
    const isError = !!error && !!touched;

    return (
        <>
            <PCDateTimePicker 
                value={value}
                onChange={v => {
                    onChange && onChange(v);
                    setFieldValue(name, v);                    
                }}
                onBlur={() => {
                    setFieldTouched(name, true);
                    onBlur && onBlur();
                }}
                onFocus={() => {
                    setFieldTouched(name, false);
                    onFocus && onFocus();
                }}
                isInvalid={isError}
                {...rest}
            />
            <div style={{paddingTop: 4, paddingBottom: 4, height: 20, boxSizing: "border-box"}}>
                <PCText fontSize={12} lineHeight={1} letterSpacing={0} color={Colors.red}>{isError ? error : ""}</PCText>
            </div>
        </>
    )
}
