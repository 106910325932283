import React from "react"

export default (props: {
    height?: number
}) => {
    const {height = 129} = props;

    return (
        <div
            style={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 3,                    
                height,                    
                borderRadius: 25,
                backgroundImage: "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.67) 35%, #ffffff 62%, #ffffff)"
            }}
        />
    )
}