import { FormikHelpers } from "formik";
import { CreateVirtualPilotLinkDto, LinkActiveRange, ServiceConfig, VirtualPilotLinkExpiration, VirtualPilotsService } from "parkcash-api";
import * as React from "react";
import * as yup from "yup";
import Resources from "../../Resources";
import RegularDateTimePicker from "../components/forms/RegularDateTimePicker";
import RegularInput from "../components/forms/RegularInput";
import ModalForm from "../components/ModalForm";
import { SwitchWithLabel } from "../components/Switch";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { EMAIL_REGEX, PHONE_MASK, PHONE_REGEX } from "../utils/Constants";
import { roundToHours, addHours } from "../utils/DateTimeUtils";
import { getErrorMessage } from "../utils/ErrorUtils";
import { useJWT } from "../utils/JWTUtils";
import Notify from "../utils/Notify";
import { getServerPhoneNumber } from "../utils/PhoneNumberUtils";
import { NAME_AND_SURNAME_FORMAT } from "../utils/UserInfoUtils";
import ChoosePilotDropdownList from "./ChoosePilotDropdownList";

const pilot = require("../../assets/images/pilot.svg");

interface FormState {
    pilotId: string,
    from: Date,
    to: Date,
    nameAndSurname: string,
    email?: string,
    phoneNumber?: string,
    oneTime: boolean
}

const validate = (state: FormState) => {

    const errors: any = {}

    const {email, phoneNumber,nameAndSurname,from,pilotId,to} = state;
    if(!pilotId){
        errors.pilotId = Resources.Wymagane;
    }

    if(nameAndSurname){
        if(!NAME_AND_SURNAME_FORMAT.test(nameAndSurname)){
            errors.nameAndSurname = Resources.Niepoprawny_format;
        }
    }

    if(!nameAndSurname){
        errors.nameAndSurname = Resources.Wymagane;
    }
    if(!to){
        errors.to = Resources.Wymagane;
    }
    if(!from){
        errors.from = Resources.Wymagane;
    }
    

    if(from && to){
        if(from >= to){
            errors.from = Resources.Data_rozpoczecia_musi_byc_mniejsza_od_daty_zakonczenia;
        }
    }

    if(!email && !phoneNumber){
        errors.email = Resources.Wymagane_jest_podanie_emaila_lub_telefonu;
    }

    if(!!phoneNumber && !PHONE_REGEX.test(phoneNumber)){
        errors.phoneNumber = Resources.Niepoprawny_format;
    }

    if(
        !!email && !EMAIL_REGEX.test(email)
    ) {
        errors.email = Resources.Niepoprawny_format;
    }


    return errors;
}

export default (props: {
    visible: boolean,
    onClose: () => void,
    mode: "admin" | "user",
    pilotId: string,
    onSubmitted: () => void;    
}) => {
    const {onClose,visible,mode,pilotId,onSubmitted} = props;
    const jwt = useJWT();
    const initialValues: FormState = {
        pilotId,
        from: roundToHours(new Date(), true),
        to: roundToHours(addHours(new Date(), 1), true),
        nameAndSurname: "",
        email: "",
        phoneNumber: "",
        oneTime: false
    }
    
    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {
        const {setStatus, setSubmitting} = helpers;
        const {email,to,pilotId,nameAndSurname,phoneNumber,from,oneTime} = state;

        try{
            
            const dto = new CreateVirtualPilotLinkDto({
                activeRange: new LinkActiveRange({
                    from,
                    to
                }),
                issuedForEmail: email || null,
                issuedForPhone: getServerPhoneNumber(phoneNumber) || null,
                virtualPilotsIds: [pilotId],
                predefinedExpiration: VirtualPilotLinkExpiration.NotSet,
                isOneTimeOnly: oneTime,
                autoSendGeneratedLinkToTarget: true,
                description: nameAndSurname
            });
            const response = await new VirtualPilotsService(new ServiceConfig({jwt})).createLink(dto);
            if(response.isSuccess){
                Notify.Success(Resources.Link_zostal_wyslany);
                onSubmitted();
            }
            else{
                setStatus(getErrorMessage(response.error.code));
            }
        }
        catch{
            setStatus(getErrorMessage());
        }
        finally{
            setSubmitting(false);
        }
    }

    return (
        <ModalForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            icon={pilot}
            iconWidth={100}
            iconHeight={114}
            visible={visible}
            onClose={onClose}
            title={Resources.Udostepnij_mobilnego_pilota}
            validateOnBlur
        >
        {args => {
            const {errors, touched, values, setFieldValue, setFieldTouched} = args;
            console.log('touched: ', touched);
            console.log('errors: ', errors);
            console.log('values: ', values);

            return (
                <React.Fragment>
                    <ChoosePilotDropdownList 
                        error={errors.pilotId}
                        mode={mode}
                        name="pilotId"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        touched={touched.pilotId}
                        value={values.pilotId}
                    />
                    <BaseSpaceSeparator size={30} />
                    <PCText color={Colors.greyishBrown} semibold fontSize={14}>
                        {Resources.W_jakich_godzinach_chcesz_udostepnic_pilota}
                    </PCText>
                    <BaseSpaceSeparator size={10} />
                    <RegularDateTimePicker
                        value={values.from}
                        error={errors.from}
                        touched={touched.from}
                        name="from"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        label={Resources.Poczatek}    
                    />
                    <RegularDateTimePicker 
                        value={values.to}
                        error={errors.to}
                        touched={touched.to}
                        name="to"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        label={Resources.Koniec}    
                    />
                    <BaseSpaceSeparator size={30} />
                    <PCText color={Colors.greyishBrown} semibold fontSize={14}>
                        {Resources.Komu_chcesz_udostepnic_pilota}
                    </PCText>
                    <BaseSpaceSeparator size={10} />
                    <RegularInput 
                        value={values.nameAndSurname}
                        error={errors.nameAndSurname}
                        touched={touched.nameAndSurname}
                        name="nameAndSurname"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        label={Resources.Imie_i_Nazwisko}
                        showClearButton
                        showValidatedButton
                    />
                    <RegularInput 
                        value={values.email}
                        error={errors.email}
                        touched={touched.email}
                        name="email"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        label={Resources.Email}
                        showClearButton
                        showValidatedButton
                    />
                    <RegularInput 
                        value={values.phoneNumber}
                        error={errors.phoneNumber}
                        touched={touched.phoneNumber}
                        name="phoneNumber"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        label={Resources.Nr_telefonu}
                        showClearButton
                        showValidatedButton
                        mask={PHONE_MASK}
                        onFocus={() => {
                            if(!values.phoneNumber){
                                setFieldValue("phoneNumber", "+48")
                            }
                        }}
                    />
                    <BaseSpaceSeparator size={20} />
                    <SwitchWithLabel 
                        label={Resources.Udostepnij_jednorazowo}
                        value={values.oneTime}
                        onChange={v => setFieldValue("oneTime", v)}
                    />
                </React.Fragment>
            );
        }}
        </ModalForm>
    );
}