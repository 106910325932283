import { AcceptParkingInvitationDto, ErrorCode, GetPrivateParkingInvitationDetailsResult, InvitationsService, LoginType, ServiceConfig } from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import Resources from "../../Resources";
import ErrorView from "../components/ErrorView";
import Spinner from "../components/Spinner";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { formatAddress } from "../utils/AddressUtils";
import { getErrorMessage } from "../utils/ErrorUtils";
import Notify from "../utils/Notify";
import LoginContent from "./LoginContent";


export default () => {
    const {invitationGuid} = useParams<{invitationGuid: string}>();
    const [progress, setProgress] = React.useState(true);
    const [error, setError] = React.useState<ErrorCode>(null);
    const [parkingInfo, setParkingInfo] = React.useState<GetPrivateParkingInvitationDetailsResult>(null);

    const init = async () => {
        try{
            const response = await new InvitationsService(new ServiceConfig()).getPrivateParkingInvitationDetails(invitationGuid);
            if(response.isSuccess){
                setParkingInfo(response.result)
            }
            else{
                setError(response.error.code);
            }
        }
        catch(e){
            const error = e;
            setError(ErrorCode.NetworkError);
        }
        finally{
            setProgress(false);
        }
    }

    React.useEffect(() => {
        init();
    }, []);

    if(progress){
        return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Spinner />
            </div>
        );
    }

    if(typeof error === "number"){
        return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <ErrorView title={getErrorMessage(ErrorCode.NetworkError)}/>
            </div>
        );
    }

    return (
        <>
            <div>
                <PCText textAlign="center" fontSize={20} color={Colors.brownish_grey}>
                    {Resources.Zostales_zaproszony_do_parkingu} {parkingInfo.friendlyName} {Resources.pod_adresem} {formatAddress(parkingInfo.address, true)}. {Resources.W_celu_dolaczenia_do_grupy_zaloguj_sie_lub_zaloz_konto}
                </PCText>
            </div>
            <BaseSpaceSeparator size={25} />
            <LoginContent 
                getRegistrationUrl={() => `/registration/${invitationGuid}`}
                showRemindPassword={false}
                afterUserInitialized={async (jwt) => {
                    const response = await new InvitationsService(new ServiceConfig({jwt})).acceptParkingInvitation(new AcceptParkingInvitationDto({
                        invitationGuid
                    }));
                    if(response.isSuccess){
                        
                    }
                    else{
                        Notify.Error(Resources.Nastapil_blad);
                    }
                }}
            />
        </>
    );
}