import { Formik, FormikHelpers } from "formik";
import { ErrorCode, ServiceConfig, SmsVerificationsService, StartSmsVerificationDto } from "parkcash-api";
import React from "react";
import { Redirect, useParams } from "react-router-dom";
import Resources from "../../Resources";
import Input from "../components/forms/Input";
import { StandardButton } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { MOBILE_WIDTH_THRESHOLD, PHONE_MASK, PHONE_REGEX } from "../utils/Constants";
import Notify from "../utils/Notify";
import { getServerPhoneNumber } from "../utils/PhoneNumberUtils";
import { useWindowSize } from "../utils/WindowSizeHook";
import { startSMSVerification } from "./SubscriptionsUtils";
import * as yup from "yup";
import RegularInput from "../components/forms/RegularInput";
import {Asserts} from "yup";

const subscription_type = require("../../assets/images/subscription_type.svg");

const validationSchema = yup.object({
    phone: yup.string().required(Resources.Wymagane).matches(PHONE_REGEX, Resources.Niepoprawny_format)
});

interface FormState extends Asserts<typeof validationSchema> {}

export default () => {
    const {windowHeight, windowWidth} = useWindowSize();
    const {parkingId} = useParams<{parkingId: string}>();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const [codeId, setCodeId] = React.useState<string>(null);

  

    const initialValues: FormState = {
        phone: "(+48) "
    }

    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {

        helpers.setSubmitting(true);
        const {codeId: id, message} = await startSMSVerification(state.phone, parkingId);
        if(message){
            Notify.Error(message);
            
        }
        else{
            setCodeId(id);
        }
        helpers.setSubmitting(false);
    }

    if(codeId){
        return <Redirect push to={`/op/entersubscriptionsmscode/${codeId}/${parkingId}`} />;
    }

    return (
        <Formik
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={initialValues}
        >
            {args => {
                const {handleSubmit, values, errors, touched, setFieldTouched, setFieldValue, isSubmitting} = args;

                return (
                    <form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingTop: isMobile ? 20 : 120,
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingBottom: isMobile ? 20 : 50
                        }}
                        noValidate={true}
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <img src={subscription_type} width={220} height={193} />
                        <BaseSpaceSeparator size={40} />
                        <div style={{width: isMobile ? 300 : 600}}>
                            <PCText textAlign="center" semibold fontSize={isMobile ? 28 : 36} color={Colors.black}>
                                {Resources.Podaj_numer_dla_ktorego_chcesz_przedlużyc_abonament}
                            </PCText>
                        </div>
                        <BaseSpaceSeparator size={isMobile ? 30 : 60} />
                        <div style={{width: isMobile ? 250 : 320}}>
                            <RegularInput 
                                value={values.phone}
                                error={errors.phone}
                                touched={touched.phone}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                name="phone"
                                mask={PHONE_MASK}
                            />
                        </div>
                        <BaseSpaceSeparator size={isMobile ? 25 : 50} />
                        <div style={{width: isMobile ? 250 : 320}}>
                            <StandardButton type="submit" progress={isSubmitting}>{Resources.Wyslij_SMS}</StandardButton>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
}
