import { IVirtualPilotDto, ParkingManagerService, ServiceConfig } from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import Resources from "../../../Resources";
import Body, { SubBody } from "../../layouts/Main/Body";
import { applicationState } from "../../redux/ApplicationState";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { IParkCashApplicationMode } from "../../utils/ParkCashApplicationMode";
import AccessGrid from "./AccessGrid";
import HistoryGrid from "./HistoryGrid";
import SharingsGrid from "./SharingsGrid";

interface Props {
    jwt: string;
    applicationMode: IParkCashApplicationMode;
}

const Container = (props: Props) => {
    const {applicationMode, jwt} = props;
    const parkingId = applicationMode.getCurrentParking()?.parkingId;
    const [pilots, setPilots] = React.useState<{text: string, id: string}[]>([]); 

    React.useEffect(() => {
        const getPilots = async () => {
            try{
                const {isSuccess, result} = await new ParkingManagerService(new ServiceConfig({jwt})).getParkingMobilePilotsList(parkingId);
                if(isSuccess){
                    setPilots(result.entries.map(e => ({
                        id: e.id,
                        text: e.friendlyName
                    })));
                }
            }
            catch{

            }
        }
        getPilots();
    }, [parkingId]);
    

    return (
        <Body title={Resources.Piloty}>
            <AccessGrid jwt={jwt} applicationMode={applicationMode} pilots={pilots}/>
            <BaseSpaceSeparator size={50}/>
            <HistoryGrid jwt={jwt} applicationMode={applicationMode} />
            <BaseSpaceSeparator size={50}/>
            <SharingsGrid jwt={jwt} applicationMode={applicationMode} pilots={pilots}/>
        </Body>
    );
}

const mapStateToProps = (state: applicationState): Props => ({
    jwt: state.user.token,
    applicationMode: state.user.applicationMode,
});

export default connect(mapStateToProps)(Container);