import * as React from "react";
import {useEffect, useState} from "react";
import {cultureMap, getInterfaceLanguage} from "../../culture-helper";
import ReactFlagsSelect from "react-flags-select";
import LocalStorageManager from "../../utils/LocalStorageManager";
import {ChangeUserCultureDto, ServiceConfig, UsersService} from "parkcash-api";
import {connect} from "react-redux";
import {applicationState} from "../../redux/ApplicationState";
import {TwoWayMap} from "../../utils/TwoWayMap";

interface LanguageSelectorProps {
    className?: string;
    updateServerCulture?: boolean;
    jwt?: string;
}


async function changeUserSavedCulture(culture: string, jwt: string) {

    const usersService = new UsersService(new ServiceConfig({jwt: jwt}));
    await usersService.changeUserCulture(new ChangeUserCultureDto({culture: culture}));

}

const langMap = new TwoWayMap<string, string>({
    "pl": "PL",
    "en": "US"
})


const LanguageSelector = (props: LanguageSelectorProps) => {
    const [selectedLang, setSelectedLang] = useState("PL");

    useEffect(() => {

        let l = getInterfaceLanguage();
        setSelectedLang(langMap.getValue(l.toLowerCase()));

    }, []);

    return (

        <ReactFlagsSelect
            {...props}
            countries={[
                "PL",
                "US"
            ]}
            placeholder=""
            selected={selectedLang}
            showSelectedLabel={true}
            showOptionLabel={true}
            customLabels={{"US": "EN", "PL": "PL"}}
            onSelect={async (l) => {
                const newLang = langMap.getKey(l);
                const newCulture = cultureMap.getValue(newLang);
                LocalStorageManager.SetCurrentLang(newLang);
                if (props.updateServerCulture) {
                    await changeUserSavedCulture(newCulture, props.jwt);
                }

                window.location.reload();
            }}
        />
    )
}


const mapStateToProps = (state: applicationState, ownProps: LanguageSelectorProps): Partial<LanguageSelectorProps> => ({
    ...ownProps,
    jwt: state.user.token
});

const mapDispatchToProps = (): Partial<LanguageSelectorProps> => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
