import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import {CardProps} from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import {useBoolean} from '../../hooks/use-boolean';
import Iconify from '../../components/iconify';
import {usePopover} from '../../components/custom-popover';
import TextMaxLine from '../../components/text-max-line';
import {useTheme} from "@mui/material/styles";
import Resources from "../../../../Resources";
import {GetReportsContextParkingsResultEntry, IParkingReportEntry} from "parkcash-api";
import {ReportType} from "parkcash-api";

// ----------------------------------------------------------------------
function getIconSource(reportType: ReportType): string {
    switch (reportType) {
        case ReportType.DriversReservationsReport:
            return "fluent:vehicle-car-parking-20-regular";
        case ReportType.InvoicesReport:
            return "vaadin:invoice";
        case ReportType.TransactionsReport:
            return "ant-design:transaction-outlined";
        default:
            return "mdi:report-box-outline";
    }
}

interface Props extends CardProps {
    item: ReportItem;
    parkingsContext: GetReportsContextParkingsResultEntry[];
    selected?: boolean;
    onSelect?: VoidFunction;
    onGenerateReport?: (reportId: string, reportTitle: string) => void
}

interface ReportItem extends IParkingReportEntry {
    type?: string

}

export default function FileManagerFileItem({
                                                item,
                                                selected,
                                                onSelect,
                                                parkingsContext,
                                                onGenerateReport,
                                                sx,
                                                ...other
                                            }: Props) {
    const checkbox = useBoolean();
    const details = useBoolean();
    const popover = usePopover();
    const reportType = item?.reportType;
    const theme = useTheme();
    const [showForm, setShowForm] = React.useState(false);
    let iconSource = getIconSource(reportType);
    const renderIcon = <Iconify icon={iconSource} color={theme.palette.primary.main} width={60}/>
    const renderAction = (<Stack direction="row" alignItems="center" sx={{
        top: 8,
        right: 8,
        position: 'absolute'
    }}>

        <IconButton color={popover.open ? 'inherit' : 'default'} size={'large'} title={Resources.Generuj_raport}
                    onClick={/*  () => setShowForm(true)*/
                        () => {
                            if (onGenerateReport) {

                                onGenerateReport(item.id, item.name);
                            }
                        }}>
            <Iconify icon="iconoir:play" color={theme.palette.success.main} width={40}/>
        </IconButton>
        {/*  {showForm &&*/}
        {/*  <GeneratedReportsForm visible={showForm} onClose={() => setShowForm(false)} parkingsContext={parkingsContext} reportId={item.id} reportTitle={item.name}/>*/}
        {/*   }*/}

    </Stack>);

    const renderText = (<>
        <TextMaxLine
            persistent
            variant="subtitle2"
            onClick={details.onTrue}
            sx={{
                width: 1,
                mt: 2,
                mb: 0.5
            }}
        >
            {item.name}
        </TextMaxLine>
        <Box

            sx={{
                maxWidth: 0.99,
                typography: 'caption',
                color: 'text.disabled',
                overflowWrap: 'break-word',
                whiteSpace: 'normal'
            }}
        >
            {item?.description ?? ''}

        </Box>
    </>);
    return (<>
        <Stack
            component={Paper}
            alignItems="flex-start"
            elevation={15}
            sx={{
                p: 2.5,
                borderRadius: 2,
                bgcolor: 'background.paper',
                cursor: 'pointer',
                position: 'relative',
                transition: 'all 0.3s ease', // smooth transition for all changes
                transform: 'scale(1)',  // default scale
                boxShadow: (theme) => theme.customShadows.z20,  // default boxShadow

                "&:hover": {
                    transform: 'scale(1.05) translateY(-5px)', // scale up and move up a bit
                    boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.2)',  // a more pronounced shadow
                },

                ...((checkbox.value || selected) && {
                    bgcolor: 'background.paper',
                    boxShadow: (theme) => theme.customShadows.z20,
                }), ...sx,
            }}
            {...other}
        >
            <Box>
                {renderIcon}
            </Box>
            {renderText}
            {renderAction}
        </Stack>

    </>);
}
