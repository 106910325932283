import * as React from "react";
import ModalForm from "../components/ModalForm";
import * as yup from "yup";
import { Asserts } from "yup";
import Resources from "../../Resources";
import { FormikHelpers } from "formik";
import {
  IVehicleExDto,
  ServiceConfig,
  VehicleDto2,
  VehicleEngineType,
  VehiclesService,
} from "parkcash-api";
import RegularInput from "../components/forms/RegularInput";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import ConfirmModal from "../components/ConfirmModal";
import Notify from "../utils/Notify";
import { useJWT } from "../utils/JWTUtils";
import { getErrorMessage } from "../utils/ErrorUtils";
import RegularDropdownList from "../components/forms/RegularDropdownList";
import Colors from "../styles/Colors";
import { transformLicencePlateNumber } from "../utils/LicencePlateNumberUtils";

const vehicleForm = require("../../assets/images/vehicleForm.svg");

interface FormState {
  licensePlateNumber: string;
  make?: string;
  model?: string;
  engineType: VehicleEngineType;
}

export default (props: {
  visible: boolean;
  onClose: () => void;
  initialVehicle: IVehicleExDto;
  onVehicleRemoved: () => void;
  onSaved: (vehicle: IVehicleExDto) => void;
}) => {
  const { onClose, visible, initialVehicle, onVehicleRemoved, onSaved } = props;
  const jwt = useJWT();
  const initialValues: FormState = {
    licensePlateNumber: initialVehicle?.licensePlateNumber || "",
    make: initialVehicle?.make || "",
    model: initialVehicle?.model || "",
    engineType: initialVehicle?.engineType || VehicleEngineType.Other,
  };
  const validationSchema = yup.object({
    licensePlateNumber: yup.string().required(Resources.Wymagane),
    engineType: yup.string().required(Resources.Wymagane),
  });

  const onSubmit = async (
    state: FormState,
    helpers: FormikHelpers<FormState>
  ) => {
    const { licensePlateNumber, make, model, engineType } = state;
    const { setSubmitting, setStatus } = helpers;
    try {
      if (initialVehicle) {
        const removeResponse = await new VehiclesService(
          new ServiceConfig({ jwt })
        ).deleteVehicle(initialVehicle.id);
        if (!removeResponse.isSuccess) {
          setStatus(getErrorMessage(removeResponse.error.code));
          return;
        }
      }

      const vehicle: VehicleDto2 = new VehicleDto2({
        licensePlateNumber,
        make: make || undefined,
        model: model || undefined,
        engineType,
      });
      const response = await new VehiclesService(
        new ServiceConfig({ jwt: jwt })
      ).createVehicle(vehicle);
      if (response.isSuccess) {
        const savedVehicle: IVehicleExDto = {
          ...vehicle,
          id: response.result.id,
        };
        onSaved(savedVehicle);
      } else {
        setStatus(getErrorMessage(response.error.code));
      }
    } catch {
      setStatus(getErrorMessage());
    } finally {
      setSubmitting(false);
    }
  };
  const onRemove = async () => {
    const confirm = await ConfirmModal.show({
      text: Resources.Czy_na_pewno_chcesz_usunac_pojazd,
      confirmText: Resources.Usun,
    });

    if (confirm) {
      try {
        const response = await new VehiclesService(
          new ServiceConfig({ jwt })
        ).deleteVehicle(initialVehicle.id);

        if (response.isSuccess) {
          onVehicleRemoved();
        } else {
          Notify.Error(getErrorMessage(response.error.code));
        }
      } catch {
        Notify.Error(getErrorMessage());
      }
    }
  };

  return (
    <ModalForm
      icon={vehicleForm}
      iconWidth={104}
      iconHeight={110}
      title={Resources.Dane_pojazdu}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      visible={visible}
      onClose={onClose}
      showCancelButton
      topIcon={!!initialVehicle ? faTrashAlt : undefined}
      onTopIconClick={onRemove}
    >
      {(args) => {
        const { values, errors, touched, setFieldTouched, setFieldValue } =
          args;

        return (
          <React.Fragment>
            <RegularInput
              transformText={transformLicencePlateNumber}
              name="licensePlateNumber"
              value={values.licensePlateNumber}
              error={errors.licensePlateNumber}
              touched={touched.licensePlateNumber}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              label={Resources.Numer_rejestracyjny}
              showValidatedButton
              showClearButton
            />
            <RegularDropdownList
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              error={errors.engineType}
              value={values.engineType}
              touched={touched.engineType}
              name="engineType"
              label={Resources.Typ_silnika}
              borderColor={Colors.brownish_grey}
              actions={[
                { id: VehicleEngineType.Electric, text: Resources.Elektryczny },
                { id: VehicleEngineType.Hybrid, text: Resources.Hybrydowy },
                { id: VehicleEngineType.LPG, text: Resources.LPG },
                { id: VehicleEngineType.Other, text: Resources.Spalinowy },
              ]}
            />
            <RegularInput
              name="make"
              value={values.make}
              error={errors.make}
              touched={touched.make}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              label={Resources.Marka}
              showValidatedButton
              showClearButton
            />
            <RegularInput
              name="model"
              value={values.model}
              error={errors.model}
              touched={touched.model}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              label={Resources.Model}
              showValidatedButton
              showClearButton
            />
          </React.Fragment>
        );
      }}
    </ModalForm>
  );
};
