export function formatNumber(i: number) {
    if(i.toString().length === 1){
        return '0' + i.toString();
    }
    else{
        return i.toString();
    }
}

export const parseFloatString = (val: string, def: number = null) => {
    if(!val){
        return def;
    }

    const result = parseFloat(val.replace(",", "."));
    if(!result){
        return def;
    }

    return result;
};
import {fPercentage} from "../MUI/utils/format-number";

export const nullOrUndefined=(numberToCheck: number|null|undefined)=>{
    if(numberToCheck===null ||numberToCheck===undefined){
        return true
    }
    else {
        return false
    }
}

export const calculatePercentage = (searchedPartValue: number | undefined, wholeValue: number | undefined): number | undefined => {
    if (nullOrUndefined(searchedPartValue) || nullOrUndefined(wholeValue)) {
        return undefined;
    } else if(searchedPartValue===0){
        return 0;
    }
    else if (wholeValue === 0) {
        // Handle the division by zero case, you might return undefined, null, or some other value
        return undefined; // or any other appropriate value or action
    } else {
        return (fPercentage((searchedPartValue! / wholeValue!)*100));
    }
}
