import ModalForm from "../../../components/ModalForm";
import Resources from "../../../../Resources";
import * as React from "react";
import {useEffect, useState} from "react";
import {FormikHelpers} from "formik";
import {useUser} from "../../../user/UseUserHook";
import {
    useCustomizedNotification
} from "../../../MUI/components/AddedOrModyfiedMuiComponents/CustomizedNotification/useCustomizedNotification";

import {AssignLicensePlatesToSpotFormFormSection} from "./AssignLicensePlatesToSpotFormListFormSection";
import {AssignLicensePlatesToSpotFormValues} from "./AssignLicensePlatesToSpotFormTypes";
import {ParkingManagerService, ServiceConfig} from "parkcash-api";
import {AssignLicensePlatesToSpotDto, AssignLicensePlatesToSpotEntry} from "parkcash-api";

interface Props {
    data: AssignLicensePlatesToSpotFormValues
    visible: boolean;
    onClose: () => void;
    parkingSpotId: string;
    onSubmitted?: () => void;
}

export const AssignLicensePlatesToSpotForm = ({
                                                  data,
                                                  visible,
                                                  onClose,
                                                  parkingSpotId,
                                                  onSubmitted

                                              }: Props) => {
    const {
        token: jwt
    } = useUser();
    const {
        enqueError,
        enqueSuccess
    } = useCustomizedNotification();


    const [initialFormValues, setinitialFormValues] = useState(data);
    const formTitle = Resources.Przypisane_tablice;

    useEffect(() => {
        if (visible && parkingSpotId) {
            setinitialFormValues(data);
        }
    }, [visible, parkingSpotId]);

    return (<ModalForm
        initialValues={initialFormValues}
        iconWidth={90}
        width={"500px"}
        /*   paddingLeft={15}
           paddingRight={15}*/
        iconHeight={110}
        visible={visible}
        onClose={onClose}
        title={formTitle}
        enableReinitialize={true}
        onSubmit={async (values: AssignLicensePlatesToSpotFormValues, actions: FormikHelpers<AssignLicensePlatesToSpotFormValues>) => {
            console.log("formikValues", values);
            {
                 const dto: AssignLicensePlatesToSpotDto = new AssignLicensePlatesToSpotDto({
                     parkingSpotId:parkingSpotId,
                     licensePlateEntries: values.plates.map(plate => new AssignLicensePlatesToSpotEntry({licensePlate: plate}))
                 });
                 try {
                     const result = await new ParkingManagerService(new ServiceConfig({jwt})).assignLicensePlatesToSpot(
                         dto);

                     if (!result.isSuccess) {
                         throw new Error("An Errorr during saving occured")
                     }
                     enqueSuccess(Resources.Dane_zostaly_pomyslnie_zapisane);
                     onClose();
                     if (onSubmitted) {
                         onSubmitted();
                     }
                 } catch (e) {
                     onClose();
                     enqueError(Resources.Wystapil_blad_podczas_zapisu_danych)
                 }
            }
        }}
    >
        {({
              setFieldTouched,
              setFieldValue,
              values,
              errors,
              touched
          }) =>
            <AssignLicensePlatesToSpotFormFormSection
                values={values}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                errors={errors}
                touched={touched}
            />
        }
    </ModalForm>);

}