import * as React from "react";
import { PCClasses } from "../utils/CSSUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faChevronRight, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Colors from "../styles/Colors";
import { PCText } from "../styles/Texts";
import "./Lists.scss";
import { BaseSpaceSeparator } from "../styles/Separators";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import BigButton from "./BigButton";

export const ListAddButton = (props: {
    children: string,
    onClick: () => void;
    backgroundColor?: string;
}) => {
    const {children, onClick, backgroundColor} = props;

    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <BigButton 
                title={children}
                titleFontSize={18}
                titleLetterSpacing={0}
                backgroundColor={backgroundColor}
                width={150}
                height={150}
                icon={faPlusCircle}
                onClick={onClick}
            />
        </div>

    )
}

export const ListContainer = (props: {
    children: React.ReactNode
}) => {
    return (
        <div className={PCClasses("pc-list-container")}>
            {props.children}
        </div>
    )
}

export const ListSeparator = () => <BaseSpaceSeparator size={50} />

export const SmallListAddButton = (props: {
    title: string,
    onClick: () => void;
}) => {
    const {onClick, title} = props;

    return (
        <div onClick={onClick} className={PCClasses("pc-button")} style={{alignSelf: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <FontAwesomeIcon icon={faPlusCircle} color={Colors.light_royal_blue} style={{textAlign: 'center', fontSize: 30, letterSpacing: 0}} />
            <BaseSpaceSeparator size={15} />
            <PCText semibold color={Colors.brownish_grey} fontSize={14}>{title}</PCText>
        </div>
    )
}

export const SmallListItem = (props: {
    icon?: IconProp,
    onIconClick?: () => void,
    iconTitle?: string,
    upperText: string,
    bottomText: string,
    onClick?: () => void
}) => {
    const {bottomText,upperText,icon,iconTitle,onClick,onIconClick} = props;

    const isClickable = !!onClick;
    const isIconClickable = !!onIconClick;


    return (
        <div onClick={onClick} style={{padding: "5px 0px", display: 'flex', alignItems: 'center'}} className={PCClasses([], {"pc-button": isClickable})}>
            <div style={{flex: 1, overflow: "hidden"}}>
                <PCText color={Colors.black} fontSize={18} lineHeight={24/16}>{upperText}</PCText>
                <PCText color={Colors.brownish_grey} fontSize={12} lineHeight={24/12}>{bottomText}</PCText>
            </div>
            <BaseSpaceSeparator size={10} />
            <div className={PCClasses([], {"pc-button": isIconClickable})} onClick={onIconClick} title={iconTitle}>
                {!!icon && <FontAwesomeIcon icon={icon} color={Colors.light_royal_blue} style={{fontSize: 17}}/>}
                {!icon && <FontAwesomeIcon icon={faChevronRight} color={Colors.light_royal_blue} style={{fontSize: 17}}/>}
            </div>
        </div>
    )
}

export const SmallListSeparator = () => {
    return (
        <div style={{width: '100%', backgroundColor: Colors.very_light_pink}}>
            <div style={{height: 1}}/>
        </div>
    );
}