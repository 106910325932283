import {Formik, FormikHelpers, FormikProps} from "formik";
import * as React from "react";
import Resources from "../../../Resources";
import ModalForm from "../../components/ModalForm";
import {
    ErrorCode,
    GetParkingAccessPointsEntry,
    ParkingSpotAvailibilityType,
    ParkingSpotSizeType,
    ParkingSpotType,
    ParkingsService,
    ServiceConfig,
    SpotCreationMode,
} from "parkcash-api";
import {BaseSpaceSeparator} from "../../styles/Separators";
import RegularInput from "../../components/forms/RegularInput";
import {ChipsChooser, SpotFeaturesChipsSection} from "../../components/Chips";
import {
    faBicycle, faCar, faDoorClosed, faDoorOpen, faMotorcycle, faRoad, faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import {applicationState} from "../../redux/ApplicationState";
import {useSelector} from "react-redux";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import Colors from "../../styles/Colors";
import {FormState} from "./FormState";
import {
    getFormStateFromInfo,
    getInitialFormState,
    getParkings,
    getParkingSpotInfo,
    getParkingUsers,
    saveParkingSpot,
    validate,
    validateWithUser,
} from "./Utils";
import {useJWT} from "../../utils/JWTUtils";
import Spinner from "../../components/Spinner";
import {PCText} from "../../styles/Texts";
import {SwitchWithLabel} from "../../components/Switch";
import SearchAddressInput, {searchAddressItem,} from "../../components/forms/SearchAddressInput";
import {getErrorMessage} from "../../utils/ErrorUtils";
import {StandardButton, StandardButton2} from "../../styles/Buttons";
import {IParkCashApplicationMode} from "../../utils/ParkCashApplicationMode";
import InitialProgress from "../../other-pages/start-stop/InitialProgress";
import Iconify from "../../MUI/components/iconify";

const homeWithDrive = require("../../../assets/images/homeWithDrive.svg");

interface Props {
    visible: boolean;
    onClose: () => void;
    mode: "admin" | "user";
    spotId: string;
    onSubmitted: (wasAdded: boolean, spotId: string) => void;
    parkingId?: string;
}

const Content = (props: {
    args: FormikProps<FormState>;
    initialProgress: boolean;
    initialError: ErrorCode;
    shouldAssignUser: boolean;
    users: Array<{ id: string; text: string }>;
    parkings: Array<searchAddressItem>;
    shouldDetermineAdress: boolean;
    onClose: () => void;
}) => {
    const {
        args,
        initialError,
        initialProgress,
        shouldAssignUser,
        parkings,
        users,
        shouldDetermineAdress,
        onClose,
    } = props;
    const {
        values,
        touched,
        errors,
        isSubmitting,
        status,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
    } = args;
    const [accessPoints, setAccessPoints] = React.useState<GetParkingAccessPointsEntry[]>([]);
    const [accessPointsProgress, setAccessPointsProgress] = React.useState(false);
    const jwt = useJWT();

    React.useEffect(() => {
        const getAccessPoints = async (parkingId: string) => {
            try {
                setAccessPointsProgress(true);
                const response = await new ParkingsService(new ServiceConfig({jwt})).getParkingAccessPoints(parkingId);
                if (response.isSuccess) {
                    if (!response.result.entries.some((e) => e.id === values.parkingAccessPointId)) {
                        setFieldValue("parkingAccessPointId", null);
                    }

                    setAccessPoints(response.result.entries || []);
                } else {
                    setAccessPoints([]);
                    setFieldValue("parkingAccessPointId", null);
                }
            } catch {
                setAccessPoints([]);
                setFieldValue("parkingAccessPointId", null);
            } finally {
                setAccessPointsProgress(false);
            }
        };

        if (values?.address?.type === "parking") {
            getAccessPoints(values.address.id);
        } else {
            setAccessPoints([]);
            setFieldValue("parkingAccessPointId", null);
        }
    }, [values.address]);

    if (initialProgress) {
        return (<div
            style={{
                height: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spinner size="medium"/>
        </div>);
    }

    if (initialError) {
        return (<div
            style={{
                height: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <PCText fontSize={12} color={Colors.red} textAlign="center">
                {getErrorMessage(initialError)}
            </PCText>
        </div>);
    }

    return (<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
        {shouldAssignUser && (<RegularDropdownList
            zIndex={20}
            actions={users}
            value={values.ownerId}
            error={errors.ownerId}
            touched={touched.ownerId}
            name="ownerId"
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            variant="standard"
            borderColor={Colors.brownish_grey}
            label={Resources.Wlasciciel_miejsca}
            searchable
        />)}
        <>
            <SearchAddressInput
                current={values.address}
                onSelected={(i) => setFieldValue("address", i)}
                onFocus={() => setFieldTouched("address", false)}
                onBlur={() => setFieldTouched("address", true)}
                label={Resources.Podaj_adres_miejsca}
                placeholder={Resources.Wpisz_adres}
                borderColor={Colors.brownish_grey}
                predefinedItems={parkings}
                disabled={!shouldDetermineAdress}
            />
            {touched.address && errors.address && (<div
                style={{
                    position: "relative",
                    top: 10,
                    paddingTop: 4,
                    paddingBottom: 4,
                    height: 20,
                    boxSizing: "border-box",
                }}
            >
                <PCText
                    fontSize={12}
                    lineHeight={1}
                    letterSpacing={0}
                    color={Colors.red}
                >
                    {touched.address && errors.address ? errors.address : ""}
                </PCText>
            </div>)}
            <BaseSpaceSeparator size={20}/>
        </>
        {!!accessPoints && !!accessPoints.length && (<RegularDropdownList
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            error={errors.parkingAccessPointId}
            touched={touched.parkingAccessPointId}
            value={values.parkingAccessPointId}
            name="parkingAccessPointId"
            actions={accessPoints.map((a) => ({
                text: a.name,
                id: a.id
            }))}
            borderColor={Colors.brownish_grey}
            label={Resources.Punkt_dostepowy}
        />)}
        <div style={{
            display: "flex",
            alignItems: "center"
        }}>
            <div style={{flex: 1}}>
                <RegularInput
                    value={values.spotNumber}
                    error={errors.spotNumber}
                    touched={touched.spotNumber}
                    name="spotNumber"
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    label={Resources.Miejsce}
                    showValidatedButton
                    showClearButton
                />
            </div>
            <BaseSpaceSeparator size={20}/>
            <div style={{flex: 1}}>
                <RegularInput
                    value={values.level}
                    error={errors.level}
                    touched={touched.level}
                    name="level"
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    label={Resources.Poziom}
                    showClearButton
                />
            </div>
        </div>
        <div style={{flex: 1}}>
            <RegularInput
                value={values.sector}
                error={errors.sector}
                touched={touched.sector}
                name="sector"
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                label={Resources.Sektor}
                showClearButton
            />
        </div>
        <BaseSpaceSeparator size={20}/>
        <ChipsChooser
            label={Resources.Typ_miejsca}
            value={values.type}
            onChange={(v) => setFieldValue("type", v)}
            options={[
                {
                    iconFontAwesome: faWarehouse,
                    text: Resources.Garaz,
                    id: ParkingSpotType.Garage,
                },
                {
                    iconFontAwesome: faRoad,
                    text: Resources.Na_zewnatrz,
                    id: ParkingSpotType.Outside,
                },
            ]}
        />
        <BaseSpaceSeparator size={20}/>
        <ChipsChooser
            label={Resources.Rodzaj_pojazdu}
            value={values.sizeType}
            onChange={(v) => setFieldValue("sizeType", v)}
            wrap={true}
            flex={1}
            options={[
                {
                    iconFontAwesome: faCar,
                    text: Resources.Samochod,
                    id: ParkingSpotSizeType.Car,
                },
                {
                    iconFontAwesome: faMotorcycle,
                    text: Resources.Motocykl,
                    id: ParkingSpotSizeType.Motorcycle,
                },
                {
                    iconOtherSource: <Iconify icon={"material-symbols:electric-scooter-outline"}
                                              title={Resources.Hulajnoga} width={17.5} sx={{height: "14px"}} padding={0}
                                              color={"inherit"}/>,
                    text: Resources.Hulajnoga,
                    id: ParkingSpotSizeType.Scooter,
                },
                {
                    iconFontAwesome: faBicycle,
                    text: Resources.Rower,
                    id: ParkingSpotSizeType.Bicycle,
                },
            ]}
        />
        <BaseSpaceSeparator size={20}/>

        <SpotFeaturesChipsSection formikArgs={args}/>
        <ChipsChooser
            label={Resources.Dostepnosc_miejsca}
            value={values.availibilityType}
            onChange={(v) => setFieldValue("availibilityType", v)}
            options={[
                {
                    iconFontAwesome: faDoorOpen,
                    text: Resources.Publiczna,
                    id: ParkingSpotAvailibilityType.Public,
                },
                {
                    iconFontAwesome: faDoorClosed,
                    text: Resources.Ograniczona,
                    id: ParkingSpotAvailibilityType.Limited,
                },
            ]}
        />
        <BaseSpaceSeparator size={30}/>
        {/*   <RegularTextArea
        value={values.directionHints}
        touched={touched.directionHints}
        error={errors.directionHints}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        name="directionHints"
        label={Resources.Wskazowki_dojazdu}
      />*/}
        <BaseSpaceSeparator size={30}/>
        <SwitchWithLabel
            label={Resources.Rezerwacje_bezplatne}
            value={values.shareForFreeInLocalCommunity}
            onChange={(v) => setFieldValue("shareForFreeInLocalCommunity", v)}
        />
        <BaseSpaceSeparator size={20}/>
        <SwitchWithLabel
            label={Resources.Rezerwacje_platne}
            value={values.availableShortTermSharing}
            onChange={(v) => setFieldValue("availableShortTermSharing", v)}
        />
        <BaseSpaceSeparator size={10}/>
        <RegularInput
            disabled={!values.availableShortTermSharing && values.shareForFreeInLocalCommunity}
            value={values.shortTermPrice}
            error={errors.shortTermPrice}
            touched={touched.shortTermPrice}
            name="shortTermPrice"
            showClearButton
            showValidatedButton
            label={Resources.Cena_za_godzine}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
        />
        <RegularInput
            disabled={!values.availableShortTermSharing && values.shareForFreeInLocalCommunity}
            value={values.max24HPrice}
            error={errors.max24HPrice}
            touched={touched.max24HPrice}
            name="max24HPrice"
            showClearButton
            showValidatedButton
            label={Resources.Maksymalna_cena_za_dobe}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
        />
        <BaseSpaceSeparator size={20}/>
        {/* 
           //This option is often used, what should happen with its values when it is set to true?
           <SwitchWithLabel
                label={Resources.Wynajmij_dlugoterminowo}
                value={values.availableLongTermSharing}
                onChange={(v) => setFieldValue("availableLongTermSharing", v)}
            />
            <BaseSpaceSeparator size={10}/>
            <RegularInput
                disabled={!values.availableLongTermSharing}
                value={values.longTermPrice}
                error={errors.longTermPrice}
                touched={touched.longTermPrice}
                name="longTermPrice"
                showClearButton
                showValidatedButton
                label={Resources.Cena_za_miesiac}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
            />
            <BaseSpaceSeparator size={80}/>*/}
        <div
            style={{
                height: 20,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <PCText
                textAlign="center"
                fontSize={12}
                lineHeight={1}
                letterSpacing={0}
                color={Colors.red}
            >
                {status || ""}
            </PCText>
        </div>
        <div style={{
            display: "flex",
            alignItems: "center",
            width: "100%"
        }}>
            <StandardButton2 onClick={onClose}>{Resources.Anuluj}</StandardButton2>
            <BaseSpaceSeparator size={20}/>
            <StandardButton type="submit" progress={isSubmitting}>
                {Resources.Zapisz}
            </StandardButton>
        </div>

        {accessPointsProgress && (<div
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Colors.darkOverlay,
            }}
        >
            <InitialProgress/>
        </div>)}
    </form>);
};

export const ParkingSpotFormContent = (props: Props) => {
    const jwt = useJWT();
    const {
        onClose,
        visible,
        parkingId,
        mode,
        spotId,
        onSubmitted,
    } = props;
    //const shouldAssignUser = mode === "admin"; //commented in case of future business use
    const shouldAssignUser = false;
    const isEdit = !!spotId;
    const shouldDetermineAdress = !parkingId;
    const [initialFormState, setInitialFormState] = React.useState<FormState>(getInitialFormState(null));
    const [initialProgress, setInitialProgress] = React.useState(true);
    const [initialError, setInitialError] = React.useState<ErrorCode>(null);
    const [parkings, setParkings] = React.useState<Array<searchAddressItem>>(null);
    const [users, setUsers] = React.useState<Array<{ id: string; text: string }>>(null);
    const applicationMode = useSelector<applicationState, IParkCashApplicationMode>((s) => s.user.applicationMode);

    const init = async () => {
        let targetParkings: searchAddressItem[] = [];
        const parkingsResponse = await getParkings(jwt);
        if (!parkingsResponse.success) {
            setInitialError(parkingsResponse.error);
            setInitialProgress(false);
            return;
        } else {
            targetParkings = parkingsResponse.result.filter((p) => {
                if (p.spotCreationMode === SpotCreationMode.AllUsers) {
                    return true;
                } else {
                    return applicationMode
                        .getParkings()
                        .some((parking) => parking.parkingId === p.id);
                }
            });

            setParkings(targetParkings);
        }

        if (shouldAssignUser) {
            const {
                success: puSuccess,
                error: puError,
                result: puResult,
            } = await getParkingUsers(parkingId, jwt);
            if (!puSuccess) {
                setInitialError(puError);
                setInitialProgress(false);
                return;
            } else {
                setUsers(puResult);
            }
        }

        if (!isEdit && shouldDetermineAdress) {
            setInitialFormState(getInitialFormState(null));
        } else if (!isEdit && !shouldDetermineAdress) {
            const parking = targetParkings.find((p) => p.id === parkingId);
            setInitialFormState(getInitialFormState(parking));
        } else {
            const {
                success: piSuccess,
                error: piError,
                result: piResult,
            } = await getParkingSpotInfo(spotId, jwt, mode);
            if (piSuccess) {
                setInitialFormState(getFormStateFromInfo(piResult));
            } else {
                setInitialError(piError);
            }
        }
        setInitialProgress(false);
    };

    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {
        const {
            setStatus,
            setSubmitting
        } = helpers;
        try {
            setSubmitting(true);
            // if editing, we didnt change share options automatically to 24/7 cause user could have changed it in the
            // separate form used for spots sharing, and if we used autoShare we will reset this value without
            // informing the user
            const shareSpot24_7 = !isEdit ? true : undefined;
            const {
                result,
                spotId: savedSpotId,
                error,
            } = await saveParkingSpot(state, spotId, jwt, shouldAssignUser, shareSpot24_7);
            if (result) {
                onSubmitted(!isEdit, savedSpotId);
            } else {
                setStatus(getErrorMessage(error));
            }
        } catch {
            setStatus(getErrorMessage());
        } finally {
            setSubmitting(false);
        }
    };

    React.useEffect(() => {
        if (visible) {
            init();
        }
    }, [
        parkingId,
        spotId,
        visible,
        mode
    ]);

    if (!visible) {
        return null;
    }

    return (<Formik
        enableReinitialize
        initialValues={initialFormState}
        onSubmit={onSubmit}
        validate={shouldAssignUser ? validateWithUser : validate}
    >
        {(args) => (<Content
            args={args}
            initialError={initialError}
            initialProgress={initialProgress}
            onClose={onClose}
            parkings={parkings}
            shouldAssignUser={shouldAssignUser}
            shouldDetermineAdress={shouldDetermineAdress}
            users={users}
        />)}
    </Formik>);
};

export default (props: Props) => {
    return (<ModalForm
        initialValues={{}}
        onSubmit={() => {
        }}
        icon={homeWithDrive}
        iconWidth={90}
        iconHeight={110}
        visible={props.visible}
        onClose={props.onClose}
        title={Resources.Miejsce_parkingowe}
        noForm
    >
        {() => <ParkingSpotFormContent {...props} />}
    </ModalForm>);
};
