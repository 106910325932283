import React, {useMemo} from "react";
import Resources from "../../../Resources";
import {
    ServiceConfig
} from "parkcash-api";
import {useUser} from "../../user/UseUserHook";
import GenertedReportsGridViewSection
    from "./sections/GeneratedReportsSections/ReportsFilesGridVievSection/ReportsGridView";
import {useBoolean} from "../../MUI/hooks/use-boolean";

import {
    ParkingReportEntry,
    GetReportsContextParkingsResultEntry,
} from "parkcash-api";
import {ReportsService, } from "parkcash-api";

import {LoadingErrorAndContentWrapper} from "../../components/Common/LoadingErrorAndContentWrapper";
import {MinimalPageWrapper} from "../../MUI/components/AddedOrModyfiedMuiComponents/PageWrapers/MinimalPageWraper";
import {
    FetchOptions, useFetchDataUsingDataSourceClass
} from "../../MUI/components/AddedOrModyfiedMuiComponents/DataFetcher/useFetchUsingDataSourceClass.";



export default function GeneratedReportsPage() {
    
    const {
        token: jwt,
        applicationMode
    } = useUser();
    const parkingId = applicationMode.getCurrentParking()?.parkingId || null;
    const fetchReportsOptions: FetchOptions<ParkingReportEntry, any> = useMemo((): FetchOptions<ParkingReportEntry, any> =>{
        return {
            additionalInfo: {
                jwt,
                parkingId
            },
            load: async (loadOptions, additionalInfo) => {
                // Fetch data logic goes here...
                const {
                    filter,
                    page,
                    pageSize,
                    sort
                } = loadOptions;
                const {
                    jwt,
                    parkingId
                } = additionalInfo;

                const {
                    isSuccess,
                    result,
                    error
                } = await new ReportsService(new ServiceConfig({jwt})).getParkingReports(parkingId);

                return isSuccess ? {
                    items: result.entries,
                    totalItems: result?.entries?.length
                } : error.code;
            }
        }
    }, [jwt, parkingId]);
    
    const { data:reportsItems, loading, error } = useFetchDataUsingDataSourceClass<ParkingReportEntry, any>(fetchReportsOptions);
    const fetchParkingsContextsOption: FetchOptions<GetReportsContextParkingsResultEntry, any> = useMemo((): FetchOptions<GetReportsContextParkingsResultEntry, any> =>{
        return {
            additionalInfo: {
                jwt,
                notUsed: "notUsed"
            }, 
            load: async (loadOptions, additionalInfo) => {
                // Fetch data logic goes here...
                const {
                    filter,
                    page,
                    pageSize,
                    sort
                } = loadOptions;
                const {
                    jwt,
                    notUsed
                } = additionalInfo;
                
                const {
                    isSuccess,
                    result,
                    error
                } = await new ReportsService(new ServiceConfig({jwt})).getReportsContextParkings(notUsed);

                return isSuccess ? {
                    items: result.entries,
                    totalItems: result?.entries?.length
                } : error.code;
            }
        }
    }, [jwt]);
    const { data:parkingsContext, loading:loadingParkingsContext, error:errorLoadingParkingsContext } = useFetchDataUsingDataSourceClass<ParkingReportEntry, any>(fetchParkingsContextsOption);
    
    const confirm = useBoolean();
    const isDataAvaliable = (!!reportsItems && reportsItems?.length > 0)&& (!!parkingsContext && parkingsContext?.length > 0);
    return (
        <MinimalPageWrapper title={Resources.Raporty_Generowane}>
            <LoadingErrorAndContentWrapper loading={loading || loadingParkingsContext} errors={[error, errorLoadingParkingsContext ]} data={isDataAvaliable} >
                <GenertedReportsGridViewSection
                                                reports={reportsItems}
                                                parkingsContext={parkingsContext}
                                             /*   sx={{
                                                    p:{xs:1, md:2}
                                                }}*/
                />
            </LoadingErrorAndContentWrapper>
               
        </MinimalPageWrapper>

        )
}





