import {IconDefinition} from "@fortawesome/free-brands-svg-icons";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Formik, FormikConfig, FormikProps} from "formik";
import * as React from "react";
import {createPortal} from "react-dom";
import Resources from "../../Resources";
import {StandardButton, StandardButton2} from "../styles/Buttons";
import Colors from "../styles/Colors";
import {BaseSpaceSeparator} from "../styles/Separators";
import {PCText} from "../styles/Texts";
import {PCClasses} from "../utils/CSSUtils";
import {useWindowSize} from "../utils/WindowSizeHook";
import IconButton from "./IconButton";
import "./ModalForm.scss";

const modalForm = document.getElementById("modalForm");

interface ModalFormProps<T> extends FormikConfig<T> {
    visible: boolean;
    onClose: () => void;
    submitButtonText?: string;
    showSubmitButton?: boolean;
    showCancelButton?: boolean;
    cancelButtonText?: string;
    icon?: any;
    iconWidth?: number;
    iconHeight?: number;
    zIndex?: number;
    bottom?: React.ReactNode;

    title?: React.ReactNode;
    subtitle?: React.ReactNode;

    topIcon?: IconDefinition;
    onTopIconClick?: () => void;
    top?: () => React.ReactNode;

    paddingLeft?: number;
    paddingRight?: number;
    paddingTop?: number;
    paddingBottom?: number;

    noForm?: boolean;
    headerTitle?: string;
    width?: string | "number";
    showBottomSeparator?: boolean;
}

export default function <T>(props: ModalFormProps<T>) {
    const {
        showSubmitButton = true,
        submitButtonText = Resources.Zapisz,
        showCancelButton = false,
        cancelButtonText = Resources.Anuluj,
        icon,
        iconWidth,
        iconHeight,
        visible,
        onClose,
        zIndex = 2000,
        title,
        subtitle,
        children,
        bottom,
        topIcon,
        onTopIconClick,
        top,
        paddingBottom = 50,
        paddingLeft = 50,
        paddingRight = 50,
        paddingTop = 50,
        noForm = false,
        headerTitle = "",
        width = 400,
        showBottomSeparator = true,
        ...formikConfig
    } = props;
    const [rendered, setRendered] = React.useState(false);
    const {isMobile} = useWindowSize();
    const targetWidth = isMobile ? "100%" : width;
    const formRef = React.useRef(null);

    const beginHiding = () => {
        if (formRef.current) {
            //we also change z-index to -1 so we do this manually if we want animation to work
            formRef.current.classList.remove('pc-modalform-overlay');
            formRef.current.classList.remove('pc-modalform-shown');
        }
        const timeoutId = setTimeout(() => {
            setRendered(false);
            document.body.classList.remove(PCClasses("pc-modalformhown"));
        }, 500); // Match the duration of the CSS transition

        return () => clearTimeout(timeoutId);

    };

    const beginShowing = () => {
        setRendered(true);
        const timeoutId = setTimeout(() => {
            if (formRef.current) {
                document.body.classList.add(PCClasses("pc-modalformhown"));
                //we also change z-index to -1 so we do this manually if we want animation to work
                formRef.current.classList.add('pc-modalform-overlay');
            }
        }, 500); // Match the duration of the CSS transition

        return () => clearTimeout(timeoutId);
    };

    React.useEffect(() => {
        if (visible) {
            beginShowing();

        } else {
            beginHiding();

        }
        /*
        *  This code can cause problems because of class pc-modalformhown added to document.body,
        if this class is not removed (e.g conditional rendering of this component which cause that this useEffect will not be called)
        * all pages will be displayed incorrectly e.g - cutting bottom of the page problem !!
        * */
    }, [visible]);


    const header = (
        <div
            style={{
                borderBottom: `0.1px solid ${Colors.very_light_pink}`,
                minHeight: 50,
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 30px",
            }}
        >
            <FontAwesomeIcon
                icon={faTimes}
                className={PCClasses("pc-button")}
                onClick={onClose}
                style={{fontSize: 34}}
                color={Colors.brownish_grey}
            />
            <div style={{paddingLeft: 10, paddingRight: 10}}>
                <PCText color={Colors.brownish_grey} fontSize={17}>
                    {headerTitle}
                </PCText>
            </div>
            {!!top && top()}
            {!!topIcon && !top && (
                <IconButton onClick={onTopIconClick} icon={topIcon}/>
            )}
            {!topIcon && !top && <div/>}
        </div>
    );

    const topContentElements = (
        <>
            {!!icon && (
                <img
                    src={icon}
                    width={iconWidth}
                    height={iconHeight}
                    style={{marginBottom: 30}}
                />
            )}
            {!!title && (
                <PCText
                    letterSpacing={0}
                    fontSize={24}
                    color={Colors.black}
                    textAlign="center"
                    semibold
                >
                    {title}
                </PCText>
            )}
            {!!subtitle && (
                <>
                    <BaseSpaceSeparator size={5}/>
                    <PCText textAlign="center" fontSize={15} color={Colors.brownish_grey}>
                        {subtitle}
                    </PCText>
                </>
            )}
        </>
    );



    return createPortal(
        <div
            ref={formRef}
            style={
                zIndex ? rendered ? {zIndex: zIndex} : {zIndex: -1} : {}
            }
            className={`${PCClasses("pc-modalform")} ${rendered ? "pc-modalform-shown" : ""}`}>
            {(noForm && rendered) && (

                <div
                    className={PCClasses("pc-modalform-content")}
                    style={{

                        width: targetWidth,
                    }}
                >
                    {header}
                    <div
                        style={{
                            paddingTop,
                            paddingRight,
                            paddingLeft,
                            paddingBottom,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            flex: 1,
                            overflow: "auto",
                        }}
                    >
                        {topContentElements}
                        <div style={{width: "100%"}}>
                            <BaseSpaceSeparator size={50}/>
                            {(children as any)(null)}
                        </div>
                    </div>
                </div>
            )}
            {(!noForm  && rendered) && (
                <Formik {...formikConfig}>
                    {(args) => {
                        const {handleSubmit, isSubmitting, status} = args;
                        return (
                            <form
                                noValidate={true}
                                autoComplete="off"
                                className={PCClasses("pc-modalform-content")}
                                onSubmit={handleSubmit}
                                style={{
                                    width: targetWidth,
                                }}
                            >
                                {header}
                                <div
                                    style={{
                                        paddingTop,
                                        paddingRight,
                                        paddingLeft,
                                        paddingBottom,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        flex: 1,
                                        overflow: "auto",
                                    }}
                                >
                                    {topContentElements}
                                    <div style={{width: "100%"}}>
                                        <BaseSpaceSeparator size={50}/>
                                        {(children as any)(args, rendered)}
                                        {showBottomSeparator && <BaseSpaceSeparator size={80}/>}
                                        <div
                                            style={{
                                                height: 20,
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <PCText
                                                textAlign="center"
                                                fontSize={12}
                                                lineHeight={1}
                                                letterSpacing={0}
                                                color={Colors.red}
                                            >
                                                {status || ""}
                                            </PCText>
                                        </div>
                                        {(showCancelButton || showSubmitButton) && (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {showCancelButton && (
                                                    <div style={{flex: 1}}>
                                                        <StandardButton2 onClick={onClose}>
                                                            {cancelButtonText}
                                                        </StandardButton2>
                                                    </div>
                                                )}
                                                {showCancelButton && showSubmitButton && (
                                                    <BaseSpaceSeparator size={20}/>
                                                )}
                                                {showSubmitButton && (
                                                    <div style={{flex: 1}}>
                                                        <StandardButton
                                                            type="submit"
                                                            progress={isSubmitting}
                                                        >
                                                            {submitButtonText}
                                                        </StandardButton>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {!!bottom && bottom}
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            )}
        </div>,
        modalForm
    );
}
