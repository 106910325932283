import {
    faArrowAltCircleRight, faEdit, faEye, faStopCircle, faTrashAlt, faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import {
    CancelSubscriptionPurchaseDto,
    ClientReservationStatus,
    ErrorCode,
    GetParkingProcessesViewProcessType,
    GetParkingProcessesViewResultEntryDto,
    MarkStartStopParkingProcessAsManualCanceledByManagerDto,
    ParkingManagerService,
    ParkingSpotsService,
    ServiceConfig,
    SortOrder,
    StandardResponseWrapperOfObjectAndErrorWrapperOfObject,
    StartStopService,
    SubscriptionPeriodType,
    SubscriptionsService,
} from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import ReservationDetails from "../../bookings/ReservationDetails";
import ParkingSpotNumberPresenter from "../../components/ParkingSpotNumberPresenter";
import PCDataGrid from "../../components/PCDataGrid";
import ActionColumn, {ActionIcon,} from "../../components/PCDataGrid/ActionColumn";
import ReservationStatusPresenter from "../../bookings/ReservationStatusPresenter";
import Body from "../../layouts/Main/Body";
import Colors from "../../styles/Colors";
import {PCText} from "../../styles/Texts";
import {CustomDataSource, FilterOperator, IDataSource,} from "../../utils/DataSource";
import {IParkCashApplicationMode} from "../../utils/ParkCashApplicationMode";
import {getFilters, getMultiKeysValue, getSorts} from "../../utils/SieveUtils";
import {BaseSpaceSeparator} from "../../styles/Separators";
import {StandardButton} from "../../styles/Buttons";
import CreateExternalReservationForm from "./CreateExternalReservationForm";
import StatusPresenter from "../../components/StatusPresenter";
import Notify from "../../utils/Notify";
import {getErrorMessage} from "../../utils/ErrorUtils";
import SubscriptionPurchaseDetails from "./SubscriptionPurchaseDetails";
import ChangeSubscriptionPurchaseForm from "./ChangeSubscriptionPurchaseForm";
import BuySubscriptionForm from "./BuySubscriptionForm";
import ProlongSubscriptionForm from "./ProlongSubscriptionForm";
import {useWindowSize} from "../../utils/WindowSizeHook";
import {MOBILE_WIDTH_THRESHOLD} from "../../utils/Constants";
import {routePrefix} from "../../../app.json";
import {getTypeText} from "./Utils";
import {useParkingLayout} from "../useParkingLayout";
import ConfirmModal from "../../components/ConfirmModal";
import GuestReservationForm from "./GuestReservationForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setTimeToDayEnd, setTimeToDayStart} from "../../MUI/utils/format-time";
import {CustomizedSelect} from "../../MUI/components/AddedOrModyfiedMuiComponents/CustomizedInputs/CustomizedSelect";
import {DatePickerStyles} from "../../MUI/components/AddedOrModyfiedMuiComponents/Styles/SavedSxStyles";
import {
    CustomizedDatePicker
} from "../../MUI/components/AddedOrModyfiedMuiComponents/CustomizedInputs/CustomizedDatePicker";
import {
    CustomizedTextfield
} from "../../MUI/components/AddedOrModyfiedMuiComponents/CustomizedInputs/CustomizedTextfield";
import {SearchInputWithButton} from "../../components/SearCHInputWithButton";

interface Props {
    jwt: string;
    applicationMode: IParkCashApplicationMode;
}

const ProcessDetailsComponent = (props: {
    item: GetParkingProcessesViewResultEntryDto;
}) => {
    if (props.item.type === GetParkingProcessesViewProcessType.ReservationProcess) {
        return <ReservationDetails reservationId={props.item.id}/>;
    } else if (props.item.type === GetParkingProcessesViewProcessType.Subscription) {
        return (<SubscriptionPurchaseDetails
            purchaseId={props.item.id}
            invoiceStatus={props.item.invoiceStatus}
            invoiceUrl={props.item.invoiceUrl}
        />);
    } else {
        throw new Error(`unsupported process type: ${props.item.type}`);
    }
};

export default ({
                    applicationMode,
                    jwt
                }: Props) => {
    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const [dataSource, setDataSource] = React.useState<IDataSource<GetParkingProcessesViewResultEntryDto>>(null);
    const [
        changeSubscriptionPurchaseSpotFormVisible, setChangeSubscriptionPurchaseSpotFormVisible,
    ] = React.useState(false);
    const [reservationStatusFilter, setReservationStatusFilter] = React.useState("all");
    const [reservationTypeFilter, setReservationTypeFilter] = React.useState("all");
    const [search, setSearch] = React.useState("");
    const [createReservationFormVisible, setCreateReservationFormVisible] = React.useState(false);
    const parkingId = applicationMode.getCurrentParking()?.parkingId || null;
    const [prolongSubscriptionFormVisible, setProlongSubscriptionFormVisible] = React.useState(false);
    const [prolongPurchaseId, setProlongPurchaseId] = React.useState<string>(null);
    const [buySubscriptionFormVisible, setBuySubscriptionFormVisible] = React.useState(false);
    const [changeSubscriptionSpotPurchase, setChangeSubscriptionSpotPurchase] = React.useState<GetParkingProcessesViewResultEntryDto>(
        null);
    const [guestReservationFormVisible, setGuesReservationFormVisible] = React.useState(false);
    const [level, setLevel] = React.useState<string>("all");
    const [sector, setSector] = React.useState<string>("all");
    const {
        levels,
        sectors
    } = useParkingLayout();
    const [startDateControl, setStartDateControl] = React.useState<Date | null>(setTimeToDayStart(new Date()));
    const [endDateControl, setEndDateControl] = React.useState<Date | null>(setTimeToDayEnd(new Date()));

    let grid: PCDataGrid<GetParkingProcessesViewResultEntryDto> = null;

    const reloadDataSource = () => {
        setReservationTypeFilter("all");
        setReservationStatusFilter("all");
        setSearch("");
        
        dataSource?.reload();
    };

    const onLevelChanged = (l: string) => {
        setLevel(l);
        if (!l || l === "all") {
            dataSource.removeFilter("level");
        } else {
            dataSource.filter("level", FilterOperator.EQUALS, l);
        }
    };

    const onSectorChanged = (s: string) => {
        setSector(s);
        if (!s || s === "all") {
            dataSource.removeFilter("sector");
        } else {
            dataSource.filter("sector", FilterOperator.EQUALS, s);
        }
    };

    const onReservationStatusFilterChanged = (v: string) => {
        setReservationStatusFilter(v);
        v === "all" ? dataSource.removeFilter("status") : dataSource.filter("status", FilterOperator.EQUALS, v);
    };

    const onReservationTypeFilterChanged = (v: string) => {
        setReservationTypeFilter(v);
        v === "all" ? dataSource.removeFilter("type") : dataSource.filter("type", FilterOperator.EQUALS, v);
    };

    const onBuy = () => {
        setBuySubscriptionFormVisible(true);
    };

    const onCopyAddress = () => {
        const parkingId = applicationMode.getCurrentParking().parkingId;
        const {host} = window.location;
        navigator.clipboard.writeText(`${host}${routePrefix}/op/external-reservation/${parkingId}`);
        Notify.Info(Resources.Skopiowano_do_schowka);
    };

    const onSearch = (value:string)=> {
        if (!value) {
            dataSource.removeFilter("buyerNIP");
        } else {
            dataSource.filter("buyerNIP", FilterOperator.EQUALS, value);
        }
    };
    
    const resetFilters=()=>{
        setReservationStatusFilter("all");
        setReservationTypeFilter("all");
        setSearch("");
        setLevel("all");
        setSector("all");
        setStartDateControl(setTimeToDayStart(new Date()));
        setEndDateControl(setTimeToDayEnd(new Date()));
    }
    
    

    const getDataSource = (parkingId: string) => {
        resetFilters();
        return new CustomDataSource<GetParkingProcessesViewResultEntryDto, { parkingId: string; jwt: string }>({
            load: async (loadOptions, additionalInfo) => {
                const {
                    jwt,
                    parkingId
                } = additionalInfo;
                const {
                    filter,
                    page,
                    pageSize,
                    sort
                } = loadOptions;
                let targetFilter = filter.filter((f) => f.key !== "buyerNIP" && f.key !== "start" && f.key !== "end");
                const nipFilterValue = filter.find((f) => f.key === "buyerNIP");

                /*dla zakresu początek 8.01, koniec 9.01 powinny się pokazać rezerwację przecinające czyli te które się kończą pomiędzy przedziałem, zaczynają albo zawierają. Warunek: start rezerwacji <=koniec przedziału i koniec rezerwacji >= start przedziału

                Wzór warunku
                    bool overlap = a.start < b.end && b.start < a.end;
                zakładamy że przedział a to jest to co wybierzesz z kontrolki a przedział b to przedział opisujący strat i end rezerwacji

                czyli równoważne zapisowei b.end> a.start && b.start < a.end
        */
                const startDateSelectFilterValue = filter.find((f) => f.key === "start");
                const endDateSelectFilterValue = filter.find((f) => f.key === "end");
                const multiSearchFilterKeys = getMultiKeysValue([
                    "targetName",
                    "buyerNIP",
                    "licensePlateNumber",
                    "email",
                    "phoneNumber",
                    "spotNumber"
                ]);

                const startFilterValue: {
                    key: keyof GetParkingProcessesViewResultEntryDto, operator: FilterOperator, value: any
                } = {
                    key: "start", 
                    operator: FilterOperator.LESS_THAN,
                    value: endDateSelectFilterValue?.value
                };
                const endFilterValue: {
                    key: keyof GetParkingProcessesViewResultEntryDto, operator: FilterOperator, value: any
                } = {
                    key: "end", 
                    operator: FilterOperator.GREATER_THAN,
                    value: startDateSelectFilterValue?.value
                };
                const multiSearchFilterValue: {
                    key: keyof GetParkingProcessesViewResultEntryDto, operator: FilterOperator, value: any
                } = {
                    key: multiSearchFilterKeys as keyof GetParkingProcessesViewResultEntryDto,
                    operator: FilterOperator.CASE_INSENSITIVE_STRING_CONTAINS,
                    value: nipFilterValue?.value
                };
                const filters: Array<{
                    key: keyof GetParkingProcessesViewResultEntryDto, operator: FilterOperator, value: any
                }> = [
                    ...targetFilter,
                ];
                if (nipFilterValue) {
                    filters.push(multiSearchFilterValue);
                }
                if (endDateSelectFilterValue) {
                    filters.push(startFilterValue);
                }
                if (startDateSelectFilterValue) {
                    filters.push(endFilterValue);
                }

                const finalFilters = getFilters(filters);
                const {
                    isSuccess,
                    result,
                    error
                } = await new ParkingManagerService(new ServiceConfig({jwt})).getParkingProcessesView(parkingId,
                    finalFilters,
                    getSorts(sort),
                    page,
                    pageSize
                );

                return isSuccess ? {
                    items: result.entries,
                    totalItems: result.paginationDto.totalElementsCount,
                } : error.code;
            },

            additionalInfo: {
                jwt,
                parkingId
            },
            initialSort: [
                {
                    key: "start",
                    order: SortOrder.Descending
                }
            ],
            initialFilter: [
                {
                    key: "end",
                    operator: FilterOperator.GREATER_THAN,
                    value: setTimeToDayEnd(new Date()).toISOString()
                },
                {
                    key: "start",
                    operator: FilterOperator.LESS_THAN,
                    value: setTimeToDayStart(new Date()).toISOString()
                },
            ]
        });
    };

    const markStartStopAsCompleted = async (processId: string) => {
        try {
            const response = await new StartStopService(new ServiceConfig({jwt})).markStartStopParkingProcessAsManualCanceledByManager(
                new MarkStartStopParkingProcessAsManualCanceledByManagerDto({
                    processId,
                }));
            if (response.isSuccess) {
                reloadDataSource();
            } else {
                Notify.Error(getErrorMessage(response.error.code));
            }
        } catch {
            Notify.Error(getErrorMessage(ErrorCode.NetworkError));
        }
    };

    const cancelProcess = React.useCallback(async (processId: string, processType: GetParkingProcessesViewProcessType) => {

        let text = '';
        switch (processType) {
            case GetParkingProcessesViewProcessType.Subscription:
                text = Resources.Czy_na_pewno_chcesz_usunac_abonament;
                break;
            case GetParkingProcessesViewProcessType.ReservationProcess:
                text = Resources.Czy_na_pewno_chcesz_odwolac_ta_rezerwacje;
                break;
        }

        const confirm = await ConfirmModal.show({
            text: text,
            confirmText: Resources.Usun,
        });
        if (!confirm) {
            return;
        }
        try {
            let response: StandardResponseWrapperOfObjectAndErrorWrapperOfObject = null;
            switch (processType) {
                case GetParkingProcessesViewProcessType.Subscription:
                    response = await new SubscriptionsService(new ServiceConfig({jwt})).cancelSubscriptionPurchase(new CancelSubscriptionPurchaseDto(
                        {
                            subscriptionPurchaseId: processId,
                        }));
                    break;
                case GetParkingProcessesViewProcessType.ReservationProcess:
                    response = await new ParkingSpotsService(new ServiceConfig({jwt}))
                        .cancelReservation(processId);
                    break;
            }
            const {
                isSuccess,
                error
            } = response;
            if (isSuccess) {
                reloadDataSource();
            } else {
                Notify.Error(getErrorMessage(error.code));
            }
        } catch {
            Notify.Error(getErrorMessage());
        }
    }, [reloadDataSource]);

    React.useEffect(() => {
        if (parkingId) {
            setDataSource(getDataSource(parkingId));
        }
    }, [parkingId]);

    function handleStartDateChange(date: Date | null) {
        if (!date) {
            setStartDateControl(date);
            dataSource.removeFilter("start");
        } else {
            const startDateDateStart = setTimeToDayStart(date);
            setStartDateControl(startDateDateStart);
            dataSource.filter("start", FilterOperator.GREATER_THAN_OR_EQUAL_TO, startDateDateStart.toISOString());
        }
    }

    function handleEndDateChange(date: Date | null) {
        if (!date) {
            setEndDateControl(date);
            dataSource.removeFilter("end");
        } else {
            const endDateDateEnd = setTimeToDayEnd(date);
            setEndDateControl(endDateDateEnd);
            dataSource.filter("end", FilterOperator.LESS_THAN_OR_EQUAL_TO, endDateDateEnd.toISOString());
        }
    }

    return (<Body
        title={Resources.Parkowanie}
        rigth={<>
            <div style={{width: isMobile ? "100%" : 215}}>
                <StandardButton onClick={onBuy}>
                    {Resources.Kup_abonament}
                </StandardButton>
            </div>
            {applicationMode.hasExternalReservations() && (<>
                <BaseSpaceSeparator size={20}/>
                <div style={{width: isMobile ? "100%" : 300}}>
                    <StandardButton onClick={onCopyAddress}>
                        {Resources.Skopiuj_adres_zakupu_rezerwacji}
                    </StandardButton>
                </div>
                <BaseSpaceSeparator size={20}/>
                <div style={{width: isMobile ? "100%" : 215}}>
                    <StandardButton
                        onClick={() => setCreateReservationFormVisible(true)}
                    >
                        {Resources.Zrob_rezerwacje}
                    </StandardButton>
                </div>
            </>)}
            {!applicationMode.hasExternalReservations() && (<>
                <BaseSpaceSeparator size={20}/>
                <div style={{width: isMobile ? "100%" : 215}}>
                    <StandardButton
                        onClick={() => setGuesReservationFormVisible(true)}
                    >
                        {Resources.Zrob_rezerwacje}
                    </StandardButton>
                </div>
            </>)}
        </>}
    >
        {applicationMode.hasExternalReservations() && (<CreateExternalReservationForm
            jwt={jwt}
            parkingId={applicationMode.getCurrentParking()?.parkingId}
            visible={createReservationFormVisible}
            onClose={() => setCreateReservationFormVisible(false)}
            onSubmitted={() => {
                setCreateReservationFormVisible(false);
                reloadDataSource();
            }}
        />)}
        <ChangeSubscriptionPurchaseForm
            jwt={jwt}
            parkingId={applicationMode.getCurrentParking()?.parkingId}
            onClose={() => setChangeSubscriptionPurchaseSpotFormVisible(false)}
            onSubmitted={() => {
                setChangeSubscriptionPurchaseSpotFormVisible(false);
                reloadDataSource();
            }}
            purchaseId={changeSubscriptionSpotPurchase?.id}
            visible={changeSubscriptionPurchaseSpotFormVisible}
        />
        <BuySubscriptionForm
            parkingId={applicationMode.getCurrentParking()?.parkingId}
            jwt={jwt}
            visible={buySubscriptionFormVisible}
            onClose={() => setBuySubscriptionFormVisible(false)}
            onSubmitted={() => {
                setBuySubscriptionFormVisible(false);
                reloadDataSource();
            }}
        />
        <GuestReservationForm
            parkingId={applicationMode.getCurrentParking()?.parkingId}
            jwt={jwt}
            visible={guestReservationFormVisible}
            onClose={() => setGuesReservationFormVisible(false)}
            onSubmitted={() => {
                setGuesReservationFormVisible(false);
                reloadDataSource();
            }}
        />
        <ProlongSubscriptionForm
            jwt={jwt}
            onClose={() => setProlongSubscriptionFormVisible(false)}
            onSubmitted={() => {
                setProlongSubscriptionFormVisible(false);
                reloadDataSource();
            }}
            purchaseId={prolongPurchaseId}
            visible={prolongSubscriptionFormVisible}
        />
        <div
            style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "center",
                justifyContent: isMobile ? "flex-start" : "flex-end",
            }}
        >
            {<>
            <div style={{width: isMobile ? "100%" : 250}} >
                <CustomizedDatePicker value={startDateControl} onChange={handleStartDateChange}
                                      label={Resources.Od}
                                   fullWidth
                />
            </div>

              
                <BaseSpaceSeparator size={20}/>
            <div style={{width: isMobile ? "100%" : 250}}>
                <CustomizedDatePicker value={endDateControl} onChange={handleEndDateChange}
                                      label={Resources.Do}
                                      fullWidth
                />
            </div>
                <BaseSpaceSeparator size={20}/>

            </>}
            {!!levels?.length && (<>
                <div style={{width: isMobile ? "100%" : 250}}>
                    <CustomizedSelect label={Resources.Poziom} options={[
                        {
                            value: "all",
                            label: Resources.Wszystkie
                        },
                        ...levels.map((l) => ({
                            value: l,
                            label: l
                        }))
                    ]} onChangeCustom={onLevelChanged} value={level}/>

                </div>
                <BaseSpaceSeparator size={20}/>
            </>)}
            {!!sectors?.length && (<>
                <div style={{width: isMobile ? "100%" : 250}}>
                    <CustomizedSelect label={Resources.Sektor} options={[
                        {
                            value: "all",
                            label: Resources.Wszystkie
                        },
                        ...sectors.map((s) => ({
                            value: s,
                            label: s
                        }))
                    ]} onChangeCustom={onSectorChanged} value={sector}/>
                </div>
                <BaseSpaceSeparator size={20}/>
            </>)}
            <div style={{width: isMobile ? "100%" : 250}}>
                <CustomizedSelect label={Resources.Status} options={[
                    {
                        value: "all",
                        label: Resources.Wszystkie
                    },
                    {
                        value: ClientReservationStatus.Pending.toString(),
                        label: Resources.Aktywne
                    },
                    {
                        value: ClientReservationStatus.Completed.toString(),
                        label: Resources.Zakonczone
                    },
                    {
                        value: ClientReservationStatus.Canceled.toString(),
                        label: Resources.Anulowane
                    },
                    {
                        value: ClientReservationStatus.Confirmed.toString(),
                        label: Resources.Zaplanowane
                    }
                ]}
                                  onChangeCustom={onReservationStatusFilterChanged}
                                  value={reservationStatusFilter}/>
            </div>
            <BaseSpaceSeparator size={20}/>
            <div style={{width: isMobile ? "100%" : 250}}>

                <CustomizedSelect label={Resources.Typ} options={[
                    {
                        value: "all",
                        label: Resources.Wszystkie
                    },
                    {
                        value: GetParkingProcessesViewProcessType.ExternalReservation.toString(),
                        label: Resources.rez_zewn
                    },
                    {
                        value: GetParkingProcessesViewProcessType.ReservationProcess.toString(),
                        label: Resources.rezerwacja
                    },
                    {
                        value: GetParkingProcessesViewProcessType.StartStopParkingProcess.toString(),
                        label: Resources.start_stop
                    },
                    {
                        value: GetParkingProcessesViewProcessType.Subscription.toString(),
                        label: Resources.abonament
                    }
                ]}
                                  onChangeCustom={onReservationTypeFilterChanged}
                                value={reservationTypeFilter}/>
            </div>
        </div>
        <BaseSpaceSeparator size={20}/>
       <SearchInputWithButton value={search} setValue={setSearch} onSearchSubmitted={value=>onSearch(value)}
        label={Resources.Wyszukaj_zakup_po_imieniu_i_nazwsku_nipie_emailu_telefonie_lub_numerze_rejestracyjnym}/>
        <BaseSpaceSeparator size={20}/>
        <PCDataGrid<GetParkingProcessesViewResultEntryDto>
            ref={(i) => {
                if (i) {
                    grid = i;
                }
            }}
            dataSource={dataSource}
            AdditionalRowComponent={ProcessDetailsComponent}
            columns={[
                {
                    width: 280,
                    label: Resources.Parkujacy,
                    dataField: "licensePlateNumber",
                    renderCell: (item) => (<PCText
                        fontSize={16}
                        lineHeight={20 / 16}
                        semibold
                        color={Colors.brownish_grey}
                        letterSpacing={0}
                    >
                        {item.licensePlateNumber}
                        <br/>
                        <PCText
                            inline
                            fontSize={16}
                            semibold
                            lineHeight={20 / 16}
                            color={Colors.black}
                            letterSpacing={0}
                        >
                            {item.targetName}
                            {item.isGuest && (<FontAwesomeIcon icon={faUserTie} color={Colors.black}
                                                               style={{marginLeft: '5px'}}/>)}
                        </PCText>
                    </PCText>),
                },
                {
                    width: 165,
                    label: Resources.Poczatek,
                    dataField: "start",
                },
                {
                    width: 165,
                    label: Resources.Koniec,
                    dataField: "end",
                },
                {
                    width: 148,
                    label: Resources.Miejsce,
                    dataField: "spotNumber",
                    renderCell: (item) => (<ParkingSpotNumberPresenter
                        spotNumber={item.spotNumber}
                        level={item.level}
                        sector={item.sector}
                    />),
                },
                {
                    width: 148,
                    dataField: "effectiveTotalPrice",
                    label: Resources.Koszt,
                    renderCell: (item) => (<PCText
                        lineHeight={20 / 16}
                        fontSize={16}
                        semibold
                        color={Colors.black}
                    >
                        {item.effectiveTotalPrice ? `${item.effectiveTotalPrice} zł` : "-"}
                    </PCText>),
                },
                {
                    width: 148,
                    label: Resources.Typ,
                    dataField: "type",
                    renderCell: (item) => {
                        return (<StatusPresenter
                            color={Colors.very_light_pink_two}
                            textColor={Colors.light_royal_blue}
                            text={getTypeText(item.type)}
                        />);
                    },
                },
                {
                    width: 148,
                    label: Resources.Status,
                    dataField: "status",
                    renderCell: (item) => {
                        return <ReservationStatusPresenter status={item.status}/>;
                    },
                },
                {
                    width: 200,
                    label: Resources.Akcje,
                    renderCell: (item, hasAdditionalRow) => (<ActionColumn>
                        <ActionIcon
                            title={Resources.Szczegoly}
                            icon={faEye}
                            visible={item.type === GetParkingProcessesViewProcessType.ReservationProcess || item.type === GetParkingProcessesViewProcessType.Subscription}
                            iconColor={hasAdditionalRow ? Colors.brownish_grey : Colors.very_light_pink}
                            onClick={() => hasAdditionalRow ? grid.removeAdditionalRow(item) : grid.addAdditionalRow(
                                item)}
                        />
                        <ActionIcon
                            title={Resources.Oznacz_jako_rozliczone}
                            visible={item.type === GetParkingProcessesViewProcessType.StartStopParkingProcess && item.status !== ClientReservationStatus.Completed}
                            icon={faStopCircle}
                            onClick={() => markStartStopAsCompleted(item.id)}
                        />
                        <ActionIcon
                            icon={faEdit}
                            title={Resources.Edytuj}
                            onClick={() => {
                                setChangeSubscriptionSpotPurchase(item);
                                setChangeSubscriptionPurchaseSpotFormVisible(true);
                            }}
                            visible={item.type === GetParkingProcessesViewProcessType.Subscription && item.subscriptionPeriodType !== SubscriptionPeriodType.GivenFullCalendarMonth}
                        />
                        <ActionIcon
                            icon={faArrowAltCircleRight}
                            title={Resources.Przedluz}
                            onClick={() => {
                                setProlongPurchaseId(item.id);
                                setProlongSubscriptionFormVisible(true);
                            }}
                            visible={item.type === GetParkingProcessesViewProcessType.Subscription}
                        />
                        <ActionIcon
                            icon={faTrashAlt}
                            title={Resources.Usun}
                            onClick={() => {
                                cancelProcess(item.id, item.type);
                            }}
                            visible={item.type === GetParkingProcessesViewProcessType.Subscription || item.type === GetParkingProcessesViewProcessType.ReservationProcess && ([
                                ClientReservationStatus.Temporary,
                                ClientReservationStatus.AwaitingPayment,
                                ClientReservationStatus.Confirmed,
                                ClientReservationStatus.Pending
                            ].includes(item.status))}
                        />
                    </ActionColumn>),
                },
            ]}
        />
    </Body>);
};
