import Colors from "../../styles/Colors";

export const CONTAINER_MIN_HEIGHT = 300;

export const HEADER_BACKGROUND_COLOR = Colors.light_royal_blue;

export const MAIN_COLOR = Colors.white;

export const BORDER_COLOR = Colors.greyVeryLight;

export const BOTTOM_COLOR = Colors.brownish_grey;

export const BOTTOM_FONT_SIZE = 18;

export const BORDER_RADIUS = 10;

export const DEFAULT_COLUMN_WIDTH = 1;

export const DEFAULT_PAGE_SIZE = 50;

export const DEFAULT_AVAILABLE_PAGE_SIZES = [5, 10, 20, 30, 50, 100];