import { InvoiceDataDto } from "parkcash-api";
import * as yup from "yup";
import Resources from "../../Resources";
import { EMAIL_REGEX, NIP_REGEX, PHONE_REGEX, POSTAL_CODE_REGEX } from "../utils/Constants";
import { getSeverNipNumber } from "../utils/NIPNumberUtils";
import { getServerPhoneNumber } from "../utils/PhoneNumberUtils";
import InvoiceFormState, { InvoiceType } from "./formInterfaces/InvoiceFormState";
import TargetFormState from "./formInterfaces/TargetFormState";

export const getAcceptRegulationsValidationSchema = () => {
    return {
        regulationsAccepted: yup.bool().oneOf([true], )
    }
}

export const getInvoiceValidationSchema = () => {
    return {
        city: yup.string().notRequired().nullable().when("wantInvoice", {
            is: true, 
            then: yup.string().required(Resources.Wymagane)
        }),
        contrahent: yup.string().notRequired().nullable().when("wantInvoice", {
            is: true, 
            then: yup.string().required(Resources.Wymagane)
        }),
        postalCode: yup.string().notRequired().nullable().when("wantInvoice", {
            is: true, 
            then: yup.string().required(Resources.Wymagane).matches(POSTAL_CODE_REGEX, Resources.Niepoprawny_format)
        }),
        nip: yup.string().notRequired().when("wantInvoice", {
            is: true, 
            then: yup.string().when("type", {
                is: InvoiceType.Company,
                then: yup.string().required(Resources.Wymagane).matches(NIP_REGEX, Resources.Niepoprawny_format),
                otherwise: yup.string().notRequired().nullable().matches(NIP_REGEX, Resources.Niepoprawny_format)
            })                    
        })
    }
}

export const getTargetValidationSchema = () => {
    return {
        nameAndSurname: yup.string().required(Resources.Wymagane)
    }
}

export const getInvoiceData = (state: InvoiceFormState) => {
    if(!state.wantInvoice){
        return undefined
    }

    const {city, contrahent, flatNumber, nip, postalCode, streetName, streetNumber, type} = state;
    return new InvoiceDataDto({
        country: "Poland",
        name: contrahent,
        nip: getSeverNipNumber(nip),
        town: city,
        postalCode,
        apartmentNumber: flatNumber,
        streetName: streetName,
        streetNumber: streetNumber,
        isForNaturalPerson: type === InvoiceType.PrivatePerson,
    });
}

export const getTargetData = (state: TargetFormState) => {
    const {email, licencePlateNumber, nameAndSurname, phoneNumber} = state;
    return {
        name: nameAndSurname,
        licencePlateNumber: licencePlateNumber || undefined,        
        email: email || undefined,  
        phoneNumber: PHONE_REGEX.test(phoneNumber) ? getServerPhoneNumber(phoneNumber) : undefined
    }
}