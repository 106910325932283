
import * as toastr from "toastr";
import "toastr/toastr.scss";

export default {
    Success: (message: string) => {
        toastr.success(message);
    },
    Error: (message: string) => {
        toastr.error(message);
    },
    Info: (message: string) => {
        toastr.info(message);
    },
    Warning: (message: string) => {
        toastr.warning(message);
    }
}