import * as React from "react";
import Colors from "../../styles/Colors";
import { PCText } from "../../styles/Texts";

export const BIG_FORM_HEADER_HEIGHT = 40;

export default (props: {
    isMobile: boolean,
    title: string,
    children: React.ReactNode,
    maxHeight?: number,
    noOverflow?: boolean
}) => {
    const {children, isMobile, title, maxHeight, noOverflow} = props;


    if(isMobile){
        return (
            <div
                style={{
                    backgroundColor: Colors.white,
                    paddingTop: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingBottom: 23
                }}
            >
                {children}
            </div>
        );
    }

    else{
        return (
            <div
                style={{
                    width: 690,
                    backgroundColor: Colors.white,
                    borderRadius: 10,
                    boxShadow: "0 3px 26px 4px rgba(0, 0, 0, 0.08)",
                    height: "min-content",
                }}
            >
                <div
                    style={{
                        height: BIG_FORM_HEADER_HEIGHT, 
                        backgroundColor: Colors.light_royal_blue, 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10
                    }}
                >
                    <PCText textAlign="center" semibold fontSize={21} color={Colors.white} >
                        {title}
                    </PCText>
                </div>

                <div
                    style={{
                        maxHeight,
                        overflow: noOverflow ? undefined : "auto",
                        boxSizing: "border-box",
                        padding: "20px 30px",
                    }}
                >
                    {children}
                </div>
            </div>
        );
    }
}