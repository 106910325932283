import { Formik, FormikHelpers } from "formik";
import * as React from "react";
import { Redirect, useParams } from "react-router-dom";
import Resources from "../../Resources";
import RegularInput from "../components/forms/RegularInput";
import * as yup from "yup";
import { PASSWORD_REGEX } from "../utils/Constants";
import { getErrorMessage } from "../utils/ErrorUtils";
import { CreatePasswordDto, ServiceConfig, UsersService } from "parkcash-api";
import Notify from "../utils/Notify";
import { PCText } from "../styles/Texts";
import Colors from "../styles/Colors";
import { StandardButton } from "../styles/Buttons";
import { BaseSpaceSeparator } from "../styles/Separators";

interface NavigationParams {
    setcode: string
}


interface FormState {
    password: string;
}

export default () => {
    const {setcode} = useParams<NavigationParams>();
    const initialValues: FormState = {password: ""};
    const [redirect, setRedirect] = React.useState(false);
    const validationSchema = yup.object({     
        password: yup.string()
        .required(Resources.Wymagane)
        .matches(PASSWORD_REGEX, Resources.Haslo_musi_zawierac_co_najmniej_7_znakow)
    });

    const onSubmit = async ({password}: FormState, {setSubmitting, setStatus}: FormikHelpers<FormState>) => {
        try{
            setSubmitting(true);
            const {isSuccess, error} = await new UsersService(new ServiceConfig()).createPassword(new CreatePasswordDto({
                password,
                passwordCreationCode: setcode
            }));
            if(isSuccess){
                setRedirect(true);
                Notify.Success(Resources.Haslo_ustawione);
            }
            else{
                setStatus(getErrorMessage(error.code));
            }
        }
        catch{
            setStatus(getErrorMessage());
        }
        finally{
            setSubmitting(false);
        }
    }

    if(redirect){
        return <Redirect to="/login" />
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({values, touched, setFieldTouched, setFieldValue, errors, isSubmitting, handleSubmit, status}) => (
                <form
                    noValidate={true}
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    style={{alignSelf: 'stretch'}}
                >
                    <PCText semibold color={Colors.brownish_grey} textAlign="center" fontSize={18}>{Resources.Ustawianie_hasla}</PCText>
                    <BaseSpaceSeparator size={50} />
                    <RegularInput
                        label={Resources.Haslo}
                        name="password"
                        variant="big"
                        value={values.password}
                        error={errors.password}
                        touched={touched.password}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        password
                    />

                    <div style={{display: 'flex', justifyContent: 'center', position: 'relative'}}>
                        <PCText color={Colors.brownish_grey} textAlign="center" fontSize={14}>{Resources.Haslo_musi_zawierac_co_najmniej_7_znakow}</PCText>
                    </div>
                    <BaseSpaceSeparator size={20} />
                    <StandardButton variant="big" progress={isSubmitting} type="submit" tabIndex={4}>{Resources.Ustaw_haslo}</StandardButton>
                    <div style={{
                        height: 50,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: "flex-end",
                        alignItems: 'center'
                    }}>
                        <PCText fontSize={12} letterSpacing={0} color={Colors.red}>{status || ""}</PCText>
                        <BaseSpaceSeparator size={10}/>
                    </div>
                </form>
            )}
        </Formik>
    );
}