import { LinkStatus } from "parkcash-api";
import * as React from "react";
import Colors from "../styles/Colors";
import { PCText } from "../styles/Texts";
import Label from "../MUI/components/label";
import Resources from "../../Resources";

export default (props: {text: string, color: string, border?: string, textColor?: string, width?: number, height?: number, fontSize?: number, flexGrow?:string}) => {
    const {color, text, textColor = Colors.white, border = undefined, width, height = 18, fontSize = 12, flexGrow=0} = props;
   
    return (
        <div id={"statusPresenter"}
            style={{
                paddingLeft: 5, 
                paddingRight: 5, 
                borderRadius: 9,
                maxWidth: width ? undefined : 110,
                width, 
                display: 'flex',
                flexGrow:flexGrow,
                height, 
                justifyContent: 'center', 
                alignItems: 'center', 
                backgroundColor: color,
                border,
                columnGap:"20px"
                
            }}
        >
            <PCText color={textColor} lineHeight={1} letterSpacing={0} fontSize={fontSize}>{text}</PCText>
          
            
        </div>
    )
}
