import * as React from "react";
import Colors from "../../../../styles/Colors";
import UserDropdown from "../Common/UserDropdown";
import MobileNavigator from "./MobileNavigator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faBars } from '@fortawesome/free-solid-svg-icons';
import LanguageSelector from "../../../../components/LanguageSelector/LanguageSelector";

export const MOBILEHEADER_HEIGHT = 70;

export const MobileHeader = () => {
    const [navigatorVisible, setNavigatorVisible] = React.useState(false);

    return (
        <div style={{height: MOBILEHEADER_HEIGHT, backgroundColor: Colors.white, display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 10, paddingRight: 20}}>
            <MobileNavigator onClose={() => setNavigatorVisible(false)} visible={navigatorVisible} />
            <div style={{cursor: "pointer", padding: 10}} onClick={() => setNavigatorVisible(true)}>
                <FontAwesomeIcon color={Colors.light_royal_blue} size="lg" icon={faBars}  />
            </div>
            <LanguageSelector className={"language-selector language-selector-desktop"} updateServerCulture={true}/>
            <UserDropdown scrolled />
        </div>
    );
}