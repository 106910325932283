import { CreditCardStatus, DeactivateCreditCardDto, ErrorCode, ICreditCardBindingDto, PaymentsService, ServiceConfig } from "parkcash-api";
import Resources from "../../Resources";
import errorPayload from "../redux/ErrorPayload";
import simplePayload from "../redux/SimplePayload";
import Notify from "../utils/Notify";
import { TransactionType } from "./Enums";
import PaymentForm from "./PaymentForm";
import ThunkFunction from "../redux/ThunkFunction";

export type TransactionPaymentActionTypes = "GettingPaymentForms" | "GettingPaymentFormsSuccess" | 
"GettingPaymentFormsError" | "PaymentFormAdded" | "PaymentFormRemoved";

export interface gettingPaymentFormsSuccessPayload extends simplePayload {
    paymentForms: PaymentForm[]
}

export const getPaymentForms = (forceReload: boolean = false, after: (forms: PaymentForm[]) => void = () => {}): ThunkFunction => async (dispatch, getState) => {
    const {payments: {paymentForms}, user: {token: jwt}} = getState();
    if(paymentForms && !forceReload){
        after && after(paymentForms);
        return;
    }

    try{
        dispatch(<simplePayload>{
            type: "GettingPaymentForms"
        });
        const response = await new PaymentsService(new ServiceConfig({jwt})).getUserCreditCards();
        if(response.isSuccess){
            const paymentForms = response.result.filter(card => card.status === CreditCardStatus.Active).map<PaymentForm>(cardInfo => new PaymentForm({type: TransactionType.PayWithSavedCreditCard, creditCardInfo: cardInfo}));
            paymentForms.push(new PaymentForm({type: TransactionType.BlumediaGate}));

            dispatch(<gettingPaymentFormsSuccessPayload>{
                type: "GettingPaymentFormsSuccess",
                paymentForms
            });
            after && after(paymentForms);
        }
        else {
            dispatch(<errorPayload>{
                type: "GettingPaymentFormsError",
                errorCode: response.error.code
            });
        }
    }
    catch{
        dispatch(<errorPayload>{
            type: "GettingPaymentFormsError",
            errorCode: ErrorCode.NetworkError
        });
    }
    
}



export interface paymentFormPayload extends simplePayload{
    paymentForm: PaymentForm
}

export const addCreditCard = (card: ICreditCardBindingDto): paymentFormPayload => ({
    paymentForm: new PaymentForm({type: TransactionType.PayWithSavedCreditCard, creditCardInfo:card}),
    type: "PaymentFormAdded"
});

export const removePaymentForm = (paymentForm: PaymentForm): paymentFormPayload => ({
    paymentForm,
    type: "PaymentFormRemoved"
});

export interface paymentsState {
    paymentForms: PaymentForm[];
    gettingPaymnentFormsProgress: boolean;
    gettingPaymentFormsError: ErrorCode;
}

const initialState: paymentsState = {    
    paymentForms: null,    
    gettingPaymentFormsError: null,
    gettingPaymnentFormsProgress: false,
};

export default function(state: paymentsState = initialState, action: simplePayload): paymentsState {
    if(action.type === "Logout"){
        return {...initialState};
    }
    
    const type = action.type as TransactionPaymentActionTypes;
    switch(type){
           
        case "GettingPaymentForms":
            return {
                ...state,
                gettingPaymnentFormsProgress: true,
                paymentForms: null,
                gettingPaymentFormsError: null
            }
        case "GettingPaymentFormsSuccess":
            return {
                ...state,
                gettingPaymentFormsError: null,
                gettingPaymnentFormsProgress: false,
                paymentForms: (action as gettingPaymentFormsSuccessPayload).paymentForms
            }         
        case "GettingPaymentFormsError":
            return {
                ...state,
                gettingPaymentFormsError: (action as errorPayload).errorCode,
                gettingPaymnentFormsProgress: false,
                paymentForms: null
            }      
        case "PaymentFormAdded":
            return {
                ...state,
                paymentForms: [
                    ...(state.paymentForms || []),
                    (action as paymentFormPayload).paymentForm
                ]
            }    
        case "PaymentFormRemoved":
            const form = (action as paymentFormPayload).paymentForm;
            return {
                ...state,
                paymentForms: (state.paymentForms || []).filter(f => !form.equals(f))
            }
        default:
            return state;
    }
}