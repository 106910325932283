import React from 'react'
import {
    IReservationPolicyPredefinedRangePeriodEntry, ReservationPolicyPredefinedRangePeriodType
} from "parkcash-api";
import {mapEnumToSelectOptions} from "../../../utils/EnumUtils/MapEnumToSelectOptions";
import {
    mapReservationPolicyPredefinedRangePeriodTypeTodisplayText
} from "../../../utils/EnumUtils/ParkingConfigRelatedEnums/ReservationPolicyPredefinedRangePeriodTypeHelpers";
import RegularInput from "../../../components/forms/RegularInput";
import Resources from "../../../../Resources";
import RegularDropdownList from "../../../components/forms/RegularDropdownList";
import Colors from "../../../styles/Colors";

import {FormikErrors, FormikTouched} from "formik";
import {useBoolean} from "../../../MUI/hooks/use-boolean";
import ButtonBase from "@mui/material/ButtonBase";
import {StandardButton} from "../../../styles/Buttons";
import {PolicyFormValues} from "../ParkingPolicyForm/policyFormTypes";
import {ListContainer, SmallListAddButton, SmallListItem} from "../../../components/Lists";
import {PCText} from "../../../styles/Texts";
import Stack from "@mui/material/Stack";
import {faPlusCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BaseSpaceSeparator} from "../../../styles/Separators";

interface Props {
    values: PolicyFormValues
    errors: FormikErrors<PolicyFormValues>
    touched: FormikTouched<PolicyFormValues>
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;

}

export const ReservationPolicyPredefinedPeriodsSection = ({
                                                              values,
                                                              setFieldValue,
                                                              setFieldTouched,
                                                              errors,
                                                              touched,

                                                          }: Props) => {
    const periodsSelectOptions = mapEnumToSelectOptions(ReservationPolicyPredefinedRangePeriodType,
        mapReservationPolicyPredefinedRangePeriodTypeTodisplayText,
        false
    );
    const showForm = useBoolean(false);
    const predefinedPeriods: IReservationPolicyPredefinedRangePeriodEntry[] = values.reservationPolicyPredefinedRangePeriodEntries;
 

    return (<div>
       
        <Stack direction={"column"}  flexWrap={"wrap"}>
            <Stack direction={"row"}  width={"100%"} justifyContent={"space-between"}>
            <PCText color={Colors.black} fontSize={16} lineHeight={24/16}>{Resources.Predefiniowane_przedzialy_czasowe}</PCText>
            <FontAwesomeIcon icon={faPlusCircle} color={Colors.light_royal_blue} style={{ verticalAlign:'top',textAlign: 'center', fontSize: 20, letterSpacing: 0}} onClick={showForm.onToggle} title={Resources.Dodaj} />
            </Stack>
            <BaseSpaceSeparator size={20}/>
            {showForm.value ? (<>
                <RegularInput
                    variant={"standard"}
                    label={Resources.Wartosc}
                    value={values.reservationPolicyPredefinedRangePeriodValue}
                    error={errors.reservationPolicyPredefinedRangePeriodValue}
                    touched={touched.reservationPolicyPredefinedRangePeriodValue}
                    name={"reservationPolicyPredefinedRangePeriodValue"}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    showClearButton
                    showValidatedButton
                />

                <RegularDropdownList
                    variant={"standard"}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    error={errors.reservationPolicyPredefinedRangePeriodType}
                    touched={touched.reservationPolicyPredefinedRangePeriodType}
                    value={values.reservationPolicyPredefinedRangePeriodType.toString()}
                    name="reservationPolicyPredefinedRangePeriodType"
                    actions={periodsSelectOptions.map((option) => ({
                        id: option.value,
                        text: option.label
                    }))}
                    borderColor={Colors.brownish_grey}
                    label={Resources.Rodzaj}
                />
                <StandardButton type={"button"} variant={"tiny"} onClick={() => {
                    setFieldValue("reservationPolicyPredefinedRangePeriodEntries", [
                        ...values.reservationPolicyPredefinedRangePeriodEntries,
                        {
                            value: values.reservationPolicyPredefinedRangePeriodValue,
                            type: values.reservationPolicyPredefinedRangePeriodType
                        }
                    ]);
                    showForm.onFalse();
                }
                }>
                    {Resources.Dodaj}
                </StandardButton>
                <BaseSpaceSeparator size={20}/>
            </>) : null}
            {predefinedPeriods.map((period, index) => {
                return (
                    <SmallListItem
                        key={index}
                        upperText={String(period.value)}
                        bottomText={mapReservationPolicyPredefinedRangePeriodTypeTodisplayText(Number(period.type))}
                        onIconClick={() => {
                            predefinedPeriods.splice(index, 1);
                            setFieldValue("reservationPolicyPredefinedRangePeriodEntries", predefinedPeriods);
                        }}
                        iconTitle={Resources.Usun}
                        icon={faTrash}
                    />
                )
            })}
        </Stack>
       
    </div>)
}
    
    

    
    