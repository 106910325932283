export type SelectOption = {
    label: string; value: string;
};

export function mapEnumToSelectOptions<T>(enumType: T, mapValueToDisplayText: (value: T[keyof T]) => string, addAllOption=true, sort=true, addNoneOption=false): SelectOption[] {
    const allOption:SelectOption= {
        label: "Wszystkie",
        value: "all"
    }
    const noneOption:SelectOption= {
        label: "Brak",
        value: "none"
    }
    const selectOptions: SelectOption[] = [
    ];
    if (addAllOption) {
        selectOptions.push(allOption);
    }
    if (addNoneOption) {
        selectOptions.push(noneOption);
    }
    for (const key in enumType) {
        if (isNaN(Number(key))) {
            const enumValue = enumType[key as keyof typeof enumType];
            selectOptions.push({
                label: mapValueToDisplayText(enumValue),
                value: String(enumValue)
            });
        }
    }
    if (sort) {
        selectOptions.sort((a, b) => a.label.localeCompare(b.label));
    }
    return selectOptions;
}


