import {CustomizedTextfield} from "../MUI/components/AddedOrModyfiedMuiComponents/CustomizedInputs/CustomizedTextfield";
import Resources from "../../Resources";
import {BaseSpaceSeparator} from "../styles/Separators";
import {StandardButton} from "../styles/Buttons";
import * as React from "react";
import {useWindowSize} from "../utils/WindowSizeHook";
import {MOBILE_WIDTH_THRESHOLD} from "../utils/Constants";

interface Props {
    value: string;
    setValue: (value: string) => void;
    onSearchSubmitted: (value:string) => void;
    label?: string;
   
}

export const SearchInputWithButton = (props: Props) => {
    const { value, setValue, label=Resources.Wyszukaj,  onSearchSubmitted } = props;
    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    return (
        <div
            style={{
                display: "flex", width: "100%", flexDirection: isMobile ? "column" : "row", alignItems: "center",
            }}
        >
            <div
                style={{
                    flex: isMobile ? undefined : 1, width: isMobile ? "100%" : undefined,
                }}
            >
                <CustomizedTextfield value={value} onChange={(e) => setValue(e.target.value)}
                                     label={label}
                                     fullWidth
                                     onKeyDown={(e) => {
                                         if (e.key === "Enter") {
                                            onSearchSubmitted(value);
                                         }
                                     }}
                />
            </div>
            <BaseSpaceSeparator size={10}/>
            <div style={{width: isMobile ? "100%" : 200}}>
                <StandardButton variant="big" onClick={()=>onSearchSubmitted(value)}>
                    {Resources.Wyszukaj}
                </StandardButton>
            </div>
        </div>
    );
}