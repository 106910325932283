import { AvailibilityStatus, ErrorCode } from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { MOBILE_WIDTH_THRESHOLD } from "../../utils/Constants";
import { getErrorMessage } from "../../utils/ErrorUtils";
import { useWindowSize } from "../../utils/WindowSizeHook";
import DropdownList from "../DropdownList";
import ErrorView from "../ErrorView";
import Spinner from "../Spinner";
import {
  getSpotInfo,
  ParkingMapDataResponseResult,
  ParkingMapSpotInfo,
} from "./Data";
import "./ParkingMap.scss";
import ParkingSpotsMetadata, {
  IParkingSpotsMetadata,
  NullParkingSpotsMetadata,
} from "./ParkingSpotsMetadata";
import Resizer from "./Resizer";
import {
  getAspectRatioFromViewBox,
  getTransformMatrix,
  getWidthAndHeightFromViewBox,
} from "./SVGUtils";
import $ from "jquery";
import { StandardButton } from "../../styles/Buttons";

const DATA_SPOT_ATTR = "data-spot";
const DATA_SECTOR_ATTR = "data-sector";
const DATA_LEVEL_ATTR = "data-level";

const pc_parkingmap_spot_free = "pc-parkingmap-spot-free";
const pc_parkingmap_spot_takennow = "pc-parkingmap-spot-takennow";
const pc_parkingmap_spot_takeninthefuture =
  "pc-parkingmap-spot-takeninthefuture";
const pc_parkingmap_spot_paymentdaleyed = "pc-parkingmap-spot-paymentdaleyed";
const pc_parkingmap_spot_paymentdaleyedmorethan30days =
  "pc-parkingmap-spot-paymentdaleyedmorethan30days";
const pc_parkingmap_spot_paymentdaleyedmorethan60days =
  "pc-parkingmap-spot-paymentdaleyedmorethan60days";
const pc_parkingmap_spot_takennowandforfutureperiods =
  "pc-parkingmap-spot-takennowandforfutureperiods";

const pc_parkingmap_spot_selected = "pc-parkingmap-spot-selected";

const PC_PARKINGMAP_PARKINGSECTORS = "pc-parkingmap-parkingsectors";
const PC_PARKINGMAP_LEVELSECTORS = "pc-parkingmap-levelsectors";

const PC_PARKINGMAP_SECTOR = "pc-parkingmap-sector";

const PC_PARKINMAP_LEVEL = "pc-parkingmap-level";

const PC_PARKING_PARKINGLEVELS = "pc-parkingmap-parkinglevels";

const ZOOM_G = "zoom-g";

const MAX_ZOOM = 5;

const MIN_ZOOM = -5;

const HEADER_HEIGHT = 60;
const HEADER_MARGIN_BOTTOM = 10;

export default (props: {
  refreshKey?: string;
  height?: number | string;
  width?: number | string;
  getSpotsCallback: (
    level: string,
    sector: string
  ) => Promise<{ error?: ErrorCode; spots?: ParkingMapSpotInfo[] }>;
  spotId: string;
  onSpotSelected: (spotId: string, status: AvailibilityStatus) => void;
  mapResult: ParkingMapDataResponseResult;
}) => {
  const {
    getSpotsCallback,
    height = "100%",
    mapResult,
    onSpotSelected,
    width = "100%",
    spotId,
    refreshKey,
  } = props;
  const [selectionMode, setSelectionMode] =
    React.useState<"spot" | "sector" | "level">(null);
  const currentDOMFragment = React.useRef<JQuery<HTMLElement>>();
  const [currentZoomX, setCurrentZoomX] = React.useState(0);
  const [currentZoomY, setCurrentZoomY] = React.useState(0);
  const [currentZoom, setCurrentZoom] = React.useState(0);
  const [currentDomFragmentViewBox, setCurrentDomFragmentViewBox] =
    React.useState<string>(null);
  const container = React.useRef<HTMLDivElement>();
  const wrapper = React.useRef<HTMLDivElement>();
  const [wrapperWidth, setWrapperWidth] = React.useState<number>(0);
  const [wrapperHeight, setWrapperHeight] = React.useState<number>(0);
  const { windowWidth, windowHeight } = useWindowSize();
  const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
  const [progress, setProgress] = React.useState(false);
  const [error, setError] = React.useState<ErrorCode>(null);
  const [parkingSpotsMetadata, setParkingSpotsMetadata] =
    React.useState<IParkingSpotsMetadata>(new NullParkingSpotsMetadata());
  const parkingSpotsMetadataRef = React.useRef<IParkingSpotsMetadata>(
    new NullParkingSpotsMetadata()
  );
  const [availableLevels, setAvailableLevels] = React.useState<
    { text: string; id: string }[]
  >([]);
  const [availableSectors, setAvailableSectors] = React.useState<
    { text: string; id: string }[]
  >([]);
  const [currentViewBox, setCurrentViewBox] = React.useState<string>(null);
  const [currentLevel, setCurrentLevel] = React.useState<string>(null);
  const [currentSector, setCurrentSector] = React.useState<string>(null);

  const showLevelDropdown =
    (!mapResult.isLevelsAsSupersectors && mapResult.hasManyLevels) ||
    (mapResult.isLevelsAsSupersectors && selectionMode === "spot");
  const showSectorDropdown =
    mapResult.hasManySectors &&
    (mapResult.spotUniquenessType || "number") === "number" &&
    selectionMode === "spot";
  const showBackToMap =
    mapResult.isLevelsAsSupersectors && selectionMode === "spot";

  const setCurrentDOMFragment = (e: JQuery<HTMLElement>) => {
    currentDOMFragment.current = e;
    e.find(`.${ZOOM_G}`).attr("transform", "matrix(1 0 0 1 0 0)");
    const viewBox =
      currentDOMFragment.current.find("svg").attr("viewBox") ||
      currentDOMFragment.current.attr("viewBox");
    const { height, width } = getWidthAndHeightFromViewBox(viewBox);
    setCurrentZoomX(width / 2);
    setCurrentZoomY(height / 2);
    setCurrentZoom(0);
    setCurrentDomFragmentViewBox(viewBox);
  };

  const onBackToMap = () => {
    setCurrentLevel(null);
    setCurrentSector(null);
    setSelectionMode("level");
  };

  const onSectorChanged = (sector: string) => {
    setCurrentSector(sector);
    setSelectionMode("spot");
    onSpotSelected(null, null);
  };

  const onLevelChanged = (level: string) => {
    setCurrentLevel(level);
    onSpotSelected(null, null);
    if (mapResult.hasManySectors) {
      setCurrentSector(null);
      if (mapResult.isLevelsAsSupersectors) {
        setSelectionMode("spot");
      } else {
        setAvailableSectors(
          mapResult.sectorsInLevels[level].map((s) => ({ id: s, text: s }))
        );
        setSelectionMode("sector");
      }
    } else {
      setSelectionMode("spot");
    }
  };

  const onSpotChanged = (number: string, sector: string, level: string) => {
    const info = parkingSpotsMetadataRef.current.getSpotInfo(
      number,
      sector,
      level
    );
    onSpotSelected(info?.id, info?.status);
  };

  const onSpotClicked = React.useCallback((e) => {
    const $target = $(e.currentTarget);
    const number = $target.attr(DATA_SPOT_ATTR);
    const sector =
      mapResult.spotUniquenessType === "numberAndSector"
        ? $target.attr(DATA_SECTOR_ATTR)
        : mapResult.hasManySectors
        ? $target.closest(`[${DATA_SECTOR_ATTR}]`).attr(DATA_SECTOR_ATTR)
        : null;
    const level = mapResult.hasManyLevels
      ? $target.closest(`[${DATA_LEVEL_ATTR}]`).attr(DATA_LEVEL_ATTR)
      : null;
    onSpotChanged(number, sector, level);
  }, []);

  const onSectorClicked = React.useCallback((e) => {
    const $target = $(e.currentTarget);
    const sector = $target.attr(DATA_SECTOR_ATTR);
    onSectorChanged(sector);
  }, []);

  const onLevelClicked = React.useCallback((e) => {
    const $target = $(e.currentTarget);
    const level = $target.attr(DATA_LEVEL_ATTR);
    onLevelChanged(level);
  }, []);

  const init = async () => {
    setProgress(true);
    const defaultSpot = {
      id: null,
      number: null,
      level: mapResult.isLevelsAsSupersectors
        ? null
        : mapResult.hasManyLevels
        ? mapResult.levels[0]
        : null,
      sector: mapResult.isLevelsAsSupersectors
        ? null
        : mapResult.hasManyLevels && mapResult.hasManySectors
        ? mapResult.sectorsInLevels[mapResult.levels[0]][0]
        : !mapResult.hasManyLevels && mapResult.hasManySectors
        ? mapResult.sectors[0]
        : null,
    };
    const { error, spot } = spotId
      ? await getSpotInfo(spotId)
      : { error: null, spot: defaultSpot };
    if (error) {
      setError(error);
    } else {
      if (mapResult.hasManyLevels && mapResult.hasManySectors) {
        setAvailableLevels(mapResult.levels.map((l) => ({ id: l, text: l })));
        setCurrentLevel(spot.level);
        setCurrentSector(spot.sector);

        if (mapResult.isLevelsAsSupersectors) {
          setSelectionMode(spotId ? "spot" : "level");
        } else {
          setAvailableSectors(
            mapResult.sectorsInLevels[spot.level].map((s) => ({
              id: s,
              text: s,
            }))
          );
          setSelectionMode(spotId ? "spot" : "sector");
        }
      } else if (!mapResult.hasManyLevels && mapResult.hasManySectors) {
        setAvailableSectors(mapResult.sectors.map((l) => ({ id: l, text: l })));
        setCurrentSector(spot.sector);
        setSelectionMode(spotId ? "spot" : "sector");
      } else if (mapResult.hasManyLevels && !mapResult.hasManySectors) {
        setAvailableLevels(mapResult.levels.map((l) => ({ id: l, text: l })));
        setCurrentLevel(spot.level);
        setSelectionMode("spot");
      } else {
        setSelectionMode("spot");
      }
    }
    setProgress(false);
  };

  React.useEffect(() => {
    const $container = $(container.current);
    $container.html(mapResult.map);
    init();

    return () => {
      $(`.${pc_parkingmap_spot_free}`).off("click");
      $(`.${pc_parkingmap_spot_paymentdaleyed}`).off("click");
      $(`.${pc_parkingmap_spot_paymentdaleyedmorethan30days}`).off("click");
      $(`.${pc_parkingmap_spot_paymentdaleyedmorethan60days}`).off("click");
      $(`.${pc_parkingmap_spot_selected}`).off("click");
      $(`.${pc_parkingmap_spot_takeninthefuture}`).off("click");
      $(`.${pc_parkingmap_spot_takennow}`).off("click");
      $(`.${pc_parkingmap_spot_takennowandforfutureperiods}`).off("click");
      $(`.${PC_PARKINGMAP_SECTOR}`).off("click");
    };
  }, []);

  React.useEffect(() => {
    if (currentViewBox) {
      const $container = $(container.current);
      $container.children("svg").attr("viewBox", currentViewBox);
    }
  }, [currentViewBox]);

  React.useEffect(() => {
    const updateParkingSpotsMetadata = async () => {
      setProgress(true);
      const { error, spots } = await getSpotsCallback(
        currentLevel,
        mapResult.isLevelsAsSupersectors ? null : currentSector
      );
      if (error) {
        setError(error);
      } else {
        const newMetadata = new ParkingSpotsMetadata(
          spots,
          mapResult.hasManyLevels,
          mapResult.hasManySectors,
          mapResult.spotUniquenessType,
          mapResult.isLevelsAsSupersectors
        );
        setParkingSpotsMetadata(newMetadata);
        parkingSpotsMetadataRef.current = newMetadata;
      }
      setProgress(false);
    };

    if (
      mapResult.isLevelsAsSupersectors &&
      mapResult.hasManyLevels &&
      mapResult.hasManySectors &&
      selectionMode === "level"
    ) {
      clearDOM();
      setCurrentViewBox(mapResult.levelsViewBox);

      setCurrentDOMFragment(
        $(container.current).find(`.${PC_PARKING_PARKINGLEVELS}`)
      );
      const newMetadata = new NullParkingSpotsMetadata();
      setParkingSpotsMetadata(newMetadata);
      parkingSpotsMetadataRef.current = newMetadata;
    } else if (
      mapResult.isLevelsAsSupersectors &&
      mapResult.hasManyLevels &&
      mapResult.hasManySectors &&
      selectionMode === "spot"
    ) {
      clearDOM();
      setCurrentViewBox(mapResult.spotsInLevelsViewBoxes[currentLevel]);
      const selector = `[${DATA_LEVEL_ATTR}=${currentLevel}]:not(.${PC_PARKINMAP_LEVEL})`;
      setCurrentDOMFragment($(container.current).find(selector));
      updateParkingSpotsMetadata();
    } else if (
      !mapResult.isLevelsAsSupersectors &&
      mapResult.hasManyLevels &&
      mapResult.hasManySectors &&
      selectionMode === "sector"
    ) {
      clearDOM();
      setCurrentViewBox(mapResult.sectorsInLevelsViewBoxes[currentLevel]);
      setCurrentDOMFragment(
        $(container.current).find(
          `[${DATA_LEVEL_ATTR}=${currentLevel}].${PC_PARKINGMAP_LEVELSECTORS}`
        )
      );
      const newMetadata = new NullParkingSpotsMetadata();
      setParkingSpotsMetadata(newMetadata);
      parkingSpotsMetadataRef.current = newMetadata;
    } else if (
      !mapResult.isLevelsAsSupersectors &&
      mapResult.hasManyLevels &&
      mapResult.hasManySectors &&
      selectionMode === "spot"
    ) {
      clearDOM();
      setCurrentViewBox(
        mapResult.spotsInSectorsOfLevelsViewBoxes[currentLevel][currentSector]
      );
      setCurrentDOMFragment(
        $(container.current).find(
          `[${DATA_LEVEL_ATTR}=${currentLevel}][${DATA_SECTOR_ATTR}=${currentSector}]`
        )
      );
      updateParkingSpotsMetadata();
    } else if (
      !mapResult.hasManyLevels &&
      mapResult.hasManySectors &&
      selectionMode === "sector"
    ) {
      clearDOM();
      setCurrentViewBox(mapResult.sectorsViewBox);
      setCurrentDOMFragment(
        $(container.current).find(`.${PC_PARKINGMAP_PARKINGSECTORS}`)
      );
      const newMetadata = new NullParkingSpotsMetadata();
      setParkingSpotsMetadata(newMetadata);
      parkingSpotsMetadataRef.current = newMetadata;
    } else if (
      !mapResult.hasManyLevels &&
      mapResult.hasManySectors &&
      selectionMode === "spot"
    ) {
      clearDOM();
      setCurrentViewBox(mapResult.spotsInSectorsViewBoxes[currentSector]);
      const selector = `[${DATA_SECTOR_ATTR}=${currentSector}]:not(.${PC_PARKINGMAP_SECTOR})`;
      setCurrentDOMFragment($(container.current).find(selector));
      updateParkingSpotsMetadata();
    } else if (
      mapResult.hasManyLevels &&
      !mapResult.hasManySectors &&
      !!selectionMode
    ) {
      clearDOM();
      setCurrentViewBox(mapResult.spotsInLevelsViewBoxes[currentLevel]);
      setCurrentDOMFragment(
        $(container.current).find(`[${DATA_LEVEL_ATTR}=${currentLevel}]`)
      );
      updateParkingSpotsMetadata();
    } else if (
      !mapResult.hasManyLevels &&
      !mapResult.hasManySectors &&
      !!selectionMode
    ) {
      setCurrentViewBox(mapResult.globalViewBox);
      setCurrentDOMFragment($(container.current).children("svg"));
      updateParkingSpotsMetadata();
    }
  }, [currentLevel, currentSector, selectionMode, refreshKey]);

  const getSpotSelector = (spot: ParkingMapSpotInfo) => {
    const { spotUniquenessType = "number" } = mapResult;
    if (spotUniquenessType === "numberAndSector") {
      return `[${DATA_SPOT_ATTR}=${spot.number}][${DATA_SECTOR_ATTR}=${spot.sector}]`;
    } else {
      return `[${DATA_SPOT_ATTR}=${spot.number}]`;
    }
  };

  const getSpotStatusClass = (s: AvailibilityStatus) => {
    switch (s) {
      case AvailibilityStatus.Free:
        return pc_parkingmap_spot_free;
      case AvailibilityStatus.PaymentDaleyed:
        return pc_parkingmap_spot_paymentdaleyed;
      case AvailibilityStatus.PaymentDaleyedMoreThan30Days:
        return pc_parkingmap_spot_paymentdaleyedmorethan30days;
      case AvailibilityStatus.PaymentDaleyedMoreThan60Days:
        return pc_parkingmap_spot_paymentdaleyedmorethan60days;
      case AvailibilityStatus.TakenInTheFuture:
        return pc_parkingmap_spot_takeninthefuture;
      case AvailibilityStatus.TakenNow:
        return pc_parkingmap_spot_takennow;
      case AvailibilityStatus.TakenNowAndForFuturePeriods:
        return pc_parkingmap_spot_takennowandforfutureperiods;
    }
  };

  React.useEffect(() => {
    if (currentDOMFragment.current) {
      //todo JS
      const s = parkingSpotsMetadata.getAllAvailableSpots();
      s.forEach((spot) => {
        currentDOMFragment.current
          .find(getSpotSelector(spot))
          .addClass(getSpotStatusClass(spot.status));
      });

      $(`.${pc_parkingmap_spot_free}`).off("click");
      $(`.${pc_parkingmap_spot_paymentdaleyed}`).off("click");
      $(`.${pc_parkingmap_spot_paymentdaleyedmorethan30days}`).off("click");
      $(`.${pc_parkingmap_spot_paymentdaleyedmorethan60days}`).off("click");
      $(`.${pc_parkingmap_spot_selected}`).off("click");
      $(`.${pc_parkingmap_spot_takeninthefuture}`).off("click");
      $(`.${pc_parkingmap_spot_takennow}`).off("click");
      $(`.${pc_parkingmap_spot_takennowandforfutureperiods}`).off("click");
      $(`.${PC_PARKINGMAP_SECTOR}`).off("click");

      $(`.${pc_parkingmap_spot_free}`).on("click", onSpotClicked);
      $(`.${pc_parkingmap_spot_paymentdaleyed}`).on("click", onSpotClicked);
      $(`.${pc_parkingmap_spot_paymentdaleyedmorethan30days}`).on(
        "click",
        onSpotClicked
      );
      $(`.${pc_parkingmap_spot_paymentdaleyedmorethan60days}`).on(
        "click",
        onSpotClicked
      );
      $(`.${pc_parkingmap_spot_takeninthefuture}`).on("click", onSpotClicked);
      $(`.${pc_parkingmap_spot_takennow}`).on("click", onSpotClicked);
      $(`.${pc_parkingmap_spot_takennowandforfutureperiods}`).on(
        "click",
        onSpotClicked
      );
      $(`.${PC_PARKINGMAP_SECTOR}`).on("click", onSectorClicked);
      $(`.${PC_PARKINMAP_LEVEL}`).on("click", onLevelClicked);
    }
  }, [parkingSpotsMetadata]);

  React.useEffect(() => {
    if (currentDOMFragment.current && spotId) {
      currentDOMFragment.current
        .find(`.${pc_parkingmap_spot_selected}`)
        .removeClass(pc_parkingmap_spot_selected);
      const spot = parkingSpotsMetadata.getSpotInfoById(spotId);
      if (spot) {
        currentDOMFragment.current
          .find(getSpotSelector(spot))
          .addClass(pc_parkingmap_spot_selected);
      }
    } else if (currentDOMFragment.current && !spotId) {
      currentDOMFragment.current
        .find(`.${pc_parkingmap_spot_selected}`)
        .removeClass(pc_parkingmap_spot_selected);
    }
  }, [spotId, parkingSpotsMetadata]);

  const clearDOM = () => {
    const $container = $(container.current);
    $container
      .find(`.${pc_parkingmap_spot_selected}`)
      .removeClass(pc_parkingmap_spot_selected);
    $container
      .find(`.${pc_parkingmap_spot_free}`)
      .removeClass(pc_parkingmap_spot_free);
    $container
      .find(`.${pc_parkingmap_spot_paymentdaleyed}`)
      .removeClass(pc_parkingmap_spot_paymentdaleyed);
    $container
      .find(`.${pc_parkingmap_spot_paymentdaleyedmorethan30days}`)
      .removeClass(pc_parkingmap_spot_paymentdaleyedmorethan30days);
    $container
      .find(`.${pc_parkingmap_spot_paymentdaleyedmorethan60days}`)
      .removeClass(pc_parkingmap_spot_paymentdaleyedmorethan60days);
    $container
      .find(`.${pc_parkingmap_spot_takeninthefuture}`)
      .removeClass(pc_parkingmap_spot_takeninthefuture);
    $container
      .find(`.${pc_parkingmap_spot_takennow}`)
      .removeClass(pc_parkingmap_spot_takennow);
    $container
      .find(`.${pc_parkingmap_spot_takennowandforfutureperiods}`)
      .removeClass(pc_parkingmap_spot_takennowandforfutureperiods);
  };

  React.useEffect(() => {
    if (currentDOMFragment.current) {
      const matrix = getTransformMatrix(
        currentZoom,
        currentZoomX,
        currentZoomY,
        currentDomFragmentViewBox
      );
      currentDOMFragment.current
        .find(`.${ZOOM_G}`)
        .attr("transform", `matrix(${matrix.join(" ")})`);
    }
  }, [currentZoom, currentZoomX, currentZoomY, currentDomFragmentViewBox]);

/*  React.useEffect(() => {
    //hack
    $("#root .pc-main-wrapper").css("overflow", "hidden");
    return () => {
      $("#root .pc-main-wrapper").css("overflow", "");
    };
  }, []);*/

  React.useEffect(() => {
    const resizeObserve = new ResizeObserver(() => {
      if(wrapper.current){
        setWrapperHeight(wrapper.current.clientHeight);
        setWrapperWidth(wrapper.current.clientWidth);
      }

    });
    resizeObserve.observe(wrapper.current);
    return () => {
      if(wrapper.current){
        resizeObserve.unobserve(wrapper.current);
        resizeObserve.disconnect();
      }

    };
  }, []);

  React.useEffect(() => {
    const aspectRatio = getAspectRatioFromViewBox(currentViewBox);
    const availableWidth = wrapperWidth;
    const availableHeight =
      wrapperHeight - HEADER_HEIGHT - HEADER_MARGIN_BOTTOM;
    const { containerWidth, containerHeight } = getDimensions(
      availableWidth,
      aspectRatio,
      availableHeight,
      mapResult.resizeMode || "auto"
    );
    const $container = $(container.current);

    if (containerWidth > 0) {
      $container.find("svg").first().attr("width", containerWidth);
    }
    if (containerHeight > 0) {
      $container.find("svg").first().attr("height", containerHeight);
    }
  }, [wrapperWidth, wrapperHeight, currentViewBox]);

  React.useEffect(() => {
    if (mapResult.customClass) {
      const $container = $(container.current);
      $container.find("svg").first().addClass(mapResult.customClass);
    }
  }, []);

  if (error) {
    return <ErrorView title={getErrorMessage(error)} />;
  }

  return (
    <div
      ref={wrapper}
      style={{
        height: mapResult.resizeMode === "max-width" ? undefined : height,
       /* width,*/
        display: "flex",
        flexDirection: "column",
        zIndex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          marginBottom: HEADER_MARGIN_BOTTOM,
          height: HEADER_HEIGHT,
          alignItems: "center",
        }}
      >
        <Resizer
          height={HEADER_HEIGHT}
          width={100}
          triangleSize={15}
          buttonSize={20}
          onDown={() => setCurrentZoomY(currentZoomY - 50)}
          onLeft={() => setCurrentZoomX(currentZoomX + 50)}
          onRight={() => setCurrentZoomX(currentZoomX - 50)}
          onUp={() => setCurrentZoomY(currentZoomY + 50)}
          onZoomIn={() => setCurrentZoom(Math.min(MAX_ZOOM, currentZoom + 1))}
          onZoomOut={() => setCurrentZoom(Math.max(MIN_ZOOM, currentZoom - 1))}
        />
        <div style={{ flex: 1, minWidth: 20, minHeight: 20 }} />
        {showBackToMap && (
          <div style={{ width: isMobile ? "100%" : 150, marginRight: 20 }}>
            <StandardButton onClick={onBackToMap}>Mapa poziomów</StandardButton>
          </div>
        )}
        {showSectorDropdown && (
          <div style={{ width: isMobile ? "100%" : 150 }}>
            <DropdownList
              value={currentSector}
              onChange={onSectorChanged}
              actions={availableSectors}
              label={Resources.Sektor}
              zIndex={200}
            />
          </div>
        )}
        {showLevelDropdown && showSectorDropdown && (
          <BaseSpaceSeparator size={20} />
        )}
        {showLevelDropdown && (
          <div style={{ width: isMobile ? "100%" : 150 }}>
            <DropdownList
              value={currentLevel}
              onChange={onLevelChanged}
              actions={availableLevels}
              label={Resources.Poziom}
              zIndex={100}
            />
          </div>
        )}
      </div>
      <div
        style={{
          width: "100%",
          flex: mapResult.resizeMode === "max-width" ? undefined : 1,
          overflow: "auto",
          position: "relative",
          zIndex: 1,
        }}
      >
        {progress && (
          <div
            style={{
              position: "absolute",
              zIndex: 2,
              backgroundColor: Colors.darkOverlay,
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner size="medium" />
          </div>
        )}
        <div
          ref={container}
          style={{
            zIndex: 1,
            position: "relative",
            minHeight: "100%",
            minWidth: "100%",
          }}
        />
      </div>
    </div>
  );
};

function getDimensions(
  availableWidth: number,
  aspectRatio: number,
  availableHeight: number,
  resizeMode: "auto" | "max-width" | "max-height"
): { containerWidth: number; containerHeight: number } {
  if (resizeMode === "auto") {
    const containerWidth =
      availableWidth * aspectRatio > availableHeight
        ? availableHeight / aspectRatio
        : availableWidth;
    const containerHeight = containerWidth * aspectRatio;
    return { containerWidth, containerHeight };
  } else if (resizeMode === "max-width") {
    return {
      containerHeight: (availableWidth - 20) * aspectRatio,
      containerWidth: availableWidth - 20,
    };
  } else if (resizeMode === "max-height") {
    return {
      containerHeight: availableHeight,
      containerWidth: availableHeight / aspectRatio,
    };
  }
}
