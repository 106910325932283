import {
    AssignAuthorizedDomainsToParkingDto,
    IGetParkingAuthorizedEmailDomainsResult,
    ReservationPolicyType,
    SpotSelectionType,
    StandardResponseWrapperOfGetParkingAuthorizedEmailDomainsResultAndErrorWrapperOfObject
} from "parkcash-api";
import ModalForm from "../../../components/ModalForm";
import Resources from "../../../../Resources";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import {FormikHelpers} from "formik";
import {useUser} from "../../../user/UseUserHook";
import {mapEnumToSelectOptions} from "../../../utils/EnumUtils/MapEnumToSelectOptions";
import {
    mapSpotSelectionTypeToDisplayText
} from "../../../utils/EnumUtils/ParkingConfigRelatedEnums/SpotSelectionTypeHelpers";
import {
    MapPolicyTypeEnumToLocalizedText,
} from "../../../utils/EnumUtils/ParkingConfigRelatedEnums/ReservationPolicyTypeHelpers";
import {IAssignAuthorizedDomainsToParkingDto, ParkingManagerService, ServiceConfig} from "parkcash-api";
import {
    ReservationPolicyPredefinedPeriodsSection
} from "../SpecialFormSections/ReservationPolicyPredefinedPeriodsSection";
import {
    useCustomizedNotification
} from "../../../MUI/components/AddedOrModyfiedMuiComponents/CustomizedNotification/useCustomizedNotification";

import {useFetch} from "../../../MUI/hooks/useFetch";
import {LoadingErrorAndContentWrapper} from "../../../components/Common/LoadingErrorAndContentWrapper";
import {mapDomainFormValuesToCreateDto, mapGetDomainsDtoToFormValues} from "./parkingDomenFormUtils";
import {ParkingDomainFormValues} from "./ParkingDomenFormTypes";
import {ParkingDomainListFormSection} from "./ParkingDomainListFormSection";

interface Props {
    visible: boolean;
    onClose: () => void;
    parkingId: string;
    parkingFriendlyName: string;
    onSubmitted?: () => void;
}

export const ParkingDomeinsForm = ({
                                     visible,
                                     onClose,
                                     parkingId,
                                     parkingFriendlyName,
                                     onSubmitted

                                 }: Props) => {
    const {
        applicationMode,
        token: jwt
    } = useUser();
    const {
        enqueError,
        enqueSuccess
    } = useCustomizedNotification();
    const fetchFunction = useCallback(async (): Promise<StandardResponseWrapperOfGetParkingAuthorizedEmailDomainsResultAndErrorWrapperOfObject> | null => {
        return await new ParkingManagerService(new ServiceConfig({jwt})).getParkingAuthorizedEmailDomains(parkingId);
    }, [
        jwt,
        parkingId
    ])
    const {
        data: initialFormDataSource,
        loading,
        error,
        fetch
    } = useFetch<IGetParkingAuthorizedEmailDomainsResult>(fetchFunction,  (data) => {
        setinitialFormValues(mapGetDomainsDtoToFormValues(data))

    });
    useEffect(() => {
        if (visible && parkingId) {
            fetch();
        }
    }, [visible, parkingId]);
    const [initialFormValues, setinitialFormValues] = useState(mapGetDomainsDtoToFormValues(initialFormDataSource));
    const formTitle = Resources.Edytuj_Autoryzowane_domeny;
    const [testKey, setTestKey] = useState(0);

    return (<ModalForm
        initialValues={initialFormValues}
        iconWidth={90}
        width={"500px"}
        /*   paddingLeft={15}
           paddingRight={15}*/
        iconHeight={110}
        visible={visible}
        onClose={onClose}
        title={formTitle}
        headerTitle={parkingFriendlyName}
        enableReinitialize={true}
        onSubmit={async (values: ParkingDomainFormValues,   actions: FormikHelpers<ParkingDomainFormValues>) => {
            console.log("formikValues", values);
            {
                const iDto: IAssignAuthorizedDomainsToParkingDto = mapDomainFormValuesToCreateDto(values, parkingId);
                const dto: AssignAuthorizedDomainsToParkingDto = new AssignAuthorizedDomainsToParkingDto(iDto);
                try {
                    const result = await new ParkingManagerService(new ServiceConfig({jwt})).assignAuthorizedDomainsToParking(
                        dto);

                    if (!result.isSuccess) {
                        throw new Error("An Errorr during saving occured")
                    }
                    enqueSuccess(Resources.Dane_zostaly_pomyslnie_zapisane);
                    onClose();
                      if (onSubmitted) {
                          onSubmitted();
                      }
                } catch (e) {
                    onClose();
                    enqueError(Resources.Wystapil_blad_podczas_zapisu_danych)
                }
            }
        }}
    >
        {({
              setFieldTouched,
              setFieldValue,
              values,
              errors,
              touched
          }) => 
            <LoadingErrorAndContentWrapper loading={loading} errors={[error]} data={!!initialFormDataSource}>
                <ParkingDomainListFormSection
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    errors={errors}
                    touched={touched}
                />
              
            </LoadingErrorAndContentWrapper>
        }
    </ModalForm>);

}