import { Formik, FormikHelpers } from "formik";
import memoizee from "memoizee";
import { AgreementsService, CreateUserWithNativeCredentialsDto, CreateUserWithThirdPartyCredentialsDto, ErrorCode, IGetUserAgreementDto, LoginType, ServiceConfig, UsersService } from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import * as yup from "yup";
import Resources from "../../Resources";
import RegularInput from "../components/forms/RegularInput";
import Spinner from "../components/Spinner";
import { applicationState } from "../redux/ApplicationState";
import { StandardButton, TextButton } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { initUser } from "../user/Redux";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../utils/Constants";
import { getErrorMessage } from "../utils/ErrorUtils";
import Notify from "../utils/Notify";
import { getNameAndSurname } from "../utils/UserInfoUtils";
import { loginNative, loginToThirdParty } from "./Utils";
import {Asserts} from "yup";

const checkEmail = memoizee(async function (email: string) {
    if (email !== undefined) {
        try{
            const response = await new UsersService(new ServiceConfig()).checkLoginAvailibility(email);
            return response.result.isFree;
        }
        catch{
            return true;
        }
    }
    return false;
});

const validationSchema = yup.object({
    nameAndSurname: yup.string().when("loginType", {
        is: LoginType.Native,
        then: yup.string().required(Resources.Wymagane),
    }),
    email: yup.string().when("loginType", {
        is: LoginType.Native,
        then: yup.string()
                 .required(Resources.Wymagane)
                 .matches(EMAIL_REGEX, Resources.Nieprawidlowy_format)
                 .test('unique-email', Resources.Uzytkownik_z_podanym_emailem_juz_istnieje, checkEmail),
    }),
    password: yup.string().when("loginType", {
        is: LoginType.Native,
        then: yup.string()
                 .required(Resources.Wymagane)
                 .matches(PASSWORD_REGEX, Resources.Haslo_musi_zawierac_co_najmniej_7_znakow)
    }),
    loginType:yup.mixed<LoginType>().default(undefined),
    accpetedIds:yup.array().default(undefined),
});

interface FormState extends Asserts<typeof validationSchema> {}

interface Props {
    name: string,
    surname: string,
    email: string,
    loginType: LoginType,
    oauthToken: string,
    afterLogin: (jwt: string, userId: string, success: () => void, fail: () => void) => void;
}

const Container = (props: Props) => {
    const {email, name, loginType, surname, oauthToken, afterLogin} = props;
    const {invitationGuid} = useParams<{invitationGuid: string}>();

    const [agreements, setAgreements] = React.useState<IGetUserAgreementDto[]>([]);
    const [agreementsProgress, setAgreementsProgress] = React.useState(true);
    const [agreementsError, setAgreementsError] = React.useState<ErrorCode>(null);
    const [redirect, setRedirect] = React.useState(false);
    const [redirectToChangeLaguage, setRedirectToChangeLaguage] = React.useState(false);

    React.useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            if (e.altKey && e.ctrlKey && e.key === "p") {
                setRedirectToChangeLaguage(true);
            }
        }
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        }
    }, []);

    React.useEffect(() => {
        const getAgreements = async () => {
            try{
            const response = await new AgreementsService(new ServiceConfig()).getUserAgreements();
            if(response.isSuccess){
                const ag = response.result.agreements;
                setAgreements(ag);
            }
            else{
                setAgreementsError(response.error.code);
            }
            }
            catch{
                setAgreementsError(ErrorCode.NetworkError);
            }
            finally{
                setAgreementsProgress(false);
            }
            
        };
        getAgreements();
    }, []);

    const initialValues: FormState = {
        nameAndSurname: (!name || !surname) ? "" : `${name} ${surname}`,
        password: "",
        email,
        accpetedIds: [],
        loginType
    }

    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {
        const {accpetedIds, email: targetEmail, loginType, nameAndSurname, password} = state;
        const {name: targetName, surname: targetSurname} = getNameAndSurname(nameAndSurname);
        const {setSubmitting, setStatus} = helpers;
        const mandatoryAgreements = agreements.filter(a => a.isMandatory);
        for(let mandatoryAgreement of mandatoryAgreements){
            if(accpetedIds.indexOf(mandatoryAgreement.id) < 0){
                Notify.Error(Resources.Wszystkie_wymagane_zgody_musza_byc_zaznaczone);
                return;
            }
        }

        try{
            const response = loginType === LoginType.Native ? (await new UsersService(new ServiceConfig()).createUserWithNativeCredentials(new CreateUserWithNativeCredentialsDto({
                acceptedAgreementsIds: accpetedIds,
                email: targetEmail,
                firstName: targetName,
                lastName: targetSurname || undefined,
                login: targetEmail,
                loginType,
                password: password,
                phoneNumber: undefined,
                joinParkingDto: undefined,
                parkingInvitationCode: invitationGuid || undefined
            }))) : (await new UsersService(new ServiceConfig()).createUserWithThirdPartyCredentials(new CreateUserWithThirdPartyCredentialsDto({
                email: targetEmail,
                firstName: targetName,
                lastName: targetSurname || undefined,
                loginType,
                phoneNumber: undefined,
                acceptedAgreementsIds: accpetedIds,
                oAuthAccessToken: oauthToken,
                joinParkingDto: undefined,
                parkingInvitationCode: invitationGuid || undefined
            })));
            if(response.isSuccess){
                const loginResponse = loginType === LoginType.Native ? (await loginNative(targetEmail, password)) : (await loginToThirdParty(oauthToken, loginType));
                if(typeof loginResponse === "boolean"){
                    setStatus(getErrorMessage(ErrorCode.ThirdPartyLoginFailed));
                }
                else if(typeof loginResponse === "string"){
                    setStatus(loginResponse);
                }
                else{
                    const {jwt, userId} = loginResponse;
                    const afterLoginResult = await new Promise<boolean>(resolve => {
                        afterLogin(jwt, userId, () => resolve(true), () => resolve(false));
                    });
                    if(afterLoginResult){
                        setRedirect(true);
                    }
                    else{
                        setStatus(Resources.Nastapil_blad);
                    }
                }
            }
            else{
                setStatus(Resources.Nastapil_blad);
            }
        }
        catch{
            setStatus(Resources.Nastapil_blad);
        }
        finally{
            setSubmitting(false);
        }
    }

    if(agreementsProgress){
        return (
            <div 
                style={{
                    height: 200, 
                    justifyContent:'center', 
                    alignItems: 'center', 
                    display: 'flex', 
                    flexDirection: 'column'
                }}
            >
                <Spinner size="medium" />
            </div>
        );
    }

    if(agreementsError){
        return (
            <div 
                style={{
                    height: 200, 
                    justifyContent:'center', 
                    alignItems: 'center', 
                    display: 'flex', 
                    flexDirection: 'column'
                }}
            >
                <PCText color={Colors.red} fontSize={16} semibold>
                    {getErrorMessage(agreementsError)}
                </PCText>
            </div>
        );
    }

    if(redirect){
        return <Redirect to="/" />;
    }

    if(redirectToChangeLaguage){
        return <Redirect to="/changelanguage" />
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {args => {
                const {handleSubmit, values, errors, touched, setFieldValue, setFieldTouched, status, isSubmitting} = args;

                console.log('errors: ', errors);

                const onAgreementPressed = (id: string) => {                         
                    const contains = values.accpetedIds.indexOf(id) >= 0;
                    const newAccepted = contains ? values.accpetedIds.filter(i => i !== id) : [...values.accpetedIds, id];

                    setFieldValue("accpetedIds", newAccepted);
                }

                const acceptAll = () => {
                    setFieldValue("accpetedIds", agreements.map(a => a.id));
                }

                const deaccetpAll = () => {
                    setFieldValue("accpetedIds", []);
                }

                return (
                    <form
                        noValidate={true}
                        autoComplete="off"
                        onSubmit={handleSubmit}
                        style={{alignSelf: 'stretch'}}
                    >
                        {loginType === LoginType.Native && (
                            <>
                                <RegularInput 
                                    label={Resources.Email}
                                    name="email"
                                    variant="big"
                                    value={values.email}
                                    error={errors.email}
                                    touched={touched.email}
                                    setFieldTouched={setFieldTouched}
                                    setFieldValue={setFieldValue}
                                    showClearButton
                                    showValidatedButton
                                />
                                <RegularInput 
                                    label={Resources.Imie_i_nazwisko}
                                    name="nameAndSurname"
                                    variant="big"
                                    value={values.nameAndSurname}
                                    error={errors.nameAndSurname}
                                    touched={touched.nameAndSurname}
                                    setFieldTouched={setFieldTouched}
                                    setFieldValue={setFieldValue}
                                    showClearButton
                                    showValidatedButton
                                />
                                <RegularInput 
                                    label={Resources.Haslo}
                                    name="password"
                                    variant="big"
                                    value={values.password}
                                    error={errors.password}
                                    touched={touched.password}
                                    setFieldTouched={setFieldTouched}
                                    setFieldValue={setFieldValue}
                                    password
                                />
                            </>
                        )}
                        

                        <div style={{display: 'flex', justifyContent: 'center', position: 'relative'}}>
                            <PCText color={Colors.brownish_grey} textAlign="center" fontSize={14}>{Resources.Haslo_musi_zawierac_co_najmniej_7_znakow}</PCText>
                        </div>

                        {!!agreements && !!agreements.length && (
                            <>
                            <div style={{width: '100%', marginTop: 50}}>
                                {agreements.map((agreement, index, arr) => {
                                    const {id, isMandatory, text} = agreement;

                                    return (
                                        <React.Fragment key={id}>
                                            <div style={{display: 'flex'}}>
                                                <input type="checkbox" onChange={() => onAgreementPressed(id)}/>
                                                <BaseSpaceSeparator size={15} />
                                                <div style={{flex: 1}}>
                                                <PCText fontSize={14} lineHeight={"auto"} color={Colors.brownish_grey}>
                                                    {isMandatory ? "*" : undefined}{text}
                                                </PCText>
                                                </div>
                                            </div>
                                            {arr.length > 1 && index !== arr.length - 1 && <BaseSpaceSeparator size={35} />}
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                            <BaseSpaceSeparator size={20} />
                            <PCText fontSize={14} lineHeight={"auto"} color={Colors.brownish_grey}>*{Resources.Wymagane}</PCText>
                            </>
                        )}
                        <div style={{height: 50, display: 'flex', flexDirection: 'column', justifyContent: "flex-end", alignItems: 'center'}}>
                            <PCText fontSize={12} letterSpacing={0} color={Colors.red}>{status || ""}</PCText>
                            <BaseSpaceSeparator size={10} />
                        </div>


                        <StandardButton variant="big" progress={isSubmitting} type="submit" tabIndex={4}>{Resources.Zaloz_konto}</StandardButton>

                        <BaseSpaceSeparator size={10} />
                        <PCText color={Colors.brownish_grey} fontSize={14} textAlign="center">
                            {Resources.Zakladajac_konto_zgadzasz_sie_z} <TextButton fontSize={14} target="_blank" href="https://parkcash.io/regulations/regulaminaplikacji.html">{Resources.Regulaminem}</TextButton> {Resources.oraz} <TextButton target="_blank" href="https://parkcash.io/regulations/politykaprywatnosci.html" fontSize={14} >{Resources.Polityka_Prywatnosci}</TextButton> 
                        </PCText>
                    </form>
                );
            }}
        </Formik>
    );
}

const mapStateToProps = (state: applicationState): Partial<Props> => ({
    email: state.authentication.email,
    name: state.authentication.name,
    surname: state.authentication.surname,
    loginType: state.authentication.loginType,
    oauthToken: state.authentication.oauthToken
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
    afterLogin: (jwt, userId, success, fail) => dispatch(initUser(jwt, userId, success, fail)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Container);