// @mui
import {alpha} from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Card, {CardProps} from '@mui/material/Card';
import LinearProgress from '@mui/material/LinearProgress';
// utils
import {fShortenNumber} from '../../../../MUI/utils/format-number';
import React from "react";

// ----------------------------------------------------------------------

interface Props extends CardProps {
    title?: string;
    subheader?: string;
    data: {
        status: string; quantity: number; value: number;
    }[];
}

export default function StyledListWithProgressBar({
                                                      title,
                                                      subheader,
                                                      data,
                                                      ...other
                                                  }: Props) {
    return (<Card {...other}>
        <CardHeader title={title} subheader={subheader}/>

        <Stack spacing={3} sx={{p: 3}}>
            {data.map((progress) => (<Stack key={progress.status}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{mb: 1}}
                >
                    <Box sx={{typography: 'overline'}}>{progress.status}</Box>
                    <Box sx={{typography: 'subtitle1'}}>{fShortenNumber(progress.value)}</Box>
                </Stack>

                <LinearProgress
                    variant="determinate"
                    value={progress.value}
                    color={(progress.status === 'Pending' && 'warning') || (progress.status === 'Canceled' && 'error') || 'success'}
                    sx={{
                        height: 8,
                        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.16)
                    }}
                />
            </Stack>))}
        </Stack>
    </Card>);
}
