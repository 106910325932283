import { IVehicleExDto } from "parkcash-api"
import PaymentForm from "../../payments/PaymentForm"

export interface ReservationConfirmationFormState {
    isMeParking: boolean,
    payment: string,
    vehicle: string,
    nameAndSurname: string,
    phoneNumber: string,
    email: string,
    description: string,
    licencePlateNumber: string
}

export const getDefaultFormState = (): ReservationConfirmationFormState => {
    return {
        isMeParking: true,
        payment: null,
        vehicle: null,
        description: "",
        email: "",
        licencePlateNumber: "",
        nameAndSurname: "",
        phoneNumber: ""
    }
}