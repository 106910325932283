import {faEdit, faQrcode, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {
    ErrorCode,
    GetParkingUsersViewResultEntryDto,
    ParkingsService,
    ServiceConfig,
    SortOrder,
    StandardResponseWrapperOfObjectAndErrorWrapperOfObject
} from "parkcash-api";
import {
    AutomationService,
    FileParameter,
    GetExternalParkingAreasAreaInfo,
    GetLprWhiteListEntriesResultEntryDto,
    LprWhiteListService,
    RemoveLprWhiteListEntryDto
} from "parkcash-api";
import * as React from "react";
import {useCallback, useEffect} from "react";
import Resources from "../../../Resources";
import PCDataGrid from "../../components/PCDataGrid";
import ActionColumn, {ActionIcon} from "../../components/PCDataGrid/ActionColumn";
import {SubBody} from "../../layouts/Main/Body";
import {StandardButton} from "../../styles/Buttons";
import Colors from "../../styles/Colors";
import {BaseSpaceSeparator} from "../../styles/Separators";
import {PCText} from "../../styles/Texts";
import {MOBILE_WIDTH_THRESHOLD} from "../../utils/Constants";
import {CustomDataSource, FilterOperator, IDataSource} from "../../utils/DataSource";
import {getFilters, getSorts} from "../../utils/SieveUtils";
import {useWindowSize} from "../../utils/WindowSizeHook";
import AddLprEntryForm from "./AddLprEntryForm";
import EditLptEntryForm from "./EditLptEntryForm";
import {getErrorMessage, getErrorMessageNew} from "../../utils/ErrorUtils";
import GlobalProgress from "../../components/GlobalProgress";
import Notify from "../../utils/Notify";
import ConfirmModal from "../../components/ConfirmModal";
import {AddOrImportActionsMenu} from "../../MUI/sections/file-manager/AddOrImportActionsMenu";
import ParkingSpotNumberPresenter from "../../components/ParkingSpotNumberPresenter";
import {showQrCodesInSeperateWindows} from "../../utils/QrCodeShowUtils";
import {IParkCashApplicationMode} from "../../utils/ParkCashApplicationMode";
import {
    CustomizedTextfield
} from "../../MUI/components/AddedOrModyfiedMuiComponents/CustomizedInputs/CustomizedTextfield";
import Enumerable from "linq";

export const LprManagementGrid = (props: {
    jwt: string; applicationMode: IParkCashApplicationMode;
}) => {
    const {
        applicationMode,
        jwt
    } = props;
    const parkingId = applicationMode.getCurrentParking()?.parkingId || null;
    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const [search, setSearch] = React.useState("");
    const grid = React.useRef<PCDataGrid<GetParkingUsersViewResultEntryDto>>();
    const [addFormVisible, setAddFormVisible] = React.useState(false);
    const [editFormVisible, setEditFormVisible] = React.useState(false);
    const [editedEntry, setEditedEntry] = React.useState<GetLprWhiteListEntriesResultEntryDto>();
    const [globalProgress, setGlobalProgress] = React.useState(false);
    const [areas, setAreas] = React.useState<GetExternalParkingAreasAreaInfo[]>();
    const [dataSource, setDataSource] = React.useState<IDataSource<GetLprWhiteListEntriesResultEntryDto>>(null);
    const [dataGridKey, setDataGridKey] = React.useState(0);

    const resetFilters = () => {
        setSearch("");
    }
    useEffect(() => {

        const action = async () => {

            try {
                const {
                    isSuccess,
                    error,
                    result
                } = await new ParkingsService(new ServiceConfig({jwt})).getExternalParkingAreas(parkingId);
                if (isSuccess) {

                    if (result.areaInfos.length === 0) {
                        result.areaInfos.push(new GetExternalParkingAreasAreaInfo({
                            id: "default",
                            name: Resources.Wszystkie
                        }));
                    }
                    else{
                      const virtualAllAreasItem =  Enumerable.from(result.areaInfos).firstOrDefault(x=>x.id=="ALL_AREAS_VIRTUAL_ID")
                        if(virtualAllAreasItem){
                            virtualAllAreasItem.name = Resources.Caly_parking
                        }
                    }

                    setAreas(result.areaInfos);
                } else {
                    Notify.Error(getErrorMessageNew(error));
                }
            } catch {
                Notify.Error(getErrorMessage());
            }
        }

        action().catch(console.error);

    }, [parkingId]);

    const getDataSource = React.useCallback(
        () => {
            resetFilters(); // reset filters on data source recreation e.g. after parkings id change
            return new CustomDataSource<GetLprWhiteListEntriesResultEntryDto>({
                initialSort: [
                    {
                        key: "licensePlateNumber",
                        order: SortOrder.Ascending
                    }
                ],
                load: async (loadOptions) => {
                    try {

                        const {
                            filter,
                            page,
                            pageSize,
                            sort
                        } = loadOptions;
                        let targetFilter = getFilters(filter);
                        const sorts = getSorts(sort);
                        const {
                            isSuccess,
                            result,
                            error
                        } = await new LprWhiteListService(new ServiceConfig({jwt})).getLprWhiteListEntries(parkingId,
                            targetFilter,
                            sorts,
                            page,
                            pageSize
                        );
                        if (isSuccess) {
                            return {
                                items: result.entries,
                                totalItems: result.paginationDto.totalElementsCount,

                            }
                        } else {
                            return error.code;
                        }
                    } catch {
                        return ErrorCode.NetworkError;
                    }
                }
            });
        },
        [
            parkingId,
            jwt
        ]
    );
    useEffect(
        () => {
            setDataSource(getDataSource());
            setDataGridKey(pre => pre + 1);
        },
        [
            parkingId,
            jwt
        ]
    );

    const onSearch = React.useCallback(() => {
        if (!search) {
            dataSource.removeFilter("licensePlateNumber");
        } else {
            dataSource.filter("licensePlateNumber", FilterOperator.CASE_INSENSITIVE_STRING_CONTAINS, search);
        }
    }, [
        dataSource,
        search
    ]);

    const onAddEntry = React.useCallback(() => {
        setAddFormVisible(true);
    }, []);
    const onUploadImportedFiles = useCallback((async (file: (string | File), fileName: string): Promise<StandardResponseWrapperOfObjectAndErrorWrapperOfObject> => {
        const fileParameter: FileParameter = {
            fileName: fileName,
            data: file
        }
        const response = await new AutomationService(new ServiceConfig({jwt})).batchCreateLprWhitelistEntries(parkingId,
            fileParameter
        );
        return response;

    }), [
        jwt,
        parkingId
    ]);

    const onEditEntry = React.useCallback((item: GetLprWhiteListEntriesResultEntryDto) => {
        setEditedEntry(item);
        setEditFormVisible(true);
    }, []);

    const onDeleteEntry = React.useCallback(async (item: GetLprWhiteListEntriesResultEntryDto) => {

        const confirm = await ConfirmModal.show({
            text: Resources.Czy_na_pewno_chcesz_usunac_dostep,
            confirmText: Resources.Usun,
        });

        if (!confirm) return;

        setGlobalProgress(true);
        try {
            const {
                isSuccess,
                error
            } = await new LprWhiteListService(new ServiceConfig({jwt})).removeLprWhiteListEntry(new RemoveLprWhiteListEntryDto(
                {
                    lprWhiteListEntryId: item.id
                }));
            if (isSuccess) {
                dataSource?.reload();
            } else {
                Notify.Error(getErrorMessageNew(error));
            }
        } catch {
            Notify.Error(getErrorMessage());
        } finally {
            setGlobalProgress(false);
        }
    }, []);

    const onShowQrCodeClicked = (plate: string) => {
        showQrCodesInSeperateWindows([plate]);
    };

    return (<SubBody
        title={Resources.Zarzadzanie_dostepem_LPR}
        subtitle={Resources.Uzytkownicy_ktorzy_posiadaja_dostep_LPR}
        rigth={(<>
            <div style={{width: isMobile ? "100%" : 300}}>
                <AddOrImportActionsMenu onAdd={onAddEntry} onUploadImportedFiles={onUploadImportedFiles}
                                        buttonTitle={Resources.Dodaj_Dostep}/>
            </div>
        </>)}
    >
        <GlobalProgress visible={globalProgress}/>

        <AddLprEntryForm
            onClose={() => setAddFormVisible(false)}
            onSubmitted={() => {
                setSearch("");
                setAddFormVisible(false);
                dataSource?.reload();
            }}
            visible={addFormVisible}
            parkingId={parkingId}
            areas={areas}
        />
        <EditLptEntryForm
            onClose={() => setEditFormVisible(false)}
            onSubmitted={() => {
                setSearch("");
                setEditFormVisible(false);
                dataSource?.reload();
            }}
            visible={editFormVisible}
            entry={editedEntry}
            parkingId={parkingId}
            areas={areas}
        />
        <BaseSpaceSeparator size={20}/>
        <div
            style={{
                display: "flex",
                width: "100%",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    flex: isMobile ? undefined : 1,
                    width: isMobile ? "100%" : undefined,
                }}
            >
                <CustomizedTextfield value={search} onChange={(e) => setSearch(e.target.value)}
                                     label={Resources.Wyszykaj_dostep_po_numerze_rejestracyjnym}
                                     fullWidth
                                     onKeyDown={(e) => {
                                         if (e.key === "Enter") {
                                             onSearch();
                                         }
                                     }}
                />
            </div>
            <BaseSpaceSeparator size={10}/>
            <div style={{width: isMobile ? "100%" : 200}}>
                <StandardButton variant="big" onClick={onSearch}>
                    {Resources.Wyszukaj}
                </StandardButton>
            </div>
        </div>
        <BaseSpaceSeparator size={20}/>
        <PCDataGrid<GetLprWhiteListEntriesResultEntryDto>
            ref={grid}
            key={dataGridKey}
            dataSource={dataSource}
            columns={[
                {
                    label: Resources.Numer_rejestracyjny,
                    dataField: "licensePlateNumber",
                    width: 200,
                    renderCell: item => (<PCText fontSize={16} lineHeight={20 / 16} color={Colors.brownish_grey}
                                                 letterSpacing={0}
                                                 semibold>
                        {item.licensePlateNumber}
                    </PCText>)
                },
                {
                    label: Resources.Opis,
                    dataField: "description",
                    width: 253,
                    renderCell: (item) => (<PCText fontSize={14} lineHeight={12 / 16} color={Colors.brownish_grey}
                                                   letterSpacing={0}>
                        {item.description}
                    </PCText>)
                },
                {
                    label: Resources.Obszar,
                    dataField: "areaName",
                    width: 50,
                    renderCell: (item) => (<PCText fontSize={14} lineHeight={12 / 16} color={Colors.brownish_grey}
                                                   letterSpacing={0}>
                        {item.areaName}
                    </PCText>)
                },
                {
                    label: Resources.Miejsce,
                    width: 200,
                    renderCell: (item) => item.assignedSpotInfo ? (<ParkingSpotNumberPresenter
                        key={item.assignedSpotInfo.id}
                        spotNumber={item.assignedSpotInfo.number}
                        level={item.assignedSpotInfo.level}
                        sector={item.assignedSpotInfo.sector}
                    />) : null
                },
                {
                    label: Resources.Waznosc_od,
                    dataField: "validFrom",
                    width: 180,
                    placeholder: "-"
                },
                {
                    label: Resources.Waznosc_do,
                    dataField: "validTo",
                    width: 180,
                    placeholder: "-"
                },

                {
                    width: '170',
                    label: Resources.Akcje,
                    renderCell: (item) => (<>
                            <ActionColumn>
                                <ActionIcon
                                    icon={faQrcode}
                                    title={Resources.Pokaz_kod_qr}
                                    onClick={() => onShowQrCodeClicked(item.qrCodeGuid)}/>
                                <ActionIcon icon={faEdit} title={Resources.Edytuj}
                                            onClick={() => onEditEntry(item)}/>
                                <ActionIcon icon={faTrashAlt} title={Resources.Usun}
                                            onClick={() => onDeleteEntry(item)}/>
                            </ActionColumn>
                        </>

                    )
                }
            ]}
        />

    </SubBody>)

}
