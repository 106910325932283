
import thunk from "redux-thunk";
import logger from "redux-logger";
import { combineReducers, createStore, applyMiddleware } from "redux";
import { applicationState } from "./ApplicationState";
import * as configuration from "../../app.json";
import userReducer from "../user/Redux";
import authenticationReducer from "../authentication/Redux";
import virtualPilotsReducer from "../mobilePilots/Redux";
import parkingSpotsReducer from "../parkingSpots/Redux";
import paymentsReducer from "../payments/Redux";
import vehiclesReducer from "../vehicles/Redux";
import sharingReducer from "../sharing/Redux";
import groupsReducer from "../groups/Redux";
import otherPagesReducer from "../otherPages/Redux";

const appReducer = combineReducers<applicationState>({
    user: userReducer,
    authentication: authenticationReducer,
    virtualPilots: virtualPilotsReducer,
    parkingSpots: parkingSpotsReducer,
    payments: paymentsReducer,
    vehicles: vehiclesReducer,
    sharing: sharingReducer,
    groups: groupsReducer,
    otherPages: otherPagesReducer
});

const store = configuration.enableReduxLogger ? createStore(appReducer, applyMiddleware(thunk, logger)) : createStore(appReducer, applyMiddleware(thunk));

export default store;