import React from 'react';
// @mui
import {useTheme} from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
// hooks
import {useMockedUser} from '../../../../../../MUI/hooks/use-mocked-user';
// _mock
// components
import {useSettingsContext} from '../../../../../../MUI/components/settings';
// assets
//
import WidgetSummaryWithSparkLine from '../../../../../../MUI/components/AddedOrModyfiedMuiComponents/Widgets/WidgetSummaryWithSparkLine';
import Resources from "../../../../../../../Resources";
import {GetFinancialDashboradDataResult} from "parkcash-api";
import {sortObjectsByDateAscending} from "../../../../../../MUI/utils/format-time";

// ----------------------------------------------------------------------

interface WidgetStatisticsSectionProps {
    data: GetFinancialDashboradDataResult
}

export default function WidgetStatisticsSection({data}: WidgetStatisticsSectionProps) {
    const {
        currentPeriodTotalValue,
        previousVsCurrentTotalValuePercentageDifferance,
        currentPeriodTotalCount,
        previousVsCurrentTotalCountPercentageDifferance,
        currentPeriodRefundsTotalValue,
        previousVsCurrentTotalRefundsValuePercentageDifferance,
        dayByDaySummarySeries
    } = data ?? {};
    sortObjectsByDateAscending(dayByDaySummarySeries);
    const valueChartSeries = dayByDaySummarySeries?.map((item) => item.totalValue);
    const countChartSeries = dayByDaySummarySeries?.map((item) => item.totalCount);
    const refundsChartSeries = dayByDaySummarySeries?.map((item) => item.refundsTotalValue);
    const {user} = useMockedUser();
    const theme = useTheme();

    const settings = useSettingsContext();
    return (/* <Container maxWidth={settings.themeStretch ? false : 'xl'}>*/
        <Grid container spacing={3} xs={12}>
            <Grid xs={12} md={4}>
                <WidgetSummaryWithSparkLine
                    title={Resources.Przychod}
                    total={currentPeriodTotalValue}
                    percent={previousVsCurrentTotalValuePercentageDifferance}
                    chart={{
                        series: valueChartSeries,
                    }}
                />
            </Grid>

            <Grid xs={12} md={4}>
                <WidgetSummaryWithSparkLine
                    title={Resources.Liczba_Transakcji}
                    total={currentPeriodTotalCount}
                    percent={previousVsCurrentTotalCountPercentageDifferance}
                    chart={{
                        colors: [
                            theme.palette.info.light,
                            theme.palette.info.main
                        ],
                        series: countChartSeries

                    }}
                />
            </Grid>
            <Grid xs={12} md={4}>
                <WidgetSummaryWithSparkLine
                    title={Resources.Zwroty}
                    total={currentPeriodRefundsTotalValue}
                    percent={previousVsCurrentTotalRefundsValuePercentageDifferance}
                    chart={{
                        colors: [
                            theme.palette.warning.light,
                            theme.palette.warning.main
                        ],
                        series: refundsChartSeries
                    }}
                />
            </Grid>
        </Grid>);

}
