import {ErrorCode, ParkingManagerService, ServiceConfig, SessionsService, UsersService} from "parkcash-api";
import {getErrorMessage} from "../utils/ErrorUtils";

export const prolongJWT = async (jwt: string) => {
    try{
        const prolongationResponse = await new  SessionsService(new ServiceConfig({jwt: jwt})).prolongSession();
        if(prolongationResponse.isSuccess){
            const {jwt, userId} = prolongationResponse.result;
            return {jwt, userId};
        }
        else{
            return null;
        }   
    }
    catch{
        return null;
    }
}

export const getUserRoles = async (jwt: string) => {
    try{
        const rolesResponse = await new SessionsService(new ServiceConfig({jwt: jwt})).getUserRoles();
        if(rolesResponse.isSuccess){
            return rolesResponse.result.roles
        }
        else{
            return getErrorMessage(rolesResponse.error.code);
        }
    }
    catch{
        return getErrorMessage(ErrorCode.NetworkError);
    }
}

export const getUserParkings = async (jwt: string) => {
    try{
        const response = await new ParkingManagerService(new ServiceConfig({jwt})).getParkingManagementContext();
        if(response.isSuccess){
            return response.result.entries;
        }
        else{
            return getErrorMessage(response.error.code);
        }
    }
    catch{
        return getErrorMessage(ErrorCode.NetworkError);
    }
}

export const getUserInfo = async (jwt: string) => {
    try{
        const response = await new UsersService(new ServiceConfig({jwt})).getUserInfo();
        if(response.isSuccess){
            const {bankAccountNumber, email, lastName, firstName, phoneNumber, loginType, externalProfileId, emailConfirmed, hideGroups, hideParkingSpots, hideParkingSpotsCreationButton, hidePayments, hidePilotsManagement, hidePriceWhenFree, hideProfileId, hideQrCodeReader, hideWallet } = response.result;
            return {
                bankAccountNumber,
                email,
                lastName,
                firstName,
                phoneNumber,
                loginType,
                profileId: externalProfileId,
                emailConfirmed,
                hideGroups,
                hideParkingSpots,
                hideParkingSpotsCreationButton,
                hidePayments,
                hidePilotsManagement,
                hidePriceWhenFree,
                hideProfileId,
                hideQrCodeReader,
                hideWallet
            }
        }
        else{
            return getErrorMessage(response.error.code);
        }
    }
    catch{
        return getErrorMessage(ErrorCode.NetworkError);
    }
}