import * as React from "react";
import Resources from "../../../Resources";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { PCClasses } from "../../utils/CSSUtils";
import { CalendarType } from "./Interfaces";
import {PCDatePickerContent} from "../../components/PCDatetimePicker";
import { ParkingSpotTimelineDaySegmentInfo, TimelineMemberCategory } from "parkcash-api";
import Timeline from "./Timeline";
import PCTabBar from "../../components/PCTabBar";
import PlaceFree from "./PlaceFree";
import PlaceOccupied from "./PlaceOccupied";

const TypeOfCalendarButton = (props: {
    type: CalendarType,
    currentType: CalendarType,
    onActivate: (type: CalendarType) => void
}) => {
    const {currentType, onActivate, type} = props;
    const isActive = currentType === type;

    return (
        <div
            onClick={isActive ? undefined : () => onActivate(type)}
            className={PCClasses([], {"pc-button": !isActive})}
            style={{
                display: 'flex',
                width: 80,
                height: 30,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: isActive ? Colors.heliotrope : "transparent",
                borderRadius: 20
            }}
        >
            <PCText semibold fontSize={14} lineHeight={20/14} letterSpacing={0} color={Colors.white} textAlign="center"> 
                {type === CalendarType.Week ? Resources.Tydzien : Resources.Dzien}
            </PCText>
        </div>
    )
}

const TimeLineItem = (props: {
    item: ParkingSpotTimelineDaySegmentInfo,
    onDismissReservation: (reservationId: string) => void;
    onRemoveSharing: (start: Date, end: Date) => void;
}) => {
    const {item, onDismissReservation, onRemoveSharing} = props;

    return (
        <>
            {item.category === TimelineMemberCategory.Free && <PlaceFree item={item} onRemoveSharing={onRemoveSharing} />}
            {item.category === TimelineMemberCategory.Reservation && <PlaceOccupied item={item} onDismiss={onDismissReservation} />}
            {item.category === TimelineMemberCategory.Subscription && <PlaceOccupied item={item} />}
            {item.category === TimelineMemberCategory.ExternalReservation && <PlaceOccupied item={item} />}
            {item.category === TimelineMemberCategory.TechnicalBuffer && <PlaceOccupied item={item} showAdditionalInfo={false} />}
        </>
    ); 
}

const TimelineItemsList = (props: {
    items: ParkingSpotTimelineDaySegmentInfo[] | ParkingSpotTimelineDaySegmentInfo;
    onDismissReservation: (reservationId: string) => void;
    onRemoveSharing: (start: Date, end: Date) => void;
}) => {
    const {items, onRemoveSharing, onDismissReservation} = props;
    if(Array.isArray(items)){
        if(!items.length){
            return (
                <PCText fontSize={16} textAlign="center" semibold color={Colors.black} >
                    {Resources.Brak_danych}
                </PCText>
            )
        }

        return (    
            <>
                {items.map((item, index, arr) => (
                    <React.Fragment key={index}>
                        <TimeLineItem 
                            item={item}
                            onDismissReservation={onDismissReservation}
                            onRemoveSharing={onRemoveSharing}   
                        />
                        {index !== arr.length - 1 && <div style={{height: 1, backgroundColor: Colors.very_light_pink}}/>}
                    </React.Fragment>
                ))}
            </>
        );       
    }
    else{
        return (
            <TimeLineItem 
                item={items}
                onDismissReservation={onDismissReservation}
                onRemoveSharing={onRemoveSharing}   
            />
        );
    }
}

export default (props: {
    calendarType: CalendarType,
    onCalendarTypeChange: (type: CalendarType) => void;
    isMobile: boolean,
    currentDay: Date,
    onDaySelected: (day: Date) => void,
    currentItem: ParkingSpotTimelineDaySegmentInfo,
    timeline: Timeline,
    onDismissReservation: (reservationId: string) => void;
    onRemoveSharing: (start: Date, end: Date) => void;
}) => {
    const {calendarType, onCalendarTypeChange, isMobile, currentDay, onDaySelected, timeline, currentItem, onDismissReservation, onRemoveSharing} = props;
    const [tab, setTab] = React.useState(1);

    React.useEffect(() => {
        setTab(1);
    }, [timeline]);

    return (
        <div
            style={{
                width: 404,
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            {!isMobile && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 25,
                        paddingRight: 25,
                        height: 80,
                        backgroundColor: Colors.light_royal_blue,
                        borderTopLeftRadius: 10
                    }}
                >
                    <TypeOfCalendarButton type={CalendarType.Day} currentType={calendarType} onActivate={onCalendarTypeChange}/>
                    <BaseSpaceSeparator size={10} />
                    <TypeOfCalendarButton type={CalendarType.Week} currentType={calendarType} onActivate={onCalendarTypeChange}/>
                </div>
            )}
            <div
                style={{
                    flex: 1,
                    backgroundColor: Colors.white,
                    borderTopLeftRadius: isMobile ? 10 : 0,
                    borderTopRightRadius: isMobile ? 10 : 0,
                    borderBottomRightRadius: isMobile ? 10 : 0,
                    borderBottomLeftRadius: 10,
                    padding: "0px 25px 25px 25px",
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <PCDatePickerContent 
                    currentDay={currentDay}
                    onDaySelected={onDaySelected}
                    dots={timeline.getDots()}
                />
                <BaseSpaceSeparator size={33} />
                <div
                    style={{flex: 1, overflow: "auto"}}
                >
                   {!!currentItem && (
                       <TimelineItemsList 
                            items={currentItem}
                            onDismissReservation={onDismissReservation}
                            onRemoveSharing={onRemoveSharing}
                       />
                   )} 
                   {!currentItem && (
                        <PCTabBar
                            itemWidth="auto"
                            items={[
                                {id: 1, text: Resources.Wszystkie},
                                {id: 2, text: Resources.Zajete}
                            ]}
                            current={tab}
                            onChange={setTab}
                        >
                            {id => {
                                if(id === 1){
                                    return (
                                        <TimelineItemsList 
                                            items={timeline.getAllItemsInDay(currentDay)}
                                            onDismissReservation={onDismissReservation}
                                            onRemoveSharing={onRemoveSharing}
                                        />
                                    );
                                }
                                else{
                                    return (
                                        <TimelineItemsList 
                                            items={timeline.getReservationsInDay(currentDay)}
                                            onDismissReservation={onDismissReservation}
                                            onRemoveSharing={onRemoveSharing}
                                        />
                                    );
                                }
                            }}
                        </PCTabBar>
                   )}
                </div>
            </div>
        </div>
    );
}