import React from "react";
import Colors from "../../styles/Colors";
import { PCText } from "../../styles/Texts";
import SearchAddressInput, { SearchAddressInputProps, searchAddressItem } from "./SearchAddressInput";

export interface RegularSearchAddressInputProps extends SearchAddressInputProps {
    error: any,
    touched: any,
    name: string;
    
    setFieldValue: (key: string, value) => void;
    setFieldTouched: (key: string, touched: boolean) => void;
}

export default class RegularSearchAddressInput extends React.Component<RegularSearchAddressInputProps> {
    private input: SearchAddressInput = null;
    
    constructor(props){
        super(props);
    }

    focus(){
        this.input?.focus();
    }

    blur(){
        this.input?.blur();
    }

    render(){
        const {
            error, 
            touched, 
            current, 
            name, 
            setFieldValue, 
            setFieldTouched, 
            onBlur, 
            onFocus, 
            onSelected,
            borderColor,
            ...restProps
        } = this.props;
        const isError = error && touched;
       let  displayError =mapErrorToText(error);
          
        return (
            <>
                <SearchAddressInput
                    {...restProps}
                    ref={i => this.input = i}
                    current={current}
                    onSelected={item => {
                        setFieldValue(name, item);
                        onSelected && onSelected(item);
                    }}
                    onBlur={() => {
                        setFieldTouched(name, true);
                        onBlur && onBlur();
                    }}
                    onFocus={() => {
                        setFieldTouched(name, false);
                        onFocus && onFocus();
                    }}
                    borderColor={isError ? Colors.red : borderColor}
                    isInvalid={isError}
                    showValidatedButton={touched && !error}
                />
                <div style={{paddingTop: 4, paddingBottom: 4, height: 20, boxSizing: "border-box", position: 'relative', top: 10}}>
                    <PCText fontSize={12} lineHeight={1} letterSpacing={0} color={Colors.red}>{isError ?displayError : ""}</PCText>
                </div>
            </>
        )
    }
}

const mapErrorToText = (error: any) => {
    let result = "";
    if(!error){
        return result;
    }
    
   if(typeof error==="object") {
       Object.values(error).forEach((value) => {
              result += value+", ";
         });
         
    }
   else {
         result = error;
   }
  
    return result;
}