import Home from "./Home";
import ParkingProcesses from "./ParkingProcesses";
import ParkingSpots from "./ParkingSpots";
import Pilots from "./Pilots";
import Users from "./Users";
import Invoices from "./Invoices";
import Subscriptions from "./Subscriptions";
import SubscriptionsPromotionCodes from "./SubscriptionsPromotionCodes";
import Timeline from "./ParkingSpots/Timeline";
import { FreeSpotsReports } from "./FreeSpotsReports";
import LprManagement from "./LprManagement";

export const ParkingManagementHome = Home;
export const ParkingManagementParkingProcesses = ParkingProcesses;
export const ParkingManagementParkingSpots = ParkingSpots;
export const ParkingManagementPilots = Pilots;
export const ParkingManagementUsers = Users;
export const ParkingManagementInvoices = Invoices;
export const ParkingManagementSubscriptions = Subscriptions;
export const ParkingManagementSubsriptionsPromotionCodes =
  SubscriptionsPromotionCodes;
export const ParkingManagementTimeline = Timeline;
export const ParkingManagementFreSpotsReports = FreeSpotsReports;
export const LptManagement = LprManagement;
