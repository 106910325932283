import React, {useEffect, useState} from 'react';
import {CancelToken} from 'axios';

type FetchFunctionType = (
    parkingId: string | null | undefined,
    start: Date | undefined,
    end: Date | undefined,
    cancelToken?: CancelToken | undefined
) => Promise<any>;

export const useDataFetcher = (
    start: Date,
    end: Date,
    parkingId: string,
    dateRangeSelected: boolean,
    setDateRangeSelected?: React.Dispatch<React.SetStateAction<boolean>>,
    fetchFunction?: FetchFunctionType
    
) => {
    const [data, setData] = useState<any | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any | null>(null);
    
    useEffect(() => {
        const allowFetchIfParkingIdIsChanging = () => {
            //
            if(setDateRangeSelected){
                setDateRangeSelected(true);
            }
           
        }
        allowFetchIfParkingIdIsChanging();

    }, [parkingId, setDateRangeSelected]);

    useEffect(() => {
        if (dateRangeSelected && start && end && parkingId && fetchFunction) {
            setLoading(true);
            fetchFunction(parkingId, start, end)
                .then((response) => {
                    setData(response.result);
                    setError(response.error);
                    setLoading(false);
                })
                .catch((error: Error) => {
                    setError(error);
                    setLoading(false);
                });
        }
    }, [start, end, parkingId, dateRangeSelected, fetchFunction]);

    return { data, loading, error };
};
