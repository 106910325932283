import React, {useCallback, useEffect, useState} from 'react';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog, {DialogProps} from '@mui/material/Dialog';
// components
import Iconify from '../../components/iconify';
import {Upload} from '../../components/upload';
import Resources from "../../../../Resources";
import {fileData} from "../../components/file-thumbnail";
import {StandardResponseWrapperOfObjectAndErrorWrapperOfObject} from "parkcash-api";
import {useBoolean} from "../../hooks/use-boolean";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import {
    useCustomizedNotification
} from "../../components/AddedOrModyfiedMuiComponents/CustomizedNotification/useCustomizedNotification";
import {downloadObjectAsJson} from "../../../utils/ImportResponseAsFile/importResponseAsFile";

// ----------------------------------------------------------------------

interface Props extends DialogProps {
    title?: string;
    uploadButtonTitle?: string;
    //
    onCreate?: VoidFunction; // original function from minimals not implemented currently
    onUpdate?: VoidFunction;//original function from minimals not implemented currently
    //
    folderName?: string;
    onChangeFolderName?: (event: React.ChangeEvent<HTMLInputElement>) => void; // original function from minimals not
                                                                               // implemented currently
    //
    open: boolean;
    onClose: VoidFunction;
    onUploadImportedFiles: (file: (File | string), fileName: string) => Promise<StandardResponseWrapperOfObjectAndErrorWrapperOfObject>;
    multipleFiles?: boolean;
}

export default function FileManagerNewFolderDialog({
                                                       title = Resources.Udostepnij_plik,
                                                       open,
                                                       onClose, //
                                                       onCreate,
                                                       onUpdate, //
                                                       folderName,
                                                       onChangeFolderName,
                                                       onUploadImportedFiles,
                                                       multipleFiles = true,
                                                       uploadButtonTitle = Resources.Udostepnij,

                                                       ...other

                                                   }: Props) {
    const [files, setFiles] = useState<(File | string)[]>([]);
    const loading = useBoolean(false);
    const {
        enqueError,
        enqueSuccess
    } = useCustomizedNotification();

    useEffect(() => {
        if (!open) {
            setFiles([]);
        }
    }, [open]);

    const handleDrop = useCallback((acceptedFiles: File[]) => {
        const newFiles = acceptedFiles.map((file) => Object.assign(file, {
            preview: URL.createObjectURL(file),
        }));

        setFiles([
            ...files,
            ...newFiles
        ]);
    }, [files]);

    const handleUpload = async () => {
        let efectiveResult = {};
        const file = files[0];
        const {
            key,
            name = '',
            size = 0
        } = fileData(file);

        try {
            loading.onTrue();
            const response = await onUploadImportedFiles(file, name);
            efectiveResult = response;
            enqueSuccess(Resources.Import_danych_z_pliku_zostal_ukonczony_pomyslnie, 2);
            console.log(response);
        } catch (e) {
            efectiveResult = e;
            enqueError(Resources.Wystapil_blad_podczas_importu_danych_z_pliku, 2);
            console.log(e)
        } finally {
            const date = new Date();
            const resultFileName = `${name.replace(".csv", "")}-${date.toISOString()}`;
            downloadObjectAsJson(efectiveResult, resultFileName);
            loading.onFalse();
            window.location.reload()
        }
        onClose();
    };

    const handleRemoveFile = (inputFile: File | string) => {
        const filtered = files.filter((file) => file !== inputFile);
        setFiles(filtered);
    };

    const handleRemoveAllFiles = () => {
        setFiles([]);
    };

    return (<Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} {...other}>
        <DialogTitle sx={{p: (theme) => theme.spacing(3, 3, 2, 3)}}> {title} </DialogTitle>

        <DialogContent dividers sx={{
            pt: 1,
            pb: 0,
            border: 'none'
        }}>
            {(onCreate || onUpdate) && (<TextField
                fullWidth
                label={Resources.Nazwa_pliku}
                value={folderName}
                onChange={onChangeFolderName}
                sx={{mb: 3}}
            />)}

            {!loading.value ? <Upload
                multiple={multipleFiles}
                file={!multipleFiles ? files[0] : undefined}
                files={multipleFiles ? files : undefined}
                onDrop={handleDrop}
                onRemove={handleRemoveFile}/> : <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                minHeight: "400px"
            }}>
                <CircularProgress/>
            </Box>}

        </DialogContent>

        {!loading.value ? <DialogActions>
            <Button
                variant="contained"
                startIcon={<Iconify icon="eva:cloud-upload-fill"/>}
                disabled={!files || files.length === 0}
                onClick={handleUpload}
            >
                {uploadButtonTitle}
            </Button>

            {!!files.length && (<Button variant="outlined" color="inherit" onClick={handleRemoveAllFiles}>
                {Resources.Usun_Wszystkie}
            </Button>)}

            {(onCreate || onUpdate) && (<Stack direction="row" justifyContent="flex-end" flexGrow={1}>
                <Button variant="soft" onClick={onCreate || onUpdate}>
                    {onUpdate ? 'Save' : 'Create'}
                </Button>
            </Stack>)}
        </DialogActions> : null}
    </Dialog>);
}
