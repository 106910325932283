import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormikProps} from "formik";
import {DayOfWeek} from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import FormLabel from "../../components/FormLabel";
import Spinner from "../../components/Spinner";
import {SwitchWithLabel} from "../../components/Switch";
import Colors from "../../styles/Colors";
import {BaseSpaceSeparator} from "../../styles/Separators";
import {addMinutes, getTime, getTodayTime} from "../../utils/DateTimeUtils";
import { nameof } from "../../utils/NameOf";
import {DayOfWeekActivePresenter, SingleDayTimeRangePresenter, SingleTimePresenter} from "./DateTimePresenters";
import {CyclicFormState} from "./ShareParkingSpotFormState";

export default (props: {
    args: FormikProps<CyclicFormState>, spotDropdown: JSX.Element, cyclicOnlyProgress: boolean
}) => {
    const {
        args,
        spotDropdown,
        cyclicOnlyProgress
    } = props;
    const {
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue
    } = args;

    const setDayAvailability = (index: number) => {
        const newAvailability = [...values.dayAvailability];
        newAvailability[index] = !newAvailability[index];
        setFieldValue("dayAvailability", newAvailability);
    }

    const onSpecyficDayStartChanged = (day: number, start: Date) => {
        const startCandidate = getTodayTime(start.getHours(), start.getMinutes());
        const currentEnd = getTodayTime(values.dayEnds[day].getHours(), values.dayEnds[day].getMinutes());
        const startsCopy = [
            ...values.dayStarts
        ];
        const endsCopy = [
            ...values.dayEnds
        ];
        startsCopy[day] = startCandidate;
        endsCopy[day] = startCandidate >= currentEnd ? addMinutes(startCandidate, 15) : currentEnd;
        setFieldValue("dayStarts", startsCopy);
        setFieldValue("dayEnds", endsCopy);
    }

    const onSpecyficDayEndChanged = (day: number, end: Date) => {
        const endCandidate = getTodayTime(end.getHours(), end.getMinutes());
        const currentStart = getTodayTime(values.dayStarts[day].getHours(), values.dayStarts[day].getMinutes());
        const startsCopy = [
            ...values.dayStarts
        ];
        const endsCopy = [
            ...values.dayEnds
        ];
        endsCopy[day] = endCandidate;
        startsCopy[day] = endCandidate <= currentStart ? addMinutes(endCandidate, -15) : currentStart;
        setFieldValue("dayStarts", startsCopy);
        setFieldValue("dayEnds", endsCopy);
    }

    return (<>
            <BaseSpaceSeparator size={30}/>
            {spotDropdown}
            {cyclicOnlyProgress && (<div style={{
                    height: 200,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Spinner size={"medium"}/>
                </div>)}
            {!cyclicOnlyProgress && (<>
                    <BaseSpaceSeparator size={30}/>
                    <SwitchWithLabel
                        label={Resources.Udostepnij_do_odwolania}
                        value={values.shareWholeTime}
                        onChange={v => setFieldValue("shareWholeTime", v)}
                    />
                    <BaseSpaceSeparator size={30}/>
                    {!values.shareWholeTime && (<>
                            <FormLabel>{Resources.Ustaw_dostepnosc_miejsca_parkingowego}</FormLabel>
                            <BaseSpaceSeparator size={5}/>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <DayOfWeekActivePresenter day={DayOfWeek.Monday} isActive={values.dayAvailability[1]}
                                                          onClick={() => setDayAvailability(1)}/>
                                <BaseSpaceSeparator size={15}/>
                                <DayOfWeekActivePresenter day={DayOfWeek.Tuesday} isActive={values.dayAvailability[2]}
                                                          onClick={() => setDayAvailability(2)}/>
                                <BaseSpaceSeparator size={15}/>
                                <DayOfWeekActivePresenter day={DayOfWeek.Wednesday} isActive={values.dayAvailability[3]}
                                                          onClick={() => setDayAvailability(3)}/>
                                <BaseSpaceSeparator size={15}/>
                                <DayOfWeekActivePresenter day={DayOfWeek.Thursday} isActive={values.dayAvailability[4]}
                                                          onClick={() => setDayAvailability(4)}/>
                                <BaseSpaceSeparator size={15}/>
                                <DayOfWeekActivePresenter day={DayOfWeek.Friday} isActive={values.dayAvailability[5]}
                                                          onClick={() => setDayAvailability(5)}/>
                                <BaseSpaceSeparator size={15}/>
                                <DayOfWeekActivePresenter day={DayOfWeek.Saturday} isActive={values.dayAvailability[6]}
                                                          onClick={() => setDayAvailability(6)}/>
                                <BaseSpaceSeparator size={15}/>
                                <DayOfWeekActivePresenter day={DayOfWeek.Sunday} isActive={values.dayAvailability[0]}
                                                          onClick={() => setDayAvailability(0)}/>
                            </div>
                            <BaseSpaceSeparator size={30}/>
                            <SwitchWithLabel
                                value={values.cyclicDetailedHours}
                                onChange={v => setFieldValue(nameof<CyclicFormState>("cyclicDetailedHours"), v)}
                                label={"Ustaw szczegółowe godziny"}
                            />

                            {values.cyclicDetailedHours && (<>
                                <SwitchWithLabel
                                    value={values.sameHours}
                                    onChange={v => setFieldValue("sameHours", v)}
                                    label={Resources.Uzyj_wszedzie_tych_samych_godzin}
                                />
                                <BaseSpaceSeparator size={30}/>
                                <FormLabel>{Resources.Wybierz_godziny_dostepnosci_miejsca}</FormLabel>
                                <BaseSpaceSeparator size={15}/>
                                {values.sameHours && (<div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <SingleTimePresenter
                                            time={values.commonStart}
                                            onChange={v => {
                                                const currentEnd = getTodayTime(
                                                    values.commonEnd.getHours(),
                                                    values.commonEnd.getMinutes()
                                                );
                                                const candidateForStart = getTodayTime(v.getHours(), v.getMinutes());
                                                setFieldValue("commonStart", candidateForStart);
                                                setFieldValue(
                                                    "commonEnd",
                                                    candidateForStart >= currentEnd ? addMinutes(
                                                        candidateForStart,
                                                        15
                                                    ) : currentEnd
                                                )
                                            }}
                                        />
                                        <BaseSpaceSeparator size={12}/>
                                        <FontAwesomeIcon icon={faChevronRight} color={Colors.light_royal_blue}
                                                         style={{fontSize: 14}}/>
                                        <BaseSpaceSeparator size={12}/>
                                        <SingleTimePresenter
                                            time={values.commonEnd}
                                            onChange={v => {
                                                const currentStart = getTodayTime(
                                                    values.commonStart.getHours(),
                                                    values.commonStart.getHours()
                                                );
                                                const candidateForEnd = getTodayTime(v.getHours(), v.getMinutes());
                                                setFieldValue(
                                                    "commonStart",
                                                    candidateForEnd <= currentStart ? addMinutes(
                                                        candidateForEnd,
                                                        -15
                                                    ) : currentStart
                                                );
                                                setFieldValue("commonEnd", candidateForEnd)
                                            }}
                                        />
                                    </div>)}
                                {!values.sameHours && (<>
                                        {values.dayAvailability[1] && (<SingleDayTimeRangePresenter
                                                day={DayOfWeek.Monday}
                                                start={values.dayStarts[1]}
                                                end={values.dayEnds[1]}
                                                onStartChange={v => {
                                                    onSpecyficDayStartChanged(1, v);
                                                }}
                                                onEndChange={v => {
                                                    onSpecyficDayEndChanged(1, v);
                                                }}
                                            />)}
                                        {values.dayAvailability[1] && <BaseSpaceSeparator size={10}/>}
                                        {values.dayAvailability[2] && (<SingleDayTimeRangePresenter
                                                day={DayOfWeek.Tuesday}
                                                start={values.dayStarts[2]}
                                                end={values.dayEnds[2]}
                                                onStartChange={v => {
                                                    onSpecyficDayStartChanged(2, v);
                                                }}
                                                onEndChange={v => {
                                                    onSpecyficDayEndChanged(2, v);
                                                }}
                                            />)}
                                        {values.dayAvailability[2] && <BaseSpaceSeparator size={10}/>}
                                        {values.dayAvailability[3] && (<SingleDayTimeRangePresenter
                                                day={DayOfWeek.Wednesday}
                                                start={values.dayStarts[3]}
                                                end={values.dayEnds[3]}
                                                onStartChange={v => {
                                                    onSpecyficDayStartChanged(3, v);
                                                }}
                                                onEndChange={v => {
                                                    onSpecyficDayEndChanged(3, v);
                                                }}
                                            />)}
                                        {values.dayAvailability[3] && <BaseSpaceSeparator size={10}/>}
                                        {values.dayAvailability[4] && (<SingleDayTimeRangePresenter
                                                day={DayOfWeek.Thursday}
                                                start={values.dayStarts[4]}
                                                end={values.dayEnds[4]}
                                                onStartChange={v => {
                                                    onSpecyficDayStartChanged(4, v);
                                                }}
                                                onEndChange={v => {
                                                    onSpecyficDayEndChanged(4, v);
                                                }}
                                            />)}
                                        {values.dayAvailability[4] && <BaseSpaceSeparator size={10}/>}
                                        {values.dayAvailability[5] && (<SingleDayTimeRangePresenter
                                                day={DayOfWeek.Friday}
                                                start={values.dayStarts[5]}
                                                end={values.dayEnds[5]}
                                                onStartChange={v => {
                                                    onSpecyficDayStartChanged(5, v);
                                                }}
                                                onEndChange={v => {
                                                    onSpecyficDayEndChanged(5, v);
                                                }}
                                            />)}
                                        {values.dayAvailability[5] && <BaseSpaceSeparator size={10}/>}
                                        {values.dayAvailability[6] && (<SingleDayTimeRangePresenter
                                                day={DayOfWeek.Saturday}
                                                start={values.dayStarts[6]}
                                                end={values.dayEnds[6]}
                                                onStartChange={v => {
                                                    onSpecyficDayStartChanged(6, v);
                                                }}
                                                onEndChange={v => {
                                                    onSpecyficDayEndChanged(6, v);
                                                }}
                                            />)}
                                        {values.dayAvailability[6] && <BaseSpaceSeparator size={10}/>}
                                        {values.dayAvailability[0] && (<SingleDayTimeRangePresenter
                                                day={DayOfWeek.Sunday}
                                                start={values.dayStarts[0]}
                                                end={values.dayEnds[0]}
                                                onStartChange={v => {
                                                    onSpecyficDayStartChanged(0, v);
                                                }}
                                                onEndChange={v => {
                                                    onSpecyficDayEndChanged(0, v);
                                                }}
                                            />)}
                                    </>)}
                            </>)}
                        </>

                    )}

                </>)}
        </>);
}