import { ErrorCode, SortOrder } from "parkcash-api";
import React from "react";
import { Column } from "./Column";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";

export default function<T> (props:{
    disableSorting: boolean;
    sort: Array<{key: keyof T, order: SortOrder}>;
    columns: Array<Column<T>>;
    wrapperWidth: number;
    onColumnClicked: (column: Column<T>) => void;
    disablePaging: boolean; 
    availablePageSizes: number[]; 
    progress: boolean; 
    error: ErrorCode; 
    items: T[]; 
    pageSize: number; 
    page: number; 
    totalItems: number;
    onPageSizeChanged: (p: number) => void;
    onPageChanged: (p: number) => void;
    additionalRows: T[];
    AdditionalRowComponent: React.ComponentType<{item: T}>;
}) {
    const {
        wrapperWidth,
        availablePageSizes,
        columns,
        disableSorting,
        disablePaging,
        error,
        onPageChanged,
        items,
        onColumnClicked,
        onPageSizeChanged,
        page,
        pageSize,
        progress,
        sort,
        totalItems,
        additionalRows,
        AdditionalRowComponent
    } = props;
    return (
        <table cellSpacing={0} cellPadding={0} style={{width: wrapperWidth, borderCollapse: "collapse", border: "none"}}>
            <TableHeader 
                columns={columns}
                disableSorting={disableSorting}
                onColumnClicked={onColumnClicked}
                sort={sort}
                wrapperWidth={wrapperWidth}
            />
            <TableBody 
                availablePageSizes={availablePageSizes}
                columns={columns}
                disablePaging={disablePaging}
                error={error}
                onPageChanged={onPageChanged}
                onPageSizeChanged={onPageSizeChanged}
                items={items}
                page={page}
                pageSize={pageSize}
                progress={progress}
                totalItems={totalItems}    
                additionalRows={additionalRows}      
                AdditionalRowComponent={AdditionalRowComponent}         
            />
        </table>
    );
}

    