import {
  AddressDto,
  BuySubscriptionDto,
  ChangeSubscriptionPurchaseSpotDto,
  ErrorCode,
  ExtendSubscriptionPurchaseDto,
  GetActiveSubscriptionsResultEntryDto,
  GetParkingSubscriptionsResultEntryDto,
  GetSubscriptionProlongationInfoResult,
  nameof,
  ParkingSpotsAvailibilityEntry,
  PaymentMethod, PaymentsService,
  ProlongSubscriptionDto,
  RegisterExternalSubscriptionPurchaseDto,
  ServiceConfig,
  SmsVerificationsService,
  SpotSelectorType,
  StartSmsVerificationDto,
  SubscriptionPeriodType,
  SubscriptionsService,
} from "parkcash-api";
import {
  generateMonths,
  getDate,
  isSameDate,
  monthsMap,
} from "../utils/DateTimeUtils";
import {
  getClientPhoneNumber,
  getServerPhoneNumber,
} from "../utils/PhoneNumberUtils";
import CreateSubscriptionFormState, {
  CreateAdminSubscriptionFormState,
} from "./CreateSubscription/CreateSuscriptionFormState";
import InvoiceFormState, {
  InvoiceType,
} from "./formInterfaces/InvoiceFormState";
import ProlongSubscriptionFormState, {
  ProlongAdminSubscriptionFormState,
} from "./ProlongSubscription/ProlongSubscriptionFormState";
import * as yup from "yup";
import Resources from "../../Resources";
import {
  CURRENCY_REGEX,
  EMAIL_OR_EMPTY_REGEX,
  EMAIL_REGEX,
  NIP_REGEX,
  PHONE_OR_EMPTY_REGEX,
  PHONE_REGEX,
  POSTAL_CODE_REGEX,
} from "../utils/Constants";
import { getClientNipNumber, getSeverNipNumber } from "../utils/NIPNumberUtils";
import {getErrorMessage as getErrorMessageGlobal, getErrorMessageNew} from "../utils/ErrorUtils";
import {
  getAcceptRegulationsValidationSchema,
  getInvoiceData,
  getInvoiceValidationSchema,
  getTargetData,
  getTargetValidationSchema,
} from "./CommonUtils";
import { FilterOperator } from "../utils/DataSource";
import { getFilters } from "../utils/SieveUtils";
import { info } from "console";
import { parseFloatString } from "../utils/NumberUtils";
import ConfirmModal from "../components/ConfirmModal";
import memoizee from "memoizee";
import {PaymentMethodsTypes} from "./formParts/PaymentMethod";
import {Al} from "react-flags-select";

export const getInitialProlongSubscriptionFormState = (
  info: GetSubscriptionProlongationInfoResult
): ProlongSubscriptionFormState => {
  return {
    city: info?.invoiceData?.town || "",
    contrahent: info?.invoiceData?.name || "",
    flatNumber: info?.invoiceData?.apartmentNumber || "",
    nip: getClientNipNumber(info?.invoiceData?.nip) || "",
    postalCode: info?.invoiceData?.postalCode || "",
    streetName: info?.invoiceData?.streetName || "",
    streetNumber: info?.invoiceData?.streetNumber || "",
    type: info?.invoiceData?.isForNaturalPerson
      ? InvoiceType.PrivatePerson
      : InvoiceType.Company,
    wantInvoice: !!info?.invoiceData && !!info?.invoiceData?.name,
    email: info?.email,
    licencePlateNumber: info?.licencePlateNumber || "",
    nameAndSurname: info?.name || "",
    phoneNumber: getClientPhoneNumber(info?.phoneNumber) || "",
    discountCode: "",
    discountCodePrice: null,
    subscriptionId: info?.subscriptionId,
    licencePlateNumberRequired: info?.requireLicensePlateNumber,
    numberOfPeriods: 1,
    targetFrom: info?.prolongationValidFrom,
    targetTo: info?.prolongationValidTo,
  };
};

export const getInitialCreateSubscriptionFormState = (
  enableParticularSpotPurchase: boolean,
  spotSelectorType: SpotSelectorType,
  subscriptionId: string,
  licencePlateNumberRequired: boolean
): CreateSubscriptionFormState => {
  return {
    city: "",
    contrahent: "",
    flatNumber: "",
    nip: "",
    postalCode: "",
    streetName: "",
    streetNumber: "",
    type: InvoiceType.Company,
    wantInvoice: false,
    email: "",
    licencePlateNumber: "",
    nameAndSurname: "",
    phoneNumber: "",
    regulationsAccepted: false,
    spotId: null,
    from: new Date(),
    enableParticularSpotPurchase,
    spotSelectorType,
    subscriptionId,
    discountCode: "",
    discountCodePrice: null,
    licencePlateNumberRequired,
    numberOfPeriods: 1,
    targetFrom: null,
    targetTo: null,
  };
};

export const getProlongForAdminFormState = (
  info: GetSubscriptionProlongationInfoResult
): ProlongAdminSubscriptionFormState => {
  if (info) {
    console.log("info: ", info);
  }
  return {
    city: info?.invoiceData?.town || "",
    subscriptionId: info?.subscriptionId || null,
    nameAndSurname: info?.name || "",
    contrahent: info?.invoiceData?.name || "",
    email: info?.email || "",
    phoneNumber: getClientPhoneNumber(info?.phoneNumber) || "",
    licencePlateNumber: info?.licencePlateNumber || "",
    wantInvoice: !!info?.invoiceData && !!info?.invoiceData?.name,
    nip: getClientNipNumber(info?.invoiceData?.nip) || "",
    postalCode: info?.invoiceData?.postalCode || "",
    flatNumber: info?.invoiceData?.apartmentNumber || "",
    streetName: info?.invoiceData?.streetName || "",
    streetNumber: info?.invoiceData?.streetNumber || "",
    type: info?.invoiceData?.isForNaturalPerson
      ? InvoiceType.PrivatePerson
      : InvoiceType.Company,
    currentPrice: null,
    discountCode: "",
    discountCodePrice: null,
    promotionCodeProgress: false,
    externalPaymentDocumentNumber: "",
    licencePlateNumberRequired: info?.requireLicensePlateNumber,
    numberOfPeriods: 1,
    targetFrom: info?.prolongationValidFrom,
    targetTo: info?.prolongationValidTo,
    customPrice: info?.price?.toString() || "",
    paymentMethod: info?.paymentMethod || PaymentMethod.CreditCard,
  };
};

export const getBuyForAdminFormState = (
  enableParticularSpotPurchase: boolean,
  spotSelectorType: SpotSelectorType,
  subscriptionId: string,
  licencePlateNumberRequired: boolean,
  spotId: string = null
): CreateAdminSubscriptionFormState => {
  return {
    city: "",
    contrahent: "",
    flatNumber: "",
    nip: "",
    postalCode: "",
    streetName: "",
    streetNumber: "",
    type: InvoiceType.Company,
    wantInvoice: false,
    email: "",
    licencePlateNumber: "",
    nameAndSurname: "",
    phoneNumber: "+48",
    spotId,
    from: new Date(),
    enableParticularSpotPurchase,
    spotSelectorType,
    subscriptionId,
    discountCode: "",
    discountCodePrice: null,
    currentPrice: null,
    promotionCodeProgress: false,
    externalPaymentDocumentNumber: "",
    licencePlateNumberRequired,
    targetFrom: null,
    numberOfPeriods: 1,
    targetTo: null,
    customPrice: "",
    paymentMethod: PaymentMethod.Cash,
  };
};

export const getBuySubscriptionDto = (
  state: CreateSubscriptionFormState,
  blikCode:string
): BuySubscriptionDto => {
  const {
    enableParticularSpotPurchase,
    from,
    spotId,
    subscriptionId,
    discountCode,
    numberOfPeriods,
  } = state;
  const dto = new BuySubscriptionDto({
    ...getTargetData(state),

    subscriptionId,
    customStart: isSameDate(new Date(), from) ? undefined : getDate(from),
    selectedParkingSpotId: enableParticularSpotPurchase ? spotId : undefined,
    invoiceData: getInvoiceData(state),
    discountCode: discountCode || undefined,
    numberOfPeriods: numberOfPeriods || undefined,
    blikCode:blikCode
  });

  return dto;
};

const getDTOForAdminBuy = (
  state: CreateAdminSubscriptionFormState
): RegisterExternalSubscriptionPurchaseDto => {
  const {
    from,
    subscriptionId,
    enableParticularSpotPurchase,
    spotId,
    externalPaymentDocumentNumber,
    wantInvoice,
    customPrice: price,
    numberOfPeriods,
    paymentMethod,
  } = state;

  return new RegisterExternalSubscriptionPurchaseDto({
    ...getTargetData(state),

    customStart: isSameDate(new Date(), from) ? undefined : getDate(from),
    invoiceData: getInvoiceData(state),
    subscriptionId,

    selectedParkingSpotId: enableParticularSpotPurchase ? spotId : undefined,
    externalPaymentDocumentNumber: wantInvoice
      ? undefined
      : externalPaymentDocumentNumber || undefined,
    customPrice: parseFloatString(price, undefined),
    numberOfPeriods: numberOfPeriods || 1,
    paymentMethod,
  });
};

const getDTOForAdminProlong = (
  state: ProlongAdminSubscriptionFormState,
  purchaseId: string
): RegisterExternalSubscriptionPurchaseDto => {
  const {
    subscriptionId,
    externalPaymentDocumentNumber,
    wantInvoice,
    customPrice,
    numberOfPeriods,
    paymentMethod,
  } = state;

  return new RegisterExternalSubscriptionPurchaseDto({
    ...getTargetData(state),
    invoiceData: getInvoiceData(state),
    subscriptionId,
    previousPurchaseId: purchaseId,
    externalPaymentDocumentNumber: wantInvoice
      ? undefined
      : externalPaymentDocumentNumber || undefined,
    customPrice: parseFloatString(customPrice, undefined),
    numberOfPeriods: numberOfPeriods || 1,
    paymentMethod,
  });
};

export const getProlongSubscriptionValidationSchema = () => {
  return yup.object({
    ...getInvoiceValidationSchema(),
    ...getTargetValidationSchema(),
    ...getAcceptRegulationsValidationSchema(),
    licencePlateNumber: yup
      .string()
      .notRequired()
      .nullable()
      .when("licencePlateNumberRequired", {
        is: true,
        then: yup.string().required(Resources.Wymagane),
      }),
    email: yup
      .string()
      .nullable()
      .matches(EMAIL_OR_EMPTY_REGEX, Resources.Nieprawidlowy_format)
      .when("phoneNumber", {
        is: (phone) => !phone || phone.length,
        then: yup
          .string()
          .matches(EMAIL_REGEX, Resources.Nieprawidlowy_format)
          .required(Resources.Wymagane_jest_podanie_emaila_lub_telefonu),
      }),
    phoneNumber: yup
      .string()
      .nullable()
      .matches(PHONE_OR_EMPTY_REGEX, Resources.Nieprawidlowy_format),
  });
};

export const getCreateSubscriptionValidationSchema = () => {
  return yup.object({
    ...getInvoiceValidationSchema(),
    ...getTargetValidationSchema(),
    ...getAcceptRegulationsValidationSchema(),
    spotId: yup
      .string()
      .notRequired()
      .nullable()
      .when("enableParticularSpotPurchase", {
        is: true,
        then: yup.string().nullable().required(Resources.Wymagane),
      }),
    from: yup.date().required(Resources.Wymagane),
    subscriptionId: yup.string().required(Resources.Wymagane),
    licencePlateNumber: yup
      .string()
      .notRequired()
      .nullable()
      .when("licencePlateNumberRequired", {
        is: true,
        then: yup.string().required(Resources.Wymagane),
      }),
    email: yup
      .string()
      .nullable()
      .matches(EMAIL_OR_EMPTY_REGEX, Resources.Nieprawidlowy_format)
      .when("phoneNumber", {
        is: (phone) => !phone || phone.length,
        then: yup
          .string()
          .matches(EMAIL_REGEX, Resources.Nieprawidlowy_format)
          .required(Resources.Wymagane_jest_podanie_emaila_lub_telefonu),
      }),
    phoneNumber: yup
      .string()
      .nullable()
      .matches(PHONE_OR_EMPTY_REGEX, Resources.Nieprawidlowy_format),
  });
};

export const getBuyForAdminFormValidationSchema = () => {
  return yup.object({
    ...getInvoiceValidationSchema(),
    ...getTargetValidationSchema(),
    spotId: yup
      .string()
      .notRequired()
      .nullable()
      .when("enableParticularSpotPurchase", {
        is: true,
        then: yup.string().nullable().required(Resources.Wymagane),
      }),
    from: yup.date().required(Resources.Wymagane),
    customPrice: yup
      .string()
      .required(Resources.Wymagane)
      .matches(CURRENCY_REGEX, Resources.Nieprawidlowy_format),
    subscriptionId: yup.string().required(Resources.Wymagane),
    externalPaymentDocumentNumber: yup.string().notRequired().nullable(),
    email: yup
      .string()
      .nullable()
      .matches(EMAIL_OR_EMPTY_REGEX, Resources.Nieprawidlowy_format),
    phoneNumber: yup.string().nullable(),
    licencePlateNumber: yup
      .string()
      .notRequired()
      .nullable()
      .when("licencePlateNumberRequired", {
        is: true,
        then: yup.string().required(Resources.Wymagane),
      }),
    paymentMethod: yup.number().required(Resources.Wymagane),
  });
};

export const getProlongForAdminFormValidationSchema = () => {
  return yup.object({
    ...getInvoiceValidationSchema(),
    ...getTargetValidationSchema(),
    externalPaymentDocumentNumber: yup.string().notRequired().nullable(),
    email: yup
      .string()
      .nullable()
      .matches(EMAIL_OR_EMPTY_REGEX, Resources.Nieprawidlowy_format),
    customPrice: yup
      .string()
      .required(Resources.Wymagane)
      .matches(CURRENCY_REGEX, Resources.Nieprawidlowy_format),
    phoneNumber: yup
      .string()
      .nullable()
      .matches(PHONE_OR_EMPTY_REGEX, Resources.Nieprawidlowy_format),
    licencePlateNumber: yup
      .string()
      .notRequired()
      .nullable()
      .when("licencePlateNumberRequired", {
        is: true,
        then: yup.string().required(Resources.Wymagane),
      }),
    paymentMethod: yup.number().required(Resources.Wymagane),
  });
};

export const getParkingLogoUrlByPurchaseId = async (purchaseId: string) => {
  const { error, info } = await getSubscriptionProlongationInfo(purchaseId, 1);
  if (error) {
    return null;
  } else {
    return info.customLogoUrl;
  }
};

export const getParkingLogoUrl = async (parkingId: string) => {
  try {
    const subscriptionsResponse = await new SubscriptionsService(
      new ServiceConfig()
    ).getParkingSubscriptions(
      parkingId,
      "",
      `${nameof<GetParkingSubscriptionsResultEntryDto>("orderIndex")}`,
      1,
      100000
    );
    if (subscriptionsResponse.isSuccess) {
      return subscriptionsResponse.result.customLogoUrl;
    } else {
      return null;
    }
  } catch {
    return null;
  }
};

export const getSubscriptions = async (
  parkingId: string
): Promise<{
  errorCode?: ErrorCode;
  subscriptions?: GetParkingSubscriptionsResultEntryDto[];
  enableParticularSpotPurchase?: boolean;
  spotSelectorType?: SpotSelectorType;
  generalParkingRegulationUrl?: string;
  privacyPolicyRegulationUrl?: string;
  salesRegulationUrl?: string;
  requireLicensePlateNumber?: boolean;
  parkingAddress?: AddressDto;
}> => {
  try {
    const subscriptionsResponse = await new SubscriptionsService(
      new ServiceConfig()
    ).getParkingSubscriptions(
      parkingId,
      "",
      `${nameof<GetParkingSubscriptionsResultEntryDto>("orderIndex")}`,
      1,
      100000
    );
    if (subscriptionsResponse.isSuccess) {
      const {
        requireLicensePlateNumber,
        enableParticularSpotPurchase,
        spotSelectorType,
        entries,
        generalParkingRegulationUrl,
        privacyPolicyRegulationUrl,
        salesRegulationUrl,
        address,
      } = subscriptionsResponse.result;
      return {
        requireLicensePlateNumber,
        enableParticularSpotPurchase,
        spotSelectorType,
        subscriptions: entries,
        generalParkingRegulationUrl,
        privacyPolicyRegulationUrl,
        salesRegulationUrl,
        parkingAddress: address,
      };
    } else {
      return { errorCode: subscriptionsResponse.error.code };
    }
  } catch {
    return { errorCode: ErrorCode.NetworkError };
  }
};

export const buySubscription = async (
  state: CreateSubscriptionFormState,
  blikCode:string
): Promise<{
  isBlikError?:boolean
  errorMessage?: string;
  paymentUrl?: string;
}> => {
  try {
    const dto = getBuySubscriptionDto(state, blikCode);
    const response = await new SubscriptionsService(
      new ServiceConfig()
    ).buySubscription(dto);
    if (response.isSuccess) {
      const { webPaymentUrl } = response.result;
      return { paymentUrl: webPaymentUrl };
    } else {
      
      return {
        isBlikError: response.error.code === ErrorCode.BlikError,
        errorMessage: getErrorMessageNew(response.error)
      };
    }
  } catch {
    return { errorMessage: getErrorMessage() };
  }
};

export const prolongSubscription = async (state: ProlongSubscriptionFormState, purchaseId: string, blikCode: string) => {
  try {
    const { discountCode, numberOfPeriods } = state;
    const response = await new SubscriptionsService(
      new ServiceConfig()
    ).prolongSubscription(
      new ProlongSubscriptionDto({
        ...getTargetData(state),

        discountCode: discountCode || undefined,
        subscriptionPurchaseId: purchaseId,
        invoiceData: getInvoiceData(state),
        numberOfPeriods: numberOfPeriods || undefined,
        blikCode: blikCode
      })
    );
    if (response.isSuccess) {
      const { webPaymentUrl } = response.result;
      return { paymentUrl: webPaymentUrl };
    } else {
      return {
        isBlikError: response.error.code === ErrorCode.BlikError,
        message: getErrorMessageNew(response.error)
      };
    }
  } catch {
    return { message: getErrorMessage() };
  }
};

export const buyAdminSubscription = async (
  state: CreateAdminSubscriptionFormState,
  jwt: string
) => {
  try {
    const dto = getDTOForAdminBuy(state);
    const response = await new SubscriptionsService(
      new ServiceConfig({ jwt })
    ).registerExternalSubscriptionPurchase(dto);
    if (response.isSuccess) {
      return { result: true };
    } else {
      return { message: getErrorMessage(response.error.code), result: false };
    }
  } catch {
    return { message: getErrorMessage(ErrorCode.NetworkError), result: false };
  }
};

export const prolongAdminSubscription = async (
  state: ProlongAdminSubscriptionFormState,
  purchaseId: string,
  jwt: string
) => {
  try {
    const response = await new SubscriptionsService(
      new ServiceConfig({ jwt })
    ).registerExternalSubscriptionPurchase(
      getDTOForAdminProlong(state, purchaseId)
    );
    if (response.isSuccess) {
      return { result: true };
    } else {
      return { message: getErrorMessage(response.error.code), result: false };
    }
  } catch {
    return { message: getErrorMessage(ErrorCode.NetworkError), result: false };
  }
};

export const getSubscriptionEffectivePrice = async (
  subscriptionId: string,
  code: string,
  parkingSpotId: string,
  numberOfPeriods: number
): Promise<{
  message?: string;
  discountedPrice?: number;
  price?: number;
}> => {
  try {
    const checkResponse = await new SubscriptionsService(
      new ServiceConfig()
    ).getSubscriptionEffectivePrice(
      subscriptionId,
      code || undefined,
      parkingSpotId || undefined,
      numberOfPeriods
    );
    if (checkResponse.isSuccess) {
      const { discountedPrice, price } = checkResponse.result;
      return { discountedPrice, price };
    } else {
      return { message: getErrorMessage(checkResponse.error.code) };
    }
  } catch {
    return { message: getErrorMessage() };
  }
};

export const getActiveSubscriptionsCount = async (
  phone: string,
  parkingId: string
): Promise<{ count?: number; message?: string }> => {
  try {
    const response = await new SubscriptionsService(
      new ServiceConfig()
    ).getActiveSubscriptionsCount(getServerPhoneNumber(phone), parkingId);
    if (response.isSuccess) {
      return { count: response.result.activeSubscriptionsCount };
    } else {
      return { message: getErrorMessage(response.error.code) };
    }
  } catch {
    return { message: getErrorMessage() };
  }
};

export const startSMSVerification = async (
  phone: string,
  parkingId: string
): Promise<{ codeId?: string; message?: string }> => {
  try {
    const { count, message } = await getActiveSubscriptionsCount(
      phone,
      parkingId
    );
    if (message) {
      return { message };
    } else if (!count) {
      return {
        message:
          Resources.Do_podanego_numeru_telefonu_nie_ma_przypisanego_zadnego_abonamentu,
      };
    }

    const response = await new SmsVerificationsService(
      new ServiceConfig()
    ).startSmsVerification(
      new StartSmsVerificationDto({
        targetPhoneNumber: getServerPhoneNumber(phone),
      })
    );
    if (response.isSuccess) {
      const { id } = response.result;
      return { codeId: id };
    } else {
      return { message: getErrorMessage(response.error.code) };
    }
  } catch {
    return { message: getErrorMessage() };
  }
};

export const sendSMSCode = async (
  parkingId: string,
  code: string,
  codeId: string
): Promise<{
  message?: string;
  subscriptions?: GetActiveSubscriptionsResultEntryDto[];
}> => {
  try {
    const response = await new SubscriptionsService(
      new ServiceConfig()
    ).getActiveSubscriptions(parkingId, codeId, code, "", "", 1, 10000);
    if (response.isSuccess) {
      const { entries } = response.result;
      return { subscriptions: entries };
    } else {
      return { message: getErrorMessage(response.error.code) };
    }
  } catch {
    return { message: getErrorMessage() };
  }
};

export const getSubscriptionProlongationInfo = async (
  purchaseId: string,
  numberOfPeriods: number
): Promise<{info?: GetSubscriptionProlongationInfoResult, error?:ErrorCode }> => {
  try {
    const response = await new SubscriptionsService(
      new ServiceConfig()
    ).getSubscriptionProlongationInfo(purchaseId, numberOfPeriods);
    if (response.isSuccess) {
      return { info: response.result };
    } else {
      return { error: response.error.code };
    }
  } catch (e) {
    console.log("error: ", JSON.stringify(e));
    return { error: ErrorCode.NetworkError };
  }
};

const getErrorMessage = (error: ErrorCode = null) => {
  if (error === ErrorCode.SubscriptionDiscountCodeNotFound) {
    return Resources.Nieprawidlowy_kod_promocyjny;
  } else if (error === ErrorCode.SubscriptionIsNotActive) {
    return Resources.Administrator_parkingu_wylaczyl_abonament;
  } else if (error === ErrorCode.SubscriptionDiscountCodeNotActive) {
    return Resources.Kod_promocyjny_zostal_wylaczony_przez_administratora_parkingu;
  } else if (error === ErrorCode.MultiplePromotionsUse) {
    return Resources.Nie_mozna_skorzystac_z_wielu_promocji_na_raz;
  } else if (error === ErrorCode.SubscriptionDiscountCodeExpired) {
    return Resources.Podany_kod_promocyjny_wygasl;
  } else if (error === ErrorCode.SmsVerificationAllowedFrequencyExceeded) {
    return Resources.Zbyt_duzo_prob_weryfikacji_numeru_Sprobuj_za_kilka_minut;
  } else if (error === ErrorCode.SmsVerificationCodeAlreadyUsed) {
    return Resources.Podany_kod_weryfikacyjny_zostal_juz_uzyty;
  } else if (error === ErrorCode.SmsVerificationCodeInvalid) {
    return Resources.Nieprawidlowy_kod_weryfikacyjny;
  } else if (error === ErrorCode.SmsVerificationCodeExpired) {
    return Resources.Nieprawidlowy_kod_weryfikacyjny;
  } else if (error === ErrorCode.SubscriptionProlongationLinkAlreadyUsed) {
    return Resources.Link_do_przedluzenia_zostal_juz_wykorzystany;
  } else if (error === ErrorCode.SubscriptionDiscountCodeAlreadyUsed) {
    return Resources.Podany_kod_promocyjny_zostal_juz_uzyty;
  } else {
    return getErrorMessageGlobal(error);
  }
};

export const changeSubscriptionParkingSpot = async (
  purchaseId: string,
  spotId: string,
  jwt: string
) => {
  try {
    const response = await new SubscriptionsService(
      new ServiceConfig({ jwt })
    ).changeSubscriptionPurchaseSpot(
      new ChangeSubscriptionPurchaseSpotDto({
        parkingSpotId: spotId,
        purchaseId,
      })
    );

    if (response.isSuccess) {
      return { result: true };
    } else {
      return { result: false, message: getErrorMessage(response.error.code) };
    }
  } catch {
    return { result: false, message: getErrorMessage(ErrorCode.NetworkError) };
  }
};

export const extendSubscriptionPurchase = async (
  purchaseId: string,
  validTo: Date,
  jwt: string
) => {
  try {
    const response = await new SubscriptionsService(
      new ServiceConfig({ jwt })
    ).extendSubscriptionPurchase(
      new ExtendSubscriptionPurchaseDto({
        purchaseId,
        validTo,
      })
    );
    if (response.isSuccess) {
      return { result: true };
    } else {
      return { result: false, message: getErrorMessage(response.error.code) };
    }
  } catch {
    return { result: false, message: getErrorMessage(ErrorCode.NetworkError) };
  }
};

export const getAvailableSpotsForSubscription = async (
  parkingId: string,
  subscriptionId: string,
  numberOfPeriods: number,
  since: Date = new Date(),
  level: string = null,
  sector: string = null
) => {
  try {
    const filter: Array<{
      key: keyof ParkingSpotsAvailibilityEntry;
      operator: FilterOperator;
      value: any;
    }> = [];
    if (level) {
      filter.push({
        key: "level",
        operator: FilterOperator.EQUALS,
        value: level,
      });
    }
    if (sector) {
      filter.push({
        key: "sector",
        operator: FilterOperator.EQUALS,
        value: sector,
      });
    }
    const response = await new SubscriptionsService(
      new ServiceConfig()
    ).getSubscriptionParkingAvailableSpots(
      parkingId,
      subscriptionId,
      numberOfPeriods,
      since,
      getFilters(filter),
      "",
      1,
      10000
    );
    if (response.isSuccess) {
      const { availableSpots } = response.result;
      if (!availableSpots.length) {
        ConfirmModal.show({
          confirmText: "OK",
          text: Resources.Niestety_ale_nie_ma_juz_zadnych_dostepnych_miejsc,
        });
      }
      return { spots: availableSpots };
    } else {
      return { error: response.error.code };
    }
  } catch {
    return { error: ErrorCode.NetworkError };
  }
};

export const getTimeRangeForSubscription = async (
  subscriptionId: string,
  numberOfPeriods: number,
  since: Date = new Date()
) => {
  try {
    const { error, isSuccess, result } = await new SubscriptionsService(
      new ServiceConfig()
    ).getSubscriptionRange(subscriptionId, numberOfPeriods, since);
    if (isSuccess) {
      return { from: result.validFrom, to: result.validTo };
    } else {
      return { error: error.code };
    }
  } catch {
    return { error: ErrorCode.NetworkError };
  }
};

export const getTimeRangeForSubcriptionProlongation = async (
  purchaseId: string,
  numberOfPeriods: number
) => {
  const { error, info } = await getSubscriptionProlongationInfo(
    purchaseId,
    numberOfPeriods
  );
  if (error) {
    return { error };
  } else {
    return { from: info.prolongationValidFrom, to: info.prolongationValidTo };
  }
};

export const getAvailableNumberOfPeriods = memoizee(
  (
    periodType: SubscriptionPeriodType,
    duration: number,
    maxPeriods: number = 10
  ) => {
    const result: { id: number; text: string }[] = [];
    for (let i = 1; i <= maxPeriods; i++) {
      const dur =
        periodType === SubscriptionPeriodType.NumberOfDays ? duration * i : i;
      const t =
        periodType === SubscriptionPeriodType.NumberOfDays && dur === 1
          ? Resources.dzien
          : periodType === SubscriptionPeriodType.NumberOfDays
          ? Resources.dni
          : dur === 1
          ? Resources.miesiac
          : Resources.miesiace;

      result.push({ id: i, text: `${dur} ${t}` });
    }
    return result;
  }
);

export const getMonths = memoizee(() => {
  return generateMonths(24).map((m) => ({
    id: m,
    text: `${monthsMap()[m.getMonth()]} ${m.getFullYear()}`,
  }));
});


