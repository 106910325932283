import * as React from "react";
import Colors from "../../styles/Colors";
import { PCText, SOURCESANSPRO_REGULAR } from "../../styles/Texts";

type NativeTextAreaProps = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

export interface TextAreaProps extends NativeTextAreaProps {
    isInvalid?: boolean;
    label?: string;
    borderColor?: string;
    onChangeText?: (value: string) => void;
}

interface State {
    focused: boolean;
}

export default class TextArea extends React.Component<TextAreaProps, State> {

    private textArea: HTMLTextAreaElement = null;

    constructor(props){
        super(props);
        this.state = {
            focused: false
        }
    }

    focus = () => {
        this.textArea?.focus();
    }

    blur = () => {
        this.textArea?.blur();
    }

    render(){
        const {
            onChangeText, 
            isInvalid, 
            onBlur, 
            onFocus, 
            label, 
            onChange, 
            style,
            value,            
            borderColor,
            ...rest
        } = this.props;
        const {focused} = this.state;

        const mainColor = isInvalid ? Colors.red : focused ? Colors.heliotrope : Colors.brownish_grey;

        const textAreaProps: NativeTextAreaProps = {
            style: {
                height: '100%', 
                width: '100%', 
                backgroundColor: 'transparent', 
                outline: "none", 
                padding: 0, 
                margin:0, 
                borderWidth: 0, 
                color: Colors.brownish_grey, 
                fontFamily: SOURCESANSPRO_REGULAR, 
                letterSpacing: 0, 
                lineHeight: 1.5, 
                fontSize: 16,
                resize: "none",
                ...style
            },
            onBlur: e => {
                onBlur && onBlur(e);
                this.setState({focused: false});
            },
            onFocus: e => {
                onFocus && onFocus(e);
                this.setState({focused: true});
            },
            value,
            onChange: e => {
                onChangeText && onChangeText(e.target.value);
            },
            ...rest
        }

        return (
            <div
                style={{backgroundColor: "transparent", paddingTop: !!label ? 10 : 0, position: 'relative'}}
            >
                {!!label && (
                    <div
                        style={{
                            backgroundColor: Colors.white, 
                            paddingLeft: 10, 
                            paddingRight: 10,
                            position: 'absolute',
                            top: 0,
                            left: 32,
                            display: 'inline-block',
                        }}

                    >
                        <PCText color={mainColor} fontSize={12} lineHeight={1.5} letterSpacing={0}>
                            {label}
                        </PCText>
                    </div>
                )}

                <div
                    style={{
                        borderColor: borderColor || mainColor,
                        borderWidth: 1,
                        borderRadius: 27.5,
                        borderStyle: "solid",
                        display: 'flex',
                        padding: 20,
                        backgroundColor: Colors.white,
                        cursor: "text",
                        width: '100%',
                        boxSizing: "border-box",
                        height: 150
                    }}
                    onClick={this.focus}
                >
                    <textarea 
                        ref={i => {
                            if(i){
                                this.textArea = i;
                            }
                        }}
                        {...textAreaProps}
                    />
                </div>
            </div>
        )
    }

}