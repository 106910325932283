import * as React from "react";
import { useLocation, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { applicationState } from "../redux/ApplicationState";
import { logout } from "../user/Redux";

interface LocationState {
    from: string;
}

interface Props {
    onInit: () => void;
}

const Logout = (props: Props) => {
    const {onInit} = props;
    const {state} = useLocation<LocationState>();

    React.useEffect(onInit, []);

    return (
        <Redirect
            to={{
                pathname: "/login",
                state: {
                    returnRoute: state?.from
                }
            }} 
        />
    );
}

const mapStateToProps = (state: applicationState): Partial<Props> => ({

});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
    onInit: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);