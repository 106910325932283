import Enumerable from "linq";
import * as React from "react";
import { PCDatePickerDotDefinition } from "../../components/PCDatetimePicker/PCDatePickerContent";
import memoized from "memoized-class-decorator";
import Resources from "../../../Resources";
import { addHours } from "../../utils/DateTimeUtils";
import {
  ParkingSpotTimelineDayInfo,
  ParkingSpotTimelineDaySegmentInfo,
  TimelineMemberCategory,
} from "parkcash-api";

export interface BigCalendarEvent {
  id: number;
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
  resource: ParkingSpotTimelineDaySegmentInfo;
  type: TimelineMemberCategory;
}

export default class Timeline {
  constructor(private readonly preview: ParkingSpotTimelineDayInfo[]) {}

  @memoized
  getDots(): { [key: string]: PCDatePickerDotDefinition } {
    const result: { [key: string]: PCDatePickerDotDefinition } = {};
    (this.preview || []).forEach((info) => {
      const { date, segments } = info;
      const key = date.toDateString();
      const hasReservation = segments.some(
        (s) =>
          s.category === TimelineMemberCategory.Reservation ||
          s.category === TimelineMemberCategory.Subscription ||
          s.category === TimelineMemberCategory.ExternalReservation
      );
      const hasSharing = segments.some(
        (s) => s.category === TimelineMemberCategory.Free
      );

      result[key] = {
        blue: false,
        red: hasReservation,
        gray: hasSharing,
      };
    });
    return result;
  }

  @memoized
  getAllItemsInDay(day: Date) {
    const dayString = day.toDateString();
    const daySegments =
      Enumerable.from(this.preview || []).firstOrDefault(
        (d) => d.date.toDateString() === dayString
      )?.segments || [];
    return daySegments.filter(
      (s) =>
        s.category === TimelineMemberCategory.Reservation ||
        s.category === TimelineMemberCategory.Free ||
        s.category === TimelineMemberCategory.Subscription ||
        s.category === TimelineMemberCategory.ExternalReservation ||
        s.category === TimelineMemberCategory.TechnicalBuffer
    );
  }

  @memoized
  getReservationsInDay(day: Date) {
    const dayString = day.toDateString();
    const daySegments =
      Enumerable.from(this.preview || []).firstOrDefault(
        (d) => d.date.toDateString() === dayString
      )?.segments || [];
    return daySegments.filter(
      (s) => s.category === TimelineMemberCategory.Reservation
    );
  }

  @memoized
  getBigCalendarEvents(): Array<BigCalendarEvent> {
    let id = 1;
    const result: Array<BigCalendarEvent> = [];
    this.preview.forEach((previewItem) => {
      previewItem.segments.forEach((segment) => {
        const { startTimestampDisplay, endTimestampDisplay, category } =
          segment;
        if (
          category !== TimelineMemberCategory.OccupiedByOwner &&
          category !== TimelineMemberCategory.Placeholder
        ) {
          result.push({
            id: id++,
            resource: segment,
            start: startTimestampDisplay,
            allDay: false,
            end: endTimestampDisplay,
            title: getTitle(category)?.toUpperCase(),
            type: category,
          });
        }
      });
    });

    return result;
  }
}

const getTitle = (category: TimelineMemberCategory) => {
  if (category === TimelineMemberCategory.Free) {
    return Resources.Udostepnione;
  } else if (category === TimelineMemberCategory.Reservation) {
    return Resources.Rezerwacja;
  } else if (category === TimelineMemberCategory.TechnicalBuffer) {
    return Resources.Blokada_miejsca;
  } else if (category === TimelineMemberCategory.ExternalReservation) {
    return Resources.Zajete;
  } else if (category === TimelineMemberCategory.Subscription) {
    return Resources.Abonament;
  }
};
