import {
  GetParkingProcessesViewResultEntryDto,
  ParkingManagerService,
  ServiceConfig,
  SortOrder,
} from "parkcash-api";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Resources from "../../Resources";
import { getReservationStatusText } from "../bookings/ReservationStatusPresenter";
import ErrorView from "../components/ErrorView";
import Spinner from "../components/Spinner";
import { getTypeText } from "../parkingManagement/ParkingProcesses/Utils";
import { FilterOperator } from "../utils/DataSource";
import { formatDate, formatTime } from "../utils/DateTimeUtils";
import { getFilters, getSorts } from "../utils/SieveUtils";
import "./SpotHistory.scss";

const Report = ({
  entries,
}: {
  entries: GetParkingProcessesViewResultEntryDto[];
}) => {
  return (
    <table className="spot-history">
      <tr>
        <th>{Resources.Typ}</th>
        <th>{Resources.Nazwa}</th>
        <th>{Resources.Nr_dokumentu}</th>
        <th>{Resources.Kwota}</th>
        <th>{Resources.Data_wplaty}</th>
        <th>{Resources.Poczatek}</th>
        <th>{Resources.Koniec}</th>
        <th>{Resources.Status}</th>
      </tr>
      {entries.map(
        (
          {
            effectiveTotalPrice,
            documentNumber,
            targetName,
            purchaseDate,
            start,
            end,
            type,
            status,
          },
          index
        ) => (
          <tr key={index}>
            <td>{getTypeText(type)}</td>
            <td>{targetName}</td>
            <td>{documentNumber}</td>
            <td>
              {effectiveTotalPrice
                ? `${effectiveTotalPrice.toFixed(2)} ${Resources.zl}`
                : "-"}
            </td>
            <td>
              {formatTime(purchaseDate)}
              <br />
              {formatDate(purchaseDate)}
            </td>
            <td>
              {formatTime(start)}
              <br />
              {formatDate(start)}
            </td>
            <td>
              {formatTime(end)}
              <br />
              {formatDate(end)}
            </td>
            <td>{getReservationStatusText(status)}</td>
          </tr>
        )
      )}
    </table>
  );
};

const SpotHistory = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [entries, setEntries] = useState<
    GetParkingProcessesViewResultEntryDto[]
  >([]);
  const jwt = localStorage.token;
  const parkingId = localStorage.parkingId;
  const { spotId } = useParams<{ spotId: string }>();

  useEffect(() => {
    const init = async () => {
      try {
        const filter: Array<{
          key: keyof GetParkingProcessesViewResultEntryDto;
          operator: FilterOperator;
          value: any;
        }> = [
          {
            key: "parkingSpotId",
            operator: FilterOperator.EQUALS,
            value: spotId,
          },
        ];
        const sort: Array<{
          key: keyof GetParkingProcessesViewResultEntryDto;
          order: SortOrder;
        }> = [{ key: "start", order: SortOrder.Descending }];
        const { isSuccess, result, error } = await new ParkingManagerService(
          new ServiceConfig({ jwt })
        ).getParkingProcessesView(
          parkingId,
          getFilters(filter),
          getSorts(sort),
          1,
          1000
        );
        if (isSuccess) {
          setEntries(result.entries);
        } else {
          setIsError(true);
        }
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    init();
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && isError && <ErrorView title={Resources.Nastapil_blad} />}
      {!isLoading && !isError && <Report entries={entries} />}
    </>
  );
};

export { SpotHistory };
