import { ErrorCode, ParkingSpotRatingResultDto, ParkingSpotsService, RatingsSortType, ServiceConfig, SortOrder } from "parkcash-api";
import * as React from "react";
import Resources from "../../Resources";
import Body from "../layouts/Main/Body";
import PCDataGrid from "../components/PCDataGrid";
import { useParams } from "react-router";
import { CustomDataSource } from "../utils/DataSource";
import { useJWT } from "../utils/JWTUtils";

export default () => {
    const {spotId} = useParams<{spotId: string}>();

    const [dataSource] = React.useState(new CustomDataSource({
        load: async (loadOptions) => {
            try{
                const {page, pageSize} = loadOptions;
                const jwt = useJWT();
                const {isSuccess, error, result} = await new ParkingSpotsService(new ServiceConfig({jwt: jwt})).getParkingSpotRatings(
                    spotId,
                    SortOrder.Descending,
                    RatingsSortType.ByDate,
                    (page - 1)*pageSize,
                    pageSize,
                    0
                );
                return isSuccess ? {items: result.ratings, totalItems: result.paginationInfo.totalElementsCount} : error.code;
            }
            catch{
                return ErrorCode.NetworkError;
            }
        }
    }))

    return (
        <Body title={Resources.Oceny}>
            <PCDataGrid<ParkingSpotRatingResultDto>
                dataSource={dataSource}
                columns={[
                    {
                        dataField: "createdTimestamp",
                        label: Resources.Data_wystawienia,
                        width: 1
                    },
                    {
                        dataField: "averageRating",
                        label: Resources.Ocena,
                        width: 1
                    },
                    {
                        dataField: "comment",
                        label: Resources.Komentarz,
                        width: 3
                    }
                ]}
                disableSorting
            >
            </PCDataGrid>
        </Body>
    )
}

