import { ErrorCode } from "parkcash-api";
import * as React from "react";
import {render, unmountComponentAtNode} from "react-dom";
import Resources from "../../../Resources";
import { OtherPagesLayout } from "../../layouts";
import Body from "../../layouts/Main/Body";
import Colors from "../../styles/Colors";
import { getErrorMessage } from "../../utils/ErrorUtils";
import ErrorView from "../ErrorView";
import { getParkingMap, ParkingMapDataResponseResult, ParkingMapSpotInfo } from "../ParkingMap/Data";
import Spinner from "../Spinner";
import SVGMap from "./SVGMap";

const modal = document.getElementById("modal");

export default class ChoosePlaceFromMap {
    static async show(args: {        
        currentSpotId: string,
        parkingId: string,
        getSpotsCallback: (level: string, sector: string) => Promise<{error?: ErrorCode, spots?: ParkingMapSpotInfo[]}>
    }){
        return new Promise<{spotId: string, number: string, level: string, sector: string}>(resolve => {
            const onConfirmed = (spotId: string, number: string, level: string, sector: string) => {
                unmountComponentAtNode(modal);
                resolve({level, number, sector, spotId});
            }
            const onCancelled = () => {
                unmountComponentAtNode(modal);
                resolve(null);
            }

            render((
                <Modal                     
                    parkingId={args.parkingId}
                    initialSpotId={args.currentSpotId}
                    getSpotsCallback={args.getSpotsCallback}
                    onCancel={onCancelled}
                    onConfirm={onConfirmed}
                />
            ), modal);
        });
    }
}

const Modal = (props: {
    initialSpotId: string,
    parkingId: string,
    onCancel: () => void,
    onConfirm: (spotId: string, number: string, level: string, sector: string) => void,
    getSpotsCallback: (level: string, sector: string) => Promise<{error?: ErrorCode, spots?: ParkingMapSpotInfo[]}>
}) => {
    const {initialSpotId, getSpotsCallback, onCancel, onConfirm, parkingId} = props;
    const [progress, setProgress] = React.useState(true);
    const [error, setError] = React.useState<ErrorCode>(null);
    const [mapResult, setMapResult] = React.useState<ParkingMapDataResponseResult>(null)

    React.useEffect(() => {
        const init = async () => {
            setProgress(true);

            const {error, result} = await getParkingMap(parkingId);
            
            
            if(error){
                setError(error)
            }
            else{
                setMapResult(result);
            }
            setProgress(false);
        }

        init();
    }, []);

    return (
        <div
            style={{overflow: 'auto', position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: Colors.white, zIndex: 2000}}
        >
            <OtherPagesLayout title={Resources.Wybierz_miejsce_parkingowe} parkingId={parkingId} headerHeight={65}>
                <Body 
                    title={""}
                    paddingBottom={0}
                    paddingTop={20}
                    paddingLeft={20}
                    paddingRight={20}
                >
                    {progress && (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400}}>
                            <Spinner size="large" />
                        </div>
                    )}
                    {!progress && !!error && (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <ErrorView title={getErrorMessage(error)} />
                        </div>                       
                    )}
                    {!progress && !error && (
                        <>
                            
                            <SVGMap 
                                mapResult={mapResult}                               
                                initialSpotId={initialSpotId} 
                                getSpotsCallback={getSpotsCallback}
                                onCancel={onCancel}
                                onConfirm={onConfirm}
                            />                          
                        </>
                    )}           
                </Body>
            </OtherPagesLayout>
        </div>
    )
}