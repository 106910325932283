import { Formik, FormikHelpers } from "formik";
import { AdminService, ErrorCode, ServiceConfig } from "parkcash-api";
import * as React from "react";
import Resources from "../../Resources";
import LocalStorageManager from "../utils/LocalStorageManager";
import {routePrefix} from "../../app.json";
import * as yup from "yup";
import RegularInput from "../components/forms/RegularInput";
import { PCText } from "../styles/Texts";
import Colors from "../styles/Colors";
import { StandardButton } from "../styles/Buttons";
import { getErrorMessage } from "../utils/ErrorUtils";
import { Asserts } from "yup";

const validationSchema = yup.object({
    superAdminPassword: yup.string().required(Resources.Wymagane),
    userId: yup.string().required(Resources.Wymagane)
});

interface FormState extends Asserts<typeof validationSchema> {}

export default () => {

    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {
        const {superAdminPassword, userId} = state;
        const {setSubmitting, setStatus} = helpers;
    
        try{
            setSubmitting(true);
            const {isSuccess, result, error} = await new AdminService(new ServiceConfig()).adminLogin(superAdminPassword);
            if(isSuccess){
                const {jwt: superAdminJwt} = result;
                const jwt = await new AdminService(new ServiceConfig({jwt: superAdminJwt})).getUserJwt(userId);
                if(jwt){
                    LocalStorageManager.SetUserToken(jwt);
                    window.location.replace(routePrefix);
                }
                else{
                    setStatus(Resources.Nastapil_blad);
                }
            }
            else{
                setStatus(getErrorMessage(ErrorCode.NetworkError));
            }
        }
        catch(e){
            const error = e;
            setStatus(getErrorMessage(ErrorCode.NetworkError));
        }
        finally{
            setSubmitting(false);
        }
    }

    const initialValues: FormState = {
        superAdminPassword: "",
        userId: ""
    } 

   

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
        >
            {args => {
                const {values,errors,touched,setFieldTouched,setFieldValue,status,isSubmitting,handleSubmit} = args;
                return (
                    <form
                        noValidate={true}
                        autoComplete="off"
                        onSubmit={handleSubmit}
                        style={{alignSelf: 'stretch'}}
                    >
                        <RegularInput 
                            label={Resources.Id_uzytkownika}
                            name="userId"
                            variant="big"
                            value={values.userId}
                            error={errors.userId}
                            touched={touched.userId}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            showClearButton
                            showValidatedButton
                        />
                        <RegularInput 
                            label={Resources.Haslo_superadmina}
                            name="superAdminPassword"
                            variant="big"
                            value={values.superAdminPassword}
                            error={errors.superAdminPassword}
                            touched={touched.superAdminPassword}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            password
                        />
                        <div style={{height: 50, display: 'flex', flexDirection: 'column', justifyContent: "flex-end", alignItems: 'center'}}>
                            <PCText fontSize={12} letterSpacing={0} color={Colors.red}>{status || ""}</PCText>
                        </div>


                        <StandardButton variant="big" progress={isSubmitting} type="submit" tabIndex={4}>{Resources.Zaloguj}</StandardButton>
                    </form>
                );
            }}
        </Formik>
    )
}