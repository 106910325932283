import React from 'react';
// @mui
import Box from '@mui/material/Box';
import Card, {CardProps} from '@mui/material/Card';
// utils
import {fShortenNumber} from '../../../../MUI/utils/format-number';
import {nullOrUndefined} from "../../../../utils/NumberUtils";

// ----------------------------------------------------------------------

interface Props extends CardProps {
    title: string;
    currentValue?: number;
    totalValue?: number;
    percentage?: number;
    icon: React.ReactElement;
    valueIcon?: React.ReactElement;
    useShortNumber?: boolean;
}

export default function WidgetSummaryWithAvatar({
                                                    title,
                                                    currentValue,
                                                    totalValue,
                                                    icon,
                                                    valueIcon,
                                                    percentage,
                                                    useShortNumber = true,
                                                    sx,
                                                    ...other
                                                }: Props) {
    const getValue=(value: number | undefined):string | undefined =>{
        if (!nullOrUndefined(value)) {
            return useShortNumber ? fShortenNumber(value) : value.toString();
        }
        return undefined;
    }
    const percentageValue = !nullOrUndefined(percentage) ? `${percentage}%` : undefined;
    const totalValueDisplayValue = getValue(totalValue);
    const currentValueDisplayValue = getValue(currentValue)
    const displayValue = () => {
        if (!!currentValueDisplayValue && !!totalValueDisplayValue) {
            return `${currentValueDisplayValue}/${totalValueDisplayValue}`;
        } else if (!!currentValueDisplayValue && !!percentageValue) {
            return `${percentageValue} (${currentValueDisplayValue})`;
        } else if (!!currentValueDisplayValue) {
            return currentValueDisplayValue;

        } else if (!!percentageValue) {
            return percentageValue;
        } else {
            return ""
        }
    }

    return (<Card
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 2,
            pl: 3, ...sx,
        }}
        {...other}
    >
        <Box>
            <Box sx={{
                mb: 1,
                typography: 'h3'
            }}>
                {valueIcon && (valueIcon)}
                {displayValue()}
            </Box>
            <Box sx={{
                color: 'text.secondary',
                typography: 'subtitle2'
            }}>{title}</Box>
        </Box>

        <Box
            sx={{
                width: 120,
                height: 120,
                lineHeight: 0,
                borderRadius: '50%',
                bgcolor: 'background.neutral',
            }}
        >
            {icon}
        </Box>
    </Card>);
}
