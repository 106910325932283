import Enumerable from "linq";
import { AvailibilityStatus, ErrorCode, ParkingPlansService, ServiceConfig } from "parkcash-api";

const OneLevel2Sectors = require("./ParkingPlans/1Level2Sectors.svg");
import * as OneLevel2SectorsJSON from "./ParkingPlans/1Level2Sectors.json";

const OneLevelNoSectors = require("./ParkingPlans/1LevelNoSectors.svg");
import * as OneLevelNoSectorsJSON from "./ParkingPlans/1LevelNoSectors.json";

const TwoLevels2Sectors = require("./ParkingPlans/2Levels2Sectors.svg");
import * as TwoLevels2SectorsJSON from "./ParkingPlans/2Levels2Sectors.json";

const TwoLevelsNoSectors = require("./ParkingPlans/2LevelsNoSectors.svg");
import * as TwoLevelsNoSectorsJSON from "./ParkingPlans/2LevelsNoSectors.json";

const LevelsAsSuperSectors = require("./ParkingPlans/LevelsAsSuperSectors.svg");
import * as LevelsAsSuperSectorsJSON from "./ParkingPlans/LevelsAsSuperSectors.json";

const Ptak = require("./Ptak/Ptak.svg");
import * as PtakJSON from "./Ptak/Ptak.json";

export interface ParkingMapSpotInfo {
    id: string,
    number: string,
    level: string,
    sector: string,
    status: AvailibilityStatus
}

export interface ParkingMapDataResponseResult {
    map?: string;

    hasManyLevels?: boolean,
    hasManySectors?: boolean,

    levels?: string[],
    sectors?: string[], 
    sectorsInLevels?: {[key: string]: string[]}

    globalViewBox?: string,
    sectorsViewBox?: string,
    spotsInLevelsViewBoxes?: {[key: string]: string},
    spotsInSectorsViewBoxes?: {[key: string]: string},
    spotsInSectorsOfLevelsViewBoxes?: {[key: string]: {[key: string]: string}},
    sectorsInLevelsViewBoxes?: {[key: string]: string},
    spotUniquenessType?: "number" | "numberAndSector",
    customClass?: string,
    levelsViewBox?: string,
    isLevelsAsSupersectors?: boolean,

    resizeMode?: "auto" | "max-width" | "max-height"
}

export interface ParkingMapDataResponse {
    error?: ErrorCode,
    result?: ParkingMapDataResponseResult  
}

export const getSpotInfo = async (spotId: string) => {
    try{
        const response = await new ParkingPlansService(new ServiceConfig()).getParkingPlanSpotLocation(spotId);
        if(response.isSuccess){
            return {
                spot: {
                    id: spotId,
                    number: response.result.number,
                    sector: response.result.sector,
                    level: response.result.level
                }
            }
        }
        else{
            return {error: response.error.code}
        }
    }
    catch{
        return {error: ErrorCode.NetworkError}
    }
}

export const getParkingMap = async (parkingId: string): Promise<ParkingMapDataResponse> => {

    // const response = await fetch(Ptak);
    // const svg = await response.text();
    // return {
    //     result: {
    //         map: svg,
    //         ...(PtakJSON as any)
    //     }
    // }

    try{
        
        
        const response = await new ParkingPlansService(new ServiceConfig()).getParkingPlanByParkingId(parkingId);
        if(response.isSuccess){
            return {
                result: {
                    map: response.result.diagram,
                    ...JSON.parse(response.result.metadata)
                }
            }
        }
        else{
            return { error: response.error.code }
        }
    }
    catch{
        return {error: ErrorCode.NetworkError}
    }
}