import * as React from "react";
import {useMemo} from "react";
import {useSelector} from "react-redux";
import Resources from "../../../Resources";
import Body from "../../layouts/Main/Body";
import {applicationState} from "../../redux/ApplicationState";
import {MOBILE_WIDTH_THRESHOLD} from "../../utils/Constants";
import {IParkCashApplicationMode} from "../../utils/ParkCashApplicationMode";
import {useWindowSize} from "../../utils/WindowSizeHook";
import Grid from "./Grid";
import Legend from "./Legend";
import Map from "./Map";
import {GetSpotsViewKpiDataResult} from "parkcash-api";
import {ParkingManagerService, ServiceConfig} from "parkcash-api";
import {useUser} from "../../user/UseUserHook";
import SpotsKpiWidgetsStatisticsSection from "./KpiWidgetsSection";
import {ToggleButtonGroup} from "@mui/material";
import {ToggleButton} from "@mui/lab";
import Iconify from "../../MUI/components/iconify";
import {LoadingErrorAndContentWrapper} from "../../components/Common/LoadingErrorAndContentWrapper";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {
    FetchOptions, useFetchDataUsingDataSourceClass
} from "../../MUI/components/AddedOrModyfiedMuiComponents/DataFetcher/useFetchUsingDataSourceClass.";

type contentType = "map" | "grid";

export default () => {
    const {applicationMode} = useSelector<applicationState, {
        applicationMode: IParkCashApplicationMode
    }>((s) => ({applicationMode: s.user.applicationMode}));
    const {windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const hasParkingPlan = applicationMode?.hasParkingPlanInCurrentParking();
    const [content, setContent] = React.useState<contentType>(hasParkingPlan ? "map" : "grid");
    const [mapCustomClass, setMapCustomClass] = React.useState<string>(null);
    const [legendIsShown, setLegendIsShown] = React.useState(false);

    React.useEffect(() => {
        setContent(hasParkingPlan ? "map" : "grid");
    }, [hasParkingPlan]);

    const {
        token: jwt,
    } = useUser();
    const parkingId = applicationMode.getCurrentParking()?.parkingId || null;

    const fetchKpiOptions: FetchOptions<GetSpotsViewKpiDataResult, any> = useMemo((): FetchOptions<GetSpotsViewKpiDataResult, any> => {
        return {
            additionalInfo: {
                jwt,
                parkingId
            },
            load: async (loadOptions, additionalInfo) => {

                const {
                    jwt,
                    parkingId
                } = additionalInfo;

                const {
                    isSuccess,
                    result,
                    error
                } = await new ParkingManagerService(new ServiceConfig({jwt})).getSpotsViewKpiData(parkingId);

                return isSuccess ? {
                    items: [result],
                    totalItems: result ? 1 : 0
                } : error.code;
            }
        }
    }, [
        jwt,
        parkingId
    ]);

    const {
        data: KPIFetchResult,
        loading,
        error
    } = useFetchDataUsingDataSourceClass<GetSpotsViewKpiDataResult, any>(fetchKpiOptions);
    const KPIFetchResultData = KPIFetchResult?.[0];

    return (<Body
        paddingBottom={content === "map" ? (isMobile ? 10 : 0) : undefined}
        title={Resources.Miejsca}
        titleFontSize={30}
        titleFontWeightBold={true}
        rigth={< Stack direction={"row"} width={isMobile ? "100%" : "fitContent"}
                       justifyContent={isMobile ? "space-between" : "start"}
                       spacing={2}
        >

            {content === "map" && mapCustomClass !== null && (<>
                <IconButton onClick={() => setLegendIsShown(true)} title={Resources.Legenda}
                            sx={{
                                backgroundColor: "white",
                                padding: 1,
                                height: "100%",
                                display: "flex",
                                direction: "column",
                                justifyContent: "center",

                            }}
                >
                    <FontAwesomeIcon icon={faQuestionCircle} style={{
                        fontSize: 30,
                        textAlign: 'center'
                    }}/>
                </IconButton>
            </>)}
            <ToggleButtonGroup size="small" value={content} exclusive
                               onChange={(event, value) => setContent(value)}

            >
                <ToggleButton value="grid" title={Resources.Lista}>
                    <Iconify icon="solar:list-bold"/>
                </ToggleButton>

                <ToggleButton value="map" title={Resources.Plan}>
                    <Iconify icon="mingcute:dot-grid-fill"/>
                </ToggleButton>
            </ToggleButtonGroup>
        </Stack>}
    >
        <Legend
            customClass={mapCustomClass}
            visible={legendIsShown}
            onClose={() => setLegendIsShown(false)}
        />
        {content === "grid" && <>
            <LoadingErrorAndContentWrapper loading={loading} errors={[error]} data={!!KPIFetchResult?.[0]}>
                <SpotsKpiWidgetsStatisticsSection {...KPIFetchResultData}/>
            </LoadingErrorAndContentWrapper>
            <Grid/>
        </>}
        {content === "map" && (<Map onMapCustomClassRetrieved={setMapCustomClass}/>)}
    </Body>);
};
