import { FormikHelpers } from "formik";
import { AddressDto, CreatePrivateParkingDto, ErrorCode, JoinParkingDto, ModifyPrivateParkingDto, ParkingsService, ServiceConfig } from "parkcash-api";
import * as React from "react";
import Resources from "../../Resources";
import ErrorView from "../components/ErrorView";
import RegularInput from "../components/forms/RegularInput";
import ModalForm from "../components/ModalForm";
import PCTabBar from "../components/PCTabBar";
import SearchAddressInput, { searchAddressItem } from "../components/forms/SearchAddressInput";
import Spinner from "../components/Spinner";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { useJWT } from "../utils/JWTUtils";
import RegularSearchAddressInput from "../components/forms/RegularSearchAddressInput";
import { getErrorMessage } from "../utils/ErrorUtils";

interface FormState {
    name: string,
    pin: string,
    code: string,
    address: searchAddressItem
}

const groups = require("../../assets/images/groups.svg");

enum Tab {
    Create,
    Join
}

export default (props: {
    mode: "join" | "edit" | "create",
    visible: boolean,
    onClose: () => void,
    groupId?: string,
    onSubmitted: () => void
}) => {
    const {mode, groupId, onClose, visible, onSubmitted} = props;
    const [progress, setProgress] = React.useState(false);
    const [error, setError] = React.useState<ErrorCode>(null);
    const [currentTab, setCurrentTab] = React.useState(Tab.Create);

    const [initialValues, setInitialValues] = React.useState<FormState>({pin: "", name: "", code: "", address: null});

    const init = async (m: "join" | "edit" | "create", gId: string) => {
        if(m === "edit"){
            try{
                setProgress(true);
                const jwt = useJWT();
                const response = await new ParkingsService(new ServiceConfig({jwt})).getPrivateParkingDetails(gId);
                if(response.isSuccess){
                    setInitialValues({
                        code: "",
                        pin: "",
                        name: response.result.friendlyName,
                        address: {
                            id: "address",
                            type: "location",
                            city: response.result.address.city,
                            lat: response.result.address.latitude,
                            lon: response.result.address.longitude,
                            streetName: response.result.address.streetName,
                            streetNumber: response.result.address.streetNumber
                        }
                    });
                }
                else{
                    setError(response.error.code);
                }
            }
            catch{
                setError(ErrorCode.NetworkError);
            }
            finally{
                setProgress(false);
            }
        }
        else{
            setInitialValues({
                address: null,
                code: "",
                name: "",
                pin: ""
            })
        }
    }

    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {
        const {pin,code,address,name} = state;
        const {setSubmitting, setStatus} = helpers;
        try{
            const jwt = useJWT();
            setSubmitting(true);
            const addressDTO = new AddressDto({
                city: address?.city,
                country: "Poland",
                latitude: address?.lat,
                longitude: address?.lon,
                streetName: address?.streetName,
                streetNumber: address?.streetNumber
            })
            if(mode === "edit"){
                const response = await new ParkingsService(new ServiceConfig({jwt})).modifyPrivateParking(new ModifyPrivateParkingDto({
                    parkingId: groupId,
                    address: addressDTO,
                    friendlyName: name,
                    description: ""
                }));
                if(response.isSuccess){
                    onSubmitted();
                }
                else{
                    setStatus(getErrorMessage(response.error.code));
                }
            }
            else if(currentTab === Tab.Create){
                const response = await new ParkingsService(new ServiceConfig({jwt})).createPrivateParking(new CreatePrivateParkingDto({
                    address: addressDTO,
                    friendlyName: name,
                    description: ""
                }));
                if(response.isSuccess){
                    onSubmitted();
                }
                else{
                    setStatus(getErrorMessage(response.error.code));
                }
            }
            else if(currentTab === Tab.Join){
                const response = await new ParkingsService(new ServiceConfig({jwt})).joinParking(new JoinParkingDto({
                    pin: pin,
                    parkingPublicId: code  
                }));
                if(response.isSuccess){
                    onSubmitted();
                }
                else{
                    if(response.error.code === ErrorCode.IncorrectParkingIdOrPIN){
                        setStatus(Resources.Niepoprawny_identyfikator_lub_pin);
                    }
                    else{
                        setStatus(getErrorMessage(response.error.code));
                    }
                }
            }
        }
        catch{
            setStatus(getErrorMessage())
        }
        finally{
            setSubmitting(false);
        }
    }

    const validate = (state: FormState) => {
        if(currentTab === Tab.Join){
            return validateJoining(state);
        } 
        else{
            return validateGroup(state);
        }
    }

    const validateGroup = (state: FormState) => {
        const {address, name} = state;
        const errors: any = {};

        if(!name){
            errors.name = Resources.Wymagane;
        }

        if(!address){
            errors.address = Resources.Wymagane;
        }

        if(!!address && !address.streetNumber){
            errors.address = Resources.Wymagane_jest_podanie_numeru_domu;
        }

        return errors;
    }

    const validateJoining = (state: FormState) => {
        const {code, pin} = state;
        const errors: any = {};

        if(!code){
            errors.code = Resources.Wymagane;
        }
        if(!pin){
            errors.pin = Resources.Wymagane;
        }

        return errors;
    }

    React.useEffect(() => {
        if(visible){
            init(mode, groupId);
        }  
    }, [mode, groupId, visible]);

    React.useEffect(() => {
        if(mode === "join"){
            setCurrentTab(Tab.Join);
        }
        else{
            setCurrentTab(Tab.Create);
        }
    }, [mode]);

    return (
        <ModalForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            onClose={onClose}
            visible={visible}
            icon={groups}
            iconWidth={120}
            iconHeight={112}
            title={Resources.Grupy}
            enableReinitialize
            validate={validate}
            submitButtonText={mode === "edit" ? Resources.Zapisz : currentTab === Tab.Create ? Resources.Utworz_grupe : Resources.Dolacz_do_grupy}
        >
            {args => {
                const {values, touched, errors, setFieldTouched, setFieldValue} = args;

                if(progress){
                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 100}}>
                            <Spinner size="small" />
                        </div>
                    );
                }
                if(typeof error === "number"){
                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <ErrorView title={getErrorMessage(error)} />
                        </div>
                    );
                }

                const addEditContent = (
                    <>
                        <RegularInput 
                            name="name"
                            value={values.name}
                            error={errors.name}
                            touched={touched.name}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            showClearButton
                            showValidatedButton
                            label={Resources.Nazwa_grupy}
                        />
                        <RegularSearchAddressInput 
                            label={Resources.Adres}
                            current={values.address}
                            name="address"
                            error={errors.address}
                            touched={touched.address}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            borderColor={Colors.brownish_grey}
                        />
                    </>
                );

                if(mode === "edit"){
                    return addEditContent;
                }
                else{
                    return (
                        <PCTabBar
                            itemWidth="full"
                            onChange={setCurrentTab}
                            current={currentTab}
                            items={[
                                {
                                    id: Tab.Create,
                                    text: Resources.Utworz_grupe
                                },
                                {
                                    id: Tab.Join,
                                    text: Resources.Dolacz_do_grupy
                                }
                            ]}
                        >
                            {id => {
                                if(id === Tab.Create){
                                    return (
                                        <>
                                            <BaseSpaceSeparator size={30} />
                                            <PCText semibold fontSize={18} textAlign="center" color={Colors.black}>
                                                {Resources.Utworz_grupe_adresowa_podajac_jej_adres}
                                            </PCText>
                                            <BaseSpaceSeparator size={50} />
                                            {addEditContent}
                                        </>
                                    );
                                }
                                else{
                                    return (
                                        <>
                                            <BaseSpaceSeparator size={30} />
                                            <PCText semibold fontSize={18} textAlign="center" color={Colors.black}>
                                                {Resources.Dolacz_do_grupy_adresowej_podajac_kod_wspoldzielenia}
                                            </PCText>
                                            <BaseSpaceSeparator size={50} />
                                            <RegularInput 
                                                name="code"
                                                value={values.code}
                                                error={errors.code}
                                                touched={touched.code}
                                                setFieldTouched={setFieldTouched}
                                                setFieldValue={setFieldValue}
                                                showClearButton
                                                showValidatedButton
                                                label={Resources.Kod_wpoldzielenia}
                                            />
                                            <RegularInput 
                                                name="pin"
                                                value={values.pin}
                                                error={errors.pin}
                                                touched={touched.pin}
                                                setFieldTouched={setFieldTouched}
                                                setFieldValue={setFieldValue}
                                                password
                                                label={Resources.PIN}
                                            />
                                        </>
                                    );
                                }
                            }}
                        </PCTabBar>
                    )
                }
            }}
        </ModalForm>
    );
}