
export const getClientPhoneNumber = (p: string) => {
    if(!p){
        return "";
    }

    return `(+${p.substr(1, 2)}) ${p.substr(3, 3)} - ${p.substr(6, 3)} - ${p.substr(9, 3)}`;
}

export const getServerPhoneNumber = (p: string) => {
    if(!p){
        return "";
    }

    return p.replace(/( |\(|\)|-)/g, "");
}