import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import Resources from "../../../../../Resources";
import Iconify from "../../../../MUI/components/iconify";
import WidgetSummaryWithAvatar
    from "../../../../MUI/components/AddedOrModyfiedMuiComponents/Widgets/WidgetSummaryWithAvatar";
import BookingIllustration from "../../../../MUI/assets/illustrations/booking-illustration";
import CheckOutIllustration from "../../../../MUI/assets/illustrations/check-out-illustration";

interface Props {

    usersCount?: number;
    spotsCount?: number;
    reservationsCount?: number;
}

export default function MainParkingDashboardKPIWidgetStatisticSection({
                                                                          usersCount,
                                                                          spotsCount,
                                                                          reservationsCount,
                                                                      }: Props) {

    
    return (<Grid container spacing={2} width={"auto"} disableEqualOverflow
                 /* mb={3}*/
    >
        <Grid xs={12} sm={6} lg={4}>
            <WidgetSummaryWithAvatar
                title={Resources.Uzytkownicy}
                currentValue={usersCount}
                useShortNumber={true}
           icon={<BookingIllustration/>} 
            />
        </Grid>
        <Grid xs={12} sm={6} lg={4}>
            <WidgetSummaryWithAvatar title={Resources.Miejsca}
                                     currentValue={spotsCount}
                                     useShortNumber={true}
                                     icon={<Iconify icon="fluent:vehicle-car-parking-16-regular" width={120} color={"primary.main"}/>

                                     }
            />
        </Grid>
        <Grid xs={12} sm={6} lg={4}>
            <WidgetSummaryWithAvatar title={Resources.Rezerwacje}
                                     currentValue={reservationsCount}
                                     useShortNumber={false}
                                     icon={<CheckOutIllustration/>}
            />
        </Grid>
       
    </Grid>)

}