import React from 'react';
import Colors from "../../../../styles/Colors";
import {TextFieldProps} from "@mui/material/TextField";
import {CustomTextField} from "../Styles/StyledComponents";

interface CustomizedTextfieldProps extends TextFieldProps<"standard"> {
    backgroundColor?: string;
}

export const CustomizedTextfield = React.forwardRef(function CustomizedTextfield(props: CustomizedTextfieldProps, ref: React.Ref<HTMLDivElement>) {
    const {
        label,
        value,
        onChange,
        backgroundColor = Colors.white,
        sx,
        ...other
    } = props;
    return (<CustomTextField
        ref={ref}
        label={label}
        value={value}
        onChange={onChange}
        sx={{
            /*'& .MuiOutlinedInput-root': {
                
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    // Remove border or make adjustments when focused
                   /!* outline: 'none',*!/
                    borderColor: 'red', //
                    // You might want to adjust the border or outline specifically here
                },
                '&.Mui-focused .MuiInputLabel-outlined': {
                    // Style the label to ensure it's visible against the input background
                    color: 'blue', // Example: Change label color to maintain visibility
                    zIndex: 1, // Ensure label is above the input field
                    backgroundColor: 'white', // Background to mimic the 'cut-out' effect
                    padding: '0 5px', // Add padding to the background for a cleaner look
                    marginLeft: '-5px', // Adjust based on padding to align correctly
                }
            },*/
         
            ...sx
        }}
       InputProps={
              {
                sx: {
                     backgroundColor: backgroundColor,
                     borderRadius: '8px',
                    width:"100%",
                }
              }
       }
        InputLabelProps={
        
        {
            sx: {
                // Custom styles for the label
                backgroundColor: 'transparent', // Prevent background color from overlapping text
                paddingRight: '5px', // Add padding to ensure text doesn't get cut off by the border
              
              
                
               
            }
        }}
        
        {...other}
    />)
})
