import { AcceptParkingInvitationDto, ErrorCode, GetPrivateParkingInvitationDetailsResult, InvitationsService, ServiceConfig } from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import Resources from "../../Resources";
import ConfirmModal from "../components/ConfirmModal";
import ErrorView from "../components/ErrorView";
import Spinner from "../components/Spinner";
import Body from "../layouts/Main/Body";
import { applicationState } from "../redux/ApplicationState";
import { StandardButton, StandardButton2 } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { formatAddress } from "../utils/AddressUtils";
import { getErrorMessage } from "../utils/ErrorUtils";
import { useJWT } from "../utils/JWTUtils";
import Notify from "../utils/Notify";
import { useWindowSize } from "../utils/WindowSizeHook";
import { getGroups } from "./Redux";

const groups = require("../../assets/images/groups.svg");

interface Props {
    onAccepted: () => void;
}

const Container = (props: Props) => {
    const {onAccepted} = props;
    const jwt = useJWT();
    const {invitationGuid} = useParams<{invitationGuid: string}>();
    const {windowWidth, windowHeight} = useWindowSize();
    const [redirect, setRedirect] = React.useState<string>(null);
    const [progress, setProgress] = React.useState(true);
    const [error, setError] = React.useState<ErrorCode>(null);
    const [parkingInfo, setParkingInfo] = React.useState<GetPrivateParkingInvitationDetailsResult>(null);
    const [acceptProgress, setAcceptProgress] = React.useState(false);

    const init = async () => {
        try{
            const response = await new InvitationsService(new ServiceConfig({jwt})).getPrivateParkingInvitationDetails(invitationGuid);
            if(response.isSuccess){
                setParkingInfo(response.result)
            }
            else{
                setError(response.error.code);
            }
        }
        catch(e){
            const error = e;
            setError(ErrorCode.NetworkError);
        }
        finally{
            setProgress(false);
        }
    }

    const onAccept = async () => {
        const confirm = await ConfirmModal.show({
            text: Resources.Czy_na_pewno_chcesz_zaakceptowac_zaproszenie_do_grupy,
            confirmText: Resources.Zaakceptuj
        });
        if(confirm){
            try{
                setAcceptProgress(true);
                const response = await new InvitationsService(new ServiceConfig({jwt})).acceptParkingInvitation(new AcceptParkingInvitationDto({
                    invitationGuid
                }));
                if(response.isSuccess){
                    onAccepted();
                    setRedirect("/groups");
                }
                else{
                    Notify.Error(getErrorMessage(response.error.code));
                }
            }
            catch{
                Notify.Error(getErrorMessage());
            }
            finally{
                setAcceptProgress(false);
            }
        }
    }

    const onDismiss = async () => {
        const confirm = await ConfirmModal.show({
            text: Resources.Czy_na_pewno_chcesz_odrzucic_zaproszenie_do_grupy,
            confirmText: Resources.Odrzuc
        });
        if(confirm){
            setRedirect("/");
        }
          
    }

    React.useEffect(() => {
        init();
    }, []);

    if(redirect){
        return <Redirect to={redirect} />
    }

    if(progress){
        return (
            <div style={{display: 'flex', alignItems: 'center', height: windowHeight - 100, justifyContent: 'center'}}>
                <Spinner />
            </div>
        );
    }

    if(typeof error === "number"){
        return (
            <div style={{display: 'flex', alignItems: 'center', height: windowHeight - 100, justifyContent: 'center'}}>
                <ErrorView title={getErrorMessage(error)}/>
            </div>
        );
    }

    return (
        <Body title={null}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                <img src={groups} width={236} height={220} />
                <BaseSpaceSeparator size={30} />
                <div style={{width: windowWidth >= 450 ? 400 : 300}}>
                    <PCText textAlign="center" fontSize={28} color={Colors.brownish_grey}>
                        {Resources.Zostales_zaproszony_do_parkingu} {parkingInfo.friendlyName} {Resources.pod_adresem} {formatAddress(parkingInfo.address, true)}. {Resources.Czy_akceptujesz_zaproszenie}
                    </PCText>
                </div>
                <BaseSpaceSeparator size={25} />
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{width: 150}}>
                        <StandardButton2 onClick={onDismiss}>{Resources.Odrzuc}</StandardButton2>
                    </div>
                    <BaseSpaceSeparator size={windowWidth >= 450 ? 50 : 20} />
                    <div style={{width: 150}}>
                        <StandardButton progress={acceptProgress} onClick={onAccept}>{Resources.Zaakceptuj}</StandardButton>
                    </div>
                </div>
            </div>
        </Body>
    );
}

const mapStateToProps = (state: applicationState): Partial<Props> => ({

});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
    onAccepted: () => dispatch(getGroups(true))
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);