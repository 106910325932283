import * as React from "react";
import Resources from "../../../Resources";
import DropdownList from "../../components/DropdownList";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { PCClasses } from "../../utils/CSSUtils";
import { InvoiceType } from "../formInterfaces/InvoiceFormState";

const Item = (props: {
    value: InvoiceType,
    isSelected: boolean,
    text: string,
    onSelected: (v: InvoiceType) => void
}) => {
    const {isSelected, text, value, onSelected} = props;

    return (
        <div
            className={PCClasses("pc-button")}
            onClick={() => onSelected(value)}
            style={{
                width: 300,
                height: 70,
                paddingRight: 15,
                paddingLeft: 15,
                display: 'flex',
                alignItems: 'center',
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: isSelected ? Colors.light_royal_blue : Colors.very_light_pink,
                borderRadius: 25 
            }}
        >
            <div 
                style={{
                    display: 'flex', 
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 18, 
                    width: 18,
                    boxSizing: "border-box",
                    borderRadius: 9, 
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: isSelected ? Colors.light_royal_blue : Colors.very_light_pink
                }}
            >
                {isSelected && (
                    <div
                        style={{
                            width: 8,
                            height: 8,
                            boxSizing: "border-box",
                            borderRadius: 4,
                            backgroundColor: Colors.light_royal_blue
                        }}
                    />
                )}
            </div>
            <BaseSpaceSeparator size={15} />
            <PCText color={Colors.black} semibold fontSize={16}>{text}</PCText>
        </div>
    );
}

export default (props: {
    isMobile: boolean,
    value: InvoiceType,
    onChange: (v: InvoiceType) => void
}) => {
    const {isMobile, onChange, value} = props;

    if(isMobile){
        return (
            <DropdownList 
                actions={[
                    {
                        id: InvoiceType.Company,
                        text: Resources.Firma
                    },
                    {
                        id: InvoiceType.PrivatePerson,
                        text: Resources.Osoba_prywatna
                    }   
                ]}
                label={Resources.Typ_faktury}    
                value={value}
                onChange={onChange}
            />
        );
    }
    else{
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Item text={Resources.Firma} value={InvoiceType.Company} isSelected={value === InvoiceType.Company} onSelected={onChange}/>
                <BaseSpaceSeparator size={15} />
                <Item text={Resources.Osoba_prywatna} value={InvoiceType.PrivatePerson} isSelected={value === InvoiceType.PrivatePerson} onSelected={onChange}/>
            </div>
        )
    }
}