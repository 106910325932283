import { FormikHelpers } from "formik";
import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import Resources from "../../Resources";
import RegularInput from "../components/forms/RegularInput";
import ModalForm from "../components/ModalForm";
import { applicationState } from "../redux/ApplicationState";
import {Asserts} from "yup";
import { setProfileId } from "./Redux";

const driver = require("../../assets/images/driver.svg");

interface OwnProps {
    visible: boolean,
    onClose: () => void;
}

interface Props {
    profileId: string
}

interface FormState {profileId: string}

export default (props: OwnProps) => {
    const {onClose, visible} = props;
    const {profileId} = useSelector<applicationState, Props>(s => ({profileId: s.user.profileId}));
    const dispatch = useDispatch();

    const initialValues: FormState = {
        profileId
    };
  
    const validationSchema = yup.object({
        profileId: yup.string().required(Resources.Wymagane)
    });
    

    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {
        const {setSubmitting, setStatus} = helpers;
        const {profileId} = state;
        setSubmitting(true);
        const result = await new Promise<boolean | string>(resolve => {            
            dispatch(setProfileId(profileId, resolve));
        });
        if(typeof result !== "string"){
            onClose();
        }
        else{
            setStatus(result);
        }
        setSubmitting(false);
    }

    return (
        <ModalForm
            icon={driver}
            iconWidth={110}
            iconHeight={110}
            showCancelButton
            title={Resources.Identyfikator_profilu}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            visible={visible}
            onClose={onClose}
        >
            {args => {
                const {values, errors, touched, setFieldTouched, setFieldValue} = args;

                return (
                    <RegularInput 
                        value={values.profileId}
                        error={errors.profileId}
                        touched={touched.profileId}
                        name="profileId"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        showValidatedButton
                        showClearButton
                        label={Resources.Identyfikator_profilu}
                    />
                )
            }}
        </ModalForm>
    )
}