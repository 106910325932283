import * as React from "react";
import Inputmask from "inputmask";

export default (props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {mask: string}) => {
    const {mask, ...inputProps} = props;
    const inputRef = React.createRef<HTMLInputElement>();

    React.useEffect(() => {
        inputRef && inputRef.current && Inputmask({
            mask
        }).mask(inputRef.current);
    }, [mask]);

    return (
        <input
            ref={inputRef} 
            {...inputProps}
        />
    )
}