import { VirtualPilotsService } from "parkcash-api";
import * as React from "react";
import Resources from "../../Resources";
import { StandardButton } from "../styles/Buttons";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { PCText } from "../styles/Texts";
import { MOBILE_WIDTH_THRESHOLD } from "../utils/Constants";
import { useWindowSize } from "../utils/WindowSizeHook";

const not_found = require("../../assets/images/not_found.svg");

export default () => {
    const {windowHeight, windowWidth} = useWindowSize();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: isMobile ? 30 : 70,
                paddingBottom: 30
            }}
        >
            {!isMobile && (
                <>
                    <img src={not_found} height={769*0.75} width={844*0.75} />
                    <BaseSpaceSeparator size={50} />
                </>
            )}       
            <PCText textAlign="center" fontSize={60} color={Colors.brownish_grey}>
                {Resources.Zakaz_wjazdu}
            </PCText>
            <BaseSpaceSeparator size={4} />
            <PCText textAlign="center" semibold fontSize={24} color={Colors.black}>
                {Resources.Przepraszamy_ale_strona_ktorej_szukasz_nie_istnieje}
                <br />
                {Resources.Moze_byc_to_spowodowane_nieprawidlowym_odnosnikiem_na_stronie_z_ktorej_tu_trafiles}
            </PCText>
            <BaseSpaceSeparator size={33} />
            <div style={{width: 300}}>
                <StandardButton variant="standard" to="/">{Resources.Wroc_do_strony_glownej}</StandardButton>
            </div>
        </div>
    );
}