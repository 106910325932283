import * as React from "react";
import { ParkCashRole } from "parkcash-api";
import { RouteProps, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { applicationState } from "../redux/ApplicationState";
import { IParkCashApplicationMode } from "../utils/ParkCashApplicationMode";

export enum RouteType {
    User,
    ParkingManager,
    SuperAdmin
}

interface OwnProps extends RouteProps {
    access?: RouteType
}

interface Props extends OwnProps {
    applicationMode: IParkCashApplicationMode;
    token: string;
  
}

const Container = (props: Props) => {
    const { token, children, applicationMode, access = RouteType.User, ...rest} = props;
   
    const isLogged = !!token;
    const hasProperRole = (access === RouteType.User && applicationMode.isUser()) ||
    (access === RouteType.ParkingManager && applicationMode.isParkingManager()) ||
    (access === RouteType.SuperAdmin && applicationMode.isSuperAdmin());

    const canAccess = isLogged && hasProperRole;

    const isNotOnParkingManagementSite = access === RouteType.User && applicationMode.isInParkingManagementMode();

    return (
        <Route
            {...rest}
            
            render={args => {

                const {location} = args;
                if(isNotOnParkingManagementSite){
                    return (
                        <Redirect
                            to="/parkingmanagement"
                        />
                    );

                }
                else if(canAccess){
                   /* 
                   orginal version
                   return children;*/
                    // version after updating to react 18 and react router dom 5.3.4
                    return typeof children === 'function' ? children({...args}) : children;
                }
                else{
                    return (
                        <Redirect
                            to={{
                                pathname: "/logout",
                                state: {from: location.pathname}
                            }}
                        />
                    )
                }
            }}  
            
           
          
        />
    );
};

const mapStateToProps = (state: applicationState, ownProps: OwnProps): Partial<Props> => ({
    ...ownProps,
    token: state.user.token,
    applicationMode: state.user.applicationMode
});

export default connect(mapStateToProps)(Container);



