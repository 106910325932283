import * as React from "react";
import Resources from "../../../Resources";
import ModalForm from "../../components/ModalForm";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { useWindowSize } from "../../utils/WindowSizeHook";

const homeWithDrive = require("../../../assets/images/homeWithDrive.svg");

const Item = (props: { children: string; color: string }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: 20,
          height: 20,
          borderRadius: 10,
          backgroundColor: props.color,
          marginRight: 10,
        }}
      />
      <PCText color={Colors.black} fontSize={16} letterSpacing={0}>
        {props.children}
      </PCText>
    </div>
  );
};

export default (props: {
  customClass: string;
  visible: boolean;
  onClose: () => void;
}) => {
  const { isMobile } = useWindowSize();
  const { customClass } = props;
  return (
    <ModalForm
      visible={props.visible}
      onClose={props.onClose}
      icon={homeWithDrive}
      iconHeight={110}
      iconWidth={90}
      title={Resources.Legenda}
      initialValues={{}}
      onSubmit={() => {}}
      showSubmitButton={false}
    >
      {() => (
        <>
          {customClass === "ptak" && (
            <>
              <Item color={Colors.green}>{Resources.wolne_miejsce}</Item>
              <BaseSpaceSeparator size={30} />
              <Item color={"rgb(255,0,0)"}>{Resources.zajete_miejsce}</Item>
              <BaseSpaceSeparator size={30} />
              <Item color={"rgb(247, 247, 8)"}>
                {Resources.klient_zalega_z_oplata_miesiac}
              </Item>
              <BaseSpaceSeparator size={30} />
              <Item color={Colors.mango}>
                {Resources.klient_zalega_z_oplata_co_najmniej_2_miesiace}
              </Item>
              <BaseSpaceSeparator size={30} />
              <Item color={"rgb(138, 66, 14)"}>
                {Resources.zajete_w_przyszlosci_miejsce}
              </Item>
              <BaseSpaceSeparator size={30} />
              <Item color={Colors.heliotrope}>{Resources.wybrane_miejsce}</Item>
            </>
          )}
          {customClass !== "ptak" && (
            <>
              <Item color={Colors.green}>{Resources.wolne_miejsce}</Item>
              <BaseSpaceSeparator size={30} />
              <Item color={Colors.light_royal_blue}>
                {Resources.zajete_miejsce}
              </Item>
              <BaseSpaceSeparator size={30} />
              <Item color={Colors.mango}>
                {Resources.nieodblokowane_miejsce}
              </Item>
              <BaseSpaceSeparator size={30} />
              <Item color={Colors.saphire}>
                {Resources.zajete_w_przyszlosci_miejsce}
              </Item>
              <BaseSpaceSeparator size={30} />
              <Item color={Colors.heliotrope}>{Resources.wybrane_miejsce}</Item>
            </>
          )}
        </>
      )}
    </ModalForm>
  );
};
