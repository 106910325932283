import { ErrorCode, LeaveParkingDto, ParkingInfo, ParkingsService, ServiceConfig } from "parkcash-api";
import * as React from "react";
import { connect } from "react-redux";
import Resources from "../../Resources";
import ConfirmModal from "../components/ConfirmModal";
import ErrorView from "../components/ErrorView";
import GlobalProgress from "../components/GlobalProgress";
import { ListAddButton, ListContainer } from "../components/Lists";
import Spinner from "../components/Spinner";
import Body from "../layouts/Main/Body";
import { applicationState } from "../redux/ApplicationState";
import Colors from "../styles/Colors";
import { BaseSpaceSeparator } from "../styles/Separators";
import { getErrorMessage } from "../utils/ErrorUtils";
import { useJWT } from "../utils/JWTUtils";
import Notify from "../utils/Notify";
import { useQuery } from "../utils/QueryParametersUtils";
import AddGroupPrompt from "./AddGroupPrompt";
import GroupDetails from "./GroupDetails";
import GroupForm from "./GroupForm";
import GroupsListItem from "./GroupsListItem";
import NoGroups from "./NoGroups";
import { getGroups } from "./Redux";

interface Props {
    error: ErrorCode,
    progress: boolean,
    items: ParkingInfo[],
    userId: string,
    onInit: (refresh: boolean) => void
}

type initialMode = "forceadd" | "add" | "create" | "share";

const Container = (props: Props) => {
    const {error, items, progress, userId, onInit} = props;
    const query = useQuery();
    const groupId = query.get("groupId");
    const initialMode: initialMode = query.get("initialMode") as initialMode;
    const pin = query.get("pin");
    const code = query.get("code");
    const [groupDetailsVisible, setGroupDetailsVisible] = React.useState(false);
    const [groupDetailId, setGroupDetailId] = React.useState<string>(null);
    const [groupFormMode, setGroupFormMode] = React.useState<"join" | "edit" | "create">("join");
    const [groupFormGroupId, setGroupFormGroupId] = React.useState<string>(null);
    const [groupFormVisible, setGroupFormVisible] = React.useState(false);
    const [globalProgress, setGlobalProgress] = React.useState(false);
    const [addGroupPromptVisible, setAddGroupPromptVisible] = React.useState(false);

    const onAdd = () => {
        setGroupFormGroupId(null);
        setGroupFormMode("create");
        setGroupFormVisible(true);
    }

    const onJoin = () => {
        setGroupFormGroupId(null);
        setGroupFormMode("join");
        setGroupFormVisible(true);
    }

    const onEdit = (p: ParkingInfo) => {
        setGroupFormGroupId(p.id);
        setGroupFormMode("edit");
        setGroupFormVisible(true);
    }

    const onManage = (p: ParkingInfo) => {
        setGroupDetailId(p.id);
        setGroupDetailsVisible(true);
    }

    React.useEffect(() => {
        if(items){
            if(!initialMode){
                return;
            }
            else if(initialMode === "share"){
                if(groupId || items[0]){
                    setGroupDetailId(groupId || items[0].id);
                    setGroupDetailsVisible(true);
                }           
            }
        }       
    }, [items]);

    React.useEffect(() => {
        if(!initialMode){
            return;
        }

        if(initialMode === "add"){
            setGroupFormMode("join");
            setGroupFormVisible(true);            
        }
        else if(initialMode === "forceadd"){
            setAddGroupPromptVisible(true);
        }
        else if(initialMode === "create"){
            setGroupFormMode("create");
            setGroupFormVisible(true);
        }
    }, [])

    const onRemove = async (p: ParkingInfo) => {
        const confirm = await ConfirmModal.show({
            text: Resources.Czy_na_pewno_chcesz_opuscic_grupe,
            confirmText: Resources.Opusc
        });
        if(confirm){
            try{
                setGlobalProgress(true);
                const jwt = useJWT();
                const response = await new ParkingsService(new ServiceConfig({jwt})).leaveParking(new LeaveParkingDto({
                    parkingId: p.id
                }));
                if(response.isSuccess){
                    onInit(true);
                }
                else{
                    if(response.error.code === ErrorCode.PowerUserCanNotLeaveGroupByHimself){
                        Notify.Error(Resources.Administrator_lub_moderator_nie_moze_opuscic_grupy);
                    }   
                    else{
                        Notify.Error(getErrorMessage(response.error.code));
                    }             
                }
            }
            catch(e){
                Notify.Error(getErrorMessage());
            }
            finally{
                setGlobalProgress(false);
            }
        }
    }

    React.useEffect(() => {
        onInit(false);
    }, []);

    return (
        <Body
            title={Resources.Grupy}
        >
            <GlobalProgress visible={globalProgress} />
            <GroupDetails 
                groupId={groupDetailId}
                visible={groupDetailsVisible}
                onClose={() => setGroupDetailsVisible(false)}
                userId={userId}
            />
            <GroupForm 
                groupId={groupFormGroupId}
                mode={groupFormMode}
                visible={groupFormVisible}
                onClose={() => setGroupFormVisible(false)}
                onSubmitted={() => {
                    setGroupFormVisible(false);
                    onInit(true);
                }}
            />
            <AddGroupPrompt 
                code={code}
                pin={pin}
                onClose={() => setAddGroupPromptVisible(false)}
                visible={addGroupPromptVisible}
                onSubmitted={() => {
                    setAddGroupPromptVisible(false);
                    onInit(true);
                }}
            />
            {progress && <Spinner />}
            {!progress && typeof error === "number" && <ErrorView title={getErrorMessage(error)} />}
            {!progress && typeof error !== "number" && !items.length && <NoGroups onAdd={onAdd} onJoin={onJoin}/>}
            {!progress && typeof error !== "number" && !!items.length && (
                <ListContainer>
                    <div>
                        <ListAddButton onClick={onAdd}>{Resources.Dodaj_nowa_grupe}</ListAddButton>
                        <BaseSpaceSeparator size={30} />
                        <ListAddButton onClick={onJoin} backgroundColor={Colors.saphire}>{Resources.Dolacz_do_nowej_grupy}</ListAddButton>
                    </div>
                    {items.map(item => (
                        <GroupsListItem 
                            key={item.id}
                            item={item}
                            onEdit={onEdit}
                            onManage={onManage}
                            onRemove={onRemove}
                        />
                    ))}
                </ListContainer>
            )}
        </Body>
    )
}

const mapStateToProps = (state: applicationState): Partial<Props> => ({
    userId: state.user.userId,
    error: state.groups.gettingGroupsError,
    progress: state.groups.gettingGroupsProgress,
    items: state.groups.groups || [],
    //items: []
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
    onInit: (refresh) => dispatch(getGroups(refresh))
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);