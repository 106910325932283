import Grid from "@mui/material/Unstable_Grid2";
import React from "react";

import {BookingIllustration, CheckInIllustration, CheckOutIllustration} from "../../MUI/assets/illustrations";
import Resources from "../../../Resources";
import {calculatePercentage} from "../../utils/NumberUtils";
import Iconify from "../../MUI/components/iconify";
import WidgetSummaryWithAvatar from "../../MUI/components/AddedOrModyfiedMuiComponents/Widgets/WidgetSummaryWithAvatar";

interface Props {
    totalSpotsCount?: number;
    freeSpotsCount?: number;
    occupiedSpotsCount?: number;
    inactiveSpotsCount?: number;
}

export default function SpotsKpiWidgetsStatisticsSection({
                                                             totalSpotsCount,
                                                             freeSpotsCount,
                                                             occupiedSpotsCount,
                                                             inactiveSpotsCount,
                                                         }: Props) {

    const occupiedPercentage = calculatePercentage(occupiedSpotsCount, totalSpotsCount);
    const freeSpotPercentage = calculatePercentage(freeSpotsCount, totalSpotsCount);
    const inactiveSpotPercentage = calculatePercentage(inactiveSpotsCount, totalSpotsCount);

    return (<Grid container spacing={2} width={"auto"} disableEqualOverflow
                  mb={3}
    >
        <Grid xs={12} sm={6} lg={3}>
            <WidgetSummaryWithAvatar
                title={Resources.Liczba_wolnych_miejsc}
                currentValue={freeSpotsCount}
                percentage={freeSpotPercentage}
                useShortNumber={false}
                icon={<Iconify icon="fluent:vehicle-car-parking-16-regular" width={120} color={"primary.main"}/>}
            />
        </Grid>
        <Grid xs={12} sm={6} lg={3} >
            <WidgetSummaryWithAvatar title={Resources.Liczba_zarezerwowanych_miejsc} 
                                     currentValue={occupiedSpotsCount}
                                     percentage={occupiedPercentage}
                                     useShortNumber={false}
                                     icon={<Iconify icon="fluent:vehicle-car-parking-16-filled" width={120} color={"primary.main"}/>
            
            }
            />
        </Grid>
        <Grid xs={12} sm={6} lg={3}>
            <WidgetSummaryWithAvatar title={Resources.Liczba_nieaktywnych_miejsc}
                                     currentValue={inactiveSpotsCount}
                                     percentage={inactiveSpotPercentage}
                                     useShortNumber={false}
                                     icon={<Iconify icon="lucide:parking-circle-off"  width={120} color={"primary.main"}/>}
            />
        </Grid>
        <Grid xs={12} sm={6} lg={3}>
            <WidgetSummaryWithAvatar title={Resources.Laczna_liczba_zarzadzanych_miejsc}
                                     currentValue={totalSpotsCount}
                                     useShortNumber={false}
                                     /*icon={<Iconify icon="vaadin:pie-chart" width={130} color={"primary.main"}/>}*/
                                     icon={<Iconify icon="fa-solid:chart-pie" width={130} color={"primary.main"}/>}
            />
        </Grid>
    </Grid>)

}