import * as React from "react";
import Switch from '@material-ui/core/Switch';
import { BaseSpaceSeparator } from "../styles/Separators";
import Colors from "../styles/Colors";
import { PCText } from "../styles/Texts";
import FormLabel from "./FormLabel";

interface SwitchProps {
    value: boolean,
    onChange: (v: boolean) => void;
}

const PCSwitch = (props: SwitchProps) => {
    const {onChange,value} = props;

    return (
        <Switch
            checked={value}
            onChange={(_, checked) => onChange(checked)}
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
        />
    )
}

export const SwitchWithLabel = (props: SwitchProps & {label: string}) => {
    const {label, ...switchProps} = props;

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{flex: 1}}>
                <FormLabel>{label}</FormLabel>
            </div>
            <BaseSpaceSeparator size={20} />
            <PCSwitch {...switchProps}/>
        </div>
    )
}

export default PCSwitch;