import { LinkStatus } from "parkcash-api";
import * as React from "react";
import Resources from "../../Resources";
import StatusPresenter from "../components/StatusPresenter";
import Colors from "../styles/Colors";

const getText = (status: LinkStatus) => {
    if(status === LinkStatus.Used){
        return Resources.Uzyty;
    }
    else if(status === LinkStatus.Active){
        return Resources.Aktywny;
    }
    else if(status === LinkStatus.Outdated){
        return Resources.Niewazny;
    }
    else{
        return Resources.Zablokowany;
    }
}

const getColor = (status: LinkStatus) => {
    if(status === LinkStatus.Used){
        return Colors.greyishBrown;
    }
    else if(status === LinkStatus.Active){
        return Colors.green;
    }
    else{
        return Colors.red;
    }
}

export default (props: {
    status: LinkStatus
}) => {
    const {status} = props;

    return <StatusPresenter color={getColor(status)} text={getText(status)} />
}