import React from "react";
import Resources from "../../Resources";
import Body from "../layouts/Main/Body";
import { StandardButton } from "../styles/Buttons";
import * as config from "../../app.json";
import { useWindowSize } from "../utils/WindowSizeHook";

const FreeSpotsReports = () => {
  const { isMobile } = useWindowSize();
  return (
    <Body
            titleFontSize={25}
    
    >
      <div style={{ width: isMobile ? "100%" : 250 }}>
        <StandardButton
          target="_blank"
          href={`${window.location.origin}${config.routePrefix}/op/listofvacantspots`}
        >
          {Resources.Wykaz_wolnych_miejsc}
        </StandardButton>
      </div>
    </Body>
  );
};

export { FreeSpotsReports };
