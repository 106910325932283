import React from 'react';
import {ApexOptions} from 'apexcharts';
// @mui
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Card, {CardProps} from '@mui/material/Card';
// components
import Chart, {useChart} from '../../../../MUI/components/chart';

// ----------------------------------------------------------------------

interface Props extends CardProps {
    title?: string;
    subheader?: string;

    chart: {
        labels: string[]; colors?: string[]; series: {
            name: string;
            type?: string;
            fill?: string;
            data: number[]|any[],
        }[]; options?: ApexOptions;
    };
}

export default function HeatMapChart({
                                                            title,
                                                            subheader,
                                                            chart,
                                                            ...other
                                                        }: Props) {
    const {
        labels,
        colors,
        series,
        options
    } = chart;

    const chartOptions = useChart({
        colors,
        plotOptions: {
            bar: {
                columnWidth: '16%',
            },
        },
        fill: {
            type: series.map((i) => i.fill) as string[],
        },
        labels,
        xaxis: {
            type: 'numeric',
        },
       
        tooltip: {
            shared: true,
            intersect: false,
            y: series.map((i) => ({
                formatter: (value: number) => {
                    let formattedValue: string = String(value);
                    if (typeof value !== 'undefined') {
                        formattedValue = `${value.toFixed(0)}`;
                    }
                    return formattedValue;
                },
            })),
        }, ...options,
    });

    return (<Card {...other} sx={{height: '100%'}} id={"CardContainer"}>
        <CardHeader title={title} subheader={subheader}/>
        <Box sx={{
            p: 3,
            pb: 1
        }}>
            <Chart dir="ltr" type="heatmap" series={series} options={chartOptions} height={364}/>
        </Box>
    </Card>);
}
