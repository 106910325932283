import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DayOfWeek as DayOfWeekEnum } from "parkcash-api";
import * as React from "react";
import { date } from "yup";
import Colors from "../../styles/Colors";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { PCText } from "../../styles/Texts";
import { MOBILE_WIDTH_THRESHOLD } from "../../utils/Constants";
import { PCClasses } from "../../utils/CSSUtils";
import { getFirstDayOfMonth, monthsMap, addMonth, dayOfWeekAbbrMap, getWeeksOfMonth, getTime } from "../../utils/DateTimeUtils";
import { capitalizeFirstLetter } from "../../utils/StringUtils";
import { useWindowSize } from "../../utils/WindowSizeHook";
import { DEFAULT_MIN, DEFAULT_MAX } from "./Constants";

export interface PCDatePickerDotDefinition {
    red: boolean,
    blue: boolean,
    gray: boolean
}

interface Props {
    currentDay: Date;
    onDaySelected: (day: Date) => void;
    max?: Date;
    min?: Date;
    dots?: {[key: string]: PCDatePickerDotDefinition},
    mobileWidth?: number,
    desktopWidth?: number
}

export default (props: Props) => {
    const {windowWidth} = useWindowSize();
    const {currentDay, onDaySelected, dots = {}, mobileWidth = 280, desktopWidth = 350, min = DEFAULT_MIN, max = DEFAULT_MAX} = props;
    const [currentMonth, setCurrentMonth] = React.useState(getFirstDayOfMonth(currentDay || new Date()));
    const [weeksInCurrentMonth, setWeeksInCurrentMonth] = React.useState<Date[][]>([]);

    const width = windowWidth >= MOBILE_WIDTH_THRESHOLD ? desktopWidth : mobileWidth;

    React.useEffect(() => {
        setCurrentMonth(getFirstDayOfMonth(currentDay || new Date()));
    }, [currentDay]);

    React.useEffect(() => {
        setWeeksInCurrentMonth(getWeeksOfMonth(currentMonth));
    }, [currentMonth]);

    return (
        <div style={{width, padding: "10px 0px", backgroundColor: Colors.white, display: "flex", flexDirection: "column"}}>
            <MonthSelector 
                current={currentMonth} 
                onNext={() => setCurrentMonth(addMonth(currentMonth, 1))} 
                onPrevious={() => setCurrentMonth(addMonth(currentMonth, -1))}
                min={min}
                max={max}
            />
            <BaseSpaceSeparator size={20} />
            <DayOfWeeks width={width} />
            <div style={{height: 2, backgroundColor: "#97b3ce", opacity: 0.4}}></div>
            {weeksInCurrentMonth.map((days, index) => (
                <Week 
                    key={index} 
                    days={days} 
                    onDaySelected={onDaySelected} 
                    dots={dots} 
                    currentDay={currentDay} 
                    width={width} 
                    currentMonth={currentMonth} 
                    max={max}
                    min={min}
                />
            ))}
        </div>
    )
}

const hasNextMonth = (current: Date, max: Date) => {
    const maxMonth = new Date(max.getFullYear(), max.getMonth(), 1);
    const nextMonth = addMonth(new Date(current.getFullYear(), current.getMonth(), 1), 1);
    return nextMonth <= maxMonth;
}

const hasPreviousMonth = (current: Date, min: Date) => {
    const minMonth = new Date(min.getFullYear(), min.getMonth(), 1);
    const previousMonth = addMonth(new Date(current.getFullYear(), current.getMonth(), 1), -1);
    return previousMonth >= minMonth;
}

const MonthSelector = (props: {
    current: Date,
    onNext: () => void,
    onPrevious: () => void,
    min: Date,
    max: Date
}) => {
    const {current, onNext, onPrevious, max, min} = props;
    const month = current.getMonth(), year = current.getFullYear(); 

    return (
        <div style={{alignSelf: 'center', display: 'flex', alignItems: 'center'}}>
            {hasPreviousMonth(current, min) && (
                <>
                    <div onClick={onPrevious} className={PCClasses("pc-button")} style={{padding: "0px 5px"}}>
                        <FontAwesomeIcon icon={faCaretLeft} color={Colors.brownish_grey} style={{fontSize: 16}} />
                    </div>
                    <BaseSpaceSeparator size={10} />
                </>
            )}
            <div style={{width: 131, userSelect: "none"}}>
                <PCText textAlign="center" color={Colors.brownish_grey} fontSize={18} semibold>
                    {capitalizeFirstLetter(monthsMap()[month])} {year}
                </PCText>
            </div>
            {hasNextMonth(current, max) && (
                <>
                    <BaseSpaceSeparator size={10} />
                    <div onClick={onNext} className={PCClasses("pc-button")} style={{padding: "0px 5px"}}>
                        <FontAwesomeIcon icon={faCaretRight} color={Colors.brownish_grey} style={{fontSize: 16}} />
                    </div>
                </>
            )}
            
        </div>
    )
}

const DayOfWeeks = (props: {width: number}) => {
    return (
        <div style={{display: 'flex'}}>
            <Day width={props.width} day={DayOfWeekEnum.Monday} />
            <Day width={props.width} day={DayOfWeekEnum.Tuesday} />
            <Day width={props.width} day={DayOfWeekEnum.Wednesday} />
            <Day width={props.width} day={DayOfWeekEnum.Thursday} />
            <Day width={props.width} day={DayOfWeekEnum.Friday} />
            <Day width={props.width} day={DayOfWeekEnum.Saturday} />
            <Day width={props.width} day={DayOfWeekEnum.Sunday} />
        </div>
    );
}

const Day = (props: {width: number, day: DayOfWeekEnum}) => {
    return (
        <div style={{width: props.width/7, height: 25}}>
            <PCText lineHeight={1} textAlign="center" fontSize={12} color={Colors.brownish_grey}>{dayOfWeekAbbrMap()[props.day].toUpperCase()}</PCText>
        </div>
    )
}

const isDayPossibleToChoose = (day: Date, max: Date, min: Date) => {
    const targetMax = getTime(max, 0, 0);
    const targetMin = getTime(min, 0, 0);
    const targetDay = getTime(day, 0, 0);

    return targetDay >= targetMin && targetDay <= targetMax;
}

const Week = (props: {
    days: Date[],
    currentDay: Date,
    currentMonth: Date,
    onDaySelected: (v: Date) => void,
    dots: {[key: string]: PCDatePickerDotDefinition},
    width: number,
    min: Date,
    max: Date,
}) => {
    const {currentDay, days, dots, onDaySelected, width, currentMonth, max, min} = props;
    return (
        <div style={{display: 'flex'}}>
            {days.map((day, index) => {
                return (
                    <DayOfWeek
                        key={index}
                        day={day.getDate()}
                        width={width/7} 
                        onClick={() => onDaySelected(day)}
                        dots={dots[day.toDateString()]}
                        isActive={!!currentMonth && day.getMonth() === currentMonth.getMonth() && day.getFullYear() === currentMonth.getFullYear()}
                        isCurrent={!!currentDay && day.getDate() === currentDay.getDate() && day.getMonth() === currentDay.getMonth() && day.getFullYear() === currentDay.getFullYear()}
                        isPossibleToChoose={isDayPossibleToChoose(day, max, min)}
                    />
                )
            })}
        </div>
    )
}

const DayOfWeek = (props: {
    isCurrent: boolean,
    isActive: boolean,
    isPossibleToChoose: boolean,
    dots: PCDatePickerDotDefinition,
    day: number,
    onClick: () => void,
    width: number
}) => {
    const {day, dots, isActive, isCurrent, isPossibleToChoose, onClick, width} = props;

    return (
        <div 
            style={{
                width, 
                height: width, 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                pointerEvents: isActive && isPossibleToChoose ? undefined : "none",
                position: 'relative',
                opacity: isPossibleToChoose || !isActive ? 1 : 0.5
            }} 
            className={PCClasses("pc-button")} 
            onClick={onClick}
        >   
            {!!dots && !!Object.keys(dots).length && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        left: 0,
                        right: 0,
                        height: 5,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 2
                    }}
                >
                    {dots.gray && <Dot color={Colors.very_light_pink}/>}
                    {(dots.gray && dots.blue) && <BaseSpaceSeparator size={3} />}
                    {dots.blue && <Dot color={Colors.light_royal_blue}/>}
                    {dots.blue && dots.red && <BaseSpaceSeparator size={3} />}
                    {dots.gray && !dots.blue && dots.red && <BaseSpaceSeparator size={3} />}
                    {dots.red && <Dot color={Colors.heliotrope} />}
                </div>     
            )}
            
            {isCurrent && isActive ? (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 9, backgroundColor: Colors.datePickerSelected, width: 24, height: 24}}>
                    <PCText semibold fontSize={12} color={Colors.light_royal_blue}>
                        {day}
                    </PCText>
                </div>
            ) : (
                <PCText semibold fontSize={12} color={isActive ? Colors.black : Colors.brownish_grey}>
                    {day}
                </PCText>
            )}
        </div>
    )
}

const Dot = (props: {color: string}) => (
    <div style={{height: 5, width: 5, borderRadius: 2.5, backgroundColor: props.color}} />
)
