import { LoginType } from "parkcash-api";
import simplePayload from "../redux/SimplePayload";

export interface authenticationState {
    loginType: LoginType,
    name: string,
    surname: string,
    email: string,
    oauthToken: string
}

export type AuthenticationActions = "InitThirdPartyRegistration" | "InitNativeRegistration";

interface thirdPartyRegistrationPayload extends simplePayload {
    loginType: LoginType,
    name: string,
    surname: string,
    email: string,
    oauthToken: string
}

export const initThirdPartyRegistration = (oauthToken: string, loginType: LoginType,name: string,surname: string, email: string): thirdPartyRegistrationPayload => ({
    type: "InitThirdPartyRegistration",
    loginType,
    name,
    surname,
    email,
    oauthToken
});

export const initNativeRegistration = (): simplePayload => ({
    type: "InitNativeRegistration"
});

const initialState: authenticationState = {
    email: "",
    name: "",
    surname: "",
    loginType: LoginType.Native,
    oauthToken: null
}

export default (state: authenticationState = initialState, action: simplePayload): authenticationState => {
    switch(action.type){
        case "InitNativeRegistration":
            return {
                ...initialState,
                loginType: LoginType.Native
            }
        case "InitThirdPartyRegistration":
            const {email,loginType,name,surname,oauthToken} = action as thirdPartyRegistrationPayload;
            return {
                email,
                name,
                loginType,
                surname,
                oauthToken
            };
        case "Logout":
            return {
                ...initialState
            }
        default:
            return state;
    }
}