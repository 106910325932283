export default class CancelablePromise<T>{
    
    private thenCallback: (args: T) => void = () => {};

    private catchCallback: (err) => void = () => {};

    private isCancelled = false;

    public get IsCancelled() {
        return this.isCancelled;
    }
    
    constructor(callback: (resolve: (args: T) => void, reject: (err) => void) => void){
        const resolve = (args: T) => {
            if(this.isCancelled){
                this.catchCallback({isCancelled: true});
            }
            else{
                this.thenCallback(args);
            }
        }
        const reject = (err) => {
            this.catchCallback(err)
        }

        callback(resolve, reject);
    }

    then(callback: (args: T) => void): CancelablePromise<T>{
        this.thenCallback = callback;
        return this;
    }

    catch(callback: (err) => void): CancelablePromise<T>{
        this.catchCallback = callback;
        return this;
    }

    cancel(){
        this.isCancelled = true;
    }
}