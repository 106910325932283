import { Formik, FormikHelpers } from "formik";
import { ErrorCode, GetSubscriptionProlongationInfoResult } from "parkcash-api";
import * as React from "react";
import { useParams } from "react-router-dom";
import Resources from "../../../Resources";
import ErrorView from "../../components/ErrorView";
import Spinner from "../../components/Spinner";
import { DESKTOP_HEADER_DEFAULT_HEIGHT } from "../../layouts/Common/DesktopHeaderBase";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { MOBILE_WIDTH_THRESHOLD } from "../../utils/Constants";
import { getErrorMessage } from "../../utils/ErrorUtils";
import { useWindowSize } from "../../utils/WindowSizeHook";
import ProlongSubscriptionFormState from "./ProlongSubscriptionFormState";
import { getSubscriptionProlongationInfo, getInitialProlongSubscriptionFormState, getProlongSubscriptionValidationSchema, prolongSubscription } from "../SubscriptionsUtils";
import AcceptRegulations from "../formParts/AcceptRegulations";
import BigForm, { BIG_FORM_HEADER_HEIGHT } from "../formParts/BigForm";
import FormWrapper from "../formParts/FormWrapper";
import InvoiceFormPart from "../formParts/InvoiceFormPart";
import PaymentPart, { PAYMENT_PART_HEADER_HEIGHT } from "../formParts/PaymentPart";
import PrologongSubscriptionSpecyficPart from "../formParts/PrologongSubscriptionSpecyficPart";
import TargetFormPart from "../formParts/TargetFormPart";
import Parameters from "./Parameters";
import Data from "./Data";
import {ISelectedPaymentInfo, PaymentMethodsTypes} from "../formParts/PaymentMethod";
import {getWebPaymentConfiguration, validateBlik} from "../../utils/PaymentConfigurationUtils";

export default () => {
    const {windowHeight, windowWidth} = useWindowSize();
    const {purchaseId} = useParams<{purchaseId: string}>();
    const isMobile = windowWidth < MOBILE_WIDTH_THRESHOLD;
    const [initialProgress, setInitialProgress] = React.useState(true);
    const [initialError, setInitialError] = React.useState<ErrorCode>(null); 
    const [errorMessage, setErrorMessage] = React.useState<string>(null);
    const [info, setInfo] = React.useState<GetSubscriptionProlongationInfoResult>(null);
    const [initialValues, setInitialValues] = React.useState<ProlongSubscriptionFormState>(getInitialProlongSubscriptionFormState(null));
    const [selectedPaymentMethodInfo, setSelectedPaymentMethodInfo] = React.useState<ISelectedPaymentInfo>();
    const [allowedPaymentMethods, setAllowedPaymentMethods] = React.useState<PaymentMethodsTypes[]>();
    const [blikError, setBlikError] = React.useState<string>();

    const onSubmit = async (state: ProlongSubscriptionFormState, helpers: FormikHelpers<ProlongSubscriptionFormState>) => {
        if (!validateBlik(selectedPaymentMethodInfo, e => setBlikError(e))) {
            return;
        }

        helpers.setSubmitting(true);
        const {
            isBlikError,
            message,
            paymentUrl
        } = await prolongSubscription(state, purchaseId, selectedPaymentMethodInfo.blikCode);
        if (message) {
            if (isBlikError) {
                setBlikError(message)
            } else {
                helpers.setStatus(message);
            }

        } else {
            window.location.href = paymentUrl;
        }
        helpers.setSubmitting(false);
    }

    React.useEffect(() => {
        const init = async () => {
            setInitialProgress(true);
            const {error, info} = await getSubscriptionProlongationInfo(purchaseId, 1);
            if(typeof error === "number"){
                setInitialError(error)
            }
            else if(!info.isActive){
                setErrorMessage(Resources.Administrator_parkingu_wylaczyl_abonament);
                setInfo(info);
            }
            else if(info.isAlreadyProlonged){
                setErrorMessage(Resources.Abonament_zostal_juz_przedluzony);
                setInfo(info);
            }
            else if(info.isExpired){
                setErrorMessage(Resources.Podany_link_jest_juz_niewazny);
                setInfo(info);
            }
            else{
                setInfo(info);
                setInitialValues(getInitialProlongSubscriptionFormState(info));

                const allowedPaymentMethods = await getWebPaymentConfiguration(info.parkingId);
                setAllowedPaymentMethods(allowedPaymentMethods);
            }
            setInitialProgress(false);
        }
        init();
    }, [])

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            enableReinitialize
            validationSchema={getProlongSubscriptionValidationSchema()}
        >
            {args => {
                const {handleSubmit, isValid, isSubmitting, values, status, setFieldValue} = args;
                const onPromotionCode = async (code: string) => {
                    setFieldValue("discountCode", code);
                    return null;
                }

                return (
                    <FormWrapper isMobile={isMobile}>
                        <div>
                            <Parameters 
                                isMobile={isMobile}
                                errorMessage={errorMessage}
                                info={info}
                                initialError={initialError}
                                initialProgress={initialProgress}
                            />
                            {!initialProgress && !initialError && !errorMessage && (
                                <>
                                    <BaseSpaceSeparator size={30} />
                                    <Data 
                                        args={args}
                                        info={info}
                                        isMobile={isMobile}
                                    />
                                </>
                            )}
                        </div>
                        {!initialProgress && !initialError && !errorMessage && (
                            <>
                                <BaseSpaceSeparator size={isMobile ? 7 : 30} />
                                <PaymentPart 
                                    showTimeRange
                                    periodType={info?.periodType}
                                    validFrom={values?.targetFrom}
                                    validTo={values.targetTo}
                                    isMobile={isMobile}
                                    maxHeight={windowHeight - PAYMENT_PART_HEADER_HEIGHT - DESKTOP_HEADER_DEFAULT_HEIGHT}
                                    status={status}                          
                                    onPay={selectedPaymentMethodInfo =>{
                                     
                                        setSelectedPaymentMethodInfo(selectedPaymentMethodInfo)
                                        handleSubmit();
                                    }}
                                    onPromotionCode={onPromotionCode}
                                    
                                    showPromotionCode={!info?.discountedPrice}
                                    price={values.currentPrice}
                                    lowerPrice={values.discountCodePrice}
                                    priceProgress={values.promotionCodeProgress}
                                    submitProgress={isSubmitting}
                                    isValid={!!values.subscriptionId}
                                    allowedPaymentMethods={allowedPaymentMethods}
                                    onBlikErrorClear={() => setBlikError('')}
                                    blikError={blikError}
                                />
                            </>
                        )}
                        
                    </FormWrapper>
                )
            }}
        </Formik>
    );
}

