import { ArtifactType, CreateArtifactDto, ErrorCode, ParkingSpotsService, ParkingSpotTimelineDaySegmentInfo, ServiceConfig } from "parkcash-api";
import * as React from "react";
import Resources from "../../../Resources";
import Spinner from "../../components/Spinner";
import Colors from "../../styles/Colors";
import { PCText } from "../../styles/Texts";
import { useJWT } from "../../utils/JWTUtils";
import { useWindowSize } from "../../utils/WindowSizeHook";
import Calendar from "./Calendar";
import { CalendarType } from "./Interfaces";
import LeftPanel from "./LeftPanel";
import Timeline from "./Timeline";
import GlobalProgress from "../../components/GlobalProgress";
import Notify from "../../utils/Notify";
import ConfirmModal from "../../components/ConfirmModal";
import ShareParkingSpotForm from "../ShareParkingSpotForm";
import { cancelReservation } from "../../bookings/BookingsUtils";
import { getErrorMessage } from "../../utils/ErrorUtils";
import { addDays } from "../../utils/DateTimeUtils";

export default (props: {
    spotId: string
}) => {
    const jwt = useJWT();
    const {spotId} = props;
    const {windowWidth} = useWindowSize();
    const [progress, setProgress] = React.useState(true);
    const [error, setError] = React.useState<ErrorCode>(null);
    const [timeline, setTimeline] = React.useState<Timeline>(null);
    const [currentDay, setCurrentDay] = React.useState(new Date());
    const [calendarType, setCalendarType] = React.useState(CalendarType.Week);
    const [globalProgress, setGlobalProgress] = React.useState(false);
    const [currentTimelineItem, setCurrentTimelineItem] = React.useState<ParkingSpotTimelineDaySegmentInfo>(null);
    const [shareParkingSpotFormVisible, setShareParkingSpotFormVisible] = React.useState(false);
    const isMobile = (window as any).isMobile() && windowWidth < 1300;

    const init = async (id: string, keepCurrentDate:boolean=true) => {
        try{
            setProgress(true);
            const start = new Date();
            const end = addDays(start, 60);
            const response = await new ParkingSpotsService(new ServiceConfig({jwt})).getParkingSpotTimeline(id, start, end);      
            if(response.isSuccess){
                if(!keepCurrentDate){
                    setCurrentDay(new Date());
                }
                setTimeline(new Timeline(response.result.entries));
                setCurrentTimelineItem(null);
            }   
            else{
                setError(response.error.code);
            }
        }
        catch{
            setError(ErrorCode.NetworkError);
        }
        finally{
            setProgress(false);
        }
    }

    const onDismissReservation = async (reservationId: string) => {
        const accept = await ConfirmModal.show({text: Resources.Czy_na_pewno_chcesz_odwolac_ta_rezerwacje});
        if(accept){
            setGlobalProgress(true);
            const result = await cancelReservation(reservationId);
            if(result){
                init(spotId);
            }
            setGlobalProgress(false);
        }
    }
    const onRemoveSharing = async (start: Date, end: Date) => {
        const accept = await ConfirmModal.show({text: Resources.Czy_na_pewno_chcesz_usunac_udostepnienie, confirmText: Resources.Usun});
        if(accept){
            try{
                setGlobalProgress(true);
                const artifact = new CreateArtifactDto({
                    artifactType: ArtifactType.Occupied,
                    start: start,
                    end: end
                });
                const response = await new ParkingSpotsService(new ServiceConfig({jwt: jwt})).createArtifact(spotId, artifact);
                if(response.isSuccess){
                    init(spotId);
                }
                else{
                    Notify.Error(getErrorMessage(response.error.code));
                }
            }
            catch{
                Notify.Error(getErrorMessage());
            }
            finally{
                setGlobalProgress(false);
            }
        }        
    }

    const onShare = () => {
        setShareParkingSpotFormVisible(true);
    }

    React.useEffect(() => {
        if(spotId){
            init(spotId,false);
        }
    }, [spotId]);

    return (
        <div
            style={{height: 1200}}
        >
            <GlobalProgress visible={globalProgress} />
            <ShareParkingSpotForm 
                showSpotSelector={false}
                spotToShare={spotId}
                visible={shareParkingSpotFormVisible}
                onClose={() => setShareParkingSpotFormVisible(false)}
                onSubmitted={() => {
                    setShareParkingSpotFormVisible(false);
                    init(spotId);
                }}
            />
            {progress && (
                <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Spinner size="large" />
                </div>
            )}
            {!progress && error && (
                <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.white}}>
                    <PCText color={Colors.red} semibold fontSize={18}>{getErrorMessage(error)}</PCText>
                </div>
            )}
            {!progress && !error && (
                <div
                    style={{
                        height: '100%',
                        display: 'flex'
                    }}
                >
                    <LeftPanel 
                        calendarType={calendarType}
                        onCalendarTypeChange={setCalendarType}
                        isMobile={isMobile}
                        currentDay={currentDay}
                        onDaySelected={day => {
                            setCurrentTimelineItem(null);
                            setCurrentDay(day);
                        }}
                        currentItem={currentTimelineItem}
                        timeline={timeline}
                        onDismissReservation={onDismissReservation}
                        onRemoveSharing={onRemoveSharing}
                    />
                    {!isMobile && <div style={{width: 1, backgroundColor: Colors.pale_grey}}/>}
                    {!isMobile && (
                        <Calendar
                            day={currentDay}
                            timeline={timeline}
                            type={calendarType} 
                            onEventSelected={event => {
                                if(event){
                                    setCurrentTimelineItem(event);
                                    setCurrentDay(event.startTimestampDisplay);
                                }
                                
                            }}
                            onShare={onShare}
                        />
                    )}
                </div>
            )}
        </div>
    );
}