import {
  ErrorCode,
  GetParkingMembersResultEntryDto,
  GetPrivateParkingDetailsResult,
  ParkingMemberType,
  ParkingsService,
  RemoveParkingMemberDto,
  ServiceConfig,
} from "parkcash-api";
import * as React from "react";
import Resources from "../../Resources";
import ErrorView from "../components/ErrorView";
import ModalForm from "../components/ModalForm";
import Spinner from "../components/Spinner";
import Colors from "../styles/Colors";
import { PCText } from "../styles/Texts";
import { formatAddress } from "../utils/AddressUtils";
import { useJWT } from "../utils/JWTUtils";
import GroupUsersCount from "./GroupUsersCount";
import {
  faFacebook,
  faFacebookMessenger,
  faWhatsapp,
  IconDefinition,
} from "@fortawesome/free-brands-svg-icons";
import { PCClasses } from "../utils/CSSUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BaseSpaceSeparator } from "../styles/Separators";
import {
  faChevronRight,
  faCopy,
  faEnvelope,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Notify from "../utils/Notify";
import ConfirmModal from "../components/ConfirmModal";
import GlobalProgress from "../components/GlobalProgress";
import { getErrorMessage } from "../utils/ErrorUtils";

const groups = require("../../assets/images/groups.svg");

const ShareLink = (props: {
  children: string;
  icon: IconDefinition;
  to?: string;
  onClick?: () => void;
}) => {
  const { children, icon, onClick, to } = props;

  const className = PCClasses("pc-button");
  const style: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    borderBottomStyle: "solid",
    borderBottomColor: Colors.very_light_pink,
    borderBottomWidth: 1,
    padding: "9px 0px",
    textDecoration: "none",
    outline: "none",
  };

  const content = (
    <>
      <FontAwesomeIcon
        icon={icon}
        color={Colors.brownish_grey}
        style={{ fontSize: 40 }}
      />
      <BaseSpaceSeparator size={25} />
      <PCText color={Colors.brownish_grey} fontSize={16}>
        {children}
      </PCText>
      <div style={{ flex: 1 }} />
      <FontAwesomeIcon
        icon={faChevronRight}
        color={Colors.brownish_grey}
        style={{ fontSize: 17 }}
      />
    </>
  );

  if (to) {
    return (
      <a
        target="_blank"
        href={to}
        className={className}
        style={style}
        onClick={onClick}
      >
        {content}
      </a>
    );
  } else {
    return (
      <div className={className} style={style} onClick={onClick}>
        {content}
      </div>
    );
  }
};

const User = (props: {
  user: GetParkingMembersResultEntryDto;
  onRemove: (u: GetParkingMembersResultEntryDto) => void;
  canRemove: boolean;
}) => {
  const {
    user: { firstName, lastName, memberType },
    onRemove,
    canRemove,
  } = props;

  const isAdmin =
    memberType === ParkingMemberType.Manager ||
    memberType === ParkingMemberType.Moderator;

  const onRemoveClicked = () => {
    onRemove(props.user);
  };

  return (
    <div
      style={{
        padding: "15px 0px",
        borderBottomWidth: 1,
        borderBottomColor: Colors.very_light_pink,
        borderBottomStyle: "solid",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <PCText fontSize={16} color={Colors.black}>
        {firstName} {lastName}
      </PCText>
      {isAdmin && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 90,
            height: 18,
            backgroundColor: Colors.light_royal_blue,
            borderRadius: 9,
          }}
        >
          <PCText color={Colors.white} semibold fontSize={12}>
            {Resources.Administrator}
          </PCText>
        </div>
      )}
      {!isAdmin && canRemove && (
        <div className={PCClasses("pc-button")} onClick={onRemoveClicked}>
          <FontAwesomeIcon
            icon={faTrashAlt}
            color={Colors.light_royal_blue}
            style={{ fontSize: 17 }}
          />
        </div>
      )}
    </div>
  );
};

export default (props: {
  groupId: string;
  userId: string;
  visible: boolean;
  onClose: () => void;
}) => {
  const { groupId, userId, visible, onClose } = props;
  const [progress, setProgress] = React.useState(true);
  const [error, setError] = React.useState<ErrorCode>(null);
  const [details, setDetails] =
    React.useState<GetPrivateParkingDetailsResult>(null);
  const [users, setUsers] =
    React.useState<GetParkingMembersResultEntryDto[]>(null);
  const [isManager, setIsManager] = React.useState(false);
  const [removeProgress, setRemoveProgress] = React.useState(false);

  const init = async () => {
    if (groupId) {
      try {
        const jwt = useJWT();
        setProgress(true);
        setError(null);
        const [infoResponse, usersResponse] = await Promise.all([
          new ParkingsService(
            new ServiceConfig({ jwt })
          ).getPrivateParkingDetails(groupId),
          new ParkingsService(new ServiceConfig({ jwt })).getParkingMembers(
            groupId,
            "",
            "",
            1,
            2000
          ),
        ]);
        if (!infoResponse.isSuccess) {
          setError(infoResponse.error.code);
        } else if (!usersResponse.isSuccess) {
          setError(usersResponse.error.code);
        } else {
          setDetails(infoResponse.result);
          const members = usersResponse.result.entries;
          setUsers(members);
          setIsManager(
            members.some(
              (m) =>
                m.userId === userId &&
                (m.memberType === ParkingMemberType.Manager ||
                  m.memberType === ParkingMemberType.Moderator)
            )
          );
        }
      } catch (e) {
        const error = e;
        setError(ErrorCode.NetworkError);
      } finally {
        setProgress(false);
      }
    }
  };

  const onRemoveUser = async (user: GetParkingMembersResultEntryDto) => {
    const confirm = await ConfirmModal.show({
      text: Resources.Czy_na_pewno_chcesz_usunac_uzytkownika_z_grupy,
      confirmText: Resources.Usun,
    });
    if (confirm) {
      try {
        const jwt = useJWT();
        setRemoveProgress(true);
        const dto = new RemoveParkingMemberDto({
          parkingId: groupId,
          userToRemoveId: user.userId,
        });
        const response = await new ParkingsService(
          new ServiceConfig({ jwt })
        ).removeParkingMember(dto);
        if (response.isSuccess) {
          setUsers(users.filter((u) => u.userId !== user.userId));
        } else {
          if (
            response.error.code === ErrorCode.PowerUserCanNotLeaveGroupByHimself
          ) {
            Notify.Error(
              Resources.Administrator_lub_moderator_nie_moze_opuscic_grupy
            );
          } else {
            Notify.Error(Resources.Nastapil_blad);
          }
        }
      } catch (e) {
        Notify.Error(Resources.Nastapil_blad);
      } finally {
        setRemoveProgress(false);
      }
    }
  };

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(details?.invitationLink);
    Notify.Info(Resources.Skopiowano_do_schowka);
  };

  React.useEffect(() => {
    init();
  }, [groupId]);

  return (
    <ModalForm
      paddingLeft={40}
      paddingRight={40}
      onSubmit={null}
      initialValues={{}}
      iconWidth={120}
      iconHeight={112}
      icon={groups}
      onClose={onClose}
      title={details?.friendlyName}
      showSubmitButton={false}
      visible={visible}
      subtitle={formatAddress(details?.address, true)}
      top={() => (
        <div style={{}}>
          <GroupUsersCount usersCount={users?.length} />
        </div>
      )}
    >
      {() => {
        if (progress) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 100,
              }}
            >
              <Spinner size="small" />
            </div>
          );
        }
        if (typeof error === "number") {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ErrorView title={getErrorMessage(error)} />
            </div>
          );
        }

        return (
          <>
            <GlobalProgress visible={removeProgress} />
            <PCText
              color={Colors.greyishBrown}
              textAlign="center"
              fontSize={18}
            >
              {Resources.Zapros_do_grupy}{" "}
              <PCText
                inline
                semibold
                fontSize={18}
                color={Colors.light_royal_blue}
              >
                {details?.friendlyName}
              </PCText>{" "}
              {Resources.i_ciesz_sie_blyskawiczna_rezerwacja}
            </PCText>
            <BaseSpaceSeparator size={30} />
            <ShareLink icon={faWhatsapp}>WhatsApp</ShareLink>
            <ShareLink icon={faFacebook}>Facebook</ShareLink>
            <ShareLink
              to={`fb-messenger://share/?link= https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fsharing%2Freference%2Fsend-dialog&app_id=123456789`}
              icon={faFacebookMessenger}
            >
              Messenger
            </ShareLink>
            <ShareLink
              to={`mailto:?body=${details?.invitationLink}`}
              icon={faEnvelope}
            >
              Email
            </ShareLink>
            <ShareLink onClick={onCopyToClipboard} icon={faCopy}>
              {Resources.Kopiuj_link}
            </ShareLink>
            <BaseSpaceSeparator size={50} />
            <PCText
              fontSize={18}
              semibold
              textAlign="center"
              color={Colors.black}
            >
              {Resources.Uzytkownicy}
            </PCText>
            {users.map((user) => (
              <User
                canRemove={isManager}
                user={user}
                key={user.userId}
                onRemove={onRemoveUser}
              />
            ))}
          </>
        );
      }}
    </ModalForm>
  );
};
