import {FormikHelpers} from "formik";
import * as React from "react";
import ModalForm from "../../components/ModalForm";
import {getFormState} from "../../parkingSpots/ShareParkingSpotForm/Utils";
import * as yup from "yup";
import Resources from "../../../Resources";
import RegularInput from "../../components/forms/RegularInput";
import {EMAIL_REGEX, PHONE_MASK, PHONE_REGEX} from "../../utils/Constants";
import {getNameAndSurname, NAME_AND_SURNAME_FORMAT} from "../../utils/UserInfoUtils";
import {getErrorMessage, getErrorMessageNew} from "../../utils/ErrorUtils";
import {useJWT} from "../../utils/JWTUtils";
import {CreateParkingMemberDto, ParkingManagerService, ParkingMemberType, ServiceConfig} from "parkcash-api";
import RegularDropdownList from "../../components/forms/RegularDropdownList";
import {getText} from "./Utils";
import RegularDateTimePicker from "../../components/forms/RegularDateTimePicker";
import Colors from "../../styles/Colors";
import {Bo} from "react-flags-select";
import {SwitchWithLabel} from "../../components/Switch";
import {nameof} from "../../utils/NameOf";
import {CyclicFormState} from "../../parkingSpots/ShareParkingSpotForm/ShareParkingSpotFormState";

const driver = require("../../../assets/images/driver.svg");
const now = new Date();

interface FormState {
    nameAndSurname: string,
    email: string,
    memberType: ParkingMemberType,
    validFrom: Date
    validTo: Date,
    allowFreeBooking: boolean
    isUnrestrictedAccess: boolean;
}

export default (props: {
    visible: boolean, onClose: () => void; onSubmitted: () => void; parkingId: string;
}) => {
    const {
        onClose,
        visible,
        onSubmitted,
        parkingId
    } = props;
    const jwt = useJWT();
    const initialValues: FormState = {
        email: "",
        nameAndSurname: "",
        memberType: ParkingMemberType.User,
        validFrom: null,
        validTo: null,
        allowFreeBooking: false,
        isUnrestrictedAccess: true,
    }

    const onSubmit = async ({
                                nameAndSurname,
                                email,
                                memberType,
                                validFrom,
                                validTo,
                                allowFreeBooking
                            }: FormState, {
                                setStatus,
                                setSubmitting
                            }: FormikHelpers<FormState>) => {
        try {
            setSubmitting(true);
            const {
                name,
                surname
            } = getNameAndSurname(nameAndSurname);
            const {
                isSuccess,
                error
            } = await new ParkingManagerService(new ServiceConfig({jwt})).createParkingMember(new CreateParkingMemberDto(
                {
                    firstName: name,
                    lastName: surname,
                    userName: email,
                    parkingId,
                    parkingMemberType: memberType,
                    membershipValidFrom: validFrom || undefined,
                    membershipValidTo: validTo || undefined,
                    allowFreeBooking: allowFreeBooking
                }));
            if (isSuccess) {
                onSubmitted();
            } else {
                setStatus(getErrorMessageNew(error));
            }
        } catch {
            setStatus(getErrorMessage());
        } finally {
            setSubmitting(false);
        }
    }

    const validationSchema = yup.object().shape({
        email: yup.string().required(Resources.Wymagane).matches(EMAIL_REGEX, Resources.Niepoprawny_format),
        nameAndSurname: yup.string()
                           .required(Resources.Wymagane)
                           .matches(NAME_AND_SURNAME_FORMAT, Resources.Niepoprawny_format)
    })

    return (<ModalForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            icon={driver}
            iconHeight={110}
            iconWidth={110}
            visible={visible}
            onClose={onClose}
            title={Resources.Dodaj_uzytkownika}
            showCancelButton
        >
            {({
                  values,
                  errors,
                  touched,
                  setFieldTouched,
                  setFieldValue
              }) => {
                return (<>
                        <RegularInput
                            label={Resources.Imie_i_Nazwisko}
                            value={values.nameAndSurname}
                            error={errors.nameAndSurname}
                            touched={touched.nameAndSurname}
                            name="nameAndSurname"
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                        />
                        <RegularInput
                            label={Resources.Email}
                            value={values.email}
                            error={errors.email}
                            touched={touched.email}
                            name="email"
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                        />
                        <RegularDropdownList
                            label={Resources.Rola}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            value={values.memberType}
                            touched={touched.memberType}
                            error={errors.memberType}
                            name={"memberType"}
                            actions={[
                                {
                                    id: ParkingMemberType.User,
                                    text: getText(ParkingMemberType.User)
                                },
                                {
                                    id: ParkingMemberType.Manager,
                                    text: getText(ParkingMemberType.Manager)
                                },
                                {
                                    id: ParkingMemberType.Staff,
                                    text: getText(ParkingMemberType.Staff)
                                },
                                {
                                    id: ParkingMemberType.Moderator,
                                    text: getText(ParkingMemberType.Moderator)
                                },
                            ]}
                            borderColor={Colors.brownish_grey}
                        />
                        <SwitchWithLabel
                            value={values.isUnrestrictedAccess}
                            onChange={v => {
                                setFieldValue(nameof<FormState>("isUnrestrictedAccess"), v);
                                if (v) {
                                    setFieldValue(nameof<FormState>("validFrom"), undefined);
                                    setFieldValue(nameof<FormState>("validTo"), undefined);
                                }
                            }}
                            label={Resources.Nieograniczony_dostep}
                        />
                        {!values.isUnrestrictedAccess && (<>
                            <RegularDateTimePicker
                                error={errors.validFrom}
                                touched={touched.validFrom}
                                value={values.validFrom}
                                name="validFrom"
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                label={Resources.Waznosc_od}
                                min={now}
                            />
                            <RegularDateTimePicker
                                error={errors.validTo}
                                touched={touched.validTo}
                                value={values.validTo}
                                name="validTo"
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                label={Resources.Waznosc_do}
                                min={now}
                            />
                        </>)}
                        <SwitchWithLabel
                            value={values.allowFreeBooking}
                            onChange={v => setFieldValue(nameof<FormState>("allowFreeBooking"), v)}
                            label={Resources.Darmowa_rezerwacja}
                        />
                    </>)
            }}
        </ModalForm>)
}