import {
  AvailibilityStatus,
  CancelSubscriptionPurchaseDto,
  ErrorCode,
  GetAllSpotsAvailibilityDataForParkingEntry,
  ParkingManagerService,
  ParkingSpotsService,
  RemoveBuyerProlongationPrecedenceActionHandlerDto,
  ResendResult,
  ServiceConfig,
  SubscriptionsService,
} from "parkcash-api";
import * as React from "react";
import { connect, useSelector } from "react-redux";
import Resources from "../../../Resources";
import EmptyList from "../../components/EmptyList";
import ErrorView from "../../components/ErrorView";
import ParkingMap from "../../components/ParkingMap";
import {
  getParkingMap,
  getSpotInfo,
  ParkingMapDataResponseResult,
  ParkingMapSpotInfo,
} from "../../components/ParkingMap/Data";
import Spinner from "../../components/Spinner";
import { applicationState } from "../../redux/ApplicationState";
import { BaseSpaceSeparator } from "../../styles/Separators";
import { FilterOperator } from "../../utils/DataSource";
import { getErrorMessage } from "../../utils/ErrorUtils";
import Notify from "../../utils/Notify";
import { IParkCashApplicationMode } from "../../utils/ParkCashApplicationMode";
import { getFilters } from "../../utils/SieveUtils";
import { useWindowSize } from "../../utils/WindowSizeHook";
import LeftPanel, { LEFT_PANEL_WIDTH } from "./LeftPanel";
import * as uuid from "uuid";
import BuySubscriptionForm from "../ParkingProcesses/BuySubscriptionForm";
import ProlongSubscriptionForm from "../ParkingProcesses/ProlongSubscriptionForm";
import ConfirmModal from "../../components/ConfirmModal";
import GlobalProgress from "../../components/GlobalProgress";
import ChangeSubscriptionPurchaseForm from "../ParkingProcesses/ChangeSubscriptionPurchaseForm";
import ParkingSpotManagementForm from "./ParkingSpotManagementForm";
import CreateExternalReservationForm from "../ParkingProcesses/CreateExternalReservationForm";

interface OwnProps {
  onMapCustomClassRetrieved: (m: string) => void;
}

interface Props {
  jwt: string;
  applicationMode: IParkCashApplicationMode;
}

const getParkingState = async (
  parkingId: string,
  date: Date,
  level: string,
  sector: string,
  jwt: string
): Promise<{ error?: ErrorCode; spots?: ParkingMapSpotInfo[] }> => {
  try {
    const filter: Array<{
      key: keyof GetAllSpotsAvailibilityDataForParkingEntry;
      operator: FilterOperator;
      value: any;
    }> = [];
    if (level) {
      filter.push({
        key: "level",
        operator: FilterOperator.EQUALS,
        value: level,
      });
    }
    if (sector) {
      filter.push({
        key: "sector",
        operator: FilterOperator.EQUALS,
        value: sector,
      });
    }
    const response = await new ParkingManagerService(
      new ServiceConfig({ jwt })
    ).getAllSpotsAvailibilityDataForParking(
      parkingId,
      date,
      getFilters(filter),
      "",
      1,
      10000
    );
    if (response.isSuccess) {
      return {
        spots: response.result.entries.map<ParkingMapSpotInfo>((e) => ({
          id: e.id,
          level: e.level,
          number: e.number,
          sector: e.sector,
          status: e.status,
        })),
      };
    } else {
      return { error: response.error.code };
    }
  } catch {
    return { error: ErrorCode.NetworkError };
  }
};

export default (props: OwnProps) => {
  const { onMapCustomClassRetrieved } = props;
  const { applicationMode, jwt } = useSelector<applicationState, Props>(
    (s) => ({ applicationMode: s.user.applicationMode, jwt: s.user.token })
  );
  const parkingId = applicationMode.getCurrentParking()?.parkingId;
  const hasMap = applicationMode.hasParkingPlanInCurrentParking();
  const { isMobile, windowHeight, windowWidth } = useWindowSize();
  const [spotId, setSpotId] = React.useState<string>(null);
  const [date, setDate] = React.useState<Date>(new Date());
  const [spotStatus, setSpotStatus] = React.useState<AvailibilityStatus>(null);
  const [progress, setProgress] = React.useState(true);
  const [error, setError] = React.useState<ErrorCode>(null);
  const [mapResult, setMapResult] =
    React.useState<ParkingMapDataResponseResult>();
  const [dateShown, setDateShown] = React.useState(false);
  const [buySubscriptionFormVisible, setBuySubscriptionFormVisible] =
    React.useState(false);
  const [mapRefreshKey, setMapRefreshKey] = React.useState(uuid.v1());
  const [prolongSubscriptionFormVisible, setProlongSubscriptionFormVisible] =
    React.useState(false);
  const [prolongPurchaseId, setProlongPurchaseId] =
    React.useState<string>(null);
  const [globalProgress, setGlobalProgress] = React.useState(false);
  const [
    changeSubscriptionPurchaseSpotFormVisible,
    setChangeSubscriptionPurchaseSpotFormVisible,
  ] = React.useState(false);
  const [changeSubscriptionPurchaseId, setChangeSubscriptionPurchaseId] =
    React.useState<string>(null);
  const [buySubscriptionSpotId, setBuySubscriptionSpotId] =
    React.useState<string>(null);
  const [createReservationFormVisible, setCreateReservationFormVisible] =
    React.useState(false);
  const [externalReservationSpotId, setExternalReservationSpotId] =
    React.useState<string>(null);

  const onProlong = (pId: string) => {
    setProlongPurchaseId(pId);
    setSpotId(null);
    setProlongSubscriptionFormVisible(true);
  };

  const onEnd = async () => {
    const confirm = await ConfirmModal.show({
      text: Resources.Czy_na_pewno_chcesz_zwolnic_miejsce_i_umozliwic_wykupienie_na_nim_nowych_abonamentow,
      confirmText: Resources.Zwolnij_miejsce,
    });
    setSpotId(null);
    if (confirm) {
      try {
        setGlobalProgress(true);
        const response = await new SubscriptionsService(
          new ServiceConfig({ jwt })
        ).removeBuyerProlongationPrecedence(
          new RemoveBuyerProlongationPrecedenceActionHandlerDto({
            parkingSpotId: spotId,
          })
        );
        if (response.isSuccess) {
          setMapRefreshKey(uuid.v1());
        } else {
          Notify.Error(getErrorMessage(response.error.code));
        }
      } catch {
        Notify.Error(getErrorMessage());
      } finally {
        setGlobalProgress(false);
      }
    }
  };

  const onBuyAbonament = () => {
    setBuySubscriptionSpotId(spotId);
    setSpotId(null);
    setBuySubscriptionFormVisible(true);
  };

  const onExternalReservation = () => {
    setExternalReservationSpotId(spotId);
    setSpotId(null);
    setCreateReservationFormVisible(true);
  };

  React.useEffect(() => {
    setSpotId(null);
  }, [parkingId]);

  React.useEffect(() => {
    if (mapResult) {
      onMapCustomClassRetrieved(mapResult.customClass);
    }
  }, [mapResult]);

  React.useEffect(() => {
    const init = async (pId: string, hMap: boolean) => {
      if (hMap) {
        setProgress(true);
        setError(null);
        const { error, result } = await getParkingMap(pId);
        if (error) {
          setError(error);
        } else {
          setMapResult(result);
        }
        setProgress(false);
      } else {
        setProgress(false);
      }
    };

    init(parkingId, hasMap);
  }, [parkingId, hasMap]);

/*  const contentHeight = isMobile ? "auto" : windowHeight - 223;*/
  const contentHeight = "auto";

  const onEditAbonament = React.useCallback((purchaseId: string) => {
    setSpotId(null);
    setChangeSubscriptionPurchaseId(purchaseId);
    setChangeSubscriptionPurchaseSpotFormVisible(true);
  }, []);

  const onCancelAbonament = React.useCallback(async (purchaseId: string) => {
    const confirm = await ConfirmModal.show({
      text: Resources.Czy_na_pewno_chcesz_usunac_abonament,
      confirmText: Resources.Usun,
    });
    if (!confirm) {
      return;
    }
    try {
      setGlobalProgress(true);
      const { isSuccess, error } = await new SubscriptionsService(
        new ServiceConfig({ jwt })
      ).cancelSubscriptionPurchase(
        new CancelSubscriptionPurchaseDto({
          subscriptionPurchaseId: purchaseId,
        })
      );
      if (isSuccess) {
        setMapRefreshKey(uuid.v1());
        setSpotId(null);
      } else {
        Notify.Error(getErrorMessage(error.code));
      }
    } catch {
      Notify.Error(getErrorMessage());
    } finally {
      setGlobalProgress(false);
    }
  }, []);

  if (!hasMap) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: contentHeight,
        }}
      >
        <EmptyList
          title={Resources.Funkcjonalnosc_niedostepna}
          subtitle={
            Resources.Jezeli_jestes_zainteresowany_planem_na_swoim_parkingu_skontaktuj_sie_z_naszym_dzialem_sprzedazy
          }
        />
      </div>
    );
  }

  if (progress) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: contentHeight,
        }}
      >
        <Spinner size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: contentHeight,
        }}
      >
        <ErrorView title={getErrorMessage(error)} />
      </div>
    );
  }

  return (
    <div
      style={{
        height: contentHeight,
        display: "flex",
        flexDirection: isMobile ? "column-reverse" : "row",
      }}
      key={`${parkingId}`}
    >
      <GlobalProgress visible={globalProgress} />
      <ChangeSubscriptionPurchaseForm
        jwt={jwt}
        parkingId={applicationMode.getCurrentParking()?.parkingId}
        onClose={() => setChangeSubscriptionPurchaseSpotFormVisible(false)}
        onSubmitted={() => {
          setChangeSubscriptionPurchaseSpotFormVisible(false);
          setMapRefreshKey(uuid.v1());
        }}
        purchaseId={changeSubscriptionPurchaseId}
        visible={changeSubscriptionPurchaseSpotFormVisible}
      />
      {applicationMode.hasExternalReservations() && (
        <CreateExternalReservationForm
          jwt={jwt}
          parkingId={applicationMode.getCurrentParking()?.parkingId}
          visible={createReservationFormVisible}
          onClose={() => {
            setCreateReservationFormVisible(false);
            setExternalReservationSpotId(null);
          }}
          spotId={externalReservationSpotId}
          onSubmitted={() => {
            setCreateReservationFormVisible(false);
            setExternalReservationSpotId(null);
            setMapRefreshKey(uuid.v1());
          }}
          onlySpecyficPlace
        />
      )}
      <BuySubscriptionForm
        parkingId={parkingId}
        jwt={jwt}
        visible={buySubscriptionFormVisible}
        onClose={() => {
          setBuySubscriptionFormVisible(false);
          setBuySubscriptionSpotId(null);
        }}
        onSubmitted={() => {
          setBuySubscriptionFormVisible(false);
          setMapRefreshKey(uuid.v1());
          setBuySubscriptionSpotId(null);
        }}
        spotId={buySubscriptionSpotId}
      />
      <ProlongSubscriptionForm
        jwt={jwt}
        onClose={() => setProlongSubscriptionFormVisible(false)}
        onSubmitted={() => {
          setProlongSubscriptionFormVisible(false);
          setMapRefreshKey(uuid.v1());
        }}
        purchaseId={prolongPurchaseId}
        visible={prolongSubscriptionFormVisible}
      />
      <ParkingSpotManagementForm
        status={spotStatus}
        spotId={spotId}
        hasExternalReservations={applicationMode.hasExternalReservations()}
        onClose={() => {
          setSpotId(null);
          setSpotStatus(null);
        }}
        parkingId={parkingId}
        onBuyAbonament={onBuyAbonament}
        onProlongAbonament={onProlong}
        onFreePlace={onEnd}
        onExternalReservation={onExternalReservation}
        onCancelAbonament={onCancelAbonament}
        onEditAbonament={onEditAbonament}
      />
      <div
        style={{
          flex: isMobile ? undefined : 1,
        }}
      >
        <ParkingMap
          refreshKey={`${date.toDateString()} | ${mapRefreshKey}`}
          height={contentHeight}
          width={isMobile ? "100%" : windowWidth - 100}
          mapResult={mapResult}
          onSpotSelected={(id, status) => {
            setSpotId(id);
            setSpotStatus(status);
          }}
          spotId={spotId}
          getSpotsCallback={async (level: string, sector: string) =>
            getParkingState(parkingId, date, level, sector, jwt)
          }
        />
      </div>
    </div>
  );
};
