export class TwoWayMap<K, V> {
    private keyToValue = new Map<K, V>();
    private valueToKey = new Map<V, K>();

    constructor(initialMap?: { [key: string]: V }) {
        if (initialMap) {
            for (const key in initialMap) {
                this.set(key as any, initialMap[key]);
            }
        }
    }

    set(key: K, value: V): void {
        if (this.keyToValue.has(key) || this.valueToKey.has(value)) {
            throw new Error('This key or value is already in the map');
        }

        this.keyToValue.set(key, value);
        this.valueToKey.set(value, key);
    }

    getKey(value: V): K | undefined {
        return this.valueToKey.get(value);
    }

    getValue(key: K): V | undefined {
        return this.keyToValue.get(key);
    }

    hasKey(key: K): boolean {
        return this.keyToValue.has(key);
    }

    hasValue(value: V): boolean {
        return this.valueToKey.has(value);
    }

    deleteByKey(key: K): boolean {
        const value = this.keyToValue.get(key);

        if (value) {
            this.valueToKey.delete(value);
        }

        return this.keyToValue.delete(key);
    }

    deleteByValue(value: V): boolean {
        const key = this.valueToKey.get(value);

        if (key) {
            this.keyToValue.delete(key);
        }

        return this.valueToKey.delete(value);
    }
}