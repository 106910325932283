import { FormikHelpers } from "formik";
import { ManagerModifyMobilePilotLinkDto, ParkingManagerService, ServiceConfig, TimeRange } from "parkcash-api";
import * as React from "react";
import * as yup from "yup";
import Resources from "../../../Resources";
import RegularDatetimePicker from "../../components/forms/RegularDateTimePicker";
import ModalForm from "../../components/ModalForm";
import { getErrorMessage } from "../../utils/ErrorUtils";
import { useJWT } from "../../utils/JWTUtils";
import {Asserts} from "yup";

const pilot = require("../../../assets/images/pilot.svg");

const validationSchema = yup.object({
    validFrom: yup.date().required(Resources.Wymagane),
    validTo: yup.date().required(Resources.Wymagane)
});

interface FormState extends Asserts<typeof validationSchema> {}

export default (props: {
    visible: boolean,
    onClose: () => void,
    linkId: string,
    validTo: Date,
    validFrom: Date,
    onSubmitted: (validFrom: Date, validTo: Date) => void
}) => {
    const jwt = useJWT();
    const {onClose,visible,linkId,onSubmitted,validFrom,validTo} = props;
    const initialValues: FormState = {
        validFrom,
        validTo
    }
   
    const onSubmit = async (state: FormState, helpers: FormikHelpers<FormState>) => {
        const {validTo, validFrom} = state;
        const {setSubmitting} = helpers;

        try{
            setSubmitting(true);

            const accessFrom = new Date(validFrom.getFullYear(), validFrom.getMonth(), validFrom.getDate(), validFrom.getHours(), validFrom.getMinutes());
            const accessTo = new Date(validTo.getFullYear(), validTo.getMonth(), validTo.getDate(), validTo.getHours(), validTo.getMinutes());

            const {isSuccess, error} = await new ParkingManagerService(new ServiceConfig({jwt})).modifyMobilePilotLink(linkId, new ManagerModifyMobilePilotLinkDto({
                timeRange: new TimeRange({
                    validFrom: accessFrom,
                    validTo: accessTo
                })
                
            }));
            if(isSuccess){
                onSubmitted(validFrom, validTo)
            }
            else{
                helpers.setStatus(getErrorMessage(error.code));
            }
        }
        catch(e){
            const error = e;
            helpers.setStatus(getErrorMessage());
        }
        finally{
            setSubmitting(false);
        }
    }

    return (
        <ModalForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            icon={pilot}
            iconWidth={100}
            iconHeight={114}
            visible={visible}
            onClose={onClose}
            title={Resources.Edytuj_udostepnienie_czasowe_pilota}
        >
        {args => {
            const {values, touched, errors, setFieldTouched, setFieldValue} = args;

            return (
                <>
                    <RegularDatetimePicker 
                        label={Resources.Waznosc_od}
                        value={values.validFrom}
                        error={errors.validFrom}
                        touched={touched.validFrom}
                        name="validFrom"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                    /> 
                    <RegularDatetimePicker 
                        label={Resources.Waznosc_do}
                        value={values.validTo}
                        error={errors.validTo}
                        touched={touched.validTo}
                        name="validTo"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                    /> 
                </>
            )
        }}
        </ModalForm>
    );
}