import * as React from "react";
import Colors from "../../styles/Colors";
import {PCClasses} from "../../utils/CSSUtils";
import "../DropdownList.scss";
import {PCText} from "../../styles/Texts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMarker, faParking} from "@fortawesome/free-solid-svg-icons";
import Resources from "../../../Resources";
import {BaseSpaceSeparator} from "../../styles/Separators";
import {formatAddress} from "../../utils/AddressUtils";
import Input from "./Input";
import GoogleMapsApiManager, {searchLocation} from "../../utils/GoogleMapsApiManager";
import CancelablePromise from "../../utils/CancelablePromise";
import Spinner from "../Spinner";
import {SpotCreationMode} from "parkcash-api";

export interface SearchAddressInputProps {
    current: searchAddressItem,
    onSelected?: (v: searchAddressItem) => void,
    predefinedItems?: searchAddressItem[];

    isInvalid?: boolean;
    zIndex?: number;

    label?: string,
    disabled?: boolean,
    borderColor?: string;
    variant?: "big" | "standard"
    placeholder?: string;

    onFocus?: () => void;
    onBlur?: () => void;
    showValidatedButton?: boolean;
}

interface State {
    width: number,
    height: number,
    opacity: 0 | 1,
    display: "block" | "none",

    search: string,
    searchProgress: boolean,
    selectedIndex: number,
    searchProcessInProgress: boolean,
    searchedItems: searchAddressItem[];
}

const ANIMATION_DURATION = 200;

export interface searchAddressItem {
    id: string;

    type: "parking" | "location";
    country?: string;
    city?: string;
    streetName?: string;
    streetNumber?: string;
    lat?: number;
    lon?: number;

    address?: string;

    spotCreationMode?: SpotCreationMode;
}

export default class SearchAddressInput extends React.Component<SearchAddressInputProps, State> {

    private wrapper: HTMLDivElement;

    private input: Input = null;

    private searchPromise: CancelablePromise<searchLocation[]>;

    private searchTimeout;

    private sessionToken = new google.maps.places.AutocompleteSessionToken();

    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            display: "none",
            opacity: 0,
            search: "",
            searchProcessInProgress: false,
            searchProgress: false,
            selectedIndex: null,
            searchedItems: []
        }
    }

    private get searchWasDone() {
        const {
            searchProcessInProgress,
            search
        } = this.state;
        return searchProcessInProgress && !!search;
    }

    focus() {
        this.input?.focus();
    }

    blur() {
        this.input?.blur();
    }

    componentDidUpdate() {
        this.updateDimensions();
    }

    componentDidMount() {
        this.updateDimensions();
    }

    render() {
        const {
            placeholder = Resources.Wpisz_adres,
            isInvalid,
            zIndex = 1,
            label,
            variant,
            disabled,
            onBlur,
            onFocus,
            current,
            showValidatedButton
        } = this.props;

        return (<div
                ref={i => this.wrapper = i}
                style={{
                    width: "100%",
                    minHeight: 58,
                    boxSizing: "border-box",
                    position: "relative",
                    pointerEvents: disabled ? "none" : undefined
                }}
            >
                <div
                    style={{
                        zIndex: zIndex + 1,
                        position: "absolute",
                        cursor: "pointer",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}
                >
                    <Input
                        ref={i => {
                            this.input = i;
                        }}
                        value={this.getInputText()}
                        onChangeText={this.onSearchChange}
                        borderColor={this.getBorderColor()}
                        onKeyDown={this.onKeyDown}
                        variant={variant}
                        placeholder={placeholder}
                        isInvalid={isInvalid}
                        label={label}
                        showValidatedButton={showValidatedButton}
                        disabled={disabled}
                        onBlur={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            onBlur && onBlur();
                            setTimeout(() => {
                                this.setState({
                                    searchProcessInProgress: false
                                });
                                this.hideContent();
                                this.input?.blur();
                            }, 100);
                        }}
                        onFocus={() => {
                            const text = this.getText(current);
                            this.setState({
                                search: text
                            }, () => {
                                if (text) {
                                    this.onSearchChange(text);
                                }
                                this.setState({
                                    searchProcessInProgress: true,
                                    selectedIndex: null,
                                    searchProgress: false,
                                })
                                onFocus && onFocus();
                                this.showContent();
                            });
                        }}
                    />
                </div>
                {this.renderContent()}
            </div>);
    }

    private updateDimensions = () => {
        const {
            width,
            height
        } = this.state;
        const newHeight = this.wrapper?.clientHeight || 0;
        const newWidth = this.wrapper?.clientWidth || 0;
        if (newWidth !== width || newHeight !== height) {
            this.setState({
                height: newHeight,
                width: newWidth
            });
        }
    }

    private showContent = () => {
        this.setState({display: "block"});
        setImmediate(() => {
            this.setState({opacity: 1});
        });
    }

    private hideContent = () => {
        this.setState({opacity: 0});
        setTimeout(() => {
            this.setState({display: 'none'});
        }, ANIMATION_DURATION);

    }

    private onSelected = async (item: searchAddressItem) => {

        const {onSelected} = this.props;
        if (item.type === "location") {
            this.setState({searchProgress: true});
            console.log()
            const address = await GoogleMapsApiManager.GetCoordinatesForPlaceId(
                item.id,
                GoogleMapsApiManager.GetStreetNumberDetailPart(item.address)
            );
            onSelected && onSelected({
                ...item, ...address
            });
            this.setState({searchProgress: false});
        } else {
            onSelected && onSelected(item);
        }

        this.hideContent();
        this.input?.blur();
    }

    private getBorderColor = () => {
        const {borderColor = Colors.very_light_pink} = this.props;
        return borderColor;
    }

    private getText = (i: searchAddressItem) => {
        if (!i) {
            return "";
        }
        const {
            streetName,
            streetNumber,
            city,
            address
        } = i;
        if (streetName) {
            return formatAddress({
                streetNumber,
                streetName,
                city
            }, true);
        } else {
            return address;
        }
    }

    private getIcon = (i: searchAddressItem) => {
        const icon = i.type === "location" ? faMarker : faParking;
        return <FontAwesomeIcon icon={icon} color={Colors.light_royal_blue} style={{fontSize: 16}}/>
    }

    private getItems = () => {
        const {searchedItems = []} = this.state;
        const {predefinedItems = []} = this.props;
        return this.searchWasDone ? searchedItems : predefinedItems
    }

    private renderContent() {
        const {
            width,
            height,
            opacity,
            display,
            selectedIndex,
            searchProgress
        } = this.state;
        const {zIndex = 1} = this.props;
        if (!height || !width) {
            return null;
        }
        const items = this.getItems();

        return (<div
                style={{
                    transition: `opacity ${ANIMATION_DURATION}ms`,
                    display,
                    opacity,
                    width: width,
                    transform: `translate(${0}px, ${height - 39}px)`,
                    zIndex,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    backgroundColor: Colors.white,
                    borderRadius: 25,
                    padding: "49px 7px 10px 10px",
                    boxSizing: "border-box",
                    border: `1px ${this.getBorderColor()} solid`
                }}
            >
                {searchProgress && (<div style={{
                        height: 43,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Spinner size="extra-small"/>
                    </div>)}
                {!searchProgress && this.searchWasDone && !items.length && (<div style={{
                        height: 43,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <PCText textAlign="center" fontSize={16} semibold
                                color={Colors.black}>{Resources.Nie_znaleziono_adresu}</PCText>
                    </div>)}
                {!searchProgress && !!items.length && (<>
                        {items.map((item, index, arr) => {
                            const {id} = item;
                            const className = PCClasses([
                                "pc-dropdownlist-link",
                                "pc-button"
                            ], {"pc-dropdownlist-link-current": index === selectedIndex});
                            const separator = arr.length > 1 && index > 0;
                            return (<React.Fragment key={id}>
                                    {separator && <div style={{
                                        height: 1,
                                        backgroundColor: Colors.very_light_pink
                                    }}/>}
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                         onClick={() => {
                                             this.onSelected(item).then();
                                         }}
                                    >
                                        {this.getIcon(item)}
                                        <BaseSpaceSeparator size={5}/>
                                        <div
                                            className={className}
                                            style={{
                                                fontFamily: "SourceSansProRegular",
                                                padding: "10px 0px",
                                                fontSize: 16,
                                                lineHeight: 1.5,
                                                cursor: "pointer",
                                                textDecoration: "none",
                                                outline: "none",
                                                display: "block",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }}
                                        >
                                            {this.getText(item)}
                                        </div>
                                    </div>
                                </React.Fragment>)
                        })}
                    </>)}
            </div>)
    }

    private getInputText = () => {
        const {
            searchProcessInProgress,
            search
        } = this.state;
        const {current} = this.props;
        if (searchProcessInProgress) {
            return search;
        } else {
            return this.getText(current);
        }
    }

    private onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const {selectedIndex} = this.state;
        const items = this.getItems();
        switch (e.key) {
            case "Enter":
                if (typeof selectedIndex === "number") {
                    this.onSelected(items[selectedIndex]);
                }
                e.preventDefault();
                break;
            case "ArrowUp":
                if (typeof selectedIndex === "number") {
                    this.setState({selectedIndex: selectedIndex <= 0 ? items.length - 1 : selectedIndex - 1})
                } else {
                    this.setState({selectedIndex: items.length - 1})
                }
                e.preventDefault();
                break;
            case "ArrowDown":
                if (typeof selectedIndex === "number") {
                    this.setState({selectedIndex: selectedIndex >= items.length - 1 ? 0 : selectedIndex + 1})
                } else {
                    this.setState({selectedIndex: 0});
                }
                e.preventDefault();
                break;
        }
    }

    private onSearchChange = (search: string) => {
        this.props.onSelected && this.props.onSelected(null);
        this.setState({
            search,
            searchProgress: true,
            selectedIndex: null
        });
        this.searchTimeout && clearTimeout(this.searchTimeout);
        this.searchPromise?.cancel();
        setTimeout(() => {
            this.searchPromise = GoogleMapsApiManager.SearchForPlaces(search, this.sessionToken)
                                                     .then(locations => {
                                                         this.setState({
                                                             searchProgress: false,
                                                             selectedIndex: null,
                                                             searchedItems: locations.map<searchAddressItem>(l => ({
                                                                 id: l.place_id,
                                                                 address: l.address,
                                                                 type: "location"
                                                             }))
                                                         });
                                                     })
                                                     .catch(err => {
                                                         if (err.isCancelled) {
                                                         } else {
                                                             this.setState({
                                                                 searchProgress: false,
                                                                 selectedIndex: null,
                                                                 searchedItems: []
                                                             });
                                                         }
                                                     })
        }, 100);
    }
}   