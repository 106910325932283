export function downloadObjectAsJson(exportObj: any, exportName: string) {
    // Convert the object to a JSON string
    exportName = sanitizeFilename(exportName);
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj, null, 4));

    // Create an anchor element and set its href attribute to the data string
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");

    // Append the anchor to the body, trigger a click, and then remove it
    document.body.appendChild(downloadAnchorNode); // required for Firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}

export function sanitizeFilename(filename: string): string {
    // Replace invalid characters < > : " / \ | ? * with an underscore
    let safeFilename = filename.replace(/[<>:"\/\\|?*]+/g, '_');
    // Trim spaces and periods from the end of the filename
    safeFilename = safeFilename.replace(/[\. ]+$/, '');

    return safeFilename;
}